import OpenAccountAdjustmentsApi from 'api/open-account/adjustments/adjustments';
import { AxiosResponse } from 'axios';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import { GET_ADJUSTMENTS, GET_ADJUSTMENTS_FAILURE, GET_ADJUSTMENTS_SUCCESS } from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchAdjustmentsResults: (action: any) => Promise<any> = async (action) => {
  const openAccountPurchaseOrdersApi = new OpenAccountAdjustmentsApi(store);
  const { type, assetId }: { type: keyof typeof AssetEnum; assetId: string } = action.payload;
  const res: AxiosResponse<any> = await openAccountPurchaseOrdersApi.getOpenAccountAdjustmentsById(
    type,
    assetId
  );
  return res.data;
};

//===================================================
//                      EPICS
//===================================================

const getAdjustments$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_ADJUSTMENTS),
    switchMap((action) =>
      from(fetchAdjustmentsResults(action)).pipe(
        map((payload) => ({ type: GET_ADJUSTMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_ADJUSTMENTS_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(getAdjustments$);
