import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const TransactionHistoryDetailList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

export const TransactionHistoryDetailListItem = styled.p``;

export const TransactionHistoryDetailListValue = styled.p`
  ${typography.bodySemiBold};
`;
