import { RequiredAuth } from 'components/routes/SecureRoute';
import { Navigate } from 'react-router';
import { IRoute } from 'utils/interfaces/route/route.interface';
import TenantSelection from 'views/TenantSelection';

const tenantRoutes: IRoute[] = [
  {
    path: '/',
    name: 'Tenant Selection Home',
    component: <RequiredAuth />,
    secure: <TenantSelection />
  },
  {
    path: 'login/callback',
    name: 'Okta Callback',
    component: <RequiredAuth />
  },
  {
    path: '*',
    name: 'Redirect',
    component: <Navigate to="/" />
  }
];

export default tenantRoutes;
