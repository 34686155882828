import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const EarlyPaymentRequestSentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const EarlyPaymentRequestSentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${isMobile() ? 0.9 : 0.5};
`;

export const EarlyPaymentRequestSentTitle = styled.h2`
  color: ${themeColors.text.primary};
`;

export const EarlyPaymentRequestSentNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${isMobile() ? '24px 0' : '24px 0 8px 0'};
`;

export const EarlyPaymentRequestSentActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EarlyPaymentRequestSentButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 16px;
`;

export const EarlyPaymentRequestSentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const EarlyPaymentRequestSentButtonText = styled.p`
  ${typography.bodySemiBold};
`;
