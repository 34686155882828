import { themeColors } from './style';

export const fontWeights = {
  bold: 700,
  medium: 500,
  semiBold: 600,
  regular: 400
};

export const fontSizes = {
  xsmall: '12px',
  small: '14px',
  regular: '16px',
  medium: '18px',
  large: '20px',
  xLarge: '24px'
};

export const font = {
  heading: `h1,
  h2,
  h3,
  h4,
  h5 {font-family: 'roc-grotesk', sans-serif;}`,
  body: `*:not(h1, h2, h3, h4, h5) {font-family: 'Source Sans Pro', sans-serif;}`
};

export const typography = {
  tableHeader: `font-size: ${fontSizes.xsmall}; font-weight: ${fontWeights.semiBold}; text-transform: uppercase; color: ${themeColors.text.light.body}`,
  bodyXSmall: `font-size: ${fontSizes.xsmall}`,
  bodyRegular: `font-size: ${fontSizes.regular}`,
  bodyMediumSemiBold: `font-size: ${fontSizes.medium}; font-weight: ${fontWeights.semiBold}`,
  bodySmallSemiBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.semiBold}`,
  bodySemiBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
  navLink: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
  bodyBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.bold}`,
  link: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}; text-decoration: underline; cursor: pointer;`,
  captionRegular: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.regular}`,
  captionBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.bold}`,
  fontHindSmall: `font-family:'Hind', sans-serif; font-size: ${fontSizes.small};`,
  fontHindRegular: `font-family:'Hind', sans-serif; font-size: ${fontSizes.regular};`,
  fontHindLarge: `font-family:'Hind', sans-serif; font-size: ${fontSizes.large};`,
  fontHindSemiBoldSmall: `font-family:'Hind', sans-serif; font-weight: ${fontWeights.semiBold}; font-size: ${fontSizes.small};`,
  fontHindSemiBoldRegular: `font-family:'Hind', sans-serif; font-weight: ${fontWeights.semiBold}; font-size: ${fontSizes.regular};`,
  fontHindSemiBoldLarge: `font-family:'Hind', sans-serif; font-weight: ${fontWeights.semiBold}; font-size: ${fontSizes.large};`,
  fontAvenirNextMedium: `font-family:'Avenir Next LT Pro', sans-serif; font-weight: ${fontWeights.medium}`
};
