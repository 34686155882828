import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IPurchaseOrderMatchingResult } from 'api/interfaces/open-account/purchase-orders/purchaseOrders.interface';
import {
  GET_PO_COMMENTS,
  GET_PO_COMMENTS_FAILURE,
  GET_PO_COMMENTS_SUCCESS,
  GET_PO_MATCHING_RESULTS,
  GET_PO_MATCHING_RESULTS_FAILURE,
  GET_PO_MATCHING_RESULTS_SUCCESS,
  POST_PO_COMMENTS,
  POST_PO_COMMENTS_FAILURE,
  POST_PO_COMMENTS_SUCCESS,
  SET_PO_FOR_VIEW
} from 'store/actions';
import { RESET_PO_LOADING } from 'store/actions/purchaseOrder';
import { ReducerAction, StateBase } from '.';

interface PurchaseOrderReducerStateProps extends StateBase {
  poMatchingResults: IPurchaseOrderMatchingResult | null;
  poForView: ILedgerPurchaseOrder | null;
  poCommentsLoading: boolean;
  poComments: IComment[];
}

const DEFAULT_STATE: PurchaseOrderReducerStateProps = {
  poMatchingResults: null,
  poForView: null,
  poCommentsLoading: false,
  poComments: [],
  error: '',
  loading: false
};

const purchaseOrderReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case RESET_PO_LOADING:
      return {
        ...state,
        loading: false
      };
    case SET_PO_FOR_VIEW:
      return {
        ...state,
        poForView: payload,
        loading: true
      };
    case GET_PO_MATCHING_RESULTS:
      return {
        ...state,
        loading: true
      };
    case GET_PO_MATCHING_RESULTS_SUCCESS:
      return {
        ...state,
        poMatchingResults: payload,
        loading: false
      };
    case GET_PO_MATCHING_RESULTS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case POST_PO_COMMENTS:
      return {
        ...state,
        poCommentsLoading: true
      };
    case POST_PO_COMMENTS_SUCCESS:
      return {
        ...state,
        poComments: payload.data,
        poCommentsLoading: false
      };
    case POST_PO_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        poCommentsLoading: false
      };
    case GET_PO_COMMENTS:
      return {
        ...state,
        poCommentsLoading: true
      };
    case GET_PO_COMMENTS_SUCCESS:
      return {
        ...state,
        poComments: payload.data,
        poCommentsLoading: false
      };
    case GET_PO_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        poCommentsLoading: false
      };
    default:
      return state;
  }
};

export { purchaseOrderReducer };
export type { PurchaseOrderReducerStateProps };
