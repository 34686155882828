import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface Color {
  color: string;
}

export const PaymentStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const PaymentStatusIndicator = styled.div<Color>`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  transform: translateY(1px);
`;

export const PaymentStatusText = styled.p`
  ${typography.bodyRegular};
`;
