import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const LogisticUploadIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '16px',
  width = '21px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C12.5128 0 12.9355 0.38604 12.9933 0.883379L13 1H17C17.3074 1 17.595 1.1411 17.7832 1.37816L17.8575 1.4855L20.8575 6.4855L20.894 6.5518L20.937 6.64994L20.9763 6.78246L20.9952 6.89984L21 7V13C21 13.5128 20.614 13.9355 20.1166 13.9933L20 14H18.8293C18.4175 15.1652 17.3062 16 16 16C14.6938 16 13.5825 15.1652 13.1707 14H8.82929C8.41746 15.1652 7.30622 16 6 16C4.69378 16 3.58254 15.1652 3.17071 14H2C1.48716 14 1.06449 13.614 1.00673 13.1166L1 13V9C1 8.44771 1.44772 8 2 8C2.51284 8 2.93551 8.38604 2.99327 8.88338L3 9V12H3.17071C3.58254 10.8348 4.69378 10 6 10C7.30622 10 8.41746 10.8348 8.82929 12H11V2H1C0.487164 2 0.0644928 1.61396 0.00672773 1.11662L0 1C0 0.487164 0.38604 0.0644928 0.883379 0.00672773L1 0H12ZM19 12H18.8293C18.4175 10.8348 17.3062 10 16 10C14.6938 10 13.5825 10.8348 13.1707 12H13V8H19V12ZM16 14C15.4477 14 15 13.5523 15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14ZM6 14C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14ZM13 6V3H16.434L18.234 6H13ZM7 5C7 4.44772 6.55228 4 6 4H2L1.88338 4.00673C1.38604 4.06449 1 4.48716 1 5C1 5.55228 1.44772 6 2 6H6L6.11662 5.99327C6.61396 5.93551 7 5.51284 7 5Z"
      fill={color}
    />
  </svg>
);

export default LogisticUploadIcon;
