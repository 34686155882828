import PackageIcon from 'assets/icons/PackageIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { toTitleCase } from 'lib/helpers/formatters/stringFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';

export const invoiceLedgerLineItemTableHeaders = (
  lineItemClickHandler: any,
  type?: keyof typeof AssetEnum
) => [
  new DataGridHeaderItem(' ', 'isDiscrepant', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          {tableMeta.rowData[0] && (
            <IconTooltip
              tooltipText="Discrepant"
              children={<WarningIcon color={themeColors.icon.error} height="16" width="16" />}
            />
          )}
        </div>
      );
    }
  }),
  new DataGridHeaderItem('Line Item Number', 'lineNumber', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '4px'
          }}
        >
          <p>{value}</p>
          {tableMeta.rowData[10] && (
            <span style={{ cursor: 'pointer' }} onClick={() => lineItemClickHandler(value)}>
              <PackageIcon />
            </span>
          )}
        </div>
      );
    }
  }),
  new DataGridHeaderItem('PO Number', 'poNumber', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('SKU', 'itemNumber', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('Product/Service Description', 'itemName', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('QTY', 'quantity', new DataGridHeaderOptions(false, true, true)),

  new DataGridHeaderItem('Unit of Measure', 'itemMeasure', {
    ...new DataGridHeaderOptions(false, true, true)
  }),

  new DataGridHeaderItem('Unit Price', 'unitPrice', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{CurrencySymbolsLookUp[tableMeta.rowData[8]] + value}</p>
    ),
    display: type !== AssetEnum.LOGISTIC
  }),

  new DataGridHeaderItem('CCY', 'currency', {
    display: false
  }),

  new DataGridHeaderItem('Amount', 'amount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{CurrencySymbolsLookUp[tableMeta.rowData[8]] + value}</p>
    ),
    display: type !== AssetEnum.LOGISTIC
  }),
  new DataGridHeaderItem('', 'hasPackagingInfo', {
    ...new DataGridHeaderOptions(false, true, true),
    display: false
  })
];

export const poLedgerLineItemTableHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem(' ', 'isDiscrepant', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          {tableMeta.rowData[0] && (
            <IconTooltip
              tooltipText="Discrepant"
              children={<WarningIcon color={themeColors.icon.error} height="16" width="16" />}
            />
          )}
        </div>
      );
    }
  }),
  new DataGridHeaderItem(
    'Line Item Number',
    'lineNumber',
    new DataGridHeaderOptions(false, true, true)
  ),

  new DataGridHeaderItem('Status', 'lineItemPrincipalStatus', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <Chip
        type={toTitleCase(value?.toLowerCase() || '')}
        color={themeColors.text.primary}
        radius="3px"
        height="18px"
        fontSize={fontSizes.small}
        semibold="bold"
        testingTag="ledger-line-item-principal-status-chip"
      />
    )
  }),
  new DataGridHeaderItem('SKU', 'itemNumber', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('Product/Service Description', 'itemName', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('Unit of Measure', 'itemMeasure', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('Unit Price', 'unitPrice', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{CurrencySymbolsLookUp[tableMeta.rowData[11]] + value}</p>
    )
  }),
  new DataGridHeaderItem(
    'Original Quantity',
    'quantity',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Original Amount', 'amount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{CurrencySymbolsLookUp[tableMeta.rowData[11]] + value}</p>
    )
  }),
  new DataGridHeaderItem(
    'Available Quantity',
    'availableQuantity',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Available Amount', 'availableAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{CurrencySymbolsLookUp[tableMeta.rowData[11]] + value}</p>
    )
  }),
  new DataGridHeaderItem('CCY', 'currency', {
    display: false
  })
];
