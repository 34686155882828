import { ChangeEvent, MouseEvent, FC, KeyboardEvent } from 'react';
import {
  OutlinedInputContainer,
  OutlinedInputLabel,
  OutlinedInputMessage,
  OutlinedInputStyled
} from './styled';

export interface OutlinedInputProps {
  id?: string;
  defaultValue?: string;
  message?: string;
  errorMessage?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  changeHandler?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  register?: any;
  label?: string;
  type?: 'number' | 'password' | 'text';
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  endAdornment?: any;
  startAdornment?: any;
  dataAutomationId?: string;
  dataTestId?: string;
  width?: string;
  height?: string;
  minHeight?: string;
  noBorder?: boolean;
  showShadow?: boolean;
  borderRadius?: string;
  handleKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const OutlinedInput: FC<OutlinedInputProps> = ({
  defaultValue,
  message,
  errorMessage,
  register,
  label,
  type,
  placeholder,
  disabled,
  onBlur,
  changeHandler,
  onClick,
  multiline,
  endAdornment,
  startAdornment,
  dataAutomationId,
  dataTestId,
  id,
  width,
  height,
  minHeight,
  noBorder,
  showShadow,
  borderRadius,
  handleKeyDown
}) => (
  <OutlinedInputContainer width={width || ''}>
    {label && <OutlinedInputLabel>{label}</OutlinedInputLabel>}

    <OutlinedInputStyled
      id={id}
      data-automation-id={dataAutomationId}
      data-testid={dataTestId}
      autoComplete="off"
      placeholder={placeholder || ''}
      value={defaultValue}
      type={type}
      disabled={disabled}
      onBlur={onBlur}
      onClick={onClick}
      onChange={changeHandler}
      onKeyDown={handleKeyDown}
      inputProps={register}
      multiline={multiline}
      endAdornment={endAdornment}
      startAdornment={startAdornment}
      borderRadius={borderRadius}
      noBorder={noBorder}
      showShadow={showShadow}
      height={height || ''}
      minHeight={minHeight || ''}
    />

    {(message || errorMessage) && (
      <OutlinedInputMessage errorMessage={errorMessage || ''}>
        {message || errorMessage}
      </OutlinedInputMessage>
    )}
  </OutlinedInputContainer>
);

export default OutlinedInput;
