import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile, isSmallerView } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: ${isMobile() ? '90%' : '60%'};
  width: ${isMobile() || isSmallerView() ? '90%' : '60%'};
  padding: ${isMobile() ? '24px 0' : '8px 32px'};
`;

export const HomeOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HomeOverviewTitle = styled.h3`
  margin: 0;
`;

export const HomeOverviewCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;

export const HomeOverviewCardButtonText = styled.h5`
  margin: 0;
  color: ${themeColors.text.primary};
`;

export const EarlyPaymentSnackbarTitle = styled.h5`
  margin: 0;
`;

export const EarlyPaymentSnackbarText = styled.p`
  margin: 0;
  span {
    ${typography.bodyBold};
  }
`;

export const HomeChartView = styled.div`
  display: flex;
	gap: 24px;
`;

export const HomeCurrencyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

