import { ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { Program, ProgramConfig, Programs } from 'api/interfaces/program/program.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import ProgramApi from 'api/program/program.api';
import { AxiosResponse } from 'axios';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { acc } from 'lib/helpers/accumulator';
import _ from 'lodash';
import { store } from 'store';
import { IAppState } from 'store/epics/app';
import { AppReducerStateProps } from 'store/reducers/app';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';
import { appLoadByCurrency } from './appLoadByCurrency';

export const supplierPayInitialisation: (
  appState: AppReducerStateProps,
  accessToken: string
) => Promise<IAppState> = async (appState, accessToken) => {
  const programApi = new ProgramApi(store, accessToken);
  const invoiceApi = new InvoiceApi(store, accessToken);

  //#region <PROGRAM>

  const allProgramsResponse: AxiosResponse<Programs> = await programApi.getPrograms();

  if (!allProgramsResponse?.data?.content?.length || allProgramsResponse.data.content.length === 0)
    throw new Error('INIT APP: No programs found.');

  const allProgramsWithDetails: ProgramConfig[] = (
    await Promise.all(
      allProgramsResponse.data.content.map(
        async (program) => await programApi.getProgram(program.id)
      )
    )
  ).flatMap((res) => res.data);

  const mappedPrograms: IGroupedProgram[] | null = await mappedProgramModel(allProgramsWithDetails);

  if (mappedPrograms === null) throw Error('NO PROGRAM TYPE DEFINED!');

  const selectedProgram: Program = allProgramsResponse.data.content[0];

  const programDashTopLevelDataMap = await Promise.all(
    mappedPrograms.map(async (p) => {
      const ids = p.programDetails.map((prog) => prog.id);
      const fetchEligibleAcceptedInvoicesResponse: AxiosResponse<ODataLedgerInvoices> =
        p.programDetails.find((p) => p.baseType.includes(ProgramType.OPEN_ACCOUNT))
          ? await invoiceApi.fetchOpenInvoices(ids)
          : await invoiceApi.fetchEligibleAcceptedInvoices(ids);
      p.openInvoices = fetchEligibleAcceptedInvoicesResponse.data.value;
      p.openInvoicesTotal =
        fetchEligibleAcceptedInvoicesResponse?.data?.value
          ?.map((r: any) => r.netAmount)
          ?.reduce(acc, 0) || 0;

      return p;
    })
  );

  return {
    ...(await appLoadByCurrency(
      programDashTopLevelDataMap[0],
      allProgramsWithDetails,
      appState,
      accessToken
    )),
    programID: selectedProgram.id,
    onboardingComplete: true,
    onboardingInitData: null,
    legalEntityInit: null,
    acceptTermsAndConditionsArtifactPdfBlob: null,
    acceptTermsAndConditionsArtifactPdfName: '',
    entityOnboardingActionsStateByProgram: null,
    onboardingBankAccountArtifacts: null,
    platformAvailable: true,
    programOwnerDetails: null,
    allProgramsWithDetails,
    groupedPrograms: programDashTopLevelDataMap
  };
};

const createProgramModel: (currency: string, programs: ProgramConfig[]) => IGroupedProgram = (
  currency,
  programs
) => ({
  currency: currency,
  type: programs.find((p) => p.baseType.includes(ProgramType.EXTERNAL_FUNDING))
    ? ProgramType.OA_EXTERNAL_FUNDING
    : ProgramType.OPEN_ACCOUNT,
  programDetails: programs,
  active: false
});

const mappedProgramModel: (programs: ProgramConfig[]) => Promise<IGroupedProgram[] | null> = async (
  programs
) => {
  const mappedCurrencies = programs.map((p) => {
    p.currency = p?.rules.find((r) => r.type === 'CURRENCIES')?.value.acceptedCurrencies[0];
    return p;
  });
  const groupedByCurrency = _.groupBy(mappedCurrencies, 'currency');

  const mappedPrograms = Object.keys(groupedByCurrency).flatMap((key) => {
    const programsGroupedByCurrency = groupedByCurrency[key];
    const model = createProgramModel(key, programsGroupedByCurrency);
    return model;
  });
  return mappedPrograms;
};
