import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { camelCaseToKebabCase } from 'lib/helpers/formatters/stringFormatters';
import React, { FC } from 'react';
import {
  LedgerDetailColumn,
  LedgerDetailSummaryContainer,
  LedgerDetailsKeyValueRow,
  LedgerDetailsSegmentContainer,
  LedgerDetailsSummaryTextRegular,
  LedgerDetailsSummaryTextSemiBold
} from './styled';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';

export interface LedgerSummaryItem {
  name: string;
  key: string;
  value: string | number;
  tooltip: string;
  link?: string;
}

interface LedgerSummaryProps {
  data: LedgerSummaryItem[];
  numericSummaryData?: LedgerSummaryItem[];
  outstandingAmount: number;
  paymentDueDate: string;
  currency: string;
  type: keyof typeof AssetEnum;
  isProgramFixedCost: boolean;
}

const LedgerDetailSummary: FC<LedgerSummaryProps> = ({
  data,
  currency,
  numericSummaryData,
  type
}) => {
  return (
    <LedgerDetailSummaryContainer data-automation-id="ledger-detail-summary-div-container">
      <LedgerDetailsSegmentContainer>
        {data.map((d, i) => (
          <LedgerDetailsKeyValueRow
            key={`summary-${d.key}-${i}`}
            data-automation-id={`ledger-detail-summary-row-${camelCaseToKebabCase(d.key)}`}
          >
            <LedgerDetailsSummaryTextSemiBold
              data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                d.key
              )}`}
            >
              {d.name}
            </LedgerDetailsSummaryTextSemiBold>
            <LedgerDetailsSummaryTextRegular
              data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                d.key
              )}`}
            >
              {d.value}
            </LedgerDetailsSummaryTextRegular>
          </LedgerDetailsKeyValueRow>
        ))}
      </LedgerDetailsSegmentContainer>
      {type !== AssetEnum.PAYMENT && numericSummaryData && numericSummaryData?.length > 0 && (
        <LedgerDetailsSegmentContainer isTotals>
          <LedgerDetailColumn>
            {numericSummaryData
              .filter((d) => d.key !== 'netAmount')
              .map((n) =>
                n.key !== 'amount' && n.key !== 'netAmount' && n.value !== 0 ? (
                  <React.Fragment key={`final-${n.key}`}>
                    <LedgerDetailsKeyValueRow>
                      <LedgerDetailsSummaryTextSemiBold
                        data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {n.name}
                      </LedgerDetailsSummaryTextSemiBold>
                      <LedgerDetailsSummaryTextRegular
                        data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {n.key !== 'cashPaidToDate' &&
                        n.key !== 'fees' &&
                        n.key !== 'totalDilutions' &&
                        n.key !== 'consumedAmount'
                          ? ''
                          : '- '}
                        {formatNumber(n.value as number, 2)}
                      </LedgerDetailsSummaryTextRegular>
                    </LedgerDetailsKeyValueRow>
                  </React.Fragment>
                ) : n.value === 0 ? (
                  <></>
                ) : (
                  <React.Fragment key={`final-${n.key}`}>
                    <LedgerDetailsKeyValueRow>
                      <LedgerDetailsSummaryTextSemiBold
                        data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {n.name}
                      </LedgerDetailsSummaryTextSemiBold>
                      <LedgerDetailsSummaryTextRegular
                        data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {formatNumber(n.value as number, 2)}
                      </LedgerDetailsSummaryTextRegular>
                    </LedgerDetailsKeyValueRow>
                  </React.Fragment>
                )
              )}
          </LedgerDetailColumn>
          <LedgerDetailColumn isTotals>
            {numericSummaryData &&
              numericSummaryData
                .filter((d) => d.key === 'netAmount')
                .map((n) => (
                  <LedgerDetailsKeyValueRow key={`totals-${n.key}`}>
                    <LedgerDetailsSummaryTextSemiBold
                      data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                        n.key
                      )}`}
                    >
                      {n.name}
                    </LedgerDetailsSummaryTextSemiBold>
                    <LedgerDetailsSummaryTextSemiBold
                      data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                        n.key
                      )}`}
                    >
                      {CurrencySymbolsLookUp[currency]} {formatNumber(n.value as number, 2)}
                    </LedgerDetailsSummaryTextSemiBold>
                  </LedgerDetailsKeyValueRow>
                ))}
          </LedgerDetailColumn>
        </LedgerDetailsSegmentContainer>
      )}
      {type === AssetEnum.PAYMENT && numericSummaryData && numericSummaryData?.length > 0 && (
        <LedgerDetailsSegmentContainer isTotals>
          <LedgerDetailColumn>
            {numericSummaryData
              .filter((d) => d.key !== 'totalPaymentAmount')
              .map((n) =>
                n.value !== 0 ? (
                  <React.Fragment key={`final-${n.key}`}>
                    <LedgerDetailsKeyValueRow>
                      <LedgerDetailsSummaryTextSemiBold
                        data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {n.name}
                      </LedgerDetailsSummaryTextSemiBold>
                      <LedgerDetailsSummaryTextRegular
                        data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {formatNumber(n.value as number, 2)}
                      </LedgerDetailsSummaryTextRegular>
                    </LedgerDetailsKeyValueRow>
                  </React.Fragment>
                ) : n.value === 0 ? (
                  <></>
                ) : (
                  <React.Fragment key={`final-${n.key}`}>
                    <LedgerDetailsKeyValueRow>
                      <LedgerDetailsSummaryTextSemiBold
                        data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {n.name}
                      </LedgerDetailsSummaryTextSemiBold>
                      <LedgerDetailsSummaryTextRegular
                        data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                          n.key
                        )}`}
                      >
                        {formatNumber(n.value as number, 2)}
                      </LedgerDetailsSummaryTextRegular>
                    </LedgerDetailsKeyValueRow>
                  </React.Fragment>
                )
              )}
          </LedgerDetailColumn>
          <LedgerDetailColumn isTotals>
            {numericSummaryData &&
              numericSummaryData
                .filter((d) => d.key === 'totalPaymentAmount')
                .map((n) => (
                  <LedgerDetailsKeyValueRow key={`totals-${n.key}`}>
                    <LedgerDetailsSummaryTextSemiBold
                      data-automation-id={`ledger-detail-summary-p-bold-value-${camelCaseToKebabCase(
                        n.key
                      )}`}
                    >
                      {n.name}
                    </LedgerDetailsSummaryTextSemiBold>
                    <LedgerDetailsSummaryTextSemiBold
                      data-automation-id={`ledger-detail-summary-p-regular-value-${camelCaseToKebabCase(
                        n.key
                      )}`}
                    >
                      {CurrencySymbolsLookUp[currency]} {formatNumber(n.value as number, 2)}
                    </LedgerDetailsSummaryTextSemiBold>
                  </LedgerDetailsKeyValueRow>
                ))}
          </LedgerDetailColumn>
        </LedgerDetailsSegmentContainer>
      )}
    </LedgerDetailSummaryContainer>
  );
};

export default LedgerDetailSummary;
