import { Select } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface SelectMessageProps {
  errorMessage?: string;
}

interface SelectStyledProps {
  disabled?: boolean;
  background?: string;
}

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.4);
  ${typography.bodyRegular};
`;

export const SelectStyled = styled(Select)<SelectStyledProps>`
  display: flex;
  width: 100%;
  height: 40px;
  font-size: ${fontSizes.regular} !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  box-sizing: border-box;
  border-radius: 6px !important;

    &.MuiOutlinedInput-root{
      background-color: ${({ disabled }) => disabled ? themeColors.bg.light.inputDisabled : themeColors.bg.light.input}!important;;
      border: 2px solid ${({ disabled }) => disabled ? themeColors.border.inputDisabled : themeColors.border.input}!important;;
      color: ${themeColors.text.light.input}!important;;
      pointer-events: ${({ disabled }) => disabled && 'none'};
      overflow: ${({ disabled }) => disabled && 'hidden'};

      &:hover, &:-webkit-autofill:hover{
        background-color: ${themeColors.bg.light.inputHover}!important;
        border: 2px solid ${({ disabled }) => disabled ? themeColors.border.inputDisabled : themeColors.border.inputHover}!important;;
        color: ${themeColors.text.light.inputHover}!important; 
      }

      &:-webkit-autofill{
        -webkit-text-fill-color:  ${themeColors.text.light.input}!important; 
        background-color: ${themeColors.bg.light.input}!important;
        border: 2px solid ${themeColors.border.input}!important; 
        color: ${themeColors.text.light.input}!important;
      }
      &:focus, &:focus-visible, &:-webkit-autofill:focus{
        -webkit-text-fill-color:  ${themeColors.text.light.inputFocus}!important; 
        background-color: ${themeColors.bg.light.inputFocus}!important;
        border: 2px solid ${themeColors.border.inputFocus}!important; 
        color: ${themeColors.text.light.inputFocus}!important; 
        outline: none; 
      }
      .MuiOutlinedInput-notchedOutline{
        border: none;
      }
    }

`;

export const SelectLabel = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
  margin-bottom: 4px;
`;

export const SelectMessage = styled.span<SelectMessageProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.text.error};
  ${typography.captionRegular};
`;
