export enum FundingStatuses {
  FUNDING_RESPONSE_ACCEPTED = 'FUNDING_RESPONSE_ACCEPTED',
  FUNDING_RESPONSE_EXPIRED = 'FUNDING_RESPONSE_EXPIRED',
  TRADE_PAYMENT_SENT = 'TRADE_PAYMENT_SENT',
  FUNDING_REQUEST_EXPIRED = 'FUNDING_REQUEST_EXPIRED',
  TRADE_PAYMENT_RECEIVED = 'TRADE_PAYMENT_RECEIVED',
  FUNDING_REQUEST_REJECTED = 'FUNDING_REQUEST_REJECTED',
  FUNDING_REQUESTED = 'FUNDING_REQUESTED',
  ELIGIBLE = 'ELIGIBLE',
  TRADE_PAYMENT_SENT_BY_FUNDER = 'TRADE_PAYMENT_SENT_BY_FUNDER',
  MATURING_PAYMENT_SENT_BY_BUYER = 'MATURING_PAYMENT_SENT_BY_BUYER',
  MATURING_PAYMENT_RECEIVED_BY_SUPPLIER = 'MATURING_PAYMENT_RECEIVED_BY_SUPPLIER',
  MATURING_PAYMENT_RECEIVED_BY_FUNDER = 'MATURING_PAYMENT_RECEIVED_BY_FUNDER'
}
