import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  height?: string;
  width?: string;
}

interface ContainerWidth {
  width?: string;
}

interface IUploading {
  uploading?: boolean;
}

export const LogisticsArtifactWrapper = styled.div<ContainerWidth>`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  width: 100%;
`;

export const FormStyled = styled.form`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
`;

export const LogisticsArtifactActionsContainer = styled.div<IUploading>`
  display: flex;
  flex-direction: ${({ uploading }) => (uploading ? 'column' : 'row')};
  align-items: center;
  width: 100%;
  gap: 16px;
  padding-bottom: 32px;
  justify-content: ${({ uploading }) => (uploading ? 'center' : '')};
`;

export const LogisticsArtifactButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || ''};
`;

export const LogisticsArtifactButtonText = styled.p`
  margin: 0;
  ${typography.bodySmallSemiBold};
`;

export const LogisticsArtifactTitle = styled.h2`
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactDescription = styled.p`
  ${typography.bodyRegular}
`;

export const LogisticsArtifactSectionText = styled.p`
  ${typography.captionRegular}
`;

export const LogisticsArtifactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: ${themeColors.bg.light.muted};
  border-radius: 6px;
  height: 30px;
  width: 100%;
  justify-content: center;
`;

export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const LogisticsArtifactDocumentTitle = styled.h4`
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactLineItemShowMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  p{
    color: ${themeColors.text.light.body};
  }
`;

export const LogisticsArtifactLineItemDescriptionDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
  width: 100%;
`;

export const GroupedTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    ${typography.tableHeader};
    background-color: ${themeColors.bg.light.surfaceRaised};
    border-right: 1px solid ${themeColors.border.light.surfaceRaised};;
    height: 64px;
    border-bottom: none;
    text-transform: uppercase;
    vertical-align: middle;
    padding-left: 8px;
  
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  th:last-child{
    border-right: none;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.border.primary};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 8px;
    font-size: ${fontSizes.small};

    .MuiOutlinedInput-input {
      font-size: ${fontSizes.small};
      height: 34px;
    }
  }
  .text-align-right {
    text-align: right;
  }

  .more-detail {
    padding: '0px 8px 16px 8px';
  }

  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin: 0;
  }
`;

export const LogisticsArtifactAdditionalForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  align-self: center;
  gap: 48px;
  width: 50rem;
`;
