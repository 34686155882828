import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const GetPaidNowFilledIcon: FC<IconSvgProps> = ({ height = '40px', width = '40px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#3D3AFF" />
    <path
      d="M25.25 17.9752C25.2125 17.6377 24.95 17.4502 24.5375 17.4502C24.3875 17.4502 24.275 17.4502 24.125 17.4877L23.3375 17.6002C23.1875 17.6377 23 17.6377 22.85 17.6752C22.3625 17.7502 21.9125 17.8252 21.425 17.8627C21.2375 17.8627 21.0875 17.9002 20.8625 17.9002H20.7875C20.4875 17.6377 20.075 17.4877 19.6625 17.4877C19.25 17.4877 18.875 17.6752 18.575 17.9752L18.5375 18.0127C18.3875 18.2002 18.2375 18.4252 18.1625 18.6502V18.7252C18.125 18.8752 18.125 18.9877 18.125 19.1002C18.125 19.4377 18.2375 19.7377 18.425 20.0002C18.4625 20.0377 18.4625 20.0752 18.5 20.1127L18.6125 20.2252L18.6875 20.3002C18.8375 20.4127 19.025 20.5252 19.2125 20.6002C19.3625 20.6377 19.55 20.6752 19.7375 20.6752C20.3 20.6752 20.825 20.3377 21.0875 19.8502C21.1625 19.8127 21.275 19.7377 21.3875 19.7002L21.4625 19.6627C21.8 19.5127 22.175 19.4002 22.5125 19.2877C23.1875 19.0627 23.9 18.8752 24.65 18.6877C25.25 18.5752 25.25 18.2002 25.25 17.9752Z"
      fill="white"
    />
    <path
      d="M24.0125 26.6752C23.6375 26.5627 23.2625 26.4127 22.925 26.3002L22.775 26.2627L20.1875 25.3627C19.8125 25.2127 19.4 25.2877 19.1375 25.5127C18.8375 25.7377 18.7625 26.1127 18.8375 26.5252C18.875 26.6377 18.875 26.7127 18.9125 26.8627L19.025 27.5002H18.725C15.1625 27.0127 12.3125 24.3127 11.6375 20.7877C11.525 20.1502 11.45 19.5127 11.4875 18.8752C11.5625 16.5502 12.575 14.4127 14.3 12.8752C15.8 11.3752 17.75 10.5877 19.7 10.5127C20.225 10.5127 20.7125 10.5127 21.2375 10.5877C21.4625 10.6252 21.6875 10.6627 21.95 10.7377C22.4375 10.8502 22.925 10.5877 23.1125 10.1377C23.225 9.87516 23.1875 9.57516 23.075 9.31266C22.925 9.05016 22.7 8.86266 22.4375 8.82516C21.875 8.71266 21.275 8.63765 20.7125 8.60015C18.875 8.52515 17.225 8.82516 15.7625 9.46266C15.05 9.76266 14.3375 10.2127 13.5875 10.8127L13.4375 10.9627C13.0625 11.2627 12.725 11.6002 12.425 11.9377C10.025 14.6377 9.12504 17.6377 9.72504 20.8252C9.87504 21.5752 10.1 22.3627 10.4375 23.1127C11.675 26.1877 14 28.1752 17.3375 29.1127C17.7875 29.2252 18.275 29.3002 18.7625 29.4127C18.9875 29.4502 19.2125 29.4877 19.475 29.5252H19.5125L19.625 30.2002C19.6625 30.3502 19.7 30.5002 19.7 30.6127C19.775 31.0252 20.0375 31.3252 20.375 31.4377C20.45 31.4752 20.5625 31.4752 20.6375 31.4752C20.9 31.4752 21.1625 31.3627 21.3875 31.1752C22.4 30.3127 23.4125 29.3752 24.4625 28.4752C24.8 28.1752 24.9125 27.8377 24.875 27.5002C24.6875 27.0877 24.425 26.7877 24.0125 26.6752Z"
      fill="white"
    />
    <path
      d="M27.3875 25.3626C27.1625 25.1751 26.9375 24.9501 26.7125 24.7626L26.525 24.6126C26.375 24.5376 26.225 24.5001 26.0375 24.5001C25.8125 24.5001 25.625 24.6126 25.475 24.8001C25.2125 25.1376 25.2125 25.5876 25.475 25.8876L25.8125 26.1876L26.3 26.6001C26.45 26.7501 26.6375 26.8251 26.8625 26.8251C27.125 26.8251 27.35 26.7501 27.5375 26.5626C27.8375 26.2251 27.7625 25.7001 27.3875 25.3626ZM29.375 21.5001C29.075 21.4251 28.8125 21.3126 28.5125 21.2376L28.2875 21.2001C28.1375 21.2001 27.9875 21.2001 27.8 21.3126C27.6125 21.4251 27.4625 21.6126 27.425 21.8001C27.275 22.2126 27.5 22.6251 27.875 22.7751L28.2875 22.8876L28.8875 23.0751C29.1125 23.1501 29.3 23.1126 29.4875 23.0376C29.7125 22.9626 29.9 22.7751 29.975 22.5126C30.125 22.1001 29.8625 21.6501 29.375 21.5001ZM29.4875 17.1501C29.1875 17.1876 28.8875 17.2251 28.5875 17.3001L28.3625 17.3751C28.2125 17.4501 28.1 17.5251 27.9875 17.6751C27.875 17.8626 27.8 18.0501 27.8375 18.2751C27.9125 18.6876 28.25 18.9876 28.7 18.9501L29.1125 18.8751L29.75 18.7626C29.975 18.7251 30.1625 18.6501 30.275 18.5001C30.4625 18.3126 30.5375 18.0876 30.5 17.8251C30.425 17.3751 30.0125 17.0751 29.4875 17.1501ZM27.8 13.1376C27.5375 13.2876 27.3125 13.4751 27.05 13.6251L26.8625 13.7751C26.75 13.8876 26.675 14.0376 26.6375 14.2251C26.6 14.4501 26.6375 14.6376 26.7875 14.8251C27.0125 15.1626 27.4625 15.2751 27.875 15.0876L28.25 14.8626L28.775 14.5251C28.9625 14.4126 29.075 14.2251 29.15 14.0376C29.225 13.8126 29.1875 13.5501 29.0375 13.3251C28.7375 12.9501 28.2125 12.8751 27.8 13.1376ZM24.5375 10.2501L24.0875 11.0376L23.975 11.2626C23.9375 11.4126 23.9 11.5626 23.9375 11.7501C23.975 11.9751 24.125 12.1251 24.3125 12.2376C24.6875 12.4626 25.1375 12.3501 25.4 12.0126L25.625 11.6376L25.9625 11.1126C26.075 10.9251 26.1125 10.7376 26.1125 10.5126C26.075 10.2501 25.9625 10.0626 25.7375 9.91262C25.2875 9.68762 24.8 9.83762 24.5375 10.2501Z"
      fill="white"
    />
  </svg>
);

export default GetPaidNowFilledIcon;
