import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const WarningIcon: FC<IconSvgProps> = ({ height = '16px', width = '16px', color = '#FFFFFF' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8ZM8 4.25C8.41421 4.25 8.75 4.58579 8.75 5V8C8.75 8.41421 8.41421 8.75 8 8.75C7.58579 8.75 7.25 8.41421 7.25 8V5C7.25 4.58579 7.58579 4.25 8 4.25ZM7.25 11C7.25 10.5858 7.58579 10.25 8 10.25C8.41421 10.25 8.75 10.5858 8.75 11C8.75 11.4142 8.41421 11.75 8 11.75C7.58579 11.75 7.25 11.4142 7.25 11Z"
      fill={color}
    />
  </svg>
);

export default WarningIcon;
