import { themeColors } from 'assets/theme/style';
import { fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  activeLink?: boolean;
  disabled?: boolean;
}

export const SideBarNavButtonContainer = styled.h4<ButtonProps>`
  display: inline-flex;
  ${({ activeLink }) => (activeLink ? typography.bodyBold : typography.bodySemiBold)};
  height: 38px;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border-radius: 6px;
  color: ${({ activeLink }) => (activeLink ? themeColors.text.light.body : themeColors.text.light.subtle)};
  &:hover {
    ${typography.bodyBold};
    color: ${themeColors.text.light.body};
    ${({ disabled }) => (disabled ? 'cursor: text;' : '')};
  }
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? '0.38' : 1)};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
`;
