import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';

const demoNavItems: NavigationItem[] = [
  {
    text: 'Home',
    path: '/',
    dataAutomationTag: 'nav-button-link-home'
  },
  {
    text: 'Purchase orders',
    path: '/purchase-orders',
    dataAutomationTag: 'nav-button-link-purchase-orders'
  },
  {
    text: 'Invoices',
    path: '/invoices',
    dataAutomationTag: 'nav-button-link-invoices'
  },
  {
    text: 'ESG',
    path: '/esg',
    dataAutomationTag: 'nav-button-link-esg'
  },
  {
    text: 'Incoming Payments',
    path: '/payments',
    dataAutomationTag: 'nav-button-link-payments'
  },
  {
    text: 'Outgoing Payments',
    path: '/outgoing-payments',
    dataAutomationTag: 'nav-button-link-outgoing-payments'
  }
];

export const navItems: NavigationItem[] = [
  {
    text: 'Home',
    path: '/',
    dataAutomationTag: 'nav-button-link-home'
  },
  {
    text: 'Purchase orders',
    path: '/purchase-orders',
    dataAutomationTag: 'nav-button-link-purchase-orders'
  },
  {
    text: 'Invoices',
    path: '/invoices',
    dataAutomationTag: 'nav-button-link-invoices'
  },
  {
    text: 'Logistics',
    path: '/logistics',
    dataAutomationTag: 'nav-button-link-payments'
  },
  {
    text: 'Payments',
    path: '/payments',
    dataAutomationTag: 'nav-button-link-payments'
  }
];

const setNavItems: () => NavigationItem[] = () =>
  window.location.origin.includes('demo') ? demoNavItems : navItems;

export let navigationData: NavigationItem[] = setNavItems();
