import { styling, themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const NavbarStyled = styled.div`
  display: flex;
  height: 72px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: ${themeColors.white};
`;

export const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const MobileNavBarStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 88px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${themeColors.bg.light.body};
  padding: ${styling.defaultPadding};
`;

export const MobileIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigationLeft = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  gap: 24px;
`;

export const NavigationRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 100px;
  gap: 8px;
`;
