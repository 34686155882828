import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const DotMenuIcon: FC<IconSvgProps> = ({ height = '36px', width = '36px' }) => (
  // <svg
  //   width={width}
  //   height={height}
  //   viewBox="0 0 4 16"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   data-automation-id="icon-svg-dot-menu-icon"
  // >
  //   <path
  //     d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
  //     fill={color}
  //   />
  // </svg>
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H32C33.933 0.5 35.5 2.067 35.5 4V32C35.5 33.933 33.933 35.5 32 35.5H4C2.067 35.5 0.5 33.933 0.5 32V4Z"
      stroke="#D8D8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9426 12.3905C19.4633 12.9112 19.4633 13.7554 18.9426 14.2761C18.4219 14.7968 17.5777 14.7968 17.057 14.2761C16.5363 13.7554 16.5363 12.9112 17.057 12.3905C17.5777 11.8698 18.4219 11.8698 18.9426 12.3905ZM18.9426 17.0572C19.4633 17.5779 19.4633 18.4221 18.9426 18.9428C18.4219 19.4635 17.5777 19.4635 17.057 18.9428C16.5363 18.4221 16.5363 17.5779 17.057 17.0572C17.5777 16.5365 18.4219 16.5365 18.9426 17.0572ZM18.9426 23.6095C19.4633 23.0888 19.4633 22.2446 18.9426 21.7239C18.4219 21.2032 17.5777 21.2032 17.057 21.7239C16.5363 22.2446 16.5363 23.0888 17.057 23.6095C17.5777 24.1302 18.4219 24.1302 18.9426 23.6095Z"
      fill="#3D3AFF"
    />
  </svg>
);

export default DotMenuIcon;
