/* eslint-disable react-hooks/exhaustive-deps */
import AssetsApi from 'api/assets/assets.api';
import {
  ITaskData,
  ITaskDataItem,
  TaskDetails
} from 'api/interfaces/funding-request/task.interface';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import {
  ILogisticsRule,
  ILogisticsRuleSubtemplate,
  ILogisticsRuleSubtemplateField,
  ProgramConfig,
  ProgramRuleTypeEnum
} from 'api/interfaces/program/program.interface';
import OrchestrationApi from 'api/orchestration/orchestration.api';
import { RightArrowBoldIcon } from 'assets/icons/ArrowIcons';
import CheckIcon from 'assets/icons/CheckIcon';
import DocumentHighlightIcon from 'assets/icons/DocumentHighlightIcon';
import { PrimaryButton } from 'components/common/buttons';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { UPDATE_REQUEST_ERROR } from 'store/actions';
import LogisticsArtifactForm from '../LogisticsArtifactForm';
import { createLogisticsSubmissionModel } from '../LogisticsArtifactForm/createLogisticsSubmissionModel';
import LogisticsArtifactSummary from '../LogisticsArtifactSummary';
import {
  SelectLogisticsArtifactButtonContainer,
  SelectLogisticsArtifactListContainer,
  SelectLogisticsArtifactListItemContainer,
  SelectLogisticsArtifactListItemIconContainer,
  SelectLogisticsArtifactTextContainer,
  SelectLogisticsArtifactTextTitle,
  SelectLogisticsArtifactTitle,
  SelectLogisticsArtifactTitleContainer,
  SelectLogisticsDialogWrapper
} from './styled';

interface ILogisticSelection {
  title: string;
  id: string;
  description?: string;
  complete: boolean;
}

export interface SelectLogisticsArtifactProps {
  identifier: string;
  invoiceOAProgram: ProgramConfig | null;
  status?: string;
  isDiscrepant?: boolean;
  closeDialogHandler: () => void;
  isCompleteHandler: () => void;
}

const SelectLogisticsArtifact: FC<SelectLogisticsArtifactProps> = (props) => {
  const dispatch = useDispatch();
  const [logisticsDocuments, setLogisticsDocuments] = useState<ILogisticSelection[]>([]);
  const [selectedArtifact, setSelectedArtifact] = useState<ILogisticsRuleSubtemplate | null>(null);
  const [logisticsSubtemplates, setLogisticsSubtemplates] = useState<ILogisticsRuleSubtemplate[]>(
    []
  );
  const [showSummaryView, setShowSummaryView] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<ILogisticsRuleSubtemplate | null>(null);
  const [uploadingLogistics, setUploadingLogistics] = useState<boolean>(false);
  const [logisticsComplete, setLogisticsComplete] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const {
    allProgramsWithDetails
  }: {
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    return () => {
      if (logisticsComplete) props.isCompleteHandler();
      setSelectedArtifact(null);
      setLogisticsSubtemplates([]);
      setLogisticsDocuments([]);
      setShowSummaryView(false);
      setSummaryData(null);
      setStatusMessage('');
      setFile(null);
    };
  }, []);

  useEffect(() => {
    getLogisticsRules();
  }, [allProgramsWithDetails]);

  useEffect(() => {
    if (logisticsDocuments.length > 0) checkDocumentsCompleted();
  }, [logisticsDocuments]);

  const orchestrationApi = new OrchestrationApi(store);
  const assetApi = new AssetsApi(store);

  const filterMandatoryData: (
    data: ILogisticsRuleSubtemplateField[]
  ) => ILogisticsRuleSubtemplateField[] = (data) => {
    // eslint-disable-next-line array-callback-return
    return data.filter((f) => {
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length === 0)
        return f;
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length > 0) {
        f.nestedFields = filterMandatoryData(f.nestedFields);
        return f;
      }
    });
  };

  const getLogisticsRules: () => any = () => {
    if (!props.invoiceOAProgram)
      throw Error('THERE ARE OA PROGRAM RULES FOR LOGISTICS CONFIGURED IN THE PROGRAM');

    const clone: ProgramConfig = structuredClone<ProgramConfig>(props.invoiceOAProgram);
    const logisticsRulesForInvoice: ILogisticsRule | undefined = clone.rules?.find(
      (rule) => rule.type === ProgramRuleTypeEnum.LOGISTIC_TEMPLATE_CONFIGURATION_SUB
    )?.value;

    if (!logisticsRulesForInvoice)
      throw Error('THERE ARE NO RULE FOR LOGISTICS CONFIGURED IN THE PROGRAM');

    const newObj = Object.assign({}, logisticsRulesForInvoice);
    newObj.subTemplates = logisticsRulesForInvoice.subTemplates.map((s) => {
      s.fields = filterMandatoryData(s.fields);
      return s;
    });

    console.log(newObj);

    setLogisticsSubtemplates(newObj.subTemplates);

    const mappedOptions: ILogisticSelection[] = logisticsRulesForInvoice.subTemplates.map((t) => ({
      title: t.name,
      id: t.templateReference,
      complete: false
    }));
    setLogisticsDocuments(mappedOptions);
  };

  const logisticsArtifactSelection: (id: string) => void = (id) => {
    const matchedSubtemplate: ILogisticsRuleSubtemplate | undefined = logisticsSubtemplates.find(
      (s) => s.templateReference === id
    );
    if (!matchedSubtemplate) throw Error('THERE ARE NOT MATCHED TEMPLATE REFERENCES');

    setSelectedArtifact(matchedSubtemplate);
  };

  const nextClickHandler: (templateRef: string, data: ILogisticsRuleSubtemplateField[]) => void = (
    templateRef,
    data
  ) => {
    const matchedRule = logisticsSubtemplates.find((d) => d.templateReference === templateRef);

    if (!matchedRule) return;

    matchedRule.fields = data;

    setSummaryData(matchedRule);
    setShowSummaryView(true);
  };

  const renderLogisticsSelection: () => JSX.Element = () => (
    <>
      {logisticsDocuments.map(({ title, id, complete }) => (
        <SelectLogisticsArtifactListItemContainer
          key={title}
          onClick={() => (complete ? {} : logisticsArtifactSelection(id))}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
            <SelectLogisticsArtifactListItemIconContainer>
              <DocumentHighlightIcon />
            </SelectLogisticsArtifactListItemIconContainer>
            <SelectLogisticsArtifactTextContainer>
              <SelectLogisticsArtifactTextTitle>{title}</SelectLogisticsArtifactTextTitle>
            </SelectLogisticsArtifactTextContainer>
          </div>
          <SelectLogisticsArtifactListItemIconContainer>
            {complete ? <CheckIcon height="20" width="20" /> : <RightArrowBoldIcon />}
          </SelectLogisticsArtifactListItemIconContainer>
        </SelectLogisticsArtifactListItemContainer>
      ))}
    </>
  );

  const summaryNextClickHandler: (templateRef: string) => void = async (templateRef) => {
    const mutArr = [...logisticsDocuments];
    const matchedDoc: ILogisticSelection | undefined = mutArr.find((doc) => doc.id === templateRef);
    if (!matchedDoc || !selectedArtifact) return;

    setUploadingLogistics(true);
    const model: LedgerLogistics = createLogisticsSubmissionModel(
      selectedArtifact.fields || [],
      templateRef,
      logisticsSubtemplates
    );

    setStatusMessage('Uploading logistic document...');

    model.lineItems = model.lineItems?.map((l) => {
      if (l.quantity) l.quantity = parseFloat(l.quantity.toString().split(',').join(''));

      return l;
    });

    const response: TaskDetails<ITaskData<ITaskDataItem>> = await orchestrationApi.uploadLogistic(
      props.invoiceOAProgram?.id || '',
      model
    );

    // indexed at zero as only 1 asset is upload at a time currently
    const { assetId, status } = response.data.items[0];

    if (!assetId) {
      dispatch({ type: UPDATE_REQUEST_ERROR, payload: 'Failed to upload the document.' });
      setStatusMessage('');
      setUploadingLogistics(false);
      return;
    }

    if (status !== 'VALID') {
      const error = response.data.items[0].reasons[0];
      dispatch({ type: UPDATE_REQUEST_ERROR, payload: error });
      setStatusMessage('');
      setUploadingLogistics(false);
      return;
    }

    //[0] indexed as it's single program only
    const isAccepted =
      response.data.items[0].programStatuses.filter((s) => s.status === 'ACCEPTED').length === 1;

    if (!isAccepted) {
      const error = response.data.items[0].programStatuses[0].reasons[0];
      dispatch({ type: UPDATE_REQUEST_ERROR, payload: error });
      setStatusMessage('');
      setUploadingLogistics(false);
      return;
    }

    let documentUploadResponse;

    if (file) {
      setStatusMessage('Uploading file...');
      documentUploadResponse = await assetApi.uploadLogisticAttachment(file, assetId);
      if (!documentUploadResponse) {
        setUploadingLogistics(false);
        setStatusMessage('There was a problem uploading your file!');
      } else {
        setStatusMessage('Successfully uploaded logistic document and file!');
      }
    }

    matchedDoc.complete = true;
    setLogisticsDocuments(mutArr);
    setTimeout(() => {
      setUploadingLogistics(false);
      setSelectedArtifact(null);
      setShowSummaryView(false);
      setStatusMessage('');
    }, 2500);
  };

  const checkDocumentsCompleted: () => void = () => {
    if (logisticsDocuments.filter((d) => d.complete === true).length === logisticsDocuments.length)
      setLogisticsComplete(true);
  };

  const summaryBackClickHandler: () => void = () => {
    setShowSummaryView(false);
    setSummaryData(null);
  };

  const renderView: () => JSX.Element = () => {
    if (selectedArtifact && showSummaryView && summaryData)
      return (
        <LogisticsArtifactSummary
          {...props}
          logisticsSubtemplate={summaryData}
          summaryNextClickHandler={summaryNextClickHandler}
          summaryBackClickHandler={summaryBackClickHandler}
          uploading={uploadingLogistics}
          progressMessage={statusMessage}
        />
      );
    if (selectedArtifact && !showSummaryView)
      return (
        <>
          <LogisticsArtifactForm
            {...selectedArtifact}
            nextClickhandler={(templateRef, data) => nextClickHandler(templateRef, data)}
            fileUploadHandler={(file) => setFile(file)}
            backClickHandler={() => {
              setSelectedArtifact(null);
              setFile(null);
            }}
          />
        </>
      );
    return (
      <SelectLogisticsDialogWrapper>
        <SelectLogisticsArtifactTitleContainer>
          <SelectLogisticsArtifactTitle>
            Select the type of logistics document you want to generate
          </SelectLogisticsArtifactTitle>
        </SelectLogisticsArtifactTitleContainer>
        <SelectLogisticsArtifactListContainer>
          {renderLogisticsSelection()}
        </SelectLogisticsArtifactListContainer>
        <SelectLogisticsArtifactButtonContainer>
          <PrimaryButton
            width="100"
            clickHandler={props.closeDialogHandler}
            disabled={!logisticsComplete}
            text="Close and Complete"
          />
        </SelectLogisticsArtifactButtonContainer>
      </SelectLogisticsDialogWrapper>
    );
  };

  return renderView();
};

export default SelectLogisticsArtifact;
