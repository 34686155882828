import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { FC } from 'react';
import {
  SelectContainer,
  SelectLabel,
  SelectMessage,
  SelectStyled,
  SelectPlaceholder
} from './styled';
import ResetIcon from 'assets/icons/ResetIcon';
import { themeColors } from 'assets/theme/style';

export interface SelectMenuItem {
  name: string;
  value: any;
}

export interface ISelectProps {
  menuItems: SelectMenuItem[];
  label?: string;
  selectID?: string;
  message?: string;
  errorMessage?: string;
  changeHandler?: (event: SelectChangeEvent<unknown>) => void;
  register?: any;
  value?: any;
  disabled?: boolean;
  background?: string;
  closeHandler?: () => void;
  testingTag?: string;
  placeholder?: string;
  tooltipText?: string;
  resetClickHandler?: () => void;
}

const Select: FC<ISelectProps> = ({
  background,
  menuItems,
  label,
  selectID,
  message,
  errorMessage,
  changeHandler,
  register,
  value,
  disabled,
  closeHandler,
  testingTag,
  placeholder,
  tooltipText,
  resetClickHandler
}) => {
  const setValidValue: () => string = () =>
    menuItems.map((item) => item.value).indexOf(value) > -1 ? value : '';
  return (
    <SelectContainer data-automation-id={testingTag}>
      {label && (
        <SelectLabel data-automation-id="select-p-label">
          {label}
          {tooltipText && <IconTooltip tooltipText={tooltipText} />}
        </SelectLabel>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px'
        }}
      >
        <SelectStyled
          id={selectID}
          background={background}
          onChange={(e) => changeHandler && changeHandler(e)}
          value={setValidValue()}
          inputProps={register}
          disabled={disabled}
          defaultValue=""
          onClose={closeHandler}
          data-automation-id="select-div-listbox"
          data-testid="sp-select-listbox"
          renderValue={() => {
            if (setValidValue().length === 0) {
              return <SelectPlaceholder>{placeholder || ''}</SelectPlaceholder>;
            }
            return setValidValue();
          }}
          displayEmpty={true}
        >
          {menuItems.map((item, i) => (
            <MenuItem key={i} value={item.value} data-automation-id="select-li-item">
              {item.name}
            </MenuItem>
          ))}
        </SelectStyled>
        {resetClickHandler && (
          <div style={{ cursor: 'pointer' }} onClick={resetClickHandler}>
            {value && <ResetIcon color={themeColors.text.light.muted} />}
          </div>
        )}
      </div>
      <SelectMessage errorMessage={errorMessage} data-automation-id="select-span-error-message">
        {message || errorMessage}
      </SelectMessage>
    </SelectContainer>
  );
};

export default Select;
