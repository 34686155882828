import styled from 'styled-components';
import { typography } from 'assets/theme/typography';
import Skeleton from '@mui/material/Skeleton';
import { themeColors } from 'assets/theme/style';

export const BarChartWrapper= styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.bg.light.surface};
  gap: 24px;
  padding: 16px;
  border-radius: 16px;
  width: calc(100% - 384px);
`;

export const BarChartTitle = styled.h3``;

export const BarChartInfo = styled.span`
  ${typography.bodyXSmall};
`;

export const SkeletonContainer= styled.div`
  display: flex;
  gap: 5px;
  height: 100%;
  width: 100%;
  align-items: baseline;
`;

export const SkeletonHorizontalStyled= styled(Skeleton)`
  width: 100%!important;
  height: 5px!important;
`;

export const SkeletonVerticalStyled= styled(Skeleton)`
  width: 5px!important;
  height: 100%!important;
`;

export const BarChartContainer= styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

