import styled from 'styled-components';
import { typography } from 'assets/theme/typography';

interface IBarContainerProps {
  height: string;
}
export const BarContainer = styled.div<IBarContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({height}) => height};
  width: calc(100% - 64px);
  margin-bottom: 24px;
`;

export const ResetIconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: flex-end;
  align-items: center;
`;

export const ResetIconTitle = styled.p`
  ${typography.bodySemiBold};
  margin: 0;
`;
