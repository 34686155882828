import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerDetailAttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

export const LedgerDetailAttachmentsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LedgerDetailAttachmentsCount = styled.p`
  ${typography.fontHindRegular};
  color: ${themeColors.text.light.subtle};
  font-size: ${fontSizes.small};
`;
