import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const DotIcon: FC<IconSvgProps> = ({
  color = themeColors.text.light.subtle,
  height = '6px',
  width = '6px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'translateY(1px)' }}
    data-automation-id="icon-svg-dot-icon"
  >
    <circle cx="3" cy="3" r="3" fill={color} />
  </svg>
);

export default DotIcon;
