import { ODataLedgerPayments } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ODataLedgerPaymentItem } from 'api/interfaces/ledger/ledgerPaymentItems.interface';
import PaymentApi from 'api/ledger/payment.api';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  FETCH_PAYMENTS_BY_IDS,
  FETCH_PAYMENTS_BY_IDS_FAILURE,
  FETCH_PAYMENTS_BY_IDS_SUCCESS,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchPaymentLedgerPaymentItems: (
  action: any
) => Promise<ODataLedgerPaymentItem> = async (action) => {
  const paymentApi = new PaymentApi(store);
  const res = await paymentApi.getPaymentsLedgerPaymentItem(action.payload);
  return res.data;
};

export const fetchPaymentsByIds: (action: any) => Promise<ODataLedgerPayments> = async (action) => {
  const paymentApi = new PaymentApi(store);
  const res = await paymentApi.getPaymentsByPaymentIds(action.payload);
  return res.data;
};

//===================================================
//                      EPICS
//===================================================

const getPaymentLedgerPaymentItems$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_PAYMENT_LEDGER_PAYMENT_ITEMS),
    switchMap((action) =>
      from(fetchPaymentLedgerPaymentItems(action)).pipe(
        map((payload) => ({ type: GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS, payload })),
        catchError((error) =>
          of({ type: GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE, payload: error.message })
        )
      )
    )
  );

const getPaymentsByIds$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PAYMENTS_BY_IDS),
    switchMap((action) =>
      from(fetchPaymentsByIds(action)).pipe(
        map((payload) => ({ type: FETCH_PAYMENTS_BY_IDS_SUCCESS, payload })),
        catchError((error) => of({ type: FETCH_PAYMENTS_BY_IDS_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(getPaymentLedgerPaymentItems$, getPaymentsByIds$);
