import RecentActivity from 'components/home/RecentActivity';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { FC } from 'react';

const DEFAULT_NUM_ACTIVITIES_TO_DISPLAY = 10;

const AllRecentActivities: FC = () => {
  return (
    <LayoutViewContainer data-testid="sp-recent-activity-container">
      <RecentActivity limit={DEFAULT_NUM_ACTIVITIES_TO_DISPLAY} testingTagPage="recent-activity" />
    </LayoutViewContainer>
  );
};

export default AllRecentActivities;
