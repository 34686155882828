import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  height?: string;
  width?: string;
}

export const CreateInvoiceDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CreateInvoiceDialogDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 40px;
`;

export const CreateInvoiceDialogDocumentsDetailUploadContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const CreateInvoiceDialogLogisticDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 40px;
`;

export const CreateInvoiceDialogLogisticDetailInfo = styled.h4``;

export const CreateInvoiceDialogDocumentsDetailInfo = styled.h4``;

export const CreateInvoiceDialogLogisticDetailRow = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const CreateInvoiceDialogInvoiceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const CreateInvoiceDialogInvoiceDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CreateInvoiceDialogInvoiceDetailCommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
`;

export const CreateInvoiceDialogInvoiceDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const CreateInvoiceDialogInvoiceDetailTitle = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body};
`;

export const CreateInvoiceDialogLogisticDetailSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const CreateInvoiceDialogTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  gap: 8px;
`;

export const CreateInvoiceDialogTitle = styled.h2`
  color: ${themeColors.text.primary};
`;

export const CreateInvoiceDialogDescription = styled.p``;

export const CreateInvoiceDialogLineItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`;

export const CreateInvoiceDialogLineItemsErrorMessage = styled.p`
  color: ${themeColors.text.error};
  ${typography.captionRegular};
  margin-bottom: 6px;
`;

export const CreateInvoiceDialogTitleRegular = styled.p``;

export const CreateInvoiceDialogTitleBold = styled.p`
  ${typography.bodySemiBold};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const CreateInvoiceDialogTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupedTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    ${typography.tableHeader};
    background-color: ${themeColors.bg.light.surfaceRaised};
    border-right: 1px solid ${themeColors.border.light.surfaceRaised};
    height: 64px;
    border-bottom: none;
    text-transform: uppercase;
    vertical-align: middle;
    padding-left: 8px;

    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  th:last-child {
    border-right: none;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
    font-size: ${fontSizes.small};

    .MuiOutlinedInput-input {
      font-size: ${fontSizes.small};
      height: 34px;
    }
  }
  .text-align-right {
    text-align: right;
  }

  .more-detail {
    padding: '0px 8px 16px 8px';
  }

  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin: 0;
  }
`;

export const CreateInvoiceDialogSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const CreateInvoiceDialogSummaryTitle = styled.h4`
  color: ${themeColors.text.light.body};
`;

export const CreateInvoiceDialogSummaryInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;

  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const CreateInvoiceDialogActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const CreateInvoiceDialogTableActionButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || ''};
  font-family: 'Source Sans Pro', sans-serif;
  margin: 8px 0 24px 0;
`;

export const CreateInvoiceDialogActionButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || ''};
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 24px;
`;

export const CreateInvoiceDialogActionButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CreateInvoiceDialogLineItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const CreateInvoiceDialogResetIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${themeColors.text.light.secondary};
`;

export const CreateInvoiceDialogShowMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CreateInvoiceDialogDescriptionDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const CreateInvoiceDialogDescriptionColumnDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
`;

export const CreateInvoiceDialogDescriptionDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 16px;
`;
