import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const FundingRequestDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  gap: ${isMobile() ? '16px' : '0'};
`;

export const FundingRequestDetailsSegment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  width: 100%;
`;

export const FundingRequestDetailsH5 = styled.h5`
  margin: 0 0 8px 0;
`;

export const FundingRequestDetailsTextContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const FundingRequestDetailsText = styled.p`
  margin: 0;
  ${typography.bodyRegular};
`;
export const FundingRequestDetailsTextBold = styled.p`
  margin: 0;
  ${typography.bodyBold};
`;

export const FundingRequestDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

export const FundingRequestDetailsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
