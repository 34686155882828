import Menu from '@mui/material/Menu';
import { themeColors, boxShadow } from 'assets/theme/style';
import { Link } from 'react-router-dom';
import { fontSizes, typography, fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';

interface NavigationProfileProps {
  active: boolean;
}
interface NavigationLinkProps {
  disabled: boolean;
}

export const NavigationProfileContainer = styled.div<NavigationProfileProps>`
  .MuiButton-root {
    background-color: ${({ active }) => (active ? `${themeColors.text.light.body}15` : 'transparent')};
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes.regular};
    padding: 0;
    text-transform: none;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ active }) => (active ? `${themeColors.text.light.body}15` : 'transparent')};
      box-shadow: none;
      transition: none;
    }
  }
`;

export const MenuItemLink = styled(Link)<NavigationLinkProps>`
  text-decoration: none;
  color: ${themeColors.text.light.body};
  ${({ disabled }) => (disabled ? 'pointer-events: none; cursor: default;' : '')};
  font-size: ${fontSizes.regular} !important;
`;

export const LogoutText = styled.p`
  display: flex;
  align-items: center;
  padding: 5px 0;
  height: 34px;
  color: ${themeColors.text.light.body};
  cursor: pointer;
  ${typography.bodyRegular};
  letter-spacing: 0.00938em;
  line-height: 1.5;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: ${boxShadow};
    border-radius: 3px;
    margin-top: 0;
    min-width: 222px;
  }
  .MuiMenu-list {
    padding: 8px;
    width: 100%;
  }
  .MuiMenuItem-gutters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 0;
    &:hover {
      background-color: transparent;
    }
  }
  .MuiSvgIcon-root {
    color: ${themeColors.text.light.subtle};
  }
`;
export const ProfileContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileNameSurname = styled.p`
  ${typography.fontHindSemiBoldRegular};
`;

export const ProfileTitle = styled.p`
  ${typography.fontHindSmall};
`;

export const ProfileProgramSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  max-width: 20rem;
`;

export const ProfileProgramText = styled.p`
  ${typography.bodyRegular};
  margin-bottom: 8px;
`;
