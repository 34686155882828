import { Checkbox } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface SelectableItemContainerProps {
  index: number;
  length: number;
  isLast: boolean;
}

export const SelectableItemContainer = styled.div<SelectableItemContainerProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: ${({ index, length, isLast }) => (length - 1 === index && !isLast ? '16px' : '')};
`;

export const SelectableItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const SelectableItemSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: 8px;
`;

export const SelectableItemLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0 !important;
  align-items: flex-start !important;
`;

export const SelectableItemTextBold = styled.p`
  ${typography.bodySemiBold};
  margin: 0;
`;

export const SelectableItemTextCaption = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.subtle};
  margin: 0;
`;

export const SelectableItemIconContainer = styled.div`
  display: flex;
  padding-top: 2px;
  margin-right: 8px;
`;

export const SelectableItemData = styled.h5`
  display: flex;
  margin: 0;
`;

export const SelectableItemValue = styled.h5`
  margin: 0;
`;
