/* eslint-disable react-hooks/exhaustive-deps */
import BaseCard from 'components/common/cards/BaseCard';
import { FC } from 'react';
import {
  BrowserExtensionDialogContainer,
  BrowserExtensionDialogTitleWrapper,
  BrowserExtensionDialogTitle,
  BrowserExtensionDialogWrapper,
  BrowserExtensionDialogContentTitle,
  BrowserExtensionDialogContentText,
  BrowserExtensionDialogContentImage,
  BrowserExtensionDialogButtonContainer,
  BrowserExtensionDialogButtonContentContainer,
  BrowserExtensionDialogButtonText,
  BrowserExtensionDialogButtonWrapper
} from './styled';
import BrowserExtensionImg from 'assets/images/browserExtension.png';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { useDispatch } from 'react-redux';
import { UPDATE_ACCOUNTING_CONNECT } from 'store/actions';

export interface IBrowserExtensionDialogProps {
  closeHandler: () => void;
}

const BrowserExtensionDialog: FC<IBrowserExtensionDialogProps> = ({ closeHandler }) => {
  const dispatch = useDispatch();

  const downloadClickHandler: () => void = () => {
    dispatch({ type: UPDATE_ACCOUNTING_CONNECT, payload: true });
    closeHandler();
  };

  return (
    <BrowserExtensionDialogWrapper>
      <BrowserExtensionDialogContainer>
        <BrowserExtensionDialogTitleWrapper>
          <BrowserExtensionDialogTitle data-automation-id="connect-accounting-software-dialog-h2-page-title">
            Congratulations, your accounting software is now linked
          </BrowserExtensionDialogTitle>
        </BrowserExtensionDialogTitleWrapper>
        <BaseCard>
          <BrowserExtensionDialogContentTitle>
            Try our Chrome browser extension!
          </BrowserExtensionDialogContentTitle>
          <BrowserExtensionDialogContentText>
            For the best experience download our Chrome extension to get immediate access to
            financing and notifcations directly within Xero
          </BrowserExtensionDialogContentText>
          <BrowserExtensionDialogContentImage src={BrowserExtensionImg} />
          <BrowserExtensionDialogButtonWrapper>
            <BrowserExtensionDialogButtonContainer>
              <PrimaryButton
                clickHandler={closeHandler}
                padding="16px"
                height="48px"
                testingTag="home-button-oa-overview-link-to-invoices"
                text="Skip"
              />
            </BrowserExtensionDialogButtonContainer>
            <BrowserExtensionDialogButtonContainer>
              <PrimaryButton
                clickHandler={downloadClickHandler}
                testingTag="home-button-oa-overview-link-to-invoices"
                text="Download"
              />
            </BrowserExtensionDialogButtonContainer>
          </BrowserExtensionDialogButtonWrapper>
        </BaseCard>
      </BrowserExtensionDialogContainer>
    </BrowserExtensionDialogWrapper>
  );
};

export default BrowserExtensionDialog;
