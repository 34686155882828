import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const TimeFillIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '32',
  width = '32'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35ZM21 10.8333C21 10.281 20.5523 9.83333 20 9.83333C19.4477 9.83333 19 10.281 19 10.8333V19.75C19 20.4404 19.5596 21 20.25 21H25.8333C26.3856 21 26.8333 20.5523 26.8333 20C26.8333 19.4477 26.3856 19 25.8333 19H21V10.8333Z"
      fill={color}
    />
  </svg>
);

export default TimeFillIcon;
