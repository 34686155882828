import { SelectMenuItem } from 'components/forms/inputs/Select';
import {
  QueryComparators,
  QueryDateComparators,
  QuerySimpleComparators
} from 'lib/enums/query/queryComparators.enum';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';

export class QueryRow {
  constructor(
    public index: number,
    public fieldValue: string,
    public queryValue: string | QueryDateRange,
    public componentType: keyof typeof QueryComponents | '',
    public comparator:
      | keyof typeof QueryComparators
      | keyof typeof QueryDateComparators
      | keyof typeof QuerySimpleComparators
      | '',
    public valueOptions?: QueryDropdownOption[],
    public fromPickerOpen?: boolean,
    public toPickerOpen?: boolean
  ) {}
}

export class QueryItem {
  constructor(
    public filterName: string,
    public filterValue: string,
    public componentType: keyof typeof QueryComponents,
    public options?: QueryDropdownOption[],
    public defaultValue?: string | QueryDateRange,
    public display?: boolean,
    public defaultComparator?:
      | keyof typeof QueryComparators
      | keyof typeof QueryDateComparators
      | keyof typeof QuerySimpleComparators
      | ''
  ) {}
}

export class QueryDropdownOption implements SelectMenuItem {
  constructor(
    public name: string,
    public value:
      | keyof typeof QueryComparators
      | keyof typeof QueryDateComparators
      | keyof typeof QuerySimpleComparators
      | string
      | boolean
  ) {}
}

export class QueryDateRange {
  constructor(public to: Date | '', public from: Date | '') {}
}
