import Menu from '@mui/material/Menu';
import { boxShadow, themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import styled from 'styled-components';

export const NavigationProfileContainer = styled.div`
  .MuiButton-root {
    background-color: ${themeColors.bg.light.page};
    font-family: 'roc-grotesk', sans-serif;
    color: ${themeColors.text.light.body};
    padding: 0;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;
    min-width: auto !important;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      transition: none;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: ${boxShadow};
    background-color: ${themeColors.bg.light.page};
    border-radius: 16px;
    margin-top: 0;
    min-width: 150px;
  }
  .MuiMenu-list {
    padding: 8x;
    width: 100%;
  }
  .MuiMenuItem-gutters {
    display: flex;
    width: 100%;
    color: ${themeColors.text.light.body};
    justify-content: space-between;
    font-size: ${fontSizes.small}!important;
    &:hover {
      background-color: transparent;
    }
  }
  .MuiSvgIcon-root {
    color: ${themeColors.text.light.body};
  }
`;

export const MenuItemWrapper = styled.div``;
