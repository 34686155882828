import { LedgerPaymentItem } from 'api/interfaces/ledger/ledgerPaymentItems.interface';
import {
  FETCH_PAYMENTS_BY_IDS,
  FETCH_PAYMENTS_BY_IDS_FAILURE,
  FETCH_PAYMENTS_BY_IDS_SUCCESS,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE,
  GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS,
  SET_PAYMENT_ITEMS
} from 'store/actions';
import { ReducerAction, StateBase } from '.';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';

interface PaymentReducerStateProps extends StateBase {
  payments: LedgerPayment[];
  paymentItems: LedgerPaymentItem[];
  paymentLedgerPaymentItems: LedgerPaymentItem[];
}

const DEFAULT_STATE: PaymentReducerStateProps = {
  paymentItems: [],
  payments: [],
  paymentLedgerPaymentItems: [],
  error: '',
  loading: false
};

const paymentReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case SET_PAYMENT_ITEMS:
      return {
        ...state,
        paymentItems: payload
      };
    case GET_PAYMENT_LEDGER_PAYMENT_ITEMS:
      return {
        ...state,
        loading: true
      };
    case GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS:
      return {
        ...state,
        paymentLedgerPaymentItems: payload.value,
        loading: false
      };
    case GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case FETCH_PAYMENTS_BY_IDS:
      return {
        ...state,
        loading: true
      };
    case FETCH_PAYMENTS_BY_IDS_SUCCESS:
      return {
        ...state,
        payments: payload.value,
        loading: false
      };
    case FETCH_PAYMENTS_BY_IDS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
};

export { paymentReducer };
export type { PaymentReducerStateProps };
