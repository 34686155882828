/* eslint-disable react-hooks/exhaustive-deps */
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Autocomplete from 'components/forms/inputs/Autocomplete';
import Select from 'components/forms/inputs/Select';
import TextInput from 'components/forms/inputs/TextInput';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {CompanyDetailsRegisteredAddressContainer, FormStyled} from '../CompanyDetailsEdit/styled';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import EntityApi from 'api/entity/entity.api';
import {
  EntityAddress,
  EntityBankAccount,
  EntityBankAccountCreate,
  EntityProofOfAccount
} from 'api/interfaces/entity/entity.interface';
import {
  IOnboardingEntity,
  IOnboardingEntityEvent
} from 'api/interfaces/onboarding/onboarding.interface';
import ClearIcon from 'assets/icons/ClearIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import Notification from 'components/Notification';
import { RestOfWorldBankDetails, USBankDetails } from 'components/bank-accounts';
import AUBankDetails from 'components/bank-accounts/AUBankDetails';
import UKBankDetails from 'components/bank-accounts/UKBankDetails';
import { BankAccountContainer } from 'components/bank-accounts/styled';
import Uploader from 'components/common/Uploader';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { popularCountries } from 'lib/constants/popularCountries';
import {
  OnboardingActionStatusEnum,
  OnboardingActionsEnum
} from 'lib/enums/onboarding/onboarding.enum';
import _ from 'lodash';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { ADD_BANK_ACCOUNT, TRIGGER_SNACKBAR, UPDATE_BANK_ACCOUNT } from 'store/actions';
import styled from 'styled-components';
import { BankAccount } from 'utils/interfaces/bank-account/bankAccount.interface';
import { AutocompleteItem } from 'utils/interfaces/input/autocomplete.interface';
import {
  CheckboxStyled,
  CompanyBankAccountDetailsActionsContainer,
  CompanyBankAccountDetailsButtonText,
  CompanyBankAccountDetailsButtonWrapper,
  CompanyBankAccountDetailsContainer,
  CompanyBankAccountDetailsTitle,
  CompanyBankAccountDetailsWrapper,
  CompanyBankDetailsActionsContainer,
  CompanyBankDetailsButtonText,
  CompanyBankDetailsButtonWrapper,
  CompanyBankDetailsCaption,
  CompanyBankDetailsCheckboxMessage,
  CompanyBankDetailsDefaultRow,
  CompanyBankDetailsLoadingContainer,
  CompanyBankDetailsMoreOptionsTitle,
  CompanyBankDetailsSubHeading,
  CompanyBankDetailsVerifyingText,
  ErrorMessage,
  UploaderContainer,
  AccordionStyled
} from './styled';
import { useAuth } from 'react-oidc-context';

interface CompanyBankAccountDetailsProps {
  closeHandler: (edited?: boolean) => void;
  saveHandler: () => void;
  isOnboarding?: boolean;
}

export const SelectStyled = styled(Select)`
  width: 100%;
  margin: 0 auto;

  .MuiSelect-select {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: ${themeColors.white};
    color: ${themeColors.text.light.body};
    padding-right: 8px !important;
    padding: 8px 2px;
  }
`;

const CompanyBankAccountDetails: FC<CompanyBankAccountDetailsProps> = ({
  closeHandler,
  saveHandler,
  isOnboarding
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState<boolean>(false);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [accountNameModified, setAccountNameModified] = useState<boolean>(false);
  const [bankDetailsModified, setBankDetailsModified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bankAccountSubmitted, setBankAccountSubmitted] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [proofOfAccount, setProofOfAccount] = useState<Blob[]>([]);
  const [proofOfAccountRemoved, setProofOfAccountRemoved] = useState<boolean>(false);
  const [countryItems, setCountryItems] = useState<AutocompleteItem[]>([]);
  const [currencyItems, setCurrencyItems] = useState<AutocompleteItem[]>([]);
  const [editFileChanged, setEditFileChanged] = useState<boolean>(false);
  const [availableBankAccounts, setAvailableBankAccounts] = useState<any[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>(null);
  const [onboardingBeneficiaryVerified, setOnboardingBeneficiaryVerified] = useState<boolean>(true);
  const [onboardingBeneficiaryAddressVerified, setOnboardingBeneficiaryAddressVerified] = useState<boolean>(true);

  const [proofOfName, setProofOfName] = useState<boolean>(false);
  const [proofOfAddress, setProofOfAddress] = useState<boolean>(false);
  const [proofOfAccountNumber, setProofOfAccountNumber] = useState<boolean>(false);
  const [proofOfAccountRouting, setProofOfAccountRouting] = useState<boolean>(false);


  interface BankAccountMixin{
    actualRegisteredName?: string
    actualRegisteredAddress?: Partial<EntityAddress>
  }

  const [bankAccountRawDataModel, setBankAccountRawDataModel] = useState<BankAccount&BankAccountMixin>({
    bankName: '',
    accountName: '',
    countryOfBank: '',
    currency: '',
    accountNumber: '',
    routingNumber: '',
    iban: '',
    sortCode: '',
    swiftOrBic: '',
    bsbNumber: '',
    defaultCurrency: false
  });
  const [bankAccountDataModel, setBankAccountDataModel] = useState<BankAccount&BankAccountMixin>({
    bankName: '',
    accountName: '',
    countryOfBank: '',
    currency: '',
    accountNumber: '',
    routingNumber: '',
    iban: '',
    sortCode: '',
    swiftOrBic: '',
    bsbNumber: '',
    defaultCurrency: false
  });
  const programId: string | null = useSelector((state: RootStateOrAny) => state.app.programID);
  const registeredName: string | null = useSelector(
    (state: RootStateOrAny) => state.program.programOwnerDetails?.contact?.registeredName
  );
  const onboardingRegisteredName: string | null = useSelector(
    (state: RootStateOrAny) =>
      state.app.programOwnerDetails?.contact?.registeredName ||
      state.program.programOwnerDetails?.contact?.registeredName
  );
  const onboardingRegisteredAddress: EntityAddress | null = useSelector(
      (state: RootStateOrAny) => state.app.programOwnerDetails?.registeredAddress ||
          state.program.programOwnerDetails?.registeredAddress
  );
  const identifier: string | null = useSelector(
    (state: RootStateOrAny) => state.program.programOwnerDetails?.identifier.x500
  );
  const editBankAccount: EntityBankAccount | null = useSelector(
    (state: RootStateOrAny) => state.program.bankAccountForEdit
  );
  const proofOfAccountMetadata: EntityProofOfAccount | null = useSelector(
    (state: RootStateOrAny) => state.program.proofOfAccount
  );
  const savingBankAccount: boolean = useSelector((state: RootStateOrAny) => state.program.loading);
  const programError: boolean = useSelector((state: RootStateOrAny) => state.program.error);
  const { countries, currencies, allCurrencies } = useSelector(
    (state: RootStateOrAny) => state.app
  );
  const { error }: { error: string } = useSelector((state: RootStateOrAny) => state.program);
  const { onboardingDataByProgram }: { onboardingDataByProgram: IOnboardingEntityEvent } =
    useSelector((state: RootStateOrAny) => state.onboarding);

  const {
    legalEntityInit
  }: {
    legalEntityInit: IOnboardingEntity | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<BankAccount>({ mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => {
    if (isOnboarding) {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
      retrieveAvailableBankAccounts();
    }
    if (editBankAccount) {
      updateEditDataModel();
    }
  }, []);

  useEffect(() => {
    if (bankAccountDataModel.countryOfBank) clearErrors('countryOfBank');
    if (bankAccountDataModel.countryOfBank === 'GB') {
      clearErrors(['iban', 'swiftOrBic', 'routingNumber', 'bsbNumber']);
    }
    if (bankAccountDataModel.countryOfBank === 'US') {
      clearErrors(['iban', 'swiftOrBic', 'sortCode', 'bsbNumber']);
    }
    if (bankAccountDataModel.countryOfBank === 'AU') {
      clearErrors(['iban', 'swiftOrBic', 'sortCode', 'routingNumber']);
    }
    if (
      bankAccountDataModel.countryOfBank !== 'AU' &&
      bankAccountDataModel.countryOfBank !== 'GB' &&
      bankAccountDataModel.countryOfBank !== 'US'
    ) {
      clearErrors(['sortCode', 'routingNumber', 'bsbNumber']);
    }
  }, [bankAccountDataModel.countryOfBank]);

  useEffect(() => {
    if (bankAccountDataModel.accountName) clearErrors('accountName');
    if (bankAccountDataModel.bankName) clearErrors('bankName');
    if (bankAccountDataModel.currency) clearErrors('currency');
    if (bankAccountDataModel.accountNumber) clearErrors('accountNumber');
    if (bankAccountDataModel.iban) clearErrors('iban');
    if (bankAccountDataModel.swiftOrBic) clearErrors('swiftOrBic');
    if (bankAccountDataModel.routingNumber) clearErrors('routingNumber');
    if (bankAccountDataModel.bsbNumber) clearErrors('bsbNumber');
    if (bankAccountDataModel.sortCode) clearErrors('sortCode');
  }, [bankAccountDataModel]);

  useEffect(() => {
    if (programError) {
      setBankAccountSubmitted(false);
      return;
    }
    if (bankAccountSubmitted && !savingBankAccount)
      editBankAccount ? closeHandler(true) : saveHandler();
  }, [bankAccountSubmitted, savingBankAccount, programError]);

  const resetBankAccount: () => void = () =>
    setBankAccountDataModel({
      bankName: '',
      accountName: '',
      countryOfBank: '',
      currency: '',
      accountNumber: '',
      routingNumber: '',
      iban: '',
      sortCode: '',
      swiftOrBic: '',
      bsbNumber: '',
      defaultCurrency: false,
      actualRegisteredName:undefined,
    });

  const retrieveAvailableBankAccounts: () => void = async () => {
    const response =
      (await new EntityApi(store).getAllOwnerBankAccounts(auth.user?.access_token || ''))?.data
        ?.content || [];

    const groupedByAccountName = _.groupBy(response, 'accountName');

    const latestVersionOfAccounts = Object.keys(groupedByAccountName).map(
      (a) => groupedByAccountName[a].sort((a, b) => (a.version < b.version ? 1 : -1))[0]
    );

    setAvailableBankAccounts(latestVersionOfAccounts);
  };

  const updateEditDataModel: () => void = () => {
    const {
      bankName,
      bankCountry,
      currencies,
      accountNumber,
      routingNumber,
      iban,
      sortCode,
      accountName,
      swiftOrBic,
      bsbNumber
    } = JSON.parse(JSON.stringify(editBankAccount)) as EntityBankAccount;

    setBankAccountDataModel({
      bankName: bankName || '',
      accountName: accountName || '',
      countryOfBank: bankCountry || '',
      currency: currencies[0]?.currency || '',
      accountNumber: accountNumber || '',
      routingNumber: routingNumber || '',
      iban: iban || '',
      sortCode: sortCode || '',
      swiftOrBic: swiftOrBic || '',
      bsbNumber: bsbNumber || '',
      defaultCurrency: !!currencies[0]?.isDefault,
    });

    setBankAccountRawDataModel({
      bankName: bankName || '',
      accountName: accountName || '',
      countryOfBank: bankCountry || '',
      currency: currencies[0]?.currency || '',
      accountNumber: accountNumber || '',
      routingNumber: routingNumber || '',
      iban: iban || '',
      sortCode: sortCode || '',
      swiftOrBic: swiftOrBic || '',
      bsbNumber: bsbNumber || '',
      defaultCurrency: !!currencies[0]?.isDefault,
    });
  };

  const proofOfAccountSaveJSX: JSX.Element = (
    <>
      <CompanyBankDetailsActionsContainer>
        <CompanyBankDetailsButtonWrapper>
          <PrimaryButton
            type="submit"
            disabled={!proofOfAccount.length}
            testingTag="company-bank-details-button-proof-of-account-save"
            text="Save"
          />
        </CompanyBankDetailsButtonWrapper>
        <CompanyBankDetailsButtonWrapper>
          <SecondaryButton
            text="Cancel"
            clickHandler={(e: any) => {
              e.preventDefault();
              closeHandler();
              setProofOfAccount([]);
              setProofOfAccountRemoved(false);
            }}
            testingTag="company-bank-details-button-proof-of-account-cancel"
          />
        </CompanyBankDetailsButtonWrapper>
      </CompanyBankDetailsActionsContainer>
    </>
  );

  const fileDropHandler = (acceptedFiles: File[]) => {
    setProofOfAccount(acceptedFiles);
    setEditFileChanged(true);
  };

  useEffect(() => {
    if (countries) countryDropdownMenuItems();
  }, [countries]);

  useEffect(() => {
    if (currencies) currencyDropdownMenuItems();
  }, [currencies]);

  const onSubmit: SubmitHandler<BankAccount> = () => {
    if (!editBankAccount && proofOfAccount.length === 0) return;
    isOnboarding ? submitAccountOnboardingForCreate() : submitAccountFormForCreate();
  };

  const renderWarning: () => JSX.Element = () => (
    <Notification
      color={themeColors.text.warning}
      icon={<WarningIcon color={themeColors.icon.warning} />}
      title="Account verification failed"
      description="Please check the account details you provided are correct and try again."
    />
  );

  const renderFailure: () => JSX.Element = () => (
    <>
      <Notification
        noShadow
        color={themeColors.text.warning}
        icon={<WarningIcon color={themeColors.icon.warning} />}
        title="Account verification failed"
        description="We were unable to verify your bank account details. Please upload a proof of account below to help us verify the account."
      />
      <CompanyBankDetailsSubHeading>Proof of account</CompanyBankDetailsSubHeading>
      <CompanyBankDetailsCaption>
        Please attach one of the following documents that can be used to validate your bank account
        is genuine: bank statement, a letter from your bank or a voided cheque. The document must be
        no older than 3 months.
      </CompanyBankDetailsCaption>
      <Uploader
        fileDropHandler={fileDropHandler}
        singlePreviewMode
        limitBytes={500000}
        testingTagPage="company-bank-account-details"
      />
      {proofOfAccountSaveJSX}
    </>
  );

  const submitForVerification: (
    data: FormData,
    onboardingDataStructure?: FormData,
    proof?: Blob
  ) => any = (data, onboardingDataStructure, proof) => {
    if (!isOnboarding) {
      editBankAccount
        ? dispatch({
            type: UPDATE_BANK_ACCOUNT,
            payload: {
              data,
              id: editBankAccount.bankAccountId,
              overrideDefaultCurrency: !editBankAccount.currencies[0].isDefault,
              identifier,
              programId
            }
          })
        : dispatch({
            type: ADD_BANK_ACCOUNT,
            payload: {
              data,
              isDefault: bankAccountDataModel.defaultCurrency,
              identifier,
              programId
            }
          });
    } else {
      const artifactId = onboardingDataByProgram.spec.actions.bankAccount.config.artifactIDs[0];
      if (!legalEntityInit) return;
      dispatch({
        type: ADD_BANK_ACCOUNT,
        payload: {
          data,
          isDefault: true,
          identifier,
          programId: onboardingDataByProgram.programId,
          isOnboarding: true,
          onboardingActionEventData: {
            actionName: OnboardingActionsEnum.bankDetails,
            data: new OnboardingActionEventPut('', '', OnboardingActionStatusEnum.completed),
            programId: onboardingDataByProgram.programId
          },
          onboardingBankAccountData: {
            artifactId,
            data: onboardingDataStructure,
            programId: onboardingDataByProgram.programId
          },
          proof,
          existingBankAccount: selectedBankAccount
        }
      });
    }
    setBankAccountSubmitted(true);
  };

  const submitAccountFormForCreate: () => void = () => {
    if (!registeredName) throw Error('No registered name available');
    const {
      bankName,
      countryOfBank,
      currency,
      accountNumber,
      iban,
      sortCode,
      routingNumber,
      accountName,
      bsbNumber,
      swiftOrBic,
      defaultCurrency
    } = bankAccountDataModel;
    const createModel: EntityBankAccountCreate = {
      bankName,
      bankCountry: countryOfBank,
      currencies: [{ currency, isDefault: !!defaultCurrency }],
      beneficiaryName: registeredName
    };
    const formData = new FormData();

    switch (bankAccountDataModel.countryOfBank) {
      case 'GB':
        createModel['accountNumber'] = accountNumber;
        createModel['sortCode'] = sortCode;
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${registeredName}`;
        if (!!iban) createModel['iban'] = iban;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        formData.append('accountDto', JSON.stringify(createModel));
        if (proofOfAccount.length > 0) formData.append('file', proofOfAccount[0]);
        submitForVerification(formData);
        break;
      case 'AU':
        createModel['accountNumber'] = accountNumber;
        createModel['bsbNumber'] = bsbNumber;
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${registeredName}`;
        if (!!iban) createModel['iban'] = iban;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        formData.append('accountDto', JSON.stringify(createModel));
        if (proofOfAccount.length > 0) formData.append('file', proofOfAccount[0]);
        submitForVerification(formData);
        break;
      case 'US':
        createModel['accountNumber'] = accountNumber;
        createModel['routingNumber'] = routingNumber;
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${registeredName}`;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        formData.append('accountDto', JSON.stringify(createModel));
        if (proofOfAccount.length > 0) formData.append('file', proofOfAccount[0]);
        submitForVerification(formData);
        break;
      default:
        if (!iban) createModel['accountNumber'] = accountNumber;
        if (!accountNumber) createModel['iban'] = iban;
        if (iban && accountNumber) {
          createModel['accountNumber'] = accountNumber;
          createModel['iban'] = iban;
        }
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${registeredName}`;
        createModel['swiftOrBic'] = swiftOrBic;
        formData.append('accountDto', JSON.stringify(createModel));
        if (proofOfAccount.length > 0) formData.append('file', proofOfAccount[0]);
        submitForVerification(formData);
        break;
    }
  };

  const submitAccountOnboardingForCreate: () => void = () => {
    if (!onboardingRegisteredName) throw Error('No registered name available');
    const {
      bankName,
      countryOfBank,
      currency,
      accountNumber,
      iban,
      sortCode,
      routingNumber,
      accountName,
      bsbNumber,
      swiftOrBic,
      defaultCurrency,
      actualRegisteredName,
      actualRegisteredAddress
    } = bankAccountDataModel;
    const createModel: EntityBankAccountCreate&BankAccountMixin = {
      bankName,
      bankCountry: countryOfBank,
      currencies: [{ currency, isDefault: !!defaultCurrency }],
      beneficiaryName: onboardingRegisteredName
    };
    const onboardingFormData = new FormData();
    const formData = new FormData();

    switch (bankAccountDataModel.countryOfBank) {
      case 'GB':
        createModel['accountNumber'] = accountNumber;
        createModel['sortCode'] = sortCode;
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${onboardingRegisteredName}`;
        if (!!iban) createModel['iban'] = iban;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        break;
      case 'AU':
        createModel['accountNumber'] = accountNumber;
        createModel['bsbNumber'] = bsbNumber;
        createModel['accountName'] =
          accountName || `${bankName}-${accountNumber}-${sortCode}-${onboardingRegisteredName}`;
        if (!!iban) createModel['iban'] = iban;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        break;
      case 'US':
        createModel['accountNumber'] = accountNumber;
        createModel['routingNumber'] = routingNumber;
        createModel['accountName'] =
          accountName ||
          `${bankName}-${accountNumber}-${routingNumber}-${onboardingRegisteredName}`;
        if (!!swiftOrBic) createModel['swiftOrBic'] = swiftOrBic;
        break;
      default:
        if (!iban) createModel['accountNumber'] = accountNumber;
        if (!accountNumber) createModel['iban'] = iban;
        createModel['accountName'] =
          accountName || `${bankName}-${iban}-${onboardingRegisteredName}`;
        createModel['swiftOrBic'] = swiftOrBic;
        break;
    }

    formData.append('accountDto', JSON.stringify(createModel));

    if (!onboardingBeneficiaryVerified){
        createModel['actualRegisteredName'] = actualRegisteredName||'';
    }
    if (!onboardingBeneficiaryAddressVerified){
        createModel['actualRegisteredAddress'] = actualRegisteredAddress||{};
    }
    onboardingFormData.append('json', JSON.stringify(createModel));

    submitForVerification(formData, onboardingFormData, proofOfAccount[0]);
  };

  const renderActionButtons: () => JSX.Element = () => (
    <CompanyBankAccountDetailsActionsContainer>
      <CompanyBankAccountDetailsButtonWrapper>
        {/* {isOnboarding && (
          <PrimaryButton
            type="submit"
            testingTag="company-bank-details-button-proof-of-account-save"
          >
            <CompanyBankDetailsButtonText data-automation-id="company-bank-details-p-button-proof-of-account-save-text">
              Save
            </CompanyBankDetailsButtonText>
          </PrimaryButton>
        )} */}
        {/* LEAVE THIS HERE!! WILL BE REQUIRED WITH BNYM!! */}
        {/* {bankDetailsModified && !isOnboarding && (
          <PrimaryButton
            clickHandler={verifyClickHandler}
            type="submit"
            disabled={!!Object.keys(errors).length}
            testingTag="company-bank-account-details-button-verify-account"
          >
            <CompanyBankAccountDetailsButtonText data-automation-id="company-bank-account-details-p-button-verify-account-text">
              Verify account
            </CompanyBankAccountDetailsButtonText>
          </PrimaryButton>
        )}
        {!bankDetailsModified && !isOnboarding && (
          <PrimaryButton
            type="submit"
            disabled={
              JSON.stringify(bankAccountRawDataModel) === JSON.stringify(bankAccountDataModel)
            }
            testingTag="company-bank-account-details-button-account-name-save"
          >
            <CompanyBankAccountDetailsButtonText data-automation-id="company-bank-account-details-p-button-account-name-save-text">
              Save
            </CompanyBankAccountDetailsButtonText>
          </PrimaryButton>
        )} */}
        <PrimaryButton
          disabled={Object.keys(errors).length > 0 || !bankDetailsModified || !proofOfName|| !proofOfAddress|| !proofOfAccountNumber|| !proofOfAccountRouting}
          type="submit"
          testingTag="company-bank-account-details-button-account-name-save"
          text="Save"
        />
      </CompanyBankAccountDetailsButtonWrapper>
      <CompanyBankDetailsButtonWrapper>
        <SecondaryButton
          text="Cancel"
          clickHandler={(e: any) => {
            e.preventDefault();
            closeHandler();
            setProofOfAccount([]);
            setProofOfAccountRemoved(false);
          }}
          testingTag="company-bank-details-button-cancel"
        />
      </CompanyBankDetailsButtonWrapper>
    </CompanyBankAccountDetailsActionsContainer>
  );

  const renderLoading: () => JSX.Element = () => (
    <CompanyBankDetailsLoadingContainer>
      <LoaderInPage />
      <CompanyBankDetailsVerifyingText>Verifying your account...</CompanyBankDetailsVerifyingText>
    </CompanyBankDetailsLoadingContainer>
  );

  const renderSaving: () => JSX.Element = () => (
    <CompanyBankDetailsLoadingContainer>
      <LoaderFullPage detail="Adding bank account" />
    </CompanyBankDetailsLoadingContainer>
  );

  const renderVerified: () => JSX.Element = () => (
    <>
      <Notification
        noShadow
        color={themeColors.text.success}
        icon={<ApproveIcon color={themeColors.icon.success} />}
        title="Account verified successfully"
        description="The account details you provided match the details held for this account."
      />
      {proofOfAccountSaveJSX}
    </>
  );

  const renderCurrencyDropdownMenu: () => JSX.Element = () =>
    currencies &&
    allCurrencies &&
    Object.keys(isOnboarding ? allCurrencies : currencies).length > 10 ? (
      <Autocomplete
        label="Currency"
        placeholder="Select currency"
        options={currencyItems}
        value={bankAccountDataModel.currency}
        changeHandler={(value: any) => updateDataModel('currency', value)}
        register={register('currency', { required: bankAccountDataModel.currency ? false : true })}
        errorMessage={errors.currency && 'Currency is a required field'}
        disabled={loading || verified}
        testingTag="company-bank-details-div-autocomplete-currency"
      />
    ) : (
      <Select
        label="Currency"
        tooltipText="Supplier Pay only supports bank accounts in US Dollars at this time"
        placeholder="Select currency"
        menuItems={currencyItems}
        value={bankAccountDataModel.currency}
        changeHandler={(event: any) => updateDataModel('currency', event.target.value)}
        register={register('currency', { required: bankAccountDataModel.currency ? false : true })}
        errorMessage={errors.currency && 'Currency is a required field'}
        disabled={loading || verified}
        testingTag="company-bank-details-div-select-currency"
      />
    );

  const countryDropdownMenuItems: () => void = () => {
    if (!countries) return [];
    const mappedItems: AutocompleteItem[] = Object.keys(countries).map((key) =>
      popularCountries.includes(key)
        ? { name: countries[key], value: key, group: 'Quick selection' }
        : { name: countries[key], value: key, group: 'All countries' }
    );
    setCountryItems(mappedItems);
  };

  const currencyDropdownMenuItems: () => void = () => {
    if (!currencies) return [];
    const mappedItems: AutocompleteItem[] = Object.keys(
      isOnboarding ? allCurrencies : currencies
    ).map((key) =>
      popularCountries.includes(key)
        ? {
            name: `${key} - ${isOnboarding ? allCurrencies[key] : currencies[key]}`,
            value: key,
            group: 'Quick selection'
          }
        : {
            name: `${key} - ${isOnboarding ? allCurrencies[key] : currencies[key]}`,
            value: key,
            group: 'All currencies'
          }
    );
    setCurrencyItems(mappedItems);
  };

  const updateDataModel: (key: string, value: string | boolean) => void = (key, value) => {
    setBankAccountDataModel({ ...bankAccountDataModel, [key]: value });
  };
  const updateDataModelAddress: (key: string, value: string | boolean) => void = (key, value) => {
    setBankAccountDataModel({
      ...bankAccountDataModel,
      actualRegisteredAddress:{
        ...bankAccountDataModel.actualRegisteredAddress || {},
        [key]: value
      }
    });
  };

  useEffect(() => {
    if (JSON.stringify(bankAccountRawDataModel) === JSON.stringify(bankAccountDataModel)) {
      setAccountNameModified(false);
      setBankDetailsModified(false);
    } else {
      bankAccountDataModel?.accountName !== bankAccountRawDataModel?.accountName
        ? setAccountNameModified(true)
        : setAccountNameModified(false);
      if (
        bankAccountRawDataModel?.bankName !== bankAccountDataModel?.bankName ||
        bankAccountRawDataModel?.countryOfBank !== bankAccountDataModel?.countryOfBank ||
        bankAccountRawDataModel?.currency !== bankAccountDataModel?.currency ||
        bankAccountRawDataModel?.accountNumber !== bankAccountDataModel?.accountNumber ||
        bankAccountRawDataModel?.routingNumber !== bankAccountDataModel?.routingNumber ||
        bankAccountRawDataModel?.iban !== bankAccountDataModel?.iban ||
        bankAccountRawDataModel?.sortCode !== bankAccountDataModel?.sortCode
      ) {
        setBankDetailsModified(true);
      } else {
        setBankDetailsModified(false);
      }
    }
  }, [bankAccountDataModel, bankAccountRawDataModel]);

  const mapSelectedAccountToAccountModel: (data: any) => void = (data) => {
    const newObj: BankAccount = { ...data };

    const currency = data.currencies.find((c: any) => c.isDefault)?.currency;

    newObj.currency = currency || data.currencies[0].currency;

    //true because we explicitly search by default currency which is expected on an active bank account
    newObj.defaultCurrency = true;

    newObj.countryOfBank = data.bankCountry;

    setBankAccountDataModel(newObj);
  };

  return savingBankAccount ? (
    renderSaving()
  ) : (
    <CompanyBankAccountDetailsWrapper>
      <CompanyBankAccountDetailsContainer>
        <CompanyBankAccountDetailsTitle data-automation-id="company-bank-account-details-h2-page-title">
          {editBankAccount ? 'Edit' : 'Add a new'} bank account
        </CompanyBankAccountDetailsTitle>
        {!savingBankAccount && (
          <FormStyled
            id="bank-account-form"
            onSubmit={(event) => {
              event.preventDefault();
              setFormSubmitted(true);
              handleSubmit(onSubmit)();
            }}
          >
            <CompanyBankDetailsCaption>
              You need to provide one of the 'proof of account' documents to complete the setup of
              this bank account on Supplier Pay.
            </CompanyBankDetailsCaption>

            {/* <CompanyBankDetailsSubHeading>
            Selecting an existing account will prefill the information for you.
          </CompanyBankDetailsSubHeading> */}
            {error && <p style={{ color: themeColors.text.error }}>*{error}</p>}
            {isOnboarding && availableBankAccounts.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  justifyContent: 'space-between'
                }}
              >
                <Select
                  label="Choose from an existing bank account"
                  value={selectedBankAccount?.accountName || ''}
                  menuItems={availableBankAccounts.map((b) => ({
                    name: b.accountName,
                    value: b.accountName
                  }))}
                  changeHandler={(event) => {
                    const matchedAcc = availableBankAccounts.find(
                      (acc) => acc.accountName === event.target.value
                    );
                    if (!matchedAcc) return;
                    mapSelectedAccountToAccountModel(matchedAcc);
                    setSelectedBankAccount(matchedAcc);
                  }}
                  resetClickHandler={() => {
                    setSelectedBankAccount(null);
                    resetBankAccount();
                  }}
                />
              </div>
            )}

            <TextInput
              label="Your unique bank account name"
              tooltipText="Give this account a name you'll know it by on Supplier Pay, e.g. Supplier Pay account, Main receivables account, Checking account etc."
              placeholder="Provide a unique name for this account"
              defaultValue={bankAccountDataModel.accountName}
              disabled={Boolean(selectedBankAccount)}
              changeHandler={(event) => updateDataModel('accountName', event.target.value)}
              register={register('accountName', {
                required: bankAccountDataModel.accountName ? false : true
              })}
              errorMessage={
                !!Object.keys(errors).length
                  ? (bankAccountDataModel.accountName === '' || errors.accountName) &&
                    'Account name is a required field'
                  : ''
              }
              testingTagPage="bank-account-details-account-name"
            />
            <TextInput
              label="Bank name"
              tooltipText="Name of the bank where this account is held with"
              placeholder="Name of your bank, e.g. 'Bank of America, SMBC, Barclays, Revolut'"
              register={register('bankName', {
                required: bankAccountDataModel.bankName.length > 0 ? false : true
              })}
              errorMessage={
                !!Object.keys(errors).length
                  ? (bankAccountDataModel.bankName === '' || errors.bankName) &&
                    'Bank name is a required field'
                  : ''
              }
              readonly={verified}
              disabled={Boolean(selectedBankAccount) || loading || verified}
              defaultValue={bankAccountDataModel.bankName}
              changeHandler={(event) => updateDataModel('bankName', event.target.value)}
              testingTagPage="bank-account-details-bank-name"
            />
            <Autocomplete
              label="Country of bank"
              placeholder="Type a country"
              options={countryItems}
              value={bankAccountDataModel.countryOfBank}
              changeHandler={(value: any) => updateDataModel('countryOfBank', value)}
              register={register('countryOfBank', {
                required: bankAccountDataModel.countryOfBank ? false : true
              })}
              errorMessage={
                !!Object.keys(errors).length
                  ? (bankAccountDataModel.countryOfBank === '' || errors.countryOfBank) &&
                    'Country of bank is a required field'
                  : ''
              }
              disabled={Boolean(selectedBankAccount) || loading || verified}
            />
            {renderCurrencyDropdownMenu()}
            <TextInput
              label="Bank account beneficiary name"
              defaultValue={isOnboarding ? onboardingRegisteredName || '' : registeredName || ''}
              tooltipText="The beneficiary name refers to the entity designated on the bank documentation to receive funds from your customer.  The beneficiary name on the bank documentation must be an EXACT match to the Kanexa platform to ensure a successful payment.  For Example; ABC, Ltd on the platform and ABC Limited on the bank documentation will fail. The platform must read ABC Limited."
              disabled
              testingTagPage="bank-account-details-account-holder"
            />
            {isOnboarding && <>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={onboardingBeneficiaryVerified}
                    onChange={(event) => setOnboardingBeneficiaryVerified(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    data-automation-id="company-bank-details-span-checkbox-beneficiary-verified"
                />
                <CompanyBankDetailsCheckboxMessage
                    data-automation-id="company-bank-details-span-checkbox-beneficiary-verified-checkbox-message"
                >
                  {'Does the above bank account beneficiary name match EXACTLY with the account name that will be paid into?'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
              {!onboardingBeneficiaryVerified &&
              <TextInput
                  label="ACTUAL Bank account beneficiary name"
                  defaultValue={bankAccountDataModel.actualRegisteredName||onboardingRegisteredName||''}
                  changeHandler={(event) => updateDataModel('actualRegisteredName', event.target.value)}
                  testingTagPage="bank-account-details-actual-account-holder"
              />
              }
                <CompanyDetailsRegisteredAddressContainer>
              <TextInput
                  disabled
                  defaultValue={onboardingRegisteredAddress?.line1|| ''}
                  label="Bank account beneficiary address"
                  testingTagPage="bank-account-details-account-holder"
              />
              <TextInput
                  disabled
                  defaultValue={onboardingRegisteredAddress?.line2|| ''}
                  testingTagPage="bank-account-details-account-holder"
              />
              <TextInput
                  disabled
                  defaultValue={onboardingRegisteredAddress?.city|| ''}
                  testingTagPage="bank-account-details-account-holder"
              />
              <TextInput
                  disabled
                  defaultValue={onboardingRegisteredAddress?.state|| ''}
                  testingTagPage="bank-account-details-account-holder"
              />
                  <Autocomplete
                      disabled
                      options={countryItems}
                      value={onboardingRegisteredAddress?.countryIso|| bankAccountDataModel.countryOfBank}
                  />
                  <TextInput
                      disabled
                      defaultValue={onboardingRegisteredAddress?.zipCode|| ''}
                      testingTagPage="bank-account-details-account-holder"
                  />
            </CompanyDetailsRegisteredAddressContainer>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={onboardingBeneficiaryAddressVerified}
                    onChange={(event) => setOnboardingBeneficiaryAddressVerified(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    data-automation-id="company-bank-details-span-checkbox-beneficiary-address-verified"
                />
                <CompanyBankDetailsCheckboxMessage
                    data-automation-id="company-bank-details-span-checkbox-beneficiary-address-verified-checkbox-message"
                >
                  {'Does the above bank account beneficiary address match EXACTLY the address for the bank account beneficiary name?'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
              {!onboardingBeneficiaryAddressVerified &&
                <>
                  <CompanyBankDetailsSubHeading>ACTUAL Bank account beneficiary address</CompanyBankDetailsSubHeading>
                  <TextInput
                      label="Line 1"
                      defaultValue={bankAccountDataModel.actualRegisteredAddress?.line1|| ''}
                      changeHandler={(event) => updateDataModelAddress('line1', event.target.value)}
                      testingTagPage="bank-account-details-account-holder"
                  />
                  <TextInput
                      label="Line 2"
                      defaultValue={bankAccountDataModel.actualRegisteredAddress?.line2|| ''}
                      changeHandler={(event) => updateDataModelAddress('line2', event.target.value)}
                      testingTagPage="bank-account-details-account-holder"
                  />
                  <TextInput
                      label="City"
                      defaultValue={bankAccountDataModel.actualRegisteredAddress?.city|| ''}
                      changeHandler={(event) => updateDataModelAddress('city', event.target.value)}
                      testingTagPage="bank-account-details-account-holder"
                  />
                  <TextInput
                      label="State"
                      defaultValue={bankAccountDataModel.actualRegisteredAddress?.state||''}
                      changeHandler={(event) => updateDataModelAddress('state', event.target.value)}
                      testingTagPage="bank-account-details-account-holder"
                  />

                  <Autocomplete
                      label="Country"
                      options={countryItems}
                      value={bankAccountDataModel.actualRegisteredAddress?.countryIso||onboardingRegisteredAddress?.countryIso|| bankAccountDataModel.countryOfBank}
                      changeHandler={(value: any) => updateDataModelAddress('countryIso', value)}
                  />
                  <TextInput
                      defaultValue={bankAccountDataModel.actualRegisteredAddress?.zipCode|| ''}
                      changeHandler={(event) => updateDataModelAddress('zipCode', event.target.value)}
                      label="Zip code"
                      testingTagPage="bank-account-details-account-holder"
                  />
                </>
              }
            </>
            }
            {bankAccountDataModel.countryOfBank === 'US' && (
              <USBankDetails
                accountNumber={{
                  register: register('accountNumber', {
                    required: bankAccountDataModel.accountNumber ? false : true
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.accountNumber === '' || errors.accountNumber) &&
                      'Account number is a required field'
                    : '',
                  value: bankAccountDataModel.accountNumber,
                  changeHandler: (event) => {
                    const value = event.target.value.replace(/[^0-9]+/i, '');
                    updateDataModel('accountNumber', value);
                  }
                }}
                routingNumber={{
                  register: register('routingNumber', {
                    required: bankAccountDataModel.routingNumber ? false : true
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.routingNumber === '' || errors.routingNumber) &&
                      'Routing number is a required field'
                    : '',
                  value: bankAccountDataModel.routingNumber,
                  changeHandler: (event) => updateDataModel('routingNumber', event.target.value)
                }}
              />
            )}
            {bankAccountDataModel.countryOfBank === 'GB' && (
              <UKBankDetails
                accountNumber={{
                  register: register('accountNumber', {
                    required: bankAccountDataModel.accountNumber ? false : true
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.accountNumber === '' || errors.accountNumber) &&
                      'Account number is a required field'
                    : '',
                  value: bankAccountDataModel.accountNumber,
                  changeHandler: (event) => {
                    const value = event.target.value.replace(/[^0-9]+/i, '');
                    updateDataModel('accountNumber', value);
                  }
                }}
                sortCode={{
                  register: register('sortCode', {
                    required: bankAccountDataModel.sortCode ? false : true,
                    pattern: /(\d{2}-?){2}\d{2}/
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.sortCode === '' || errors.sortCode) &&
                      'Sort code is a required field'
                    : '',
                  value: bankAccountDataModel.sortCode,
                  changeHandler: (event) => updateDataModel('sortCode', event.target.value)
                }}
              />
            )}
            {bankAccountDataModel.countryOfBank === 'AU' && (
              <AUBankDetails
                accountNumber={{
                  register: register('accountNumber', {
                    required: bankAccountDataModel.accountNumber ? false : true
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.accountNumber === '' || errors.accountNumber) &&
                      'Account number is a required field'
                    : '',
                  value: bankAccountDataModel.accountNumber,
                  changeHandler: (event) => {
                    const value = event.target.value.replace(/[^0-9]+/i, '');
                    updateDataModel('accountNumber', value);
                  }
                }}
                bsbCode={{
                  register: register('bsbNumber', {
                    required: bankAccountDataModel.bsbNumber ? false : true,
                    pattern: /(\d{2}-?){2}\d{2}/
                  }),
                  disabled: Boolean(selectedBankAccount) || loading || verified,
                  errorMessage: !!Object.keys(errors).length
                    ? (bankAccountDataModel.bsbNumber === '' || errors.bsbNumber) &&
                      'BSB code is a required field'
                    : '',
                  value: bankAccountDataModel.bsbNumber,
                  changeHandler: (event) => updateDataModel('bsbNumber', event.target.value)
                }}
              />
            )}
            {bankAccountDataModel.countryOfBank !== 'US' &&
              bankAccountDataModel.countryOfBank !== 'GB' &&
              bankAccountDataModel.countryOfBank !== 'AU' && (
                <RestOfWorldBankDetails
                  accountNumber={{
                    disabled: Boolean(selectedBankAccount) || loading || verified,
                    register: register('accountNumber', {
                      required: bankAccountDataModel.iban === '' ? true : false
                    }),
                    value: bankAccountDataModel.accountNumber,
                    errorMessage: !!Object.keys(errors).length
                      ? bankAccountDataModel.iban === ''
                        ? (bankAccountDataModel.accountNumber === '' || errors.accountNumber) &&
                          'Account number is a required field if IBAN is not provided'
                        : ''
                      : '',
                    changeHandler: (event) => {
                      const value = event.target.value.replace(/[^0-9]+/i, '');
                      updateDataModel('accountNumber', value);
                    }
                  }}
                  iban={{
                    disabled: Boolean(selectedBankAccount) || loading || verified,
                    register: register('iban', {
                      required: bankAccountDataModel.accountNumber === '' ? true : false
                    }),
                    value: bankAccountDataModel.iban,
                    errorMessage: !!Object.keys(errors).length
                      ? bankAccountDataModel.accountNumber === ''
                        ? (bankAccountDataModel.iban === '' || errors.iban) &&
                          'IBAN is a required field if Account number is not provided'
                        : ''
                      : '',
                    changeHandler: (event) => updateDataModel('iban', event.target.value)
                  }}
                  swiftOrBic={{
                    disabled: Boolean(selectedBankAccount) || loading || verified,
                    register: register('swiftOrBic', { required: true }),
                    value: bankAccountDataModel.swiftOrBic || '',
                    errorMessage: !!Object.keys(errors).length
                      ? (bankAccountDataModel.swiftOrBic === '' || errors.swiftOrBic) &&
                        'BIC/SWIFT is a required field'
                      : '',
                    changeHandler: (event) => updateDataModel('swiftOrBic', event.target.value)
                  }}
                />
              )}
            <CompanyBankDetailsDefaultRow>
              <CheckboxStyled
                checked={bankAccountDataModel.defaultCurrency}
                onChange={(event) => updateDataModel('defaultCurrency', event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={selectedBankAccount || editBankAccount?.currencies[0]?.isDefault}
                data-automation-id="company-bank-details-span-checkbox-default-currency"
              />
              <CompanyBankDetailsCheckboxMessage
                disabled={editBankAccount?.currencies[0].isDefault}
                data-automation-id="company-bank-details-p-checkbox-message"
              >
                {editBankAccount?.currencies[0].isDefault
                  ? 'Already set as the default account for all payments in this currency'
                  : 'Set as the default account for all payments in this currency'}
              </CompanyBankDetailsCheckboxMessage>
            </CompanyBankDetailsDefaultRow>
            <CompanyBankDetailsSubHeading>Proof of account</CompanyBankDetailsSubHeading>
            {editBankAccount &&
              proofOfAccountMetadata &&
              !proofOfAccountRemoved &&
              !proofOfAccount.length &&
              !isOnboarding && (
                <>
                  <CompanyBankDetailsCaption>
                    Please attach one of the following documents that can be used to validate your
                    bank account is genuine: bank statement, a letter from your bank or a voided
                    cheque. The document must be no older than 3 months.
                  </CompanyBankDetailsCaption>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 8px'
                    }}
                  >
                    <p
                      style={{
                        display: 'flex',
                        fontWeight: 600,
                        cursor: 'pointer',
                        color: themeColors.text.primary
                      }}
                    >
                      {proofOfAccountMetadata.name}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        zIndex: 10000
                      }}
                      onClick={() => setProofOfAccountRemoved(true)}
                    >
                      <ClearIcon />
                    </div>
                  </div>
                </>
              )}
            {!editBankAccount && !proofOfAccountRemoved && !proofOfAccount.length && (
              <>
                <CompanyBankDetailsCaption>
                  Please attach one of the following documents that can be used to validate your
                  bank account is genuine: bank statement, a letter from your bank or a voided
                  cheque. The document must be no older than 3 months.
                </CompanyBankDetailsCaption>
                <CompanyBankDetailsCaption>
                  The proof of account must be documentation from your bank that shows the beneficiary bank account name,
                  beneficiary address, bank account number and SWIFT, BIC or Routing Number.
                  To ensure payments have no errors the documentation must match the Kanexa system EXACTLY.
                </CompanyBankDetailsCaption>
              </>
            )}
            {editBankAccount &&
              (proofOfAccountRemoved || !proofOfAccountMetadata) &&
              !isOnboarding && (
                <Uploader
                  borderColor={themeColors.border.warning}
                  fileDropHandler={fileDropHandler}
                  singlePreviewMode
                  limitBytes={5000000}
                  testingTagPage="company-bank-account-details"
                />
              )}
            {!editBankAccount && (
              <UploaderContainer>
                <Uploader
                  borderColor={themeColors.border.warning}
                  fileDropHandler={fileDropHandler}
                  singlePreviewMode
                  preloadedBlob={proofOfAccount[0]}
                  limitBytes={5000000}
                  testingTagPage="company-bank-account-details"
                />
                {proofOfAccount.length === 0 && formSubmitted && (
                  <ErrorMessage>Proof of account upload is required</ErrorMessage>
                )}
              </UploaderContainer>
            )}
            {(bankAccountDataModel.countryOfBank === 'US' ||
              bankAccountDataModel.countryOfBank === 'GB' ||
              bankAccountDataModel.countryOfBank === 'AU') && (
              <>
                <AccordionStyled
                  disableGutters
                  defaultExpanded={false}
                  style={{ marginTop: '-24px' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    data-automation-id="ledger-detail-view-div-accordion-summary"
                  >
                    <CompanyBankDetailsMoreOptionsTitle>
                      More options
                    </CompanyBankDetailsMoreOptionsTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {bankAccountDataModel.countryOfBank !== 'US' && (
                      <BankAccountContainer data-testid="sp-bank-account-details">
                        <TextInput
                          label="IBAN"
                          placeholder="i.e. GB15ABCD10203012345678"
                          tooltipText="If you’re based in Europe, your International Bank Account Number (IBAN) will help banks based in other countries to identify yours for payment. It will be a combination of letters and numbers up to 34 characters long"
                          register={register('iban', { required: false })}
                          defaultValue={bankAccountDataModel.iban}
                          readonly={verified}
                          disabled={loading || verified}
                          changeHandler={(event) => updateDataModel('iban', event.target.value)}
                          testingTagPage="bank-account-details"
                        />
                      </BankAccountContainer>
                    )}
                    <BankAccountContainer data-testid="sp-bank-account-details">
                      <TextInput
                        label="BIC/SWIFT"
                        placeholder="8 - 11 character code"
                        tooltipText="A SWIFT code, also known as a BIC number, is used by banks to process international transactions ensuring they arrive at the correct bank and branch. This code is made up of letters and will be 8 or 11 characters long"
                        register={register('swiftOrBic', { required: false })}
                        defaultValue={bankAccountDataModel.swiftOrBic}
                        readonly={verified}
                        disabled={loading || verified}
                        changeHandler={(event) => updateDataModel('swiftOrBic', event.target.value)}
                        testingTagPage="bank-account-details"
                      />
                    </BankAccountContainer>
                  </AccordionDetails>
                </AccordionStyled>
              </>
            )}
            {!verified && !loading && failedCount === 1 && !isOnboarding && renderWarning()}
            {!verified && !loading && failedCount === 2 && !isOnboarding && renderFailure()}
            {!loading && !verified && failedCount !== 2 && !isOnboarding && renderActionButtons()}

            {!loading && isOnboarding &&
            <>
              <CompanyBankDetailsSubHeading>Does your supporting documentation contain (select all that apply):</CompanyBankDetailsSubHeading>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={proofOfName}
                    onChange={(event) => setProofOfName(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <CompanyBankDetailsCheckboxMessage>
                  {'Beneficiary Bank Account Name'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={proofOfAddress}
                    onChange={(event) => setProofOfAddress(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <CompanyBankDetailsCheckboxMessage>
                  {'Beneficiary Bank Account Address'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={proofOfAccountNumber}
                    onChange={(event) => setProofOfAccountNumber(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <CompanyBankDetailsCheckboxMessage>
                  {'Bank Account Number'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
              <CompanyBankDetailsDefaultRow>
                <CheckboxStyled
                    checked={proofOfAccountRouting}
                    onChange={(event) => setProofOfAccountRouting(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <CompanyBankDetailsCheckboxMessage>
                  {'Bank Account Routing, BIC/SWIFT, Sort Code or BSB Code'}
                </CompanyBankDetailsCheckboxMessage>
              </CompanyBankDetailsDefaultRow>
            </>

            }
            {
              !loading && isOnboarding && renderActionButtons()

            }
            {loading && !isOnboarding && renderLoading()}
            {verified && !isOnboarding && renderVerified()}
          </FormStyled>
        )}
      </CompanyBankAccountDetailsContainer>
    </CompanyBankAccountDetailsWrapper>
  );
};

export default CompanyBankAccountDetails;
