import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const FullPageLoaderCenteringContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const FullPageLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

export const FullPageLoaderHeading = styled.h2`
  color: ${themeColors.text.light.body};
`;

export const FullPageLoaderText = styled.p`
  color: ${themeColors.text.light.body};
`;
