import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { CSVDownloaderSmallStyled, CSVDownloaderStyled, CSVDownloaderText } from './styled';
import { _DATE_FORMAT } from 'lib/constants/contants';

export interface CSVHeader {
  label: string;
  key: string;
}

interface CSVDownloaderProps {
  headers: CSVHeader[];
  data: any;
  filenamePrefix: string;
  isSmall?: boolean;
  label?: string;
}

const CSVDownloader: FC<CSVDownloaderProps> = ({
  data,
  headers,
  filenamePrefix,
  isSmall = true,
  label
}) =>
  isSmall ? (
    <CSVDownloaderSmallStyled
      data={data}
      headers={headers}
      filename={`${filenamePrefix}-${DateTime.now().toFormat(`${_DATE_FORMAT}_HH:mm`)}.csv`}
    >
      <CSVDownloaderText className="embolden" data-automation-id="csv-downloader-h5-export-text">
        {label || 'Export'}
      </CSVDownloaderText>
    </CSVDownloaderSmallStyled>
  ) : (
    <CSVDownloaderStyled
      data={data}
      headers={headers}
      filename={`${filenamePrefix}-${DateTime.now().toFormat(`${_DATE_FORMAT}_HH:mm`)}.csv`}
    >
      <CSVDownloaderText className="embolden">{label || 'Export'}</CSVDownloaderText>
    </CSVDownloaderStyled>
  );

export default CSVDownloader;
