import styled from 'styled-components';

export const FormStyled = styled.form`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiOutlinedInput-input {
    height: 52px !important;
  }
`;

export const OnboardingStepCompanyDetailsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const OnboardingStepCompanyDetailsInput = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const OnboardingStepCompanyDetailsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(10px);
  position: absolute;
  right: -48px;
  cursor: pointer;
`;
