/* eslint-disable react-hooks/exhaustive-deps */
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import CloseOutlineIcon from 'assets/icons/CloseOutlineIcon';
import { themeColors } from 'assets/theme/style';
import PageTitle from 'components/PageTitle';
import Snackbar from 'components/snackbar';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TRIGGER_SNACKBAR } from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import OnboardingCompleteSetUp from './OnboardingCompleteSetUp';
import { OnboardingTasksContainer, OnboardingTasksSnackboxDescription } from './styled';
import WarningIcon from 'assets/icons/WarningIcon';
import {useAuth} from "react-oidc-context";

const OnboardingTasks: FC = () => {
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState<boolean>(false);
  const {
    snackbarOpen,
    platformAvailable
  }: {
    snackbarOpen: boolean;
    platformAvailable: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const completeSnackbarData: SnackbarData = {
    title: (
      <h5 data-automation-id="onboarding-tasks-h5-notification-title">
        {completed ? `Congratulations!` : `You’re live on Supplier Pay`}
      </h5>
    ),
    message: (
      <OnboardingTasksSnackboxDescription data-automation-id="onboarding-tasks-p-notification-description">
        {completed
          ? <>
            <p>Your account has been activated, and your registration information has been successfully submitted to the Kanexa Open Account Automation program.</p>
              <p>What’s next…</p>
              <ul>
                <li>Bank Account Approval - Kanexa’s bank account validation process takes approximately 24-48 hours.
                </li>
                <li>To learn more – click on the Kanexa HUB link provided in your invite letter.
                </li>
                <li>Questions? – Contact Kanexa Support - <a href="mailto:support@kanexa.com">support@kanexa.com</a>
                </li>
              </ul>
            </>
          : `Thanks for activating your account. You can now complete your profile by adding a bank
        account.`}
      </OnboardingTasksSnackboxDescription>
    ),
    leftIcon: <ApproveIcon height="18" width="18" color={themeColors.icon.success} />,
    type: 'success',
    topAligned: true,
    centerAlignedClear: true,
    closeIcon: <CloseOutlineIcon color={themeColors.icon.light.default} />
  };

  const platformUnavailableSnackbarData: SnackbarData = {
    title: <h5 data-automation-id="onboarding-tasks-h5-notification-title">Reviewing</h5>,
    message: (
      <OnboardingTasksSnackboxDescription data-automation-id="onboarding-tasks-p-notification-description">
        We are currently reviewing the latest information you provided. You should be able to resume
        your registration within the next 24 hours.
      </OnboardingTasksSnackboxDescription>
    ),
    leftIcon: <WarningIcon height="18" width="18" color={themeColors.icon.warning} />,
    type: 'warning',
    topAligned: true,
    centerAlignedClear: true,
    closeIcon: <CloseOutlineIcon />
  };

  const completeHandler: (isComplete: boolean) => void = (isCompleted) => setCompleted(isCompleted);

  useEffect(() => {
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: completeSnackbarData } });
    return () => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    };
  }, []);

  useEffect(() => {
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    dispatch({
      type: TRIGGER_SNACKBAR,
      payload: {
        open: true,
        data: platformAvailable ? completeSnackbarData : platformUnavailableSnackbarData
      }
    });
  }, [completed]);
  const auth = useAuth()
  return (
    <OnboardingTasksContainer data-automation-id="onboarding-tasks-div-container">
      <PageTitle isOnboarding />
      {snackbarOpen && <Snackbar />}
      {auth.isAuthenticated &&
          auth.user&&
          <OnboardingCompleteSetUp completeHandler={completeHandler} accessToken={auth.user.access_token} />}
    </OnboardingTasksContainer>
  );
};

export default OnboardingTasks;
