import Lottie from 'lottie-react';
import { FC } from 'react';
import loader from 'assets/lottie-animations/vertical_loader.json';

const LoaderInPage: FC<{ height?: string }> = ({ height = '5vh' }) => (
  <Lottie
    style={{
      height
    }}
    loop
    animationData={loader}
    data-testid="sp-in-page-loader"
    data-automation-id="loader-in-page"
  />
);

export default LoaderInPage;
