import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const ActivityBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ActivityBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ActivityBarRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ActivityBarLeftTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: 8px;
`;

export const ActivityBarActivity = styled.p`
  ${typography.bodyRegular};
  margin: 0;
`;

export const ActivityBarCompany = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.subtle};
  margin: 0;
`;

export const ActivityBarIconContainer = styled.div`
  display: flex;
  padding-top: 2px;
  margin-right: 8px;
`;

export const ActivityBarData = styled.h5`
  display: flex;
  margin: 0;
`;

export const ActivityBarValue = styled.h5`
  margin: 0;
`;
