import StoreBase from 'api/base/storeBase';
import { ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IInvoiceMatchingResult } from 'api/interfaces/open-account/invoices/invoices.interface';
import { AxiosResponse } from 'axios';
import { CommentPostModel } from 'utils/classes/open-account/comment/comment';

const _PATH: string = '/open-accounts/invoices';

export default class OpenAccountInvoiceApi extends StoreBase {
  //added for testing - to be ablet o query ledger to see all available fields
  public getOpenAccountInvoiceMatchingResults: (
    invoiceId: string
  ) => Promise<AxiosResponse<IInvoiceMatchingResult>> = async (invoiceId) =>
    this.axiosInstance.get<IInvoiceMatchingResult>(`${_PATH}/${invoiceId}/matching-results`);

  public getOpenAccountInvoiceById: (invoiceId: string) => Promise<AxiosResponse<any>> = async (
    invoiceId
  ) => this.axiosInstance.get<any>(`${_PATH}/${invoiceId}`);

  public getOpenAccountInvoiceApprovalsById: (invoiceId: string) => Promise<AxiosResponse<any>> =
    async (invoiceId) => this.axiosInstance.get<any>(`${_PATH}/${invoiceId}/approvals`);

  public fetchOpenInvoices: (
    programId?: string | undefined
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (programId) =>
    await this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$skip=0&$top=5000&$filter=linkedProgram/invoiceStatus eq 'OPEN' and linkedProgram/programId eq '${programId}'`
    );

  public getInvoiceEventHistoryById: (
    invoiceId?: string | undefined
  ) => Promise<AxiosResponse<any[]>> = async (invoiceId) =>
    await this.axiosInstance.get<any[]>(`${_PATH}/${invoiceId}/event-history`);

  public postInvoiceComment: (
    invoiceId: string,
    data: CommentPostModel
  ) => Promise<AxiosResponse<IComment>> = async (invoiceId, data) =>
    this.axiosInstance.post<IComment>(`${_PATH}/${invoiceId}/comments`, data);

  public getInvoiceComments: (invoiceId: string) => Promise<AxiosResponse<IComment[]>> = async (
    invoiceId
  ) => this.axiosInstance.get<IComment[]>(`${_PATH}/${invoiceId}/comments`);
}
