/* eslint-disable react-hooks/exhaustive-deps */
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { IInvoiceMatchingResult } from 'api/interfaces/open-account/invoices/invoices.interface';
import OpenAccountInvoiceApi from 'api/open-account/invoices/invoices';
import WarningIcon from 'assets/icons/WarningIcon';
import { layoutSizes, themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import { AxiosResponse } from 'axios';
import DataGrid from 'components/DataGrid';
import Chip from 'components/common/Chip';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import LedgerDetail from 'components/ledger/LedgerDetail';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { ledgerPurchaseOrderHeader } from 'lib/data/ledger/ledgerPurchaseOrderHeader';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { MatchingDocumentEnum } from 'lib/enums/matching/matchingDocuments.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { getPOStatusTooltipText } from 'lib/tooltips/tooltips';
import _ from 'lodash';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router-dom';
import { store } from 'store';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  LedgerPurchaseOrderContainer,
  LedgerPurchaseOrderMobileContainer,
  LedgerPurchaseOrderMobileItemBold,
  LedgerPurchaseOrderMobileItemLeft,
  LedgerPurchaseOrderMobileItemRegular,
  LedgerPurchaseOrderMobileItemRight,
  LedgerPurchaseOrderMobileWrapper,
  LedgerPurchaseOrderNoData,
  LedgerPurchaseOrderSubtitle,
  LedgerPurchaseOrderTitle,
  LedgerPurchaseOrderWrapper
} from './styled';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';

interface LedgerDetailProps {}

const LedgerPurchaseOrder: FC<LedgerDetailProps> = () => {
  const params: Readonly<Params<string>> = useParams();
  const layoutRef: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const [layoutWidth, setLayoutWidth]: [number, Dispatch<SetStateAction<number>>] =
    useState<number>(0);
  const [poNumbers, setPoNumbers] = useState<string[]>([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState<ILedgerPurchaseOrder[]>([]);
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const [ledgerDetailOpen, setLedgerDetailOpen] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState<string>('');
  const {
    purchaseOrderLedger,
    invoiceLedger,
    loading,
    allProgramsWithDetails
  }: {
    purchaseOrderLedger: ILedgerPurchaseOrder[];
    invoiceLedger: ILedgerInvoice[];
    loading: boolean;
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const openAccountInvoiceApi = new OpenAccountInvoiceApi(store);

  const clientWidth: number = layoutRef?.current?.clientWidth || 0;

  useEffect(() => {
    if (!params.invoiceId || !invoiceLedger) return;
    getInvoicePONumber();
  }, [params.invoiceId, invoiceLedger]);

  useEffect(() => {
    if (!poNumbers) return;
    getPurchaseOrderId();
  }, [poNumbers]);

  useEffect(() => {
    if (!purchaseOrderData) return;
    setData(mapPurchaseOrderToGridModel());
  }, [purchaseOrderData]);

  useEffect(() => {
    getLayoutSize();
    window.addEventListener('resize', getLayoutSize);

    return () => {
      window.removeEventListener('resize', getLayoutSize);
    };
  }, [clientWidth]);

  const getLayoutSize: () => void = () => {
    const newWidth: number = layoutRef?.current?.clientWidth || clientWidth;
    setLayoutWidth(newWidth);
  };

  const getInvoicePONumber: () => void = async () => {
    if (!params.invoiceId) return;
    setPageLoading(true);

    const res: AxiosResponse<IInvoiceMatchingResult> =
      await openAccountInvoiceApi.getOpenAccountInvoiceMatchingResults(params.invoiceId);

    if (!res || !res?.data?.invoiceLineItems) {
      setPurchaseOrderData([]);
      return;
    }

    const { invoiceLineItems } = res.data;

    const poNumbersFromMatch = invoiceLineItems.flatMap((li) =>
      li.links
        .filter((link) => link.type === MatchingDocumentEnum.PURCHASE_ORDER)
        .flatMap((doc) => doc.id)
    ) as string[];

    const uniquePoNumbers = Array.from(new Set(poNumbersFromMatch));

    setPoNumbers(uniquePoNumbers);
    setPageLoading(false);
  };

  const getPurchaseOrderId: () => void = async () => {
    if (poNumbers.length === 0) return;
    const poApi = new PurchaseOrderApi(store);
    const response = await poApi.getPurchaseOrderLedger(allProgramsWithDetails.map((p) => p.id));
    setPageLoading(false);
    const data: ILedgerPurchaseOrder[] = _.compact(
      poNumbers.map((key) => response.data.value.filter((po) => po.purchaseOrderId === key)[0])
    );
    setPurchaseOrderData(data || []);
  };

  const mapPurchaseOrderToGridModel: () => DataGridRowItem[] = () =>
    purchaseOrderData.map((po) => ({
      purchaseOrderId: po.purchaseOrderId,
      purchaseOrderNumber: po.data.poNumber,
      status: po.linkedPrograms[0].purchaseOrderStatus,
      matchingStatus: po.linkedPrograms[0].isDiscrepant,
      buyer: po.data.buyerName,
      createdDate: formatDateTime(po.data.createdDate || '', 'yyyy/MM/dd'),
      paymentDueDate: formatDateTime(po.data.paymentDueDate || '', 'yyyy/MM/dd'),
      totalAmount: CurrencySymbolsLookUp[po.data.currency || ''] + formatNumber(po.data.amount, 2)
    }));

  const closeFullViewDialog: () => void = () => {
    setLedgerDetailOpen(false);
    purchaseOrderId && setPurchaseOrderId('');
  };

  const openFullViewDialog: (row: string) => void = (row) => {
    layoutWidth < layoutSizes.maxMobileWidth ? setPurchaseOrderId(row) : setPurchaseOrderId(row[0]);
    setLedgerDetailOpen(true);
  };

  const renderFullViewDialog: (id: string) => JSX.Element = (id) => (
    <FullViewDialog
      open={ledgerDetailOpen}
      clickHandler={closeFullViewDialog}
      dialogContent={
        <LayoutViewContainer size="small" margin="0 auto">
          <LedgerDetail
            type={AssetEnum.PURCHASE_ORDER}
            showSummaryAccordions
            showEntities
            hideBanner
            paramId={id}
          />
        </LayoutViewContainer>
      }
    />
  );

  const renderTable: () => JSX.Element = () =>
    layoutWidth < layoutSizes.maxMobileWidth ? (
      <>
        {data.map((data, index, array) => (
          <LedgerPurchaseOrderMobileWrapper key={index}>
            <LedgerPurchaseOrderMobileContainer
              onClick={() => openFullViewDialog(data.purchaseOrderId)}
            >
              <LedgerPurchaseOrderMobileItemLeft>
                <LedgerPurchaseOrderMobileItemBold>
                  {data.purchaseOrderNumber}
                </LedgerPurchaseOrderMobileItemBold>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                  {data.status && (
                    <Chip
                      type={data.status}
                      color={themeColors.text.primary}
                      radius="3px"
                      height="18px"
                      fontSize={fontSizes.small}
                      semibold="bold"
                      testingTag="purchase-order-ledger-detail-mobile-header"
                      tooltipText={getPOStatusTooltipText(data?.status)}
                    />
                  )}
                  {data.matchingStatus && (
                    <IconTooltip
                      tooltipText="An issue was found on the invoice(s) or logistics documents linked to this purchase order during the matching process."
                      children={
                        <WarningIcon color={themeColors.white} fill={themeColors.icon.error} />
                      }
                    />
                  )}
                </div>
              </LedgerPurchaseOrderMobileItemLeft>
              <LedgerPurchaseOrderMobileItemRight>
                <LedgerPurchaseOrderMobileItemBold>{data.buyer}</LedgerPurchaseOrderMobileItemBold>
                <LedgerPurchaseOrderMobileItemRegular>
                  Total Amount: {data.totalAmount}
                </LedgerPurchaseOrderMobileItemRegular>
              </LedgerPurchaseOrderMobileItemRight>
            </LedgerPurchaseOrderMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LedgerPurchaseOrderMobileWrapper>
        ))}
      </>
    ) : (
      <DataGrid
        headers={ledgerPurchaseOrderHeader}
        data={data}
        ariaLabel="Ledger Purchase orders Table"
        ariaCaption="A table that shows the Purchase orders."
        totalRows={data.length}
        rowClickHandler={openFullViewDialog}
        exportFilename={''}
        fixedHeader
        dataAutomationTag="purchase-order-ledger-detail-table-invoices"
      />
    );

  const renderLedgerPurchaseOrderSummary: () => JSX.Element = () => (
    <LedgerDetail
      type={AssetEnum.PURCHASE_ORDER}
      showSummaryAccordions
      showEntities
      paramId={purchaseOrderData[0]?.purchaseOrderId}
    />
  );

  const renderLedgerPurchaseOrderList: () => JSX.Element = () => (
    <>
      {purchaseOrderId && renderFullViewDialog(purchaseOrderId)}

      <LedgerPurchaseOrderWrapper>
        <LedgerPurchaseOrderContainer>
          <LedgerPurchaseOrderTitle data-automation-id="ledger-purchase-order-h4-page-title">
            Purchase orders
          </LedgerPurchaseOrderTitle>
          <LedgerPurchaseOrderSubtitle data-automation-id="ledger-purchase-order-p-page-description">
            There are multiple purchase orders linked to this invoice.
          </LedgerPurchaseOrderSubtitle>
          {layoutWidth > 0 && renderTable()}
        </LedgerPurchaseOrderContainer>
      </LedgerPurchaseOrderWrapper>
    </>
  );

  const renderLedgerPurchaseOrder: () => JSX.Element = () => (
    <LedgerPurchaseOrderWrapper ref={layoutRef} data-testid="sp-ledger-purchase-order-view">
      {data.length > 0 ? (
        data.length === 1 ? (
          renderLedgerPurchaseOrderSummary()
        ) : (
          renderLedgerPurchaseOrderList()
        )
      ) : (
        <LedgerPurchaseOrderNoData data-automation-id="ledger-purchase-order-h5-no-data">
          Purchase order unavailable.
        </LedgerPurchaseOrderNoData>
      )}
    </LedgerPurchaseOrderWrapper>
  );

  return !pageLoading && !loading ? (
    poNumbers.length === 0 ? (
      <LedgerPurchaseOrderNoData
        data-automation-id="ledger-purchase-order-h5-no-poNumber"
        data-testid="sp-ledger-purchase-order-no-po-number"
      >
        Purchase order unavailable.
      </LedgerPurchaseOrderNoData>
    ) : (
      renderLedgerPurchaseOrder()
    )
  ) : (
    <LoaderInPage />
  );
};

export default LedgerPurchaseOrder;
