import { IOnboardingEntityEvent } from 'api/interfaces/onboarding/onboarding.interface';
import {
  GET_ONBOARDING_ACTION_EVENT,
  GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM,
  GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE,
  GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS,
  GET_ONBOARDING_ACTION_EVENT_FAILURE,
  GET_ONBOARDING_ACTION_EVENT_SUCCESS,
  ONBOARDING_ADD_BANK_ACCOUNT,
  ONBOARDING_ADD_BANK_ACCOUNT_FAILURE,
  ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS,
  RESET_ONBOARDING_ERROR,
  UPDATE_ONBOARDING_ACTION_EVENT,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS,
  UPDATE_ONBOARDING_ACTION_EVENT_FAILURE,
  UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface OnboardingReducerStateProps extends StateBase {
  onboardingData: IOnboardingEntityEvent | null;
  onboardingDataByProgram: IOnboardingEntityEvent | null;
}

const DEFAULT_STATE: OnboardingReducerStateProps = {
  onboardingData: null,
  onboardingDataByProgram: null,
  error: '',
  loading: false
};

const onboardingReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case GET_ONBOARDING_ACTION_EVENT:
      return {
        ...state,
        loading: true
      };
    case GET_ONBOARDING_ACTION_EVENT_SUCCESS:
      return {
        ...state,
        onboardingData: payload,
        loading: false
      };
    case GET_ONBOARDING_ACTION_EVENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM:
      return {
        ...state,
        loading: true
      };
    case GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS:
      return {
        ...state,
        onboardingDataByProgram: payload,
        loading: false
      };
    case GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case UPDATE_ONBOARDING_ACTION_EVENT:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS:
      return {
        ...state,
        onboardingData: payload,
        loading: false
      };
    case UPDATE_ONBOARDING_ACTION_EVENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS:
      return {
        ...state,
        onboardingDataByProgram: payload,
        loading: false
      };
    case UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case ONBOARDING_ADD_BANK_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ONBOARDING_ADD_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case RESET_ONBOARDING_ERROR:
      return {
        ...state,
        error: ''
      };
    default:
      return state;
  }
};

export { onboardingReducer };
export type { OnboardingReducerStateProps };
