import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { Task } from 'api/interfaces/funding-request/task.interface';
import { x500Formatter } from 'lib/helpers/formatters/x500Formatter';
import {
  FETCH_FUNDING_REQUEST_CALCULATION,
  FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS,
  FETCH_FUNDING_REQUEST_CALCULATION_FAILURE,
  COMPLETE_FUNDING_REQUEST,
  COMPLETE_FUNDING_REQUEST_FAILURE,
  COMPLETE_FUNDING_REQUEST_SUCCESS,
  FUNDING_REQUEST_RESET,
  UPDATE_FUNDING_REQUEST_CALCULATION,
  UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE,
  UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS,
  SET_EARLY_PAYMENT_DIALOG_OPEN
} from 'store/actions';
import FundingRequestDataGridItem from 'utils/classes/data-grid/fundingRequestDataGridItem';
import { ReducerAction, StateBase } from '.';

interface FundingRequestReducerStateProps extends StateBase {
  calculationStatus: 'SUCCESS' | 'FAILED' | null;
  fundingRequestStatus: 'CREATED' | 'FAILED' | null;
  fundingRequestCalculation: FundingRequestCalculation | null;
  task: Task | null;
  funder: string;
  fundingRequestModuleId: string;
  fundingRequestAsyncTaskId: string;
  invoicesForFundingRequest: FundingRequestDataGridItem[];
  earlyPaymentDialogOpen: boolean;
}

const DEFAULT_STATE: FundingRequestReducerStateProps = {
  calculationStatus: null,
  fundingRequestStatus: null,
  fundingRequestCalculation: null,
  task: null,
  funder: '',
  fundingRequestModuleId: '',
  fundingRequestAsyncTaskId: '',
  invoicesForFundingRequest: [],
  earlyPaymentDialogOpen: false,
  loading: false,
  error: ''
};

const fundingRequestReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case FETCH_FUNDING_REQUEST_CALCULATION:
      return {
        ...DEFAULT_STATE,
        ...state,
        loading: true
      };
    case FUNDING_REQUEST_RESET:
      return {
        ...state,
        calculationStatus: null,
        fundingRequestCalculation: null,
        funder: '',
        fundingRequestModuleId: '',
        fundingRequestAsyncTaskId: '',
        invoicesForFundingRequest: [],
        error: '',
        loading: false
      };
    case FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS:
      return {
        ...state,
        calculationStatus: payload.status,
        fundingRequestCalculation: payload.fundingRequestCalculation,
        fundingRequestModuleId: payload.fundingRequestModuleId,
        funder: x500Formatter(payload.fundingRequestCalculation.invoicesSummary[0].funderX500)?.OU,
        fundingRequestAsyncTaskId: payload.fundingRequestAsyncTaskId,
        task: payload.task,
        loading: false,
        error: ''
      };
    case FETCH_FUNDING_REQUEST_CALCULATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case COMPLETE_FUNDING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case COMPLETE_FUNDING_REQUEST_SUCCESS:
      return {
        ...state,
        task: payload,
        fundingRequestStatus: payload.data.status,
        earlyPaymentDialogOpen: false,
        loading: false,
        error: ''
      };
    case COMPLETE_FUNDING_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_FUNDING_REQUEST_CALCULATION:
      return {
        ...state,
        loading: true
      };
    case UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS:
      return {
        ...state,
        calculationStatus: payload.status,
        fundingRequestCalculation: payload.fundingRequestCalculation,
        fundingRequestModuleId: payload.fundingRequestModuleId,
        funder: x500Formatter(payload.fundingRequestCalculation.invoicesSummary[0].funderX500)?.OU,
        fundingRequestAsyncTaskId: payload.fundingRequestAsyncTaskId,
        task: payload.task,
        loading: false,
        error: ''
      };
    case UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case SET_EARLY_PAYMENT_DIALOG_OPEN:
      return {
        ...state,
        earlyPaymentDialogOpen: payload,
        loading: false
      };
    default:
      return state;
  }
};

export { fundingRequestReducer };
export type { FundingRequestReducerStateProps };
