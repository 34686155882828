import StoreBase from 'api/base/storeBase';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { AxiosResponse } from 'axios';
import { ProgramInvoices } from 'utils/interfaces/invoice/programInvoices.interface';

const _PATH: string = '/supplier-pay/fundings';

export default class SupplierPayApi extends StoreBase {
  public financeCalculationResults: (calculationID: string) => Promise<FundingRequestCalculation> =
    async (calculationID) => {
      const { data } = await this.axiosInstance.get<FundingRequestCalculation>(
        `${_PATH}/calculation/${calculationID}`
      );
      return data;
    };

  public getEligibleInvoicesByPrograms: (
    programID: string,
    currency: string
  ) => Promise<AxiosResponse<ProgramInvoices>> = async (programID, currency) =>
    await this.axiosInstance.get<ProgramInvoices>(
      `${_PATH}/programs/${programID}/currency/${currency}/invoices?page=1&size=500`
    );
}
