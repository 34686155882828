import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { DividerProps } from './Divider';

export const DividerLine = styled.div<DividerProps>`
  display: flex;
  height: 1px;
  border: none;
  background-color: ${({ color }) => (color ? color : themeColors.bg.light.surfaceRaised)};
  margin: ${({ margin }) => (margin ? margin : '4px 0')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '')};
`;
