/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { themeColors } from 'assets/theme/style';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { convertNumberToShortString } from 'lib/helpers/formatters/convertNumberToShortString';
import { BarContainer, ResetIconContainer } from './styled';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { isMobile } from 'lib/helpers/mobile';
import { BarChartData, BarChartDataSet } from 'utils/classes/charts/barChart';
import { useSelector, RootStateOrAny } from 'react-redux';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { YoutubeSearchedFor } from '@mui/icons-material';
import { indexOf } from 'lodash';
import { barChartAxisLookup, emptyBarChartAxisLookup } from 'lib/lookups/barChartAxisLookup';

interface BarChartProps {
  title?: string;
  chartStyle?: string;
  changeHandler?: (status: any) => void;
  labels: string[];
  datasets: BarChartDataSet[];
  showRestart?: boolean;
  noData?: boolean;
}

const BarChart: FC<BarChartProps> = ({
  title,
  changeHandler,
  labels,
  datasets,
  chartStyle,
  showRestart = false,
  noData = false
}) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const [complete, setComplete] = useState<boolean>(false);
  const [calculatedYAxis, setCalculatedYAxis] = useState<number[]>([]);
  const approvedColor: string = themeColors.bg.primary;
  const approvedUnselectedColor: string = `${themeColors.bg.primary}30`;
  const eligibleColor: string = themeColors.chart.light.colour1;
  const eligibleUnselectedColor: string = `${themeColors.chart.light.colour1}30`;
  const ineligibleColor: string = themeColors.chart.light.colour3;
  const ineligibleUnselectedColor: string = `${themeColors.chart.light.colour5}30`;
  const {
    activeCurrencyCode
  }: {
    activeCurrencyCode: string;
  } = useSelector((state: RootStateOrAny) => state.app);

  const dataAxis: number[] = datasets[0]?.data || [];
  const maxAmount: number = dataAxis.length > 0 ? Math.max(...dataAxis) : 0;

  useEffect(() => {
    setComplete(false);
    calculateYAxis();
  }, [maxAmount]);

  const calculateYAxis: () => void = () => {
    const upperLimit = barChartAxisLookup.find((val, i) =>
      i === 0
        ? maxAmount < val.maxValue
        : maxAmount < val.maxValue && maxAmount > barChartAxisLookup[i - 1].maxValue
    );

    if (!upperLimit) {
      setComplete(true);
      return;
    }

    if (maxAmount === 0) {
      setCalculatedYAxis([0, ...emptyBarChartAxisLookup[0].range]);
    } else {
      setCalculatedYAxis([0, ...upperLimit.range]);
    }
    setComplete(true);
  };

  const data: BarChartData = {
    labels,
    datasets
  };

  const optionsInvoices = {
    plugins: {
      tooltip: {
        boxWidth: 7,
        boxHeight: 20,
        cornerRadius: 12,
        caretPadding: 5,
        backgroundColor: themeColors.white,
        borderWidth: 1,
        borderColor: themeColors.border.primary,
        footerFont: {
          size: 12,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        footerMarginTop: 14,
        footerColor: themeColors.text.light.body,
        titleColor: themeColors.text.light.body,
        titleFont: {
          size: 14,
          weight: '600',
          family: "'roc-grotesk', sans-serif"
        },
        titleMarginBottom: 12,
        bodyFont: {
          size: 14,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        labelFont: {
          size: 14,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        bodySpacing: 3,
        bodyColor: themeColors.text.light.body,
        usePointStyle: false,
        yAlign: 'bottom' as const,
        callbacks: {
          title: (context: any) =>
            context[0].dataset.customProperty.data[context[0].label].invoices[
              context[0].dataset.customProperty.dataStatus
            ].length +
            `${
              context[0].dataset.customProperty.data[context[0].label].invoices[
                context[0].dataset.customProperty.dataStatus
              ].length > 1
                ? ' invoices'
                : ' invoice'
            }`,
          afterTitle: (context: any) =>
            CurrencySymbolsLookUp[activeCurrencyCode] + context[0].formattedValue || '',
          label: (context: any) => context.dataset.label || '',
          footer: () => 'Click to filter'
        }
      },
      title: {
        display: title && title.length > 0 ? true : false,
        align: 'start' as const,
        position: 'top' as const,
        text: `${title || ''}`,
        font: {
          size: 14,
          weight: '700'
        },
        color: themeColors.text.light.body,
        padding: {
          bottom: 20
        }
      },
      legend: {
        onClick: (e: any, legendItem: any, legend: any) => {
          const datasets = legend.legendItems.map((dataset: any, index: any) => {
            return dataset.text;
          });
          const chart = legend.chart;
          const dataset0 = chart.config.data.datasets[0].backgroundColor;
          const dataset1 = chart.config.data.datasets[1].backgroundColor;
          const index = datasets.indexOf(legendItem.text);

          if (index === 0) {
            dataset0.forEach((element: any, index: any) => {
              dataset0[index] = eligibleColor;
            });
            dataset1.forEach((element: any, index: any) => {
              dataset1[index] = ineligibleUnselectedColor;
            });
          }

          if (index === 1) {
            dataset0.forEach((element: any, index: any) => {
              dataset0[index] = eligibleUnselectedColor;
            });
            dataset1.forEach((element: any, index: any) => {
              dataset1[index] = ineligibleColor;
            });
          }
          //legend.chart.legend.chart.config.options.plugins.legend.labels.color = "red"
          legend.chart.update();
        },
        display: true,
        align: 'center' as const,
        position: 'bottom' as const,
        labels: {
          boxWidth: isMobile() ? 10 : 15,
          usePointStyle: true,
          font: {
            size: 12,
            weight: '600'
          },
          color: themeColors.text.light.body,
          padding: 16,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, index: number) => ({
              text: dataset.label,
              fillStyle: getColor(index),
              strokeStyle: getColor(index)
            }));
          }
        }
      }
    },
    elements: {
      bar: {
        borderRadius: isMobile() ? 3 : 6
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        title: {
          display: true,
          text: 'Week ending',
          color: themeColors.text.light.body,
          padding: {
            top: 8
          }
        }
      },
      y: {
        stacked: true,
        suggestedMin: 0,
        suggestedMax: 50000,
        grid: {
          color: themeColors.border.primary,
          borderWidth: 2,
          borderColor: themeColors.border.primary,
          tickLength: 0
        },
        ticks: {
          labelOffset: 4,
          align: 'start' as const,
          z: 1,
          padding: 10,
          color: themeColors.text.light.body,
          font: {
            size: isMobile() ? 9 : 12
          },
          callback: (value: any) => {
            return CurrencySymbolsLookUp[activeCurrencyCode] + convertNumberToShortString(value, 0);
          }
        }
      }
    },
    onClick: (evt: any, element: any, chart: any) => {
      filterDataset(chart, element);
    }
  };

  const optionsDashboard = {
    plugins: {
      tooltip: {
        boxWidth: 7,
        boxHeight: 20,
        cornerRadius: 12,
        caretPadding: 5,
        backgroundColor: themeColors.bg.light.strong,
        borderWidth: 1,
        borderColor: themeColors.bg.light.strong,
        footerFont: {
          size: 12,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        footerMarginTop: 14,
        footerColor: themeColors.text.onPrimary,
        titleColor: themeColors.text.onPrimary,
        titleFont: {
          size: 12,
          weight: '600',
          family: "'roc-grotesk', sans-serif"
        },
        titleMarginBottom: 12,
        bodyFont: {
          size: 14,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        labelFont: {
          size: 14,
          weight: '400',
          family: "'Source Sans Pro', sans-serif"
        },
        bodySpacing: 3,
        bodyColor: themeColors.text.onPrimary,
        usePointStyle: true,
        yAlign: 'bottom' as const,
        callbacks: {
          title: (context: any) =>
            context[0].dataset.customProperty.data[context[0].label].invoices[
              context[0].dataset.customProperty.dataStatus
            ].length +
            `${
              context[0].dataset.customProperty.data[context[0].label].invoices[
                context[0].dataset.customProperty.dataStatus
              ].length > 1
                ? ' invoices'
                : ' invoice'
            }`,
          afterTitle: (context: any) =>
            CurrencySymbolsLookUp[activeCurrencyCode] + context[0].formattedValue || '',
          label: (context: any) => context.dataset.label || ''
        }
      },
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    elements: {
      bar: {
        borderRadius: isMobile() ? 3 : 6
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: noData,
          borderWidth: 1,
          borderColor: themeColors.bg.primary,
          display: false
        },
        title: {
          display: true,
          text: 'Week ending',
          color: themeColors.text.light.subtle,
          padding: {
            top: 8
          }
        },
        ticks: {
          font: {
            size: isMobile() ? 9 : 12,
            weight: '600'
          }
        }
      },
      y: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: false
        },
        suggestedMin: 0,
        suggestedMax: calculatedYAxis[calculatedYAxis.length - 1],
        ticks: {
          count: 6,
          // stepSize: stepSize,
          // suggestedMax: maxAmount,
          align: 'end' as const,
          z: 1,
          padding: 10,
          color: themeColors.text.light.subtle,
          font: {
            size: isMobile() ? 9 : 12,
            weight: '600'
          },
          callback: function (value: any, index: any) {
            return (
              CurrencySymbolsLookUp[activeCurrencyCode] +
              convertNumberToShortString(calculatedYAxis[index], 1)
            );
          }
        }
      }
    }
  };
  const filterDataset: (chart: any, element: any) => void = (chart, element) => {
    if (element.length > 0) {
      changeHandler &&
        changeHandler(
          data.datasets[element[0].datasetIndex].customProperty.data[data.labels[element[0].index]]
            .invoices[data.datasets[element[0].datasetIndex].customProperty.dataStatus]
        );
      updateScale('filter', chart, element);
    } else {
      changeHandler && changeHandler([]);
      updateScale('all', chart, element);
    }
  };

  const updateScale: (status: string, chart: any, element: any) => void = (
    status,
    chart,
    element
  ) => {
    const dataset0 = chart.config.data.datasets[0].backgroundColor;
    const dataset1 = chart.config.data.datasets[1].backgroundColor;

    if (status === 'filter') {
      dataset0.forEach((element: any, index: any) => {
        dataset0[index] = eligibleUnselectedColor;
      });

      dataset1.forEach((element: any, index: any) => {
        dataset1[index] = ineligibleUnselectedColor;
      });

      if (element[0].datasetIndex === 0) {
        dataset0[element[0].index] = eligibleColor;
      } else {
        dataset1[element[0].index] = ineligibleColor;
      }
    }

    if (status === 'all') {
      dataset0.forEach((element: any, index: any) => {
        dataset0[index] = eligibleColor;
      });

      dataset1.forEach((element: any, index: any) => {
        dataset1[index] = ineligibleColor;
      });
    }

    chart.update();
  };

  const getColor: (index: number) => string = (index) => {
    switch (index) {
      case 0:
        return eligibleColor;
      case 1:
        return ineligibleColor;
      case 3:
        return approvedColor;
      default:
        return themeColors.text.light.body;
    }
  };

  const resetChart: () => void = () => {
    changeHandler && changeHandler([]);
    const dataset0 = data.datasets[0].backgroundColor;
    const dataset1 = data.datasets[1].backgroundColor;

    dataset0 &&
      dataset0.forEach((element: any, index: any) => {
        dataset0[index] = eligibleColor;
      });

    dataset1 &&
      dataset1.forEach((element: any, index: any) => {
        dataset1[index] = ineligibleColor;
      });
  };

  return (
    <BarContainer
      data-automation-id="bar-chart-div-container"
      data-testid="sp-bar-chart"
      height={chartStyle === 'dashboard' ? '508px' : '40vh'}
    >
      {showRestart && (
        <ResetIconContainer>
          <RestartAltIcon
            data-testid="sp-bar-chart-restart"
            data-automation-id="bar-chart-svg-reset-chart"
            style={{ cursor: 'pointer', color: themeColors.icon.primary }}
            onClick={resetChart}
          />
        </ResetIconContainer>
      )}
      {chartStyle === 'dashboard' && complete && (
        <Bar
          options={chartStyle === 'dashboard' ? optionsDashboard : optionsInvoices}
          data={data}
          data-automation-id="bar-chart-canvas"
          data-testid="sp-bar-chart-canvas"
          style={{ height: chartStyle === 'dashboard' ? '508px' : '40vh' }}
        />
      )}
      {chartStyle !== 'dashboard' && (
        <Bar
          options={chartStyle === 'dashboard' ? optionsDashboard : optionsInvoices}
          data={data}
          data-automation-id="bar-chart-canvas"
          data-testid="sp-bar-chart-canvas"
          style={{ height: chartStyle === 'dashboard' ? '508px' : '40vh' }}
        />
      )}
    </BarContainer>
  );
};

export default BarChart;
