import OrchestrationApi from 'api/orchestration/orchestration.api';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  CREATE_NEW_INVOICE,
  CREATE_NEW_INVOICE_FAILURE,
  CREATE_NEW_INVOICE_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const createInvoice: (action: any) => Promise<any> = async (action) => {
  const orchestrationApi = new OrchestrationApi(store);
  const status = action.payload.status || '';
  const invRes =  await orchestrationApi.postCreateInvoice(action.payload.status, (status=== 'draft') ? action.payload.taskID : action.payload.inv) 

  const item = invRes.data?.items?.[0] || {};
  const programStatuses = item.programStatuses?.[0] ?? [];

  if (item.status !== 'VALID') {
    return {
      errorMessage: item.reasons,
      assetId: '',
      completed: false,
      draft: true
    };
  } else {
    if (programStatuses?.status !== 'ACCEPTED') {
      return {
        errorMessage: programStatuses?.reasons,
        assetId: '',
        completed: false,
        draft: true
      };
    } else {
      return {
        errorMessage: '',
        assetId: item.assetId || '',
        completed: true,
        draft: false,
        draftAsyncTaskID: null
      };
    }
  }
  // If the item's status is valid, check if the program status is accepted
};

//===================================================
//                      EPICS
//===================================================

const createInvoiceEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_NEW_INVOICE),
    switchMap((action) =>
      from(createInvoice(action)).pipe(
        map((payload) => ({ type: CREATE_NEW_INVOICE_SUCCESS, payload })),
        catchError((error) =>
          of({ type: CREATE_NEW_INVOICE_FAILURE, payload: 'SP request not successful' })
        )
      )
    )
  );

export default combineEpics(createInvoiceEpic$);
