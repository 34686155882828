/* eslint-disable react-hooks/exhaustive-deps */
import { themeColors } from 'assets/theme/style';
import { fontWeights, typography } from 'assets/theme/typography';
import CustomLink from 'components/common/links/CustomLink';
import { softwareList } from 'lib/constants/accountingSoftware';
import { FC, useEffect, useState } from 'react';
import {
  ConnectAccountingSoftwareDialogContainer,
  ConnectAccountingSoftwareDialogHeader,
  ConnectAccountingSoftwareDialogIcon,
  ConnectAccountingSoftwareDialogListContainer,
  ConnectAccountingSoftwareDialogTitle,
  ConnectAccountingSoftwareDialogTitleBold,
  ConnectAccountingSoftwareDialogTitleWrapper,
  ConnectAccountingSoftwareDialogWrapper
} from './styled';

export interface IAccountingSoftware {
  name: string;
  logo?: string;
  link?: string;
}

export interface IConnectAccountingSoftwareDialogProps {
  clickHandler: () => void;
}

const ConnectAccountingSoftwareDialog: FC<IConnectAccountingSoftwareDialogProps> = ({
  clickHandler
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [accountingSoftware, setAccountingSoftware] = useState<IAccountingSoftware[]>([]);

  useEffect(() => {
    setAccountingSoftware(softwareList);
  }, []);

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => setSearchTerm(event.currentTarget.value);

  const filteredData: () => IAccountingSoftware[] = () =>
    accountingSoftware.filter((r) =>
      (r.name as string).toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

  return (
    <ConnectAccountingSoftwareDialogWrapper>
      <ConnectAccountingSoftwareDialogContainer>
        <ConnectAccountingSoftwareDialogTitleWrapper>
          <ConnectAccountingSoftwareDialogTitle data-automation-id="connect-accounting-software-dialog-h2-page-title">
            Connect accounting software
          </ConnectAccountingSoftwareDialogTitle>
        </ConnectAccountingSoftwareDialogTitleWrapper>
        <ConnectAccountingSoftwareDialogHeader data-automation-id="connect-accounting-software-dialog-div-header">
          <ConnectAccountingSoftwareDialogTitleBold>
            Select your accounting software to connect...
          </ConnectAccountingSoftwareDialogTitleBold>
        </ConnectAccountingSoftwareDialogHeader>
        <ConnectAccountingSoftwareDialogListContainer>
          {filteredData().map((software: IAccountingSoftware, index: number) => (
            <ConnectAccountingSoftwareDialogIcon
              key={`${index}-${software.name}`}
              onClick={clickHandler}
              src={software.logo}
              alt={software.name}
            />
          ))}
        </ConnectAccountingSoftwareDialogListContainer>
        <CustomLink
          title="Not in this list?"
          link=""
          typography={typography.bodyRegular}
          textColor={themeColors.text.primary}
          fontWeight={fontWeights.semiBold}
          target
        />
      </ConnectAccountingSoftwareDialogContainer>
    </ConnectAccountingSoftwareDialogWrapper>
  );
};

export default ConnectAccountingSoftwareDialog;
