export const toTitleCase: (str: string) => string = (str) =>
  str.replace(/\b\S/g, (t) => t.toUpperCase());

export const camelCaseToKebabCase: (str: string) => string = (str) =>
  str.replace(/[A-Z]/g, '-$&').toLowerCase();

export const camelCaseToSentenceCase: (str: string) => string = (str) =>
  str.replace(/[A-Z]/g, ' $&').toLowerCase();

export const toKebabCase: (str: string) => string = (str) =>
  str
    ? str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()
    : '';

export const toSentenceCase: (str: string) => string = (str) => {
  if (!str) return '';
  const res = str.replace(/([A-Z])/g, ' $1');
  return res.charAt(0).toUpperCase() + res.slice(1);
};
