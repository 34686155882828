import { FC } from 'react';
import { FlagCardProps } from 'utils/interfaces/flatcard/flatcard.interface';
import {
  FlatCardContainer,
  FlatCardLeft,
  FlatCardRight,
  FlatCardTitle,
  FlatCardValue,
  FlatCardFlag,
  FlatCardIcon,
  FlatCardContentContainer
} from './styled';

const FlatCard: FC<FlagCardProps> = ({
  icon,
  title,
  value,
  flag,
  clickHandler,
  testingTagPage
}) => {
  return (
    <FlatCardContainer onClick={clickHandler} isFlag={!!flag} data-testid="sp-flat-card">
      <FlatCardLeft>
        {icon && (
          <FlatCardIcon data-automation-id={`${testingTagPage}-div-flat-card-icon`}>
            {icon}
          </FlatCardIcon>
        )}
        <FlatCardContentContainer>
          <FlatCardTitle data-automation-id={`${testingTagPage}-div-flat-card-title`}>
            {title}
          </FlatCardTitle>
          <FlatCardValue data-automation-id={`${testingTagPage}-div-flat-card-value`}>
            {value}
          </FlatCardValue>
        </FlatCardContentContainer>
      </FlatCardLeft>
      {flag && (
        <FlatCardFlag data-automation-id={`${testingTagPage}-div-flat-card-flag`}>
          {flag}
        </FlatCardFlag>
      )}
    </FlatCardContainer>
  );
};

export default FlatCard;
