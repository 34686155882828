export const SET_GATEWAY_TO_FINANCE_ID =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID';
export const SET_GATEWAY_TO_FINANCE_ID_SUCCESS =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID_SUCCESS';
export const SET_GATEWAY_TO_FINANCE_ID_FAILURE =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID_FAILURE';
export const RESET_GATEWAY_TO_FINANCE_ID =
  '@@frontend/store/reducers/gatewayToFinance/RESET_GATEWAY_TO_FINANCE_ID';
export const SHOW_GATEWAY_TO_FINANCE_BAR =
  '@@frontend/store/reducers/gatewayToFinance/SHOW_GATEWAY_TO_FINANCE_BAR';
export const RESET_GATEWAY_TO_FINANCE_ERROR =
  '@@frontend/store/reducers/gatewayToFinance/RESET_GATEWAY_TO_FINANCE_ERROR';
export const SET_GATEWAY_TO_FINANCE_ID_CONTINUE =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID_CONTINUE';
export const SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS';
export const SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE =
  '@@frontend/store/reducers/gatewayToFinance/SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE';
