import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  height?: string;
  width?: string;
}

export const GatewayToFinanceDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const GatewayToFinanceDialogTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const GatewayToFinanceDialogTitle = styled.h2`
  text-align: center;
  color: ${themeColors.text.primary};
`;

export const GatewayToFinanceDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`;

export const GatewayToFinanceDialogTitleRegular = styled.p``;

export const GatewayToFinanceDialogTitleBold = styled.p`
  ${typography.bodySemiBold};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const GatewayToFinanceDialogTable = styled.div`
  display: flex;
`;

export const GroupedTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    padding: 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
  }
  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
`;

export const GatewayToFinanceDialogSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const GatewayToFinanceDialogSummaryTitle = styled.h4`
  color: ${themeColors.text.light.body};
`;

export const GatewayToFinanceDialogSummaryInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;

  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const GatewayToFinanceDialogActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const GatewayToFinanceDialogActionButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || ''};
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 24px;
`;

export const GatewayToFinanceDialogActionButtonText = styled.p`
  ${typography.bodySemiBold};
`;