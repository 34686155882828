import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Title } from './styled';
import { useTranslation } from 'react-i18next';

interface PageTitleProps {
  isOnboarding?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({ isOnboarding }) => {
  const { t } = useTranslation();
  const pageTitle: string = useSelector((state: RootStateOrAny) => state.app.pageTitle);
  const username: string | null = useSelector((state: RootStateOrAny) => state.app.username);
  const setValue: () => string = () =>
    pageTitle === 'Home'
      ? !!username
        ? isOnboarding
          ? `${t('welcome')} ${username}`
          : `${t('welcomeBack')}, ${username}`
        : ''
      : pageTitle;

  return !setValue() ? (
    <></>
  ) : (
    <Title data-automation-id="home-title-h3-welcome" data-testid="sp-page-title">
      {setValue()}
    </Title>
  );
};

export default PageTitle;
