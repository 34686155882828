/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from '@mui/material';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import { earlyPaymentRequestHeader } from 'lib/data/early-payment-request/earlyPaymentRequestHeader';
import { isMobile } from 'lib/helpers/mobile';
import _ from 'lodash';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router';
import {
  FETCH_FUNDING_REQUEST_CALCULATION,
  SET_EARLY_PAYMENT_DIALOG_OPEN,
  SET_FUNDING_COSTS,
  SET_MODIFIED_DATA,
  SET_ORIGINAL_DATA,
  UPDATE_FUNDING_REQUEST_CALCULATION,
  UPDATE_REQUEST_ERROR
} from 'store/actions';
import FundingRequestDataGridItem from 'utils/classes/data-grid/fundingRequestDataGridItem';
import { FundingCosts, GroupedItem } from 'utils/interfaces/early-payment/earlyPayment.interface';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';
import EarlyPaymentRequestAccordion from './EarlyPaymentRequestAccordion';
import EarlyPaymentRequestSummary from './EarlyPaymentRequestSummary';
import {
  EarlyPaymentInvoicesSummaryContainer,
  EarlyPaymentLoadingContainer,
  EarlyPaymentRequestActionsContainer,
  EarlyPaymentRequestButtonContainer,
  EarlyPaymentRequestButtonText,
  EarlyPaymentRequestContainer,
  EarlyPaymentRequestData,
  EarlyPaymentRequestInvoicesRightSegment,
  EarlyPaymentRequestMobileActionsContainer,
  EarlyPaymentRequestMobileBottomContainer,
  EarlyPaymentRequestMobileButtonContainer,
  EarlyPaymentRequestMobileTableContainer,
  EarlyPaymentRequestMobileTableUpdateButtonContainer,
  EarlyPaymentRequestMobileTableViewContainer,
  EarlyPaymentRequestMobileTopContainer,
  EarlyPaymentRequestName,
  EarlyPaymentRequestTitle,
  EarlyPaymentRequestWrapper,
  GroupedTable,
  GroupTableContainer
} from './styled';

interface EarlyPaymentRequestProps {
  closeClickHandler: () => void;
  sendRequestClickHandler: () => void;
}

export interface HeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
}

const EarlyPaymentRequest: FC<EarlyPaymentRequestProps> = ({
  closeClickHandler,
  sendRequestClickHandler
}) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [headerCheckbox, setHeaderCheckbox] = useState<HeaderCheckbox>({
    indeterminate: false,
    checked: true
  });
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const invoices: Invoice[] = useSelector((state: RootStateOrAny) => state.app.invoices);
  const {
    loading,
    fundingRequestCalculation,
    error
  }: {
    loading: boolean;
    fundingRequestCalculation: FundingRequestCalculation | null;
    error: string | null;
  } = useSelector((state: RootStateOrAny) => state.fundingRequest);
  const {
    modifiedData,
    originalData,
    fundingCosts
  }: {
    modifiedData: GroupedItem[];
    originalData: GroupedItem[];
    fundingCosts: FundingCosts;
  } = useSelector((state: RootStateOrAny) => state.earlyPayment);

  useEffect(() => {
    if (error && error.length > 0) {
      dispatch({ type: UPDATE_REQUEST_ERROR, payload: error });
      dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: false });
      navigate('/');
    }
  }, [error]);

  useEffect(() => {
    if (!headerCheckbox.indeterminate) setGridDataSelected();
    mappedData();
  }, [headerCheckbox]);

  useEffect(() => {
    if (fundingRequestCalculation) mappedData();
  }, [fundingRequestCalculation]);

  useEffect(() => {
    dataComparisonCheck();
    if (fundingRequestCalculation) calculateFundingCosts(fundingRequestCalculation);
  }, [fundingRequestCalculation]);

  useEffect(() => {
    dataComparisonCheck();
  }, [modifiedData]);

  useEffect(() => {
    if (modifiedData.length === 0 || originalData.length === 0) mappedData();
    dispatch({ type: FETCH_FUNDING_REQUEST_CALCULATION });
    return () => {
      mappedData();
      dispatch({ type: SET_MODIFIED_DATA, payload: [] });
      dispatch({ type: SET_ORIGINAL_DATA, payload: [] });
    };
  }, []);

  const dataComparisonCheck: () => void = () => {
    const originalSelectedCount: number = originalData
      .flatMap((o) => o.invoices)
      .filter((o) => o.selected).length;
    const modifiedSelectedCount: number = modifiedData
      .flatMap((o) => o.invoices)
      .filter((o) => o.selected).length;
    setIsModified(originalSelectedCount !== modifiedSelectedCount);
  };

  const tableBodyCheckboxClickHandler: (inv: FundingRequestDataGridItem) => void = (
    inv: FundingRequestDataGridItem
  ) => {
    const mutArr: GroupedItem[] = [...modifiedData];
    const invs: FundingRequestDataGridItem[] = modifiedData.flatMap(({ invoices }) => invoices);
    const index: number = invs.map((g) => g.invoiceNumber).indexOf(inv.invoiceNumber);
    if (index < 0) return;
    invs[index].selected = !invs[index].selected;
    dispatch({ type: SET_MODIFIED_DATA, payload: mutArr });
    headerCheckboxState();
    rowCheckboxState();
  };

  const headerCheckboxState: () => void = () => {
    const invoices: FundingRequestDataGridItem[] = modifiedData.flatMap(({ invoices }) => invoices);
    const selectedArr: FundingRequestDataGridItem[] = invoices.filter(({ selected }) => selected);
    const unSelectedArr: FundingRequestDataGridItem[] = invoices.filter(
      ({ selected }) => !selected
    );
    const indeterminate: boolean = selectedArr.length !== invoices.length;
    const checked: boolean =
      selectedArr.length === invoices.length || unSelectedArr.length === invoices.length;

    return setHeaderCheckbox({ indeterminate, checked });
  };

  const rowCheckboxState: () => void = () => {
    const mapped: GroupedItem[] = modifiedData.map((g) => {
      const invoices: FundingRequestDataGridItem[] = g.invoices;
      const selectedArr: FundingRequestDataGridItem[] = invoices.filter(({ selected }) => selected);
      const unSelectedArr: FundingRequestDataGridItem[] = invoices.filter(
        ({ selected }) => !selected
      );
      g.indeterminate =
        selectedArr.length !== invoices.length && unSelectedArr.length !== invoices.length;
      g.selected = selectedArr.length === invoices.length;

      return g;
    });
    dispatch({ type: SET_MODIFIED_DATA, payload: mapped });
  };

  const headerCheckboxClickHandler: () => void = () =>
    setHeaderCheckbox({ indeterminate: false, checked: !headerCheckbox.checked });

  const setGridDataSelected: () => void = () => {
    const mapped: GroupedItem[] = modifiedData.map((g) => {
      g.selected = headerCheckbox.checked;
      g.invoices = g.invoices.map((inv: any) => {
        inv.selected = headerCheckbox.checked;
        return inv;
      });
      return g;
    });
    dispatch({ type: SET_MODIFIED_DATA, payload: mapped });
    rowCheckboxState();
  };

  const tableAccordionCheckboxClickHandler: (
    e: ChangeEvent<HTMLInputElement>,
    row: FundingRequestDataGridItem
  ) => void = (e: ChangeEvent<HTMLInputElement>, row: FundingRequestDataGridItem) => {
    e.stopPropagation();
    const mutArr: GroupedItem[] = Array.from(modifiedData);
    const index: number = modifiedData.map((g) => g.buyer).indexOf(row.buyer);
    if (index < 0) return;
    mutArr[index].selected = !mutArr[index].selected;
    mutArr[index].indeterminate = false;
    mutArr[index].invoices = mutArr[index].invoices.map((inv: FundingRequestDataGridItem) => {
      inv.selected = mutArr[index].selected;
      return inv;
    });
    dispatch({ type: SET_MODIFIED_DATA, payload: mutArr });
  };

  const rowChangedClickHandler: (expanded: boolean, item: any) => void = (
    expanded: boolean,
    item: any
  ) => {
    const mutArr: GroupedItem[] = Array.from(modifiedData);
    const index: number = mutArr.map((d) => d.buyer).indexOf(item.buyer);
    if (index < 0) return;
    mutArr[index].expanded = expanded;
    mutArr[index].indeterminate = false;
    dispatch({ type: SET_MODIFIED_DATA, payload: mutArr });
  };

  const mappedData: () => void = async () => {
    if (originalData.length > 0) return;
    const grouped: _.Dictionary<FundingRequestDataGridItem[]> =
      _.groupBy<FundingRequestDataGridItem>(mappedApiDataToViewModel(), 'buyer');
    const x: GroupedItem[] = Object.entries(grouped).map(([key, value]) => ({
      buyer: key,
      invoices: mappedDataInvoicesSelectionState(value),
      expanded: false,
      ...mappedDataRowSelectionState(value)
    }));
    dispatch({ type: SET_ORIGINAL_DATA, payload: x });
    dispatch({ type: SET_MODIFIED_DATA, payload: JSON.parse(JSON.stringify(x)) });
  };

  const calculateFundingCosts: (fundingRequestCalc: FundingRequestCalculation) => void = (
    fundingRequestCalc
  ) => {
    const totalBaseRateCost: number = fundingRequestCalc.invoicesSummary.reduce(
      (acc, val) => acc + val.baseRateCosts,
      0
    );
    const totalMarginCost: number = fundingRequestCalc.invoicesSummary.reduce(
      (acc, val) => acc + val.marginCosts,
      0
    );
    dispatch({ type: SET_FUNDING_COSTS, payload: { totalBaseRateCost, totalMarginCost } });
  };

  const mappedDataInvoicesSelectionState: (
    invoices: FundingRequestDataGridItem[]
  ) => FundingRequestDataGridItem[] = (invoices) => {
    const invoiceIDsInFundingRequest: string[] | undefined =
      fundingRequestCalculation?.invoicesSummary.map((inv) => inv.id);
    if (!invoiceIDsInFundingRequest) return invoices;

    return invoices.map((inv) => {
      inv.selected = invoiceIDsInFundingRequest.includes(inv.id);
      return inv;
    });
  };

  const mappedDataRowSelectionState: (invoices: FundingRequestDataGridItem[]) => {
    indeterminate: boolean;
    selected: boolean;
  } = (invoices) => {
    const selectedArr: FundingRequestDataGridItem[] = invoices.filter(({ selected }) => selected);
    const unSelectedArr: FundingRequestDataGridItem[] = invoices.filter(
      ({ selected }) => !selected
    );
    const indeterminate =
      selectedArr.length !== invoices.length && unSelectedArr.length !== invoices.length;
    const selected = selectedArr.length === invoices.length;

    return { indeterminate, selected };
  };

  const selectedInvoiceCount: () => number = () =>
    modifiedData.flatMap((m) => m.invoices).filter((inv) => inv.selected).length;

  const updateClickHandler: () => void = () => {
    const selectedInvoices: FundingRequestDataGridItem[] = modifiedData
      .flatMap((m) => m.invoices)
      .filter((inv) => inv.selected);
    dispatch({ type: UPDATE_FUNDING_REQUEST_CALCULATION, payload: selectedInvoices });
    dispatch({ type: SET_ORIGINAL_DATA, payload: JSON.parse(JSON.stringify(modifiedData)) });
    setIsModified(false);
    setEditOpen(!editOpen);
  };

  const invoiceSelectionCancelClickHandler: () => void = () => setEditOpen(!editOpen);

  const invoiceUpdateSelectionCancelClickHandler: () => void = () => {
    dispatch({ type: SET_MODIFIED_DATA, payload: JSON.parse(JSON.stringify(originalData)) });
    setEditOpen(!editOpen);
  };

  const editClickHandler: () => void = () => setEditOpen(!editOpen);

  const mappedApiDataToViewModel: () => FundingRequestDataGridItem[] = () => {
    return invoices.map(
      ({
        invoiceNumber,
        buyerName,
        maturityDate,
        invoiceValue,
        purchasePrice,
        id,
        currency,
        marginCost,
        baseRateCost
      }) => {
        return new FundingRequestDataGridItem(
          invoiceNumber,
          buyerName,
          maturityDate,
          invoiceValue,
          '-',
          purchasePrice,
          id,
          currency,
          true,
          marginCost,
          baseRateCost
        );
      }
    );
  };

  const renderTable: () => JSX.Element = () => (
    <>
      <GroupTableContainer>
        <GroupedTable data-automation-id="early-payment-request-table-grouped-invoice-table">
          <thead style={{ textAlign: 'left' }}>
            <tr>
              <th>
                <Checkbox
                  style={{
                    color: themeColors.text.primary,
                    backgroundColor: 'transparent',
                    zIndex: 10
                  }}
                  {...headerCheckbox}
                  onChange={() => headerCheckboxClickHandler()}
                  inputProps={
                    {
                      'data-testid': 'sp-clickable-header-checkbox'
                    } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                  data-testid="sp-header-checkbox"
                />
              </th>
              {earlyPaymentRequestHeader.map((h) => {
                return (
                  <th key={h.label} style={{ width: 'calc(100% / 6)' }}>
                    {h.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {modifiedData.map((d, i) => (
              <tr key={i}>
                <td colSpan={7} className="accordion-row">
                  <EarlyPaymentRequestAccordion
                    fundingRequestCalculation={fundingRequestCalculation}
                    data={d}
                    rowChangedClickHandler={rowChangedClickHandler}
                    tableAccordionCheckboxClickHandler={tableAccordionCheckboxClickHandler}
                    tableBodyCheckboxClickHandler={tableBodyCheckboxClickHandler}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </GroupedTable>
      </GroupTableContainer>
      <EarlyPaymentRequestActionsContainer>
        <EarlyPaymentRequestButtonContainer>
          <PrimaryButton
            clickHandler={updateClickHandler}
            testingTag="early-payment-request-button-update"
            text="Update"
          />
        </EarlyPaymentRequestButtonContainer>
        <EarlyPaymentRequestButtonContainer>
          <SecondaryButton
            text="Cancel"
            clickHandler={
              isModified
                ? invoiceUpdateSelectionCancelClickHandler
                : invoiceSelectionCancelClickHandler
            }
            testingTag="early-payment-request-button-modified-invoice-selection-cancel"
          />
        </EarlyPaymentRequestButtonContainer>
      </EarlyPaymentRequestActionsContainer>
    </>
  );

  const renderMobileTable: () => JSX.Element = () => (
    <EarlyPaymentRequestMobileTableViewContainer data-testid="sp-early-payment-request-mobile-table-view">
      <EarlyPaymentRequestMobileTableContainer>
        {modifiedData.map((data) => (
          <EarlyPaymentRequestAccordion
            key={data.buyer}
            data={data}
            fundingRequestCalculation={fundingRequestCalculation}
            rowChangedClickHandler={rowChangedClickHandler}
            tableAccordionCheckboxClickHandler={tableAccordionCheckboxClickHandler}
            tableBodyCheckboxClickHandler={tableBodyCheckboxClickHandler}
          />
        ))}
      </EarlyPaymentRequestMobileTableContainer>
      {isModified && (
        <EarlyPaymentRequestMobileTableUpdateButtonContainer>
          <PrimaryButton
            clickHandler={updateClickHandler}
            testingTag="early-payment-request-button-mobile-modified-update"
            text="Update"
          />
        </EarlyPaymentRequestMobileTableUpdateButtonContainer>
      )}
      {!isModified && (
        <EarlyPaymentRequestMobileTableUpdateButtonContainer>
          <SecondaryButton
            text="Cancel"
            clickHandler={invoiceSelectionCancelClickHandler}
            testingTag="early-payment-request-button-mobile-invoice-selection-cancel"
          />
        </EarlyPaymentRequestMobileTableUpdateButtonContainer>
      )}
    </EarlyPaymentRequestMobileTableViewContainer>
  );

  const renderMainView: () => JSX.Element = () => (
    <EarlyPaymentRequestWrapper data-testid="sp-early-payment-request-main-view">
      <EarlyPaymentRequestContainer>
        <EarlyPaymentRequestTitle data-automation-id="early-payment-request-h2-page-title">
          {editOpen
            ? 'Edit invoices included in early payment request'
            : 'Your early payment request'}
        </EarlyPaymentRequestTitle>
        {!editOpen && (
          <>
            <EarlyPaymentRequestSummary
              currency={invoices?.length ? invoices[0].currency : ''}
              fundingCosts={fundingCosts}
            />
            <Divider />
            <EarlyPaymentInvoicesSummaryContainer>
              <EarlyPaymentRequestName data-automation-id="early-payment-request-p-invoice-count-title">
                Number of invoices
              </EarlyPaymentRequestName>
              <EarlyPaymentRequestInvoicesRightSegment>
                <EarlyPaymentRequestData data-automation-id="early-payment-request-p-invoice-count-value">
                  {selectedInvoiceCount()}/{invoices.length} Invoice
                  {`${selectedInvoiceCount() === 1 ? '' : 's'}`}
                </EarlyPaymentRequestData>
                <EarlyPaymentRequestButtonContainer width="60px">
                  <SecondaryButton
                    text="Edit"
                    clickHandler={editClickHandler}
                    padding="0"
                    height="28px"
                    testingTag="early-payment-request-button-invoice-selection-edit"
                  />
                </EarlyPaymentRequestButtonContainer>
              </EarlyPaymentRequestInvoicesRightSegment>
            </EarlyPaymentInvoicesSummaryContainer>
            <Divider margin="4px 0 32px 0" />
            <EarlyPaymentRequestActionsContainer>
              <EarlyPaymentRequestButtonContainer>
                <PrimaryButton
                  clickHandler={sendRequestClickHandler}
                  testingTag="early-payment-request-button-send-request"
                  text="Send request"
                />
              </EarlyPaymentRequestButtonContainer>
              <EarlyPaymentRequestButtonContainer>
                <SecondaryButton
                  text="Cancel"
                  clickHandler={closeClickHandler}
                  testingTag="early-payment-request-button-cancel"
                />
              </EarlyPaymentRequestButtonContainer>
            </EarlyPaymentRequestActionsContainer>
          </>
        )}
        {editOpen && renderTable()}
      </EarlyPaymentRequestContainer>
    </EarlyPaymentRequestWrapper>
  );
  const renderMobileView: () => JSX.Element = () => (
    <EarlyPaymentRequestWrapper data-testid="sp-early-payment-request-mobile-view">
      <EarlyPaymentRequestContainer>
        {!editOpen && (
          <>
            <EarlyPaymentRequestMobileTopContainer>
              <EarlyPaymentRequestTitle>Your early payment request</EarlyPaymentRequestTitle>
              <EarlyPaymentRequestSummary
                currency={invoices?.length ? invoices[0].currency : ''}
                fundingCosts={fundingCosts}
              />
              <Divider />
              <EarlyPaymentInvoicesSummaryContainer>
                <EarlyPaymentRequestName>Number of invoices</EarlyPaymentRequestName>
                <EarlyPaymentRequestInvoicesRightSegment>
                  <EarlyPaymentRequestData>
                    {selectedInvoiceCount()}/{invoices.length} invoice
                    {`${selectedInvoiceCount() === 1 ? '' : 's'}`}
                  </EarlyPaymentRequestData>
                  <EarlyPaymentRequestMobileButtonContainer height="28px" width="55px">
                    <SecondaryButton
                      text="Edit"
                      clickHandler={editClickHandler}
                      padding="0"
                      height="28px"
                      testingTag="early-payment-request-button-mobile-edit"
                    />
                  </EarlyPaymentRequestMobileButtonContainer>
                </EarlyPaymentRequestInvoicesRightSegment>
              </EarlyPaymentInvoicesSummaryContainer>
              <Divider margin="4px 0 32px 0" />
            </EarlyPaymentRequestMobileTopContainer>
            <EarlyPaymentRequestMobileBottomContainer>
              <EarlyPaymentRequestMobileActionsContainer>
                <EarlyPaymentRequestMobileButtonContainer>
                  <PrimaryButton
                    disabled={isModified}
                    clickHandler={sendRequestClickHandler}
                    testingTag="early-payment-request-button-mobile-send-request"
                    text="Send request"
                  />
                </EarlyPaymentRequestMobileButtonContainer>
                <EarlyPaymentRequestMobileButtonContainer>
                  <SecondaryButton
                    text="Cancel"
                    clickHandler={closeClickHandler}
                    testingTag="early-payment-request-button-mobile-cancel"
                  />
                </EarlyPaymentRequestMobileButtonContainer>
              </EarlyPaymentRequestMobileActionsContainer>
            </EarlyPaymentRequestMobileBottomContainer>
          </>
        )}
        {editOpen && renderMobileTable()}
      </EarlyPaymentRequestContainer>
    </EarlyPaymentRequestWrapper>
  );

  const renderLoadingView: () => JSX.Element = () => (
    <EarlyPaymentLoadingContainer data-testid="sp-early-payment-request-loading">
      <LoaderFullPage />
    </EarlyPaymentLoadingContainer>
  );

  return fundingRequestCalculation && !loading
    ? isMobile()
      ? renderMobileView()
      : renderMainView()
    : renderLoadingView();
};

export default EarlyPaymentRequest;
