import { FC } from 'react';
import _ from 'lodash';
import {
  MobileInvoiceBarContainer,
  MobileInvoiceBarSection,
  MobileInvoiceCaption,
  MobileInvoicesContainer,
  MobileInvoiceSemiBoldText
} from './styled';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import CustomPagination from 'components/CustomPagination';

export interface InvoicesViewTableData {
  buyer: string;
  currency: string;
  dueDate: string;
  totalAmount: string;
  invoiceDate: string;
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  status: string;
  selected: boolean;
}

interface InvoicesViewMobileProps {
  data: DataGridRowItem[];
  rowClickHandler?: (row: string) => void;
  totalRows: number;
  page: number;
  rowsPerPage: number;
  changePage: (action: any, tableState: any) => void;
  rowsPerPageHandler: (numberOfRows: any) => void;
}

const PurchaseOrderViewMobile: FC<InvoicesViewMobileProps> = ({
  data,
  rowClickHandler,
  totalRows,
  page,
  rowsPerPage,
  rowsPerPageHandler,
  changePage
}) => {
  // group invoices by duedate

  const mappedData: () => DataGridRowItem[] = () => {
    return data.map((v) => ({
      purchaseOrderId: v.purchaseOrderId,
      purchaseOrderNumber: v.purchaseOrderNumber,
      totalAmount: `${CurrencySymbolsLookUp[v.currency as string]}${v.totalAmount}`,
      buyer: v.buyer,
      status: v.status
    }));
  };
  const buildInvoiceBar: (viewModel: DataGridRowItem, key: number) => JSX.Element = (
    { purchaseOrderId, purchaseOrderNumber, totalAmount, buyer, status },
    key
  ) => (
    <MobileInvoiceBarContainer
      key={key}
      onClick={() => rowClickHandler && rowClickHandler(purchaseOrderId as string)}
      data-testid="sp-mobile-invoice-bar"
    >
      <MobileInvoiceBarSection>
        <MobileInvoiceSemiBoldText>{purchaseOrderNumber}</MobileInvoiceSemiBoldText>
        <MobileInvoiceSemiBoldText>{status}</MobileInvoiceSemiBoldText>
      </MobileInvoiceBarSection>
      <MobileInvoiceBarSection>
        <MobileInvoiceCaption>{buyer}</MobileInvoiceCaption>
        <MobileInvoiceCaption>{totalAmount}</MobileInvoiceCaption>
      </MobileInvoiceBarSection>
    </MobileInvoiceBarContainer>
  );

  const buildView: () => JSX.Element = () => {
    return (
      <>
        <MobileInvoicesContainer>
          {mappedData().map((data, i) => buildInvoiceBar(data, i))}
        </MobileInvoicesContainer>

        {totalRows > 15 && (
          <CustomPagination
            totalRows={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            changePage={changePage}
            changeRowsPerPage={rowsPerPageHandler}
          />
        )}
      </>
    );
  };

  return <div>{buildView()}</div>;
};

export default PurchaseOrderViewMobile;
