/* eslint-disable react-hooks/exhaustive-deps */ import { Checkbox } from '@mui/material';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  GatewayToFinanceDialogActionButtonContainer,
  GatewayToFinanceDialogActionButtonText,
  GatewayToFinanceDialogActionContainer,
  GatewayToFinanceDialogContainer,
  GatewayToFinanceDialogHeader,
  GatewayToFinanceDialogSummaryContainer,
  GatewayToFinanceDialogSummaryInfo,
  GatewayToFinanceDialogSummaryTitle,
  GatewayToFinanceDialogTable,
  GatewayToFinanceDialogTitle,
  GatewayToFinanceDialogTitleBold,
  GatewayToFinanceDialogTitleRegular,
  GatewayToFinanceDialogTitleWrapper,
  GroupTableContainer,
  GroupedTable
} from './styled';

interface GatewayToFinanceDialogDialogProps {
  closeHandler: () => void;
  createInvoiceClickHandler: (lineItems: DataGridRowItem[]) => void;
  lineItems: LedgerLineItem[];
  currencyCode: string;
  g2fCreateInvoiceLoading: boolean;
  g2fCreateInvoiceId: string;
  poNumber: string;
}

export interface HeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
}

const GatewayToFinanceDialog: FC<GatewayToFinanceDialogDialogProps> = ({
  closeHandler,
  createInvoiceClickHandler,
  lineItems,
  currencyCode,
  poNumber
}) => {
  const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
    useState<DataGridRowItem[]>([]);
  const [headerCheckbox, setHeaderCheckbox] = useState<HeaderCheckbox>({
    indeterminate: false,
    checked: true
  });

  const headerCheckboxClickHandler: () => void = () => {
    setHeaderCheckbox({ indeterminate: false, checked: !headerCheckbox.checked });
    !headerCheckbox.indeterminate && setGridDataSelected();
  };

  const headerCheckboxState: () => void = () => {
    const selected: number = data.filter((item) => item.itemSelected === true).length || 0;
    const unSelected: number = data.filter((item) => item.itemSelected === false).length || 0;

    const indeterminate: boolean = selected !== data.length;
    const checked: boolean = selected === data.length || unSelected === data.length;

    return setHeaderCheckbox({ indeterminate, checked });
  };

  const formatNumberStringToNumber: (val: string) => number = (val) =>
    parseFloat(val.split(',').join(''));

  const selectedItems: DataGridRowItem[] = data.filter((item) => item.itemSelected === true);

  const totalExtendedAmount: string = formatNumber(
    selectedItems.reduce(
      (accum, item) => accum + formatNumberStringToNumber(item.extendedAmount),
      0
    ),
    2
  );

  useEffect(() => {
    setData(mapLineItemsToGridModel());
  }, [lineItems]);

  const mapLineItemsToGridModel: () => DataGridRowItem[] = () =>
    lineItems.map((line) => ({
      itemSelected: true,
      lineNumber: line.lineItemNumber,
      itemNumber: line.manufacturerPartNumber,
      taxAmount: line.taxAmount || 0,
      shortDescription: line.shortDescription,
      itemMeasure: line.unitOfMeasure,
      currency: currencyCode,
      quantity: line.quantity || 0,
      unitPrice: formatNumber(line.unitPrice === null ? 0 : line.unitPrice || 0, 2),
      extendedAmount: formatNumber(
        line.extendedPriceBeforeTax === null ? 0 : line.extendedPriceBeforeTax || 0,
        2
      ),
      sku: line.sku
    }));

  const setGridDataSelected: () => void = () => {
    const mapped: DataGridRowItem[] = data.map((g) => {
      g.itemSelected = !headerCheckbox.checked;
      return g;
    });
    setData(mapped);
  };

  const tableBodyCheckboxClickHandler: (item: DataGridRowItem) => void = (
    item: DataGridRowItem
  ) => {
    const mutArr: DataGridRowItem[] = Array.from(data);
    const index: number = mutArr.map((d) => d.lineNumber).indexOf(item.lineNumber);
    if (index < 0) return;
    mutArr[index].itemSelected = !mutArr[index].itemSelected;
    setData(mutArr);
    headerCheckboxState();
  };

  const renderMainView: () => JSX.Element = () => (
    <GatewayToFinanceDialogContainer>
      <GatewayToFinanceDialogTitleWrapper>
        <GatewayToFinanceDialogTitle data-automation-id="gateway-to-finance-dialog-h2-page-title">
          Select the line items you wish to
          <br />
          invoice for
        </GatewayToFinanceDialogTitle>
      </GatewayToFinanceDialogTitleWrapper>
      <GatewayToFinanceDialogHeader data-automation-id="gateway-to-finance-dialog-div-header">
        <GatewayToFinanceDialogTitleRegular>Line Items</GatewayToFinanceDialogTitleRegular>
        <GatewayToFinanceDialogTitleBold>
          {lineItems ? lineItems.length : '0'} Line Item{lineItems && lineItems.length > 0 && 's'}
        </GatewayToFinanceDialogTitleBold>
      </GatewayToFinanceDialogHeader>
      <GatewayToFinanceDialogTable>
        <GroupTableContainer>
          <GroupedTable data-automation-id="gateway-to-finance-dialog-table-grouped-invoice-table">
            <thead style={{ textAlign: 'left' }}>
              <tr>
                <th>
                  <Checkbox
                    style={{
                      color: themeColors.text.primary,
                      backgroundColor: 'transparent',
                      zIndex: 10
                    }}
                    {...headerCheckbox}
                    onChange={() => headerCheckboxClickHandler()}
                    inputProps={
                      {
                        'data-testid': 'sp-clickable-header-checkbox'
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                    data-testid="sp-header-checkbox"
                  />
                </th>
                <th>LINE ITEM NUMBER</th>
                <th>PO NUMBER</th>
                <th>SKU</th>
                <th>PRODUCT/SERVICE DESCRIPTION</th>
                <th>QTY</th>
                <th>UNIT OF MEASURE</th>
                <th>UNIT PRICE</th>
                <th>LINE ITEM EXTENDED AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={`${d.lineNumber}`}>
                  <td>
                    <Checkbox
                      style={{
                        color: themeColors.text.primary,
                        backgroundColor: 'transparent'
                      }}
                      checked={d.itemSelected}
                      onChange={() => tableBodyCheckboxClickHandler(d)}
                      inputProps={
                        {
                          role: 'checkbox',
                          'data-testid': 'sp-clickable-checkbox'
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  </td>
                  <td className="accordion-row">{d.itemNumber}</td>
                  <td className="accordion-row">{poNumber}</td>
                  <td className="accordion-row">{d.itemNumber}</td>
                  <td className="accordion-row">{d.shortDescription}</td>
                  <td className="accordion-row">{d.quantity}</td>
                  <td className="accordion-row">{d.itemMeasure}</td>
                  <td className="accordion-row">
                    {CurrencySymbolsLookUp[currencyCode]}
                    {d.unitPrice}
                  </td>
                  <td className="accordion-row">
                    {CurrencySymbolsLookUp[currencyCode]}
                    {d.extendedAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </GroupedTable>
        </GroupTableContainer>
      </GatewayToFinanceDialogTable>
      <GatewayToFinanceDialogSummaryContainer>
        <GatewayToFinanceDialogSummaryTitle>Summary</GatewayToFinanceDialogSummaryTitle>
        <GatewayToFinanceDialogSummaryInfo>
          <GatewayToFinanceDialogTitleRegular>
            Line items selected
          </GatewayToFinanceDialogTitleRegular>
          <GatewayToFinanceDialogTitleBold>
            {selectedItems.length} / {data.length}
          </GatewayToFinanceDialogTitleBold>
        </GatewayToFinanceDialogSummaryInfo>
        <GatewayToFinanceDialogSummaryInfo>
          <GatewayToFinanceDialogTitleRegular>
            Total extended amount
          </GatewayToFinanceDialogTitleRegular>
          <GatewayToFinanceDialogTitleBold>
            {CurrencySymbolsLookUp[currencyCode]}
            {totalExtendedAmount}
          </GatewayToFinanceDialogTitleBold>
        </GatewayToFinanceDialogSummaryInfo>
        <Divider color={themeColors.bg.light.muted} />
      </GatewayToFinanceDialogSummaryContainer>
      <GatewayToFinanceDialogActionContainer>
        <GatewayToFinanceDialogActionButtonContainer>
          <SecondaryButton
            clickHandler={closeHandler}
            text="Cancel"
            testingTag="gateway-to-finance-dialog-button-cancel"
          />
        </GatewayToFinanceDialogActionButtonContainer>
        <GatewayToFinanceDialogActionButtonContainer>
          <PrimaryButton
            clickHandler={() => createInvoiceClickHandler(selectedItems)}
            testingTag="gateway-to-finance-dialog-button-send-request"
            text="Create invoice in Xero"
          />
        </GatewayToFinanceDialogActionButtonContainer>
      </GatewayToFinanceDialogActionContainer>
    </GatewayToFinanceDialogContainer>
  );

  return (
    <LayoutViewContainer size="small" margin="0 auto" data-testid="gateway-to-finance-dialog">
      {renderMainView()}
    </LayoutViewContainer>
  );
};

export default GatewayToFinanceDialog;
