import { BankAccountCurrency } from 'api/interfaces/bank-account/bankAccountBase.api';
import { EntityBankAccount, EntityProofOfAccount } from 'api/interfaces/entity/entity.interface';
import { FlatCardFlag } from 'components/common/FlatCard/styled';
import ItemCard from 'components/common/cards/ItemCard';
import Divider from 'components/common/divider';
import { FC, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { FETCH_PROOF_OF_ACCOUNT } from 'store/actions';
import {
  CompanyBankAccountDetailContainer,
  CompanyBankAccountDetailInfoWrapper,
  CompanyBankAccountDetailSubtitle,
  CompanyBankAccountDetailSubtitleContainer,
  CompanyBankAccountDetailTitle,
  CompanyBankAccountDetailWrapper,
  ItemCardRow
} from './styled';

interface CompanyBankAccountDetailReadonlyProps {
  closeHandler: () => void;
  pageTitle: string;
}

const CompanyBankAccountDetailReadonly: FC<CompanyBankAccountDetailReadonlyProps> = ({
  pageTitle,
  closeHandler
}) => {
  const dispatch = useDispatch();
  const {
    bankAccountForEdit,
    proofOfAccount
  }: { bankAccountForEdit: EntityBankAccount | null; proofOfAccount: EntityProofOfAccount | null } =
    useSelector((state: RootStateOrAny) => state.program);
  const {
    countries
  }: {
    countries: Record<string, string>;
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    if (bankAccountForEdit?.proofOfAccountId)
      dispatch({ type: FETCH_PROOF_OF_ACCOUNT, payload: bankAccountForEdit.proofOfAccountId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountForEdit]);

  const renderBankDataView: (type: string) => JSX.Element = (type) => {
    switch (type) {
      case 'GB':
        return (
          <>
            <ItemCard title="Account number" value={bankAccountForEdit?.accountNumber || ''} />
            <Divider />
            <ItemCard title="Sort code" value={bankAccountForEdit?.sortCode || ''} />
            <Divider />
          </>
        );
      case 'US':
        return (
          <>
            <ItemCard title="Account number" value={bankAccountForEdit?.accountNumber || ''} />
            <Divider />
            <ItemCard title="Routing number" value={bankAccountForEdit?.routingNumber || ''} />
            <Divider />
          </>
        );
      default:
        return (
          <>
            <ItemCard title="IBAN number" value={bankAccountForEdit?.iban || ''} />
            <Divider />
          </>
        );
    }
  };

  const defaultCurrency: () => boolean = () => {
    const matched: BankAccountCurrency | undefined = bankAccountForEdit?.currencies.find(
      (c) => c.isDefault
    );
    return matched ? matched.isDefault : false;
  };

  return (
    <CompanyBankAccountDetailWrapper>
      <CompanyBankAccountDetailContainer>
        <CompanyBankAccountDetailTitle data-automation-id="company-bank-account-detail-h2-page-title">
          Bank account details
        </CompanyBankAccountDetailTitle>
        <CompanyBankAccountDetailSubtitleContainer>
          <CompanyBankAccountDetailSubtitle>{pageTitle}</CompanyBankAccountDetailSubtitle>
          {defaultCurrency() && (
            <FlatCardFlag>{bankAccountForEdit?.currencies[0]?.currency || ''} DEFAULT</FlatCardFlag>
          )}
        </CompanyBankAccountDetailSubtitleContainer>
        <CompanyBankAccountDetailInfoWrapper>
          <ItemCard title="Bank name" value={bankAccountForEdit?.bankName || ''} />
          <Divider />
          <ItemCard
            title="Country of bank"
            value={bankAccountForEdit?.bankCountry ? countries[bankAccountForEdit.bankCountry] : ''}
          />
          <Divider />
          <ItemCard title="Currency" value={bankAccountForEdit?.currencies[0].currency || ''} />
          <Divider />
          {/* NOT IN FIGMA PLAYTHROUGH */}
          {/* <ItemCard title="Account holder" value={registeredName} />
          <Divider /> */}
          <ItemCardRow>{renderBankDataView(bankAccountForEdit?.bankCountry || '')}</ItemCardRow>
          <ItemCard
            title="Proof of account document"
            list={[{ name: proofOfAccount?.name || '' }]}
          />
          <Divider />
          <ItemCard title="Default for currency" value={defaultCurrency() ? 'Yes' : 'No'} />
        </CompanyBankAccountDetailInfoWrapper>
        {/* DISABLED UNTIL 4.0.0 */}
        {/* <CompanyBankAccountDetailButtonWrapper>
          <SecondaryButton
            backgroundColor={themeColors.white}
            textColor={themeColors.text.primary}
            clickHandler={closeHandler}
            testingTag="company-bank-account-detail-button-edit"
            data-testid="sp-company-bank-account-detail-button-edit"
          >
            <CompanyBankAccountDetailButtonText data-automation-id="company-bank-account-detail-p-button-edit-text">
              Edit
            </CompanyBankAccountDetailButtonText>
          </SecondaryButton>
        </CompanyBankAccountDetailButtonWrapper> */}
      </CompanyBankAccountDetailContainer>
    </CompanyBankAccountDetailWrapper>
  );
};

export default CompanyBankAccountDetailReadonly;
