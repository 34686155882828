export const FETCH_FUNDING_REQUEST_CALCULATION =
  '@@frontend/store/reducers/fundingRequest/FETCH_FUNDING_REQUEST_CALCULATION';
export const FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS =
  '@@frontend/store/reducers/fundingRequest/FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS';
export const FETCH_FUNDING_REQUEST_CALCULATION_FAILURE =
  '@@frontend/store/reducers/fundingRequest/FETCH_FUNDING_REQUEST_CALCULATION_FAILURE';
export const COMPLETE_FUNDING_REQUEST =
  '@@frontend/store/reducers/fundingRequest/COMPLETE_FUNDING_REQUEST';
export const COMPLETE_FUNDING_REQUEST_SUCCESS =
  '@@frontend/store/reducers/fundingRequest/COMPLETE_FUNDING_REQUEST_SUCCESS';
export const COMPLETE_FUNDING_REQUEST_FAILURE =
  '@@frontend/store/reducers/fundingRequest/COMPLETE_FUNDING_REQUEST_FAILURE';
export const FUNDING_REQUEST_RESET =
  '@@frontend/store/reducers/fundingRequest/FUNDING_REQUEST_RESET';
export const UPDATE_FUNDING_REQUEST_CALCULATION =
  '@@frontend/store/reducers/fundingRequest/UPDATE_FUNDING_REQUEST_CALCULATION';
export const UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS =
  '@@frontend/store/reducers/fundingRequest/UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS';
export const UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE =
  '@@frontend/store/reducers/fundingRequest/UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE';
export const SET_EARLY_PAYMENT_DIALOG_OPEN =
  '@@frontend/store/reducers/fundingRequest/SET_EARLY_PAYMENT_DIALOG_OPEN';
