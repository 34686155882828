import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const PrivacyIcon: FC<IconSvgProps> = ({ height = '60', width = '60' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.8367 14.0196C54.3597 42.9694 35.8648 54.9481 31.1075 57.5467C30.4089 57.9282 29.5744 57.9648 28.8471 57.6418C23.9797 55.4804 5.45033 45.1898 6.17123 14.0196L6.17199 14.0195C12.1406 13.0422 16.4979 8.06565 16.4735 2.25386L16.4726 2.04822H43.5351L43.5342 2.25463C43.5103 8.06642 47.868 13.0427 53.8367 14.0196Z"
      fill="white"
    />
    <path
      d="M39.1043 47.4009C34.237 45.2396 15.7076 34.949 16.4285 3.77886L16.4726 2.04822L16.4735 2.25386C16.4979 8.06565 12.1407 13.0422 6.17199 14.0196L6.17123 14.0198C5.45033 45.1898 23.9797 55.4804 28.847 57.6417C29.5743 57.9646 30.4089 57.9282 31.1074 57.5465C33.5497 56.2126 39.6124 52.405 44.864 45.0994C43.4033 46.1369 42.1815 46.8596 41.3647 47.3056C40.6662 47.6873 39.8317 47.7239 39.1043 47.4009C34.2371 45.2395 39.8317 47.7239 39.1043 47.4009Z"
      fill="#E7EAF8"
    />
    <path
      d="M28.0133 59.5132C10.6195 51.3783 3.70155 32.0819 4.1204 13.9723C4.14194 13.0364 4.79643 12.2344 5.71002 12.0238C10.6157 10.8926 14.459 7.36462 14.4213 2.05679C14.4166 0.937051 15.3512 0 16.4728 0H43.5353C44.6567 0 45.5912 0.936667 45.5867 2.05628C45.4781 7.04987 49.3166 11.2044 54.1686 11.9985C55.1466 12.1585 55.87 12.9936 55.8878 13.9827C56.2107 31.8592 48.3404 50.4682 32.0921 59.3435C31.4081 59.7171 30.1595 60.5168 28.0133 59.5132ZM29.6808 55.7703C29.8226 55.8331 29.9837 55.8258 30.1231 55.7497C35.2277 52.9614 51.6195 41.9387 51.7949 15.656C46.4909 14.1281 42.346 9.60718 41.6034 4.09641H18.4048C17.6614 9.61128 13.5113 14.1342 8.20232 15.6592C8.17361 31.3751 14.285 48.9335 29.6808 55.7703C29.8226 55.8332 27.5139 54.8081 29.6808 55.7703Z"
      fill="#0032A0"
    />
    <path
      d="M29.9992 40.8791C37.0097 40.8791 42.6927 35.18 42.6927 28.1497C42.6927 21.1195 37.0097 15.4204 29.9992 15.4204C22.9888 15.4204 17.3058 21.1195 17.3058 28.1497C17.3058 35.18 22.9888 40.8791 29.9992 40.8791Z"
      fill="#C09C2F"
    />
    <path
      d="M26.3635 33.2113L22.6632 28.9003L25.7787 26.2347L28.0208 28.8469L34.3985 22.3337L37.3323 25.1973L29.3881 33.3103C29.0015 33.7051 27.7555 34.4562 26.3635 33.2113Z"
      fill="white"
    />
  </svg>
);

export default PrivacyIcon;
