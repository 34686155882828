import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface IIcon {
  color?: string;
  disabled?: boolean;
}

interface IInView {
  inView?: boolean;
}

export const LedgerDetailCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LedgerDetailCommentsRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${themeColors.bg.light.body};
  border-radius: 16px;
  min-height: 112px;
  padding: 16px;
`;

export const LedgerDetailCommentsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const LedgerDetailCommentsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const CommentIconContainer = styled.div<IIcon>`
  display: flex;
  background-color:${({ color }) => color};
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 2px solid ${themeColors.border.light.subtle};
  box-shadow: 0px 1px 1px 0px rgba(48, 49, 51, 0.1),  0px 0px 1px 0px rgba(48, 49, 51, 0.05);
`;

export const LedgerDetailCommentsInitials = styled.p`
  color: ${themeColors.text.onPrimary};
`;

export const LedgerDetailCommentsHeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const LedgerDetailCommentsTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const LedgerDetailCommentsTitle = styled.p`
  display: flex;
  ${typography.bodyBold};
  padding-right: 8px;
  border-right: 1px solid ${themeColors.text.light.subtle};
`;

export const LedgerDetailCommentsSubtitle = styled.p`
  display: flex;
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body};
`;

export const LedgerDetailCommentsCard = styled.div`
  display: flex;
  padding: 10px;
  align-self: flex-start;
`;

export const LedgerDetailCommentsCardText = styled.p``;

export const LedgerDetailCommentsDateTime = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.subtle};
`;

export const CommentIconWrapper = styled.div<IIcon>`
  display: flex;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: pointer;
`;

export const LedgerDetailCommentsButtonContainer = styled.div<IInView>`
  display: flex;
  flex-direction: ${({ inView }) => (inView ? 'column' : 'row')};
  align-items: ${({ inView }) => (inView ? 'flex-end' : 'center')};
  justify-content: ${({ inView }) => (inView ? '' : 'space-between')};
  margin: 16px 0;
`;

export const LedgerDetailCommentLink = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
`;

export const LedgerDetailCommentsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 50rem;
  overflow-y: auto;
  padding: 8px;
  margin: 0 -8px;
`;
