import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const FRMobileInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FRMobileInvoiceBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 56px;
  border-bottom: 1px solid ${themeColors.bg.light.muted};
`;

export const FRMobileInvoiceBarSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FRMobileInvoiceSemiBoldText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const FRMobileInvoiceCaption = styled.p`
  margin: 0;
  ${typography.captionRegular};
`;
