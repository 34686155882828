import BaseCard from 'components/common/cards/BaseCard';
import Sidebar from 'components/sidebar';
import { ERPSelectionTiles } from 'hipster-sdk';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ConnectionsContainer, ConnectionsPageTitle, ConnectionsWrapper } from './styled';

const Connections: FC = () => {
  const { g2fAccessToken, g2fCustUID, tenant } = useSelector((state: RootStateOrAny) => state.app);

  return (
    <ConnectionsWrapper
      data-automation-id="connections-div-container"
      data-testid="sp-connections-container"
    >
      <Sidebar />
      <ConnectionsContainer>
        <ConnectionsPageTitle data-automation-id="connections-h2-page-title">
          Connections
        </ConnectionsPageTitle>
        <BaseCard>
          {g2fAccessToken && g2fCustUID && (
            <ERPSelectionTiles
              setup={{
                token: g2fAccessToken,
                redirectPath: 'https://g2fauth-staging.finecta.dev/redirect',
                customerUID: g2fCustUID,
                platformRedirectPath: `${window.location.origin}/app/${tenant}/connections`,
                g2fDomain: `https://g2fapi-staging.finecta.dev`
              }}
            />
          )}
        </BaseCard>
      </ConnectionsContainer>
    </ConnectionsWrapper>
  );
};

export default Connections;
