import CheckIcon from 'assets/icons/CheckIcon';
import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import IToDo from 'utils/classes/todos/todo';
import { ToDoContentRow } from '../styled';
import {
  ToDoButtonContainer,
  ToDoHeader,
  ToDoIconContainer,
  ToDoSegment,
  ToDoTextContainer,
  ToDoTextDescription
} from './styled';

const ToDo: FC<IToDo> = ({ title, description, isComplete, buttonJsx, customIcon }) => {
  return (
    <ToDoContentRow>
      <ToDoSegment>
        <ToDoIconContainer>
          {customIcon ? (
            customIcon
          ) : (
            <CheckIcon
              height="20"
              width="20"
              color={isComplete ? themeColors.icon.success : themeColors.icon.light.default}
            />
          )}
        </ToDoIconContainer>
        <ToDoTextContainer>
          <ToDoHeader
            isComplete={isComplete}
            data-automation-id={`onboarding-h4-todo-title`}
            data-testid={`sp-onboarding-h4-todo-title`}
          >
            {title}
          </ToDoHeader>
          <ToDoTextDescription
            isComplete={isComplete}
            data-automation-id={`onboarding-p-todo-description`}
            data-testid={`sp-onboarding-p-todo-description`}
          >
            {description}
          </ToDoTextDescription>
        </ToDoTextContainer>
      </ToDoSegment>
      <ToDoButtonContainer
        data-automation-id={`onboarding-div-todo-button-container`}
        data-testid={`sp-onboarding-div-todo-button-container`}
      >
        {!isComplete && buttonJsx}
      </ToDoButtonContainer>
    </ToDoContentRow>
  );
};

export default ToDo;
