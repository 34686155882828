/* eslint-disable react-hooks/exhaustive-deps */

import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import React, { FC, useState } from 'react';
import LedgerCreateInvoice from 'components/ledger/LedgerCreateInvoice';
import FirstPoFlipDialog from './FirstPoFlipDialog';

interface CreateInvoiceDialogProps {
  po: ILedgerPurchaseOrder;
  closeHandler: () => void;
  purchaseOrderId: string;
}

export interface HeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
}

const CreateInvoiceDialog: FC<CreateInvoiceDialogProps> = ({
  po,
  closeHandler,
  purchaseOrderId
}) => {
  const [showFirstDialog, setShowFirstDialog] = useState<boolean>(true);

  const handleDialog: (show: boolean) => void = (show) => setShowFirstDialog(show);

  return (
    <LayoutViewContainer size="medium" margin="0 auto" data-testid="create-invoice-dialog">
      {showFirstDialog && (
        <FirstPoFlipDialog
          closeHandler={closeHandler}
          saveDialog={() => handleDialog(false)}
          purchaseOrderId={purchaseOrderId}
          po={po}
        />
      )}
      {!showFirstDialog && (
        <LedgerCreateInvoice closeDialogHandler={() => handleDialog(!showFirstDialog)} />
      )}
    </LayoutViewContainer>
  );
};

export default CreateInvoiceDialog;
