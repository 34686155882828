import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IInvoiceMatchingResult } from 'api/interfaces/open-account/invoices/invoices.interface';
import OpenAccountInvoiceApi from 'api/open-account/invoices/invoices';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  GET_INVOICE_COMMENTS,
  GET_INVOICE_COMMENTS_FAILURE,
  GET_INVOICE_COMMENTS_SUCCESS,
  GET_INVOICE_MATCHING_RESULTS,
  GET_INVOICE_MATCHING_RESULTS_FAILURE,
  GET_INVOICE_MATCHING_RESULTS_SUCCESS,
  POST_INVOICE_COMMENTS,
  POST_INVOICE_COMMENTS_FAILURE,
  POST_INVOICE_COMMENTS_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchInvoiceMatchingResults: (action: any) => Promise<IInvoiceMatchingResult> = async (
  action
) => {
  const openAccountInvoicesApi = new OpenAccountInvoiceApi(store);
  const res: AxiosResponse<IInvoiceMatchingResult> =
    await openAccountInvoicesApi.getOpenAccountInvoiceMatchingResults(action.payload);
  return res.data;
};

export const postInvoiceComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountInvoicesApi = new OpenAccountInvoiceApi(store);
  const { invoiceId, data } = action.payload;
  await openAccountInvoicesApi.postInvoiceComment(invoiceId, data);
  return await getInvoiceComments(action);
};

export const getInvoiceComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountInvoicesApi = new OpenAccountInvoiceApi(store);
  return openAccountInvoicesApi.getInvoiceComments(action.payload.invoiceId);
};

//===================================================
//                      EPICS
//===================================================

const getInvoiceMatchingResults$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_INVOICE_MATCHING_RESULTS),
    switchMap((action) =>
      from(fetchInvoiceMatchingResults(action)).pipe(
        map((payload) => ({ type: GET_INVOICE_MATCHING_RESULTS_SUCCESS, payload })),
        catchError((error) =>
          of({ type: GET_INVOICE_MATCHING_RESULTS_FAILURE, payload: error.message })
        )
      )
    )
  );

const getInvoiceComments$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_INVOICE_COMMENTS),
    switchMap((action) =>
      from(getInvoiceComments(action)).pipe(
        map((payload) => ({ type: GET_INVOICE_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_INVOICE_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

const postInvoiceComments$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(POST_INVOICE_COMMENTS),
    switchMap((action) =>
      from(postInvoiceComments(action)).pipe(
        map((payload) => ({ type: POST_INVOICE_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: POST_INVOICE_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(getInvoiceMatchingResults$, postInvoiceComments$, getInvoiceComments$);
