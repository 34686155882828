import systemDownJson from 'assets/lottie-animations/system_down.json';
import Lottie from 'lottie-react';
import { FC } from 'react';
import {
  NotFoundPageContainer,
  NotFoundPageContainerDescription,
  NotFoundPageContainerError,
  NotFoundPageContainerPageTitle,
  NotFoundPageLeftContainer,
  NotFoundPageRightContainer
} from './styled';

const SystemDown: FC = () => {
  return (
    <NotFoundPageContainer
      data-automation-id="SystemDown-div-container"
      data-testid="sp-system-down-container"
    >
      <NotFoundPageLeftContainer>
        <NotFoundPageContainerError>500 - Internal error</NotFoundPageContainerError>
        <NotFoundPageContainerPageTitle>Platform not available</NotFoundPageContainerPageTitle>
        <NotFoundPageContainerDescription>
          The platform is currently unavailable. Please contact us.
        </NotFoundPageContainerDescription>
      </NotFoundPageLeftContainer>
      <NotFoundPageRightContainer>
        <Lottie
          style={{
            maxHeight: '40vh'
          }}
          loop
          animationData={systemDownJson}
        />
      </NotFoundPageRightContainer>
    </NotFoundPageContainer>
  );
};

export default SystemDown;
