import { isMobile } from 'lib/helpers/mobile';
import Checkbox from '@mui/material/Checkbox';
import Step from '@mui/material/Step';
import styled from 'styled-components';
import { styling, themeColors } from 'assets/theme/style';
import { fontWeights, typography } from 'assets/theme/typography';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const QontoStepIconRoot = styled.div<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: themeColors.text.light.muted,
    display: 'flex',
    height: 24,
    alignItems: 'center',
    ...(ownerState.active && {
      color: themeColors.icon.primary
    }),
    '& .QontoStepIcon-completedIcon': {
      color:  themeColors.icon.primary,
      zIndex: 1,
      fontSize: 18
    },
    '& .QontoStepIcon-circle': {
      color: themeColors.text.light.muted,
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    }
  })
);

export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.border.primary
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.border.primary
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: themeColors.text.light.muted,
    borderTopWidth: 3,
    borderRadius: 3
  }
}));

export const OnboardingLayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${themeColors.white};
  padding-bottom: 60px;
`;

export const FormStyled = styled.form`
  width: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiOutlinedInput-input {
    height: 52px !important;
  }
`;

export const StepContainer = styled(Step)`
  .MuiSvgIcon-root {
    color: ${themeColors.text.light.muted};
  }
  .MuiStepLabel-root {
    .MuiStepLabel-iconContainer {
      .Mui-active {
        color: ${themeColors.royalGold};
      }
      .Mui-completed {
        color: ${themeColors.icon.primary};
      }
    }
    .MuiStepLabel-labelContainer {
      color: ${themeColors.text.light.muted};

      .Mui-active {
        color: ${themeColors.royalGold};
      }
      .Mui-completed {
        color: ${themeColors.icon.primary};
      }
    }
  }
`;

export const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const OnboardingHeader = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .MuiCollapse-wrapper {
    min-width: 500px;
  }
`;

export const OnboardingTitle = styled.h2`
  color: ${themeColors.text.light.body};
  text-align: center;
`;

export const OnboardingContentTitle = styled.h3`
  color: ${themeColors.text.light.body};
`;

export const OnboardingLogo = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const OnboardingContentCaption = styled.h4`
  color: ${themeColors.text.light.body};
  margin: 24px 0 8px 0;
  &:first-child {
    margin-top: 0;
  }
`;

export const OnboardingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const OnboardingContentText = styled.p`
  margin: 0;
  ${typography.bodyRegular};
`;

export const OnboardingContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 690px);
  min-height: 250px;
  max-height: 1000px;
  overflow-y: scroll;
  margin-top: 24px;
`;

export const OnboardingDescription = styled.p`
  color: ${themeColors.mono1};
  text-align: center;
  span {
    ${typography.bodyBold};
    color: ${themeColors.text.primary};
    cursor: pointer;
  }
`;

export const OnboardingCaption = styled.span`
  color: ${themeColors.text.primary};
  font-family: 'roc-grotesk', sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: 2.25rem; //36px
  text-align: center;
`;

export const OnboardingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OnboardingCheckboxMessage = styled.p`
  margin: 0;
  ${typography.captionRegular};
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0 !important;
  align-items: flex-start !important;
  color: ${themeColors.text.light.muted} !important;
  background-color: transparent !important;
  &.Mui-checked {
    color: ${themeColors.text.primary} !important;
  }
  &.Mui-disabled {
    color: ${themeColors.text.light.muted} !important;
  }
`;

export const OnboardingFormFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const OnboardingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'auto'};
  margin-top: 8px;
`;

export const OnboardingButtonText = styled.p`
  ${typography.bodySemiBold};
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

export const OnboardingContentFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${themeColors.bg.light.page};
  border-bottom-left-radius: ${styling.defaultRadius};
  border-bottom-right-radius: ${styling.defaultRadius};
  padding: 16px;
`;

export const OnboardingContentDownload = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 10rem;
`;

export const OnboardingContentDownloadText = styled.p`
  ${typography.captionBold};
  color: ${themeColors.text.primary};
  margin: 0;
  cursor: pointer;
`;

export const OnboardingContentButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const OnboardingHelpButton = styled.div`
  position: fixed;
  bottom: 60px;
  right: 59px;
`;

export const SnackbarContainer = styled.div`
  display: flex;
  width: 30rem;
  flex-direction: column;
  margin-bottom: 16px;
`;
