import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const TabsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsHeaderMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const TabPanelContainer = styled.div``;

export const TabsStyled = styled(Tabs)`

  &.MuiTabs-root {
    min-height: auto;
    .MuiTabs-flexContainer{
      gap: 16px;
    }
  }

  .MuiButtonBase-root {
    font-family: 'roc-grotesk', sans-serif !important;
    min-width: auto;
    background-color: ${themeColors.bg.light.surface};
    color: ${themeColors.text.light.subtle};
    ${typography.captionRegular};
    opacity: 1;
    text-transform: none;
    border-radius: 25px;
    padding: 8px, 16px, 8px, 16px;
    max-height: 36px;
    min-height: auto;
    height: 36px;
  }

  .Mui-selected {
    background-color: ${themeColors.bg.light.surfaceInvert};
    color: ${themeColors.text.light.onInvert};
    ${typography.captionBold};
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

export const TabContainer = styled(Tab)``;
