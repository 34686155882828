import { FC } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimelineProps } from 'utils/interfaces/timeline/timeline.interface';

import {
  AccordionStyled,
  TimelineAccordionHeader,
  TimelineAccordionTitle,
  TimelineAccordionText,
  TimelineAccordionStatus
} from './styled';

const Timeline: FC<TimelineProps> = ({
  title,
  info,
  disabled,
  displayChildren,
  icon,
  opacity,
  childJSX
}) => {
  return (
    <AccordionStyled
      disableGutters
      disabled={disabled}
      defaultExpanded={disabled && displayChildren}
    >
      <AccordionSummary
        expandIcon={disabled ? '' : <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TimelineAccordionHeader data-automation-id="timeline-accordion-div-header">
          {icon}
          <TimelineAccordionText opacity={opacity ? '1' : '0.6'}>
            <TimelineAccordionTitle data-automation-id="timeline-accordion-h5-title">
              {title}
            </TimelineAccordionTitle>
            <TimelineAccordionStatus data-automation-id="timeline-accordion-p-description">
              {info}
            </TimelineAccordionStatus>
          </TimelineAccordionText>
        </TimelineAccordionHeader>
      </AccordionSummary>
      <AccordionDetails style={{ paddingBottom: 0, marginBottom: '-30px' }}>
        {childJSX}
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default Timeline;
