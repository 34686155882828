import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ClearIcon: FC<IconSvgProps> = ({
  color = themeColors.iconBlue,
  height = '14px',
  width = '14px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6129 0.209705C1.22061 -0.0953203 0.653377 -0.0675907 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929L0.209705 12.3871C-0.0953203 12.7794 -0.0675907 13.3466 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071L12.3871 13.7903C12.7794 14.0953 13.3466 14.0676 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711L13.7903 1.6129C14.0953 1.22061 14.0676 0.653377 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893L1.6129 0.209705Z"
      fill={color}
    />
  </svg>
);

export default ClearIcon;
