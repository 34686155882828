/* eslint-disable react-hooks/exhaustive-deps */
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ReducerAction } from 'store/reducers';
import {
  G2FNoContactDialog,
  G2FNoContactDialogActionsRow,
  G2FNoContactDialogContainer,
  G2FNoContactDialogHeaderContainer,
  LedgerDetailCreateInvoiceBannerButtonContainer,
  LedgerDetailCreateInvoiceBannerButtonContentContainer,
  LedgerDetailCreateInvoiceBannerButtonText,
  LedgerDetailCreateInvoiceBannerButtonWrapper,
  LedgerDetailCreateInvoiceBannerContainer,
  LedgerDetailCreateInvoiceBannerContentContainer,
  LedgerDetailCreateInvoiceBannerDescription,
  LedgerDetailCreateInvoiceBannerIconContainer,
  LedgerDetailCreateInvoiceEdit
} from './styled';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import GatewayToFinanceDialog from 'views/PurchaseOrders/GatewayToFinanceDialog';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import CreateInvoiceDialog from 'views/PurchaseOrders/CreateInvoiceDialog';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  RESET_GATEWAY_TO_FINANCE_ERROR,
  RESET_GATEWAY_TO_FINANCE_ID,
  SET_GATEWAY_TO_FINANCE_ID,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE,
  SHOW_GATEWAY_TO_FINANCE_BAR
} from 'store/actions/gatewayToFinance';
import { formatNumberStringToNumber } from 'lib/helpers/formatters/convertNumberStringtoNumber';
import {
  RESET_NEW_INVOICE_ID,
  HIDE_NEW_INVOICE_BAR,
  RESET_CREATE_NEW_INVOICE_ERROR
} from 'store/actions';
import { IG2FExternalCustomer } from 'utils/interfaces/g2f/g2f.interface';
import CloseIcon from 'assets/icons/CloseIcon';
import Divider from 'components/common/divider';
import { SelectStyled } from 'components/sidebar/styled';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { SelectMenuItem } from 'components/forms/inputs/Select';
import Notification from 'components/Notification';
import InfoIcon from 'assets/icons/InfoIcon';
import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import { DateTime } from 'luxon';
import { NavigateFunction, useNavigate } from 'react-router';
import { getCookie } from 'lib/helpers/cookieHelper';

interface GatewayToFinanceButtonProps {
  showText?: boolean;
  showOnlyButton?: boolean;
  primaryButton?: boolean;
}

const GatewayToFinanceButton: FC<GatewayToFinanceButtonProps> = ({
  showText = false,
  showOnlyButton = false,
  primaryButton = false
}) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [g2fDialogOpen, setG2fDialogOpen] = useState<boolean>(false);
  const [g2fNoContactDialog, setG2fNoContactDialog] = useState<boolean>(false);
  const [g2fSelectedCustomer, setG2fSelectedCustomer] = useState<string>('');
  const [g2fNoContactSelectOptions, setG2fNoContactSelectOptions] = useState<SelectMenuItem[]>([]);
  const [showPoFlipBanner, setShowPoFlipBanner] = useState<boolean>(false);

  const {
    draft
  }: {
    draft: boolean;
  } = useSelector((state: RootStateOrAny) => state.createInvoice);

  const {
    g2fAccessToken,
    g2fCustUID,
    g2fConnection,
    g2fFeatureHidden
  }: {
    g2fAccessToken: string | null;
    g2fCustUID: string | null;
    g2fConnection: any;
    g2fFeatureHidden: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    poForView
  }: {
    poForView: ILedgerPurchaseOrder | null;
  } = useSelector((state: RootStateOrAny) => state.purchaseOrder);

  const {
    loading: g2fCreateInvoiceLoading,
    g2fCreateInvoiceId,
    g2fBuyerSelection
  }: {
    loading: boolean;
    g2fCreateInvoiceId: string;
    g2fBuyerSelection: IG2FExternalCustomer[];
  } = useSelector((state: RootStateOrAny) => state.gatewayToFinance);

  const lineItemsData: LedgerLineItem[] = poForView?.data.lineItems || [];
  const currencyCode: string = poForView?.data.currency || '';

  useEffect(() => {
    getCookie('spPoFlipEnabled') === 'true'
      ? setShowPoFlipBanner(true)
      : setShowPoFlipBanner(false);
  }, []);

  const createInvoiceClickHandler: (lineItems: DataGridRowItem[]) => void = (
    lineItems: DataGridRowItem[]
  ) => {
    if (!poForView) throw Error('NO PO STORED.');
    dispatch({ type: RESET_GATEWAY_TO_FINANCE_ERROR });
    dispatch({ type: SHOW_GATEWAY_TO_FINANCE_BAR });

    const mappedLineItems = lineItems.map((l) => {
      return {
        description: `${l.lineNumber || 1} :: ${l.sku} :: ${l.shortDescription}` || '-',
        quantity: l.quantity,
        unitAmount: formatNumberStringToNumber(l.unitPrice),
        taxAmount: l.taxAmount,
        lineItemId: l.lineNumber
      };
    });

    const data = {
      currency: currencyCode,
      issueDate: poForView.data.createdDate || DateTime.now().toISO(),
      dueDate: poForView.data.paymentDueDate || DateTime.utc(2023, 2, 25).toISO(),
      reference: poForView.data.poNumber || '',
      lineItems: mappedLineItems
    };

    dispatch({ type: SET_GATEWAY_TO_FINANCE_ID, payload: { data, setup } });
  };

  const confirmResetDraft: () => void = () => {
    dispatch({ type: RESET_NEW_INVOICE_ID });
    dispatch({ type: RESET_CREATE_NEW_INVOICE_ERROR });
  };

  const toggleDialog: () => void = () => {
    setG2fDialogOpen(!g2fDialogOpen);
    confirmResetDraft();
  };

  const createInvoiceDialogClickHandler: (lineItems: DataGridRowItem[]) => void = (lineItems) => {
    createInvoiceClickHandler(lineItems);
    setG2fDialogOpen(!g2fDialogOpen);
  };

  const setup = {
    token: g2fAccessToken,
    redirectPath: 'https://g2fauth-staging.finecta.dev/redirect',
    customerUID: g2fCustUID,
    platformRedirectPath: `${window.location.origin}/connections?isLinkFrom=true`
  };

  const g2fCustomerContinueClickHandler: () => void = () => {
    dispatch({
      type: SET_GATEWAY_TO_FINANCE_ID_CONTINUE,
      payload: { externalCustomerRef: g2fSelectedCustomer, setup }
    });
    setG2fNoContactDialog(false);
  };

  useEffect(() => {
    if (g2fBuyerSelection?.length > 0) {
      const mappedData: SelectMenuItem[] = g2fBuyerSelection?.map((b) => ({
        name: b.name,
        value: b.externalCustomerRef
      }));
      setG2fNoContactSelectOptions(mappedData);
    }
  }, [g2fBuyerSelection]);

  useEffect(() => {
    if (g2fCreateInvoiceLoading && g2fBuyerSelection?.length > 0 && !g2fCreateInvoiceId)
      setG2fNoContactDialog(true);
  }, [g2fCreateInvoiceLoading, g2fBuyerSelection, g2fCreateInvoiceId]);

  const g2fCustomerSelectChangeHandler: (event: SelectChangeEvent<unknown>) => void = (event) =>
    setG2fSelectedCustomer(event.target.value as any);

  const closeBanner: () => void = () => {
    if (!draft) {
      dispatch({ type: RESET_GATEWAY_TO_FINANCE_ID });
      dispatch({ type: RESET_NEW_INVOICE_ID });
    }

    dispatch({ type: HIDE_NEW_INVOICE_BAR });
  };

  const renderCreateInvoiceView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        backgroundColor={themeColors.bg.light.surface}
        padding="16px"
        border
        closeHandler={closeBanner}
        hasClosed
        titleLarge
        descriptionLarge
        noShadow
        title="Create an invoice for this purchase order"
        icon={
          <InfoIcon
            height="24px"
            width="24px"
            color={themeColors.icon.primary}
            fill={themeColors.icon.primary}
          />
        }
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              You can generate and invoice from this purchase order and edit before submission.
            </LedgerDetailCreateInvoiceBannerDescription>
            <LedgerDetailCreateInvoiceBannerButtonWrapper>
              <LedgerDetailCreateInvoiceBannerButtonContainer data-testid="sp-g2f-banner-button">
                <PrimaryButton
                  backgroundColor={themeColors.bg.light.surfaceInvert}
                  width="fit-content"
                  clickHandler={toggleDialog}
                  testingTag="-ledger-detail-g2f-banner-button"
                >
                  <LedgerDetailCreateInvoiceBannerButtonContentContainer>
                    <LedgerDetailCreateInvoiceBannerButtonText
                      data-automation-id="ledger-detail-G2F-banner-button-text"
                      data-testid="sp-g2f-banner-button-text"
                    >
                      Create invoice
                    </LedgerDetailCreateInvoiceBannerButtonText>
                    <LedgerDetailCreateInvoiceBannerIconContainer
                      data-automation-id="ledger-detail-div-g2f-banner-button-icon-container"
                      data-testid="sp-g2f-banner-button-icon"
                    >
                      <RightLongArrowIcon
                        color={themeColors.text.onPrimary}
                        height="10"
                        width="12"
                      />
                    </LedgerDetailCreateInvoiceBannerIconContainer>
                  </LedgerDetailCreateInvoiceBannerButtonContentContainer>
                </PrimaryButton>
              </LedgerDetailCreateInvoiceBannerButtonContainer>
            </LedgerDetailCreateInvoiceBannerButtonWrapper>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderEditTextView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceEdit onClick={toggleDialog}>Edit</LedgerDetailCreateInvoiceEdit>
  );

  const renderEditButtonView: () => JSX.Element = () =>
    primaryButton ? (
      <PrimaryButton
        backgroundColor={themeColors.bg.light.surfaceInvert}
        text="Edit invoice"
        clickHandler={() => toggleDialog()}
        testingTag="early-payment-request-button-modified-invoice-selection-cancel"
        width="fit-content"
      />
    ) : (
      <SecondaryButton
        backgroundColor={themeColors.bg.light.surface}
        text="Edit invoice"
        clickHandler={() => toggleDialog()}
        testingTag="early-payment-request-button-modified-invoice-selection-cancel"
        width="fit-content"
      />
    );

  const g2fDialogRender: () => JSX.Element = () => (
    <G2FNoContactDialog
      fullWidth
      maxWidth="sm"
      onClose={() => setG2fDialogOpen(false)}
      open={g2fNoContactDialog}
    >
      <G2FNoContactDialogContainer>
        <G2FNoContactDialogHeaderContainer>
          <h4>Select buyer</h4>
          <span style={{ cursor: 'pointer' }} onClick={() => setG2fNoContactDialog(false)}>
            <CloseIcon color={themeColors.icon.primary} />
          </span>
        </G2FNoContactDialogHeaderContainer>
        <Divider />
        <p>
          The buyer name cannot be found in your accounting software - this could be because the
          names do not match or the buyer does not exist in your accounting software.
          <br />
          <br />
          You can select the buyer name from the list below, or add the name within the accounting
          software and try again.
          <br />
          <br />
        </p>
        <SelectStyled
          id="g2f-select-buyer"
          label=""
          placeholder="Select buyer"
          value={g2fSelectedCustomer}
          onChange={(event: SelectChangeEvent<unknown>) => g2fCustomerSelectChangeHandler(event)}
          displayEmpty
        >
          {g2fNoContactSelectOptions.map((item, i) => (
            <MenuItem key={i} value={item.value} data-automation-id="select-li-item">
              {item.name}
            </MenuItem>
          ))}
        </SelectStyled>
        <G2FNoContactDialogActionsRow>
          <PrimaryButton text="Continue" clickHandler={() => g2fCustomerContinueClickHandler()} />
          <SecondaryButton
            text="Cancel"
            clickHandler={() => setG2fNoContactDialog(false)}
            testingTag="early-payment-request-button-modified-invoice-selection-cancel"
            width="75px"
          />
        </G2FNoContactDialogActionsRow>
      </G2FNoContactDialogContainer>
    </G2FNoContactDialog>
  );

  const renderView: () => JSX.Element = () =>
    showPoFlipBanner ? (
      showText ? (
        renderEditTextView()
      ) : showOnlyButton ? (
        renderEditButtonView()
      ) : (
        renderCreateInvoiceView()
      )
    ) : (
      <></>
    );

  return (
    <>
      {renderView()}
      {g2fFeatureHidden && g2fDialogOpen && poForView && (
        <FullViewDialog
          open={g2fDialogOpen}
          clickHandler={toggleDialog}
          dialogContent={
            g2fConnection?.active ? (
              <GatewayToFinanceDialog
                closeHandler={toggleDialog}
                poNumber={poForView?.data?.poNumber || ''}
                lineItems={lineItemsData}
                currencyCode={currencyCode}
                createInvoiceClickHandler={createInvoiceDialogClickHandler}
                g2fCreateInvoiceLoading={g2fCreateInvoiceLoading}
                g2fCreateInvoiceId={g2fCreateInvoiceId}
              />
            ) : (
              <CreateInvoiceDialog
                closeHandler={toggleDialog}
                purchaseOrderId={poForView?.purchaseOrderId || ''}
                po={poForView}
              />
            )
          }
          data-testid="sp-purchase-order-detail-g2f-line-items-dialog"
        />
      )}
      {g2fDialogRender()}
    </>
  );
};
export default GatewayToFinanceButton;
