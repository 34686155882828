import { Params, useParams } from 'react-router';
import { FC, useEffect, useState } from 'react';
import TradePaymentDetail from 'views/Payments/PaymentDetail/TradePaymentDetail';
import { store } from 'store';
import InvoiceApi from 'api/ledger/invoices.api';
import { FundingDetailNoData } from './styled';
import LoaderInPage from 'components/common/loader/LoaderInPage';

const FundingDetail: FC = () => {
  const params: Readonly<Params<string>> = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [fundingId, setFundingId] = useState<string | null>(null);
  const invoiceApi = new InvoiceApi(store);

  useEffect(() => {
    setLoading(true);
    getPaymentId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.invoiceId]);

  const getPaymentId: () => void = async () => {
    if (!params.invoiceId) return;
    const invoiceResponse = await invoiceApi.getInvoiceById(params.invoiceId);
    if (!invoiceResponse) setFundingId(null);
    if (invoiceResponse?.data?.value[0])
      setFundingId(invoiceResponse.data.value[0]?.activeFunding?.fundingId || null);
    setLoading(false);
  };

  return !loading ? (
    fundingId ? (
      <TradePaymentDetail fundingId={fundingId} loaderInPage={true} />
    ) : (
      <FundingDetailNoData data-automation-id="ledger-invoices-h5-no-data">
        No Funding
      </FundingDetailNoData>
    )
  ) : (
    <LoaderInPage />
  );
};

export default FundingDetail;
