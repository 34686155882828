import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UPDATE_ACTIVE_NAV_BUTTON } from 'store/actions';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import {
  NavigationLinkButtonContainer,
  NavigationLinkButtonText,
  NavigationLinkButtonIcon,
  NavigationLinkButtonHeadingText
} from './styled';
import { fontSizes } from 'assets/theme/typography';
export interface NavigationLinkButtonProps {
  text: string;
  path: string;
  icon?: JSX.Element[] | JSX.Element;
  fontSize?: string;
  color?: string;
  fontWeight?: number;
  testingTag?: string;
  externalPage?: boolean;
  isHeadingStyle?: boolean;
}

const NavigationLinkButton: FC<NavigationLinkButtonProps> = ({
  text,
  path,
  icon,
  fontSize,
  color,
  fontWeight,
  testingTag,
  externalPage,
  isHeadingStyle
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState<boolean>(false);
  const activeNavButton: NavigationItem = useSelector(
    (state: RootStateOrAny) => state.app.activeNavButton
  );

  const isActive: () => void = () => {
    if (!activeNavButton) return setActive(false);
    if (activeNavButton.text === text && window.location.pathname === path) return setActive(true);
    if (!activeNavButton.path) return setActive(false);
    return setActive(window.location.pathname === path);
  };

  useEffect(() => {
    isActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNavButton]);

  const clickHandler: (item: NavigationLinkButtonProps) => void = (item) => {
    if (!externalPage) dispatch({ type: UPDATE_ACTIVE_NAV_BUTTON, payload: item });
    externalPage ? window.open(path, '_BLANK') : navigate(path);
  };

  return (
    <NavigationLinkButtonContainer
      onClick={() => clickHandler({ text, path, icon, fontSize })}
      active={active}
      data-automation-id={testingTag || ''}
      data-testid="sp-navigation-link-button"
    >
      {icon && (
        <NavigationLinkButtonIcon data-testid="sp-navigation-button-icon">
          {icon}
        </NavigationLinkButtonIcon>
      )}
      {isHeadingStyle ? (
        <NavigationLinkButtonHeadingText
          fontWeight={fontWeight}
          color={color}
          fontSize={fontSize ? fontSize : `${fontSizes.medium}`}
          data-automation-id="navigation-h5-button-text"
          data-testid="navigation-h5-button-text"
        >
          {text}
        </NavigationLinkButtonHeadingText>
      ) : (
        <NavigationLinkButtonText
          fontWeight={fontWeight}
          color={color}
          fontSize={fontSize ? fontSize : `${fontSizes.large}`}
          data-automation-id="navigation-p-button-text"
          data-testid="navigation-p-button-text"
        >
          {text}
        </NavigationLinkButtonText>
      )}
    </NavigationLinkButtonContainer>
  );
};

export default NavigationLinkButton;
