import {
  SET_GATEWAY_TO_FINANCE_ID,
  SET_GATEWAY_TO_FINANCE_ID_FAILURE,
  SET_GATEWAY_TO_FINANCE_ID_SUCCESS,
  SHOW_GATEWAY_TO_FINANCE_BAR,
  RESET_GATEWAY_TO_FINANCE_ID
} from 'store/actions';
import {
  RESET_GATEWAY_TO_FINANCE_ERROR,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS
} from 'store/actions/gatewayToFinance';
import { IG2FExternalCustomer } from 'utils/interfaces/g2f/g2f.interface';
import { ReducerAction, StateBase } from '.';

interface GatewayToFinanceReducerStateProps extends StateBase {
  g2fCreateInvoiceId: string | null;
  display: boolean;
  lineItems: any[];
  g2fBuyerSelection: IG2FExternalCustomer[];
}

const DEFAULT_STATE: GatewayToFinanceReducerStateProps = {
  g2fCreateInvoiceId: null,
  display: false,
  loading: false,
  lineItems: [],
  g2fBuyerSelection: [],
  error: ''
};

const gatewayToFinanceReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case RESET_GATEWAY_TO_FINANCE_ID:
      return {
        ...state,
        g2fCreateInvoiceId: null,
        loading: false,
        display: false
      };
    case SHOW_GATEWAY_TO_FINANCE_BAR:
      return {
        ...state,
        display: true
      };
    case SET_GATEWAY_TO_FINANCE_ID:
      return {
        ...state,
        lineItems: payload.data,
        loading: true
      };
    case SET_GATEWAY_TO_FINANCE_ID_SUCCESS:
      if (typeof payload === 'string') {
        return {
          ...state,
          g2fCreateInvoiceId: payload,
          lineItems: [],
          loading: false
        };
      } else {
        return {
          ...state,
          g2fBuyerSelection: payload.value || [],
          loading: payload?.value?.length > 0 
        };
      }
    case SET_GATEWAY_TO_FINANCE_ID_FAILURE:
      return {
        ...state,
        error: payload,
        lineItems: [],
        loading: false
      };
    case SET_GATEWAY_TO_FINANCE_ID_CONTINUE:
      return {
        ...state,
        loading: true
      };
    case SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS:
      return {
        ...state,
        g2fCreateInvoiceId: payload,
        g2fBuyerSelection: [],
        lineItems: [],
        loading: false
      };
    case SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE:
      return {
        ...state,
        error: payload,
        g2fBuyerSelection: [],
        lineItems: [],
        loading: false
      };
    case RESET_GATEWAY_TO_FINANCE_ERROR:
      return {
        ...state,
        error: ''
      };
    default:
      return state;
  }
};

export { gatewayToFinanceReducer };
export type { GatewayToFinanceReducerStateProps };
