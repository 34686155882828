import { combineEpics } from 'redux-observable';
import appEpics from './app';
import fundingRequestEpics from './fundingRequest';
import programEpics from './program';
import ledgerEpics from './ledger';
import entityEpics from './entity';
import paymentEpics from './payment';
import purchaseOrderEpics from './purchaseOrder';
import invoiceEpics from './invoice';
import logisticEpics from './logistic';
import adjustmentEpics from './adjustment';
import userEpics from './user';
import onboardingEpics from './onboarding';
import gatewayToFinanceEpics from './gatewayToFinance';
import createInvoiceEpics from './createInvoice';

export const rootEpic = combineEpics(
  appEpics,
  fundingRequestEpics,
  programEpics,
  ledgerEpics,
  entityEpics,
  paymentEpics,
  purchaseOrderEpics,
  invoiceEpics,
  logisticEpics,
  adjustmentEpics,
  userEpics,
  onboardingEpics,
  gatewayToFinanceEpics,
  createInvoiceEpics
);
