import StoreBase from 'api/base/storeBase';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IPurchaseOrderMatchingResult } from 'api/interfaces/open-account/purchase-orders/purchaseOrders.interface';
import { AxiosResponse } from 'axios';
import { CommentPostModel } from 'utils/classes/open-account/comment/comment';

const _PATH: string = '/open-accounts/purchase-orders';

export default class OpenAccountPurchaseOrdersApi extends StoreBase {
  public getOpenAccountPurchaseOrderMatchingById: (
    purchaseOrderId: string
  ) => Promise<AxiosResponse<IPurchaseOrderMatchingResult>> = async (purchaseOrderId) =>
    this.axiosInstance.get<IPurchaseOrderMatchingResult>(
      `${_PATH}/${purchaseOrderId}/matching-results`
    );

  public postPoComment: (
    purchaseOrderId: string,
    data: CommentPostModel
  ) => Promise<AxiosResponse<IComment>> = async (purchaseOrderId, data) =>
    this.axiosInstance.post<IComment>(`${_PATH}/${purchaseOrderId}/comments`, data);

  public getPoComments: (purchaseOrderId: string) => Promise<AxiosResponse<IComment[]>> = async (
    purchaseOrderId
  ) => this.axiosInstance.get<IComment[]>(`${_PATH}/${purchaseOrderId}/comments`);
}
