import { FC } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { CurrencyTileContainer, CurrencyTileP } from './styled';

interface CurrencyTileProps {
  currencyCode: string;
  active?: boolean;
  clickHandler: () => void;
}

const CurrencyTile: FC<CurrencyTileProps> = ({ currencyCode, active, clickHandler }) => {
  return (
    <CurrencyTileContainer active={Boolean(active)} onClick={clickHandler}>
      <CurrencyFlag
        style={{ borderRadius: '50%', width: '24px', height: '24px' }}
        currency={currencyCode}
      />
      <CurrencyTileP>{currencyCode}</CurrencyTileP>
    </CurrencyTileContainer>
  );
};

export default CurrencyTile;
