import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerSummaryItem } from 'components/ledger/LedgerDetail/LedgerDetailSummary';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { _matchingDiscrepantStatus } from 'lib/constants/matching';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { DateTime } from 'luxon';
import LedgerHeaderBuilder from 'utils/classes/ledger/ledgerHeaderBuilder';
import LedgerSummaryItemBuilder from 'utils/classes/ledger/ledgerSummaryItemBuilder';

export const mapInvoiceToLedgerSummaryFormat: (invoice: ILedgerInvoice) => LedgerSummaryItem[] = (
  invoice
) => [
  new LedgerSummaryItemBuilder('Buyer', 'buyerRef', invoice.buyerRef, '', ''),
  new LedgerSummaryItemBuilder('Term', 'paymentTerms', invoice.data.paymentTerms || '-', '', ''),
  new LedgerSummaryItemBuilder(
    'Invoice date',
    'createdDate',
    formatDateTime(invoice.createdAt, _DATE_FORMAT),
    'This is when this invoice was created on Supplier Pay ready for matching.',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Payment due date',
    'paymentDueDate',
    formatDateTime(invoice.data.paymentDueDate || '', _DATE_FORMAT),
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Maturity date',
    'maturityDate',
    formatDateTime(
      DateTime.fromFormat(invoice.maturityDate || '', _DATE_FORMAT).toISO(),
      _DATE_FORMAT
    ),
    '',
    ''
  ),
  new LedgerSummaryItemBuilder('Beneficiary', 'beneficiaryRef', invoice.beneficiaryRef, '', '')
];

export const mapInvoiceNumericDataToLedgerSummaryFormat: (
  invoice: ILedgerInvoice
) => LedgerSummaryItem[] = (invoice) => [
  new LedgerSummaryItemBuilder(
    'Original invoice amount',
    'amount',
    invoice.data.amount || 0,
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Cash paid to date',
    'cashPaidToDate',
    invoice.data.cashPaidToDate || 0,
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Total dilutions',
    'totalDilutions',
    invoice.data.dilutions || 0,
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Total adjustments',
    'adjustments',
    invoice.appliedAdjustment || 0,
    '',
    ''
  ),
  new LedgerSummaryItemBuilder('Total fees', 'fees', invoice.matchingFees || 0, '', ''),
  new LedgerSummaryItemBuilder(
    'Total net invoice amount',
    'netAmount',
    invoice.netAmount || 0,
    '',
    ''
  )
];

export const mapInvoiceHeaderData: (invoice: ILedgerInvoice) => LedgerHeaderBuilder = (invoice) => {
  return new LedgerHeaderBuilder(
    invoice.invoiceNumber || '-',
    'Net invoice amount',
    invoice.netAmount || 0,
    invoice.invoiceCurrency || '-',
    invoice?.linkedProgram?.invoiceStatus || '',
    invoice?.linkedProgram?.matchingStatus === _matchingDiscrepantStatus,
    invoice.netAmount
  );
};
