/* eslint-disable react-hooks/exhaustive-deps */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Checkbox, Collapse } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import {
  ILogisticsRuleSubtemplate,
  ILogisticsRuleSubtemplateField
} from 'api/interfaces/program/program.interface';
import HideMoreIcon from 'assets/icons/HideMoreIcon';
import ShowMoreIcon from 'assets/icons/ShowMoreIcon';
import { themeColors } from 'assets/theme/style';
import Uploader from 'components/common/Uploader';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import TextInput from 'components/forms/inputs/TextInput';
import {
  CheckboxFieldRow,
  CheckboxFieldText,
  CreateInvoiceDetailDateSegment
} from 'components/ledger/LedgerCreateInvoice/LedgerCreateInvoiceField/styled';
import { CreateInvoiceDialogBoldTitle } from 'components/ledger/LedgerCreateInvoice/styled';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { InvoiceLogisticsFlipDictionary } from 'lib/lookups/invoiceFlip.lookup';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CheckboxStyled } from 'views/Onboarding/styled';
import {
  FormStyled,
  GroupTableContainer,
  GroupedTable,
  LogisticsArtifactActionsContainer,
  LogisticsArtifactAdditionalForm,
  LogisticsArtifactButtonContainer,
  LogisticsArtifactDescription,
  LogisticsArtifactDocumentTitle,
  LogisticsArtifactLineItemShowMoreContainer,
  LogisticsArtifactSectionContainer,
  LogisticsArtifactSectionText,
  LogisticsArtifactTitle,
  LogisticsArtifactWrapper,
  UploaderContainer
} from './styled';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';

const _requiredLineItemField: string[] = ['lineItemDescription', 'quantity', 'uom', 'selected'];

interface LogisticsArtifactFormProps extends ILogisticsRuleSubtemplate {
  backClickHandler: () => void;
  nextClickhandler: (templateReference: string, data: ILogisticsRuleSubtemplateField[]) => void;
  fileUploadHandler: (file: File) => void;
}

interface HeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
}

const LogisticsArtifactForm: FC<LogisticsArtifactFormProps> = ({
  name,
  templateReference,
  fields,
  nextClickhandler,
  backClickHandler,
  fileUploadHandler
}) => {
  const [additionalStep, setAdditionalStep] = useState<boolean>(false);
  const [showAdditionalStep, setShowAdditionalStep] = useState<boolean>(false);
  const [headerCheckbox, setHeaderCheckbox] = useState<HeaderCheckbox>({
    indeterminate: false,
    checked: true
  });
  const [open, setOpen] = useState<number[]>([]);
  const [invoice, setInvoice] = useState<ILedgerInvoice | null>(null);
  const [additionalViewJSX, setAdditionalViewJSX] = useState<JSX.Element | null>(null);
  const [entryViewJSX, setEntryViewJSX] = useState<JSX.Element | null>(null);
  const [fieldsFromRules, setFieldsFromRules] = useState<ILogisticsRuleSubtemplateField[]>([]);
  const [lineItemsMandatoryFieldsComplete, setLineItemsMandatoryFieldsComplete] =
    useState<boolean>(false);
  const [topLevelMandatoryFieldsComplete, setTopLevelMandatoryFieldsComplete] =
    useState<boolean>(false);

  const {
    ledgerInvoice
  }: {
    ledgerInvoice: ILedgerInvoice;
  } = useSelector((state: RootStateOrAny) => state.invoice);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => {
    Object.keys(errors).length > 0
      ? setTopLevelMandatoryFieldsComplete(false)
      : setTopLevelMandatoryFieldsComplete(true);
  }, [Object.keys(errors).length]);

  useEffect(() => {
    if (fields && fields.length > 0) {
      setFieldsFromRules([...fields]);
      hasLineItemAdditionalStep([...fields]);
    }
    if (ledgerInvoice) {
      const newObj = Object.assign({}, ledgerInvoice);
      setInvoice(newObj);
    }
    setAdditionalViewJSX(renderAdditionalStep());
  }, []);

  useEffect(() => {
    if (fieldsFromRules) setAdditionalViewJSX(renderAdditionalStep());
  }, [fieldsFromRules, open]);

  useEffect(() => {
    if (fieldsFromRules) setEntryViewJSX(renderEntryView());
  }, [fieldsFromRules, Object.keys(errors).length]);

  const isLineItemMandatoryDataSupplied: (data: ILogisticsRuleSubtemplateField[]) => boolean = (
    data
  ) => {
    const lineItems = data.find((d) => d.name === 'lineItems');

    if (!lineItems) return false;

    const completedArr: () => string[] = () =>
      (lineItems.value as any[]).flatMap((value) => {
        const keys: string[] = Object.keys(value);
        return keys.flatMap((key) => (value[key] === null || value[key] === '' ? 'false' : 'true'));
      });

    return !completedArr().includes('false');
  };

  const hasLineItemAdditionalStep: (fieldsData: ILogisticsRuleSubtemplateField[]) => void = (
    fieldsData
  ) => setAdditionalStep(Boolean(fieldsData.find((f) => f.name === 'lineItems')));

  const renderFields: (
    fieldsTemplate: ILogisticsRuleSubtemplateField[],
    parentReferencePath?: string,
    index?: number
  ) => JSX.Element[] = (fieldsTemplate, parentReferencePath, index) => {
    return fieldsTemplate.map((f, i) => {
      f.referencePath = parentReferencePath ? `${parentReferencePath}.${f.name}` : `${f.name}`;
      if (f.type === 'OBJECT') {
        return (
          <div
            key={f.name}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '32px',
              padding: '0 0 16px 0',
              borderTop: `1px solid ${themeColors.text.light.muted}`,
              borderBottom: index === undefined ? `1px solid ${themeColors.text.light.muted}` : '',
              marginTop: index === undefined ? '16px' : '',
              gap: index === undefined ? 0 : '16px'
            }}
          >
            <h5
              style={{
                padding: '16px 0',
                backgroundColor: index === undefined ? themeColors.mono5 : ''
              }}
            >
              {f.displayName}
            </h5>
            {renderFields(f.nestedFields, f.referencePath, i)}
          </div>
        );
      }

      if (f.type === 'ARRAY')
        f.nestedFields = configureArrayTypeReferencePaths(f.nestedFields, f.referencePath, i);

      if (f.value === undefined) f.value = setValueFromInvoice(f);

      return renderField(f);
    });
  };

  const configureArrayTypeReferencePaths: (
    fieldsTemplate: ILogisticsRuleSubtemplateField[],
    parentReferencePath?: string,
    index?: number
  ) => ILogisticsRuleSubtemplateField[] = (fieldsTemplate, parentReferencePath, index) => {
    return fieldsTemplate.map((t) => {
      t.referencePath = parentReferencePath ? `${parentReferencePath}.${t.name}` : `${t.name}`;
      if (t.type === 'OBJECT' || t.type === 'ARRAY')
        configureArrayTypeReferencePaths(t.nestedFields, t.referencePath, index);

      return t;
    });
  };

  const updateHandler: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (value, fieldName, referencePath, fieldsData) => {
    setFieldsFromRules(updateFieldValueFromInput(value, fieldName, referencePath, fieldsData));
  };

  const updateFieldValueFromInput: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => ILogisticsRuleSubtemplateField[] = (value, fieldName, referencePath, fieldsData) => {
    const mutArr = [...fieldsData];
    return mutArr.map((f, i) => {
      if (f.type === 'OBJECT')
        updateFieldValueFromInput(value, fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.value = value;

      return f;
    });
  };

  const lineItemChangeHandler: (value: any, fieldName: string, index: number) => void = (
    value,
    fieldName,
    index
  ) => {
    const mutArr = [...fieldsFromRules];
    const lineItems = mutArr.find((r) => r.name === 'lineItems');

    if (!lineItems) return;

    (lineItems.value as any[])[index][fieldName] = value;
    setFieldsFromRules(mutArr);
  };

  const updatePickerOpenValue: (
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (fieldName, referencePath, fieldsData) => {
    const updatedFields = [...fieldsData].map((f, i) => {
      if (f.type === 'OBJECT') updatePickerOpenValue(fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.isOpen = !f.isOpen;

      return f;
    });
    setFieldsFromRules(updatedFields);
  };

  const renderField: (field: ILogisticsRuleSubtemplateField) => JSX.Element = (field) => {
    switch (field.type) {
      case 'STRING':
        return (
          <TextInput
            testingTagPage="ledger-invoice-logistics-flip"
            key={field.name}
            label={field.displayName}
            disabled={
              field.name === 'buyerReference' ||
              field.name === 'buyerName' ||
              field.name === 'supplierReference' ||
              field.name === 'supplierName'
            }
            defaultValue={field.value as string}
            tooltipText={field.description}
            register={register(field.name, { required: true })}
            errorMessage={errors[field.name] && `${field.displayName} is a mandatory field`}
            required
            changeHandler={(e) =>
              updateHandler(e.target.value, field.name, field.referencePath, fieldsFromRules)
            }
          />
        );
      case 'BOOLEAN':
        return (
          <CheckboxFieldRow key={field.name}>
            <CheckboxFieldText data-automation-id="ledger-invoice-logistics-flip-p-checkbox-message">
              {field.displayName}
            </CheckboxFieldText>
            <CheckboxStyled
              checked={field.value as boolean}
              onChange={(e) =>
                updateHandler(e.target.checked, field.name, field.referencePath, fieldsFromRules)
              }
              data-testid="sp-checkbox-item"
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'sp-clickable-checkbox'
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              data-automation-id={`logistics-artifact-form-span-checkbox-${field.name
                .split(' ')
                .join('')}`}
            />
          </CheckboxFieldRow>
        );
      case 'DATE':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                field.value ? (field.value as Date).toISOString() : '',
                'yyyy/MM/dd HH:mm'
              )}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(field.value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DATE_TIME':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                field.value ? (field.value as Date).toISOString() : '',
                'yyyy/MM/dd HH:mm'
              )}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(field.value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              showTimeSelect
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DOUBLE':
        return (
          <TextInput
            key={field.name}
            type="number"
            defaultValue={field.value as string}
            placeholder={field.exampleValue}
            label={field.displayName}
            tooltipText={field.description}
            changeHandler={(e) =>
              updateHandler(e.target.value, field.name, field.referencePath, fieldsFromRules)
            }
            register={register(field.name, { required: true })}
            testingTagPage="ledger-invoice-logistics-flip"
          />
        );

      default:
        return <React.Fragment key={field.name} />;
    }
  };

  const updateLineItemHandler: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[],
    index: number
  ) => void = (value, fieldName, referencePath, fieldsData, index) => {
    const mutArr = [...fieldsFromRules];
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined = mutArr.find(
      (r) => r.name === 'lineItems'
    );

    if (!lineItemsRule) return;

    const mutReferencePath = referencePath?.slice().split('.');
    mutReferencePath?.shift();

    if (lineItemsRule.value)
      _.set((lineItemsRule.value as any[])[index], mutReferencePath || '', value);

    setFieldsFromRules(mutArr);

    setLineItemsMandatoryFieldsComplete(isLineItemMandatoryDataSupplied(mutArr));
  };

  const lineItemErrorMessage: (
    field: ILogisticsRuleSubtemplateField,
    values: any[],
    index: number
  ) => string = (field, values, index) => {
    const mutReference = field.referencePath?.slice().split('.') || [];

    mutReference.shift();

    return (_.get(values[index], mutReference) as string)
      ? ''
      : `${mutReference.map((r) => toSentenceCase(r)).join(' - ')} is a mandatory field`;
  };

  const renderLineItemField: (
    field: ILogisticsRuleSubtemplateField,
    values: any[],
    index: number,
    parentName?: string,
    parentId?: string
  ) => JSX.Element = (field, values, index, parentName, parentId) => {
    let level: number = 0;

    const mutReference = field.referencePath?.slice().split('.') || [];

    mutReference.shift();

    switch (field.type) {
      case 'STRING':
        return (
          <TextInput
            testingTagPage="ledger-invoice-logistics-flip"
            key={field.referencePath}
            disabled={
              field.name === 'sku' ||
              field.name === 'poNumber' ||
              field.name === 'invoiceNumber' ||
              field.name === 'unitsOfMeasure'
            }
            label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            defaultValue={_.get(values[index], mutReference) as string}
            tooltipText={field.description}
            placeholder={field.exampleValue}
            errorMessage={lineItemErrorMessage(field, values, index)}
            required
            changeHandler={(e) =>
              updateLineItemHandler(
                e.target.value,
                field.name,
                field.referencePath,
                fieldsFromRules,
                index
              )
            }
          />
        );
      case 'BOOLEAN':
        return (
          <CheckboxFieldRow key={field.referencePath}>
            <CheckboxFieldText data-automation-id="ledger-invoice-logistics-flip-p-checkbox-message">
              {parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            </CheckboxFieldText>
            <CheckboxStyled
              checked={!!field.value}
              onChange={(e) =>
                updateLineItemHandler(
                  e.target.checked,
                  field.name,
                  field.referencePath,
                  fieldsFromRules,
                  index
                )
              }
              data-testid="sp-checkbox-item"
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'sp-clickable-checkbox'
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              data-automation-id="ledger-create-invoices-detail-span-checkbox-administrator"
            />
          </CheckboxFieldRow>
        );
      case 'DATE':
        return (
          <CreateInvoiceDetailDateSegment key={field.referencePath}>
            <TextInput
              label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                ((_.get(values[index], mutReference) as Date) || new Date()).toISOString(),
                'yyyy/MM/dd'
              )}
              errorMessage={lineItemErrorMessage(field, values, index)}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(_.get(values[index], mutReference) as Date) || new Date()}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DATE_TIME':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                ((values[index][field.name] as Date) || new Date()).toISOString(),
                'yyyy/MM/dd HH:mm'
              )}
              errorMessage={lineItemErrorMessage(field, values, index)}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(values[index][field.name] as Date) || new Date()}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              showTimeSelect
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DOUBLE':
        return (
          <TextInput
            key={field.name}
            type="number"
            defaultValue={_.get(values[index], mutReference) as string}
            placeholder={field.exampleValue}
            label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            tooltipText={field.description}
            changeHandler={(e) =>
              updateLineItemHandler(
                e.target.value,
                field.name,
                field.referencePath,
                fieldsFromRules,
                index
              )
            }
            errorMessage={lineItemErrorMessage(field, values, index)}
            testingTagPage="ledger-invoice-logistics-flip"
          />
        );
      case 'OBJECT':
        level = level + 1;
        if (parentName)
          field.displayName =
            parentName === undefined ? field.displayName : `${parentName} - ${field.displayName}`;

        if (parentId)
          field.referencePath = parentId === undefined ? field.name : `${parentId}.${field.name}`;

        if (field.displayName.split(' - ').length > 2)
          field.displayName = field.displayName.split(' - ').slice(-2).join(' - ');

        return (
          <>
            {field.nestedFields.map((f, i) =>
              renderLineItemField(f, values, index * level, field.displayName, parentId)
            )}
          </>
        );
      // return (
      //   <TextInput
      //     key={field.name}
      //     type="number"
      //     defaultValue={field.value as string}
      //     placeholder={field.exampleValue}
      //     label={field.displayName}
      //     tooltipText={field.description}
      //     changeHandler={(e) =>
      //       updateLineItemHandler(
      //         e.target.value,
      //         field.name,
      //         field.referencePath,
      //         fieldsFromRules,
      //         index
      //       )
      //     }
      //     register={register(field.name, { required: true })}
      //     errorMessage={errors[field.name] && `${field.displayName} is a mandatory field`}
      //     testingTagPage="ledger-invoice-logistics-flip"
      //   />
      // );

      default:
        return <React.Fragment key={field.name} />;
    }
  };

  const setValueFromInvoice: (field: ILogisticsRuleSubtemplateField) => string = (field) => {
    if (!ledgerInvoice || !invoice || !field.referencePath) return '';
    const name: string = InvoiceLogisticsFlipDictionary[field.referencePath];
    let dataFromInvoice = _.get(invoice, name);
    if (field.name === 'lineItems') {
      const requiredFields: string[] = [
        ...field.nestedFields.map((f) => f.name),
        ..._requiredLineItemField
      ];

      dataFromInvoice = (dataFromInvoice as LedgerLineItem[]).map((o) => {
        const newObj = Object.assign({});
        requiredFields.forEach((r) => (newObj[r] = o[r]));
        newObj['invoiceNumber'] = invoice.invoiceNumber;
        newObj['selected'] = true;
        return newObj;
      });
    }
    return dataFromInvoice;
  };

  const fileDropHandler = (acceptedFiles: File[]) => fileUploadHandler(acceptedFiles[0]);

  const secondStageClickHandler: () => void = () => {
    setShowAdditionalStep(true);
    setLineItemsMandatoryFieldsComplete(isLineItemMandatoryDataSupplied(fieldsFromRules));
  };

  const renderEntryView: () => JSX.Element = () => {
    return (
      <>
        <LogisticsArtifactTitle>Create a {name}</LogisticsArtifactTitle>
        {/* <LogisticsArtifactDescription>
          Provide the logistics information for this invoice below.
        </LogisticsArtifactDescription> */}
        {/* <LogisticsArtifactSectionContainer>
          <LogisticsArtifactSectionText>
            Shipping details - required for matching
          </LogisticsArtifactSectionText>
        </LogisticsArtifactSectionContainer> */}
        {fieldsFromRules && fieldsFromRules.length > 0 && renderFields(fieldsFromRules)}
        <LogisticsArtifactDocumentTitle>Document attachments</LogisticsArtifactDocumentTitle>
        <LogisticsArtifactDescription>Please upload documents here.</LogisticsArtifactDescription>
        <UploaderContainer>
          <Uploader
            borderColor={themeColors.border.warning}
            fileDropHandler={fileDropHandler}
            singlePreviewMode
            limitBytes={9000000}
            acceptedFileTypes={['jpg', 'jpeg', 'tiff', 'png', 'pdf']}
          />
        </UploaderContainer>
      </>
    );
  };

  const toggleShowMoreClickHandler: (index: number) => void = (index) => {
    let openCopy: number[];
    if (open.includes(index)) {
      openCopy = open.filter((element) => {
        return element !== index;
      });
      setOpen(openCopy);
    } else {
      openCopy = [...open];
      openCopy.push(index);
      setOpen(openCopy);
    }
  };

  const headerCheckboxClickHandler: () => void = () => {
    setHeaderCheckbox({ indeterminate: false, checked: !headerCheckbox.checked });
    const mutRules = [...fieldsFromRules];
    const lineItemsRule = mutRules.find((r) => r.name === 'lineItems');

    if (!lineItemsRule) return;

    (lineItemsRule.value as any[]).forEach((v) => (v.selected = !headerCheckbox.checked));

    setFieldsFromRules(mutRules);
  };

  const renderLineItemsInputFields: (lineItem: any, index: number) => JSX.Element[] = (
    lineItem,
    index
  ) => {
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined = fieldsFromRules.find(
      (r) => r.name === 'lineItems'
    );

    if (!lineItemsRule) return [];

    const keysFromRule = lineItemsRule.nestedFields.map((f) => f.name);

    let newObj = {};

    keysFromRule.forEach((k) => {
      newObj = { ...newObj, [k]: lineItem[k] };
    });

    const fields = lineItemsRule.nestedFields
      .filter(
        (field) =>
          field.name !== 'quantity' && field.name !== 'lineItemNumber' && field.name !== 'uom'
      )
      .map((n) => renderLineItemField(n, lineItemsRule.value as any[], index));

    return fields;
  };

  const rowCheckboxClickHandler: (lineItem: any) => void = (lineItem) => {
    const mutRules = [...fieldsFromRules];
    const lineItemsRule = mutRules.find((r) => r.name === 'lineItems');

    if (!lineItemsRule) return;

    const matchedValue = (lineItemsRule.value as any[]).find(
      (v) => v.lineItemNumber === lineItem.lineItemNumber
    );

    if (!matchedValue) return;

    matchedValue.selected = !matchedValue.selected;

    const checkForAllState: boolean =
      (lineItemsRule.value as any[]).filter((r) => r.selected === matchedValue.selected).length ===
      (lineItemsRule.value as any[]).length;

    if (checkForAllState)
      setHeaderCheckbox({ indeterminate: false, checked: matchedValue.selected });

    setFieldsFromRules(mutRules);
  };

  const renderAdditionalStep: () => JSX.Element = () => (
    <GroupTableContainer>
      {!lineItemsMandatoryFieldsComplete && showAdditionalStep && (
        <p style={{ color: themeColors.text.error, marginBottom: '16px' }}>
          * Some mandatory information has not been supplied. You may need to expand the row to
          provide this information.
        </p>
      )}
      <GroupedTable data-automation-id="logistics-line-items-table-grouped">
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th style={{ width: '6%' }}>
              <Checkbox
                style={{
                  color: themeColors.text.primary,
                  backgroundColor: 'transparent',
                  zIndex: 10
                }}
                {...headerCheckbox}
                onChange={() => headerCheckboxClickHandler()}
                data-testid="sp-header-checkbox"
              />
            </th>
            <th style={{ width: '70%' }}>LINE ITEM DESCRIPTION</th>
            <th style={{ width: '12%' }}>QTY</th>
            <th style={{ width: '12%' }}>UNIT OF MEASURE</th>
          </tr>
        </thead>
        <tbody>
          {((fieldsFromRules.find((r) => r.name === 'lineItems')?.value as any[]) || []).map(
            (d, index) => {
              return (
                <React.Fragment key={`${index}-fragment`}>
                  <tr
                    key={`${index}-lineItem`}
                    style={{
                      borderBottom: `${open.includes(index) ? 'none' : '1px solid #D8D8FF'}`
                    }}
                  >
                    <td className="accordion-row">
                      <Checkbox
                        checked={d.selected}
                        style={{
                          color: themeColors.text.primary,
                          backgroundColor: 'transparent',
                          zIndex: 10
                        }}
                        onChange={() => rowCheckboxClickHandler(d)}
                      />
                    </td>
                    <td
                      className="accordion-row"
                      onClick={() => toggleShowMoreClickHandler(index)}
                      data-testid="sp-create-invoice-show-more"
                    >
                      <LogisticsArtifactLineItemShowMoreContainer>
                        <p>
                          <CreateInvoiceDialogBoldTitle>
                            Line item number:
                          </CreateInvoiceDialogBoldTitle>
                          {` ${d.lineItemNumber}` || '-'}
                        </p>
                        {open.includes(index) ? <HideMoreIcon /> : <ShowMoreIcon />}
                      </LogisticsArtifactLineItemShowMoreContainer>
                    </td>
                    <td className="accordion-row">
                      <TextInput
                        defaultValue={(d.quantity || 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        changeHandler={(e) =>
                          lineItemChangeHandler(e.target.value, 'quantity', index)
                        }
                      />
                    </td>
                    <td className="accordion-row">{d.unitOfMeasure || '-'}</td>
                  </tr>
                  {open.includes(index) && (
                    <tr aria-colspan={3}>
                      <td></td>
                      <td className="accordion-row-collapse text-align-right more-detail">
                        <Collapse in={open.includes(index)}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              textAlign: 'start'
                            }}
                          >
                            {renderLineItemsInputFields(d, index)}
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            }
          )}
        </tbody>
      </GroupedTable>
    </GroupTableContainer>
  );

  const renderActions: (disabled?: boolean) => JSX.Element = (disabled) => (
    <LogisticsArtifactActionsContainer>
      <LogisticsArtifactButtonContainer>
        <PrimaryButton disabled={disabled} type="submit" text="Next" />
      </LogisticsArtifactButtonContainer>
      <LogisticsArtifactButtonContainer>
        <SecondaryButton
          text="Back"
          clickHandler={(e) => {
            e.preventDefault();
            additionalStep && showAdditionalStep
              ? setShowAdditionalStep(false)
              : backClickHandler();
          }}
        />
      </LogisticsArtifactButtonContainer>
    </LogisticsArtifactActionsContainer>
  );

  const onSubmit: (fn: () => void) => void = (fn) => fn();

  const additionalStepSubmit: () => void = () => {
    const isComplete = isLineItemMandatoryDataSupplied(fieldsFromRules);

    if (!isComplete) {
      setLineItemsMandatoryFieldsComplete(isComplete);
      return;
    }
    nextClickhandler(templateReference, fieldsFromRules);
  };

  return (
    <LogisticsArtifactWrapper>
      {!showAdditionalStep ? (
        <FormStyled onSubmit={handleSubmit(() => onSubmit(secondStageClickHandler))}>
          {entryViewJSX}
          {renderActions(!topLevelMandatoryFieldsComplete)}
        </FormStyled>
      ) : (
        <LogisticsArtifactAdditionalForm
          onSubmit={handleSubmit(() => onSubmit(additionalStepSubmit))}
        >
          {additionalViewJSX}
          {renderActions(!lineItemsMandatoryFieldsComplete)}
        </LogisticsArtifactAdditionalForm>
      )}
    </LogisticsArtifactWrapper>
  );
};

export default LogisticsArtifactForm;
