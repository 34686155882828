import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import GetPaidNowIcon from 'assets/icons/GetPaidNowIcon';
import { GetPaidNowButtonWrapper, GetPaidNowText } from './styled';
import { PrimaryButton } from 'components/common/buttons';
import { SET_EARLY_PAYMENT_DIALOG_OPEN } from 'store/actions';

interface IEarlyPaymentButton {
  height: string;
  borderRadius: string;
  padding: string;
  fullWidth: boolean;
}

const EarlyPaymentButton: FC<Partial<IEarlyPaymentButton>> = ({ height, fullWidth }) => {
  const dispatch = useDispatch();
  const {
    advancedInvoicesTotal,
    loading
  }: {
    activeCurrencyCode: string;
    advancedInvoicesTotal: number;
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);
  const {
    loading: fundingRequestLoading
  }: {
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.fundingRequest);

  const toggleAutoPaymentDialog: () => void = () => {
    dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: true });
  };

  return (
    <GetPaidNowButtonWrapper fullWidth={fullWidth}>
      <PrimaryButton
        backgroundColor={themeColors.bg.light.surfaceInvert}
        clickHandler={toggleAutoPaymentDialog}
        height={height}
        gap="8px"
        minWidth="170px"
        disabled={loading || fundingRequestLoading || advancedInvoicesTotal === 0}
        testingTag="home-button-get-paid-early"
      >
        <GetPaidNowIcon
          color={themeColors.text.onPrimary}
          disabled={loading || fundingRequestLoading || advancedInvoicesTotal === 0}
        />
        <GetPaidNowText
          className="embolden"
          data-automation-id="home-h4-button-name-get-paid-early"
          data-testid="early-payment-button-get-paid-early"
        >
          {fundingRequestLoading ? 'Please wait...' : 'Get paid early'}
        </GetPaidNowText>
      </PrimaryButton>
    </GetPaidNowButtonWrapper>
  );
};

export default EarlyPaymentButton;
