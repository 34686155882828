import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const ConnectAccountingSoftwareDialogWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 16px 32px 32px 32px;
`;

export const ConnectAccountingSoftwareDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const ConnectAccountingSoftwareDialogTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;
`;

export const ConnectAccountingSoftwareDialogTitle = styled.h2`
  color: ${themeColors.text.primary};
`;

export const ConnectAccountingSoftwareDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ConnectAccountingSoftwareDialogTitleRegular = styled.p``;

export const ConnectAccountingSoftwareDialogTitleBold = styled.p`
  ${typography.bodySemiBold};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const ConnectAccountingSoftwareDialogSearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
  }
`;

export const ConnectAccountingSoftwareDialogListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  border: 1px solid ${themeColors.bg.light.muted};
  border-radius: 6px;
  padding: 16px;
  gap: 48px;
  margin-bottom: 24px;
  height: 430px;
`;

export const ConnectAccountingSoftwareDialogIcon = styled.img`
display: flex;
max-width: 100px;
max-height: 100px;
padding: 20px;
border-radius: 12px;
border: 1px solid transparent;
background-color: ${themeColors.mono5};
cursor: pointer;
`;

