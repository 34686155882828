import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ConnectionIcon: FC<IconSvgProps> = ({
  height = '24',
  width = '24',
  color = themeColors.icon.success
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.71 9.29L17.71 5.29C17.6167 5.19676 17.5061 5.1228 17.3842 5.07234C17.2624 5.02188 17.1318 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H6.99999C6.73477 9 6.48042 9.10536 6.29288 9.2929C6.10534 9.48043 5.99999 9.73479 5.99999 10C5.99999 10.2652 6.10534 10.5196 6.29288 10.7071C6.48042 10.8946 6.73477 11 6.99999 11H21C21.1974 10.999 21.3901 10.9396 21.5538 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9427 9.6092 21.8487 9.43063 21.71 9.29ZM17 13H2.99999C2.80256 13.001 2.60985 13.0604 2.44613 13.1707C2.2824 13.2811 2.15501 13.4374 2.07999 13.62C2.00341 13.8021 1.98248 14.0028 2.01986 14.1968C2.05723 14.3908 2.15123 14.5694 2.28999 14.71L6.28999 18.71C6.38295 18.8037 6.49355 18.8781 6.61541 18.9289C6.73727 18.9797 6.86798 19.0058 6.99999 19.0058C7.132 19.0058 7.2627 18.9797 7.38456 18.9289C7.50642 18.8781 7.61702 18.8037 7.70999 18.71C7.80372 18.617 7.87811 18.5064 7.92888 18.3846C7.97965 18.2627 8.00579 18.132 8.00579 18C8.00579 17.868 7.97965 17.7373 7.92888 17.6154C7.87811 17.4936 7.80372 17.383 7.70999 17.29L5.40999 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8946 14.5196 18 14.2652 18 14C18 13.7348 17.8946 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z"
      fill={color}
    />
  </svg>
);

export default ConnectionIcon;
