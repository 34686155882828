import { FC } from 'react';
import {
  TransactionHistoryDetailList,
  TransactionHistoryDetailListItem,
  TransactionHistoryDetailListValue
} from './styled';

export interface TransactionSummaryProps {
  label: string;
  value: string;
}

const TransactionSummaryDetail: FC<TransactionSummaryProps> = ({ label, value }) => {
  return (
    <TransactionHistoryDetailList>
      <TransactionHistoryDetailListItem>{label}</TransactionHistoryDetailListItem>
      <TransactionHistoryDetailListValue>{value}</TransactionHistoryDetailListValue>
    </TransactionHistoryDetailList>
  );
};

export default TransactionSummaryDetail;
