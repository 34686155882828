export enum QueryComparators {
  eq = 'is equal to',
  ne = 'is not equal to',
  gt = 'is greater than',
  gte = 'is greater than or equal to',
  lt = 'is less than',
  lte = 'is less than or equal to'
}

export enum QueryDateComparators {
  btw = 'is between'
}

export enum QuerySimpleComparators {
  eq = 'is equal to',
  ne = 'is not equal to',
  ct = 'contains'
}

export enum QueryDefinitiveSimpleComparators {
  eq = 'is equal to',
  ne = 'is not equal to'
}

export enum QueryDefinitiveBooleanComparators {
  eq = 'is equal to'
}

export enum QueryOperatorsEnum {
  eq = 'eq',
  ne = 'ne',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  btw = 'btw',
  ct = 'ct'
}
