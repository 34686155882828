import { themeColors } from 'assets/theme/style';
import { FC, ReactNode } from 'react';
import Divider from 'components/common/divider';
import ActionLink from 'components/common/links/ActionLink';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { BaseCardContainer, BaseCardHeader, BaseCardHeaderMenu } from './styled';

export interface BaseCardProps {
  children?: ReactNode;
  actionButtons?: JSX.Element;
  noPadding?: boolean;
  margin?: string;
  hasHover?: boolean;
  backgroundColor?: string;
  textColor?: string;
  styles?: any;
  paperStyle?: boolean;
  headerMenu?: boolean;
  noBoxShadow?: boolean;
  earlyPaymentButton?: boolean;
  customBoxShadow?: string;
  tabs?: JSX.Element;
  actionMenuItems?: NavigationItem[] | undefined;
  minWidth?: boolean;
  maxWidth?: string;
  borderColor?: string;
  maxHeight?: string;
  noBorder?: boolean;
  flex?: string;
}

const BaseCard: FC<BaseCardProps> = ({
  children,
  noPadding,
  margin,
  hasHover = false,
  textColor = themeColors.text.light.body,
  backgroundColor = themeColors.bg.light.surface,
  styles,
  paperStyle = false,
  headerMenu = false,
  noBoxShadow = false,
  actionButtons,
  customBoxShadow,
  tabs,
  actionMenuItems,
  minWidth = false,
  borderColor,
  maxHeight,
  maxWidth,
  noBorder = false,
  flex
}) => (
  <BaseCardContainer
    noPadding={noPadding}
    margin={margin}
    hasHover={hasHover}
    textColor={textColor}
    backgroundColor={backgroundColor}
    paperStyle={paperStyle}
    headerMenu={headerMenu}
    noBoxShadow={noBoxShadow}
    customBoxShadow={customBoxShadow}
    minWidth={minWidth}
    maxHeight={maxHeight}
    borderColor={borderColor}
    noBorder={noBorder}
    maxWidth={maxWidth}
    flex={flex}
    {...styles}
  >
    {headerMenu && !tabs && (
      <BaseCardHeader>
        <BaseCardHeaderMenu noPadding={noPadding}>
          {actionButtons && actionButtons}
          <ActionLink menuItems={actionMenuItems} />
        </BaseCardHeaderMenu>
        <Divider />
      </BaseCardHeader>
    )}
    {tabs ? tabs : children}
  </BaseCardContainer>
);

export default BaseCard;
