import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import {
  SET_LEDGER_LOGISTIC,
  GET_LOGISTIC_COMMENTS,
  GET_LOGISTIC_COMMENTS_FAILURE,
  GET_LOGISTIC_COMMENTS_SUCCESS,
  POST_LOGISTIC_COMMENTS,
  POST_LOGISTIC_COMMENTS_FAILURE,
  POST_LOGISTIC_COMMENTS_SUCCESS,
} from 'store/actions';
import { ReducerAction, StateBase } from '.';
import { ILedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';

interface LogisticReducerStateProps extends StateBase {
  logisticCommentsLoading: boolean;
  logisticComments: IComment[];
  ledgerLogistic: ILedgerLogistic | null;
}

const DEFAULT_STATE: LogisticReducerStateProps = {
  logisticCommentsLoading: false,
  logisticComments: [],
  ledgerLogistic: null,
  error: '',
  loading: false
};

const logisticReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
  
    case POST_LOGISTIC_COMMENTS:
      return {
        ...state,
        logisticCommentsLoading: true
      };
    case POST_LOGISTIC_COMMENTS_SUCCESS:
      return {
        ...state,
        logisticComments: payload.data,
        logisticCommentsLoading: false
      };
    case POST_LOGISTIC_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        logisticCommentsLoading: false
      };
    case GET_LOGISTIC_COMMENTS:
      return {
        ...state,
        logisticCommentsLoading: true
      };
    case GET_LOGISTIC_COMMENTS_SUCCESS:
      return {
        ...state,
        logisticComments: payload.data,
        logisticCommentsLoading: false
      };
    case GET_LOGISTIC_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        logisticCommentsLoading: false
      };
      case SET_LEDGER_LOGISTIC:
        return {
          ...state,
          ledgerLogistic: null
        };
    default:
      return state;
  }
};

export { logisticReducer };
export type { LogisticReducerStateProps };
