import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

//DESKTOP STYLES
export const LedgerAdjustmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LedgerAdjustmentContainer = styled.div`
  display: flex;
`;

//MOBILE STYLES
export const LedgerAdjustmentMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
`;

export const LedgerAdjustmentMobileRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LedgerAdjustmentMobileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 4px;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LedgerAdjustmentMobileItemLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LedgerAdjustmentMobileItemRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const LedgerAdjustmentMobileItemBold = styled.p`
  ${typography.bodySemiBold};
`;

export const LedgerAdjustmentMobileItemRegular = styled.p`
  ${typography.captionRegular};
`;
