import { Dialog } from '@mui/material';
import FullScreenDialogAppBar from 'components/app-bars/FullScreenDialogAppBar';
import EarlyPaymentRequest from 'components/early-payment/EarlyPaymentRequest';
import EarlyPaymentRequestSent from 'components/early-payment/EarlyPaymentRequestSent';
import { FC, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  COMPLETE_FUNDING_REQUEST,
  FUNDING_REQUEST_RESET,
  SET_EARLY_PAYMENT_DIALOG_OPEN,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY
} from 'store/actions';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

interface FundingRequestDialogProps {
  open: boolean;
}

const FundingRequestDialog: FC<FundingRequestDialogProps> = ({ open }) => {
  const dispatch = useDispatch();
  const [requestSentOpen, setRequestSentOpen] = useState<boolean>(false);

  const {
    selectedProgramByCurrency
  }: {
    selectedProgramByCurrency: IGroupedProgram | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const sendRequestClickHandler: () => void = () => {
    dispatch({ type: COMPLETE_FUNDING_REQUEST });
    setRequestSentOpen(true);
  };

  const closeAutoPaymentDialog: () => void = () => {
    if (requestSentOpen) dispatch({ type: FUNDING_REQUEST_RESET });
    dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: false });
    dispatch({ type: UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY, payload: selectedProgramByCurrency });
  };

  return (
    <Dialog
      style={{ width: '100vw' }}
      fullScreen
      open={open}
      onClose={closeAutoPaymentDialog}
      data-testid="sp-full-screen-dialog"
    >
      <FullScreenDialogAppBar clickHandler={closeAutoPaymentDialog} />
      {!requestSentOpen && (
        <EarlyPaymentRequest
          closeClickHandler={closeAutoPaymentDialog}
          sendRequestClickHandler={sendRequestClickHandler}
        />
      )}
      {requestSentOpen && <EarlyPaymentRequestSent closeClickHandler={closeAutoPaymentDialog} />}
    </Dialog>
  );
};

export default FundingRequestDialog;
