import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface AutocompleteMessageProps {
  errorMessage?: string;
}

interface AutocompleteProps {
  disabled?: boolean;
}

export const AutocompleteContainer = styled.div<AutocompleteProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiAutocomplete-root {

    .MuiOutlinedInput-root{
      display: flex;
      width: 100%;
      height: 40px;
      font-size: ${fontSizes.regular} !important;
      font-family: 'Source Sans Pro', sans-serif !important;
      box-sizing: border-box;
      border-radius: 6px !important;
      padding: 0;
      padding-left: 9px;
      border: none!important;
      background-color: ${({ disabled }) => disabled ? themeColors.bg.light.inputDisabled : themeColors.bg.light.input}!important;;
      border: 2px solid ${({ disabled }) => disabled ? themeColors.border.inputDisabled : themeColors.border.input}!important;;
      color: ${themeColors.text.light.input}!important;;
      pointer-events: ${({ disabled }) => disabled && 'none'};
      overflow: ${({ disabled }) => disabled && 'hidden'};
    
      &:hover, &:-webkit-autofill:hover{
        background-color: ${themeColors.bg.light.inputHover}!important;
        border: 2px solid ${({ disabled }) => disabled ? themeColors.border.inputDisabled : themeColors.border.inputHover}!important;;
        color: ${themeColors.text.light.inputHover}!important; 
      }

      &:-webkit-autofill{
        -webkit-text-fill-color:  ${themeColors.text.light.input}!important; 
        background-color: ${themeColors.bg.light.input}!important;
        border: 2px solid ${themeColors.border.input}!important; 
        color: ${themeColors.text.light.input}!important;
      }
      &:focus, &:focus-visible, &:-webkit-autofill:focus{
        -webkit-text-fill-color:  ${themeColors.text.light.inputFocus}!important; 
        background-color: ${themeColors.bg.light.inputFocus}!important;
        border: 2px solid ${themeColors.border.inputFocus}!important; 
        color: ${themeColors.text.light.inputFocus}!important; 
        outline: none; 
      }
    }

    .MuiOutlinedInput-notchedOutline {
    border: none;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiButtonBase-root {
      color: ${themeColors.text.light.input}!important;
      background-color: transparent !important;
      &:hover,
      &:focus {
        background-color: transparent !important;
        box-shadow: none;
      }
    }

  }
`;

export const AutocompleteLabel = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body};
  margin-bottom: 4px;
`;

export const AutocompleteMessage = styled.span<AutocompleteMessageProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.text.error};
  ${typography.captionRegular};
`;
