import { Dialog } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const PurchaseOrderButtonContainer = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const PurchaseOrderButtonText = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.small};
`;

export const PurchaseOrderButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const G2FNoContactDialog = styled(Dialog)`
  &.MuiPaper-root {
    padding: 16px;
  }
`;

export const G2FNoContactDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const G2FNoContactDialogHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

export const G2FNoContactDialogActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding-top: 16px;
`;


export const LedgerDetailCreateInvoiceBannerContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
  padding: 0 16px;
`;

export const LedgerDetailCreateInvoiceBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LedgerDetailCreateInvoiceBannerDescription = styled.p`
  ${typography.bodyRegular};
`;

export const LedgerDetailCreateInvoiceBannerButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const LedgerDetailCreateInvoiceBannerButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const LedgerDetailCreateInvoiceBannerButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const LedgerDetailCreateInvoiceEdit = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
  cursor: pointer;
`;

export const LedgerDetailCreateInvoiceBannerButtonText = styled.p``;

export const LedgerDetailCreateInvoiceBannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;