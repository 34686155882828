import { Accordion } from '@mui/material';
import { boxShadow, styling, themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import styled from 'styled-components';

const shadowHighlight: string = ` 0px 8px 16px 0px rgba(48, 49, 51, 0.1), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)`;

export const CompanyDetailsMainWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const CompanyDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${isMobile() ? '1fr' : 'fit-content(354px) auto'};
  width: 100%;
  margin: 0 auto;
`;

export const CompanyDetailsContainer = styled.div`
  width: ${isMobile() ? '100%' : '80%'};
  min-width: ${isMobile() ? 'auto' : '760px'};
  max-width: 1200px;
`;

export const CompanyDetailsPageTitle = styled.h2`
  margin: 0;
  display: ${isMobile() ? 'none' : 'block'};
`;

export const CompanyDetailsContentWrapper = styled.div`
  box-shadow: ${boxShadow};
  margin: 17px 0;
  background-repeat: repeat;
  background-color: ${themeColors.white};
`;

export const CompanyDetailsHeader = styled.div`
  display: flex;
  align-items: ${isMobile() ? 'flex-start' : 'center'};
  justify-content: space-between;
  flex-direction: ${isMobile() ? 'column-reverse' : 'row'};
  gap: 16px;
  padding: 16px;
`;

export const CompanyDetailsHeaderName = styled.h2`
  display: flex;
`;

export const CompanyDetailsHeaderLogo = styled.div`
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: ${isMobile() ? '12px' : '16px 24px'};
  gap: 17px;

  img {
    width: ${isMobile() ? '35px' : '50px'};
  }
`;

export const CompanyDetailsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0!important;
    margin: 0;
    ::before {
      display: none;
    }
    .MuiAccordionDetails-root {
      box-shadow: ${shadowHighlight};
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 16px;
    }

    &.Mui-expanded {
      margin: 0;

      .MuiButtonBase-root {
        background-color: ${themeColors.bg.light.surfaceRaised};
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .MuiAccordionSummary-expandIconWrapper {
        color: ${themeColors.icon.primary};
        height: 42px;
        align-items: end;
      }

      .MuiCheckbox-root{
        background-color: transparent!important;
      }
      
    }
    .MuiButtonBase-root {
      padding: 0 16px;
      width: 100%;
      background-color: ${themeColors.bg.light.surface};
      border-radius: 16px;
      height: 80px;
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: ${themeColors.icon.light.muted};
      height: 42px;
      align-items: baseline;
  }
  }
  
`;

export const AccordionSummaryTitle = styled.h3`
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
  margin: 0;
`;

export const AccordionSummaryDetailRow = styled.div`
  display: grid;
  grid-template-columns: ${isMobile() ? '1fr' : '1fr 1fr'};
  gap: ${isMobile() ? '16px' : '50px'};
  margin-bottom: 16px;
`;

export const AccordionSummaryDetailLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AccordionSummaryDetailRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AccordionSummaryDetailLabel = styled.p`
  display: flex;
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
`;

export const AccordionSummaryDetailValue = styled.p`
  display: flex;
  color: ${themeColors.text.light.body};
  ${typography.bodyRegular};
  text-align: right;
`;

export const CompanyDetailsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'fit-content'};
`;

export const CompanyDetailsButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CompanyDetailsNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${isMobile() ? '0 16px 16px 16px' : '0 16px 19px 16px'};
`;

export const AccordionDetailsWrapper = styled.div`
  padding: 0 8px 8px 8px;
`;

export const CompanyDetailsLottieAnimation = styled(Lottie)`
  display: flex;
  height: 25px;
  margin-bottom: 16px;
`;

export const NonDefaultAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NonDefaultAccountsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 8px;
`;

export const NonDefaultAccountsTitle = styled.p`
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
`;

export const NonDefaultAccountsCollapse = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.info};
  cursor: pointer;
`;

export const LogoPlaceholder = styled.div`
  height: 85px;
  width: 225px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${themeColors.lightBlue};
  gap: 8px;
  border-radius: ${styling.defaultRadius};
`;

export const LogoPlaceholderText = styled.h5`
  background: linear-gradient(266.47deg, #56a4ed -0.24%, #0842c1 79.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const AccordionSummaryTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const AccordionSummaryTitleName = styled.p`
  color: ${themeColors.text.light.subtle};
`;

