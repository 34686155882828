import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRouteReducer } from './reducers';
import { rootEpic } from './epics';
import { createEpicMiddleware } from 'redux-observable';
// import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
var createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();

const persistConfig = {
  key: 'app',
  storage: storage,
  blacklist: ['app', 'fundingRequest', 'onboarding'] // Reducers to be excluded from REDUX-PERSIST
};

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
  persistReducer(persistConfig, createRouteReducer(history)),
  composeWithDevTools(applyMiddleware(epicMiddleware))
);

export const configureStore = () => {
  epicMiddleware.run(rootEpic);

  return store;
};
