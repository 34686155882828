import React, { FC } from 'react';
import GroupedToDo from 'utils/classes/todos/groupedToDo';
import ToDoBuilder from 'utils/classes/todos/todo';
import logo from './mock_buyer_logo.png';
import {
  GroupedToDosContainer,
  GroupedToDosHeadingHeader,
  GroupedToDosHeadingTextContainer,
  GroupedToDosHeadingTextDescription,
  GroupToDosContentContainer,
  GroupToDosRow
} from './styled';
import ToDo from './ToDo';

interface IToDos {
  groupedToDos: GroupedToDo[];
  hasLogos?: boolean;
  summaryToDo?: ToDoBuilder;
}

const ToDos: FC<IToDos> = ({ groupedToDos, hasLogos, summaryToDo }) => {
  return (
    <GroupedToDosContainer>
      {groupedToDos.map((g, i) => (
        <React.Fragment key={i}>
          <GroupToDosRow>
            {hasLogos && (
              <img
                src={logo}
                alt="logo"
                data-automation-id={`onboarding-img-buyer-logo-${g.groupedName}`}
                data-testid={`sp-onboarding-img-buyer-logo-${g.groupedName}`}
              />
            )}
            <GroupedToDosHeadingTextContainer>
              <GroupedToDosHeadingHeader
                data-automation-id={`onboarding-h4-group-name-${g.groupedName}`}
                data-testid={`sp-onboarding-h4-group-name-${g.groupedName}`}
              >
                {g.groupedName}
              </GroupedToDosHeadingHeader>
              <GroupedToDosHeadingTextDescription
                data-automation-id={`onboarding-p-grouped-task-description`}
                data-testid={`sp-onboarding-p-grouped-task-description`}
              >
                {g.groupDescription}
              </GroupedToDosHeadingTextDescription>
            </GroupedToDosHeadingTextContainer>
          </GroupToDosRow>
          <GroupToDosContentContainer>
            {g.todos.map((t, i) => (
              <React.Fragment key={t.title}>
                <ToDo {...t} />
              </React.Fragment>
            ))}
            {summaryToDo && g.todos.filter((t) => !t.isComplete).length === 0 && (
              <ToDo {...summaryToDo} />
            )}
          </GroupToDosContentContainer>
        </React.Fragment>
      ))}
    </GroupedToDosContainer>
  );
};

export default ToDos;
