import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import Notification from 'components/Notification';
import loadingSpinner from 'assets/lottie-animations/loading-dot.json';
import { FC, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  LedgerDetailGatewayToFinanceBannerButtonContainer,
  LedgerDetailGatewayToFinanceBannerButtonContentContainer,
  LedgerDetailGatewayToFinanceBannerContainer,
  LedgerDetailGatewayToFinanceBannerButtonText,
  LedgerDetailGatewayToFinanceBannerIconContainer,
  LedgerDetailGatewayToFinanceBannerContentContainer,
  LedgerDetailGatewayToFinanceBannerDescription,
  LedgerDetailGatewayToFinanceBannerButtonWrapper,
  LottieAnimationContainer
} from './styled';
import WarningIcon from 'assets/icons/WarningIcon';

const LedgerDetailGatewayToFinanceBanner: FC = () => {
  const [isCreatedViewVisible, setIsCreatedViewVisible] = useState<boolean>(true);
  const {
    error,
    loading,
    display,
    g2fCreateInvoiceId
  }: {
    error: string;
    loading: boolean;
    display: boolean;
    g2fCreateInvoiceId: string;
  } = useSelector((state: RootStateOrAny) => state.gatewayToFinance);

  const navigateToInvoice: () => void = () =>
    window.open(
      `https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${g2fCreateInvoiceId}`,
      '_BLANK'
    );

  const g2FErrorView: () => JSX.Element = () => (
    <LedgerDetailGatewayToFinanceBannerContainer>
      <Notification
        backgroundColor="rgba(228, 51, 6, 0.05)"
        padding="16px"
        border
        descriptionLarge
        titleLarge
        noShadow
        color={themeColors.text.error}
        title={error.split(' - ')[0]}
        description={error.split(' - ')[1]}
        icon={<WarningIcon color={themeColors.icon.error} />}
      />
    </LedgerDetailGatewayToFinanceBannerContainer>
  );

  const g2FLoadingView: () => JSX.Element = () => (
    <LedgerDetailGatewayToFinanceBannerContainer>
      <Notification
        backgroundColor="rgba(113, 133, 212, 0.05)"
        padding="16px"
        border
        titleLarge
        descriptionLarge
        color={themeColors.borderBlue}
        title="Connecting to Xero"
        icon={
          <LottieAnimationContainer
            loop
            animationData={loadingSpinner}
            data-testid="sp-loading-view"
          />
        }
        descriptionJSX={
          <LedgerDetailGatewayToFinanceBannerContentContainer>
            <LedgerDetailGatewayToFinanceBannerDescription>
              We are sending the information to your accounting package so that you can create an
              invoice from this purchase order.
              <br />
              <br />
              It will only take a moment.
            </LedgerDetailGatewayToFinanceBannerDescription>
          </LedgerDetailGatewayToFinanceBannerContentContainer>
        }
      />
    </LedgerDetailGatewayToFinanceBannerContainer>
  );

  const g2FCreatedView: () => JSX.Element = () =>
    isCreatedViewVisible ? (
      <LedgerDetailGatewayToFinanceBannerContainer>
        <Notification
          hasClosed
          backgroundColor="rgba(21, 182, 114, 0.05)"
          padding="16px"
          border
          titleLarge
          descriptionLarge
          fullContent
          closeHandler={() => setIsCreatedViewVisible(false)}
          icon={<ApproveIcon color={themeColors.icon.success} />}
          color={themeColors.text.success}
          title="Invoice created on Xero"
          noShadow
          descriptionJSX={
            <LedgerDetailGatewayToFinanceBannerContentContainer>
              <LedgerDetailGatewayToFinanceBannerDescription>
                Your invoice is now ready for you on Xero. You can view and approve the invoice by
                clicking the button below.
              </LedgerDetailGatewayToFinanceBannerDescription>
              <LedgerDetailGatewayToFinanceBannerButtonWrapper>
                <LedgerDetailGatewayToFinanceBannerButtonContainer data-testid="sp-g2f-banner-button">
                  <PrimaryButton
                    backgroundColor={themeColors.bg.light.surfaceInvert}
                    clickHandler={navigateToInvoice}
                    testingTag="-ledger-detail-g2f-banner-button"
                  >
                    <LedgerDetailGatewayToFinanceBannerButtonContentContainer>
                      <LedgerDetailGatewayToFinanceBannerButtonText
                        data-automation-id="ledger-detail-G2F-banner-button-text"
                        data-testid="sp-g2f-banner-button-text"
                      >
                        Go to Xero
                      </LedgerDetailGatewayToFinanceBannerButtonText>
                      <LedgerDetailGatewayToFinanceBannerIconContainer
                        data-automation-id="ledger-detail-div-g2f-banner-button-icon-container"
                        data-testid="sp-g2f-banner-button-icon"
                      >
                        <RightLongArrowIcon
                          color={themeColors.text.onPrimary}
                          height="10"
                          width="12"
                        />
                      </LedgerDetailGatewayToFinanceBannerIconContainer>
                    </LedgerDetailGatewayToFinanceBannerButtonContentContainer>
                  </PrimaryButton>
                </LedgerDetailGatewayToFinanceBannerButtonContainer>
              </LedgerDetailGatewayToFinanceBannerButtonWrapper>
            </LedgerDetailGatewayToFinanceBannerContentContainer>
          }
        />
      </LedgerDetailGatewayToFinanceBannerContainer>
    ) : (
      <></>
    );

  return display ? (loading ? g2FLoadingView() : error ? g2FErrorView() : g2FCreatedView()) : null;
};

export default LedgerDetailGatewayToFinanceBanner;
