import TextInput from 'components/forms/inputs/TextInput';
import { FC } from 'react';
import { BankAccountProps } from 'utils/interfaces/bank-account/bankAccount.interface';
import { BankAccountContainer } from '../styled';

interface IRestOfWorldBankDetails {
  accountNumber: BankAccountProps;
  iban: BankAccountProps;
  swiftOrBic: BankAccountProps;
}

const RestOfWorldBankDetails: FC<IRestOfWorldBankDetails> = ({
  accountNumber,
  iban,
  swiftOrBic
}) => (
  <>
    <BankAccountContainer data-testid="sp-bank-account-details-account-number">
      <TextInput
        label="Account number"
        placeholder="8 - 12  digit number"
        register={accountNumber.register}
        errorMessage={accountNumber.errorMessage}
        disabled={accountNumber.disabled}
        defaultValue={accountNumber.value}
        changeHandler={accountNumber.changeHandler}
        testingTagPage="bank-account-details"
      />
    </BankAccountContainer>
    <BankAccountContainer data-testid="sp-bank-account-details-iban">
      <TextInput
        label="IBAN"
        placeholder="i.e. GB15ABCD10203012345678"
        tooltipText="If you’re based in Europe, your International Bank Account Number (IBAN) will help banks based in other countries to identify yours for payment. It will be a combination of letters and numbers up to 34 characters long"
        register={iban.register}
        errorMessage={iban.errorMessage}
        disabled={iban.disabled}
        defaultValue={iban.value}
        changeHandler={iban.changeHandler}
        testingTagPage="bank-account-details"
      />
    </BankAccountContainer>
    <BankAccountContainer data-testid="sp-bank-account-details-bic-swift">
      <TextInput
        label="BIC/SWIFT"
        placeholder="8 - 11 digit code"
        tooltipText="A SWIFT code, also known as a BIC number, is used by banks to process international transactions ensuring they arrive at the correct bank and branch. This code is made up of letters and will be 8 or 11 characters long"
        register={swiftOrBic.register}
        errorMessage={swiftOrBic.errorMessage}
        disabled={swiftOrBic.disabled}
        defaultValue={swiftOrBic.value}
        changeHandler={swiftOrBic.changeHandler}
        testingTagPage="bank-account-details"
      />
    </BankAccountContainer>
  </>
);

export default RestOfWorldBankDetails;
