import styled from 'styled-components';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { boxShadow } from 'assets/theme/style';

interface ITooltipProps {
  backgroundColor: string;
  color: string;
}

export const LightTooltipContainer = styled.div`
  padding: 0;
  width: fit-content;
  border-radius: 16px;
  border: none;
  display: flex;
`;

export const LightTooltip = styled(
  ({ backgroundColor, color, className, ...props }: TooltipProps & ITooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(
  ({
    backgroundColor,
    color,
    theme
  }: {
    backgroundColor?: string;
    color?: string;
    theme: any;
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor,
      color: color,
      boxShadow: boxShadow,
      fontSize: 14,
      zIndex: 2,
      padding: '10px 16px'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor
    }
  })
);
