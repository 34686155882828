import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LedgerDetailHeader, {
  LedgerDetailHeaderProps
} from 'components/ledger/LedgerDetail/LedgerDetailHeader';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { FC, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  LedgerDetailAddCommentButtonContainer,
  LedgerDetailAddCommentContainer,
  LedgerDetailCommentContainer,
  LedgerDetailCommentTextArea
} from './styled';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';

interface LedgerDetailAddCommentProps {
  addCommentClickHandler: (comment: string) => void;
}

const LedgerDetailAddComment: FC<LedgerDetailAddCommentProps> = ({ addCommentClickHandler }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const {
    programOwnerDetails
  }: {
    programOwnerDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.program);
  const {
    documentForViewHeaderData
  }: {
    documentForViewHeaderData: {
      header: LedgerDetailHeaderProps | null;
      data: ILedgerInvoice | ILedgerPurchaseOrder | null;
    } | null;
  } = useSelector((state: RootStateOrAny) => state.context);

  const addClickHandler: () => void = () => {
    const el: any = document.getElementById('sp-comment-text-area');
    if (!el || !el.value) return;
    addCommentClickHandler(el.value || '');
  };

  const validateText: (e: any) => void = (e) =>
    setIsDisabled(!e.target.value || e.target.value.length === 0);

  return (
    <LayoutViewContainer size="small" margin="0 auto">
      <BaseCard noBoxShadow noBorder>
        <LedgerDetailAddCommentContainer>
          {documentForViewHeaderData && documentForViewHeaderData.header && (
            <LedgerDetailHeader
              {...documentForViewHeaderData.header}
              companyLogo={programOwnerDetails?.companyLogo || ''}
              reduced
            />
          )}
          <LedgerDetailCommentContainer>
            <Divider margin="16px 0 0 0" />
            <p
              data-automation-id="ledger-detail-add-comment-p-description"
              data-testid="sp-ledger-add-comment-description"
            >
              You are adding a comment to{' '}
              {documentForViewHeaderData?.header?.identifier || 'the selected document'} - this will
              be shown on the document and be visible to all users than can access this item.
            </p>
            <h5
              data-automation-id="ledger-detail-add-comment-h5-title"
              data-testid="sp-ledger-add-comment-title"
            >
              Comment
            </h5>
            <LedgerDetailCommentTextArea
              data-automation-id="ledger-detail-add-comment-textarea"
              data-testid="sp-ledger-add-comment-textarea"
              id="sp-comment-text-area"
              rows={10}
              placeholder="Enter your comment - this will be shown on the invoice (1000 characters max)"
              maxLength={1000}
              autoFocus
              onKeyUp={(e) => validateText(e)}
            />
            <LedgerDetailAddCommentButtonContainer>
              <PrimaryButton
                text="Add"
                clickHandler={addClickHandler}
                height="36px"
                width="104px"
                disabled={isDisabled}
              />
            </LedgerDetailAddCommentButtonContainer>
          </LedgerDetailCommentContainer>
        </LedgerDetailAddCommentContainer>
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default LedgerDetailAddComment;
