import { FC } from 'react';
import { DateBarContainer, DateValue } from './styled';

export interface DateBarProps {
  date?: string;
  color?: string;
  toEdge?: boolean;
}

const DateBar: FC<DateBarProps> = ({ date, color, toEdge }) => (
  <DateBarContainer data-automation-id="date-bar-div-container" color={color} toEdge={toEdge}>
    <DateValue data-automation-id="date-bar-p-value">{date}</DateValue>
  </DateBarContainer>
);

export default DateBar;
