import { FC } from 'react';
import {
  FundingRequestPaymentDetailsContainer,
  FundingRequestPaymentDetailsKey,
  FundingRequestPaymentDetailsRow,
  FundingRequestPaymentDetailsTitle,
  FundingRequestPaymentDetailsValue
} from './styled';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';

interface FundingRequestPaymentDetailsProps {
  totalFees: number;
  totalAdjustments: number;
  totalPaymentAmount: number;
  currency: string;
}

const FundingRequestPaymentDetails: FC<FundingRequestPaymentDetailsProps> = ({
  totalAdjustments,
  totalFees,
  totalPaymentAmount,
  currency
}) => {
  const totalInvoiceValue = () => {
    return totalPaymentAmount + totalFees - totalAdjustments;
  };
  return (
    <FundingRequestPaymentDetailsContainer>
      <FundingRequestPaymentDetailsTitle>Payment</FundingRequestPaymentDetailsTitle>
      <FundingRequestPaymentDetailsRow>
        <FundingRequestPaymentDetailsKey>Total invoiced amount</FundingRequestPaymentDetailsKey>
        <FundingRequestPaymentDetailsValue>
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(totalInvoiceValue(), 2)}
        </FundingRequestPaymentDetailsValue>
      </FundingRequestPaymentDetailsRow>
      <FundingRequestPaymentDetailsRow>
        <FundingRequestPaymentDetailsKey>Total fees</FundingRequestPaymentDetailsKey>
        <FundingRequestPaymentDetailsValue>
        -{CurrencySymbolsLookUp[currency]}{formatNumber(totalFees, 2)}
        </FundingRequestPaymentDetailsValue>
      </FundingRequestPaymentDetailsRow>
      <FundingRequestPaymentDetailsRow>
        <FundingRequestPaymentDetailsKey>Total adjustments</FundingRequestPaymentDetailsKey>
        <FundingRequestPaymentDetailsValue>
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(totalAdjustments, 2)}
        </FundingRequestPaymentDetailsValue>
      </FundingRequestPaymentDetailsRow>
      <FundingRequestPaymentDetailsRow>
        <FundingRequestPaymentDetailsKey>Total payment amount</FundingRequestPaymentDetailsKey>
        <FundingRequestPaymentDetailsKey>
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(totalPaymentAmount, 2)}
        </FundingRequestPaymentDetailsKey>
      </FundingRequestPaymentDetailsRow>
    </FundingRequestPaymentDetailsContainer>
  );
};

export default FundingRequestPaymentDetails;
