import GatewayToFinanceApi from 'api/gatewayToFinance/gatewayToFinance.api';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import {
  SET_GATEWAY_TO_FINANCE_ID,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE,
  SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS,
  SET_GATEWAY_TO_FINANCE_ID_FAILURE,
  SET_GATEWAY_TO_FINANCE_ID_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const addGatewayToFinance: (
  state: any,
  action: any,
  stage: number
) => Promise<AxiosResponse<string>> = async (state, action, stage) => {
  const appState = state.value.app;
  const g2fState = state.value.gatewayToFinance;
  const programBuyer = appState.program.participants.find((p: any) => p.role === 'BUYER');
  if (!programBuyer) throw Error('No buyer found on program participants');
  const gatewayToFinanceApi = new GatewayToFinanceApi();
  const { data, setup, externalCustomerRef } = action.payload;
  return gatewayToFinanceApi.postGatewayToFinance(
    g2fState.lineItems ? g2fState.lineItems : data,
    programBuyer.registeredName,
    setup,
    stage,
    externalCustomerRef
  );
};

//===================================================
//                      EPICS
//===================================================

const addGatewayToFinanceEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_GATEWAY_TO_FINANCE_ID),
    switchMap((action) =>
      from(addGatewayToFinance(state$, action, 1)).pipe(
        map((payload) => ({ type: SET_GATEWAY_TO_FINANCE_ID_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: SET_GATEWAY_TO_FINANCE_ID_FAILURE,
            payload: error.message || 'G2F request not successful.'
          })
        )
      )
    )
  );

const continueGatewayToFinanceEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_GATEWAY_TO_FINANCE_ID_CONTINUE),
    switchMap((action) =>
      from(addGatewayToFinance(state$, action, 2)).pipe(
        map((payload) => ({ type: SET_GATEWAY_TO_FINANCE_ID_CONTINUE_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: SET_GATEWAY_TO_FINANCE_ID_CONTINUE_FAILURE,
            payload: error.message || 'G2F request not successful.'
          })
        )
      )
    )
  );

export default combineEpics(addGatewayToFinanceEpic$, continueGatewayToFinanceEpic$);
