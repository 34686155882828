import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import {
  IOnboardingArtifact,
  IOnboardingEntity,
  IOnboardingEntityEvent
} from 'api/interfaces/onboarding/onboarding.interface';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { OnboardingActionsEnum } from 'lib/enums/onboarding/onboarding.enum';
import { store } from 'store';
import { UPDATE_REQUEST_ERROR, UPDATE_REQUEST_ERROR_APP_TERMINATE } from 'store/actions';
import { AppReducerStateProps } from 'store/reducers/app';

const _PATH_LEGAL_ENTITY: string = `${window.API_PATH_INTERNAL}/legalEntity`;
const _PATH_ARTIFACTS: string = `${window.API_PATH_INTERNAL}/artifacts`;
const _PATH_PROGRAM: string = `${window.API_PATH_INTERNAL}/program`;

export default class OnboardingApi {
  private pvtAxiosObj: AxiosInstance;
  private app: AppReducerStateProps;
  constructor(public accessToken?: string) {
    this.pvtAxiosObj = Axios.create();
    this.app = store.getState().app;
    this.pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${
      this.accessToken || this.app.accessToken
    }`;
    this.pvtAxiosObj.interceptors.response.use(
      (next: any) => {
        return Promise.resolve(next);
      },
      (error: any) => {
        if (
          error?.response?.request?.responseURL &&
          (error.response.request.responseURL as string).includes(
            'https://tlccsupplierpay-spmpnapi-as.azurewebsites.net/legalEntity/'
          ) &&
          error?.response.data.status === 404
        ) {
          store.dispatch({
            type: UPDATE_REQUEST_ERROR_APP_TERMINATE,
            payload:
              'ONBOARDING - the user you have logged in as does not exist on the Marco Polo Network. You will be logged out.'
          });
        } else {
          store.dispatch({
            type: UPDATE_REQUEST_ERROR,
            payload: error.message
          });
        }
        return Promise.reject(error);
      }
    );
  }

  public getLegalEntityByTenantId: (token?: string) => Promise<AxiosResponse<IOnboardingEntity>> =
    async (token) => {
      this.pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return await this.pvtAxiosObj.get<IOnboardingEntity>(
        `${_PATH_LEGAL_ENTITY}/${this.app.tenant}`
      );
    };

  public getLegalEntitiesByTenantId: (tenants: string[], token: string) => Promise<any[]> = async (
    tenants,
    token
  ) => {
    this.pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return tenants.map(async (t) => {
      const res = await this.pvtAxiosObj.get<IOnboardingEntity>(`${_PATH_LEGAL_ENTITY}/${t}`);
      return { tenantId: t, name: res.data.spec.name };
    });
  };

  public getEntityOnboardingActionsState: (
    legalEntityId: string
  ) => Promise<AxiosResponse<IOnboardingEntityEvent>> = async (legalEntityId) =>
    await this.pvtAxiosObj.get<IOnboardingEntityEvent>(
      `${_PATH_LEGAL_ENTITY}/${this.app.tenant}/onboarding`
    );

  public putEntityOnboardingActionEvent: (
    actionName: keyof typeof OnboardingActionsEnum,
    data: OnboardingActionEventPut
  ) => Promise<AxiosResponse<IOnboardingEntityEvent>> = async (actionName, data) => {
    if (!OnboardingActionsEnum[actionName])
      throw new Error('action type provided is not accepted.');
    if (!this.app.legalEntityInit) throw new Error('no legal entity returned.');
    return await this.pvtAxiosObj.put<IOnboardingEntityEvent>(
      `${_PATH_LEGAL_ENTITY}/${this.app.tenant}/onboarding/${actionName}/event`,
      data
    );
  };

  public getEntityOnboardingActionsStateByProgram: (id: string) => Promise<IOnboardingEntityEvent> =
    async (id) => {
      const response = await this.pvtAxiosObj.get<IOnboardingEntityEvent>(
        `${_PATH_LEGAL_ENTITY}/${this.app.tenant}/program/${id}/onboarding`
      );

      response.data.programId = id;

      return response.data;
    };

  public getEntityOnboardingProgramDetails: (id: string) => Promise<any> = async (id) => {
    const response = await this.pvtAxiosObj.get<any>(`${_PATH_PROGRAM}/${id}`);

    return response.data;
  };

  public putEntityOnboardingActionEventByProgram: (
    actionName: keyof typeof OnboardingActionsEnum,
    data: OnboardingActionEventPut,
    programId: string
  ) => Promise<AxiosResponse<IOnboardingEntityEvent>> = async (actionName, data, programId) => {
    if (!OnboardingActionsEnum[actionName]) throw new Error('Action name provided is not valid.');

    if (!this.app.legalEntityInit) throw new Error('no legal entity returned.');
    return await this.pvtAxiosObj.put<IOnboardingEntityEvent>(
      `${_PATH_LEGAL_ENTITY}/${this.app.tenant}/program/${programId}/onboarding/${actionName}/event`,
      data
    );
  };

  public getOnboardingArtifacts: (
    artifactId: string
  ) => Promise<AxiosResponse<IOnboardingArtifact>> = async (artifactId) =>
    await this.pvtAxiosObj.get<IOnboardingArtifact>(`${_PATH_ARTIFACTS}/${artifactId}`);

  public getOnboardingArtifactById: (artifactId: string) => Promise<IOnboardingArtifact> = async (
    artifactId
  ) => (await this.pvtAxiosObj.get<IOnboardingArtifact>(`${_PATH_ARTIFACTS}/${artifactId}`)).data;

  public getOnboardingArtifactDataById: (
    artifactId: string,
    artifactDataId: string,
    name: string
  ) => Promise<AxiosResponse<ArrayBuffer>> = async (artifactId, artifactDataId, name) =>
    await this.pvtAxiosObj.get<ArrayBuffer>(
      `${_PATH_ARTIFACTS}/${artifactId}/data/${artifactDataId}/${name}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      }
    );

  public postBankAccount: (data: FormData, artifactId: string) => Promise<AxiosResponse<any>> =
    async (data, artifactId) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return await this.pvtAxiosObj.post<any>(
        `${_PATH_ARTIFACTS}/${artifactId}/data`,
        data,
        config
      );
    };

  public putArtifactReady: (artifactId: string) => Promise<AxiosResponse<any>> = async (
    artifactId
  ) => await this.pvtAxiosObj.put<any>(`${_PATH_ARTIFACTS}/${artifactId}/ready`);
}
