import React, { FC } from 'react';
import { SideBarNavButtonContainer } from './styled';

export interface SideBarNavButtonProps {
  text?: string;
  activeLink?: boolean;
  disabled?: boolean;
  testingTag?: string;
}

const SideBarNavButton: FC<SideBarNavButtonProps> = ({
  text,
  activeLink,
  disabled,
  testingTag
}) => {
  return (
    <SideBarNavButtonContainer
      activeLink={activeLink}
      disabled={disabled}
      data-automation-id={testingTag}
    >
      {text}
    </SideBarNavButtonContainer>
  );
};

export default SideBarNavButton;
