/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Collapse } from '@mui/material';
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { LedgerInvoiceData } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import {
  IInvoiceRule,
  IInvoiceRuleField,
  ILogisticsRuleSubtemplateField,
  ProgramConfig,
  ProgramRuleTypeEnum
} from 'api/interfaces/program/program.interface';
import HideMoreIcon from 'assets/icons/HideMoreIcon';
import ShowMoreIcon from 'assets/icons/ShowMoreIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import AlertDialog from 'components/dialogs/alertDialog';
import { _MODULE_ID } from 'lib/constants/contants';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import _ from 'lodash';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Img } from 'react-image';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import {
  CREATE_NEW_INVOICE,
  HIDE_NEW_INVOICE_BAR,
  RESET_CREATE_NEW_INVOICE_ERROR,
  RESET_NEW_INVOICE_ID,
  SET_DRAFT_INVOICE_MODAL
} from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import GatewayToFinanceButton from 'views/PurchaseOrders/PurchaseOrderDetail/GatewayToFinanceButton';
import LedgerDetailBanner from '../LedgerDetail/LedgerDetailBanner';
import LedgerDetailCreateInvoiceBanner from '../LedgerDetail/LedgerDetailCreateInvoiceBanner';
import LedgerCreateInvoiceField from './LedgerCreateInvoiceField';
import {
  AccordionStyled,
  CreateInvoiceDialogBoldTitle,
  CreateInvoiceDialogDescriptionColumnDetail,
  CreateInvoiceDialogDescriptionDetail,
  CreateInvoiceDialogDescriptionDetailWrapper,
  CreateInvoiceDialogLineItemsContainer,
  CreateInvoiceDialogLineItemsHeader,
  CreateInvoiceDialogShowMoreContainer,
  CreateInvoiceDialogSummaryContainer,
  CreateInvoiceDialogSummaryInfo,
  CreateInvoiceDialogSummaryTitle,
  CreateInvoiceDialogTable,
  CreateInvoiceDialogTitleBold,
  CreateInvoiceDialogTitleRegular,
  FormStyled,
  GroupTableContainer,
  GroupedTable,
  LedgerCreateInvoiceAccordionTitle,
  LedgerCreateInvoicesAccordionContainer,
  LedgerCreateInvoicesHeader,
  LedgerCreateInvoicesHeaderAmountLabel,
  LedgerCreateInvoicesHeaderAmountValue,
  LedgerCreateInvoicesHeaderLeft,
  LedgerCreateInvoicesHeaderLogo,
  LedgerCreateInvoicesHeaderRight,
  LedgerCreateInvoicesHeaderTitle,
  LedgerCreateInvoicesHeaderTitleWrapper,
  LedgerCreateInvoicesNoData,
  LedgerCreateInvoicesWrapper,
  LedgerDetailAccordionTitleWrapper,
  CreateInvoiceDialogInvoiceEdit
} from './styled';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import Notification from 'components/Notification';
import { createInvoiceModel } from './createInvoiceModel';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { NavigateFunction, useNavigate } from 'react-router';
interface ILedgerDetailCreateInvoice {
  closeDialogHandler: () => void;
}

const LedgerCreateInvoice: FC<ILedgerDetailCreateInvoice> = ({ closeDialogHandler }) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const params: Readonly<Params<string>> = useParams();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [open, setOpen] = useState<Number[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const {
    draft,
    draftInvoiceModal,
    draftAsyncTaskID,
    draftPurchaseOrder,
    errorMessage,
    completed,
    loading
  }: {
    draft: boolean;
    completed: boolean;
    draftAsyncTaskID: string | null;
    draftInvoiceModal: ILogisticsRuleSubtemplateField[] | null;
    draftPurchaseOrder: ILedgerPurchaseOrder | null;
    errorMessage: string[];
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.createInvoice);

  useEffect(() => {
    if (draft && draftAsyncTaskID) {
      dispatch({
        type: CREATE_NEW_INVOICE,
        payload: { status: 'draft', taskID: draftAsyncTaskID }
      });
    }
  }, []);

  const {
    poForView
  }: {
    poForView: ILedgerPurchaseOrder | null;
  } = useSelector((state: RootStateOrAny) => state.purchaseOrder);

  const {
    programOwnerDetails
  }: {
    programOwnerDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.program);

  const {
    activeCurrencyCode
  }: {
    activeCurrencyCode: string;
  } = useSelector((state: RootStateOrAny) => state.app);

  const draftLineItems: LedgerLineItem[] = draftInvoiceModal
    ? (draftInvoiceModal.find((f) => f.name === 'lineItems')?.value as any[])?.filter(
        (l) => l.selected
      )
    : [];

  // Setting Invoice Details, Participant Details, Additional Information fields statically as we currently lack information from the rule API.

  const invoicedetailFields: string[] = [
    'currency',
    'paymentTerms',
    'poNumber',
    'createdDate',
    'createdBy'
  ];

  const participantFields: string[] = ['supplierReference', 'buyerReference'];

  const buyerReference: string = draftInvoiceModal
    ? (draftInvoiceModal?.find((r) => r.name === 'buyerReference')?.value as string) || ''
    : '';

  const invoicedetailsField: ILogisticsRuleSubtemplateField[] | null =
    draftInvoiceModal &&
    draftInvoiceModal.filter((field: ILogisticsRuleSubtemplateField) =>
      invoicedetailFields.includes(field.name)
    );

  const participantDetailsField: () => ILogisticsRuleSubtemplateField[] = () => {
    const data = draftInvoiceModal?.filter((field: ILogisticsRuleSubtemplateField) =>
      participantFields.includes(field.name)
    );
    const match = data?.find((d) => d.name === 'buyerReference');

    if (match)
      match.value = poForView?.data.buyerReference?.startsWith('FTL')
        ? (poForView?.data.buyerSubsidiaryName as string)
        : (poForView?.data.buyerReference as string);

    return data || [];
  };

  const additionalInformationField: ILogisticsRuleSubtemplateField[] | null =
    draftInvoiceModal &&
    draftInvoiceModal.filter(
      (field: ILogisticsRuleSubtemplateField) =>
        !participantFields.includes(field.name) &&
        !invoicedetailFields.includes(field.name) &&
        field.name !== 'amount' &&
        field.name !== 'invoiceNumber' &&
        field.name !== 'createdBy' &&
        field.name !== 'createdDate' &&
        field.type !== 'ARRAY' &&
        field.name !== 'cashPaidToDate'
    );

  const isCompleted: (data: ILogisticsRuleSubtemplateField[]) => boolean = (data) => {
    if (!data || data.length === 0) {
      return false;
    }

    for (const field of data) {
      if (
        field.name !== 'amount' &&
        field.name !== 'buyerReference' &&
        field.name !== 'createdBy' &&
        field.name !== 'createdDate' &&
        field.name !== 'paymentTerms' &&
        field.name !== 'supplierReference' &&
        field.name !== 'poNumber' &&
        field.name !== 'currency' &&
        field.type !== 'ARRAY'
      ) {
        if (field.type === 'OBJECT') {
          if (!isCompleted(field.nestedFields)) {
            return false;
          }
        } else {
          if (field.value === null || field.value === undefined || field.value === '') {
            return false;
          }
        }
      }
    }

    return true;
  };

  const currencyField: ILogisticsRuleSubtemplateField | undefined = draftInvoiceModal?.find(
    (field) => field.name === 'currency'
  );
  const invoiceCurrency: string = (currencyField?.value as string) || activeCurrencyCode;

  const confirmInvoice: () => void = () => {
    createInvoiceSPClickHandler();
    setConfirmDialogOpen(false);
  };

  const toggleConfirmClickHandler: () => void = () => {
    setConfirmDialogOpen(!confirmDialogOpen);
  };

  const subtotalAmount: number =
    draftInvoiceModal && draftLineItems && draftLineItems?.length > 0
      ? parseFloat(
          draftLineItems
            ?.reduce(
              (accum: number, item: LedgerLineItem) =>
                accum + (item.unitPrice || 0) * (item.quantity || 0),
              0
            )
            .toFixed(2)
        )
      : 0;

  const toggleShowMoreClickHandler: (clickedIndex: number) => void = (clickedIndex) => {
    if (open.includes(clickedIndex)) {
      const openCopy = open.filter((element) => {
        return element !== clickedIndex;
      });
      setOpen(openCopy);
    } else {
      const openCopy = [...open];
      openCopy.push(clickedIndex);
      setOpen(openCopy);
    }
  };

  const saveDraft: (data: LedgerInvoiceData) => void = (data) => {
    dispatch({
      type: SET_DRAFT_INVOICE_MODAL,
      payload: { data: data, id: params.purchaseOrderId || '' }
    });

    if (errorMessage.length > 0) {
      dispatch({ type: RESET_CREATE_NEW_INVOICE_ERROR });
    }
  };

  const closeHandler: () => void = () => {
    dispatch({ type: HIDE_NEW_INVOICE_BAR });
  };

  const resetHandler: () => void = () => {
    dispatch({ type: RESET_NEW_INVOICE_ID });
  };

  const handleExpandedChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //HERE
  const createInvoiceSPClickHandler: () => void = () => {
    if (!draftInvoiceModal) throw Error('NO DRAFT INVOICE STORED.');
    const mappedData = createInvoiceModel(draftInvoiceModal);

    const oaProgram = draftPurchaseOrder?.linkedPrograms.find((p) =>
      p.baseType.includes(ProgramType.OPEN_ACCOUNT)
    );

    if (mappedData)
      mappedData.amount = parseFloat(
        draftLineItems
          ?.reduce(
            (accum: number, item: LedgerLineItem) =>
              accum + (item.unitPrice || 0) * (item.quantity || 0),
            0
          )
          .toFixed(4 || '0')
      );

    const data = {
      data: [
        {
          assignment: {
            moduleId: _MODULE_ID,
            programIds: [oaProgram?.programId]
          },
          data: mappedData
        }
      ]
    };

    dispatch({
      type: CREATE_NEW_INVOICE,
      payload: { inv: data }
    });
  };

  const renderLedgerCreateInvoiceErrorBanner: () => JSX.Element = () => (
    <Notification
      padding="18px"
      backgroundColor={themeColors.bg.errorMuted}
      borderColor={themeColors.bg.error}
      border
      noShadow
      icon={<WarningIcon height="18px" width="18px" color={themeColors.icon.error} />}
      title="Not all fields are complete"
      description="There are some mandatory fields that need to be completed before creating this invoice."
    />
  );

  const formatName: (name: string) => string = (name) => {
    const labels: string[] = name.split('.');
    const lastTwoLabels: string[] = labels.slice(-2).map((label) =>
      label
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .split(' ')
        .map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    );
    return lastTwoLabels.join(' / ');
  };

  const renderLineItemField: (
    field: ILogisticsRuleSubtemplateField,
    values: any[],
    index: number,
    parentName?: string,
    parentId?: string
  ) => JSX.Element = (field, values, index, parentName, parentId) => {
    let level: number = 0;

    const mutReference = field.referencePath?.slice().split('.') || [];
    const name: string = field.referencePath ? formatName(field.referencePath) : field.displayName;
    mutReference.shift();

    if (field.type === 'OBJECT') {
      level = level + 1;
      if (parentName)
        field.displayName =
          parentName === undefined ? field.displayName : `${parentName} - ${field.displayName}`;

      if (parentId)
        field.referencePath = parentId === undefined ? field.name : `${parentId}.${field.name}`;

      if (field.displayName.split(' - ').length > 2)
        field.displayName = field.displayName.split(' - ').slice(-2).join(' - ');

      return (
        <>
          {field.nestedFields.map((f: ILogisticsRuleSubtemplateField, i: number) =>
            renderLineItemField(f, values, index * level, field.displayName, parentId)
          )}
        </>
      );
    }

    return (
      <CreateInvoiceDialogDescriptionColumnDetail key={`${index}-${field.displayName}`}>
        <CreateInvoiceDialogBoldTitle>{name}:</CreateInvoiceDialogBoldTitle>
        {_.get(values[index], mutReference) as string}
      </CreateInvoiceDialogDescriptionColumnDetail>
    );
  };

  const renderLineItemsInputFields: (lineItem: any, index: number) => JSX.Element[] = (
    lineItem,
    index
  ) => {
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined | null =
      draftInvoiceModal && draftInvoiceModal.find((r) => r.name === 'lineItems');

    if (!lineItemsRule) return [];

    const keysFromRule: string[] = lineItemsRule.nestedFields.map((f) => f.name);
    let newObj = {};

    keysFromRule.forEach((k) => {
      newObj = { ...newObj, [k]: lineItem[k] };
    });

    const fields = lineItemsRule.nestedFields
      .filter(
        (field) =>
          field.name !== 'quantity' &&
          field.name !== 'lineItemNumber' &&
          field.name !== 'unitOfMeasure' &&
          field.name !== 'unitPrice' &&
          field.name !== 'amount'
      )
      .map((n: ILogisticsRuleSubtemplateField) =>
        renderLineItemField(n, lineItemsRule.value as any[], index)
      );

    return fields;
  };

  const calculateLineItemTotalPrice = (data: LedgerLineItem): string => {
    const totalPrice =
      parseFloat(data.unitPrice?.toString() || '0') * parseFloat(data.quantity?.toString() || '0');
    return invoiceCurrency && CurrencySymbolsLookUp[invoiceCurrency] + formatNumber(totalPrice, 2);
  };

  const renderLineItemsDetails: () => JSX.Element = () => (
    <CreateInvoiceDialogLineItemsContainer>
      <CreateInvoiceDialogLineItemsHeader data-automation-id="create-invoice-dialog-div-header">
        <CreateInvoiceDialogTitleBold>
          {draftLineItems && draftLineItems?.length} item
          {draftLineItems && draftLineItems?.length === 1 ? '' : 's'}
        </CreateInvoiceDialogTitleBold>
        {!completed && (
          <CreateInvoiceDialogInvoiceEdit onClick={() => closeDialogHandler()}>
            Edit
          </CreateInvoiceDialogInvoiceEdit>
        )}
      </CreateInvoiceDialogLineItemsHeader>
      <CreateInvoiceDialogTable>
        <GroupTableContainer>
          <GroupedTable data-automation-id="create-invoice-dialog-table-grouped-invoice-table">
            <thead style={{ textAlign: 'left' }}>
              <tr>
                <th style={{ width: '40%' }}>LINE ITEM NUMBER</th>
                <th style={{ width: '8%' }}>QTY</th>
                <th style={{ width: '12%' }}>UNIT OF MEASURE</th>
                <th style={{ width: '15%' }}>UNIT PRICE</th>
                <th style={{ width: '25%' }}>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {draftLineItems?.map((d, index) => (
                <React.Fragment key={`${index}-fragment`}>
                  <tr
                    key={`${index}-lineItem`}
                    style={{
                      borderBottom: `${open.includes(index) ? 'none' : '1px solid #E4E4E4'}`
                    }}
                  >
                    <td
                      className="accordion-row"
                      onClick={() => toggleShowMoreClickHandler(index)}
                      data-testid="sp-create-invoice-show-more"
                    >
                      <CreateInvoiceDialogShowMoreContainer>
                        <CreateInvoiceDialogDescriptionDetail>
                          {d.lineItemNumber}
                        </CreateInvoiceDialogDescriptionDetail>
                        {open.includes(index) ? <HideMoreIcon /> : <ShowMoreIcon />}
                      </CreateInvoiceDialogShowMoreContainer>
                    </td>
                    <td className="accordion-row">{d.quantity}</td>

                    <td className="accordion-row">{d.unitOfMeasure}</td>
                    <td className="accordion-row">{d.unitPrice}</td>
                    <td className="accordion-row text-align-right">
                      {calculateLineItemTotalPrice(d)}0
                    </td>
                  </tr>
                  {open.includes(index) && (
                    <tr aria-colspan={3}>
                      <td className="accordion-row-collapse text-align-right more-detail">
                        <Collapse in={open.includes(index)}>
                          <CreateInvoiceDialogDescriptionDetailWrapper>
                            {renderLineItemsInputFields(d, index)}
                          </CreateInvoiceDialogDescriptionDetailWrapper>
                        </Collapse>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </GroupedTable>
        </GroupTableContainer>
      </CreateInvoiceDialogTable>

      <CreateInvoiceDialogSummaryContainer data-automation-id="create-invoice-dialog-div-summary-container">
        <CreateInvoiceDialogSummaryTitle data-automation-id="create-invoice-dialog-h5-summary-title">
          Summary
        </CreateInvoiceDialogSummaryTitle>
        <CreateInvoiceDialogSummaryInfo>
          <CreateInvoiceDialogTitleRegular data-automation-id="create-invoice-dialog-p-total-extended-amount-title">
            Total invoice amount
          </CreateInvoiceDialogTitleRegular>
          <CreateInvoiceDialogTitleBold data-automation-id="create-invoice-dialog-p-total-extended-amount-value">
            {invoiceCurrency && CurrencySymbolsLookUp[invoiceCurrency]}
            {formatNumber(subtotalAmount || 0, 2)}
          </CreateInvoiceDialogTitleBold>
        </CreateInvoiceDialogSummaryInfo>
      </CreateInvoiceDialogSummaryContainer>
    </CreateInvoiceDialogLineItemsContainer>
  );

  const renderFieldValue: (value: string) => any = (value) => {
    return draftInvoiceModal
      ? draftInvoiceModal.find((f) => f.name === `${value}`)?.value || ''
      : '';
  };
  const renderLedgerCreateInvoiceHeader: () => JSX.Element = () => (
    <LedgerCreateInvoicesHeader
      data-automation-id="ledger-create-invoices-header-div-container"
      data-testid="sp-ledger-create-invoices-header-container"
    >
      <LedgerCreateInvoicesHeaderLeft data-automation-id="lledger-create-invoices-header-div-header-left">
        <LedgerCreateInvoicesHeaderLogo data-automation-id="ledger-create-invoices-header-div-logo">
          <Img
            height={40}
            style={{ objectFit: 'contain' }}
            src={programOwnerDetails?.companyLogo || ''}
            alt="Company Logo"
          />
        </LedgerCreateInvoicesHeaderLogo>
        <LedgerCreateInvoicesHeaderTitleWrapper>
          <LedgerCreateInvoicesHeaderTitle data-automation-id="ledger-create-invoices-header-h2-document-number">
            {renderFieldValue('invoiceNumber') || ''}
          </LedgerCreateInvoicesHeaderTitle>
          <Chip
            type="Draft"
            color={themeColors.text.primary}
            radius="3px"
            height="18px"
            fontSize={fontSizes.small}
            semibold="bold"
            testingTag="ledger-create-invoices-header"
            tooltipText="draft"
          />
        </LedgerCreateInvoicesHeaderTitleWrapper>
      </LedgerCreateInvoicesHeaderLeft>
      <LedgerCreateInvoicesHeaderRight data-automation-id="ledger-detail-header-div-header-right">
        <LedgerCreateInvoicesHeaderAmountLabel data-automation-id="ledger-detail-header-h5-value-title">
          Invoice Amount
        </LedgerCreateInvoicesHeaderAmountLabel>
        <LedgerCreateInvoicesHeaderAmountValue data-automation-id="ledger-detail-header-h2-value">
          {invoiceCurrency && CurrencySymbolsLookUp[invoiceCurrency]}
          {formatNumber(subtotalAmount || 0, 2)}
        </LedgerCreateInvoicesHeaderAmountValue>
      </LedgerCreateInvoicesHeaderRight>
    </LedgerCreateInvoicesHeader>
  );

  const renderInvoiceDetails: () => JSX.Element = () => (
    <AccordionStyled
      disableGutters
      expanded={expanded === 'invoice'}
      onChange={handleExpandedChange('invoice')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        data-automation-id="ledger-create-invoices-details-accordion-summary"
      >
        <LedgerDetailAccordionTitleWrapper>
          <LedgerCreateInvoiceAccordionTitle data-automation-id="ledger-create-invoices-details-h4-accordion-title-invoice">
            Invoice details
          </LedgerCreateInvoiceAccordionTitle>

          <ApproveIcon height="18px" width="18px" fill={themeColors.icon.success} />
        </LedgerDetailAccordionTitleWrapper>
      </AccordionSummary>
      <AccordionDetails>
        {invoicedetailsField && draftInvoiceModal && (
          <LedgerCreateInvoiceField
            fields={invoicedetailsField}
            type="TEXT"
            values={draftInvoiceModal}
            saveDraft={saveDraft}
          />
        )}
      </AccordionDetails>
    </AccordionStyled>
  );

  const renderParticipantDetails: () => JSX.Element = () => (
    <AccordionStyled
      disableGutters
      expanded={expanded === 'participant'}
      onChange={handleExpandedChange('participant')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        data-automation-id="ledger-create-invoices-participant-details-accordion-summary"
      >
        <LedgerDetailAccordionTitleWrapper>
          <LedgerCreateInvoiceAccordionTitle data-automation-id="ledger-create-invoices-participant-details-h4-accordion-title-participant">
            Participant details
          </LedgerCreateInvoiceAccordionTitle>
          <ApproveIcon height="18px" width="18px" fill={themeColors.icon.success} />
        </LedgerDetailAccordionTitleWrapper>
      </AccordionSummary>
      <AccordionDetails>
        {participantDetailsField() && draftInvoiceModal && (
          <LedgerCreateInvoiceField
            fields={participantDetailsField()}
            type="TEXT"
            values={draftInvoiceModal}
            saveDraft={saveDraft}
          />
        )}
      </AccordionDetails>
    </AccordionStyled>
  );

  const renderLineItems: () => JSX.Element = () => (
    <AccordionStyled
      disableGutters
      expanded={expanded === 'lineItems'}
      onChange={handleExpandedChange('lineItems')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4a-content"
        id="panel4a-header"
        data-automation-id="ledger-create-invoices-line-items-accordion-summary"
      >
        <LedgerDetailAccordionTitleWrapper>
          <LedgerCreateInvoiceAccordionTitle data-automation-id="ledger-create-invoices-line-items-h4-accordion-title-line-items">
            Line items
          </LedgerCreateInvoiceAccordionTitle>
          {draftLineItems?.length > 0 ? (
            <ApproveIcon height="18px" width="18px" fill={themeColors.icon.success} />
          ) : (
            <WarningIcon height="18px" width="18px" fill={themeColors.icon.error} />
          )}
        </LedgerDetailAccordionTitleWrapper>
      </AccordionSummary>
      <AccordionDetails>
        {draftInvoiceModal && draftLineItems && renderLineItemsDetails()}
      </AccordionDetails>
    </AccordionStyled>
  );

  const renderAdditionalInformation: () => JSX.Element = () => (
    <AccordionStyled
      disableGutters
      expanded={expanded === 'information'}
      onChange={handleExpandedChange('information')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4a-content"
        id="panel4a-header"
        data-automation-id="ledger-create-invoices-additional-information-accordion-summary"
      >
        <LedgerDetailAccordionTitleWrapper>
          <LedgerCreateInvoiceAccordionTitle data-automation-id="ledger-create-invoices-additional-information-h4-accordion-title-additional-information">
            Additional information
          </LedgerCreateInvoiceAccordionTitle>
          {isCompleted(additionalInformationField || []) ? (
            <ApproveIcon height="18px" width="18px" color={themeColors.icon.success} />
          ) : (
            <WarningIcon height="18px" width="18px" color={themeColors.icon.error} />
          )}
        </LedgerDetailAccordionTitleWrapper>
      </AccordionSummary>
      <AccordionDetails>
        {additionalInformationField && draftInvoiceModal && (
          <LedgerCreateInvoiceField
            fields={additionalInformationField}
            values={draftInvoiceModal}
            saveDraft={saveDraft}
          />
        )}
      </AccordionDetails>
    </AccordionStyled>
  );

  const renderLedgerCreateInvoiceView: () => JSX.Element = () => (
    <LedgerCreateInvoicesWrapper>
      <FormStyled
        id="ledger-create-invoice-form"
        onSubmit={(event) => {
          event.preventDefault();
          // handleSubmit(onSubmit)();
        }}
      >
        {isCompleted(draftInvoiceModal || []) ? (
          <LedgerDetailCreateInvoiceBanner
            clickHandler={toggleConfirmClickHandler}
            closeHandler={closeDialogHandler}
            resetHandler={resetHandler}
            editHandler={closeDialogHandler}
            purchaseOrderId={params.purchaseOrderId || ''}
          />
        ) : (
          renderLedgerCreateInvoiceErrorBanner()
        )}

        {draft && renderLedgerCreateInvoiceHeader()}
        {draft && (
          <LedgerCreateInvoicesAccordionContainer>
            {renderInvoiceDetails()}

            {renderParticipantDetails()}

            {renderLineItems()}

            {renderAdditionalInformation()}
          </LedgerCreateInvoicesAccordionContainer>
        )}
      </FormStyled>
      <AlertDialog
        title="Are you sure?"
        description={`Please confirm that you are happy to create this invoice.`}
        open={confirmDialogOpen}
        clickHandler={confirmInvoice}
        clickSecondHandler={toggleConfirmClickHandler}
      />
    </LedgerCreateInvoicesWrapper>
  );

  const renderNoDataView: () => JSX.Element = () => (
    <LedgerCreateInvoicesNoData>No data</LedgerCreateInvoicesNoData>
  );

  return !loading ? (
    <LayoutViewContainer size="large" margin="0 auto" data-testid="create-invoice-dialog">
      {draftInvoiceModal ? renderLedgerCreateInvoiceView() : renderNoDataView()}
    </LayoutViewContainer>
  ) : (
    <LoaderFullPage />
  );
};

export default LedgerCreateInvoice;
