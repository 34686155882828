import { IUser } from 'api/interfaces/users/user.interface';

export const _user: IUser = {
  id: '',
  status: '',
  created: '',
  activated: '',
  statusChanged: '',
  lastLogin: '',
  lastUpdated: '',
  passwordChanged: '',
  isAdmin: false,
  profile: {
    firstName: '',
    lastName: '',
    mobilePhone: '',
    title: '',
    email: '',
    login: '',
    tenants: []
  }
};
