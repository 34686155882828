import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import React, {Component, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {getCookie, setCookie} from 'lib/helpers/cookieHelper';
import {DateTime} from 'luxon';
import {useAuth, withAuthenticationRequired} from 'react-oidc-context';
import {useDispatch, useSelector} from 'react-redux';
import {SET_TENANT, UPDATE_ACCESS_TOKEN} from 'store/actions';
import OnboardingApi from 'api/onboarding/onboarding.api';

const selectedPlatformName: string = 'supplier-platform';

export const RequiredAuth: React.FC = () => {
    const dispatch = useDispatch();
    const auth = useAuth();

    const Component = withAuthenticationRequired(Outlet, {
            OnRedirecting: () => <LoaderFullPage/>
        }
    )

    useEffect(() => {
        if (auth && auth.isAuthenticated) {
            const accessToken = auth.user?.access_token || '';
            const groups: string[] = [...(auth.user?.profile as any).groups];
            const tenants: string[] = [...(auth.user?.profile as any).tenants];

            const selectedPlatform: string = getCookie(selectedPlatformName);
            if (!selectedPlatform) setCookie(selectedPlatformName, tenants[0], 365);

            const onboardingApi = new OnboardingApi(accessToken);
            onboardingApi.getLegalEntitiesByTenantId(tenants, accessToken)
                .then(data => Promise.all(data)
                    .then(res =>
                        dispatch({
                            type: UPDATE_ACCESS_TOKEN,
                            payload: {
                                tenant: selectedPlatform || tenants[0],
                                tenants: res,
                                accessToken,
                                email: auth.user?.profile.preferred_username || '',
                                username: auth.user?.profile.name || '',
                                userSubId: auth.user?.profile.sub,
                                tokenExpiryMillis: DateTime.fromMillis(0)
                                    .plus({seconds: auth.user?.expires_in || 3500})
                                    .minus({minute: 1})
                                    .toMillis(),
                                groups: groups
                            }
                        })
                    )
                )

        }
    }, [auth, dispatch]);

    return <Component/>;
};
