import { FC, useState } from 'react';
import BaseCard from 'components/common/cards/BaseCard';
import ClearIcon from 'assets/icons/ClearIcon';
import { themeColors } from 'assets/theme/style';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import {
  FundingRequestMoreDetailsWrapper,
  FundingRequestMoreDetailsLink,
  FundingRequestMoreDetailsContainer,
  FundingRequestMoreDetailsRow,
  FundingRequestMoreDetailsText,
  FundingRequestMoreDetailsLiborRow,
  FundingRequestMoreDetailsLiborTitle,
  FundingRequestMoreDetailsLiborIconWrapper,
  FundingRequestMoreDetailsBaseRatesContainer,
  FundingRequestMoreDetailsLiborTitleRow
} from './styled';
import { FundingCosts } from 'utils/interfaces/early-payment/earlyPayment.interface';
import {
  IBaseRate,
  IBaseRateReferenced,
  IBaseRates
} from 'utils/interfaces/base-rates/baseRates.interface';
import { RootStateOrAny, useSelector } from 'react-redux';
import { BaseRatesEnum } from 'lib/enums/base-rates/baseRates.enum';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';

export interface FundingRequestMoreDetailsProps extends FundingCosts {
  currency: string;
  showLink?: boolean;
}

const FundingRequestMoreDetails: FC<FundingRequestMoreDetailsProps> = ({
  showLink = false,
  currency,
  totalBaseRateCost,
  totalMarginCost
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showBaseRate, setShowBaseRate] = useState<boolean>(false);
  const baseRates: IBaseRates | null = useSelector((state: RootStateOrAny) => state.app.baseRates);

  const renderBaseRates: () => JSX.Element = () => {
    if (!baseRates || baseRates.type === BaseRatesEnum.FIXED) return <></>;
    const ratesWithValues: IBaseRate[] = (baseRates.rates as IBaseRateReferenced).tenorData.filter(
      (rate) => rate.rate !== null
    );
    return (
      <FundingRequestMoreDetailsBaseRatesContainer>
        {ratesWithValues.map((rate, i) => (
          <FundingRequestMoreDetailsLiborRow key={rate.tenor || i}>
            <FundingRequestMoreDetailsText
              data-automation-id={`early-payment-request-p-label-libor-${rate.tenor?.toLowerCase()}`}
            >
              {toSentenceCase(rate.tenor || '')}
            </FundingRequestMoreDetailsText>
            <FundingRequestMoreDetailsText
              data-automation-id={`early-payment-request-p-value-libor-${rate.tenor?.toLowerCase()}`}
            >
              {rate.rate}
            </FundingRequestMoreDetailsText>
          </FundingRequestMoreDetailsLiborRow>
        ))}
      </FundingRequestMoreDetailsBaseRatesContainer>
    );
  };

  const renderBaseRateView: () => JSX.Element = () => (
    <BaseCard margin="8px 0 0 0">
      <FundingRequestMoreDetailsLiborTitleRow data-testid="sp-more-details-libor">
        <FundingRequestMoreDetailsLiborTitle data-automation-id="early-payment-request-h5-title-libor">
          Libor rate - {currency}
        </FundingRequestMoreDetailsLiborTitle>
        <FundingRequestMoreDetailsLiborIconWrapper
          onClick={() => setShowBaseRate(false)}
          data-testid="sp-funding-request-more-details"
        >
          <ClearIcon
            data-automation-id="early-payment-request-svg-label-libor-icon"
            height="10"
            width="10"
            color={themeColors.icon.primary}
          />
        </FundingRequestMoreDetailsLiborIconWrapper>
      </FundingRequestMoreDetailsLiborTitleRow>
      {renderBaseRates()}
    </BaseCard>
  );
  const renderBaseRateLink: () => JSX.Element = () =>
    baseRates?.type === BaseRatesEnum.REFERENCED ? (
      <FundingRequestMoreDetailsLink
        data-automation-id="early-payment-request-p-more-details-base-rates-link"
        data-testid="sp-more-details-base-rates-link"
        onClick={() => setShowBaseRate(true)}
      >
        Show base rate table
      </FundingRequestMoreDetailsLink>
    ) : (
      <FundingRequestMoreDetailsRow>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-label-base-rate">
          Base rate
        </FundingRequestMoreDetailsText>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-value-base-rate">
          {baseRates?.rates}
        </FundingRequestMoreDetailsText>
      </FundingRequestMoreDetailsRow>
    );

  const renderMoreDetailLink: (title: string, status: boolean) => JSX.Element = (title, status) => (
    <FundingRequestMoreDetailsLink
      data-automation-id="early-payment-request-p-more-details-link"
      data-testid="sp-more-details-link"
      onClick={() => setOpen(status)}
    >
      {title}
    </FundingRequestMoreDetailsLink>
  );

  const renderMoreDetailView: () => JSX.Element = () => (
    <FundingRequestMoreDetailsContainer>
      <FundingRequestMoreDetailsRow>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-label-margin-cost">
          Margin cost
        </FundingRequestMoreDetailsText>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-value-margin-cost">
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(totalMarginCost, 2)}
        </FundingRequestMoreDetailsText>
      </FundingRequestMoreDetailsRow>
      <FundingRequestMoreDetailsRow>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-label-base-rate-cost">
          Base rate cost
        </FundingRequestMoreDetailsText>
        <FundingRequestMoreDetailsText data-automation-id="early-payment-request-p-value-base-rate-cost">
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(totalBaseRateCost, 2)}
        </FundingRequestMoreDetailsText>
      </FundingRequestMoreDetailsRow>
      <FundingRequestMoreDetailsContainer>
        {!showBaseRate && renderBaseRateLink()}
        {showBaseRate && renderBaseRateView()}
      </FundingRequestMoreDetailsContainer>
    </FundingRequestMoreDetailsContainer>
  );

  return (
    <FundingRequestMoreDetailsWrapper>
      {!open && !showLink && renderMoreDetailLink('more details', true)}
      {!showLink && open && renderMoreDetailLink('close', false)}
      {(open || showLink) && renderMoreDetailView()}
    </FundingRequestMoreDetailsWrapper>
  );
};

export default FundingRequestMoreDetails;
