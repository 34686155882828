/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ILedgerInvoice, LedgerActiveFunding } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayment as ILedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import PaymentApi from 'api/ledger/payment.api';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import TransactionHistory from 'components/funding-request/TransactionHistory';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatDate, formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { store } from 'store';
import LedgerSummaryItemBuilder from 'utils/classes/ledger/ledgerSummaryItemBuilder';
import LedgerDetailSummary from '../LedgerDetail/LedgerDetailSummary';
import LedgerPaymentHeader from './LedgerPaymentHeader';
import LedgerPaymentInvoices, { PaymentInvoiceViewModel } from './LedgerPaymentInvoices';
import { AccordionStyled } from './styled';
import MaturingPaymentDetail from 'views/Payments/PaymentDetail/MaturingPaymentDetail';
import TradePaymentDetail from 'views/Payments/PaymentDetail/TradePaymentDetail';

const LedgerPayment: FC = () => {
  const params = useParams();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [payment, setPayment] = useState<ILedgerPayment | null>(null);
  const [invoices, setInvoices] = useState<ILedgerInvoice[] | null>([]);
  const [requestAmount, setRequestAmount] = useState<number | null>(null);
  const [baseRateCosts, setBaseRateCosts] = useState<number | null>(null);
  const [fees, setFees] = useState<number | null>(null);
  const [adjustments, setAdjustments] = useState<number | null>(null);
  const [marginCosts, setMarginCosts] = useState<number | null>(null);
  const [data, setData] = useState<PaymentInvoiceViewModel[]>([]);

  const invoiceLedger: ILedgerInvoice[] = useSelector(
    (state: RootStateOrAny) => state.app.invoiceLedger
  );

  const paymentApi = new PaymentApi(store);
  const invoiceApi = new InvoiceApi(store);

  useEffect(() => {
    const invoiceIdFromParams: string | undefined = params?.invoiceId;
    if (invoiceIdFromParams) {
      const inv = invoiceLedger.find((inv) => inv.invoiceId === invoiceIdFromParams);

      if (!inv) {
        setErrorState();
        return;
      }

      const paymentIds: string[] = inv.linkedPayments
        ?.map((payment) => payment.paymentId)
        .filter((id) => Boolean(id)) as string[];

      getPayments(paymentIds);
    }
  }, []);

  useEffect(() => {
    if (invoices && invoices?.length > 0) setData(mapInvoiceData());
  }, [invoices]);

  const getPayments: (ids: string[]) => void = async (ids) => {
    try {
      const paymentsResponse = await paymentApi.getPaymentsByPaymentIds(ids);

      const payments = paymentsResponse.data.value;
      if (payments.length === 0) return setErrorState();

      setPayment(payments[0]);

      if (payments[0]?.type === 'MATURING') {
        const paymentItemsResponse = await paymentApi.getPaymentsLedgerPaymentItem(
          payments[0].id || ''
        );

        const invoiceIds: string[] = paymentItemsResponse.data.value
          .map(
            (v) =>
              v.externalReferences.find((r) => r.referenceKind === 'INVOICE_ID')?.referenceValue ||
              ''
          )
          .filter((id) => Boolean(id));

        const paymentInvoices = await paymentApi.getPaymentInvoicesByPaymentIds(invoiceIds);

        setFees(paymentInvoices.data.value.reduce((acc, val) => acc + val.matchingFees || 0, 0));
        setAdjustments(
          paymentInvoices.data.value.reduce((acc, val) => acc + val.appliedAdjustment || 0, 0)
        );

        setInvoices(paymentInvoices.data.value);
      }

      if (payments[0]?.type === 'TRADE') {
        if (payments.length === 0) return setErrorState();

        const paymentItemsResponse = await paymentApi.getPaymentsLedgerPaymentItem(
          payments[0].id || ''
        );

        const tradeMatchedId = paymentItemsResponse.data.value[0].externalReferences.find(
          (ex) => ex.referenceKind === 'TRADE_ID'
        )?.referenceValue;

        if (!tradeMatchedId) return setErrorState();

        const paymentInvoices = await invoiceApi.getInvoiceLedgerByFundingId(tradeMatchedId);

        setInvoices(paymentInvoices.data.value);
        calculatePaymentData(paymentInvoices.data.value);
      }
    } finally {
      setLoading(false);
    }
  };

  const setErrorState: () => void = () => {
    setErrorMessage('No payments found!');
    setLoading(false);
  };

  const calculatePaymentData: (invs: ILedgerInvoice[]) => void = (invs) => {
    const ac = invs
      .map((inv) => inv.activeFunding)
      .filter((f) => Boolean(f)) as LedgerActiveFunding[];

    setBaseRateCosts(ac.reduce((acc, val) => acc + val.invoiceBaseRateCosts, 0));
    setRequestAmount(invs.reduce((acc, val) => acc + val.netAmount, 0));
    setMarginCosts(ac.reduce((acc, val) => acc + val.invoiceBaseRateCosts, 0));
  };

  const mapInvoiceData: () => PaymentInvoiceViewModel[] = () => {
    if (!invoices) return [];
    const mappedInvoiceViewModel: PaymentInvoiceViewModel[] = invoices.map((inv) => {
      return {
        invoiceNumber: inv.invoiceNumber || '',
        buyer: inv.buyerRef || '',
        paymentDueDate: formatDateTime(inv.maturityDate || '', 'yyyy-LL-dd'),
        currency: inv.invoiceCurrency,
        invoiceAmount: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv.data.amount || 0,
          2
        )}`,
        netAmount: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv?.netAmount || 0,
          2
        )}`,
        fees: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${
          inv && inv.matchingFees > 0 ? '-' : ''
        }${formatNumber(inv.matchingFees || 0, 2)}`,
        adjustments: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv.appliedAdjustment || 0,
          2
        )}`,
        baseRateCosts: `${
          inv?.activeFunding && inv.activeFunding.invoiceBaseRateCosts >= 0 ? '-' : ''
        }${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv?.activeFunding ? inv.activeFunding.invoiceBaseRateCosts : 0,
          2
        )}`,
        marginCosts: `${
          inv?.activeFunding && inv.activeFunding.invoiceMarginCosts >= 0 ? '-' : ''
        }${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv?.activeFunding ? inv.activeFunding.invoiceMarginCosts : 0,
          2
        )}`,
        totalPaymentAmount: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv?.activeFunding ? inv.activeFunding.invoicePurchasePrice : 0,
          2
        )}`,
        fundingCosts: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv.activeFunding?.invoiceFundingCost || 0,
          2
        )}`,
        purchasePrice: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
          inv.activeFunding?.invoicePurchasePrice || 0,
          2
        )}`,
        id: inv?.invoiceId || '',
        currencySymbol: CurrencySymbolsLookUp[inv.invoiceCurrency]
      };
    });
    return mappedInvoiceViewModel;
  };

  const renderPaymentView: () => JSX.Element = () =>
    payment ? (
      <>
        <LedgerPaymentHeader
          paymentValue={payment.amount}
          paymentCurrency={payment.currency}
          paymentType={payment.type}
        />
        <LedgerDetailSummary
          data={[
            new LedgerSummaryItemBuilder(
              'Payment issuer',
              '',
              payment.fromParticipant.registeredName || '',
              ''
            ),
            new LedgerSummaryItemBuilder(
              'Beneficiary',
              '',
              payment.toParticipant.registeredName || '',
              ''
            ),
            new LedgerSummaryItemBuilder(
              'Expected payment date',
              '',
              formatDate(payment.expectedPaymentDate || ''),
              ''
            )
          ]}
          numericSummaryData={
            payment.type === 'TRADE'
              ? [
                  new LedgerSummaryItemBuilder(
                    'Total request amount',
                    'totalRequestAmount',
                    requestAmount || 0,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Base rate costs',
                    'baseRateCosts',
                    `${baseRateCosts && baseRateCosts > 0 ? '-' : ''}${baseRateCosts || 0}`,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Margin costs',
                    'marginCosts',
                    `${marginCosts && marginCosts > 0 ? '-' : ''}${marginCosts || 0}`,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Total payment amount',
                    'totalPaymentAmount',
                    payment.amount || 0,
                    ''
                  )
                ]
              : [
                  new LedgerSummaryItemBuilder(
                    'Total request amount',
                    'totalRequestAmount',
                    requestAmount || 0,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Total fees',
                    'fees',
                    `${fees && fees > 0 ? '-' : ''}${fees || 0}`,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Total adjustments',
                    'marginCosts',
                    `${adjustments && adjustments > 0 ? '-' : ''}${adjustments || 0}`,
                    ''
                  ),
                  new LedgerSummaryItemBuilder(
                    'Total payment amount',
                    'totalPaymentAmount',
                    payment.amount || 0,
                    ''
                  )
                ]
          }
          outstandingAmount={0}
          paymentDueDate={''}
          currency={payment.currency || ''}
          type={AssetEnum.PAYMENT}
          isProgramFixedCost={false}
        />
        <Divider margin="0 0 8px 0" />
        <AccordionStyled disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 data-automation-id="early-payment-request-h4-accordion-title-transaction-history">
              Event history
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            {payment.type && (
              <TransactionHistory
                paymentType={payment.type}
                currencyCode={payment.currency || ''}
                loading={loading}
                paidDate={formatDateTime(payment.receivedDate || '', `${_DATE_FORMAT} HH:mm`)}
                bankAccountId={payment.destinationBankAccountId}
                entityName={payment?.fromParticipant.registeredName || ''}
              />
            )}
          </AccordionDetails>
        </AccordionStyled>
        <Divider margin="0 0 8px 0" />
        {invoices && data && (
          <LedgerPaymentInvoices
            invoices={data}
            loading={loading}
            isMaturing={payment.type === 'MATURING'}
          />
        )}
      </>
    ) : (
      <></>
    );

  return loading ? (
    <LoaderInPage />
  ) : errorMessage ? (
    <h5 style={{ padding: '24px' }} data-automation-id="ledger-payment-h5-no-data">
      {errorMessage}
    </h5>
  ) : payment && payment.type === 'MATURING' ? (
    <MaturingPaymentDetail paymentIdData={payment?.id || ''} />
  ) : (
    renderPaymentView()
  );
};

export default LedgerPayment;
