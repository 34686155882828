import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { SideBarData } from 'utils/interfaces/sidebar/sidebar.interface';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import SideBarNavButton from 'components/common/buttons/SideBarNavButton';
import { ReducerAction } from 'store/reducers';
import { UPDATE_CURRENT_PATH } from 'store/actions';
import { useDispatch } from 'react-redux';
import { SidebarLinksContainer } from './styled';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';

interface sideBarMenuProps {
  sideBarMenuData: SideBarData | undefined;
  pathname: string;
}

const SidebarDesktop: FC<sideBarMenuProps> = ({ sideBarMenuData, pathname }) => {
  const dispatch: (arg: ReducerAction) => void = useDispatch();
  const isActiveLink: (path: string) => boolean = (path: string) => pathname.includes(path);
  return (
    <SidebarLinksContainer>
      {sideBarMenuData?.links &&
        Object.values(sideBarMenuData.links).map(({ text, path }: NavigationItem, key: number) => (
          <NavLink
            style={
              path ? { textDecoration: 'none' } : { textDecoration: 'none', pointerEvents: 'none' }
            }
            key={key + `-${text}`}
            to={path || ''}
            onClick={() => path && dispatch({ type: UPDATE_CURRENT_PATH, payload: { path } })}
            data-automation-id={`side-bar-a-${toKebabCase(text || '')}`}
          >
            <SideBarNavButton
              text={text}
              activeLink={isActiveLink(path || '')}
              disabled={path ? false : true}
              testingTag={`side-bar-h4-${toKebabCase(text || '')}`}
            />
          </NavLink>
        ))}
    </SidebarLinksContainer>
  );
};

export default SidebarDesktop;
