/* eslint-disable react-hooks/exhaustive-deps */
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import {
  IOnboardingEntityEvent,
  IOnboardingEntityEventStateActions
} from 'api/interfaces/onboarding/onboarding.interface';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import notFound from 'assets/lottie-animations/not_found.json';
import { themeColors } from 'assets/theme/style';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import Snackbar from 'components/snackbar';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum
} from 'lib/enums/onboarding/onboarding.enum';
import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  RESET_ONBOARDING_ERROR,
  TRIGGER_SNACKBAR,
  UPDATE_ONBOARDING_ACTION_EVENT,
  UPDATE_REQUEST_ERROR_APP_TERMINATE
} from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import OnboardingStepCompanyDetails from './OnboardingSteps/OnboardingStepCompanyDetails';
import OnboardingStepConfirmIdentity from './OnboardingSteps/OnboardingStepConfirmIdentity';
import OnboardingStepPersonalDetails from './OnboardingSteps/OnboardingStepPersonalDetails';
import OnboardingStepTermsConditions from './OnboardingSteps/OnboardingStepTermsConditions';
import OnboardingTasks from './OnboardingTasks';
import {
  OnboardingLayoutContainer,
  QontoConnector,
  QontoStepIconRoot,
  SnackbarContainer,
  StepContainer
} from './styled';

const steps: string[] = ['User details', 'Company details', 'Terms & conditions'];

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <div
          style={{
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            backgroundColor: themeColors.royalGold
          }}
        />
      ) : completed ? (
        <ApproveIcon color={themeColors.icon.primary} />
      ) : (
        <div
          style={{
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            backgroundColor: themeColors.text.light.muted
          }}
        />
      )}
    </QontoStepIconRoot>
  );
};

const Onboarding: FC = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<number>(0);

  const {
    onboardingComplete,
    onboardingInitData,
    snackbarOpen,
    accessToken
  }: {
    onboardingComplete: boolean | null;
    onboardingInitData: IOnboardingEntityEvent | null;
    snackbarOpen: boolean;
    accessToken: string;
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    loading,
    onboardingData,
    error
  }: { loading: boolean; onboardingData: IOnboardingEntityEvent | null; error: string } =
    useSelector((state: RootStateOrAny) => state.onboarding);

  const { terminationError }: { terminationError: string } = useSelector(
    (state: RootStateOrAny) => state.error
  );

  const errorSnackbarData: SnackbarData = {
    title: <h5>Error</h5>,
    message: <>{error}</>,
    leftIcon: <WarningIcon color={themeColors.icon.error} />,
    type: 'error'
  };

  useEffect(() => {
    // testReset();
    if (!loading && onboardingData && accessToken) {
      determineActiveStep(onboardingData);
    }
  }, [onboardingData, loading, accessToken]);

  useEffect(() => {
    if (error) {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: errorSnackbarData } });
      setTimeout(() => {
        dispatch({
          type: TRIGGER_SNACKBAR,
          payload: { open: false, data: null }
        });
        dispatch({
          type: RESET_ONBOARDING_ERROR
        });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (terminationError !== null && terminationError !== undefined && terminationError.length > 0)
      setTimeout(() => {
        dispatch({
          type: UPDATE_REQUEST_ERROR_APP_TERMINATE,
          payload: null
        });
      }, 10000);
  }, [terminationError]);

  useEffect(() => {
    // oktaAuth.signOut();
    if (onboardingInitData) determineActiveStep(onboardingInitData);
  }, [onboardingInitData]);

  const determineActiveStep: (data: IOnboardingEntityEvent) => void = (data) => {
    if (!data) return;

    const { state } = data;
    const { actions } = state;

    if (!actions) {
      setActiveStep(-1);
      return;
    }

    const steps: number[] = [
      vendorIDStepCheck(actions),
      vendorPersonalDetailsStepCheck(actions),
      vendorCompanyDetailsStepCheck(actions),
      vendorTermsAndConditionsStepCheck(actions)
    ];

    steps.forEach((step, i) => {
      if (i === 0 && step === 0) {
        setActiveStep(-1);
        return;
      }
      if (step === -1 && steps[i + 1] > -1) {
        setActiveStep(steps[i + 1]);
        return;
      }
      if (i === 3 && step === -1) setActiveStep(4);

      // return setActiveStep(-1);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testReset: () => void = () => {
    dispatch({
      type: UPDATE_ONBOARDING_ACTION_EVENT,
      payload: {
        actionName: OnboardingActionsEnum.vendorID,
        data: new OnboardingActionEventPut('', '', OnboardingActionStatusEnum.initiated)
      }
    });
    dispatch({
      type: UPDATE_ONBOARDING_ACTION_EVENT,
      payload: {
        actionName: OnboardingActionsEnum.personalDetails,
        data: new OnboardingActionEventPut('', '', OnboardingActionStatusEnum.initiated)
      }
    });
    dispatch({
      type: UPDATE_ONBOARDING_ACTION_EVENT,
      payload: {
        actionName: OnboardingActionsEnum.companyDetails,
        data: new OnboardingActionEventPut('', '', OnboardingActionStatusEnum.initiated)
      }
    });
    dispatch({
      type: UPDATE_ONBOARDING_ACTION_EVENT,
      payload: {
        actionName: OnboardingActionsEnum.acceptTermsAndConditions,
        data: new OnboardingActionEventPut('', '', OnboardingActionStatusEnum.initiated)
      }
    });
  };

  const vendorIDStepCheck: (actions: IOnboardingEntityEventStateActions) => number = (actions) =>
    actions[OnboardingActionsEnum.vendorID]?.status !== OnboardingActionStatusEnum.completed
      ? 0
      : -1;

  const vendorPersonalDetailsStepCheck: (actions: IOnboardingEntityEventStateActions) => number = (
    actions
  ) =>
    actions[OnboardingActionsEnum.personalDetails]?.status !== OnboardingActionStatusEnum.completed
      ? 0
      : -1;

  const vendorCompanyDetailsStepCheck: (actions: IOnboardingEntityEventStateActions) => number = (
    actions
  ) =>
    actions[OnboardingActionsEnum.companyDetails]?.status !== OnboardingActionStatusEnum.completed
      ? 1
      : -1;

  const vendorTermsAndConditionsStepCheck: (
    actions: IOnboardingEntityEventStateActions
  ) => number = (actions) =>
    actions[OnboardingActionsEnum.acceptTermsAndConditions]?.status !==
    OnboardingActionStatusEnum.completed
      ? 2
      : -1;

  const handleNext: (
    actionName: keyof typeof OnboardingActionsEnum,
    data: OnboardingActionEventPut
  ) => void = (actionName, data) => {
    dispatch({ type: UPDATE_ONBOARDING_ACTION_EVENT, payload: { actionName, data } });
  };

  const renderOnboarding: () => JSX.Element = () => {
    switch (activeStep) {
      case 0:
        return <OnboardingStepPersonalDetails handleNext={handleNext} />;
      case 1:
        return <OnboardingStepCompanyDetails handleNext={handleNext} />;
      case 2:
        return <OnboardingStepTermsConditions handleNext={handleNext} />;
      default:
        return <></>;
    }
  };

  const renderOnboardingFlow: () => JSX.Element = () =>
    onboardingComplete === null ? (
      <LoaderFullPage />
    ) : activeStep !== 4 ? (
      <OnboardingLayoutContainer>
        <LayoutViewContainer>
          {activeStep > -1 && activeStep < 4 && (
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};

                return (
                  <StepContainer key={label} {...stepProps}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                  </StepContainer>
                );
              })}
            </Stepper>
          )}
        </LayoutViewContainer>
        {snackbarOpen && (
          <SnackbarContainer>
            <Snackbar />
          </SnackbarContainer>
        )}
        {activeStep === -1 && <OnboardingStepConfirmIdentity handleNext={handleNext} />}
        <LayoutViewContainer size={activeStep === 2 ? 'small' : 'small'}>
          {loading && activeStep !== -1 ? <LoaderInPage /> : renderOnboarding()}
        </LayoutViewContainer>
      </OnboardingLayoutContainer>
    ) : (
      <OnboardingTasks />
    );

  return terminationError === null || terminationError === '' || !terminationError ? (
    renderOnboardingFlow()
  ) : (
    <>
      <Lottie
        style={{ height: '20vh' }}
        loop
        animationData={notFound}
        data-testid="sp-not-found-full-page"
      />
      <h2
        style={{
          marginTop: '32px',
          width: '50%',
          lineHeight: '2rem',
          textAlign: 'center',
          color: themeColors.text.primary
        }}
      >
        {terminationError}
      </h2>
    </>
  );
};

export default Onboarding;
