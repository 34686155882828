import { LayoutProps } from 'layout/Layout';
import { FC } from 'react';
import { LayoutViewContainerStyled } from './styled';

interface LayoutViewContainerProps extends LayoutProps {
  size?: 'xlarge' | 'large' | 'medium' | 'small';
  margin?: string;
  padding?: string;
  paddingBottom?: string;
  minWidth?: string;
}

const LayoutViewContainer: FC<LayoutViewContainerProps> = ({
  children,
  size,
  margin,
  padding,
  paddingBottom,
  minWidth
}) => (
  <LayoutViewContainerStyled
    data-automation-id="layout-div-layout-container"
    data-testid="sp-layout-view-container"
    size={size}
    margin={margin}
    padding={padding}
    paddingBottom={paddingBottom}
    minWidth={minWidth}
  >
    {children}
  </LayoutViewContainerStyled>
);

export default LayoutViewContainer;
