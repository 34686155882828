import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from 'store';
import 'translations/i18n';
import App from './App';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from 'lib/oidc/oidc.config';
// import ReactGA from 'react-ga';
// import reportWebVitals from './reportWebVitals';

// Sentry.init({
//   dsn: 'https://7955327d73ab49c4a77b5242bbfaacc4@o1141543.ingest.sentry.io/4504650798989312',
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

// ReactGA.initialize('GTM-MT3DLTV');

const store = configureStore();
const persistor = persistStore(store);
const onSigninCallback = () => {
    window.history.replaceState({}, document.title, '/')
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
            <App />
          </AuthProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
