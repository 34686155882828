import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';

export const LedgerDetailCreateInvoiceBannerContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
  padding: 0 16px;
`;

export const LedgerDetailCreateInvoiceBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LedgerDetailCreateInvoiceBannerDescription = styled.p`
  ${typography.bodyRegular};
`;

export const LedgerDetailCreateInvoiceBannerButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  gap: 24px;
`;

export const LedgerDetailCreateInvoiceBannerButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const LedgerDetailCreateInvoiceBannerButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const LedgerDetailCreateInvoiceBannerButtonText = styled.p`
  ${typography.bodySmallSemiBold};
`;

export const LedgerDetailCreateInvoiceBannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const LottieAnimationContainer = styled(Lottie)`
  display: flex;
  width: 24px;
  height: 32px;
  margin-top: -10px;
`;
