export const SET_PAYMENT_ITEMS = '@@frontend/store/reducers/payment/SET_PAYMENT_ITEMS';
export const GET_PAYMENT_LEDGER_PAYMENT_ITEMS =
  '@@frontend/store/reducers/payment/GET_PAYMENT_LEDGER_PAYMENT_ITEMS';
export const GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS =
  '@@frontend/store/reducers/payment/GET_PAYMENT_LEDGER_PAYMENT_ITEMS_SUCCESS';
export const GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE =
  '@@frontend/store/reducers/payment/GET_PAYMENT_LEDGER_PAYMENT_ITEMS_FAILURE';
export const FETCH_PAYMENTS_BY_IDS = '@@frontend/store/reducers/payment/FETCH_PAYMENTS_BY_IDS';
export const FETCH_PAYMENTS_BY_IDS_SUCCESS =
  '@@frontend/store/reducers/payment/FETCH_PAYMENTS_BY_IDS_SUCCESS';
export const FETCH_PAYMENTS_BY_IDS_FAILURE =
  '@@frontend/store/reducers/payment/FETCH_PAYMENTS_BY_IDS_FAILURE';
