import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface Color {
  color: string;
}
interface LineSegment extends Color {
  lineWidthPercentage: number;
}

export const LineChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.bg.light.surface};
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 24px;
`;

export const LineChartTitle = styled.h3`
  margin-bottom: 16px;
`;

export const LineChartBarContainer = styled.div<Color>`
  display: flex;
  flex-direction: row;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  margin-bottom: 24px;
  gap: 8px;
`;

export const LineChartLineSegment = styled.div<LineSegment>`
  display: flex;
  width: ${({ lineWidthPercentage }) =>
    lineWidthPercentage > 0 ? `${lineWidthPercentage}%` : '0'};
  background-color: ${({ color }) => color};
  border-radius: 10px;
`;

export const LineChartKeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4rem;
  gap: 64px;
  justify-content: center;
  align-items: center;
`;

export const LineChartKeySegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const LineChartKeySegmentLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LineChartKeySegmentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LineChartKeyTopRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const LineChartPill = styled.div<Color>`
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const LineChartKeyText = styled.p`
  ${typography.bodyRegular};
`;

export const LineChartKeyValue = styled.h3``;
