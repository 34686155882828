import StoreBase from 'api/base/storeBase';
import { ODataLedgerPurchaseOrders } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { AxiosResponse } from 'axios';

const _PATH: string = '/ledger/supplier-pay/odata/purchase-orders';

export default class PurchaseOrderApi extends StoreBase {
  public getPurchaseOrderLedger: (
    programIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerPurchaseOrders>> = async (programIds) =>
    this.axiosInstance.get<ODataLedgerPurchaseOrders>(
      `${_PATH}?$count=true&$top=5000&$filter=${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );

  public getFilterPurchaseOrderLedger: (
    programIds: string[],
    rowsPerPage: number | null,
    page: number | null,
    filter: string,
    orderBy: string
  ) => Promise<AxiosResponse<ODataLedgerPurchaseOrders>> = async (
    programIds,
    rowsPerPage,
    page,
    filter,
    orderBy
  ) =>
    this.axiosInstance.get(
      `${_PATH}?$count=true&$top=${
        rowsPerPage || 5000
      }&$orderby=${orderBy}&$filter=${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}${filter || ''}&$skip=${page && rowsPerPage !== null ? rowsPerPage * page : 0}`
    );

  public getPurchaseOrderById: (id: string) => Promise<AxiosResponse<ODataLedgerPurchaseOrders>> =
    async (id) =>
      this.axiosInstance.get<ODataLedgerPurchaseOrders>(
        `${_PATH}?$filter=purchaseOrderId eq '${id}'`
      );
}
