/* eslint-disable react-hooks/exhaustive-deps */
import { IUser } from 'api/interfaces/users/user.interface';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import { UserActionStatusesEnum } from 'lib/enums/user/userStatuses.enum';
import { FC } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_ACTION } from 'store/actions';
import {
  CompanyUserManagementActionButtonContainer,
  CompanyUserManagementActionButtonWrapper,
  CompanyUserManagementActionContainer,
  CompanyUserManagementActionDescription,
  CompanyUserManagementActionTitle,
  CompanyUserManagementActionWrapper
} from './styled';

const _messageLockedOut: string = 'The user will no longer be able to log in and use Supplier Pay.';
const _messageEmail: string =
  'The user will receive an email to set their new password and log in.';
interface CompanyUserManagementProps {
  closeHandler: () => void;
  status: string;
}

interface IUserActionData {
  title: string;
  description: JSX.Element;
  clickHandler: () => void;
}

const CompanyUserManagementAction: FC<CompanyUserManagementProps> = ({ closeHandler, status }) => {
  const dispatch = useDispatch();
  const { currentUser }: { currentUser: IUser | null } = useSelector(
    (state: RootStateOrAny) => state.user
  );

  const userString: string = currentUser?.profile.login || 'this user';
  const userId: string = currentUser?.id || '';

  const userActionClickHandler: () => void = () => {
    if (status && userId) {
      dispatch({ type: UPDATE_USER_ACTION, payload: { userId: userId, type: status } });
    }
  };

  const confirmMessage: (message: string) => string = (message) =>
    `Are you sure that you would like to ${message} for ${userString}?`;

  const getActionData: () => IUserActionData | undefined = () => {
    if (status === UserActionStatusesEnum.RESET)
      return {
        title: 'Reset password',
        description: (
          <>
            {confirmMessage('reset the password')} <br />
            <br /> {_messageEmail}
          </>
        ),
        clickHandler: userActionClickHandler
      };
    if (status === UserActionStatusesEnum.SUSPEND)
      return {
        title: 'Suspend user',
        description: (
          <>
            {confirmMessage('suspend the account')}
            <br />
            <br />
            {_messageLockedOut}
          </>
        ),
        clickHandler: userActionClickHandler
      };
    if (status === UserActionStatusesEnum.DEACTIVATE)
      return {
        title: 'Deactivate user',
        description: (
          <>
            {confirmMessage('deactivate the account')} <br />
            <br />
            {_messageLockedOut}
          </>
        ),
        clickHandler: userActionClickHandler
      };
    if (status === UserActionStatusesEnum.ACTIVATE)
      return {
        title: 'Activate user',
        description: (
          <>
            {confirmMessage('activate the account')} <br />
            {_messageEmail}
          </>
        ),
        clickHandler: userActionClickHandler
      };
  };

  return (
    <CompanyUserManagementActionWrapper>
      <CompanyUserManagementActionContainer>
        <CompanyUserManagementActionTitle
          data-automation-id="user-management-user-action-h2-page-title"
          data-testid="sp-user-management-user-action"
        >
          {getActionData()?.title}
        </CompanyUserManagementActionTitle>
        <CompanyUserManagementActionDescription>
          {getActionData()?.description}
        </CompanyUserManagementActionDescription>
        <CompanyUserManagementActionButtonContainer>
          <CompanyUserManagementActionButtonWrapper>
            <PrimaryButton
              clickHandler={getActionData()?.clickHandler}
              testingTag="user-management-user-action-button-save"
              text={getActionData()?.title || ''}
            />
          </CompanyUserManagementActionButtonWrapper>
          <CompanyUserManagementActionButtonWrapper>
            <SecondaryButton
              text="Cancel"
              clickHandler={closeHandler}
              testingTag="user-management-user-action-button-cancel"
            />
          </CompanyUserManagementActionButtonWrapper>
        </CompanyUserManagementActionButtonContainer>
      </CompanyUserManagementActionContainer>
    </CompanyUserManagementActionWrapper>
  );
};

export default CompanyUserManagementAction;
