/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse } from '@mui/material';
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import {
  ILogisticsRuleSubtemplate,
  ILogisticsRuleSubtemplateField
} from 'api/interfaces/program/program.interface';
import HideMoreIcon from 'assets/icons/HideMoreIcon';
import ShowMoreIcon from 'assets/icons/ShowMoreIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { CreateInvoiceDialogBoldTitle } from 'components/ledger/LedgerCreateInvoice/styled';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { getInvoiceStatusTooltipText } from 'lib/tooltips/tooltips';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { Img } from 'react-image';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  GroupTableContainer,
  GroupedTable,
  LogisticsArtifactActionsContainer,
  LogisticsArtifactButtonContainer,
  LogisticsArtifactLineItemShowMoreContainer
} from '../LogisticsArtifactForm/styled';
import { SelectLogisticsArtifactProps } from '../SelectLogisticsArtifact/SelectLogisticsArtifact';
import {
  LogisticsArtifactSummaryBoldSmallText,
  LogisticsArtifactSummaryBoldText,
  LogisticsArtifactSummaryContainer,
  LogisticsArtifactSummaryContentHeader,
  LogisticsArtifactSummaryDataContainer,
  LogisticsArtifactSummaryDataItemContainer,
  LogisticsArtifactSummaryDescription,
  LogisticsArtifactSummaryHeaderIcon,
  LogisticsArtifactSummaryHeaderLeft,
  LogisticsArtifactSummaryHeaderLogo,
  LogisticsArtifactSummaryHeaderRight,
  LogisticsArtifactSummaryHeaderTitle,
  LogisticsArtifactSummaryHeaderTitleWrapper,
  LogisticsArtifactSummarySmallText,
  LogisticsArtifactSummaryTitle,
  LogisticsArtifactSummaryTitleContainer,
  LogisticsArtifactSummaryWrapper
} from './styled';

const _requiredLineItemField: string[] = [
  'lineItemNumber',
  'lineItemDescription',
  'quantity',
  'uom',
  'selected'
];

interface LogisticsArtifactSummaryProps extends SelectLogisticsArtifactProps {
  logisticsSubtemplate: ILogisticsRuleSubtemplate;
  summaryNextClickHandler: (templateRef: string) => void;
  summaryBackClickHandler: () => void;
  uploading: boolean;
  progressMessage: string;
}

const LogisticsArtifactSummary: FC<LogisticsArtifactSummaryProps> = ({
  identifier,
  status,
  isDiscrepant,
  logisticsSubtemplate,
  summaryNextClickHandler,
  summaryBackClickHandler,
  uploading,
  progressMessage
}) => {
  const [open, setOpen] = useState<number[]>([]);
  const [tableViewJSX, setTableViewJSX] = useState<JSX.Element | null>(null);
  const {
    programOwnerDetails
  }: {
    programOwnerDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.program);

  const isProgramOpenAccount: boolean = useCheckProgramsContainsType(ProgramType.OPEN_ACCOUNT);

  useEffect(() => {
    if (logisticsSubtemplate) setTableViewJSX(renderTableView());
  }, [open, logisticsSubtemplate]);

  const toggleShowMoreClickHandler: (index: number) => void = (index) => {
    let openCopy: number[];
    if (open.includes(index)) {
      openCopy = open.filter((element) => {
        return element !== index;
      });
      setOpen(openCopy);
    } else {
      openCopy = [...open];
      openCopy.push(index);
      setOpen(openCopy);
    }
  };

  const renderLineItemSummaryFields: (lineItem: any) => JSX.Element[] = (lineItem) => {
    const lineItemKeys = Object.keys(lineItem);
    const filteredKeys = lineItemKeys.filter((k) => !_requiredLineItemField.includes(k));
    let elements: JSX.Element[] = [];

    filteredKeys.forEach((k) => {
      const lineItemRules = logisticsSubtemplate.fields.find((f) => f.name === 'lineItems');
      if (!lineItemRules) return <></>;

      const matched = lineItemRules.nestedFields.find((f) => f.name === k);

      if (!matched) return <></>;

      if (matched?.type !== 'OBJECT') {
        elements.push(
          <p key={matched.referencePath} style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: '700' }}>{matched.displayName}: </span>
            {lineItem[k] || '-'}
          </p>
        );
      }

      if (matched?.type === 'OBJECT') {
        const drillDown: (fieldsTemplate: ILogisticsRuleSubtemplateField[]) => JSX.Element[] = (
          fieldsTemplate
        ) => {
          return fieldsTemplate.flatMap((template) => {
            if (template.nestedFields.length > 0) return drillDown(template.nestedFields);

            const mutReference = template.referencePath?.slice().split('.') || [];
            mutReference.shift();

            return (
              <p key={template.referencePath} style={{ fontSize: '12px' }}>
                <span style={{ fontWeight: '700' }}>
                  {mutReference.map((r) => toSentenceCase(r)).join(' - ')}:{' '}
                </span>
                {_.get(lineItem, mutReference) || '-'}
              </p>
            );
          });
        };
        elements = [...elements, ...drillDown([matched])];
      }
    });
    return elements;
  };

  const renderSummaryFields: (data: ILogisticsRuleSubtemplateField[]) => JSX.Element[] = (data) => {
    return data
      .filter((f) => (f.type !== 'OBJECT' || f.name === 'dateTimeEvents') && f.type !== 'ARRAY')
      .flatMap((field) => {
        if (field.type === 'OBJECT') return renderSummaryFields(field.nestedFields);
        if (field.value === undefined || field.value === null)
          return <React.Fragment key={field.name} />;
        return (
          <LogisticsArtifactSummaryDataItemContainer key={field.name}>
            <LogisticsArtifactSummaryBoldSmallText>
              {field.displayName}
            </LogisticsArtifactSummaryBoldSmallText>
            <LogisticsArtifactSummarySmallText>
              {field.type === 'DATE_TIME'
                ? DateTime.fromJSDate(field.value as Date).toFormat('yyyy/MM/dd HH:mm')
                : field.value}
            </LogisticsArtifactSummarySmallText>
          </LogisticsArtifactSummaryDataItemContainer>
        );
      });
  };

  const renderTableView: () => JSX.Element = () => (
    <GroupTableContainer>
      <GroupedTable data-automation-id="logistics-line-items-table-grouped">
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th style={{ width: '70%' }}>LINE ITEM DESCRIPTION</th>
            <th style={{ width: '12%' }}>QTY</th>
            <th style={{ width: '12%' }}>UNIT OF MEASURE</th>
          </tr>
        </thead>
        <tbody>
          {((logisticsSubtemplate.fields.find((r) => r.name === 'lineItems')?.value as any[]) || [])
            .filter((li) => li.selected)
            .map((d, index) => (
              <React.Fragment key={`${index}-fragment`}>
                <tr
                  key={`${index}-lineItem`}
                  style={{
                    borderBottom: `${open.includes(index) ? 'none' : '1px solid #D8D8FF'}`
                  }}
                >
                  <td
                    className="accordion-row"
                    onClick={() => toggleShowMoreClickHandler(index)}
                    data-testid="sp-create-invoice-show-more"
                  >
                    <LogisticsArtifactLineItemShowMoreContainer>
                      <p>
                        <CreateInvoiceDialogBoldTitle>
                          Line item number:
                        </CreateInvoiceDialogBoldTitle>
                        {` ${d.lineItemNumber}` || '-'}
                      </p>
                      {open.includes(index) ? (
                        <HideMoreIcon height="14" width="14" />
                      ) : (
                        <ShowMoreIcon />
                      )}
                    </LogisticsArtifactLineItemShowMoreContainer>
                  </td>
                  <td className="accordion-row">
                    <p>{d.quantity || 0}</p>
                  </td>
                  <td className="accordion-row">{d.unitOfMeasure || '-'}</td>
                </tr>
                {open.includes(index) && (
                  <tr aria-colspan={2}>
                    <td className="accordion-row-collapse text-align-right more-detail">
                      <Collapse in={open.includes(index)}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            textAlign: 'start'
                          }}
                        >
                          {renderLineItemSummaryFields(d)}
                          <p style={{ fontSize: '12px' }}>
                            <span style={{ fontWeight: '700' }}>SKU: </span>
                            {d.sku || '-'}
                          </p>
                          <p style={{ fontSize: '12px' }}>
                            <span style={{ fontWeight: '700' }}>Product/Service Description: </span>
                            {d.shortDescription || '-'}
                          </p>
                          <p style={{ fontSize: '12px' }}>
                            <span style={{ fontWeight: '700' }}>PO number: </span>
                            {d.poNumber || '-'}
                          </p>
                        </div>
                      </Collapse>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </GroupedTable>
    </GroupTableContainer>
  );

  return (
    <LogisticsArtifactSummaryWrapper>
      <LogisticsArtifactSummaryTitleContainer>
        <LogisticsArtifactSummaryTitle>Review & confirm</LogisticsArtifactSummaryTitle>
        {/* <LogisticsArtifactSummaryDescription>
          Provide the logistics information for this invoice below.
        </LogisticsArtifactSummaryDescription> */}
      </LogisticsArtifactSummaryTitleContainer>
      <LogisticsArtifactSummaryContentHeader
        data-automation-id="ledger-detail-header-div-container"
        data-testid="sp-ledger-detail-header-container"
      >
        <LogisticsArtifactSummaryHeaderLeft data-automation-id="ledger-detail-header-div-header-left">
          <LogisticsArtifactSummaryHeaderLogo data-automation-id="ledger-detail-header-div-logo">
            <Img
              height={40}
              style={{ objectFit: 'contain' }}
              src={programOwnerDetails?.companyLogo || ''}
              alt="Company Logo"
            />
          </LogisticsArtifactSummaryHeaderLogo>
          <LogisticsArtifactSummaryHeaderTitleWrapper>
            <LogisticsArtifactSummaryHeaderTitle data-automation-id="ledger-detail-header-h2-document-number">
              {identifier || ''}
            </LogisticsArtifactSummaryHeaderTitle>
            {status && (
              <Chip
                type={status}
                color={themeColors.text.primary}
                radius="3px"
                height="18px"
                fontSize={fontSizes.small}
                semibold="bold"
                testingTag="ledger-detail-header"
                tooltipText={getInvoiceStatusTooltipText(status)}
              />
            )}
            {isDiscrepant && isProgramOpenAccount && (
              <IconTooltip
                tooltipText="An issue was found on this invoice during the matching process. Your buyer will review the issue(s) and accept it for payment or contact you to amend the invoice."
                children={
                  <LogisticsArtifactSummaryHeaderIcon>
                    <WarningIcon
                      color={themeColors.white}
                      fill={themeColors.icon.error}
                      width="18px"
                      height="18px"
                    />
                  </LogisticsArtifactSummaryHeaderIcon>
                }
              />
            )}
          </LogisticsArtifactSummaryHeaderTitleWrapper>
        </LogisticsArtifactSummaryHeaderLeft>
        <LogisticsArtifactSummaryHeaderRight data-automation-id="ledger-detail-header-div-header-right">
          <LogisticsArtifactSummaryBoldText data-automation-id="ledger-detail-header-h5-value-title">
            {logisticsSubtemplate.name}
          </LogisticsArtifactSummaryBoldText>
          <LogisticsArtifactSummaryBoldSmallText data-automation-id="ledger-detail-header-h2-value">
            {DateTime.now().toFormat('yyyy/MM/dd HH:mm')}
          </LogisticsArtifactSummaryBoldSmallText>
        </LogisticsArtifactSummaryHeaderRight>
      </LogisticsArtifactSummaryContentHeader>
      <Divider fullWidth />
      <LogisticsArtifactSummaryDataContainer>
        {renderSummaryFields(logisticsSubtemplate.fields)}
      </LogisticsArtifactSummaryDataContainer>
      <LogisticsArtifactSummaryContainer>{tableViewJSX}</LogisticsArtifactSummaryContainer>
      <LogisticsArtifactActionsContainer uploading={uploading}>
        {uploading ? (
          <>
            <LoaderInPage />
            {progressMessage && <p style={{ textAlign: 'center' }}>{progressMessage}</p>}
          </>
        ) : (
          <>
            <LogisticsArtifactButtonContainer>
              <PrimaryButton
                clickHandler={(e) => {
                  e.preventDefault();
                  summaryNextClickHandler(logisticsSubtemplate.templateReference);
                }}
                text="Confirm"
              />
            </LogisticsArtifactButtonContainer>
            <LogisticsArtifactButtonContainer>
              <SecondaryButton
                clickHandler={(e) => {
                  e.preventDefault();
                  summaryBackClickHandler();
                }}
                text="Back"
              />
            </LogisticsArtifactButtonContainer>
          </>
        )}
      </LogisticsArtifactActionsContainer>
    </LogisticsArtifactSummaryWrapper>
  );
};

export default LogisticsArtifactSummary;
