import Checkbox from '@mui/material/Checkbox';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { Accordion } from '@mui/material';

interface LottieSize {
  height?: string;
}

interface Disabled {
  disabled?: boolean;
}

export const CompanyBankAccountDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  padding: ${isMobile() ? '0 16px' : 0};
`;

export const CompanyBankAccountDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${isMobile() ? '100%' : '30%'};
  min-width: ${isMobile() ? 'auto' : '350px'};
`;

export const CompanyBankAccountDetailsTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.light.body};
  margin-bottom: ${isMobile() ? '12px' : '32px'};
`;

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CompanyBankAccountDetailsActionsContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  gap: 16px;
  align-items: center;
  margin-bottom: 33px;
`;

export const CompanyBankAccountDetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'auto'};
`;

export const CompanyBankAccountDetailsButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CompanyBankAccountDetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const CompanyBankDetailsLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const CompanyBankDetailsLottieAnimation = styled(Lottie)<LottieSize>`
  display: flex;
  height: ${({ height }) => height || '50px'};
`;

export const CompanyBankDetailsVerifyingText = styled.p`
  color: ${themeColors.text.primary};
  ${typography.bodyRegular};
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0 !important;
  align-items: flex-start !important;
  color: ${themeColors.text.light.muted} !important;
  background-color: transparent !important;
  &.Mui-checked {
    color: ${themeColors.text.primary} !important;
  }
  &.Mui-disabled {
    color: ${themeColors.text.light.muted} !important;
  }
`;

export const CompanyBankDetailsDefaultRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const CompanyBankDetailsCheckboxMessage = styled.p<Disabled>`
  ${typography.bodyRegular};
  color: ${({ disabled }) => (disabled ? themeColors.text.light.muted : '')};
`;

export const CompanyBankDetailsActionsContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  gap: 16px;
  align-items: center;
  margin-bottom: 33px;
`;

export const CompanyBankDetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'auto'};
`;

export const CompanyBankDetailsButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CompanyBankDetailsSubHeading = styled.h4`
  color: ${themeColors.text.light.body};
`;

export const CompanyBankDetailsCaption = styled.p`
  ${typography.bodyRegular};
`;

export const CompanyBankDetailsMoreOptionsTitle = styled.h4`
  color: ${themeColors.text.light.body};
`;

export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const ErrorMessage = styled.span`
  color: ${themeColors.text.error};
  ${typography.captionRegular};
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    background-color: ${themeColors.bg.light.surface};
    border-radius: 16px!important;
    padding: 16px 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 24px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
  }
  .MuiButtonBase-root {
    width: 100%;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
    min-height: 40px;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionDetails-root {
    padding: 0;
    ${typography.bodyRegular};
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
  .MuiAccordionDetails-root{
    padding: 0 24px;
  }
`;