import { Checkbox, MenuItem, SelectChangeEvent } from '@mui/material';
import CheckIcon from 'assets/icons/CheckIcon';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import {
  OutgoingPaymentGroupedItem,
  HeaderCheckbox
} from 'components/outgoing-payment/OutgoingPaymentRequest/OutgoingPaymentRequest';
import OutgoingPaymentRequestAccordion from 'components/outgoing-payment/OutgoingPaymentRequest/OutgoingPaymentRequestAccordion';
import { GroupedTable } from 'components/GroupedDataGrid/styled';
import Snackbar from 'components/snackbar';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import _ from 'lodash';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TRIGGER_SNACKBAR } from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import OutgoingPaymentDataGridItem from 'utils/classes/data-grid/outgoingPaymentDataGridItem';
import {
  DatePickerStyled,
  OutgoingPaymentActionsContainer,
  OutgoingPaymentButtonContainer,
  OutgoingPaymentButtonText,
  OutgoingPaymentSelectContainer,
  OutgoingPaymentsKey,
  OutgoingPaymentSnackbarText,
  OutgoingPaymentSnackbarTitle,
  OutgoingPaymentsSelectionContainer,
  OutgoingPaymentsSelectionSegment,
  OutgoingPaymentsText,
  OutgoingPaymentsTitle,
  OutgoingPaymentTotalPaymentContainer,
  OutgoingPaymentTotalsContainer,
  SelectStyled
} from './styled';

import 'react-datepicker/dist/react-datepicker.css';
import './styled.css';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';

const headers: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Invoice Reference', 'invoiceNumber'),
  new DataGridHeaderItem('Supplier', 'supplier'),
  new DataGridHeaderItem('Due Date', 'paymentDueDate'),
  new DataGridHeaderItem('Invoice Amount', 'invoiceAmount'),
  new DataGridHeaderItem('', '')
];

const data: OutgoingPaymentDataGridItem[] = [
  new OutgoingPaymentDataGridItem(
    'Fulton-Bill-1',
    'Fulton Airport Parking',
    '2022-06-10',
    1000.0,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Fulton-Bill-2',
    'Fulton Airport Parking',
    '2022-07-04',
    4425.33,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Fulton-Bill-3',
    'Fulton Airport Parking',
    '2022-06-30',
    149000.0,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Capital-Bill-1',
    'Capital Cab Co',
    '2022-04-15',
    7560.0,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Capital-Bill-2',
    'Capital Cab Co',
    '2022-06-30',
    3526.04,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Capital-Bill-3',
    'Capital Cab Co',
    '2022-06-03',
    2149.36,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Capital-Bill-4',
    'Capital Cab Co',
    '2022-07-06',
    14900.44,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  ),
  new OutgoingPaymentDataGridItem(
    'Capital-Bill-5',
    'Capital Cab Co',
    '2022-07-24',
    14330.0,
    '00CD04D6D029892ABAD09AFA77A1C46A',
    'USD',
    true
  )
];

const tomorrow: Date = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const OutgoingPayments: FC = () => {
  const dispatch = useDispatch();
  const [originalData, setOriginalData] = useState<OutgoingPaymentGroupedItem[]>([]);
  const [payFrom, setPayFrom] = useState<string>('Marco Polo Wallet');
  const [payTo, setPayTo] = useState<string>('Capital Cab Co');
  const [scheduled, setScheduled] = useState<Date>(tomorrow);
  const [open, setOpen] = useState<boolean>(false);
  const [modifiedData, setModifiedData] = useState<OutgoingPaymentGroupedItem[]>([]);
  const [headerCheckbox, setHeaderCheckbox] = useState<HeaderCheckbox>({
    indeterminate: false,
    checked: true
  });
  const snackbarOpen: boolean = useSelector((state: RootStateOrAny) => state.app.snackbarOpen);

  useEffect(() => {
    mappedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableBodyCheckboxClickHandler: (inv: OutgoingPaymentDataGridItem) => void = (
    inv: OutgoingPaymentDataGridItem
  ) => {
    const mutArr: OutgoingPaymentGroupedItem[] = [...modifiedData];
    const invs: OutgoingPaymentDataGridItem[] = modifiedData.flatMap(({ invoices }) => invoices);
    const index: number = invs.map((g) => g.invoiceNumber).indexOf(inv.invoiceNumber);
    if (index < 0) return;
    invs[index].selected = !invs[index].selected;
    setModifiedData(mutArr);
    headerCheckboxState();
    rowCheckboxState();
  };

  const headerCheckboxState: () => void = () => {
    const invoices: OutgoingPaymentDataGridItem[] = modifiedData.flatMap(
      ({ invoices }) => invoices
    );
    const selectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(({ selected }) => selected);
    const unSelectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(
      ({ selected }) => !selected
    );
    const indeterminate: boolean = selectedArr.length !== invoices.length;
    const checked: boolean =
      selectedArr.length === invoices.length || unSelectedArr.length === invoices.length;

    return setHeaderCheckbox({ indeterminate, checked });
  };

  const rowCheckboxState: () => void = () => {
    const mapped: OutgoingPaymentGroupedItem[] = modifiedData.map((g) => {
      const invoices: OutgoingPaymentDataGridItem[] = g.invoices;
      const selectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(
        ({ selected }) => selected
      );
      const unSelectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(
        ({ selected }) => !selected
      );
      g.indeterminate =
        selectedArr.length !== invoices.length && unSelectedArr.length !== invoices.length;
      g.selected = selectedArr.length === invoices.length;

      return g;
    });
    setModifiedData(mapped);
  };

  const headerCheckboxClickHandler: () => void = () =>
    setHeaderCheckbox({ indeterminate: false, checked: !headerCheckbox.checked });

  const tableAccordionCheckboxClickHandler: (
    e: ChangeEvent<HTMLInputElement>,
    row: OutgoingPaymentDataGridItem
  ) => void = (e: ChangeEvent<HTMLInputElement>, row: OutgoingPaymentDataGridItem) => {
    e.stopPropagation();
    const mutArr: OutgoingPaymentGroupedItem[] = Array.from(modifiedData);
    const index: number = modifiedData.map((g) => g.buyer).indexOf(row.buyer);
    if (index < 0) return;
    mutArr[index].selected = !mutArr[index].selected;
    mutArr[index].indeterminate = false;
    mutArr[index].invoices = mutArr[index].invoices.map((inv: OutgoingPaymentDataGridItem) => {
      inv.selected = mutArr[index].selected;
      return inv;
    });
    setModifiedData(mutArr);
  };

  const rowChangedClickHandler: (expanded: boolean, item: any) => void = (
    expanded: boolean,
    item: any
  ) => {
    const mutArr: OutgoingPaymentGroupedItem[] = Array.from(modifiedData);
    const index: number = mutArr.map((d) => d.buyer).indexOf(item.buyer);
    if (index < 0) return;
    mutArr[index].expanded = expanded;
    mutArr[index].indeterminate = false;
    setModifiedData(mutArr);
  };

  const mappedData: () => void = () => {
    if (originalData.length > 0) return;
    const grouped: _.Dictionary<OutgoingPaymentDataGridItem[]> =
      _.groupBy<OutgoingPaymentDataGridItem>(data, 'buyer');
    const x: OutgoingPaymentGroupedItem[] = Object.entries(grouped).map(([key, value]) => ({
      buyer: key,
      invoices: value,
      expanded: false,
      ...mappedDataRowSelectionState(value)
    }));
    setOriginalData(x);
    setModifiedData(JSON.parse(JSON.stringify(x)));
  };

  const mappedDataRowSelectionState: (invoices: OutgoingPaymentDataGridItem[]) => {
    indeterminate: boolean;
    selected: boolean;
  } = (invoices) => {
    const selectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(({ selected }) => selected);
    const unSelectedArr: OutgoingPaymentDataGridItem[] = invoices.filter(
      ({ selected }) => !selected
    );
    const indeterminate =
      selectedArr.length !== invoices.length && unSelectedArr.length !== invoices.length;
    const selected = selectedArr.length === invoices.length;

    return { indeterminate, selected };
  };

  const renderTable: () => JSX.Element = () => (
    <GroupedTable>
      <thead style={{ textAlign: 'left' }}>
        <tr>
          <th>
            <Checkbox
              style={{
                color: themeColors.text.primary,
                backgroundColor: 'transparent',
                zIndex: 10
              }}
              {...headerCheckbox}
              onChange={() => headerCheckboxClickHandler()}
            />
          </th>
          {headers.map((h) => {
            return (
              <th key={h.label} style={{ width: 'calc(100% / 5)' }}>
                {h.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {modifiedData.map((d, i) => {
          return (
            <tr key={i}>
              <td colSpan={7} className="accordion-row">
                <OutgoingPaymentRequestAccordion
                  data={d}
                  rowChangedClickHandler={rowChangedClickHandler}
                  tableAccordionCheckboxClickHandler={tableAccordionCheckboxClickHandler}
                  tableBodyCheckboxClickHandler={tableBodyCheckboxClickHandler}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </GroupedTable>
  );

  const handlePayFromChange: (event: SelectChangeEvent) => void = (event) =>
    setPayFrom(event.target.value as string);

  const handlePayToChange: (event: SelectChangeEvent) => void = (event) =>
    setPayTo(event.target.value as string);

  const totalPayment: () => string = () => {
    const total: number = modifiedData
      .flatMap((m) => m.invoices)
      .filter((inv) => inv.selected)
      .reduce((acc, val) => acc + val.invoiceAmount, 0);
    return `${CurrencySymbolsLookUp[modifiedData[0].invoices[0].currencySymbol]}${formatNumber(
      total,
      2
    )}`;
  };

  const snackbarData: SnackbarData = {
    title: <OutgoingPaymentSnackbarTitle>Request for payment sent</OutgoingPaymentSnackbarTitle>,
    message: (
      <OutgoingPaymentSnackbarText>
        Your request for payment has been sent and will be processed shortly
      </OutgoingPaymentSnackbarText>
    ),
    leftIcon: <CheckIcon />
  };

  const submitClickHandler: () => void = () => {
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: snackbarData } });
    setTimeout(() => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    }, 10000);
  };

  const handleDateChange: (newValue: Date) => void = (newValue) => {
    setScheduled(newValue);
  };

  const formattedDate: () => string = () => {
    return formatDateTime(scheduled.toISOString(), 'dd/MM/yyyy');
  };

  return (
    <LayoutViewContainer>
      <OutgoingPaymentsTitle>Select additional invoices to pay</OutgoingPaymentsTitle>
      {snackbarOpen && <Snackbar />}
      <BaseCard>
        {renderTable()}
        <OutgoingPaymentsSelectionContainer>
          <OutgoingPaymentSelectContainer>
            <OutgoingPaymentsSelectionSegment>
              <OutgoingPaymentsKey>Pay from:</OutgoingPaymentsKey>
              <SelectStyled
                value={payFrom}
                onChange={(e: any) => handlePayFromChange(e)}
                style={{ width: '20rem' }}
              >
                <MenuItem
                  style={{ fontSize: '14px', fontFamily: `'Source Sans Pro', sans-serif` }}
                  value="Marco Polo Wallet"
                >
                  Marco Polo Wallet
                </MenuItem>
                <MenuItem
                  style={{ fontSize: '14px', fontFamily: `'Source Sans Pro', sans-serif` }}
                  value="Bank of America"
                >
                  Bank of America
                </MenuItem>
              </SelectStyled>
            </OutgoingPaymentsSelectionSegment>
            <OutgoingPaymentsSelectionSegment>
              <OutgoingPaymentsKey>Pay To:</OutgoingPaymentsKey>
              <SelectStyled
                value={payTo}
                onChange={(e: any) => handlePayToChange(e)}
                style={{ width: '20rem' }}
              >
                <MenuItem
                  style={{ fontSize: '14px', fontFamily: `'Source Sans Pro', sans-serif` }}
                  value={'Fulton Airport Parking'}
                >
                  Fulton Airport Parking
                </MenuItem>
                <MenuItem
                  style={{ fontSize: '14px', fontFamily: `'Source Sans Pro', sans-serif` }}
                  value={'Capital Cab Co'}
                >
                  Capital Cab Co
                </MenuItem>
              </SelectStyled>
            </OutgoingPaymentsSelectionSegment>
          </OutgoingPaymentSelectContainer>
          <OutgoingPaymentTotalsContainer>
            <OutgoingPaymentTotalPaymentContainer>
              <OutgoingPaymentsText>Total Payment:</OutgoingPaymentsText>
              <OutgoingPaymentsKey>{modifiedData.length ? totalPayment() : ''}</OutgoingPaymentsKey>
            </OutgoingPaymentTotalPaymentContainer>
            <OutgoingPaymentTotalPaymentContainer>
              <OutgoingPaymentsText>Scheduled Date:</OutgoingPaymentsText>
              <OutgoingPaymentsKey>{formattedDate()}</OutgoingPaymentsKey>
            </OutgoingPaymentTotalPaymentContainer>
          </OutgoingPaymentTotalsContainer>
        </OutgoingPaymentsSelectionContainer>
        <OutgoingPaymentActionsContainer>
          <OutgoingPaymentButtonContainer>
            <DatePickerStyled
              open={open}
              selected={scheduled}
              onChange={(date: Date) => handleDateChange(date)}
              onClickOutside={() => setOpen(false)}
            />
            <SecondaryButton
              clickHandler={() => setOpen(true)}
              text="Schedule"
              testingTag="outgoing-payment-button-schedule"
            />
          </OutgoingPaymentButtonContainer>
          <OutgoingPaymentButtonContainer>
            <PrimaryButton
              clickHandler={submitClickHandler}
              testingTag="outgoing-payment-button-pay-now"
              text="Pay Now"
            />
          </OutgoingPaymentButtonContainer>
        </OutgoingPaymentActionsContainer>
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default OutgoingPayments;
