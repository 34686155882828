import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const AlertIcon: FC<IconSvgProps> = ({ height = '24px', width = '24px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.25"
      d="M9.31659 4.6595C10.422 2.44772 13.5783 2.44773 14.6836 4.65953L20.6805 16.6588C21.6774 18.6535 20.2269 20.9999 17.997 20.9999H6.00311C3.77319 20.9999 2.32269 18.6534 3.31959 16.6588L9.31659 4.6595Z"
      fill="#171717"
    />
    <path
      d="M12 8C11.4477 8 11 8.44772 11 9V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V9C13 8.44772 12.5523 8 12 8Z"
      fill="#171717"
    />
    <path
      d="M12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15Z"
      fill="#171717"
    />
  </svg>
);

export default AlertIcon;
