import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const FundingRequestPaymentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
  width: 50%;
`;

export const FundingRequestPaymentDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FundingRequestPaymentDetailsTitle = styled.h4``;

export const FundingRequestPaymentDetailsKey = styled.p`
  ${typography.bodySemiBold}
`;

export const FundingRequestPaymentDetailsValue = styled.p`
  ${typography.bodyRegular}
`;
