/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import DataGrid from 'components/DataGrid';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';
import { FC, useEffect, useState } from 'react';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import {
  LedgerDetailAdditionalInformationContentContainer,
  LedgerDetailAdditionalInformationTitle
} from '../LedgerDetailAdditionalInformation/styled';
import LedgerDetailAddresses from '../LedgerDetailAddresses';
import {
  AccordionStyled,
  LedgerDetailLogisticContainer,
  LedgerDetailLogisticRow,
  LedgerDetailLogisticRowNested,
  LedgerDetailLogisticsKeyValueRow,
  LedgerDetailLogisticsTextRegular,
  LedgerDetailLogisticsTextSemiBold
} from './styled';

interface LedgerDetailLineItemsProps {
  logistics: LedgerLogistics;
}

const LedgerDetailLogistic: FC<LedgerDetailLineItemsProps> = ({ logistics }) => {
  const [nonNestedJSX, setNonNestedJSX] = useState<JSX.Element[]>([]);
  const [nestedJSX, setNestedJSX] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (logistics) {
      setNonNestedJSX(mapNonNestedView(logistics));
      setNestedJSX(mapNestedView(logistics));
    }
  }, []);

  const mapNonNestedView: (data: LedgerLogistics) => JSX.Element[] = (data) =>
    Object.keys(data)
      .filter(
        (key) =>
          typeof (data as any)[key] !== 'object' &&
          !Array.isArray((data as any)[key]) &&
          (data as any)[key] !== null
      )
      .map((k) => (
        <LedgerDetailLogisticsKeyValueRow key={k}>
          <LedgerDetailLogisticsTextSemiBold>{toSentenceCase(k)}</LedgerDetailLogisticsTextSemiBold>
          <LedgerDetailLogisticsTextRegular>
            {typeof (data as any)[k] === 'boolean'
              ? Boolean((data as any)[k])
                ? 'Yes'
                : 'No'
              : (data as any)[k]}
          </LedgerDetailLogisticsTextRegular>
        </LedgerDetailLogisticsKeyValueRow>
      ));

  const mapNestedView: (data: any) => JSX.Element[] = (data) =>
    Object.keys(data)
      .filter(
        (key) =>
          typeof data[key] === 'object' &&
          data[key] !== null &&
          hasDisplayableValues(data, key) &&
          key !== 'addresses'
      )
      .map((k) => (
        <AccordionStyled disableGutters key={k}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-automation-id="ledger-detail-lineItems-accordion-summary"
          >
            <LedgerDetailAdditionalInformationTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
              {toSentenceCase(k)}
            </LedgerDetailAdditionalInformationTitle>
          </AccordionSummary>
          <AccordionDetails>
            <LedgerDetailLogisticRowNested>
              {Object.keys(data[k])
                .filter((dk) => data[k][dk] !== null)
                .map((dk) =>
                  typeof data[k][dk] !== 'object' &&
                  !Array.isArray(data[k][dk]) &&
                  data[k][dk] !== null ? (
                    <LedgerDetailLogisticsKeyValueRow key={dk}>
                      <LedgerDetailLogisticsTextSemiBold>
                        {toSentenceCase(dk)}
                      </LedgerDetailLogisticsTextSemiBold>
                      <LedgerDetailLogisticsTextRegular>
                        {typeof data[k][dk] === 'boolean'
                          ? Boolean(data[k][dk])
                            ? 'Yes'
                            : 'No'
                          : data[k][dk]}
                      </LedgerDetailLogisticsTextRegular>
                    </LedgerDetailLogisticsKeyValueRow>
                  ) : (
                    renderTable(data[k][dk], dk)
                  )
                )}
            </LedgerDetailLogisticRowNested>
          </AccordionDetails>
        </AccordionStyled>
      ));

  const hasDisplayableValues: (data: any, key: string) => boolean = (data, key) =>
    Object.values(data[key]).filter((v) => Boolean(v)).length > 0;

  const renderTable: (data: any, parentKey: string) => JSX.Element = (data, parentKey) => {
    const headers: DataGridHeaderItem[] = [
      new DataGridHeaderItem(
        isNaN(parseInt(parentKey)) ? toSentenceCase(parentKey) : 'Field',
        'field',
        new DataGridHeaderOptions(false, true, true)
      ),
      new DataGridHeaderItem('Values', 'values', {
        ...new DataGridHeaderOptions(false, false, true),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => <p>{value}</p>
      })
    ];
    let mappedData: any[] = [];

    if (data) {
      mappedData = Object.keys(data)
        .filter((k) => data[k] !== null)
        .map((key) => ({
          field: toSentenceCase(key),
          values: JSON.stringify(data[key])
        }));
    }

    // const mappedData: DataGridRowItem[] = []

    return (
      <DataGrid
        headers={headers}
        data={mappedData}
        ariaLabel={''}
        ariaCaption={''}
        dataAutomationTag={''}
        margin="0 -48px"
      />
    );
  };

  return (
    <LedgerDetailLogisticContainer data-automation-id="ledger-detail-logistic-div-container">
      <LedgerDetailLogisticRow>{nonNestedJSX}</LedgerDetailLogisticRow>
      {nestedJSX}
      {logistics.addresses && (
        <AccordionStyled disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-automation-id="ledger-detail-lineItems-accordion-summary"
          >
            <LedgerDetailAdditionalInformationTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
              Addresses
            </LedgerDetailAdditionalInformationTitle>
          </AccordionSummary>
          <AccordionDetails>
            <LedgerDetailAdditionalInformationContentContainer>
              {logistics.addresses ? (
                <LedgerDetailAddresses addresses={logistics.addresses} />
              ) : (
                <p>No addresses available.</p>
              )}
            </LedgerDetailAdditionalInformationContentContainer>
          </AccordionDetails>
        </AccordionStyled>
      )}
    </LedgerDetailLogisticContainer>
  );
};

export default LedgerDetailLogistic;
