export enum QueryComponents {
  BOOLEAN = 'BOOLEAN',
  DEFINITIVE_SIMPLE_DROPDOWN = 'DEFINITIVE_SIMPLE_DROPDOWN',
  SIMPLE_DROPDOWN = 'SIMPLE_DROPDOWN',
  FUZZY_DROPDOWN = 'FUZZY_DROPDOWN',
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY'
}
