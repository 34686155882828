export const UPDATE_CURRENT_PATH = '@@frontend/store/reducers/app/UPDATE_CURRENT_PATH';
export const UPDATE_PAGE_TITLE = '@@frontend/store/reducers/app/UPDATE_PAGE_TITLE';
export const UPDATE_ACTIVE_NAV_BUTTON = '@@frontend/store/reducers/app/UPDATE_ACTIVE_NAV_BUTTON';
export const UPDATE_USERNAME = '@@frontend/store/reducers/app/UPDATE_USERNAME';
export const UPDATE_CURRENCY_CODE = '@@frontend/store/reducers/app/UPDATE_CURRENCY_CODE';
export const INITIALISE_APP = '@@frontend/store/reducers/app/INITIALISE_APP';
export const REINITIALISE_APP = '@@frontend/store/reducers/app/REINITIALISE_APP';
export const INITIALISE_APP_SUCCESS = '@@frontend/store/reducers/app/INITIALISE_APP_SUCCESS';
export const INITIALISE_APP_FAILURE = '@@frontend/store/reducers/app/INITIALISE_APP_FAILURE';
export const NAV_BACK_VISIBLE = '@@frontend/store/reducers/app/NAV_BACK_VISIBLE';
export const TRIGGER_SNACKBAR = '@@frontend/store/reducers/app/TRIGGER_SNACKBAR';
export const FETCH_INVOICES_BY_PROGRAM =
  '@@frontend/store/reducers/fundingRequest/FETCH_INVOICES_BY_PROGRAM';
export const FETCH_INVOICES_BY_PROGRAM_SUCCESS =
  '@@frontend/store/reducers/fundingRequest/FETCH_INVOICES_BY_PROGRAM_SUCCESS';
export const FETCH_INVOICES_BY_PROGRAM_FAILURE =
  '@@frontend/store/reducers/fundingRequest/FETCH_INVOICES_BY_PROGRAM_FAILURE';
export const GET_FUNDINGS = '@@frontend/store/reducers/fundingRequest/GET_FUNDINGS';
export const GET_FUNDINGS_SUCCESS = '@@frontend/store/reducers/fundingRequest/GET_FUNDINGS_SUCCESS';
export const GET_FUNDINGS_FAILURE = '@@frontend/store/reducers/fundingRequest/GET_FUNDINGS_FAILURE';
export const GET_G2F_CONNECTIVITY = '@@frontend/store/reducers/fundingRequest/GET_G2F_CONNECTIVITY';
export const GET_G2F_CONNECTIVITY_SUCCESS =
  '@@frontend/store/reducers/fundingRequest/GET_G2F_CONNECTIVITY_SUCCESS';
export const GET_G2F_CONNECTIVITY_FAILURE =
  '@@frontend/store/reducers/fundingRequest/GET_G2F_CONNECTIVITY_FAILURE';
export const UPDATE_SELECTED_PROGRAM = '@@frontend/store/reducers/app/UPDATE_SELECTED_PROGRAM';
export const UPDATE_ACCESS_TOKEN = '@@frontend/store/reducers/app/UPDATE_ACCESS_TOKEN';
export const UPDATE_ACCOUNTING_CONNECT = '@@frontend/store/reducers/app/UPDATE_ACCOUNTING_CONNECT';
export const SET_REDIRECT_PATH = '@@frontend/store/reducers/app/SET_REDIRECT_PATH';
export const SET_TENANT = '@@frontend/store/reducers/app/SET_TENANT';
export const UPDATE_PLATFORM_AVAILABILITY =
  '@@frontend/store/reducers/app/UPDATE_PLATFORM_AVAILABILITY';
export const UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY =
  '@@frontend/store/reducers/app/UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY';
export const UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS =
  '@@frontend/store/reducers/app/UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS';
export const UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE =
  '@@frontend/store/reducers/app/UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE';
