import EntityApi from 'api/entity/entity.api';
import { EntityCurrency, EntityOwner, ICountries } from 'api/interfaces/entity/entity.interface';
import {
  IOnboardingArtifact,
  IOnboardingEntity,
  IOnboardingEntityEvent,
  IOnboardingProgramData
} from 'api/interfaces/onboarding/onboarding.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import { AxiosResponse } from 'axios';
import { OnboardingActionsEnum } from 'lib/enums/onboarding/onboarding.enum';
import { store } from 'store';
import { IAppState } from 'store/epics/app';
import { AppReducerStateProps } from 'store/reducers/app';

const onboardingDataState: IAppState = {
  g2fAccessToken: null,
  g2fCustUID: null,
  programs: [],
  invoices: [],
  invoiceLedger: [],
  purchaseOrderLedger: [],
  entities: [],
  programID: null,
  advancedInvoicesTotal: 0,
  acceptedEligibleInvoices: [],
  acceptedEligibleInvoicesTotal: 0,
  fundings: [],
  earlyPaymentsReceivedValue: 0,
  earlyPaymentsTenorValue: 0,
  countries: null,
  currencies: [],
  programCurrencies: [],
  program: null,
  paymentsTypeTrade: [],
  payments: [],
  payableItemsTypeTrade: [],
  payableItemsTypeMaturing: [],
  programType: null,
  onboardingComplete: false,
  onboardingInitData: null,
  legalEntityInit: null,
  acceptTermsAndConditionsArtifactPdfBlob: null,
  acceptTermsAndConditionsArtifactPdfName: '',
  entityOnboardingActionsStateByProgram: null,
  onboardingBankAccountArtifacts: null,
  platformAvailable: true,
  programOwnerDetails: null,
  programIds: [],
  programsWithDetails: [],
  allProgramsWithDetails: [],
  groupedPrograms: [],
  selectedProgramByCurrency: null,
  discrepantInvoicesTotal: 0,
  discrepantInvoicesTotalCount: 0,
  pendingInvoicesTotal: 0,
  pendingInvoicesTotalCount: 0,
  internalLoading: false
};

const artifactCheck: (data: IOnboardingEntityEvent, key: string) => boolean = (data, key) =>
  data.spec.actions[key]?.config?.artifactIDs?.length > 0;

const requiredArtifactKeys: OnboardingActionsEnum[] = [
  OnboardingActionsEnum.acceptTermsAndConditions
];

export const onboardingInitialisation: (
  legalEntity: IOnboardingEntity,
  appState: AppReducerStateProps,
  accessToken: string
) => Promise<IAppState> = async (legalEntity, appState, accessToken) => {
  if (!appState.tenant) throw Error('NO TENANT SET');
  const onboardingApi = new OnboardingApi(accessToken);
  const entityApi = new EntityApi(store);

  const onboardingActionsResponse: AxiosResponse<IOnboardingEntityEvent> =
    await onboardingApi.getEntityOnboardingActionsState(appState.tenant);

  let getEntityOnboardingActionsStateByProgramResponse: IOnboardingEntityEvent[] =
    await Promise.all(
      Object.keys(legalEntity.state.programStatus).map(
        async (id) => await onboardingApi.getEntityOnboardingActionsStateByProgram(id)
      )
    );

  if (!getEntityOnboardingActionsStateByProgramResponse)
    console.warn('APP INIT - Can not get onboarding actions by program.');

  //Artifact Guards
  if (
    requiredArtifactKeys.filter((key) => !artifactCheck(onboardingActionsResponse.data, key))
      .length > 0
  )
    throw Error('Required artifacts are not available!');

  // INDEXED 0 - but need to understand how we can do this more dynamically
  const onboardingArtifactsResponse: AxiosResponse<IOnboardingArtifact> =
    await onboardingApi.getOnboardingArtifacts(
      onboardingActionsResponse.data.spec.actions[OnboardingActionsEnum.acceptTermsAndConditions]
        .config.artifactIDs[0]
    );

  if (!onboardingArtifactsResponse.data.state.dataRefs.context)
    throw Error('DataRefs do not exist.');

  const matchingPdf: number = onboardingArtifactsResponse.data.state.dataRefs.context.findIndex(
    (c) => c.name?.includes('pdf')
  );

  if (
    matchingPdf === -1 ||
    !onboardingArtifactsResponse.data.state.dataRefs.context[matchingPdf].id ||
    !onboardingArtifactsResponse.data.state.dataRefs.context[matchingPdf].name
  )
    throw Error('DataRefs pdf does not exist.');

  const getOnboardingArtifactDataByIdResponse = await onboardingApi.getOnboardingArtifactDataById(
    onboardingActionsResponse.data.spec.actions[OnboardingActionsEnum.acceptTermsAndConditions]
      .config.artifactIDs[0],
    onboardingArtifactsResponse.data.state.dataRefs.context[matchingPdf].id as string,
    onboardingArtifactsResponse.data.state.dataRefs.context[matchingPdf].name as string
  );

  const getOnboardingArtifactByIdResponse: IOnboardingProgramData[] = await Promise.all(
    getEntityOnboardingActionsStateByProgramResponse.map(async (action) => ({
      programOnboardingData: action,
      programOnboardingArtifactData: await onboardingApi.getOnboardingArtifactById(
        action.spec.actions.bankAccount.config.artifactIDs[0]
      )
    }))
  );

  // ------INTERNAL APP CALLS FOR CURRENCY AND COUNTRIES - ALSO INFORMS TO PLATFORM AVAILABILITY

  try {
    const fetchCountriesResponse: ICountries = await entityApi.getCountries();
    const fetchCurrenciesResponse: AxiosResponse<EntityCurrency[]> =
      await entityApi.getCurrencies();
    const fetchOwnerResponse: AxiosResponse<EntityOwner> = await entityApi.getOwner(accessToken);

    onboardingDataState.onboardingInitData = onboardingActionsResponse
      ? onboardingActionsResponse.data
      : null;
    onboardingDataState.legalEntityInit = legalEntity;
    onboardingDataState.onboardingComplete = false;
    onboardingDataState.acceptTermsAndConditionsArtifactPdfBlob =
      getOnboardingArtifactDataByIdResponse.data;
    onboardingDataState.acceptTermsAndConditionsArtifactPdfName = onboardingArtifactsResponse.data
      .state.dataRefs.context[matchingPdf].name as string;
    onboardingDataState.entityOnboardingActionsStateByProgram = getOnboardingArtifactByIdResponse;
    onboardingDataState.onboardingBankAccountArtifacts = getOnboardingArtifactByIdResponse;
    onboardingDataState.countries = fetchCountriesResponse;
    onboardingDataState.currencies = fetchCurrenciesResponse.data;
    onboardingDataState.platformAvailable = true;
    onboardingDataState.programOwnerDetails = fetchOwnerResponse.data;
    return onboardingDataState;
  } catch (error) {
    onboardingDataState.onboardingInitData = onboardingActionsResponse
      ? onboardingActionsResponse.data
      : null;
    onboardingDataState.legalEntityInit = legalEntity;
    onboardingDataState.onboardingComplete = false;
    onboardingDataState.acceptTermsAndConditionsArtifactPdfBlob =
      getOnboardingArtifactDataByIdResponse.data;
    onboardingDataState.acceptTermsAndConditionsArtifactPdfName = onboardingArtifactsResponse.data
      .state.dataRefs.context[matchingPdf].name as string;
    onboardingDataState.entityOnboardingActionsStateByProgram = getOnboardingArtifactByIdResponse;
    onboardingDataState.onboardingBankAccountArtifacts = getOnboardingArtifactByIdResponse;
    onboardingDataState.countries = null;
    onboardingDataState.currencies = [];
    onboardingDataState.platformAvailable = false;

    return onboardingDataState;
  }

  // --------END OF INTERNAL APP CALLS
};
