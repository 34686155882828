import TextInput from 'components/forms/inputs/TextInput';
import React, { FC } from 'react';
import { BankAccountProps } from 'utils/interfaces/bank-account/bankAccount.interface';
import { BankAccountContainer } from '../styled';

interface USBankDetailsProps {
  accountNumber: BankAccountProps;
  routingNumber: BankAccountProps;
}

const USBankDetails: FC<USBankDetailsProps> = ({ accountNumber, routingNumber }) => (
  <BankAccountContainer data-testid="sp-bank-account-details">
    <TextInput
      label="Account number"
      placeholder="8 - 12 digit number"
      register={accountNumber.register}
      errorMessage={accountNumber.errorMessage}
      disabled={accountNumber.disabled}
      defaultValue={accountNumber.value}
      changeHandler={accountNumber.changeHandler}
      testingTagPage="bank-account-details"
    />
    <TextInput
      label="Routing number"
      placeholder="9 digit number"
      tooltipText="A routing number is a unique 9-digit code which identifies your bank in the United States and where payments for you should be made. Larger banks can have multiple routing numbers, so you should use the one linked to the state the account was opened in"
      register={routingNumber.register}
      errorMessage={routingNumber.errorMessage}
      disabled={routingNumber.disabled}
      defaultValue={routingNumber.value}
      changeHandler={routingNumber.changeHandler}
    />
  </BankAccountContainer>
);

export default USBankDetails;
