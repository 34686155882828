/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  CustomPaginationButton,
  CustomPaginationButtonLabel,
  CustomPaginationContainer,
  CustomPaginationLabel,
  CustomPaginationWrapper,
  SelectStyled
} from './styled';
import { themeColors } from 'assets/theme/style';
import LeftArrowBoldIcon from 'assets/icons/ArrowIcons/LeftArrowBoldIcon';
import { RightArrowBoldIcon } from 'assets/icons/ArrowIcons';
import { isMobile } from 'lib/helpers/mobile';

interface CustomPaginationProps {
  totalRows: any;
  count?: any;
  page: any;
  rowsPerPage: any;
  changeRowsPerPage: any;
  changePage: any;
  textLabels?: any;
}

export interface CustomPaginationMenuItem {
  name: number;
  value: number;
}

const CustomPagination: FC<CustomPaginationProps> = ({
  totalRows,
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  textLabels
}) => {
  const totalPages: number = Math.ceil(totalRows / rowsPerPage);

  const rowsPerPageItems: CustomPaginationMenuItem[] = [
    { name: 10, value: 10 },
    { name: 15, value: 15 },
    { name: 20, value: 20 },
    { name: 25, value: 25 }
  ];

  const mapTotalPageData: () => CustomPaginationMenuItem[] = () => {
    const mappedArray = [];
    for (let index = 0; index < totalPages; index++) {
      mappedArray.push({
        name: index + 1,
        value: index + 1
      });
    }

    return mappedArray;
  };

  return (
    <CustomPaginationWrapper>
      {!isMobile() && (
        <CustomPaginationContainer>
          <CustomPaginationLabel>Items per page</CustomPaginationLabel>
          <SelectStyled
            id="g2f-select-rowsPerPage"
            label=""
            value={rowsPerPage}
            onChange={(event: SelectChangeEvent<unknown>) => changeRowsPerPage(event.target.value)}
          >
            {rowsPerPageItems.map(
              (item, i) =>
                totalRows > item.value && (
                  <MenuItem key={i} value={item.value} data-automation-id={`select-li-item-${i}`}>
                    {item.name}
                  </MenuItem>
                )
            )}
          </SelectStyled>
          <CustomPaginationLabel>
            {page * rowsPerPage + 1} -{' '}
            {Math.min(page > 0 ? (page + 1) * rowsPerPage : rowsPerPage, totalRows)} of {totalRows}{' '}
            items
          </CustomPaginationLabel>
        </CustomPaginationContainer>
      )}
      <CustomPaginationContainer>
        <CustomPaginationButton
          onClick={() => page > 0 && changePage(page - 1)}
          disabled={page === 0}
        >
          <LeftArrowBoldIcon color={themeColors.text.light.subtle} />
          <CustomPaginationButtonLabel>Previous</CustomPaginationButtonLabel>
        </CustomPaginationButton>
        <SelectStyled
          id="g2f-select-page"
          label=""
          value={page}
          onChange={(event: SelectChangeEvent<unknown>) => changePage(event.target.value)}
        >
          {mapTotalPageData().map((item, i) => (
            <MenuItem key={i} value={item.value - 1} data-automation-id={`select-li-item-${i}`}>
              {item.name}
            </MenuItem>
          ))}
        </SelectStyled>
        <CustomPaginationLabel>of {totalPages}</CustomPaginationLabel>
        <CustomPaginationButton
          disabled={page === mapTotalPageData().length - 1}
          onClick={() => page < mapTotalPageData().length - 1 && changePage(page + 1)}
        >
          <CustomPaginationButtonLabel>Next</CustomPaginationButtonLabel>
          <RightArrowBoldIcon color={themeColors.text.light.subtle} />
        </CustomPaginationButton>
      </CustomPaginationContainer>
    </CustomPaginationWrapper>
  );
};

export default CustomPagination;
