import { styling, themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface MatchingRulesValuesRowType {
  flex: string;
}

export const MatchingRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MatchingRulesDescription = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.lightBlue};
  padding: 9px 24px;
  color: ${themeColors.dark};
  ${typography.bodyBold};
`;

export const MatchingRulesValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: ${styling.defaultPadding};
`;

export const MatchingRulesValuesTitle = styled.h4`
  margin: 0;
  color: ${themeColors.text.light.body};
`;

export const MatchingRulesRuleContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 24px;
  gap: 8px;
`;

export const MatchingRulesRuleCaption = styled.h4`
  margin: 0;
  color: ${themeColors.text.light.body};
`;

export const MatchingRulesRuleDescription = styled.div`
  margin: 0;
  color: ${themeColors.text.light.body};
`;

export const MatchingRulesValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MatchingRulesValuesArrows = styled.div`
  display: flex;
  width: 30%;
  min-width: 72px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
`;

export const MatchingRulesValuesRow = styled.div<MatchingRulesValuesRowType>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => flex};
`;
