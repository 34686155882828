import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const FundingRequestMoreDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FundingRequestMoreDetailsLink = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 8px;
`;

export const FundingRequestMoreDetailsBaseRatesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FundingRequestMoreDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  :last-child {
    margin-bottom: 4px;
  }
`;

export const FundingRequestMoreDetailsText = styled.p`
  margin: 0;
  ${typography.bodyRegular};
`;

export const FundingRequestMoreDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  :nth-child(3) {
    margin-bottom: 4px;
  }
`;

export const FundingRequestMoreDetailsLiborRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const FundingRequestMoreDetailsLiborTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  :first-child {
    margin-bottom: 20px;
  }
`;

export const FundingRequestMoreDetailsLiborTitle = styled.h5``;

export const FundingRequestMoreDetailsLiborIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
