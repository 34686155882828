import { EntityCurrency, EntityOwner, ICountries } from 'api/interfaces/entity/entity.interface';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayableItem } from 'api/interfaces/ledger/ledgerPayableItems.interface';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import {
  IOnboardingEntity,
  IOnboardingEntityEvent,
  IOnboardingProgramData
} from 'api/interfaces/onboarding/onboarding.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import FundingApi from 'api/ledger/funding.api';
import Axios, { AxiosResponse } from 'axios';
import { getConnectionStatus } from 'hipster-sdk';
import { _MODULE_ID } from 'lib/constants/contants';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { Epic, combineEpics, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  GET_FUNDINGS,
  GET_FUNDINGS_FAILURE,
  GET_FUNDINGS_SUCCESS,
  GET_G2F_CONNECTIVITY,
  GET_G2F_CONNECTIVITY_FAILURE,
  GET_G2F_CONNECTIVITY_SUCCESS,
  INITIALISE_APP,
  INITIALISE_APP_FAILURE,
  INITIALISE_APP_SUCCESS,
  REINITIALISE_APP,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS
} from 'store/actions';
import { appLoadByCurrency } from 'store/functions/app/appLoadByCurrency';
import { appPreIntialisation } from 'store/functions/app/appPreInitialisation';
import { onboardingInitialisation } from 'store/functions/app/onboardingInitialisation';
import { supplierPayInitialisation } from 'store/functions/app/supplierPayInitialisation';
import { AppReducerStateProps } from 'store/reducers/app';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

export interface IPreInitialisationData {
  getLegalEntityByTenantIdResponse: AxiosResponse<IOnboardingEntity>;
  onboardingComplete: boolean;
}

export interface IAppState {
  g2fAccessToken: string | null | undefined;
  g2fCustUID: string | null;
  programs: ProgramConfig[];
  invoices: Invoice[];
  invoiceLedger: ILedgerInvoice[];
  purchaseOrderLedger: ILedgerPurchaseOrder[];
  entities: EntityOwner[];
  programID: string | null;
  advancedInvoicesTotal: number;
  acceptedEligibleInvoices: ILedgerInvoice[];
  acceptedEligibleInvoicesTotal: number;
  fundings: Funding[];
  earlyPaymentsReceivedValue: number;
  earlyPaymentsTenorValue: number;
  countries: ICountries | null;
  currencies: EntityCurrency[];
  programCurrencies: string[] | undefined;
  program: ProgramConfig | null;
  payments: LedgerPayment[];
  paymentsTypeTrade: LedgerPayment[];
  payableItemsTypeTrade: LedgerPayableItem[];
  payableItemsTypeMaturing: LedgerPayableItem[];
  programType: keyof typeof ProgramType | null;
  onboardingComplete: boolean;
  onboardingInitData: IOnboardingEntityEvent | null;
  entityOnboardingActionsStateByProgram: IOnboardingProgramData[] | null;
  legalEntityInit: IOnboardingEntity | null;
  acceptTermsAndConditionsArtifactPdfBlob: ArrayBuffer | null;
  acceptTermsAndConditionsArtifactPdfName: string;
  onboardingBankAccountArtifacts: IOnboardingProgramData[] | null;
  platformAvailable: boolean;
  programOwnerDetails: EntityOwner | null;
  programIds: string[];
  programsWithDetails: ProgramConfig[];
  allProgramsWithDetails: ProgramConfig[];
  groupedPrograms: IGroupedProgram[];
  selectedProgramByCurrency: IGroupedProgram | null;
  discrepantInvoicesTotal: number;
  discrepantInvoicesTotalCount: number;
  pendingInvoicesTotal: number;
  pendingInvoicesTotalCount: number;
  internalLoading: boolean;
}

//===================================================
//                      API CALLS
//===================================================

const initApp: (appState: AppReducerStateProps, action?: any) => Promise<IAppState | void> = async (
  appState,
  action
) => {
  const accessToken = action.payload;
  // return await supplierPayInitialisation(appState);
  if (!accessToken) return;
  const { getLegalEntityByTenantIdResponse, onboardingComplete }: IPreInitialisationData =
    await appPreIntialisation(accessToken);

  if (onboardingComplete) return await supplierPayInitialisation(appState, accessToken);
  return onboardingInitialisation(getLegalEntityByTenantIdResponse.data, appState, accessToken);
};

const getFundings: (appState: AppReducerStateProps) => Promise<Funding[]> = async (appState) => {
  const fundingApi = new FundingApi(store);
  if (!appState.programIds) {
    throw Error('Can not initialise funding poll as program ID is not available');
  }
  return (
    await Promise.all(
      appState.programIds.map(async (id) => await fundingApi.getFundingsByProgramId(id))
    )
  ).flatMap((res) => res.flatMap((r) => r));
};

const getG2fConnectivity: (appState: AppReducerStateProps) => Promise<any> = async (appState) => {
  const g2fRes = await Axios.get(
    `${window.API_PATH_INTERNAL}/connector?programid=${appState.programID}&moduleid=${_MODULE_ID}&name=${appState.owner?.contact.registeredName}`,
    {
      headers: { Authorization: `Bearer ${appState.accessToken}` }
    }
  );

  return await getConnectionStatus(
    g2fRes.data.customerUid,
    g2fRes.data.auth.accessToken,
    `https://g2fapi-staging.finecta.dev`
  );
};

const updateDataForProgramsByCurrency: (
  appState: AppReducerStateProps,
  action: any
) => Promise<any> = async (appState, action) => {
  const { allProgramsWithDetails } = appState;
  const accessToken = action.payload.accessToken;
  return await appLoadByCurrency(
    action.payload.program,
    allProgramsWithDetails,
    appState,
    accessToken
  );
};

//===================================================
//                      EPICS
//===================================================

const initAppEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(INITIALISE_APP),
    switchMap((action) =>
      from(initApp(state$.value.app as AppReducerStateProps, action)).pipe(
        map((payload) => ({ type: INITIALISE_APP_SUCCESS, payload })),
        catchError((error) => of({ type: INITIALISE_APP_FAILURE, payload: error.message }))
      )
    )
  );

const reInitAppEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(REINITIALISE_APP),
    switchMap((action) =>
      from(initApp(state$.value.app as AppReducerStateProps, action)).pipe(
        map((payload) => ({ type: INITIALISE_APP_SUCCESS, payload })),
        catchError((error) => of({ type: INITIALISE_APP_FAILURE, payload: error.message }))
      )
    )
  );

const getFundingsEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_FUNDINGS),
    switchMap(() =>
      from(getFundings(state$.value.app as AppReducerStateProps)).pipe(
        map((payload) => ({ type: GET_FUNDINGS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_FUNDINGS_FAILURE, payload: error.message }))
      )
    )
  );

const getG2fConnectivityEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_G2F_CONNECTIVITY),
    switchMap(() =>
      from(getG2fConnectivity(state$.value.app as AppReducerStateProps)).pipe(
        map((payload) => ({ type: GET_G2F_CONNECTIVITY_SUCCESS, payload })),
        catchError((error) => of({ type: GET_G2F_CONNECTIVITY_FAILURE, payload: error.message }))
      )
    )
  );

const updateDataForProgramsByCurrencyEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY),
    switchMap((action) =>
      from(updateDataForProgramsByCurrency(state$.value.app as AppReducerStateProps, action)).pipe(
        map((payload) => ({ type: UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS, payload })),
        catchError((error) =>
          of({ type: UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE, payload: error.message })
        )
      )
    )
  );

export default combineEpics(
  initAppEpic$,
  reInitAppEpic$,
  getFundingsEpic$,
  getG2fConnectivityEpic$,
  updateDataForProgramsByCurrencyEpic$
);
