import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';
import { Accordion } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

interface IReduced {
  reduced?: boolean;
}

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LedgerCreateInvoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LedgerCreateInvoicesNoData = styled.h5`
  padding: 24px;
`;

export const LedgerCreateInvoicesButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 16px 0 0 24px;
`;

export const  LedgerCreateInvoicesButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const LedgerCreateInvoicesHeader = styled.div<IReduced>`
  display: flex;
  width: 100%;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  align-items: ${isMobile() ? 'start' : 'end'};
  justify-content: space-between;
  padding: ${isMobile() ? '16px' : '24px'};
  gap: ${isMobile() ? '16px' : '24px'};
`;

export const LedgerCreateInvoicesHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const LedgerCreateInvoicesHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobile() ? 'flex-start' : 'flex-end'};
  gap: 4px;
  min-width: fit-content;
`;

export const LedgerCreateInvoicesHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const  LedgerCreateInvoicesHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LedgerCreateInvoicesHeaderTitle = styled.h3``;

export const LedgerCreateInvoicesHeaderAmountLabel = styled.h5`
  text-align: end;
`;

export const LedgerCreateInvoicesHeaderAmountValue = styled.h3`
  text-align: end;
`;

export const LedgerCreateInvoicesAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    background-color: ${themeColors.bg.light.surface};
    border-radius: 16px!important;
    padding: 21px 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 24px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
  }
  .MuiButtonBase-root {
    width: 100%;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '0 16px' : '16px 24px'};
    ${typography.bodyRegular};
  }

  .MuiAccordionSummary-content {
    margin: 16px 0;
  }
`;

export const LedgerDetailAccordionTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LedgerCreateInvoiceAccordionTitle = styled.h4`
  margin: 0;
  ${typography.bodySemiBold};
  font-family: 'Source Sans Pro', sans-serif!important;
`;

export const CreateInvoiceDialogLineItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const CreateInvoiceDialogInvoiceEdit = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
  cursor: pointer;
`;

export const CreateInvoiceDialogLineItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 18px 0;
`;

export const CreateInvoiceDialogTitleRegular = styled.p`
  ${typography.captionRegular};
`;

export const CreateInvoiceDialogTitleBold = styled.p`
  ${typography.bodySemiBold};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const CreateInvoiceDialogTable = styled.div`
  display: flex;
`;

export const GroupedTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  
  th {
    ${typography.tableHeader};
    background-color: ${themeColors.bg.light.surfaceRaised};
    border-right: 1px solid ${themeColors.border.light.surfaceRaised};;
    height: 64px;
    border-bottom: none;
    text-transform: uppercase;
    vertical-align: middle;
    padding-left: 8px;
  
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  th:last-child{
        border-right: none;
      }
  
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.border.primary};
      background-color: ${themeColors.bg.light.body};
    }
  }

  td {
    padding: 16px 8px;
  }

  .accordion-row {
    padding: 16px 8px;
    font-size: ${fontSizes.small};

    .MuiOutlinedInput-input {
      font-size: ${fontSizes.small};
      height: 34px;
    }
  }

  .accordion-row-collapse {
    padding: 0 8px;
    font-size: ${fontSizes.small};  
  }

  .text-align-right {
    text-align: right;
  }

  .more-detail{
    padding: '0px 8px 16px 8px'
  }

  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin: 0;
  }
`;

export const CreateInvoiceDialogSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const CreateInvoiceDialogSummaryTitle = styled.h5`
  color: ${themeColors.text.light.body};
`;

export const CreateInvoiceDialogSummaryInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;

  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const CreateInvoiceDialogShowMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CreateInvoiceDialogDescriptionDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${themeColors.text.light.body};
`;

export const CreateInvoiceDialogDescriptionColumnDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
`;

export const CreateInvoiceDialogDescriptionDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
  margin-top: -8px;
`;

export const CreateInvoiceDialogBoldTitle = styled.span`
  font-weight: ${fontWeights.bold};
  color: ${themeColors.text.light.body};
`;