import AssetsApi from 'api/assets/assets.api';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import LogisticsApi from 'api/ledger/logistics.api';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';
import OpenAccountInvoiceApi from 'api/open-account/invoices/invoices';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { Params } from 'react-router';
import { LedgerView } from 'utils/interfaces/ledger/ledgerView.interface';
import {
  mapInvoiceHeaderData,
  mapInvoiceNumericDataToLedgerSummaryFormat,
  mapInvoiceToLedgerSummaryFormat
} from './invoiceLedger.service';
import { mapLogisticHeaderData, mapLogisticToLedgerSummaryFormat } from './logisticLedger.service';
import {
  mapPONumericDataToLedgerSummaryFormat,
  mapPurchaseOrderHeaderData,
  mapPurchaseOrderToLedgerSummaryFormat
} from './purchaseOrder.service';

export const getLedgerDataByType: (
  params: Readonly<Params<string>>,
  type: keyof typeof AssetEnum,
  purchaseOrderId: string | undefined,
  store: any
) => Promise<LedgerView<ILedgerInvoice | ILedgerPurchaseOrder | ILedgerLogistic> | null> = async (
  params,
  type,
  paramId,
  store
) => {
  const id: string = !paramId
    ? type === AssetEnum.INVOICE
      ? params.invoiceId || ''
      : type === AssetEnum.PURCHASE_ORDER
      ? params.purchaseOrderId || ''
      : type === AssetEnum.LOGISTIC
      ? params.logisticId || ''
      : ''
    : paramId;

  if (!id) throw Error('Ledger Service: No valid param id available.');
  switch (type) {
    case AssetEnum.INVOICE:
      try {
        const invoiceApi = new InvoiceApi(store);
        const openAccountInvoiceApi = new OpenAccountInvoiceApi(store);
        const { data } = await invoiceApi.getInvoiceById(id);
        const assetApi = new AssetsApi(store);
        const attachmentData = await assetApi.getAttachments(data.value[0]?.invoiceId, type);
        const eventHistory = data.value[0].linkedPrograms.find((p) =>
          p.baseType.includes(ProgramType.OPEN_ACCOUNT)
        )
          ? (await openAccountInvoiceApi.getInvoiceEventHistoryById(id)).data
          : [];

        return data.value[0]
          ? {
              data: data.value[0],
              breadcrumbValue: data?.value[0]?.invoiceNumber || '',
              header: mapInvoiceHeaderData(data.value[0]),
              summary: mapInvoiceToLedgerSummaryFormat(data.value[0]),
              numericSummary: mapInvoiceNumericDataToLedgerSummaryFormat(data.value[0]),
              currency: data.value[0].invoiceCurrency || '',
              documentNumber: data.value[0].invoiceNumber || '',
              attachments: attachmentData.data.content,
              eventHistory
            }
          : null;
      } catch (error) {
        throw Error('Ledger Service - Can not retrieve a matched invoice.');
      }
    case AssetEnum.PURCHASE_ORDER:
      try {
        const purchaseOrderApi = new PurchaseOrderApi(store);
        const { data } = await purchaseOrderApi.getPurchaseOrderById(id);
        const assetApi = new AssetsApi(store);
        const attachmentData = await assetApi.getAttachments(data.value[0]?.purchaseOrderId, type);

        return data.value[0]
          ? {
              data: data.value[0],
              breadcrumbValue: data?.value[0]?.data?.poNumber || '',
              header: mapPurchaseOrderHeaderData(data.value[0]),
              summary: mapPurchaseOrderToLedgerSummaryFormat(data.value[0]),
              numericSummary: mapPONumericDataToLedgerSummaryFormat(data.value[0]),
              currency: data.value[0].data.currency || '',
              documentNumber: data.value[0].data.poNumber || '',
              attachments: attachmentData.data.content,
              eventHistory: []
            }
          : null;
      } catch (error) {
        throw Error('Ledger Service - Can not retrieve a matched Purchase Order.');
      }
    case AssetEnum.LOGISTIC:
      try {
        const logisticApi = new LogisticsApi(store);
        const { data } = await logisticApi.getLogisticById(id);
        const assetApi = new AssetsApi(store);
        const attachmentData = await assetApi.getAttachments(data.value[0]?.logisticId, type);

        return data.value[0]
          ? {
              data: data.value[0],
              breadcrumbValue:
                `${data?.value[0]?.data.subTemplate?.name} - ${data?.value[0]?.data.trackingNumber}` ||
                '',
              header: mapLogisticHeaderData(data.value[0]),
              summary: mapLogisticToLedgerSummaryFormat(data.value[0]),
              numericSummary: [],
              currency: '',
              documentNumber: data.value[0].logisticId || '',
              attachments: attachmentData.data.content,
              eventHistory: []
            }
          : null;
      } catch (error) {
        throw Error('Ledger Service - Can not retrieve a matched invoice.');
      }

    default:
      throw Error('LEDGER SERVICE: INCORRECT IMPLEMENTATION');
  }
};
