import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideBarData } from 'utils/interfaces/sidebar/sidebar.interface';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { SelectStyled } from './styled';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { SidebarLinksContainer } from './styled';

interface sideBarMenuProps {
  sideBarMenuData: SideBarData | undefined;
  pathname: string;
}

const SidebarMobile: FC<sideBarMenuProps> = ({ sideBarMenuData, pathname }) => {
  const navigate = useNavigate();

  const sideBarMenuLink: (event: SelectChangeEvent) => void = (event) =>
    navigate(event.target.value as string);

  return (
    <SidebarLinksContainer>
      <SelectStyled defaultValue={pathname} onChange={(e: any) => sideBarMenuLink(e)}>
        {sideBarMenuData?.links &&
          Object.values(sideBarMenuData.links).map(
            ({ text, path }: NavigationItem, key: number) => (
              <MenuItem
                style={{ fontSize: '14px', fontFamily: `'Source Sans Pro', sans-serif` }}
                value={path}
                key={key}
                disabled={path ? false : true}
              >
                {text}
              </MenuItem>
            )
          )}
      </SelectStyled>
    </SidebarLinksContainer>
  );
};

export default SidebarMobile;
