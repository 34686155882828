import StoreBase from 'api/base/storeBase';
import { AsyncTask } from 'api/interfaces/asyncTask.interface';
import { PostFundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequest.interface';
import {
  ICalculationTaskData,
  ITaskData,
  ITaskDataItem,
  Task,
  TaskDetails
} from 'api/interfaces/funding-request/task.interface';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import { AxiosInstance, AxiosResponse } from 'axios';
import { _MODULE_ID } from 'lib/constants/contants';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { store } from 'store';
import { SET_DRAFT_ASYNCTASKID } from 'store/actions/createInvoice';

const _PATH_ORC_FINANCE = '/orchestration/finance';
const _PATH_ORC_DATA = '/orchestration/data';

export default class OrchestrationApi extends StoreBase {
  public postCreateInvoice: (status:string, data: any) => Promise<any> = async (status, data) => {

    if (status === "draft") {
      return await this.taskCompletionPoll(data);
    } else {
      try {
        const response = await this.axiosInstance.post<any>(`${_PATH_ORC_DATA}/invoices/import`, data);
  
        store.dispatch({
          type: SET_DRAFT_ASYNCTASKID,
          payload:response.data.asyncTaskId });
      
  
        return await this.taskCompletionPoll(response.data.asyncTaskId);
      } 
      catch (error) {
        console.error("Error processing data:", error);
        throw error;
      }
    }
    
  };

  public fundingRequestCalculation: (
    programID: string,
    invoices: string[],
    currency: string
  ) => Promise<AsyncTask> = async (programID, invoices, currency) => {
    const body: PostFundingRequestCalculation = {
      programId: programID,
      currency,
      criteria: {
        kind: 'SpSpecificAssetCriteria',
        assets: invoices.map((invoice) => ({ assetId: invoice }))
      }
    };

    const { data }: { data: AsyncTask } = await this.axiosInstance.post<
      any,
      AxiosResponse<AsyncTask>,
      PostFundingRequestCalculation
    >(`${_PATH_ORC_FINANCE}/${_MODULE_ID}/funding-request/calculation`, body);
    return data;
  };

  public calculationTaskCompletionPoll: (
    taskID: string
  ) => Promise<TaskDetails<ICalculationTaskData>> = async (taskID) => {
    let count: number = 0;
    const interval: number = 2000;
    const totalTimeMillis: number = 180000;
    const maxCount: number = totalTimeMillis / interval;
    const _inProgress: string = 'IN_PROGRESS';
    const _notStarted: string = 'NOT_STARTED';
    const _retrying: string = 'RETRYING';
    const _complete: string = 'COMPLETED';
    const failedModel: TaskDetails<ICalculationTaskData> = {
      id: '',
      type: '',
      data: {
        moduleId: '',
        calculationTaskId: '',
        fundingId: '',
        status: 'FAILED',
        reason: ''
      },
      createdBy: '',
      createdAt: '',
      completedAt: ''
    };

    return new Promise((resolve, reject) => {
      (async function check(axiosInstance: AxiosInstance) {
        const response: AxiosResponse<Task> = await axiosInstance.get<Task>(
          `${_PATH_ORC_FINANCE}/task/${taskID}`
        );

        if (
          count <= maxCount &&
          (response.data.status === _inProgress ||
            response.data.status === _retrying ||
            response.data.status === _notStarted)
        ) {
          count++;
          console.info('POLLING: 202 - repeat request');
          setTimeout(() => {
            check(axiosInstance);
          }, interval);
          return;
        }

        console.info(`POLLING STOPPED: Calculation Task - Status: ${response.data.status}`);

        if (
          count === maxCount &&
          (response.data.status === _inProgress ||
            response.data.status === _retrying ||
            response.data.status === _notStarted)
        ) {
          failedModel.data.reason = `FAILED: ${count} API calls made and task not completed.`;
          resolve(failedModel);
          return;
        }

        if (
          response.data.status !== _inProgress ||
          response.data.status !== _retrying ||
          response.data.status !== _notStarted ||
          response.data.status !== _complete
        ) {
          const detailsResponse: AxiosResponse<TaskDetails<ICalculationTaskData>> =
            await axiosInstance.get<TaskDetails<ICalculationTaskData>>(
              `${_PATH_ORC_FINANCE}/task/${taskID}/details`
            );
          resolve(detailsResponse.data);
          return;
        }

        if (response.data.status === _complete) {
          const detailsResponse: AxiosResponse<TaskDetails<ICalculationTaskData>> =
            await axiosInstance.get<TaskDetails<ICalculationTaskData>>(
              `${_PATH_ORC_FINANCE}/task/${taskID}/details`
            );

          resolve(detailsResponse.data);
          return;
        }

        failedModel.data.reason = `FAILED: No recognised status`;
        resolve(failedModel);
        return;
      })(this.axiosInstance);
    });
  };

  public submitFinanceRequest: (moduleID: string, calculationTaskID: string) => Promise<AsyncTask> =
    async (moduleID, calculationTaskID) => {
      const body = {
        calculationTaskId: calculationTaskID,
        comment: null
      };
      const { data } = await this.axiosInstance.post(
        `${_PATH_ORC_FINANCE}/${moduleID}/funding-request`,
        body
      );
      return data;
    };

  public uploadAsset: (
    programId: string,
    assetType: keyof typeof AssetEnum,
    asset: any
  ) => Promise<TaskDetails<ITaskData<ITaskDataItem>>> = async (programId, assetType, asset) => {
    const body: any = {
      data: []
    };

    if (asset.constructor === Array) {
      body.data = asset.map((a) => ({
        assignment: {
          moduleId: _MODULE_ID,
          programIds: [programId]
        },
        data: a
      }));
    } else {
      body.data.push({
        assignment: {
          moduleId: _MODULE_ID,
          programIds: [programId]
        },
        data: asset
      });
    }

    const { data }: { data: AsyncTask } = await this.axiosInstance.post<
      any,
      AxiosResponse<AsyncTask>,
      any
    >(`${_PATH_ORC_DATA}/${assetType.toLowerCase()}s/import`, body);

    const pollRes = await this.taskCompletionPoll(data.asyncTaskId);

    if (!pollRes) throw Error(`Failed to perform ${assetType}S upload.`);

    return pollRes;
  };

  public uploadLogistic: (
    programId: string,
    logistic: LedgerLogistics
  ) => Promise<TaskDetails<ITaskData<ITaskDataItem>>> = async (programId, logistic) => {
    const body: any = {
      data: [
        {
          assignment: {
            moduleId: _MODULE_ID,
            programIds: [programId]
          },
          data: logistic
        }
      ]
    };

    const { data }: { data: AsyncTask } = await this.axiosInstance.post<
      any,
      AxiosResponse<AsyncTask>,
      any
    >(`${_PATH_ORC_DATA}/logistics/import`, body);

    const pollRes = await this.taskCompletionPoll(data.asyncTaskId);

    if (!pollRes) throw Error('Failed to perform logistics document upload.');

    return pollRes;
  };

  public taskCompletionPoll: (taskID: string) => Promise<TaskDetails<ITaskData<ITaskDataItem>>> =
    async (taskID) => {
      let count: number = 0;
      const interval: number = 2000;
      const totalTimeMillis: number = 180000;
      const maxCount: number = totalTimeMillis / interval;
      const _notStarted: string = 'NOT_STARTED';
      const _inProgress: string = 'IN_PROGRESS';
      const _retrying: string = 'RETRYING';
      const _complete: string = 'COMPLETED';
      const _failed: string = 'FAILED';
      const failedModel: any = {
        id: '',
        type: '',
        data: {
          moduleId: '',
          logisticTaskId: '',
          fundingId: '',
          status: 'FAILED',
          reason: ''
        },
        createdBy: '',
        createdAt: '',
        completedAt: ''
      };

      return new Promise((resolve, reject) => {
        (async function check(axiosInstance: AxiosInstance) {
          const response: AxiosResponse<Task> = await axiosInstance.get<Task>(
            `${_PATH_ORC_DATA}/task/${taskID}`
          );

          if (
            count <= maxCount &&
            (response.data.status === _notStarted ||
              response.data.status === _inProgress ||
              response.data.status === _retrying)
          ) {
            count++;
            console.info('POLLING: 202 => repeat request');
            setTimeout(() => {
              check(axiosInstance);
            }, interval);
            return;
          }

          console.info(`POLLING STOPPED: Calculation Task - Status: ${response.data.status}`);

          if (
            count === maxCount &&
            (response.data.status === _notStarted ||
              response.data.status === _inProgress ||
              response.data.status === _retrying)
          ) {
            failedModel.data.reason = `FAILED: ${count} API calls made and task not completed.`;
            resolve(failedModel);
            return;
          }

          if (response.data.status === _failed) {
            const detailsResponse: AxiosResponse<TaskDetails<ITaskData<ITaskDataItem>>> =
              await axiosInstance.get<TaskDetails<ITaskData<ITaskDataItem>>>(
                `${_PATH_ORC_DATA}/task/${taskID}/details`
              );
            resolve(detailsResponse.data);
            return;
          }

          if (response.data.status === _complete) {
            const detailsResponse: AxiosResponse<TaskDetails<ITaskData<ITaskDataItem>>> =
              await axiosInstance.get<TaskDetails<ITaskData<ITaskDataItem>>>(
                `${_PATH_ORC_DATA}/task/${taskID}/details`
              );

            resolve(detailsResponse.data);
            return;
          }

          failedModel.data.reason = `FAILED: No recognised status`;
          resolve(failedModel);
          return;
        })(this.axiosInstance);
      });
    };
}
