import LedgerDetail from 'components/ledger/LedgerDetail';
import LedgerInvoice from 'components/ledger/LedgerInvoice';
import LedgerLogistics from 'components/ledger/LedgerLogistics';
import LedgerMatching from 'components/ledger/LedgerMatching';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

export const purchaseOrderTabs: TabsItem[] = [
  {
    text: 'Purchase Order Name',
    children: (
      <LedgerDetail
        type={AssetEnum.PURCHASE_ORDER}
        showEntities
        showSummaryAccordions
        showCreateInvoiceBanner
      />
    )
  },
  {
    text: 'Invoice',
    children: <LedgerInvoice />
  },
  // {
  //   text: 'Invoice',
  //   children: <LedgerCreateInvoice />
  // },
  {
    text: 'Logistics',
    children: <LedgerLogistics type={AssetEnum.PURCHASE_ORDER} />
  },
  {
    text: 'Matching',
    children: <LedgerMatching />
  }
];
