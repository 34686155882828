import { AssetEnum } from 'lib/enums/asset/asset.enum';

export const csvUploadExclusions: { [key: string]: { displayName: string; fieldName: string }[] } =
  {
    [AssetEnum.INVOICE]: [
      { displayName: 'Invoice Number', fieldName: 'invoiceNumber' },
      { displayName: 'Buyer Reference', fieldName: 'buyerReference' },
      { displayName: 'Supplier Reference', fieldName: 'supplierReference' },
      { displayName: 'Currency', fieldName: 'currency' }
    ],
    [AssetEnum.PURCHASE_ORDER]: [
      { displayName: 'PO Number', fieldName: 'poNumber' },
      { displayName: 'Buyer Reference', fieldName: 'buyerReference' },
      { displayName: 'Supplier Reference', fieldName: 'supplierReference' },
      { displayName: 'Currency', fieldName: 'currency' }
    ],

    [AssetEnum.LOGISTIC]: [
      { displayName: 'Tracking Number', fieldName: 'trackingNumber' },
      { displayName: 'Consignee Reference', fieldName: 'consigneeReference' },
      { displayName: 'Consignor Reference', fieldName: 'consignorReference' }
    ]
  };

export const csvUploadMandatoryFieldInclusions: {
  [key: string]: { displayName: string; fieldName: string }[];
} = {
  [AssetEnum.INVOICE]: [
    { displayName: 'Invoice Number', fieldName: 'invoiceNumber' },
    { displayName: 'Buyer Reference', fieldName: 'buyerReference' },
    { displayName: 'Supplier Reference', fieldName: 'supplierReference' },
    { displayName: 'Currency', fieldName: 'currency' }
  ],
  [AssetEnum.PURCHASE_ORDER]: [
    { displayName: 'PO Number', fieldName: 'poNumber' },
    { displayName: 'Buyer Reference', fieldName: 'buyerReference' },
    { displayName: 'Supplier Reference', fieldName: 'supplierReference' },
    { displayName: 'Currency', fieldName: 'currency' }
  ],

  [AssetEnum.LOGISTIC]: [
    { displayName: 'Tracking Number', fieldName: 'trackingNumber' },
    { displayName: 'Consignee Reference', fieldName: 'consigneeReference' },
    { displayName: 'Consignor Reference', fieldName: 'consignorReference' }
  ]
};
