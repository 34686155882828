import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import { themeColors } from 'assets/theme/style';

export const LedgerDetailGatewayToFinanceBannerContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
  padding: 0 16px;
`;

export const LedgerDetailGatewayToFinanceBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LedgerDetailGatewayToFinanceBannerDescription = styled.p`
  ${typography.bodyRegular};
`;

export const LedgerDetailGatewayToFinanceBannerButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const LedgerDetailGatewayToFinanceBannerButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const LedgerDetailGatewayToFinanceBannerButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const LedgerDetailGatewayToFinanceBannerButtonText = styled.p`
  ${typography.bodySmallSemiBold};
`;

export const LedgerDetailGatewayToFinanceBannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const LottieAnimationContainer = styled(Lottie)`
  display: flex;
  width: 24px;
  height: 32px;
  margin-top: -10px;
`;
