/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import {
  ILedgerLogistic,
  ILedgerLogisticLinkedProgram
} from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import MatchingChip from 'components/Chips/MatchingChip';
import DataGrid from 'components/DataGrid';
import QueryBuilder from 'components/QueryBuilder';
import Chip from 'components/common/Chip';
import PillButton from 'components/common/buttons/PillButton';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { logisticsViewHeaders } from 'lib/data/logistics/logisticsHeaders';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { QueryOperatorsEnum } from 'lib/enums/query/queryComparators.enum';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { isMobile, isSmallerView } from 'lib/helpers/mobile';
import { FC, useEffect, useState, KeyboardEvent } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router';
import { QueryItem, QueryRow } from 'utils/classes/query/query';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { IQuery } from 'utils/interfaces/query/query.interface';
import { SearchAdvancedText } from 'views/InvoicesView/styled';
import {
  LogisticsViewFilterButtonTextWrapper,
  LogisticsViewMobileContainer,
  LogisticsViewMobileItemBold,
  LogisticsViewMobileItemLeft,
  LogisticsViewMobileItemRegular,
  LogisticsViewMobileItemRight,
  LogisticsViewMobileWrapper,
  LogisticsViewSearchContainer,
  LogisticsViewTitle
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import { store } from 'store';
import LogisticsApi from 'api/ledger/logistics.api';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';
import CustomPagination from 'components/CustomPagination/CustomPagination';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';

export interface LogisticItem {
  logisticId: string;
  documentDescription: string;
  trackingNumber: string;
  status: string;
  matchingStatus: string;
  consignorName: string;
  consigneeName: string;
  programName: string;
}

const fieldDictionary: any = {
  trackingNumber: 'data/trackingNumber',
  documentDescription: 'data/subTemplate/name',
  consignorName: 'data/consignorName',
  consigneeName: 'data/consigneeName'
};

const LogisticsView: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const [exportData, setExportData] = useState<DataGridRowItem[]>([]);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [querySet, setQuerySet] = useState<QueryItem[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [orderBy, setOrderBy] = useState<string>('createdAt desc');
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [logisticLedgerCount, setlogisticLedgerCount] = useState<number>(0);
  const [logisticLoading, setlogisticLoading] = useState<boolean>(false);
  const [logisticInitialLoading, setlogisticInitialLoading] = useState<boolean>(false);
  const [initialise, setInitialise] = useState<boolean>(false);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const {
    loading: appLoading,
    allProgramsWithDetails,
    activeCurrencyCode,
    selectedProgramByCurrency
  }: {
    loading: boolean;
    activeCurrencyCode: string;
    selectedProgramByCurrency: IGroupedProgram | null;
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const logisticApi = new LogisticsApi(store);

  useEffect(() => {
    if (!initialise) {
      setInitialise(true);
      getLogistics(true);
    }
  }, []);

  useEffect(() => {
    initialise && getLogistics();
  }, [rowsPerPage, page, activeQueries, orderBy]);

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => {
    const inputValue = event.currentTarget.value;
    setSearchTerm(inputValue);
  };

  const searchBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue === '') {
      getLogistics();
      setPage(0);
    }
  };

  const searchHandleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === 'Enter') {
      getLogistics();
      setPage(0);
    }
  };

  const clickSearchHandle: () => void = () => {
    getLogistics();
    setPage(0);
  };

  const getLogistics: (initialLoading?: boolean) => void = async (initialLoading = false) => {
    initialLoading ? setlogisticInitialLoading(true) : setlogisticLoading(true);
    const programIds = allProgramsWithDetails.map((p) => p.id) || [];

    let filterSearch = ``;
    if (searchTerm.length > 0) {
      filterSearch += `and (contains(data/trackingNumber,'${searchTerm}') or contains(data/subTemplate/name,'${searchTerm}') or contains(data/consigneeName,'${searchTerm}') or (linkedPrograms/any(f: contains(f/programName,'${searchTerm}'))))`;
    }

    if (activeQueries.length > 0) {
      setShowAdvancedSearch(true);

      activeQueries.forEach((q: QueryRow) => {
        if (q.fieldValue === 'trackingNumber') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/trackingNumber, '${q.queryValue}')`
              : `and (data/trackingNumber ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'documentDescription') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/subTemplate/name, '${q.queryValue}')`
              : `and (data/subTemplate/name ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'status') {
          filterSearch += `and (linkedPrograms/any(f:f/logisticStatus ${q?.comparator} '${q?.queryValue}'))`;
        } else if (q.fieldValue === 'matchingStatus') {
          filterSearch += `and (linkedPrograms/any(f: f/matchingStatus ${q?.comparator} '${q?.queryValue}'))`;
        } else if (q.fieldValue === 'consignorName') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/consignorName, '${q.queryValue}')`
              : `and (data/consignorName ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'consigneeName') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/consigneeName, '${q.queryValue}')`
              : `and (data/consigneeName ${q.comparator} '${q.queryValue}')`;
        }
      });
    }

    const logisticsResponse = await logisticApi.getFilterLogistics(
      programIds,
      rowsPerPage,
      page,
      filterSearch,
      orderBy
    );

    const logisticsResponseAll = await logisticApi.getFilterLogistics(
      programIds,
      null,
      null,
      filterSearch,
      orderBy
    );

    setData(mapLogisticItemsToGridModel(logisticsResponse?.data?.value || []));
    setExportData(mapLogisticItemsToGridModel(logisticsResponseAll?.data?.value || []));

    setlogisticLedgerCount(logisticsResponse?.data['@odata.count'] || 0);

    initialLoading ? setlogisticInitialLoading(false) : setlogisticLoading(false);
  };

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    const clone = [...queries];

    const isUpdated =
      clone.length === activeQueries.length &&
      clone.some((cloneValue) => {
        return activeQueries.some(
          (activeValue) =>
            cloneValue.fieldValue === activeValue.fieldValue &&
            cloneValue.queryValue === activeValue.queryValue
        );
      });

    if (isUpdated || activeQueries.length !== clone.length) {
      setActiveQueries(clone);
      setPage(0);
    }
  };

  const rowClickHandler: (row: any) => void = (row) => {
    const data: any = isMobile() ? row : row[0];
    navigate(`/logistics/${data}`);
  };

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const applyQueryParamFilters: (queryItems: QueryItem[]) => QueryItem[] = (queryItems) => {
    const queryParams = new URLSearchParams(window.location.search);
    return [...queryItems].map((queryItem) => {
      const presetValue: string = queryParams.get(queryItem.filterValue) || '';
      return presetValue
        ? {
            ...queryItem,
            defaultComparator: 'eq',
            defaultValue: presetValue.toUpperCase()
          }
        : queryItem;
    });
  };

  const getLinkedProgram: (
    linkedPrograms: ILedgerLogisticLinkedProgram[]
  ) => ILedgerLogisticLinkedProgram | undefined = (linkedPrograms) => {
    return linkedPrograms.find((program) => program.baseType.includes(ProgramType.OPEN_ACCOUNT));
  };

  const mapLogisticItemsToGridModel: (data: ILedgerLogistic[]) => DataGridRowItem[] = (data) =>
    data.map(
      (log): LogisticItem => ({
        logisticId: log.logisticId || '',
        trackingNumber: log.data?.trackingNumber || '-',
        documentDescription: log.data?.subTemplate?.name || '',
        status: getLinkedProgram(log.linkedPrograms)?.logisticStatus || '',
        matchingStatus: getLinkedProgram(log.linkedPrograms)?.matchingStatus || '',
        consignorName: log.data?.consignorName || '',
        consigneeName: log.data?.consigneeName || '',
        programName: getLinkedProgram(log.linkedPrograms)?.programName || ''
      })
    );

  const queryItems: QueryItem[] = [
    {
      filterName: 'Tracking Number',
      filterValue: 'trackingNumber',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Document Description',
      filterValue: 'documentDescription',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Status',
      filterValue: 'status',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Accepted',
          value: 'ACCEPTED'
        },
        {
          name: 'Approved',
          value: 'APPROVED'
        },
        {
          name: 'Open',
          value: 'OPEN'
        },
        {
          name: 'Closed',
          value: 'CLOSED'
        }
      ]
    },
    {
      filterName: 'Matching Status',
      filterValue: 'matchingStatus',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Pending',
          value: 'PENDING'
        },
        {
          name: 'Accepted',
          value: 'ACCEPTED'
        },
        {
          name: 'Matched',
          value: 'MATCHED'
        },
        {
          name: 'Discrepant',
          value: 'DISCREPANT'
        },
        {
          name: 'Rejected',
          value: 'REJECTED'
        },
        {
          name: 'Closed',
          value: 'CLOSED'
        }
      ]
    },
    {
      filterName: 'Consignor Name',
      filterValue: 'consignorName',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Consignee Name',
      filterValue: 'consigneeName',
      componentType: QueryComponents.TEXT
    }
  ];

  useEffect(() => {
    const hydratedQueryItems = applyQueryParamFilters(queryItems);
    setQuerySet(hydratedQueryItems);
  }, []);

  const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
    setPage(0);
  };

  const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) => {
    setPage(tableState.page);
  };

  const columnSortChangeClickHandler = (col: any) => {
    const odataField: string | undefined = fieldDictionary[col];
    const sortable = Boolean(odataField);
    if (!sortable) return;
    const direction = orderDirection === 'asc' ? 'desc' : 'asc';
    setOrderDirection(direction);
    setOrderBy(`${fieldDictionary[col]} ${direction}`);
  };

  const renderTable: () => JSX.Element = () =>
    isMobile() ? (
      <>
        {data.map((data, index, array) => (
          <LogisticsViewMobileWrapper key={index}>
            <LogisticsViewMobileContainer onClick={() => rowClickHandler(data.logisticId)}>
              <LogisticsViewMobileItemLeft>
                <LogisticsViewMobileItemBold>{data.trackingNumber}</LogisticsViewMobileItemBold>
                <LogisticsViewMobileItemRegular>
                  {data.documentDescription}
                </LogisticsViewMobileItemRegular>
              </LogisticsViewMobileItemLeft>
              <LogisticsViewMobileItemRight>
                <LogisticsViewMobileItemBold></LogisticsViewMobileItemBold>
                <LogisticsViewMobileItemRegular>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                    <Chip
                      type={data.status}
                      color={themeColors.text.primary}
                      radius="3px"
                      height="18px"
                      fontSize={fontSizes.small}
                      semibold="bold"
                      testingTag="ledger-logistics-header"
                    />
                    <MatchingChip
                      type={data.matchingStatus}
                      testingTag="logistic-ledger"
                      ledgerType={LedgerTypes.LOGISTIC}
                    />
                  </div>
                </LogisticsViewMobileItemRegular>
              </LogisticsViewMobileItemRight>
            </LogisticsViewMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LogisticsViewMobileWrapper>
        ))}
        {logisticLedgerCount > 15 && (
          <CustomPagination
            totalRows={logisticLedgerCount}
            count={logisticLedgerCount}
            page={page}
            rowsPerPage={rowsPerPage}
            changePage={tableChangeHandler}
            changeRowsPerPage={rowsPerPageHandler}
            textLabels=""
          />
        )}
      </>
    ) : (
      <DataGrid
        headers={logisticsViewHeaders}
        data={data}
        exportData={exportData}
        serverSide
        loading={logisticLoading}
        ariaLabel={''}
        ariaCaption={''}
        fixedHeader
        rowClickHandler={rowClickHandler}
        tableChangeHandler={tableChangeHandler}
        columnSortChangeClickHandler={columnSortChangeClickHandler}
        hasActions
        exportFilename="logistics-view_ledger"
        smallDownloadButton={false}
        dataAutomationTag="logistics-view-ledger-table-ledger-items"
        page={page === -1 ? 0 : page}
        rowsPerPage={rowsPerPage}
        totalRows={logisticLedgerCount}
        changeRowsPerPageHandler={rowsPerPageHandler}
        showFooter={logisticLedgerCount > 15}
      />
    );

  return logisticInitialLoading || appLoading ? (
    <LoaderFullPage />
  ) : (
    <LayoutViewContainer size="xlarge">
      <BaseCard noBorder noBoxShadow>
        <LogisticsViewTitle
          data-automation-id="logistics-views-page-title"
          data-testid="sp-page-title"
        >
          Logistics
        </LogisticsViewTitle>
        <LogisticsViewSearchContainer>
          <OutlinedInput
            id="outlined-adornment-search"
            dataTestId="sp-logistic-view-input-fuzzy-search"
            dataAutomationId="logistic=view-input-fuzzy-search"
            defaultValue={searchTerm}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => searchBlurHandler(event)}
            changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              searchHandler(event)
            }
            handleKeyDown={(event: KeyboardEvent<HTMLInputElement>) => searchHandleKeyDown(event)}
            placeholder="Search by tracking number, document description, consignor name, consignee name"
          />

          <PrimaryButton
            clickHandler={clickSearchHandle}
            disabled={searchTerm === '' || searchTerm.length < 3}
            height="40px"
            width="40px"
          >
            <LogisticsViewFilterButtonTextWrapper>
              <SearchIcon />
            </LogisticsViewFilterButtonTextWrapper>
          </PrimaryButton>
          {!isMobile() && (
            <SearchAdvancedText
              data-automation-id="payments-p-advanced-search-link-button"
              onClick={advancedSearchClickHandler}
              data-testid="sp-advanced-search"
            >
              {showAdvancedSearch ? 'Hide advanced search' : 'Advanced search'}
            </SearchAdvancedText>
          )}
        </LogisticsViewSearchContainer>
        <QueryBuilder
          queryItems={querySet}
          activeQueriesHandler={activeQueriesHandler}
          closeClickHandler={advancedSearchClickHandler}
          open={showAdvancedSearch}
          testingTagPage="logistics"
        />
        {!showAdvancedSearch && <Divider />}

        {renderTable()}
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default LogisticsView;
