import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';

export const userManagementAdminLink: NavigationItem[] = [
  {
    text: 'Reset Password',
    status: 'RESET'
  },
  {
    text: 'Suspend',
    status: 'SUSPEND'
  },
  {
    text: 'Deactivate',
    status: 'DEACTIVATE'
  }
];

export const userManagementAdminNotActiveLink: NavigationItem[] = [
  {
    text: 'Suspend',
    status: 'SUSPEND'
  },
  {
    text: 'Deactivate',
    status: 'DEACTIVATE'
  }
];

export const userManagementOnlyResetPasswordLink: NavigationItem[] = [
  {
    text: 'Reset Password',
    status: 'RESET'
  }
];

export const userManagementOnlyDeactivateLink: NavigationItem[] = [
  {
    text: 'Deactivate',
    status: 'DEACTIVATE'
  }
];

export const userManagementOnlyActivateLink: NavigationItem[] = [
  {
    text: 'Activate',
    status: 'ACTIVATE'
  }
];
