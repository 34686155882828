import { IAccountingSoftware } from "views/Home/ConnectAccountingSoftware/ConnectAccountingSoftwareDialog/ConnectAccountingSoftwareDialog";
import logoFreshbooks from 'assets/images/accountingSofwareLogo/freshbooks.png';
import logoPabbly from 'assets/images/accountingSofwareLogo/pabbly.png';
import logoQuickbooks from 'assets/images/accountingSofwareLogo/quickbooks.png';
import logoSage from 'assets/images/accountingSofwareLogo/sage.png';
import logoWave from 'assets/images/accountingSofwareLogo/wave.png';
import logoXero from 'assets/images/accountingSofwareLogo/xero.png';
import logoZoho from 'assets/images/accountingSofwareLogo/zoho.png';

export const softwareList: IAccountingSoftware[] = [
  {
    name: 'Freshbooks',
    logo: logoFreshbooks,
    link: ''
  },
  {
    name: 'Pabbly',
    logo: logoPabbly,
    link: ''
  },
  {
    name: 'Quickbooks',
    logo: logoQuickbooks,
    link: ''
  },
  {
    name: 'Sage',
    logo: logoSage,
    link: ''
  },
  {
    name: 'Wave',
    logo: logoWave,
    link: ''
  },
  {
    name: 'Xero',
    logo: logoXero,
    link: ''
  },
  {
    name: 'Zoho',
    logo: logoZoho,
    link: ''
  }
];