/* eslint-disable react-hooks/exhaustive-deps */
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import Tabs from 'components/Tabs';
import BaseCard from 'components/common/cards/BaseCard';
import LedgerDetail from 'components/ledger/LedgerDetail';
import { LedgerDetailHeaderProps } from 'components/ledger/LedgerDetail/LedgerDetailHeader';
import LedgerNotifications from 'components/ledger/LedgerNotifications';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { invoiceDetailFixedCostTabs } from 'lib/data/invoiceDetailFixedCostTabs';
import { invoiceDetailTabs } from 'lib/data/invoiceDetailTabs';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { Dispatch, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SET_BREADCRUMB, SET_DOCUMENT_FOR_VIEW } from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

const InvoiceDetail: FC = () => {
  const [searchParams] = useSearchParams();
  const searchTab = searchParams.get('tab');
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [defaultTab, setDefaultTab] = useState<number>(0);
  const {
    documentForViewHeaderData
  }: {
    documentForViewHeaderData: {
      header: LedgerDetailHeaderProps | null;
      data: ILedgerInvoice | ILedgerPurchaseOrder | null;
    } | null;
  } = useSelector((state: RootStateOrAny) => state.context);
  const isProgramOpenAccount: boolean = useCheckProgramsContainsType(ProgramType.OPEN_ACCOUNT);
  const isProgramFixedCost: boolean = useCheckProgramsContainsType(ProgramType.FIXED_DISCOUNT);

  useEffect(() => {
    return () => {
      dispatch({ type: SET_BREADCRUMB, payload: null });
      dispatch({ type: SET_DOCUMENT_FOR_VIEW, payload: null });
    };
  }, []);

  useEffect(() => {
    searchTab && setDefaultTab(Number(searchTab));
  }, [searchTab]);

  const mapTabs: () => TabsItem[] = () => {
    if (isProgramFixedCost) return invoiceDetailFixedCostTabs.map((tab) => tab);
    if (isProgramOpenAccount) return invoiceDetailTabs.map((tab) => tab);
    return [
      {
        text: 'Invoice Name',
        children: <LedgerDetail type={AssetEnum.INVOICE} showEntities showSummaryAccordions />
      }
    ];
  };

  return (
    <LayoutViewContainer size="xlarge">
      {documentForViewHeaderData && (
        <LedgerNotifications matchingClickHandler={() => setDefaultTab(3)} />
      )}
      <Tabs
        tabItems={mapTabs()}
        documentName="Invoice"
        defaultValue={defaultTab}
        testingTagPage="invoice-detail"
      />
    </LayoutViewContainer>
  );
};

export default InvoiceDetail;
