import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const PackageIcon: FC<IconSvgProps> = ({
  height = '24',
  width = '25',
  color = themeColors.icon.light.muted
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1056 21.833L4.17644 17.3701C3.85663 17.1901 3.66675 16.8601 3.66675 16.5001V7.50012C3.66675 7.20281 3.78935 6.93133 4.00345 6.74793C4.08955 6.66877 4.18801 6.60708 4.29374 6.56409L12.1715 2.13012C12.4714 1.95012 12.8611 1.95012 13.1709 2.13012L17.0068 4.28912C17.1149 4.32721 17.2169 4.38404 17.3074 4.45828L21.0773 6.58016C21.2629 6.66389 21.4253 6.80614 21.5361 7.00009C21.6507 7.20396 21.6877 7.43128 21.6557 7.6468V16.5001C21.6457 16.8601 21.4558 17.1901 21.146 17.3701L13.1797 21.8539C13.0288 21.9462 12.8519 21.9996 12.6632 22L12.6612 22.0001L12.6591 22C12.4529 21.9996 12.2631 21.9381 12.1056 21.833ZM14.6215 5.25137L12.6612 4.15012L6.71084 7.49289L8.67495 8.59838L14.6215 5.25137ZM10.6976 9.73682L16.6461 6.38872L18.6228 7.49916L12.6724 10.8483L10.6976 9.73682ZM8.17471 10.6193L11.6618 12.582V19.2887L5.66552 15.9201V9.20704L8.16122 10.6117C8.1657 10.6143 8.17019 10.6168 8.17471 10.6193ZM13.6606 19.2887L19.657 15.9201V9.2196L13.6606 12.5946V19.2887Z"
      fill={color}
    />
  </svg>
);

export default PackageIcon;
