import { ProgramConfig, ProgramRuleTypeEnum } from 'api/interfaces/program/program.interface';
import { ProgramType } from 'lib/enums/program/programType.enum';

export const getFundingParticipantName: (
  programs: ProgramConfig[],
  type: 'BUYER' | 'SUPPLIER' | 'FUNDER'
) => string = (programs, type) =>
  programs
    .find(
      (program) =>
        program.baseType.includes(ProgramType.SP_FUNDING) ||
        program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    )
    ?.participants.find((p) => p.role === type)?.registeredName || type;

export const getManagingBankName: (programs: ProgramConfig[]) => string = (programs) => {
  const matchedProgram = programs.find(
    (program) =>
      program.baseType.includes(ProgramType.SP_FUNDING) ||
      program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
  );

  if (!matchedProgram) return '';

  const managingBankRule =
    matchedProgram.rules.find((r) => r.type === ProgramRuleTypeEnum.MANAGING_BANK)?.value
      ?.managingBankName || '';

  return managingBankRule;
};
