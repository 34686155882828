import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { FC } from 'react';
import { LedgerLineBuilder } from 'utils/classes/ledger/ledgerLineBuilder';
import {
  LineChartBarContainer,
  LineChartContainer,
  LineChartKeyContainer,
  LineChartKeySegment,
  LineChartKeySegmentLeft,
  LineChartKeySegmentRight,
  LineChartKeyText,
  LineChartKeyTopRow,
  LineChartKeyValue,
  LineChartLineSegment,
  LineChartPill,
  LineChartTitle
} from './styled';

const LedgerDetailLineChart: FC<LedgerLineBuilder> = ({
  title,
  total,
  consumedData,
  availableData,
  currency
}) => {
  const calculatePercentage: (val: number) => number = (val) => (val / total) * 100;

  const renderSegments: () => JSX.Element = () => (
    <>
      {consumedData.value > 0 && (
        <LineChartLineSegment
          lineWidthPercentage={calculatePercentage(consumedData.value)}
          color={consumedData.color}
        />
      )}
      {availableData.value > 0 && (
        <LineChartLineSegment
          lineWidthPercentage={calculatePercentage(availableData.value)}
          color={availableData.color}
        />
      )}
    </>
  );

  const renderKeySegments: () => JSX.Element = () => (
    <>
      <LineChartKeySegment>
        <LineChartKeyTopRow>
          <LineChartPill color={consumedData.color}></LineChartPill>
          <LineChartKeyText>{consumedData.name}</LineChartKeyText>
        </LineChartKeyTopRow>
        <LineChartKeySegmentRight>
          <LineChartKeyValue>
            {currency} {formatNumber(consumedData.value, 2)}
          </LineChartKeyValue>
        </LineChartKeySegmentRight>
      </LineChartKeySegment>
      <LineChartKeySegment key="available-segment">
        <LineChartKeyTopRow>
          <LineChartPill color={availableData.color}></LineChartPill>
          <LineChartKeyText>{availableData.name}</LineChartKeyText>
        </LineChartKeyTopRow>
        <LineChartKeySegmentRight>
          <LineChartKeyValue>
            {currency} {formatNumber(availableData.value, 2)}
          </LineChartKeyValue>
        </LineChartKeySegmentRight>
      </LineChartKeySegment>
    </>
  );

  return (
    <LineChartContainer id="ledger-detail-line-chart">
      <LineChartTitle>{title}</LineChartTitle>
      <LineChartBarContainer color={availableData.color}>{renderSegments()}</LineChartBarContainer>
      <LineChartKeyContainer>{renderKeySegments()}</LineChartKeyContainer>
    </LineChartContainer>
  );
};

export default LedgerDetailLineChart;
