import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const BannerIcon: FC<IconSvgProps> = ({
  height = '40',
  width = '40',
  color = themeColors.bg.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill={color}
      fillOpacity="0.14"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 11C22.7909 11 21 12.7909 21 15C21 17.2091 22.7909 19 25 19C27.2091 19 29 17.2091 29 15C29 12.7909 27.2091 11 25 11ZM25 13C26.1046 13 27 13.8954 27 15C27 16.1046 26.1046 17 25 17C23.8954 17 23 16.1046 23 15C23 13.8954 23.8954 13 25 13ZM19 14C19 13.4477 18.5523 13 18 13H15L14.8237 13.0051C13.2489 13.0963 12 14.4023 12 16V25L12.0051 25.1763C12.0963 26.7511 13.4023 28 15 28H24L24.1763 27.9949C25.7511 27.9037 27 26.5977 27 25V22L26.9933 21.8834C26.9355 21.386 26.5128 21 26 21C25.4477 21 25 21.4477 25 22V25L24.9933 25.1166C24.9355 25.614 24.5128 26 24 26H15L14.8834 25.9933C14.386 25.9355 14 25.5128 14 25V16L14.0067 15.8834C14.0645 15.386 14.4872 15 15 15H18L18.1166 14.9933C18.614 14.9355 19 14.5128 19 14Z"
      fill={color}
    />
  </svg>
);

export default BannerIcon;
