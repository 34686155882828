import TextInput from 'components/forms/inputs/TextInput';
import { FC } from 'react';
import {
  BankAccountProps,
  BankAccountSortCodeProps
} from 'utils/interfaces/bank-account/bankAccount.interface';
import { BankAccountContainer } from '../styled';

interface UKBankDetailsProps {
  accountNumber: BankAccountProps;
  sortCode: BankAccountSortCodeProps;
}

const UKBankDetails: FC<UKBankDetailsProps> = ({ accountNumber, sortCode }) => {
  return (
    <BankAccountContainer data-testid="sp-bank-account-details">
      <TextInput
        label="Account number"
        placeholder="8 - 12  digit number"
        register={accountNumber.register}
        errorMessage={accountNumber.errorMessage}
        disabled={accountNumber.disabled}
        defaultValue={accountNumber.value}
        changeHandler={accountNumber.changeHandler}
        testingTagPage="bank-account-details"
      />

      <TextInput
        label="Sort code"
        register={sortCode.register}
        placeholder="6 digit number"
        errorMessage={sortCode.errorMessage}
        disabled={sortCode.disabled}
        defaultValue={sortCode.value}
        changeHandler={sortCode.changeHandler}
        mask="**-**-**"
        testingTagPage="bank-account-details"
      />
    </BankAccountContainer>
  );
};

export default UKBankDetails;
