import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const CompanyUserManagementActionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  padding: ${isMobile() ? '0 16px' : 0};
`;

export const CompanyUserManagementActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${isMobile() ? '100%' : '30%'};
  min-width: ${isMobile() ? 'auto' : '350px'};
`;

export const CompanyUserManagementActionTitle = styled.h3`
  margin: 0;
  color: ${themeColors.text.light.body};
  margin-bottom: ${isMobile() ? '16px' : '24px'};
`;

export const CompanyUserManagementActionDescription = styled.p`
  ${typography.bodyRegular};
  margin-bottom: 24px;
`;

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CompanyUserManagementActionButtonContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  gap: 16px;
  align-items: center;
  margin-bottom: 33px;
`;

export const CompanyUserManagementActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'auto'};
`;

export const CompanyUserManagementActionButtonText = styled.p`
  ${typography.bodySemiBold};
`;

