import { isMobile } from 'lib/helpers/mobile';
import { FC } from 'react';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import DesktopNavbar from './DesktopNavBar/DesktopNavBar';

const Navbar: FC = () => {
  return isMobile() ? <MobileNavBar /> : <DesktopNavbar />;
};

export default Navbar;
