export enum UserActionStatusesEnum {
  ACTIVATE = 'ACTIVATE',
  RESET = 'RESET',
  SUSPEND = 'SUSPEND',
  DEACTIVATE = 'DEACTIVATE'
}

export enum UserStatusesEnum {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  STAGED = 'STAGED',
  DEACTIVATED = 'DEACTIVATED',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  RECOVERY = 'RECOVERY',
  PROVISIONED = 'PROVISIONED',
  LOCKED_OUT = 'LOCKED_OUT'
}
