import { AsyncTask } from 'api/interfaces/asyncTask.interface';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { ICalculationTaskData, TaskDetails } from 'api/interfaces/funding-request/task.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import OrchestrationApi from 'api/orchestration/orchestration.api';
import SupplierPayApi from 'api/supplier-pay/supplierPay.api';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  COMPLETE_FUNDING_REQUEST,
  COMPLETE_FUNDING_REQUEST_FAILURE,
  COMPLETE_FUNDING_REQUEST_SUCCESS,
  FETCH_FUNDING_REQUEST_CALCULATION,
  FETCH_FUNDING_REQUEST_CALCULATION_FAILURE,
  FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS,
  UPDATE_FUNDING_REQUEST_CALCULATION,
  UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE,
  UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS
} from 'store/actions';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';

//===================================================
//                      API CALLS
//===================================================

const initFundingRequest = async (state: any) => {
  const orchestrationApi = new OrchestrationApi(store);
  const supplierPayApi = new SupplierPayApi(store);
  const invoiceIDs: string[] = state.app.invoices.map((invoice: Invoice) => invoice.id);
  if (!invoiceIDs.length) throw Error('No invoices to process for funding request calculation.');

  const fundingProgramIdIndex = (state.app.programs as ProgramConfig[]).findIndex(
    (program) =>
      program.baseType.includes(ProgramType.SP_FUNDING) ||
      program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
  );

  if (fundingProgramIdIndex === -1)
    throw Error('Can not request a calculation without a correct funding program identifier');

  //STEP 1: Requests the Pre-calculation for the Funding Request
  const fundingRequestCalculationResponse: AsyncTask =
    await orchestrationApi.fundingRequestCalculation(
      state.app.programs[fundingProgramIdIndex].id,
      invoiceIDs,
      state.app.activeCurrencyCode
    );
  //STEP 2: Get the Async Task Details - POLLING
  const calculationTaskCompletionResponse: TaskDetails<ICalculationTaskData> =
    await orchestrationApi.calculationTaskCompletionPoll(
      fundingRequestCalculationResponse.asyncTaskId
    );
  // Fallback in case of API failure.
  if (calculationTaskCompletionResponse.data.status === 'FAILED')
    throw Error(`Task Failed! - ${calculationTaskCompletionResponse.data.reason}`);

  // throw error is calculation task Id is not available
  if (!calculationTaskCompletionResponse.data.calculationTaskId)
    throw Error('Task failed - no Calculation Task ID');
  // STEP 3: Get the calculation response
  const calculationResponse: FundingRequestCalculation =
    await supplierPayApi.financeCalculationResults(
      calculationTaskCompletionResponse.data.calculationTaskId
    );

  return {
    status: calculationTaskCompletionResponse.data.status,
    fundingRequestCalculation: calculationResponse,
    fundingRequestModuleId: calculationTaskCompletionResponse.data.moduleId,
    fundingRequestAsyncTaskId: fundingRequestCalculationResponse.asyncTaskId,
    task: calculationTaskCompletionResponse
  };
};

const updateFundingRequest = async (state: any, action: any) => {
  const orchestrationApi = new OrchestrationApi(store);
  const supplierPayApi = new SupplierPayApi(store);
  const invoiceIDs: string[] = action.payload.map((p: any) => p.id);
  if (!invoiceIDs.length) throw Error('No invoices to process for funding request calculation.');

  const fundingProgramIdIndex = (state.app.programs as ProgramConfig[]).findIndex(
    (program) =>
      program.baseType.includes(ProgramType.SP_FUNDING) ||
      program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
  );

  if (fundingProgramIdIndex === -1)
    throw Error('Can not request a calculation without a correct funding program identifier');

  //STEP 1: Requests the Pre-calculation for the Funding Request
  const fundingRequestCalculationResponse: AsyncTask =
    await orchestrationApi.fundingRequestCalculation(
      state.app.programs[fundingProgramIdIndex].id,
      invoiceIDs,
      state.app.activeCurrencyCode
    );

  //STEP 2: Get the Async Task Details - POLLING
  let calculationTaskCompletionResponse: TaskDetails<ICalculationTaskData> =
    await orchestrationApi.calculationTaskCompletionPoll(
      fundingRequestCalculationResponse.asyncTaskId
    );

  // Fallback in case of API failure.
  if (
    calculationTaskCompletionResponse.data.status === 'FAILED' ||
    (calculationTaskCompletionResponse.data.reason !== null &&
      calculationTaskCompletionResponse.data.reason.includes('FAILED:'))
  )
    throw Error(`Task Failed! - ${calculationTaskCompletionResponse.data.reason}`);

  // throw error is calculation task Id is not available
  if (!calculationTaskCompletionResponse.data.calculationTaskId)
    throw Error('Task failed - no Calculation Task ID');

  // STEP 3: Get the calculation response
  const calculationResponse: FundingRequestCalculation =
    await supplierPayApi.financeCalculationResults(
      calculationTaskCompletionResponse.data.calculationTaskId
    );

  return {
    status: calculationTaskCompletionResponse.data.status,
    fundingRequestCalculation: calculationResponse,
    fundingRequestModuleId: calculationTaskCompletionResponse.data.moduleId,
    fundingRequestAsyncTaskId: fundingRequestCalculationResponse.asyncTaskId,
    task: calculationTaskCompletionResponse
  };
};

const completeFundingRequest = async (state: any) => {
  const orchestrationApi = new OrchestrationApi(store);
  const { calculationId } = state.fundingRequest.fundingRequestCalculation;
  const fundingRequestModuleId: string = state.fundingRequest.fundingRequestModuleId;
  const submitFinanceTaskResponse: AsyncTask = await orchestrationApi.submitFinanceRequest(
    fundingRequestModuleId,
    calculationId
  );

  if (!submitFinanceTaskResponse) throw Error('Error submitting Early Payment request');

  const financeTaskCompletionResponse: TaskDetails<ICalculationTaskData> =
    await orchestrationApi.calculationTaskCompletionPoll(submitFinanceTaskResponse.asyncTaskId);

  return financeTaskCompletionResponse;
};

//===================================================
//                      EPICS
//===================================================

const initFundingRequestEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_FUNDING_REQUEST_CALCULATION),
    switchMap(() =>
      from(initFundingRequest(state$.value)).pipe(
        map((payload) => {
          return { type: FETCH_FUNDING_REQUEST_CALCULATION_SUCCESS, payload };
        }),
        catchError((error) =>
          of({ type: FETCH_FUNDING_REQUEST_CALCULATION_FAILURE, payload: error.message })
        )
      )
    )
  );

const updateFundingRequestEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_FUNDING_REQUEST_CALCULATION),
    mergeMap((action) =>
      from(updateFundingRequest(state$.value, action)).pipe(
        map((payload) => {
          return { type: UPDATE_FUNDING_REQUEST_CALCULATION_SUCCESS, payload };
        }),
        catchError((error) =>
          of({ type: UPDATE_FUNDING_REQUEST_CALCULATION_FAILURE, payload: error.message })
        )
      )
    )
  );

const completeFundingRequestEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(COMPLETE_FUNDING_REQUEST),
    switchMap(() =>
      from(completeFundingRequest(state$.value)).pipe(
        map((payload) => {
          return { type: COMPLETE_FUNDING_REQUEST_SUCCESS, payload };
        }),
        catchError((error) =>
          of({ type: COMPLETE_FUNDING_REQUEST_FAILURE, payload: error.message })
        )
      )
    )
  );

export default combineEpics(
  initFundingRequestEpic$,
  completeFundingRequestEpic$,
  updateFundingRequestEpic$
);
