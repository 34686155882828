import EntityApi from 'api/entity/entity.api';
import { EntityBankAccount } from 'api/interfaces/entity/entity.interface';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  FETCH_BANK_ACCOUNT,
  FETCH_BANK_ACCOUNT_FAILURE,
  FETCH_BANK_ACCOUNT_SUCCESS,
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITY_DETAILS_FAILURE,
  FETCH_ENTITY_DETAILS_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchEntityDetailsById: (action: any) => Promise<any> = async (action) => {
  const entityApi = new EntityApi(store);
  const getEntityDetailsResponse = await entityApi.getEntityDetailsById(action.payload.id);
  if (!getEntityDetailsResponse) throw Error('Company details do not exist.');
  return {
    entityType: action.payload.entityType,
    entityDetails: getEntityDetailsResponse.data
  };
};
export const fetchBankAccountById: (action: any) => Promise<any> = async (action) => {
  const entityApi = new EntityApi(store);
  const getBankAccountByIdResponse: AxiosResponse<EntityBankAccount> =
    await entityApi.getBankAccountById(action.payload);
  if (!getBankAccountByIdResponse) throw Error('Bank account does not exist.');
  return getBankAccountByIdResponse;
};

//===================================================
//                      EPICS
//===================================================

const getEntityDetailsEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ENTITY_DETAILS),
    switchMap((action) =>
      from(fetchEntityDetailsById(action)).pipe(
        map((payload) => ({ type: FETCH_ENTITY_DETAILS_SUCCESS, payload })),
        catchError((error) => of({ type: FETCH_ENTITY_DETAILS_FAILURE, payload: error.message }))
      )
    )
  );

const getBankAccountByIdEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_BANK_ACCOUNT),
    switchMap((action) =>
      from(fetchBankAccountById(action)).pipe(
        map((payload) => ({ type: FETCH_BANK_ACCOUNT_SUCCESS, payload })),
        catchError((error) => of({ type: FETCH_BANK_ACCOUNT_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(getEntityDetailsEpic$, getBankAccountByIdEpic$);
