import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { Select } from '@mui/material';

export const NavbarLogoutText = styled.p`
  display: flex;
  align-items: center;
  padding: 10px;
  height: 34px;
  color: ${themeColors.text.light.body};
  cursor: pointer;
  font-size: ${fontSizes.regular};
  letter-spacing: 0.00938em;
  line-height: 1.5;
`;

export const NavigationPlatformContainer = styled.div`
 display: flex;
 align-items: center;
 padding-top: 4px;
`;

export const NavigationPlatformText = styled.span`
  ${typography.captionRegular};
  width: 100%;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 28px;
  margin: 0 auto;

  .MuiSelect-select {
    ${typography.captionRegular};
    font-family: 'Source Sans Pro', sans-serif;
    background-color: ${themeColors.white};
    color: ${themeColors.text.light.body};
    padding-right: 8px!important;
    padding: 8px 2px;  
  }
  
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const NavigationButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const NavigationButtonText = styled.p`
 ${typography.bodySmallSemiBold};
`;

export const NavigationLinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;


