/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Snackbar } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { UPDATE_REQUEST_ERROR } from 'store/actions';

const ErrorSnackBar: FC = () => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const errorMessage: string = useSelector((state: RootStateOrAny) => state.error.error);

  useEffect(() => {
    if (errorMessage.length > 0) {
      setSnackbarOpen(true);
      setTimeout(() => {
        snackbarOff();
      }, 10000);
    }
  }, [errorMessage]);

  const snackbarOff: () => void = () => {
    setSnackbarOpen(false);
    dispatch({ type: UPDATE_REQUEST_ERROR, payload: '' });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackbarOpen}
      onClose={(event, reason) => {
        snackbarOff();
      }}
      data-testid="sp-snackbar"
    >
      <Alert
        onClose={snackbarOff}
        style={{ backgroundColor: themeColors.bg.error, color: themeColors.white }}
        sx={{ width: '100%' }}
        severity="error"
        icon={false}
      >
        <strong>Error:</strong>{' '}
        {errorMessage || 'Something went wrong. Please try again or contact an administrator.'}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackBar;
