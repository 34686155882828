/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from '@mui/material';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import { layoutSizes } from 'assets/theme/style';
import DataGrid from 'components/DataGrid';
import {
  invoiceLedgerLineItemTableHeaders,
  poLedgerLineItemTableHeaders
} from 'lib/data/ledger/ledgerLineItemHeaders';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  LedgerLineItemContainer,
  LedgerLineItemMobileContainer,
  LedgerLineItemMobileItemBold,
  LedgerLineItemMobileItemLeft,
  LedgerLineItemMobileItemRegular,
  LedgerLineItemMobileItemRight,
  LedgerLineItemMobileRow,
  LedgerLineItemMobileWrapper,
  LedgerLineItemsWrapper
} from './styled';
import OpenAccountInvoiceApi from 'api/open-account/invoices/invoices';
import { store } from 'store';
import OpenAccountPurchaseOrdersApi from 'api/open-account/purchase-orders/purchaseOrders';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import LedgerDetailLineItemsDialog from './LedgerDetailLineItemsDialog';
import OpenAccountLogisticsApi from 'api/open-account/logistics/logistics';

interface LedgerDetailLineItemsProps {
  lineItems: LedgerLineItem[];
  currencyCode: string;
  type: keyof typeof AssetEnum;
  assetId: string;
}

interface ILedgerLineItemMatchingResult {
  isDiscrepant: boolean;
  lineItemNumber: string;
}

const LedgerDetailLineItems: FC<LedgerDetailLineItemsProps> = ({
  lineItems,
  currencyCode,
  type,
  assetId
}) => {
  const layoutRef: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const [layoutWidth, setLayoutWidth]: [number, Dispatch<SetStateAction<number>>] =
    useState<number>(0);
  const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
    useState<DataGridRowItem[]>([]);
  const [lineItemForView, setLineItemForView] = useState<LedgerLineItem | null>(null);

  const lineItemClickHandler: (lineItemNumber: string) => void = (lineItemNumber) => {
    setLineItemForView(lineItems.find((li) => li.lineItemNumber === lineItemNumber) || null);
  };

  const headers: DataGridHeaderItem[] =
    type === AssetEnum.INVOICE || type === AssetEnum.LOGISTIC
      ? invoiceLedgerLineItemTableHeaders(lineItemClickHandler, type)
      : poLedgerLineItemTableHeaders;

  useEffect(() => {
    getLayoutSize();
    window.addEventListener('resize', getLayoutSize);

    if (type === AssetEnum.LOGISTIC) getLogisticsMatchingResults();
    if (type === AssetEnum.INVOICE) getInvoiceMatchingResults();
    if (type === AssetEnum.PURCHASE_ORDER) getPurchaseOrderMatchingResults();

    return () => {
      window.removeEventListener('resize', getLayoutSize);
    };
  }, []);

  const getInvoiceMatchingResults: () => void = async () => {
    const openAccountInvoicesApi = new OpenAccountInvoiceApi(store);
    const result = await openAccountInvoicesApi.getOpenAccountInvoiceMatchingResults(assetId);

    const mappedData = result.data.invoiceLineItems.map(({ lineItemNumber, matchingStatus }) => ({
      lineItemNumber: lineItemNumber || '',
      isDiscrepant: matchingStatus === 'DISCREPANT'
    }));

    setData(mapLineItemsToGridModel(mappedData));
  };

  const getPurchaseOrderMatchingResults: () => void = async () => {
    const openAccountPurchaseOrderApi = new OpenAccountPurchaseOrdersApi(store);
    const result = await openAccountPurchaseOrderApi.getOpenAccountPurchaseOrderMatchingById(
      assetId
    );

    const mappedData = result.data.purchaseOrderLineItems.map(
      ({ lineItemNumber, isDiscrepant }) => ({ lineItemNumber, isDiscrepant })
    );

    setData(mapLineItemsToGridModel(mappedData));
  };

  const getLogisticsMatchingResults: () => void = async () => {
    const openAccountLogisticsApi = new OpenAccountLogisticsApi(store);
    const result = await openAccountLogisticsApi.getOpenAccountLogisticsMatchingById(assetId);

    const mappedData = result.data.logisticLineItems.map(
      ({
        lineItemNumber,
        matchingStatus
      }: {
        lineItemNumber: string | null;
        matchingStatus: string | null;
      }) => ({
        lineItemNumber: lineItemNumber || '',
        isDiscrepant: matchingStatus === 'DISCREPANT'
      })
    );

    setData(mapLineItemsToGridModel(mappedData));
  };

  const getLayoutSize: () => void = () => {
    const newWidth: number = layoutRef?.current?.clientWidth || 0;
    setLayoutWidth(newWidth);
  };

  const mapLineItemsToGridModel: (
    matchingResults: ILedgerLineItemMatchingResult[]
  ) => DataGridRowItem[] = (matchingResults) =>
    lineItems.map((line: LedgerLineItem) => ({
      isDiscrepant:
        matchingResults.find((r) => r.lineItemNumber === line.lineItemNumber)?.isDiscrepant ||
        false,
      lineNumber: line.lineItemNumber,
      lineItemPrincipalStatus: line.lineItemPrincipalStatus,
      poNumber: line.poNumber,
      itemNumber: line.sku,
      itemName: line.shortDescription,
      itemMeasure: line.unitOfMeasure,
      currency: currencyCode,
      quantity: line.quantity,
      amount: formatNumber(line.amount === null ? 0 : line.amount || 0, 2),
      availableAmount: formatNumber(
        line.availableAmount === null ? 0 : line.availableAmount || 0,
        2
      ),
      availableQuantity: line.availableQuantity,
      unitPrice: formatNumber(line.unitPrice === null ? 0 : line.unitPrice || 0, 2),
      extendedAmount: formatNumber(
        line.extendedPriceBeforeTax === null ? 0 : line.extendedPriceBeforeTax || 0,
        2
      ),
      hasPackagingInfo: Boolean(line.packaging)
    }));

  const renderTable: () => JSX.Element = () =>
    layoutWidth < layoutSizes.maxMobileWidth ? (
      <LedgerLineItemMobileWrapper>
        {data.map((data, index, array) => (
          <LedgerLineItemMobileRow
            key={index}
            data-automation-id="ledger-detail-line-items-div-line-items-mobile"
          >
            <LedgerLineItemMobileContainer>
              <LedgerLineItemMobileItemLeft>
                <LedgerLineItemMobileItemBold data-automation-id="ledger-detail-line-items-p-line-item-number-mobile">
                  {data.itemNumber}
                </LedgerLineItemMobileItemBold>
                <LedgerLineItemMobileItemRegular data-automation-id="ledger-detail-line-items-p-line-item-name-mobile">
                  {data.itemName}
                </LedgerLineItemMobileItemRegular>
              </LedgerLineItemMobileItemLeft>
              <LedgerLineItemMobileItemRight>
                <LedgerLineItemMobileItemBold data-automation-id="ledger-detail-line-items-p-line-item-amount-mobile">
                  {`${CurrencySymbolsLookUp[data.currency]}${data.extendedAmount}`}
                </LedgerLineItemMobileItemBold>
                <LedgerLineItemMobileItemRegular data-automation-id="ledger-detail-line-items-p-line-item-price-mobile">
                  Unit Price: {`${CurrencySymbolsLookUp[data.currency]}${data.unitPrice}`}
                </LedgerLineItemMobileItemRegular>
              </LedgerLineItemMobileItemRight>
            </LedgerLineItemMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LedgerLineItemMobileRow>
        ))}
      </LedgerLineItemMobileWrapper>
    ) : (
      <DataGrid
        headers={headers}
        data={data}
        ariaLabel="Ledger Line Items Table"
        ariaCaption="A table that shows the line items."
        totalRows={data.length}
        exportFilename={''}
        fixedHeader
        dataAutomationTag="ledger-detail-table-ledger-line-items"
        hasActions
        rowsPerPage={200}
      />
    );

  return (
    <LedgerLineItemsWrapper ref={layoutRef}>
      <LedgerLineItemContainer>{layoutWidth > 0 && renderTable()}</LedgerLineItemContainer>
      {Boolean(lineItemForView) && Boolean(lineItemForView?.packaging) && (
        <FullViewDialog
          open={Boolean(lineItemForView)}
          clickHandler={() => setLineItemForView(null)}
          dialogContent={
            <LedgerDetailLineItemsDialog
              packaging={lineItemForView?.packaging}
              lineItemNumber={lineItemForView?.lineItemNumber || ''}
            />
          }
        />
      )}
    </LedgerLineItemsWrapper>
  );
};

export default LedgerDetailLineItems;
