import { OnboardingActionStatusEnum } from 'lib/enums/onboarding/onboarding.enum';

export default class OnboardingActionEventPut {
  constructor(
    public comment: string,
    public rawState: string,
    public status: keyof typeof OnboardingActionStatusEnum,
    public programId?: string
  ) {}
}
