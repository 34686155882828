import { DocumentStatus } from "lib/enums/documentStatus.enum";

export const getPOStatusTooltipText: (status: string) => string = (status) => {
  switch (status.toLowerCase()) {
    case DocumentStatus.closed:
      return "This purchase order is closed which usually means it's been fulfilled.";
    case DocumentStatus.cancelled:
      return 'This purchase order has been cancelled.';
    case DocumentStatus.expired:
      return 'This purchase order has expired. Your buyer needs to share a new one. ';

    default:
      return '';
  }
};

export const getInvoiceStatusTooltipText: (status: string) => string = (status) => {
  switch (status.toLowerCase()) {
    case DocumentStatus.closed:
      return "This invoice is closed which usually means it's been paid in full.";
    case DocumentStatus.cancelled:
      return 'This invoice has been cancelled.';
    case DocumentStatus.expired:
      return "This invoice has expired, so payments can't be made against it anymore.";
    case DocumentStatus.approved:
      return 'This invoice is approved for payment.';

    default:
      return '';
  }
};
