export const GET_PO_MATCHING_RESULTS =
  '@@frontend/store/reducers/purchaseOrder/GET_PO_MATCHING_RESULTS';
export const GET_PO_MATCHING_RESULTS_SUCCESS =
  '@@frontend/store/reducers/purchaseOrder/GET_PO_MATCHING_RESULTS_SUCCESS';
export const GET_PO_MATCHING_RESULTS_FAILURE =
  '@@frontend/store/reducers/purchaseOrder/GET_PO_MATCHING_RESULTS_FAILURE';
export const SET_PO_FOR_VIEW = '@@frontend/store/reducers/purchaseOrder/SET_PO_FOR_VIEW';
export const POST_PO_COMMENTS = '@@frontend/store/reducers/purchaseOrder/POST_PO_COMMENTS';
export const POST_PO_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/purchaseOrder/POST_PO_COMMENTS_SUCCESS';
export const POST_PO_COMMENTS_FAILURE =
  '@@frontend/store/reducers/purchaseOrder/POST_PO_COMMENTS_FAILURE';
export const GET_PO_COMMENTS = '@@frontend/store/reducers/purchaseOrder/GET_PO_COMMENTS';
export const GET_PO_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/purchaseOrder/GET_PO_COMMENTS_SUCCESS';
export const GET_PO_COMMENTS_FAILURE =
  '@@frontend/store/reducers/purchaseOrder/GET_PO_COMMENTS_FAILURE';
export const RESET_PO_LOADING =
  '@@frontend/store/reducers/purchaseOrder/RESET_PO_LOADING';
