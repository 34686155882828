import StoreBase from 'api/base/storeBase';
import { ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { AxiosResponse } from 'axios';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { DateTime } from 'luxon';
import { IAppState } from 'store/epics/app';

const _PATH: string = '/ledger/supplier-pay/odata/invoices';

export default class InvoiceApi extends StoreBase {
  public getInvoiceLedger: (programIds: string[]) => Promise<AxiosResponse<ODataLedgerInvoices>> =
    async (programIds) =>
      this.axiosInstance.get<ODataLedgerInvoices>(
        `${_PATH}?$skip=0&$top=5000&$count=true&$filter=${this.odataFilterBuilderForObjectsInArray(
          'linkedPrograms',
          'programId',
          programIds
        )}`
      );

  public getFilterInvoiceLedger: (
    programIds: string[],
    rowsPerPage: number | null,
    page: number | null,
    filter: string,
    orderBy: string
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (
    programIds,
    rowsPerPage,
    page,
    filter,
    orderBy
  ) =>
    this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$count=true&$top=${
        rowsPerPage || 5000
      }&$orderby=${orderBy}&$filter=${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}${filter || ''}&$skip=${page && rowsPerPage !== null ? rowsPerPage * page : 0}`
    );

  public getInvoiceLedgerByFundingId: (
    fundingId: string,
    programId?: string
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (fundingId, programId) => {
    const fundingProgramId =
      (this.app as IAppState).selectedProgramByCurrency?.programDetails.find((program) =>
        program.baseType.includes(ProgramType.SP_FUNDING)
      )?.id || '';
    return this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$skip=0&$top=5000&$count=true&$filter=linkedFundings/any(d: d/fundingId in ('${fundingId}')) And linkedProgram/programId eq '${
        programId || fundingProgramId
      }'`
    );
  };

  public fetchEligibleAcceptedInvoices: (
    programIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (programIds) =>
    await this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$skip=0&$top=5000&$filter=linkedProgram/invoiceStatus eq 'ACCEPTED' and ${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );

  public fetchOpenInvoices: (programIds: string[]) => Promise<AxiosResponse<ODataLedgerInvoices>> =
    async (programIds) =>
      await this.axiosInstance.get<ODataLedgerInvoices>(
        `${_PATH}?$skip=0&$top=5000&$filter=linkedProgram/invoiceStatus eq 'OPEN' and ${this.odataFilterBuilderForObjectsInArray(
          'linkedPrograms',
          'programId',
          programIds
        )}`
      );

  public getApprovedInvoices: (
    programIds: string[],
    currency: string
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (programIds, currency) =>
    await this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$skip=0&$top=5000&$filter=linkedProgram/invoiceStatus eq 'APPROVED' and invoiceCurrency eq '${currency}' and ${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );

  public getInvoiceLedgerLinkedFundingsByFundingIds: (
    programIds: string[],
    fundingIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (programIds, fundingIds) => {
    const fundingIdsFormatted: string = fundingIds.map((id) => `${id}`).join("','");
    return this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$skip=0&$top=5000&$count=true&$filter=linkedFundings/any(d: d/fundingId in ('${fundingIdsFormatted}')) and ${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );
  };

  public getInvoiceById: (invoiceId: string) => Promise<AxiosResponse<ODataLedgerInvoices>> =
    async (invoiceId) =>
      this.axiosInstance.get<ODataLedgerInvoices>(`${_PATH}?$filter=invoiceId eq '${invoiceId}'`);

  public getInvoicesByEligibilityStatus: (
    eligibilityStatuses: string[],
    programIds: string[],
    paymentDueDate: string
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (
    eligibilityStatuses,
    programIds,
    paymentDueDate
  ) => {
    const eligibilityStatusFilter: string = eligibilityStatuses
      .map((s) => `linkedProgram/tags/any(tag:contains(tag, '${s}'))`)
      .join(' OR ');
    return this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$top=1000&$filter=linkedProgram/invoicestatus eq 'ACCEPTED' and (${eligibilityStatusFilter}) AND data/paymentDueDate lt ${paymentDueDate} and ${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );
  };

  public getApprovedInvoicesForNext12Weeks: (
    programIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (programIds) => {
    const now = DateTime.now().startOf('week').toISODate();
    const twelveWeeks = DateTime.now().plus({ weeks: 12 }).endOf('week').toISODate();

    return this.axiosInstance.get<ODataLedgerInvoices>(
      `${_PATH}?$top=1000&$filter=linkedProgram/invoicestatus eq 'APPROVED' AND data/paymentDueDate gt ${now} AND data/paymentDueDate lt ${twelveWeeks} and ${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );
  };
}
