import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const Pending3Icon: FC<IconSvgProps> = ({
  height = '16',
  width = '16',
  color = themeColors.icon.warning
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM8.37506 2.00371C11.3906 2.19018 13.8095 4.60943 13.9959 7.62494C14.0087 7.83165 13.8396 8 13.6325 8H8.375C8.16789 8 8 7.83211 8 7.625V2.36715C8 2.16004 8.16835 1.99093 8.37506 2.00371Z"
      fill={color}
    />
  </svg>
);

export default Pending3Icon;
