import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayableItem } from 'api/interfaces/ledger/ledgerPayableItems.interface';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import {
  IOnboardingArtifact,
  IOnboardingEntity,
  IOnboardingEntityEvent
} from 'api/interfaces/onboarding/onboarding.interface';
import { Program, ProgramConfig } from 'api/interfaces/program/program.interface';
import { CurrencyCodeEnum } from 'lib/enums/currencyCode.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import {
  GET_FUNDINGS,
  GET_FUNDINGS_FAILURE,
  GET_FUNDINGS_SUCCESS,
  GET_G2F_CONNECTIVITY,
  GET_G2F_CONNECTIVITY_FAILURE,
  GET_G2F_CONNECTIVITY_SUCCESS,
  INITIALISE_APP,
  INITIALISE_APP_FAILURE,
  INITIALISE_APP_SUCCESS,
  NAV_BACK_VISIBLE,
  REINITIALISE_APP,
  SET_REDIRECT_PATH,
  SET_TENANT,
  TRIGGER_SNACKBAR,
  UPDATE_ACCESS_TOKEN,
  UPDATE_ACCOUNTING_CONNECT,
  UPDATE_ACTIVE_NAV_BUTTON,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE,
  UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_CODE,
  UPDATE_CURRENT_PATH,
  UPDATE_PAGE_TITLE,
  UPDATE_PLATFORM_AVAILABILITY,
  UPDATE_SELECTED_PROGRAM,
  UPDATE_USERNAME
} from 'store/actions';
import { IBaseRates } from 'utils/interfaces/base-rates/baseRates.interface';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { ReducerAction, StateBase } from '.';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

interface AppReducerStateProps extends StateBase {
  owner: EntityOwner | null;
  g2fAccessToken: string | null | undefined;
  g2fCustUID: string | null;
  g2fConnection: any;
  baseRates: IBaseRates | null;
  programs: ProgramConfig[];
  currentPath: string;
  pageTitle: string;
  username: string | null;
  userSubId: string | null;
  activeCurrencyCode: string;
  programID: string | null;
  invoices: Invoice[];
  invoiceLedger: ILedgerInvoice[];
  purchaseOrderLedger: ILedgerPurchaseOrder[];
  entities: EntityOwner[] | null;
  advancedInvoicesTotal: number;
  acceptedEligibleInvoicesTotal: number;
  acceptedEligibleInvoices: ILedgerInvoice[];
  approvedInvoicesTotal: number;
  approvedInvoices: ILedgerInvoice[];
  earlyPaymentsReceivedValue: number;
  earlyPaymentsTenorValue: number;
  snackbarData: SnackbarData | null;
  snackbarOpen: boolean;
  navBackVisible: boolean;
  activeNavButton: NavigationItem;
  fundings: Funding[];
  countries: Record<string, string> | null;
  currencies: any;
  allCurrencies: any;
  program: ProgramConfig | null;
  payments: LedgerPayment[];
  paymentsTypeTrade: LedgerPayment[];
  payableItemsTypeTrade: LedgerPayableItem[];
  payableItemsTypeMaturing: LedgerPayableItem[];
  programType: keyof typeof ProgramType | null;
  accessToken: string | null;
  tenant: string | null;
  tenants: string[];
  tokenExpiryMillis: number;
  onboardingComplete: boolean | null;
  onboardingInitData: IOnboardingEntityEvent | null;
  entityOnboardingActionsStateByProgram: IOnboardingEntityEvent | null;
  legalEntityInit: IOnboardingEntity | null;
  accountingConnected: boolean;
  acceptTermsAndConditionsArtifactPdfBlob: ArrayBuffer | null;
  acceptTermsAndConditionsArtifactPdfName: string;
  onboardingBankAccountArtifacts: IOnboardingArtifact[] | null;
  appLoadFromLinkPath: string | null;
  redirectedPath: string | null;
  g2fFeatureHidden: boolean;
  platformAvailable: boolean;
  programIds: string[];
  programsWithDetails: ProgramConfig[];
  allProgramsWithDetails: ProgramConfig[];
  groupedPrograms: IGroupedProgram[];
  selectedProgramByCurrency: IGroupedProgram | null;
  discrepantInvoicesTotal: number;
  discrepantInvoicesTotalCount: number;
  pendingInvoicesTotal: number;
  pendingInvoicesTotalCount: number;
  internalLoading: boolean;
  isUserAdmin: boolean;
}

export interface SnackbarData {
  title: JSX.Element;
  message: JSX.Element;
  leftIcon: JSX.Element;
  closeIcon?: JSX.Element;
  topAligned?: boolean;
  centerAlignedClear?: boolean;
  type?: 'success' | 'error' | 'warning';
}

const DEFAULT_STATE: AppReducerStateProps = {
  owner: null,
  g2fAccessToken: null,
  g2fCustUID: null,
  g2fConnection: null,
  baseRates: null,
  programs: [],
  currentPath: '/',
  pageTitle: '',
  username: null,
  userSubId: null,
  activeCurrencyCode: '',
  programID: null,
  loading: false,
  invoices: [],
  invoiceLedger: [],
  purchaseOrderLedger: [],
  programIds: [],
  entities: null,
  error: '',
  advancedInvoicesTotal: 0,
  acceptedEligibleInvoicesTotal: 0,
  acceptedEligibleInvoices: [],
  approvedInvoicesTotal: 0,
  approvedInvoices: [],
  snackbarData: null,
  snackbarOpen: false,
  navBackVisible: false,
  activeNavButton: { text: 'Home', path: '' },
  fundings: [],
  countries: null,
  allCurrencies: null,
  currencies: null,
  earlyPaymentsReceivedValue: 0,
  earlyPaymentsTenorValue: 0,
  program: null,
  paymentsTypeTrade: [],
  payments: [],
  payableItemsTypeTrade: [],
  payableItemsTypeMaturing: [],
  programType: null,
  accessToken: null,
  tenant: null,
  tenants: [],
  tokenExpiryMillis: 0,
  onboardingComplete: null,
  onboardingInitData: null,
  legalEntityInit: null,
  accountingConnected: false,
  acceptTermsAndConditionsArtifactPdfBlob: null,
  acceptTermsAndConditionsArtifactPdfName: '',
  entityOnboardingActionsStateByProgram: null,
  onboardingBankAccountArtifacts: null,
  appLoadFromLinkPath: null,
  redirectedPath: null,
  g2fFeatureHidden: false,
  platformAvailable: true,
  programsWithDetails: [],
  allProgramsWithDetails: [],
  groupedPrograms: [],
  selectedProgramByCurrency: null,
  discrepantInvoicesTotal: 0,
  discrepantInvoicesTotalCount: 0,
  pendingInvoicesTotal: 0,
  pendingInvoicesTotalCount: 0,
  internalLoading: false,
  isUserAdmin: false
};

const appReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case UPDATE_CURRENT_PATH:
      return {
        ...state,
        currentPath: payload.path
      };
    case SET_REDIRECT_PATH:
      return {
        ...state,
        redirectedPath: payload
      };
    case UPDATE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: payload.pageTitle
      };
    case UPDATE_ACTIVE_NAV_BUTTON:
      return {
        ...state,
        activeNavButton: payload
      };
    case UPDATE_USERNAME:
      return {
        ...state,
        username: payload.username
      };
    case UPDATE_CURRENCY_CODE:
      return {
        ...state,
        activeCurrencyCode: payload.currencyCode
      };
    case INITIALISE_APP:
      return {
        ...state,
        loading: true
      };
    case REINITIALISE_APP:
      return {
        ...state,
        loading: true
      };
    case INITIALISE_APP_SUCCESS:
      const {
        owner,
        activeCurrencyCode,
        g2fAccessToken,
        g2fCustUID,
        g2fConnection,
        baseRates,
        invoices,
        invoiceLedger,
        purchaseOrderLedger,
        entities,
        program,
        programID,
        advancedInvoicesTotal,
        acceptedEligibleInvoices,
        acceptedEligibleInvoicesTotal,
        approvedInvoices,
        approvedInvoicesTotal,
        fundings,
        countries,
        currencies,
        payments,
        programCurrencies,
        earlyPaymentsReceivedValue,
        earlyPaymentsTenorValue,
        paymentsTypeTrade,
        payableItemsTypeTrade,
        programType,
        onboardingComplete,
        onboardingInitData,
        legalEntityInit,
        acceptTermsAndConditionsArtifactPdfBlob,
        acceptTermsAndConditionsArtifactPdfName,
        entityOnboardingActionsStateByProgram,
        onboardingBankAccountArtifacts,
        g2fFeatureHidden,
        platformAvailable,
        payableItemsTypeMaturing,
        programIds,
        programs,
        programsWithDetails,
        allProgramsWithDetails,
        groupedPrograms,
        discrepantInvoicesTotal,
        discrepantInvoicesTotalCount,
        pendingInvoicesTotal,
        pendingInvoicesTotalCount,
        selectedProgramByCurrency,
        accessToken
      } = payload;
      const mappedCountries: Record<any, any> = countries?.content
        .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        .reduce((map: any, obj: any, i: any) => {
          if (i === 0) map[''] = '';
          map[obj.isoCode] = obj.name;
          return map;
        }, {});
      const mappedCurrencies: Record<any, any> = currencies
        .filter((c: any) => programCurrencies.indexOf(c.isoCode) > -1)
        .sort((a: any, b: any) => (a.isoCode > b.isoCode ? 1 : -1))
        .reduce((map: any, obj: any, i: any) => {
          if (i === 0) map[''] = '';
          map[obj.isoCode] = obj.name;
          return map;
        }, {});

      const allMappedCurrencies: Record<any, any> = currencies
        .sort((a: any, b: any) => (a.isoCode > b.isoCode ? 1 : -1))
        .reduce((map: any, obj: any, i: any) => {
          if (i === 0) map[''] = '';
          map[obj.isoCode] = obj.name;
          return map;
        }, {});

      return {
        ...state,
        owner,
        activeCurrencyCode,
        g2fAccessToken,
        g2fCustUID,
        g2fConnection,
        baseRates,
        programs,
        invoices,
        invoiceLedger,
        purchaseOrderLedger,
        program,
        entities,
        programID,
        advancedInvoicesTotal,
        acceptedEligibleInvoices,
        acceptedEligibleInvoicesTotal,
        approvedInvoices,
        approvedInvoicesTotal,
        earlyPaymentsReceivedValue,
        earlyPaymentsTenorValue,
        fundings,
        countries: mappedCountries,
        currencies: mappedCurrencies,
        allCurrencies: allMappedCurrencies,
        payments,
        paymentsTypeTrade,
        payableItemsTypeTrade,
        payableItemsTypeMaturing,
        programType,
        loading: false,
        error: '',
        onboardingComplete,
        onboardingInitData,
        legalEntityInit,
        acceptTermsAndConditionsArtifactPdfBlob,
        acceptTermsAndConditionsArtifactPdfName,
        entityOnboardingActionsStateByProgram,
        onboardingBankAccountArtifacts,
        g2fFeatureHidden,
        platformAvailable,
        programIds,
        programsWithDetails,
        allProgramsWithDetails,
        groupedPrograms,
        pendingInvoicesTotal,
        pendingInvoicesTotalCount,
        discrepantInvoicesTotal,
        discrepantInvoicesTotalCount,
        selectedProgramByCurrency,
        accessToken
      };
    case INITIALISE_APP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY:
      return {
        ...state,
        internalLoading: true
      };
    case UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_SUCCESS:
      return {
        ...state,
        ...payload,
        internalLoading: false
      };
    case UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY_FAILURE:
      return {
        ...state,
        error: payload,
        internalLoading: false
      };
    case TRIGGER_SNACKBAR:
      return {
        ...state,
        snackbarOpen: payload.open,
        snackbarData: payload.data
      };
    case NAV_BACK_VISIBLE:
      return {
        ...state,
        navBackVisible: payload.visible
      };
    case UPDATE_SELECTED_PROGRAM:
      const matchedProgramIndex: number = state.programs.findIndex((p) => p.id === payload);
      const matchedProgram: Program =
        matchedProgramIndex > -1 ? state.programs[matchedProgramIndex] : programs[0];
      return {
        ...state,
        program: matchedProgram,
        programID: matchedProgram.id,
        loading: true
      };
    case UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        tenant: payload.tenant,
        tenants: payload.tenants,
        username: payload.username,
        userSubId: payload.userSubId,
        tokenExpiryMillis: payload.tokenExpiryMillis,
        isUserAdmin: payload.isUserAdmin
      };
    case SET_TENANT:
      return {
        ...state,
        tenant: payload
      };
    case UPDATE_ACCOUNTING_CONNECT:
      return {
        ...state,
        accountingConnected: payload
      };
    case GET_FUNDINGS:
      return {
        ...state
      };
    case UPDATE_PLATFORM_AVAILABILITY:
      return {
        platformAvailable: payload
      };
    case GET_FUNDINGS_SUCCESS:
      return {
        ...state,
        fundings: payload,
        loading: false,
        error: ''
      };
    case GET_FUNDINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case GET_G2F_CONNECTIVITY:
      return {
        ...state
      };
    case GET_G2F_CONNECTIVITY_SUCCESS:
      return {
        ...state,
        g2fConnection: payload,
        loading: false,
        error: ''
      };
    case GET_G2F_CONNECTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};

export { appReducer };
export type { AppReducerStateProps };
