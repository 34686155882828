import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { LedgerSummaryItem } from 'components/ledger/LedgerDetail/LedgerDetailSummary';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { DateTime } from 'luxon';
import LedgerHeaderBuilder from 'utils/classes/ledger/ledgerHeaderBuilder';
import LedgerSummaryItemBuilder from 'utils/classes/ledger/ledgerSummaryItemBuilder';

export const mapPurchaseOrderToLedgerSummaryFormat: (
  po: ILedgerPurchaseOrder
) => LedgerSummaryItem[] = (po) => [
  new LedgerSummaryItemBuilder(
    'Created date',
    'createdDate',
    po.data.createdDate ? formatDateTime(po.data.createdDate, _DATE_FORMAT) : '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Expiry date',
    'expiryDate',
    po.data.expiryDate
      ? formatDateTime(
          DateTime.fromFormat(po.data.expiryDate || '', _DATE_FORMAT).toISO(),
          _DATE_FORMAT
        )
      : '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Payment terms',
    'paymentTerms',
    po.data.paymentTerms || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder('Supplier', 'supplierName', po.data.supplierName || '-', '', '')
];

export const mapPurchaseOrderHeaderData: (po: ILedgerPurchaseOrder) => LedgerHeaderBuilder = (
  po
) => {
  return new LedgerHeaderBuilder(
    po.data.poNumber || '-',
    'Total available PO amount',
    po.data.amount || 0,
    po.data.currency || '-',
    po.linkedPrograms[0]?.purchaseOrderStatus || '',
    po.linkedPrograms[0]?.isDiscrepant || false
  );
};

export const mapPONumericDataToLedgerSummaryFormat: (
  po: ILedgerPurchaseOrder
) => LedgerSummaryItem[] = (po) => [
  new LedgerSummaryItemBuilder('Original PO amount', 'amount', po.data.amount || 0, '', ''),
  new LedgerSummaryItemBuilder(
    'Consumed amount',
    'consumedAmount',
    po.consumption.fulfilledAmount || 0,
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Total available amount',
    'netAmount',
    po.consumption.availableAmount || 0,
    '',
    ''
  )
];
