import Accordion from '@mui/material/Accordion';
import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface Type {
  type?: keyof typeof LedgerTypes;
}

export const LedgerDetailLogisticContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;
`;

export const LedgerDetailLogisticRow = styled.div<Type>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ type }) => (type === LedgerTypes.INVOICE ? '32px' : '')};
`;

export const LedgerDetailLogisticRowNested = styled.div<Type>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ type }) => (type === LedgerTypes.INVOICE ? '32px' : '')};
  padding: 0 48px;
`;

export const LedgerDetailLogisticSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 50%;
  width: 100%;
  margin-bottom: 24px;
  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const LedgerDetailLogisticLabel = styled.h5``;

export const LedgerDetailLogisticValue = styled.p`
  display: flex;
  ${typography.bodyRegular};
  text-align: left;
`;

export const LedgerDetailLogisticInfo = styled.h3``;

export const LedgerDetailAdditionalInformationContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LedgerDetailLogisticsTextSemiBold = styled.p`
  ${typography.bodySemiBold};
  font-size: ${fontSizes.regular};
`;
export const LedgerDetailLogisticsTextRegular = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.regular};
  text-align: end;
`;

export const LedgerDetailLogisticsKeyValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  width: 50%;
  :nth-child(odd) {
    padding-right: 24px;
  }
`;

export const SubAccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 16px !important;
    background-color: transparent;
    padding: 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 12px;
    }
    .MuiAccordionSummary-root {
      background-color: ${themeColors.bg.light.surface};
      flex-direction: row-reverse;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.light.default};
  }
  .MuiButtonBase-root {
    width: 100%;
    border-radius: 6px;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
    transform: rotate(-90deg);
    margin-right: 8px;
    &.Mui-expanded {
      transform: rotate(360deg);
      margin-right: 8px;
    }
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '0 32px' : '0 48px'};
    ${typography.bodyRegular};
    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 16px !important;
    background-color: transparent;
    padding: 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 12px;
    }
    .MuiAccordionSummary-root {
      background-color: ${themeColors.bg.light.surface};
      flex-direction: row-reverse;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.light.default};
  }
  .MuiButtonBase-root {
    width: 100%;
    border-radius: 6px;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
    transform: rotate(-90deg);
    margin-right: 8px;
    &.Mui-expanded {
      transform: rotate(360deg);
      margin-right: 8px;
    }
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '8px 16px' : '8px 0 !important'};
    ${typography.bodyRegular};
    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;
