import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const DownloadIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '24px',
  width = '24px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00001 11.4142C5.60949 11.0237 5.60949 10.3905 6.00002 9.99997C6.39054 9.60945 7.0237 9.60945 7.41423 9.99997L11 13.5858L11 3.99997C11 3.44769 11.4477 2.99997 12 2.99997C12.5523 2.99997 13 3.44769 13 3.99997L13 13.5858L16.5858 9.99997C16.9763 9.60945 17.6095 9.60945 18 9.99997C18.3905 10.3905 18.3905 11.0237 18 11.4142L12 17.4142L6.00001 11.4142Z"
      fill={color}
    />
    <path d="M5 21L19 21" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default DownloadIcon;
