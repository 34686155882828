import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { DateTime } from 'luxon';
import { dateFormatForIso } from 'lib/helpers/formatters/datetimeFormatters';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import Chip from 'components/common/Chip';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { fontSizes } from 'assets/theme/typography';
import { getPOStatusTooltipText } from 'lib/tooltips/tooltips';

export const ledgerPurchaseOrderHeader: DataGridHeaderItem[] = [
  new DataGridHeaderItem('purchaseOrderId', 'purchaseOrderId', {
    display: false
  }),
  new DataGridHeaderItem('matchingStatus', 'matchingStatus', {
    display: false
  }),
  new DataGridHeaderItem(
    'PO Number',
    'purchaseOrderNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Status', 'status', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
        <Chip
          type={value}
          color={themeColors.text.primary}
          radius="3px"
          height="18px"
          fontSize={fontSizes.small}
          semibold="bold"
          testingTag="ledger-purchase-order-header"
          tooltipText={getPOStatusTooltipText(value)}
        />
        {tableMeta.rowData[1] && (
          <IconTooltip
            tooltipText="An issue was found on the invoice(s) or logistics documents linked to this purchase order during the matching process."
            children={<WarningIcon color={themeColors.white} fill={themeColors.icon.error} />}
          />
        )}
      </div>
    )
  }),
  new DataGridHeaderItem('Buyer', 'buyer', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'createdDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'paymentDueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Total Amount', 'totalAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  })
];
