/* eslint-disable react-hooks/exhaustive-deps */
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import {
  IInvoiceMatchingResult,
  IInvoiceMatchingResultLineItem,
  IInvoiceMatchingResultLineItemLink,
  IInvoiceMatchingResultLineItemScenario,
  IInvoiceMatchingResultLineItemScenarioRule
} from 'api/interfaces/open-account/invoices/invoices.interface';
import {
  IPurchaseOrderMatchingResult,
  IPurchaseOrderMatchingResultLineItem,
  IPurchaseOrderMatchingResultLineItemLink,
  IPurchaseOrderMatchingResultLineItemScenario,
  IPurchaseOrderMatchingResultLineItemScenarioRule,
  IPurchaseOrderMatchingResultLineItemScenarioRuleData
} from 'api/interfaces/open-account/purchase-orders/purchaseOrders.interface';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { camelCaseToSentenceCase, toTitleCase } from 'lib/helpers/formatters/stringFormatters';
import _ from 'lodash';
import { Dispatch, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router-dom';
import {
  GET_INVOICE_MATCHING_RESULTS,
  GET_PO_MATCHING_RESULTS,
  RESET_MATCHING_RESULTS
} from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { InvoiceReducerStateProps } from 'store/reducers/invoice';
import { PurchaseOrderReducerStateProps } from 'store/reducers/purchaseOrder';
import {
  ILedgerMatching,
  ILedgerMatchingConfigurable,
  LedgerMatchingData
} from 'utils/interfaces/ledger/matching/matching.interface';
import LedgerMatchingAccordion from './LedgerMatchingAccordion';
import {
  LedgerMatchingViewContainer,
  LedgerMatchingViewDescription,
  LedgerMatchingViewHeader,
  LedgerMatchingViewTitle
} from './styled';

enum ViewType {
  INVOICE = 'INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  FCR = 'FCR',
  PL = 'PL'
}

const LedgerMatching: FC = () => {
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const params: Readonly<Params<string>> = useParams();
  const [discrepantViewModel, setDiscrepantViewModel] = useState<ILedgerMatching[]>([]);
  const [viewType, setViewType] = useState<keyof typeof ViewType | null>(null);
  const [passedViewModel, setPassedViewModel] = useState<ILedgerMatching[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const appLoading: string = useSelector((state: RootStateOrAny) => state.app.loading);
  const invoiceLedger: ILedgerInvoice[] = useSelector(
    (state: RootStateOrAny) => state.app.invoiceLedger
  );
  const purchaseOrderLedger: ILedgerPurchaseOrder[] = useSelector(
    (state: RootStateOrAny) => state.app.purchaseOrderLedger
  );
  const {
    poMatchingResults,
    loading: poLoading
  }: { poMatchingResults: IPurchaseOrderMatchingResult | null; loading: boolean } = useSelector(
    (state: RootStateOrAny) => state.purchaseOrder as PurchaseOrderReducerStateProps
  );
  const {
    invoiceMatchingResults,
    loading: invoiceLoading
  }: { invoiceMatchingResults: IInvoiceMatchingResult | null; loading: boolean } = useSelector(
    (state: RootStateOrAny) => state.invoice as InvoiceReducerStateProps
  );

  useEffect(() => {
    const path = window.location.pathname;

    if (
      path.includes('invoices') &&
      !appLoading &&
      !invoiceLoading &&
      (discrepantViewModel.length > 0 || passedViewModel.length > 0)
    )
      setLoading(false);
    if (
      path.includes('purchase-orders') &&
      !appLoading &&
      !poLoading &&
      (discrepantViewModel.length > 0 || passedViewModel.length > 0)
    )
      setLoading(false);

    if (!appLoading && !poLoading && !invoiceLoading) setLoading(false);
  }, [appLoading, poLoading, invoiceLoading, discrepantViewModel, passedViewModel]);

  useEffect(() => {
    if (params?.purchaseOrderId) {
      dispatch({ type: GET_PO_MATCHING_RESULTS, payload: params.purchaseOrderId });
      setViewType(ViewType.PURCHASE_ORDER);
    }
    if (params?.invoiceId) {
      dispatch({ type: GET_INVOICE_MATCHING_RESULTS, payload: params.invoiceId });
      setViewType(ViewType.INVOICE);
    }
  }, [params.purchaseOrderId, params.invoiceId]);

  useEffect(() => {
    if (poMatchingResults) groupRuleset();
  }, [poMatchingResults]);

  useEffect(() => {
    if (invoiceMatchingResults) groupRuleset();
  }, [invoiceMatchingResults]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_MATCHING_RESULTS });
    };
  }, []);

  const groupRuleset: () => void = () => {
    if (!poMatchingResults && !invoiceMatchingResults) return;
    const dataSet:
      | IPurchaseOrderMatchingResultLineItem[]
      | IInvoiceMatchingResultLineItem[]
      | undefined =
      viewType === ViewType.PURCHASE_ORDER
        ? poMatchingResults?.purchaseOrderLineItems
        : invoiceMatchingResults?.invoiceLineItems;

    if (!dataSet) return;

    const flattenedRules = dataSet.flatMap(
      (lineItem: IPurchaseOrderMatchingResultLineItem | IInvoiceMatchingResultLineItem) => {
        return lineItem.scenarios.flatMap(
          (
            item:
              | IPurchaseOrderMatchingResultLineItemScenario
              | IInvoiceMatchingResultLineItemScenario
          ) => {
            return item.rules.map(
              (
                r:
                  | IPurchaseOrderMatchingResultLineItemScenarioRule
                  | IInvoiceMatchingResultLineItemScenarioRule
              ) => {
                const matchedLink:
                  | IPurchaseOrderMatchingResultLineItemLink
                  | IInvoiceMatchingResultLineItemLink
                  | undefined = lineItem.links.find(
                  (l) => l.lineItemNumber === lineItem.lineItemNumber
                );
                r.lineItemMatchingDateTime =
                  matchedLink &&
                  (matchedLink as IInvoiceMatchingResultLineItemLink)?.matchingDateTime
                    ? (matchedLink as IInvoiceMatchingResultLineItemLink).matchingDateTime
                    : '';
                r.lineItemNumber = lineItem.lineItemNumber || '';
                return r;
              }
            );
          }
        );
      }
    );

    if (flattenedRules.length === 0) {
      setDiscrepantViewModel([]);
      setPassedViewModel([]);
      return;
    }

    const groupedByRuleStatus = _.groupBy(flattenedRules, 'ruleStatus');

    const { DISCREPANT: discrepant, MATCHED: passed } = groupedByRuleStatus;

    setDiscrepantViewModel(mapToViewModel(discrepant || [], 'discrepant'));
    setPassedViewModel(mapToViewModel(passed || [], 'passed'));
  };

  const mapToViewModel: (
    rules: IPurchaseOrderMatchingResultLineItemScenarioRule[],
    type: 'passed' | 'discrepant'
  ) => ILedgerMatching[] = (rules, type) => {
    return rules
      .map((r) => {
        const ruleUnitSegments: string[] = r.expression?.split('.') || [];
        const isLineItemRule: boolean = ruleUnitSegments.includes('lineItems');

        return {
          title: `${
            type === 'discrepant'
              ? `${
                  isLineItemRule ? `Line item - ${r.lineItemNumber} - ` : ''
                }Matching has found discrepancies with ${camelCaseToSentenceCase(
                  ruleUnitSegments[ruleUnitSegments.length - 1]
                ).toUpperCase()}`
              : `${
                  isLineItemRule ? `Line item - ${r.lineItemNumber} - ` : ''
                }Matching has been successful for this rule`
          }`,
          rule: r.expression || '',
          values: mapRuleDataToViewModel(r.data),
          isLineItemRule,
          lineItemNumber: r.lineItemNumber,
          lineItemMatchingDateTime: r.lineItemMatchingDateTime || ''
        };
      })
      .sort((a, b) => (a.isLineItemRule === b.isLineItemRule ? 0 : a.isLineItemRule ? 1 : -1));
  };

  const mapRuleDataToViewModel: (
    ruleData: IPurchaseOrderMatchingResultLineItemScenarioRuleData[]
  ) => LedgerMatchingData[] = (ruleData) => {
    return ruleData.map((d) => ({
      title: d.field?.split('.')[0].toUpperCase() || '',
      subtitle: d.field?.toUpperCase() || '',
      value: d.value || '',
      ...mapLink(d.field?.split('.')[0] || '')
    }));
  };

  const mapLink: (field: string) => ILedgerMatchingConfigurable = (field) => {
    if ((!poMatchingResults && !field) || (!invoiceMatchingResults && !field))
      return { documentNumber: '', info: '', documentPath: '' };

    const dataset =
      viewType === ViewType.PURCHASE_ORDER
        ? poMatchingResults?.purchaseOrderLineItems
        : invoiceMatchingResults?.invoiceLineItems;
    if (!dataset) return { documentNumber: '', info: '', documentPath: '' };

    const flattenedLinks = dataset.flatMap((item) => item.links);

    const matchedLinkIndex: number = flattenedLinks.findIndex((link: any) => link.type === field);

    if (params?.invoiceId && field === ViewType.INVOICE) {
      const matchedInvoiceIndex: number = invoiceLedger.findIndex(
        (inv) => inv.invoiceId === params.invoiceId
      );

      return {
        info:
          matchedInvoiceIndex > -1
            ? ''
            : `This ${toTitleCase(field?.split('.')[0].split('_').join(' ') || '')}`,
        documentNumber:
          matchedInvoiceIndex === -1 ? '' : invoiceLedger[matchedInvoiceIndex].invoiceNumber,
        documentPath:
          matchedInvoiceIndex === -1 ? '' : `/invoices/${params.invoiceId}?isLinkFrom=true`
      } as ILedgerMatchingConfigurable;
    }

    if (params?.purchaseOrderId && field === ViewType.PURCHASE_ORDER) {
      const matchedPurchaseOrderIndex: number = purchaseOrderLedger.findIndex(
        (po) => po.purchaseOrderId === params.purchaseOrderId
      );

      return {
        info:
          matchedPurchaseOrderIndex > -1
            ? ''
            : `This ${toTitleCase(field?.split('.')[0].split('_').join(' ') || '')}`,
        documentNumber:
          matchedPurchaseOrderIndex === -1
            ? ''
            : purchaseOrderLedger[matchedPurchaseOrderIndex].data.poNumber,
        documentPath:
          matchedPurchaseOrderIndex === -1
            ? ''
            : `/purchase-orders/${params.purchaseOrderId}?isLinkFrom=true`
      } as ILedgerMatchingConfigurable;
    }

    if (viewType === ViewType.INVOICE) {
      return {
        info:
          matchedLinkIndex > -1
            ? ''
            : `This ${toTitleCase(field?.split('.')[0].split('_').join(' ') || '')}`,
        documentNumber:
          matchedLinkIndex === -1 ? '' : flattenedLinks[matchedLinkIndex].referenceNumber,
        documentPath:
          matchedLinkIndex === -1
            ? ''
            : `/purchase-orders/${flattenedLinks[matchedLinkIndex].id}?isLinkFrom=true`
      } as ILedgerMatchingConfigurable;
    }

    return {
      info:
        matchedLinkIndex > -1
          ? ''
          : `This ${toTitleCase(field?.split('.')[0].split('_').join(' ') || '')}`,
      documentNumber:
        matchedLinkIndex === -1 ? '' : flattenedLinks[matchedLinkIndex].referenceNumber,
      documentPath:
        matchedLinkIndex === -1
          ? ''
          : `/invoices/${flattenedLinks[matchedLinkIndex].id}?isLinkFrom=true`
    } as ILedgerMatchingConfigurable;
  };

  return loading || appLoading || (poLoading && invoiceLoading) ? (
    <LoaderInPage />
  ) : discrepantViewModel.length === 0 && passedViewModel.length === 0 ? (
    <h5 style={{ padding: '16px', textAlign: 'center' }}>Matching results unavailable.</h5>
  ) : (
    <LedgerMatchingViewContainer>
      <LedgerMatchingViewHeader>
        <LedgerMatchingViewTitle
          data-automation-id="ledger-matching-h4-page-title"
          data-testid="sp-ledger-matching-title"
        >
          Matching
        </LedgerMatchingViewTitle>
        <LedgerMatchingViewDescription data-automation-id="ledger-matching-p-page-description">
          The matching rules have been checked and the results available below.
        </LedgerMatchingViewDescription>
      </LedgerMatchingViewHeader>
      <LedgerMatchingAccordion
        discrepant={discrepantViewModel}
        matched={passedViewModel}
        matchingDateTime={poMatchingResults?.matchingDateTime || ''}
      />
    </LedgerMatchingViewContainer>
  );
};

export default LedgerMatching;
