import { FC } from 'react';
import { useLocation } from 'react-router';
import TradePaymentDetail from './TradePaymentDetail';
import MaturingPaymentDetail from './MaturingPaymentDetail';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import BaseCard from 'components/common/cards/BaseCard';
export interface PaymentDetailInvoiceViewModel {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: string;
  fundingCosts: string;
  purchasePrice: string;
  id: string;
  currencySymbol: string;
}

const PaymentDetail: FC = () => {
  const { search } = useLocation();
  const paymentType = new URLSearchParams(search).get('type');

  const renderView: () => JSX.Element = () => {
    switch (paymentType) {
      case 'MATURING':
        return <MaturingPaymentDetail />;

      default:
        return <TradePaymentDetail />;
    }
  };

  return (
    <LayoutViewContainer size="xlarge" data-testid="sp-layout-view-container">
      <BaseCard noBorder noPadding>
        {renderView()}
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default PaymentDetail;
