import { navigationData } from 'lib/data/navigationData';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NAV_BACK_VISIBLE, UPDATE_ACTIVE_NAV_BUTTON, UPDATE_PAGE_TITLE } from 'store/actions';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import routes from './index.routes';
// import ReactGA from 'react-ga';

interface RoutesTrackerProps {
  children?: any;
}

const RoutesTracker: FC<RoutesTrackerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: UPDATE_PAGE_TITLE, payload: { pageTitle: getPathName() } });
    dispatch({ type: NAV_BACK_VISIBLE, payload: { visible: getPathName() !== 'Home' } });
    dispatch({ type: UPDATE_ACTIVE_NAV_BUTTON, payload: setActiveNavOption() });
    // ReactGA.ga('send', 'pageview', location.pathname + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getPathName: () => string = () => {
    const matchedIndex: number = routes.map((r) => r.path).indexOf(location.pathname);
    return matchedIndex > -1 ? routes[matchedIndex].name : '';
  };

  const setActiveNavOption: () => NavigationItem = () => {
    const defaultNavigationItem: NavigationItem = { text: 'Home', path: '' };
    if (getPathName() === 'Home') return defaultNavigationItem;
    const path: NavigationItem | undefined = navigationData.find((n) => {
      return n.path ? location.pathname.includes(n.path) : false;
    });
    return path ? path : defaultNavigationItem;
  };

  return <>{children}</>;
};

export default RoutesTracker;
