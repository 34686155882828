/* eslint-disable react-hooks/exhaustive-deps */
import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import { IOnboardingPersonal } from 'api/interfaces/onboarding/onboarding.interface';
import {IUser, IUserForm, IUserProfile} from 'api/interfaces/users/user.interface';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import TextInput from 'components/forms/inputs/TextInput';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TRIGGER_SNACKBAR, UPDATE_USER } from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import {
  FormStyled,
  OnboardingButtonText,
  OnboardingButtonWrapper,
  OnboardingContainer,
  OnboardingContentContainer,
  OnboardingDescription,
  OnboardingFormFieldContainer,
  OnboardingHeader,
  OnboardingTitle
} from '../../styled';
import {useAuth} from "react-oidc-context";

interface OnboardingStepProps {
  handleNext: (
    actionName: keyof typeof OnboardingActionsEnum,
    data: OnboardingActionEventPut
  ) => void;
}

const OnboardingStepPersonalDetails: FC<OnboardingStepProps> = ({ handleNext }) => {
  const dispatch = useDispatch();
  const [userDataModel, setUserDataModel] = useState<IOnboardingPersonal>({
    firstName: '',
    lastName: '',
    mobilePhone: '',
    title: '',
    email: ''
  });
  const auth = useAuth()
  const loggedInUser= auth.user? {
        id: auth.user.profile.sub,
        profile: {
          firstName: auth.user.profile.given_name ,
          lastName: auth.user.profile.family_name,
          mobilePhone: auth.user.profile.phone_number,
          email: auth.user.profile.email,
          login: auth.user.profile.email,
          tenants: [...(auth.user?.profile as any).tenants as string[]],
        }
      }
    : null;


  const accountCreatedSnackbarData: SnackbarData = {
    title: <h5>Your account is ready</h5>,
    message: <></>,
    leftIcon: <ApproveIcon color={themeColors.icon.success} />,
    type: 'success'
  };

  useEffect(() => {
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: accountCreatedSnackbarData } });
    reset();
    return () => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    };
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      const { firstName, lastName, mobilePhone, email, title } = JSON.parse(
        JSON.stringify(loggedInUser.profile)
      );
      setUserDataModel({
        firstName,
        lastName,
        mobilePhone,
        email,
        title
      });
    }
  }, [loggedInUser]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<{ firstName: string; lastName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onSubmit: SubmitHandler<{ firstName: string; lastName: string }> = () => {
    if (!loggedInUser?.id) return;
    const { firstName, lastName, mobilePhone, title } = userDataModel;
    const putModel = new OnboardingActionEventPut(
      '',
      JSON.stringify(userDataModel),
      OnboardingActionStatusEnum.completed
    );
    const payload: IUserForm = {
      id: loggedInUser.id,
      data: {
        profile: {
          firstName,
          lastName,
          mobilePhone,
          title
        }
      }
    };

    if (dataChangeCheck()) dispatch({ type: UPDATE_USER, payload: { ...payload, noCall: true } });
    handleNext(OnboardingActionsEnum.personalDetails, putModel);
  };

  const dataChangeCheck: () => boolean = () =>
    Object.keys(userDataModel).filter(
      (k) => (userDataModel as any)[k] !== (loggedInUser?.profile as any)[k as any]
    ).length > 0;

  const updateDataModel: (key: string, value: string | boolean) => void = (key, value) =>
    setUserDataModel({ ...userDataModel, [key]: value });

  return (
    <OnboardingContainer data-testid="sp-onboarding-personal-details">
      <OnboardingHeader>
        <OnboardingTitle data-automation-id="onboarding-personal-details-h3-onboarding-title">
          Check and confirm your contact details
        </OnboardingTitle>
        <OnboardingDescription data-automation-id="onboarding-personal-details-div-onboarding-description">
          Your buyer gave us the following details for you. Please update your name, contact number
          and role if they are missing or incorrect.
        </OnboardingDescription>
      </OnboardingHeader>
      <OnboardingContentContainer>
        <FormStyled
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
          }}
          data-testid="sp-onboarding-personal-details-form"
          style={{ minWidth: '500px' }}
        >
          <TextInput
            label="Email"
            defaultValue={userDataModel?.email || ''}
            changeHandler={(event) => updateDataModel('email', event.target.value)}
            testingTagPage="onboarding-personal-details"
            disabled
          />
          <OnboardingFormFieldContainer>
            <TextInput
              label="First name"
              placeholder="Enter first name"
              register={register('firstName', { required: true })}
              errorMessage={
                !!Object.keys(errors).length
                  ? (userDataModel.firstName === '' || errors.firstName) && 'This field is required'
                  : ''
              }
              defaultValue={userDataModel?.firstName || ''}
              changeHandler={(event) => updateDataModel('firstName', event.target.value)}
              testingTagPage="onboarding-personal-details"
            />
            <TextInput
              label="Last name"
              placeholder="Enter last name"
              register={register('lastName', { required: true })}
              errorMessage={
                !!Object.keys(errors).length
                  ? (userDataModel.lastName === '' || errors.lastName) && 'This field is required'
                  : ''
              }
              defaultValue={userDataModel?.lastName || ''}
              changeHandler={(event) => updateDataModel('lastName', event.target.value)}
              testingTagPage="onboarding-personal-details"
            />
          </OnboardingFormFieldContainer>
          <TextInput
            label="Contact number"
            placeholder="Enter contact number"
            defaultValue={userDataModel?.mobilePhone || ''}
            changeHandler={(event) => {
              const value = event.target.value.replace(/[^+\0-9]+/i, '');
              updateDataModel('mobilePhone', value);
            }}
            testingTagPage="onboarding-personal-details"
          />
          <TextInput
            label="Job title"
            placeholder="What is your job role?"
            defaultValue={userDataModel?.title || ''}
            changeHandler={(event) => updateDataModel('title', event.target.value)}
            testingTagPage="onboarding-personal-details"
          />
          <OnboardingButtonWrapper>
            <PrimaryButton
              type="submit"
              disabled={!!Object.keys(errors).length}
              testingTag="onboarding-personal-details-button-confirm"
              text="Confirm"
            />
          </OnboardingButtonWrapper>
        </FormStyled>
      </OnboardingContentContainer>
    </OnboardingContainer>
  );
};

export default OnboardingStepPersonalDetails;
