/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, MenuItem, Paper } from '@mui/material';
import AssetsApi from 'api/assets/assets.api';
import { IAttachment } from 'api/interfaces/attachment/attachment.interface';
import DotMenuIcon from 'assets/icons/DotMenuIcon';
import { themeColors } from 'assets/theme/style';
import DataGrid from 'components/DataGrid';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { store } from 'store';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import {
  LedgerDetailAttachmentsContainer,
  LedgerDetailAttachmentsCount,
  LedgerDetailAttachmentsRow
} from './styled';

export interface ILedgerDetailAttachmentsProps {
  uploadAttachmentButtonClickHandler: () => void;
}

interface ILedgerDetailAttachmentAssetProps extends ILedgerDetailAttachmentsProps {
  documentId: string;
  documentType: keyof typeof AssetEnum;
  attachments: IAttachment[];
}

const LedgerDetailAttachments: FC<ILedgerDetailAttachmentAssetProps> = ({
  uploadAttachmentButtonClickHandler,
  documentId,
  documentType,
  attachments
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [actionSelectedAttachmentId, setActionSelectedAttachmentId] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  const assetApi = new AssetsApi(store);
  const open = Boolean(anchorEl);

  const handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose: () => void = () => {
    setAnchorEl(null);
    setActionSelectedAttachmentId(null);
  };

  const downloadClickHandler: () => void = async () => {
    if (!actionSelectedAttachmentId) return;
    const matchedAttachment = attachments.find(
      (a) => a.attachmentId === actionSelectedAttachmentId
    );

    if (!matchedAttachment) return;

    setDownloading(true);

    const getAttachmentForDownloadResponse = await assetApi.getAttachmentById(
      actionSelectedAttachmentId
    );

    const path = URL.createObjectURL(
      new Blob([getAttachmentForDownloadResponse.data], { type: 'application/pdf' })
    );

    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = path;
    anchor.download =
      matchedAttachment.attachmentName ||
      `kanexa_download_${DateTime.now().toFormat(_DATE_FORMAT + 'HH_mm_ss')}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    setDownloading(false);
    handleClose();
  };

  const viewClickHandler: () => void = async () => {
    if (!actionSelectedAttachmentId) return;
    const matchedAttachment = attachments.find(
      (a) => a.attachmentId === actionSelectedAttachmentId
    );

    if (!matchedAttachment) return;

    const getAttachmentForDownloadResponse = await assetApi.getAttachmentById(
      actionSelectedAttachmentId
    );

    const path = URL.createObjectURL(
      new Blob([getAttachmentForDownloadResponse.data], {
        type: getAttachmentForDownloadResponse.data.type
      })
    );

    window.open(path, '_BLANK');
    handleClose();
  };

  const headers: DataGridHeaderItem[] = [
    new DataGridHeaderItem('Attachment Id', 'attachmentId', {
      display: false
    }),
    new DataGridHeaderItem('Document Name', 'attachmentName', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p>{value?.split('.')[0] || ''}</p>
      )
    }),
    new DataGridHeaderItem('File Type', 'attachmentName', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p>{value?.split('.')[value?.split('.').length - 1 || 0] || ''}</p>
      )
    }),
    new DataGridHeaderItem('Comment', 'uploadedDate', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p>{DateTime.fromISO(value).toFormat(`${_DATE_FORMAT} HH:mm:ss`)}</p>
      )
    }),
    new DataGridHeaderItem('Uploaded By', 'uploadedBy', {
      ...new DataGridHeaderOptions(false, true, true)
    }),
    new DataGridHeaderItem('Actions', 'actions', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <div>
          <div
            key={tableMeta[0]}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span
              style={{
                cursor: 'pointer',
                padding: '0 8px'
              }}
              onClick={(e) => {
                e.preventDefault();
                handleClick(e as any);
                setActionSelectedAttachmentId(tableMeta.rowData[0]);
              }}
            >
              <DotMenuIcon />
            </span>
          </div>
          <Paper>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ boxShadow: 'none' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              {downloading && (
                <MenuItem>
                  <span style={{ width: '70px' }}>
                    <LoaderInPage height="2vh" />
                  </span>
                </MenuItem>
              )}
              {!downloading && <MenuItem onClick={viewClickHandler}>View</MenuItem>}
              {!downloading && <MenuItem onClick={downloadClickHandler}>Download</MenuItem>}
            </Menu>
          </Paper>
        </div>
      )
    })
  ];

  return (
    <LedgerDetailAttachmentsContainer>
      <>
        <LedgerDetailAttachmentsRow>
          <LedgerDetailAttachmentsCount>
            {attachments.length} item{attachments.length === 1 ? '' : 's'}
          </LedgerDetailAttachmentsCount>
          <PrimaryButton
            width="145px"
            padding="6px 16px"
            borderRadius="6px"
            gap="8px"
            minWidth="92px"
            clickHandler={uploadAttachmentButtonClickHandler}
            testingTag={`ledger-details-attachments-button`}
            text="Add document"
          />
        </LedgerDetailAttachmentsRow>
        <DataGrid
          headers={headers}
          data={attachments}
          ariaLabel={''}
          ariaCaption={''}
          dataAutomationTag={''}
          customOptions={{ resizableColumns: false }}
        />
      </>
    </LedgerDetailAttachmentsContainer>
  );
};

export default LedgerDetailAttachments;
