import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import { IOnboardingIdentity } from 'api/interfaces/onboarding/onboarding.interface';
import PrivacyIcon from 'assets/icons/PrivacyIcon';
import { PrimaryButton } from 'components/common/buttons';
import TextInput from 'components/forms/inputs/TextInput';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FormStyled,
  OnboardingButtonText,
  OnboardingButtonWrapper,
  OnboardingCaption,
  OnboardingContainer,
  OnboardingContentContainer,
  OnboardingDescription,
  OnboardingHeader,
  OnboardingTitle
} from '../../styled';

interface OnboardingStepProps {
  handleNext: (
    actionName: keyof typeof OnboardingActionsEnum,
    data: OnboardingActionEventPut
  ) => void;
}

const OnboardingStepConfirmIdentity: FC<OnboardingStepProps> = ({ handleNext }) => {
  const [vendorId, setVendorId] = useState<string>('');

  const { handleSubmit } = useForm<IOnboardingIdentity>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const onSubmit: SubmitHandler<IOnboardingIdentity> = () => {
    const putModel = new OnboardingActionEventPut(
      '',
      vendorId,
      OnboardingActionStatusEnum.completed
    );
    handleNext(OnboardingActionsEnum.vendorID, putModel);
  };

  return (
    <OnboardingContainer data-testid="sp-onboarding-confirm-identity">
      <OnboardingHeader>
        <PrivacyIcon></PrivacyIcon>
        <OnboardingTitle data-automation-id="onboarding-confirm-identity-h3-onboarding-title">
          Confirm your identity
        </OnboardingTitle>
        <OnboardingDescription data-automation-id="onboarding-confirm-identity-div-onboarding-description">
          Please enter your vendor ID from your buyer. If you don't have this,
          <br />
          please contact them as you'll need it to access your account.
        </OnboardingDescription>
      </OnboardingHeader>
      <OnboardingContentContainer>
        <FormStyled
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
          }}
          data-testid="sp-onboarding-confirm-identity-form"
        >
          <TextInput
            label="Vendor ID"
            placeholder="Enter vendor ID"
            defaultValue={vendorId || ''}
            changeHandler={(event) => setVendorId(event.target.value)}
            testingTagPage="onboarding-confirm-identity"
            tooltipText="If you have multiple vendor IDs with your buyer, please pick one for this step so we can identify you - any of them will work. You may know this as your vendor code or supplier reference."
          />
          <OnboardingButtonWrapper>
            <PrimaryButton
              type="submit"
              testingTag="onboarding-confirm-identity-button-submit"
              text="Submit"
            />
          </OnboardingButtonWrapper>
        </FormStyled>
      </OnboardingContentContainer>
    </OnboardingContainer>
  );
};

export default OnboardingStepConfirmIdentity;
