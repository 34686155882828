export class LedgerLineBuilder {
  constructor(
    public title: string,
    public total: number,
    public currency: string,
    public consumedData: LedgerDataItem,
    public availableData: LedgerDataItem
  ) {}
}

export class LedgerDataItem {
  constructor(
    public name: string,
    public key: string,
    public value: number,
    public color: string
  ) {}
}
