import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const PaymentPendingIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.success,
  height = '24px',
  width = '24px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-payment-pending-icon"
  >
    <circle cx="12" cy="12" r="11.5" fill="#E4E4E4" stroke="#C4C4C4" />
  </svg>
);

export default PaymentPendingIcon;
