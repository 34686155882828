import styled from 'styled-components';

export const EarlyPaymentRequestSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EarlyPaymentRequestSummaryH4 = styled.h4`
  margin: 12px 0;
`;
