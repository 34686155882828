import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const LeftLongArrowIcon: FC<IconSvgProps> = ({
  color = themeColors.text.light.muted,
  height = '13px',
  width = '18px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-left-long-arrow-icon"
  >
    <path d="M18 5.5H3.83L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09L3.83 7.5H18V5.5Z" fill={color} />
  </svg>
);

export default LeftLongArrowIcon;
