import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const PaymentsTitle = styled.h1`
  display: ${isMobile() ? 'none' : 'block'};
  font-size: ${fontSizes.xLarge};
`;

export const PaymentsActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin: 16px 0;
`;

export const ExportDataButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

export const ExportDataButtonText = styled.h5``;

export const PaymentsCountContainer = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.mono1};
`;

export const PaymentsSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin: 16px 0;
  width: 100%;
`;

export const PaymentsSearchFilterButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
