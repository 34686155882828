import { Accordion } from '@mui/material';
import { styling, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

//DESKTOP STYLES
export const LedgerDetailAdditionalInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const LedgerDetailAdditionalInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const LedgerDetailAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${themeColors.border.primary};
  border-radius: ${styling.defaultRadius};
  background-color: ${themeColors.bg.light.surface};
  padding: 24px;
  width: 300px;
`;

export const LedgerDetailAdditionalInformationTitle = styled.p`
  ${typography.bodySemiBold}
`;
export const LedgerDetailAdditionalInformationValue = styled.p``;

export const LedgerDetailAddressValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;

export const LedgerDetailAdditionalInformationContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LedgerDetailsAdditionalInformationTextSemiBold = styled.p`
  ${typography.bodySemiBold};
  font-size: ${fontSizes.regular};
`;
export const LedgerDetailsAdditionalInformationTextRegular = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.regular};
  text-align: end;
`;

export const LedgerDetailsAdditionalInformationKeyValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  width: 50%;
  :nth-child(odd) {
    padding-right: 24px;
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 16px !important;
    background-color: transparent;
    padding: 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 12px;
    }
    .MuiAccordionSummary-root {
      background-color: ${themeColors.bg.light.surface};
      flex-direction: row-reverse;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.light.default};
  }
  .MuiButtonBase-root {
    width: 100%;
    border-radius: 6px;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
    transform: rotate(-90deg);
    margin-right: 8px;
    &.Mui-expanded {
      transform: rotate(360deg);
      margin-right: 8px;
    }
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '0 32px' : '0 48px'};
    ${typography.bodyRegular};
    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;
