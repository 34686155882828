import React, { FC } from 'react';
import {
  CollapsibleCardContainer,
  CollapsibleCardContainerBackground,
  CollapsibleCardExpand,
  CollapsibleCardLeft,
  CollapsibleCardRight,
  CollapsibleCardTitle,
  CollapsibleCardValue
} from './styled';

interface CollapsibleCardsProps {
  title: string;
  value: string;
  clickHandler?: () => void;
  testingTagPage?: string;
}

const CollapsibleCards: FC<CollapsibleCardsProps> = ({
  title,
  value,
  clickHandler,
  testingTagPage
}) => {
  return (
    <CollapsibleCardContainerBackground
      data-automation-id={`${testingTagPage}-div-collapsible-card`}
    >
      <CollapsibleCardContainer onClick={clickHandler} data-testid="sp-collapsible-card">
        <CollapsibleCardLeft>
          <CollapsibleCardTitle data-automation-id={`${testingTagPage}-div-collapsible-card-title`}>
            {title}
          </CollapsibleCardTitle>
          <CollapsibleCardValue data-automation-id={`${testingTagPage}-div-collapsible-card-value`}>
            {value}
          </CollapsibleCardValue>
        </CollapsibleCardLeft>
        <CollapsibleCardRight>
          <CollapsibleCardExpand
            onClick={clickHandler}
            data-automation-id={`${testingTagPage}-p-title-expand`}
          >
            Expand
          </CollapsibleCardExpand>
        </CollapsibleCardRight>
      </CollapsibleCardContainer>
    </CollapsibleCardContainerBackground>
  );
};

export default CollapsibleCards;
