import Lottie from 'lottie-react';
import styled from 'styled-components';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 248px);
`;

export const AppSuspenseLottieAnimationContainer = styled(Lottie)`
  display: flex;
  height: 200px;
  margin-top: 25vh;
`;
