import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';

interface NavigationLinkButtonProps {
  active: boolean;
}

interface NavigationLinkButtonTextProps {
  fontSize: string;
  color?: string;
  fontWeight?: number;
}

export const NavigationLinkButtonContainer = styled.li<NavigationLinkButtonProps>`
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? `${themeColors.white}25` : 'transparent')};
  border: none;
  cursor: pointer;
`;

export const NavigationLinkButtonText = styled.p<NavigationLinkButtonTextProps>`
  margin: 0;
  color: ${({ color }) => color || themeColors.text.light.body};
  font-size: ${({ fontSize }) => fontSize || fontSizes.regular};
  font-weight: ${({ fontWeight }) => fontWeight || `${fontWeights.semiBold} !important`};
`;

export const NavigationLinkButtonHeadingText = styled.p<NavigationLinkButtonTextProps>`
  margin: 0;
  color: ${({ color }) => color || themeColors.text.light.body};
  font-size: ${({ fontSize }) => fontSize || fontSizes.medium};
  font-weight: ${fontWeights.semiBold};
`;

export const NavigationLinkButtonIcon = styled.div`
  display: flex;
  margin-right: 16px;
`;
