import { Checkbox } from "@mui/material";
import { themeColors } from "assets/theme/style";
import { typography } from "assets/theme/typography";
import styled from "styled-components";
import DatePicker from 'react-datepicker';

export const InvoiceCreateFieldWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const InvoiceCreateFieldContainer = styled.div`
  display: flex;
`;

export const InvoiceCreateFieldLabel = styled.h5``;

export const InvoiceCreateFieldValue = styled.p`
  ${typography.bodyRegular};
`;

export const InvoiceCreateFieldLabelContainer= styled.div`
  display: flex;
  gap: 10px;
`;


export const CheckboxFieldRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0 !important;
  align-items: flex-start !important;
  color: ${themeColors.text.light.muted} !important;
  background-color: transparent !important;

  &.Mui-checked {
    color: ${themeColors.text.primary} !important;
  }
  &.Mui-disabled {
    color: ${themeColors.text.light.muted} !important;
  }
  &.MuiButtonBase-root{
    padding: 0;
    width: fit-content;
  }
`;

export const CheckboxFieldText = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${typography.bodySemiBold};
  margin-bottom: 4px;
  width: fit-content;
`;

export const DatePickerStyled = styled(DatePicker)``;

export const CreateInvoiceDetailDateSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
`;

export const InvoiceCreateFieldSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 50%;
  width: 100%;
  margin-bottom: 24px;
  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;
