import warningJson from 'assets/lottie-animations/lottie_warning.json';
import Lottie from 'lottie-react';
import { FC } from 'react';
import {
  FullPageForbiddenContainer,
  FullPageForbiddenHeading,
  FullPageForbiddenText
} from './styled';

const Forbidden: FC = () => (
  <FullPageForbiddenContainer>
    <FullPageForbiddenHeading>
      You do not have permission to view this application.
    </FullPageForbiddenHeading>
    <FullPageForbiddenText>
      If this has been done in error, please contact your Administrator.
    </FullPageForbiddenText>
    <Lottie
      style={{
        height: '10vh'
      }}
      loop
      animationData={warningJson}
      data-testid="sp-forbidden-full-page"
      data-automation-id="forbidden"
    />
  </FullPageForbiddenContainer>
);

export default Forbidden;
