import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Notification from 'components/Notification';
import loadingSpinner from 'assets/lottie-animations/loading-dot.json';
import { FC, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import InfoIcon from 'assets/icons/InfoIcon';
import GatewayToFinanceButton from 'views/PurchaseOrders/PurchaseOrderDetail/GatewayToFinanceButton';
import {
  LedgerDetailCreateInvoiceBannerButtonContainer,
  LedgerDetailCreateInvoiceBannerButtonContentContainer,
  LedgerDetailCreateInvoiceBannerContainer,
  LedgerDetailCreateInvoiceBannerButtonText,
  LedgerDetailCreateInvoiceBannerIconContainer,
  LedgerDetailCreateInvoiceBannerContentContainer,
  LedgerDetailCreateInvoiceBannerDescription,
  LedgerDetailCreateInvoiceBannerButtonWrapper,
  LottieAnimationContainer
} from './styled';
import WarningIcon from 'assets/icons/WarningIcon';
import { NavigateFunction, useNavigate } from 'react-router';
import { UPDATE_ACTIVE_NAV_BUTTON } from 'store/actions';
interface LedgerDetailCreateInvoiceBannerProps {
  clickHandler?: () => void;
  closeHandler?: () => void;
  resetHandler?: () => void;
  editHandler?: () => void;
  purchaseOrderId?: string;
}

const LedgerDetailCreateInvoiceBanner: FC<LedgerDetailCreateInvoiceBannerProps> = ({
  clickHandler,
  closeHandler,
  resetHandler,
  editHandler,
  purchaseOrderId
}) => {
  const {
    error,
    errorMessage,
    draft,
    loading,
    display,
    createInvoiceId
  }: {
    error: string;
    errorMessage: string[];
    draft: boolean;
    loading: boolean;
    display: boolean;
    createInvoiceId: string;
  } = useSelector((state: RootStateOrAny) => state.createInvoice);
  const dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const {
    tenant
  }: {
    tenant: string | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const navigateToInvoice: () => void = () =>
    window.open(`/app/${tenant}/invoices/${createInvoiceId}`, '_BLANK');

  useEffect(() => {
    createInvoiceId &&
      purchaseOrderId &&
      setTimeout(() => {
        dispatch({
          type: UPDATE_ACTIVE_NAV_BUTTON,
          payload: {
            fontSize: undefined,
            icon: undefined,
            path: '/invoices',
            text: 'Invoices'
          }
        });
        navigate(`/invoices`);
      }, 2000);
  }, [createInvoiceId]);

  const renderErrorView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        padding="18px"
        backgroundColor={themeColors.bg.errorMuted}
        borderColor={themeColors.bg.error}
        border
        noShadow
        title={error}
        icon={<WarningIcon color={themeColors.icon.error} width="24px" height="24px" />}
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderErrorInvoceView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        padding="18px"
        backgroundColor={themeColors.bg.errorMuted}
        borderColor={themeColors.bg.error}
        border
        noShadow
        title={`Error${errorMessage.length > 0 && 's'}`}
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              {errorMessage.length > 0
                ? errorMessage.map((message: string, index: number) => (
                    <p key={`${index}-error`}>{message}</p>
                  ))
                : ''}
            </LedgerDetailCreateInvoiceBannerDescription>
            {/* <LedgerDetailCreateInvoiceBannerButtonWrapper>
              <LedgerDetailCreateInvoiceBannerButtonContainer data-testid="sp-g2f-banner-button">
                <GatewayToFinanceButton showOnlyButton primaryButton />
              </LedgerDetailCreateInvoiceBannerButtonContainer>
            </LedgerDetailCreateInvoiceBannerButtonWrapper> */}
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
        icon={<WarningIcon color={themeColors.icon.error} width="24px" height="24px" />}
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderLoadingView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        padding="18px"
        backgroundColor={themeColors.bg.light.surface}
        border
        borderColor={themeColors.bg.info}
        title="Creating invoice"
        icon={
          <LottieAnimationContainer
            loop
            animationData={loadingSpinner}
            data-testid="sp-loading-view"
          />
        }
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              Your invoice is currently being processed.
            </LedgerDetailCreateInvoiceBannerDescription>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderCreatedView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        padding="18px"
        backgroundColor={themeColors.bg.light.surface}
        borderColor={themeColors.bg.info}
        noShadow
        border
        fullContent
        icon={<ApproveIcon color={themeColors.icon.success} />}
        title="Your invoice has been submitted"
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              The invoice has been submitted and accepted.
            </LedgerDetailCreateInvoiceBannerDescription>
            <LedgerDetailCreateInvoiceBannerButtonWrapper>
              <LedgerDetailCreateInvoiceBannerButtonContainer data-testid="sp-g2f-banner-button">
                <PrimaryButton
                  backgroundColor={themeColors.bg.light.surfaceInvert}
                  clickHandler={navigateToInvoice}
                  testingTag="-ledger-detail-g2f-banner-button"
                >
                  <LedgerDetailCreateInvoiceBannerButtonContentContainer>
                    <LedgerDetailCreateInvoiceBannerButtonText
                      data-automation-id="ledger-detail-G2F-banner-button-text"
                      data-testid="sp-g2f-banner-button-text"
                    >
                      Go to invoice
                    </LedgerDetailCreateInvoiceBannerButtonText>
                    <LedgerDetailCreateInvoiceBannerIconContainer
                      data-automation-id="ledger-detail-div-g2f-banner-button-icon-container"
                      data-testid="sp-g2f-banner-button-icon"
                    >
                      <RightLongArrowIcon
                        color={themeColors.text.onPrimary}
                        height="10"
                        width="12"
                      />
                    </LedgerDetailCreateInvoiceBannerIconContainer>
                  </LedgerDetailCreateInvoiceBannerButtonContentContainer>
                </PrimaryButton>
              </LedgerDetailCreateInvoiceBannerButtonContainer>
            </LedgerDetailCreateInvoiceBannerButtonWrapper>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderDraftView: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        padding="18px"
        backgroundColor={themeColors.bg.light.surface}
        borderColor={themeColors.bg.info}
        border
        noShadow
        fullContent
        icon={<InfoIcon height="24px" width="24px" color={themeColors.icon.primary} />}
        title="Review & confirm invoice"
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              Please check you’re happy with the invoice and confirm this invoice to send it to your
              buyer.
            </LedgerDetailCreateInvoiceBannerDescription>
            <LedgerDetailCreateInvoiceBannerButtonWrapper>
              <LedgerDetailCreateInvoiceBannerButtonContainer data-testid="sp-g2f-banner-button">
                <PrimaryButton
                  backgroundColor={themeColors.bg.light.surfaceInvert}
                  width="fit-content"
                  clickHandler={clickHandler}
                  testingTag="-ledger-detail-g2f-banner-button"
                >
                  <LedgerDetailCreateInvoiceBannerButtonContentContainer>
                    <LedgerDetailCreateInvoiceBannerButtonText
                      data-automation-id="ledger-detail-G2F-banner-button-text"
                      data-testid="sp-g2f-banner-button-text"
                    >
                      Confirm invoice
                    </LedgerDetailCreateInvoiceBannerButtonText>
                  </LedgerDetailCreateInvoiceBannerButtonContentContainer>
                </PrimaryButton>
                <SecondaryButton
                  width="fit-content"
                  clickHandler={editHandler}
                  testingTag="-ledger-detail-g2f-banner-button"
                >
                  <LedgerDetailCreateInvoiceBannerButtonContentContainer>
                    <LedgerDetailCreateInvoiceBannerButtonText
                      data-automation-id="ledger-detail-G2F-banner-button-text"
                      data-testid="sp-g2f-banner-button-text"
                    >
                      Edit
                    </LedgerDetailCreateInvoiceBannerButtonText>
                  </LedgerDetailCreateInvoiceBannerButtonContentContainer>
                </SecondaryButton>
              </LedgerDetailCreateInvoiceBannerButtonContainer>
            </LedgerDetailCreateInvoiceBannerButtonWrapper>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  const renderView: () => JSX.Element = () => {
    if (!display) {
      return <></>;
    }

    if (loading) {
      return renderLoadingView();
    }
    if (error) {
      return renderErrorView();
    }
    if (errorMessage.length > 0) {
      return renderErrorInvoceView();
    }
    if (draft) {
      return renderDraftView();
    }

    return renderCreatedView();
  };

  return renderView();
};

export default LedgerDetailCreateInvoiceBanner;
