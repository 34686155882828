import { Dialog } from '@mui/material';
import { font } from 'assets/theme/typography';
import styled from 'styled-components';
import { styling } from 'assets/theme/style';

export const SessionTimeoutDialogDialogStyled = styled(Dialog)`
  ${font.body};
  ${font.heading};
  .MuiDialog-paper {
    padding: 0px;
    margin-bottom: 10px;
    height: 230px;
    width: 600px;
    border-radius: ${styling.defaultRadius};
  }
`;

export const SessionTimeoutDialogTitle = styled.h2`
  background: #0032a0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
`;

export const SessionTimeoutDialogText = styled.p`
  padding: 20px;
`;

export const SessionTimeoutDialogButtonWrapper = styled.div`
  width: 80px;
  margin: auto;
`;
