import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { ICalculationTaskData, TaskDetails } from 'api/interfaces/funding-request/task.interface';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import FundingApi from 'api/ledger/funding.api';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import { PrimaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import TransactionHistory from 'components/funding-request/TransactionHistory';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { store } from 'store';
import {
  EarlyPaymentRequestSentActionsContainer,
  EarlyPaymentRequestSentButtonContainer,
  EarlyPaymentRequestSentButtonText,
  EarlyPaymentRequestSentButtonsContainer,
  EarlyPaymentRequestSentContainer,
  EarlyPaymentRequestSentNotificationWrapper,
  EarlyPaymentRequestSentTitle,
  EarlyPaymentRequestSentWrapper
} from './styled';

interface EarlyPaymentRequestSentProps {
  closeClickHandler: () => void;
}

const EarlyPaymentRequestSent: FC<EarlyPaymentRequestSentProps> = ({ closeClickHandler }) => {
  const {
    fundingRequestCalculation,
    loading,
    task
  }: {
    fundingRequestCalculation: FundingRequestCalculation;
    loading: boolean;
    fundingRequestStatus: 'CREATED' | 'FAILED' | null;
    task: TaskDetails<ICalculationTaskData>;
  } = useSelector((state: RootStateOrAny) => state.fundingRequest);
  const appLoading: Funding[] = useSelector((state: RootStateOrAny) => state.app.loading);
  const [funding, setFunding] = useState<Funding>();
  const fundingApi = new FundingApi(store);

  useEffect(() => {
    if (task?.data.fundingId) getFunding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const getFunding: () => void = async () => {
    if (!task.data.fundingId) throw Error('FAILED TO MATCH FUNDING');
    const matchedFunding = await fundingApi.getMatchedFundingByProgramIDPoll(task.data.fundingId);
    setFunding(matchedFunding);
  };

  // const clickHandler: () => void = () => {
  //   dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: false });
  //   navigate(`/payments/${task.data.fundingId}`);
  // };

  const renderView: () => JSX.Element = () => (
    <EarlyPaymentRequestSentWrapper data-testid="sp-early-payment-request-sent">
      <EarlyPaymentRequestSentContainer>
        <EarlyPaymentRequestSentTitle data-automation-id="early-payment-request-sent-h2-request-sent-title">
          Your early payment request has been sent
        </EarlyPaymentRequestSentTitle>
        <EarlyPaymentRequestSentNotificationWrapper>
          <Notification
            noShadow
            backgroundColor={themeColors.bg.successMuted}
            color={themeColors.text.success}
            icon={<ApproveIcon color={themeColors.icon.success} />}
            title="Early payment request sent"
            description="Your early payment has been requested - you can see full details of the request and the next steps below."
          />
        </EarlyPaymentRequestSentNotificationWrapper>
        <TransactionHistory
          currencyCode={funding?.currency || ''}
          funder={funding?.funder || ''}
          fundingRequest={funding?.fundingRequest}
          fundingResponse={funding?.fundingResponse}
          linkedPayments={funding?.linkedPayments}
          loading={appLoading && loading}
          isEarlyPaymentSent
          earlyPaymentData={fundingRequestCalculation}
          paymentType="POST_EARLY_PAYMENT"
        />
        <EarlyPaymentRequestSentActionsContainer>
          <Divider />
          <EarlyPaymentRequestSentButtonsContainer>
            <EarlyPaymentRequestSentButtonContainer>
              <PrimaryButton
                clickHandler={closeClickHandler}
                testingTag="early-payment-request-sent-button-close"
                text="Close"
              />
            </EarlyPaymentRequestSentButtonContainer>
            {/* <EarlyPaymentRequestSentButtonContainer>
              <SecondaryButton
                backgroundColor={themeColors.white}
                textColor={themeColors.text.primary}
                clickHandler={clickHandler}
                testingTag="early-payment-request-sent-button-see-more-detail"
              >
                <EarlyPaymentRequestSentButtonText data-automation-id="early-payment-request-sent-p-button-see-more-detail-text">
                  See more detail
                </EarlyPaymentRequestSentButtonText>
              </SecondaryButton>
            </EarlyPaymentRequestSentButtonContainer> */}
          </EarlyPaymentRequestSentButtonsContainer>
        </EarlyPaymentRequestSentActionsContainer>
      </EarlyPaymentRequestSentContainer>
    </EarlyPaymentRequestSentWrapper>
  );

  return renderView();
};

export default EarlyPaymentRequestSent;
