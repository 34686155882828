/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { ILedgerInvoice, ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import {
  LedgerPaymentItem,
  ODataLedgerPaymentItem
} from 'api/interfaces/ledger/ledgerPaymentItems.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import PaymentApi from 'api/ledger/payment.api';
import { AxiosResponse } from 'axios';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { FundingRequestIncludedInvoices, FundingRequestSummary } from 'components/funding-request';
import TransactionHistory from 'components/funding-request/TransactionHistory';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { store } from 'store';
import { AccordionStyled, PaymentAccordionTitle, FundingRequestSummaryContainer } from '../styled';
import FundingRequestPaymentDetails from 'components/funding-request/FundingRequestPaymentDetails';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { ProgramConfig } from 'api/interfaces/program/program.interface';

export interface PaymentDetailInvoiceViewModel {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: string;
  fundingCosts: string;
  fees: string;
  adjustments: string;
  purchasePrice: string;
  id: string;
  currencySymbol: string;
}

export interface IPaymentDetail {
  paymentIdData?: string;
}

const PaymentDetail: FC<IPaymentDetail> = ({ paymentIdData }) => {
  const params: Readonly<Params<string>> = useParams();
  const [paymentItems, setPaymentItems] = useState<LedgerPaymentItem[]>([]);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);
  const [invoiceLedger, setInvoiceLedger] = useState<ILedgerInvoice[]>([]);
  const [payment, setPayment] = useState<LedgerPayment | null>(null);
  const { loading }: { loading: boolean } = useSelector((state: RootStateOrAny) => state.ledger);
  const appLoading: boolean = useSelector((state: RootStateOrAny) => state.app.loading);
  const allProgramsWithDetails: ProgramConfig[] = useSelector(
    (state: RootStateOrAny) => state.app.allProgramsWithDetails
  );
  const invoiceApi = new InvoiceApi(store);
  const paymentApi = new PaymentApi(store);

  const paymentId: string = paymentIdData ? paymentIdData : params.paymentId || '';

  useEffect(() => {
    setInternalLoading(true);
  }, []);
  useEffect(() => {
    if (allProgramsWithDetails) getPaymentItems();
  }, [allProgramsWithDetails]);

  const getPaymentItems: () => void = async () => {
    if (!paymentId) return;
    try {
      const res: AxiosResponse<ODataLedgerPaymentItem> =
        await paymentApi.getPaymentsLedgerPaymentItem(paymentId);
      const ledgerRes: AxiosResponse<ODataLedgerInvoices> = await invoiceApi.getInvoiceLedger(
        allProgramsWithDetails.map((p) => p.id)
      );

      setInvoiceLedger(ledgerRes.data.value);
      setPaymentItems(res.data.value);
      matchedPayment();
    } catch (error) {
      throw Error('MATURING PAYMENTS: can not get the payment ledger items');
    }
  };

  const matchedPayment: () => void = async () => {
    const paymentApi = new PaymentApi(store);
    const paymentLedger = await paymentApi.getPaymentsLedger(
      allProgramsWithDetails.map((p) => p.id)
    );
    const matched: number = paymentLedger.data.value.findIndex((p) => p.id === paymentId);
    setPayment(matched > -1 ? paymentLedger.data.value[matched] : null);
    setInternalLoading(false);
  };

  const mapInvoiceData: () => PaymentDetailInvoiceViewModel[] = () => {
    const mappedInvoiceViewModel: PaymentDetailInvoiceViewModel[] = paymentItems.map(
      (paymentItem) => {
        const matchedInvoice = invoiceLedger.find((inv) => {
          const matchedReference = paymentItem.externalReferences.find(
            (r) => r.referenceKind === 'INVOICE_ID'
          );
          if (matchedReference) return matchedReference.referenceValue === inv.invoiceId;
          return false;
        });

        return {
          invoiceNumber: matchedInvoice ? matchedInvoice.invoiceNumber : '',
          buyer: matchedInvoice ? matchedInvoice.buyerRef : '',
          paymentDueDate: formatDateTime(
            matchedInvoice ? matchedInvoice.maturityDate || '' : '',
            'yyyy-LL-dd'
          ),
          invoiceAmount: `${CurrencySymbolsLookUp[paymentItem.currency]}${formatNumber(
            matchedInvoice?.data.amount || 0,
            2
          )}`,
          netAmount: `${CurrencySymbolsLookUp[paymentItem.currency]}${formatNumber(
            matchedInvoice?.netAmount || 0,
            2
          )}`,
          fees: `${CurrencySymbolsLookUp[paymentItem.currency]}${
            matchedInvoice && matchedInvoice.matchingFees > 0 ? '-' : ''
          }${formatNumber(matchedInvoice ? matchedInvoice.matchingFees : 0, 2)}`,
          adjustments: `${CurrencySymbolsLookUp[paymentItem.currency]}${formatNumber(
            matchedInvoice ? matchedInvoice.appliedAdjustment : 0,
            2
          )}`,
          fundingCosts: `${CurrencySymbolsLookUp[paymentItem.currency]}${formatNumber(0 || 0, 2)}`,
          purchasePrice: `${CurrencySymbolsLookUp[paymentItem.currency]}${formatNumber(0 || 0, 2)}`,
          id: matchedInvoice?.invoiceId || '',
          currencySymbol: CurrencySymbolsLookUp[paymentItem.currency]
        };
      }
    );
    return mappedInvoiceViewModel;
  };

  const renderView: () => JSX.Element = () => (
    <FundingRequestSummaryContainer data-testid="sp-maturing-payment-container">
      <FundingRequestSummary
        pageTitle="Maturity payment"
        paymentId={payment?.paymentReferences[0] || ''}
        funder=""
        createdDateTime=""
        paymentAmount={payment?.amount}
        expectedPaymentDate={formatDateTime(payment?.expectedPaymentDate || '', _DATE_FORMAT)}
        paymentSentDate={formatDateTime(payment?.sentDate || '', `${_DATE_FORMAT} HH:mm`)}
        status={payment?.status || ''}
        buyer={payment?.fromParticipant.registeredName || ''}
        isSmallTitle={paymentIdData ? true : false}
        currency={payment?.currency || ''}
      />
      <Divider margin="0 0 8px 0" />
      <FundingRequestPaymentDetails
        totalFees={payment?.invoicesTotalFees || 0}
        totalAdjustments={payment?.invoicesTotalAdjustments || 0}
        totalPaymentAmount={payment?.amount || 0}
        currency={payment?.currency || ''}
      />
      <Divider margin="0 0 8px 0" />
      <AccordionStyled defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentAccordionTitle data-automation-id="early-payment-request-h4-accordion-title-transaction-history">
            Transaction history
          </PaymentAccordionTitle>
        </AccordionSummary>
        <AccordionDetails style={{ paddingBottom: 0 }}>
          <TransactionHistory
            paymentType="MATURING"
            currencyCode={payment?.currency || ''}
            loading={loading}
            paidDate={formatDateTime(payment?.sentDate || '', `${_DATE_FORMAT} HH:mm`)}
            bankAccountId={payment?.destinationBankAccountId}
            entityName={payment?.fromParticipant.registeredName || ''}
          />
        </AccordionDetails>
      </AccordionStyled>
      <Divider margin="8px 0" />
      <AccordionStyled defaultExpanded disableGutters style={{ marginBottom: '8px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentAccordionTitle data-automation-id="early-payment-request-h4-accordion-title-included-invoices">
            Included invoices
          </PaymentAccordionTitle>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '8px' }}>
          <FundingRequestIncludedInvoices
            invoices={mapInvoiceData()}
            loading={loading}
            isMaturing
            isHrefUrl={paymentIdData ? true : false}
          />
        </AccordionDetails>
      </AccordionStyled>
    </FundingRequestSummaryContainer>
  );

  return appLoading || loading || internalLoading ? <LoaderInPage /> : renderView();
};

export default PaymentDetail;
