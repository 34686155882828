import LedgerDetail from 'components/ledger/LedgerDetail';
import LedgerLogistics from 'components/ledger/LedgerLogistics';
import LedgerMatching from 'components/ledger/LedgerMatching';
import LedgerPayment from 'components/ledger/LedgerPayment';
import LedgerPurchaseOrder from 'components/ledger/LedgerPurchaseOrder';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

export const invoiceDetailTabs: TabsItem[] = [
  {
    text: 'Invoice Name',
    children: <LedgerDetail type={AssetEnum.INVOICE} showEntities showSummaryAccordions />
  },
  {
    text: 'Purchase order',
    children: <LedgerPurchaseOrder />
  },
  {
    text: 'Logistics',
    children: <LedgerLogistics type={AssetEnum.INVOICE} />
  },
  {
    text: 'Matching',
    children: <LedgerMatching />
  },
  {
    text: 'Payment',
    children: <LedgerPayment />
  }
];
