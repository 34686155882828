import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const WarningIconFilled: FC<IconSvgProps> = ({
  color = themeColors.icon.error,
  height = '24px',
  width = '24px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16ZM11 13.1111C11 13.602 11.4477 14 12 14C12.5523 14 13 13.602 13 13.1111L13 6.88889C13 6.39797 12.5523 6 12 6C11.4477 6 11 6.39797 11 6.88889L11 13.1111Z"
      fill={color}
    />
  </svg>
);

export default WarningIconFilled;
