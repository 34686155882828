import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { TextInputProps } from './TextInput';
import TextField from '@mui/material/TextField';

export interface TextFieldProps {
  error?: boolean;
}

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextInputStyled = styled(TextField)<TextFieldProps>`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 36px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :disabled {
    background-color: ${themeColors.bg.light.inputDisabled};
    border: 1px solid ${themeColors.border.inputDisabled};
    color: ${themeColors.text.light.muted} !important;
  }

  .MuiOutlinedInput-root {
    border: none !important;
    ${typography.bodyRegular};
    font-family: 'Source Sans Pro', sans-serif;

    &:hover {
      border: none !important;
    }
  }

  .MuiOutlinedInput-input {
    height: 36px;
    border-radius: 6px;
    font-size: ${fontSizes.regular};
    padding: 0 6px 0 12px;
    background-color: ${themeColors.bg.light.input};
    border: 2px solid
      ${({ error }) => (error ? themeColors.border.error : themeColors.border.input)};
    color: ${themeColors.text.light.input};

    &:hover,
    &:-webkit-autofill:hover {
      background-color: ${themeColors.bg.light.inputHover}!important;
      border: 2px solid
        ${({ error }) => (error ? themeColors.border.error : themeColors.border.inputHover)};
      color: ${themeColors.text.light.inputHover}!important;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${themeColors.text.light.input}!important;
      background-color: ${themeColors.bg.light.input}!important;
      border: 2px solid ${themeColors.border.input}!important;
      color: ${themeColors.text.light.input}!important;
    }
    &:focus,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${themeColors.text.light.inputFocus}!important;
      background-color: ${themeColors.bg.light.inputFocus}!important;
      border: 2px solid
        ${({ error }) => (error ? themeColors.border.error : themeColors.border.inputFocus)} !important;
      color: ${themeColors.text.light.inputFocus}!important;
      outline: none;
    }

    &.Mui-disabled {
      background-color: ${themeColors.bg.light.inputDisabled};
      color: ${themeColors.text.light.readableDisabled} !important;
      -webkit-text-fill-color: ${themeColors.text.light.readableDisabled} !important;
      pointer-events: none;
    }
  }
`;

export const TextInputLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${themeColors.text.light.body};
  gap: 6px;
  ${typography.bodySemiBold};
  margin-bottom: 4px;
`;

export const TextInputMessage = styled.span<TextInputProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.text.error};
  ${typography.captionRegular};
  margin-top: 8px;
`;
