import ESGImage from 'assets/images/ESGImage';
import { FC } from 'react';

const ESG: FC = () => {
  return (
    <div
      style={{ padding: '8px 32px 32px 100px', height: '100%', width: '100%' }}
      data-automation-id="ESG-div-container"
      data-testid="sp-ESG-container"
    >
      <ESGImage />
    </div>
  );
};

export default ESG;
