export default class FundingRequestDataGridItem {
  constructor(
    public invoiceNumber: string,
    public buyer: string,
    public paymentDueDate: string,
    public invoiceAmount: number,
    public fundingCosts: number | string,
    public purchasePrice: number,
    public id: string,
    public currencySymbol: string,
    public selected: boolean,
    public marginCost: number | string,
    public baseRateCost: number | string
  ) {}
}
