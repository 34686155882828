import { themeColors } from 'assets/theme/style';
import DateBar from 'components/activity/DateBar';
import PaymentsStatus from 'components/payments/PaymentsStatus';
import _ from 'lodash';
import { FC } from 'react';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  MobileInvoiceBarColumn,
  MobileInvoiceBarContainer,
  MobileInvoiceBarSection,
  MobileInvoiceCaption,
  MobileInvoicesContainer,
  MobileInvoiceSemiBoldText
} from './styled';
import CustomPagination from 'components/CustomPagination';

export interface PaymentsViewTableData {
  created: string;
  expectedPaymentDate: string;
  includedInvoices: number;
  paymentIssuer: string;
  paymentType: string;
  referenceNumber: string;
  status: 'Paid' | 'Pending';
  totalPaymentValue: string;
}

interface PaymentsViewMobileProps {
  payments: DataGridRowItem[];
  rowClickHandler?: (row: any) => void;
  totalRows: number;
  page: number;
  rowsPerPage: number;
  changePage: (action: any, tableState: any) => void;
  rowsPerPageHandler: (numberOfRows: any) => void;
}

interface PaymentsViewMobileListViewModel {
  date: string;
  paymentsData: DataGridRowItem[];
}

const PaymentsViewMobile: FC<PaymentsViewMobileProps> = ({
  payments,
  rowClickHandler,
  totalRows,
  page,
  rowsPerPage,
  changePage,
  rowsPerPageHandler
}) => {
  const mappedData: () => PaymentsViewMobileListViewModel[] = () => {
    const grouped = _.groupBy(payments, 'created');
    return Object.entries(grouped)
      .map(([key, value]) => ({
        date: key,
        paymentsData: value.map((v) => ({
          referenceNumber: v.referenceNumber,
          paymentType: v.paymentType,
          totalPaymentValue: v.totalPaymentValue,
          paymentIssuer: v.paymentIssuer,
          status: v.status
        }))
      }))
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
  };

  const buildInvoiceBar: (viewModel: DataGridRowItem, key: number) => JSX.Element = (
    { referenceNumber, paymentType, totalPaymentValue, paymentIssuer, status },
    key
  ) => (
    <MobileInvoiceBarContainer
      key={key}
      onClick={() => rowClickHandler && rowClickHandler(referenceNumber as string)}
      data-testid="sp-mobile-invoice-bar"
    >
      <MobileInvoiceBarSection>
        <MobileInvoiceBarColumn>
          <MobileInvoiceSemiBoldText data-automation-id="payments-p-mobile-value">
            {referenceNumber}
          </MobileInvoiceSemiBoldText>
          <PaymentsStatus status={status as 'Paid' | 'Pending'} />
        </MobileInvoiceBarColumn>

        <MobileInvoiceSemiBoldText data-automation-id="payments-p-mobile-value">
          {paymentIssuer}
        </MobileInvoiceSemiBoldText>
      </MobileInvoiceBarSection>
      <MobileInvoiceBarSection>
        <MobileInvoiceCaption data-automation-id="payments-p-mobile-value">
          {paymentType}
        </MobileInvoiceCaption>
        <MobileInvoiceCaption data-automation-id="payments-p-mobile-value">
          {totalPaymentValue}
        </MobileInvoiceCaption>
      </MobileInvoiceBarSection>
    </MobileInvoiceBarContainer>
  );

  const buildView: () => JSX.Element = () => {
    return (
      <>
        {mappedData().map(({ date, paymentsData }) => (
          <MobileInvoicesContainer key={date}>
            <DateBar date={date} toEdge color={themeColors.mono5} />
            {paymentsData.map((data, i) => buildInvoiceBar(data, i))}
          </MobileInvoicesContainer>
        ))}
        {totalRows > 15 && (
          <CustomPagination
            totalRows={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            changePage={changePage}
            changeRowsPerPage={rowsPerPageHandler}
          />
        )}
      </>
    );
  };
  return <div data-testid="sp-payments-mobile-table">{buildView()}</div>;
};

export default PaymentsViewMobile;
