import { FC } from 'react';
import { themeColors } from 'assets/theme/style';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const HideMoreIcon: FC<IconSvgProps> = ({
  height = '16',
  width = '16',
  color = themeColors.icon.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28448 1.33331H8.71536C9.931 1.3333 10.9108 1.33328 11.6815 1.43689C12.4816 1.54446 13.1552 1.77459 13.6903 2.30962C14.2253 2.84466 14.4554 3.51833 14.563 4.31842C14.6666 5.08907 14.6666 6.06891 14.6666 7.28456V8.71541C14.6666 9.93105 14.6666 10.9109 14.563 11.6815C14.4554 12.4816 14.2253 13.1553 13.6903 13.6903C13.1552 14.2254 12.4816 14.4555 11.6815 14.5631C10.9108 14.6667 9.93099 14.6667 8.71535 14.6666H7.28449C6.06885 14.6667 5.089 14.6667 4.31836 14.5631C3.51827 14.4555 2.8446 14.2254 2.30956 13.6903C1.77453 13.1553 1.5444 12.4816 1.43683 11.6815C1.33322 10.9109 1.33323 9.93106 1.33325 8.71542V7.28454C1.33323 6.0689 1.33322 5.08906 1.43683 4.31842C1.5444 3.51833 1.77453 2.84466 2.30956 2.30962C2.8446 1.77459 3.51827 1.54446 4.31836 1.43689C5.089 1.33328 6.06884 1.3333 7.28448 1.33331ZM4.49603 2.75833C3.84378 2.84603 3.49838 3.00642 3.25237 3.25243C3.00636 3.49845 2.84596 3.84384 2.75827 4.49609C2.668 5.16751 2.66659 6.05739 2.66659 7.33331V8.66665C2.66659 9.94257 2.668 10.8325 2.75827 11.5039C2.84596 12.1561 3.00636 12.5015 3.25237 12.7475C3.49838 12.9935 3.84378 13.1539 4.49603 13.2416C5.16745 13.3319 6.05733 13.3333 7.33325 13.3333H8.66659C9.94251 13.3333 10.8324 13.3319 11.5038 13.2416C12.1561 13.1539 12.5015 12.9935 12.7475 12.7475C12.9935 12.5015 13.1539 12.1561 13.2416 11.5039C13.3318 10.8325 13.3333 9.94257 13.3333 8.66665V7.33331C13.3333 6.05739 13.3318 5.16751 13.2416 4.49609C13.1539 3.84384 12.9935 3.49845 12.7475 3.25243C12.5015 3.00642 12.1561 2.84603 11.5038 2.75833C10.8324 2.66806 9.94251 2.66665 8.66659 2.66665H7.33325C6.05733 2.66665 5.16745 2.66806 4.49603 2.75833Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 4.66669C8.36811 4.66669 8.66659 4.96516 8.66659 5.33335L8.66659 10.6667C8.66659 11.0349 8.36811 11.3334 7.99992 11.3334C7.63173 11.3334 7.33325 11.0349 7.33325 10.6667L7.33325 5.33335C7.33325 4.96516 7.63173 4.66669 7.99992 4.66669Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 7.99998C11.3333 8.36817 11.0348 8.66665 10.6666 8.66665L5.33325 8.66665C4.96506 8.66665 4.66659 8.36817 4.66659 7.99998C4.66659 7.63179 4.96506 7.33331 5.33325 7.33331L10.6666 7.33331C11.0348 7.33331 11.3333 7.63179 11.3333 7.99998Z"
      fill={color}
    />
  </svg>
);

export default HideMoreIcon;
