import Divider from 'components/common/divider';
import ActionLink from 'components/common/links/ActionLink';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import {
  TabContainer,
  TabsHeaderContainer,
  TabsHeaderMenu,
  TabsStyled,
  TabsWrapper
} from './styled';
import TabPanel from './TabPanel';
import BaseCard from 'components/common/cards/BaseCard';

export interface TabsItemType {
  text: string;
  children: JSX.Element;
}

export interface TabsProps {
  tabItems: TabsItemType[];
  documentName: string;
  actionMenuItems?: NavigationItem[];
  defaultValue?: number;
  testingTagPage: string;
  customAction?: JSX.Element | null;
}

const Tabs: FC<TabsProps> = ({
  tabItems,
  documentName,
  actionMenuItems,
  defaultValue,
  testingTagPage,
  customAction
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<number>(0);

  const handleChange: (event: any, newValue: any) => void = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return (
    <TabsWrapper
      data-automation-id={`${testingTagPage}-div-tabs-container`}
      data-testid={`sp-tabs`}
    >
      {tabItems.length > 1 && (
        <TabsHeaderContainer>
          <TabsHeaderMenu>
            <TabsStyled
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabItems.map((item: TabsItemType, index: number) => (
                <TabContainer
                  data-automation-id={`${testingTagPage}-tab-item-${index}`}
                  data-testid={`sp-tabs-tab-item-${index}`}
                  label={index === 0 ? documentName : item.text}
                  key={`${index}-tab-container`}
                />
              ))}
            </TabsStyled>
            {actionMenuItems && <ActionLink menuItems={actionMenuItems} />}
            {customAction && customAction}
          </TabsHeaderMenu>
        </TabsHeaderContainer>
      )}
      {tabItems.map(
        (item: TabsItemType, index: number) =>
          value === index && (
            <BaseCard
              key={`TabPanel-${index}`}
              noBorder
              minWidth
              noBoxShadow
              tabs={
                <TabPanel value={value} index={index}>
                  {item.children}
                </TabPanel>
              }
            />
          )
      )}
    </TabsWrapper>
  );
};

export default Tabs;
