import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { themeColors } from 'assets/theme/style';
import WarningIcon from 'assets/icons/WarningIcon';
import Chip from 'components/common/Chip';
import { fontSizes } from 'assets/theme/typography';

export const ledgerLogisticsHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem(
    'Document Description',
    'documentDescription',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem(
    'Tracking Number',
    'trackingNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('', 'isDiscrepant', {
    display: false
  }),
  new DataGridHeaderItem('Status', 'status', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <Chip
            type={value}
            color={themeColors.text.primary}
            radius="3px"
            height="18px"
            fontSize={fontSizes.small}
            semibold="bold"
            testingTag="ledger-logistics-header"
          />
          {tableMeta.rowData[2] && <WarningIcon color={themeColors.icon.error} />}
        </div>
      );
    }
  }),
  new DataGridHeaderItem(
    'Created Date',
    'createdDate',
    new DataGridHeaderOptions(false, true, true)
  )
];
