import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const GroupedToDosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const GroupToDosRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: ${themeColors.text.light.body};
  background-color: ${themeColors.bg.light.surface};
  padding: 16px 24px;
  margin: 0 -24px;
`;

export const GroupToDosContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 16px 24px;
`;

export const ToDoContentRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding: 16px 24px;
  margin-left: 1rem;
  flex: 1;
  justify-content: space-between;
`;

export const GroupedToDosHeadingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const GroupedToDosHeadingHeader = styled.h4``;

export const GroupedToDosHeadingTextDescription = styled.p``;
