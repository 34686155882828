import Divider from 'components/common/divider';
import React, { FC } from 'react';
import {
  FundingRequestDetailsContainer,
  FundingRequestDetailsH5,
  FundingRequestDetailsRow,
  FundingRequestDetailsRowContainer,
  FundingRequestDetailsSegment,
  FundingRequestDetailsText,
  FundingRequestDetailsTextBold,
  FundingRequestDetailsTextContainer
} from './styled';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import FundingRequestMoreDetails from 'components/funding-request/FundingRequestMoreDetails';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import IconTooltip from 'components/common/tooltip/IconTooltip';

interface FundingRequestDetailsProps {
  buyers: string[];
  fixedDiscountRate: number;
  invoicesTotalFixedDiscountCost: number;
  totalRequestAmount: number;
  totalFundingCost: number;
  totalPaymentValue: number;
  totalMarginCosts: number;
  totalBaseRateCosts: number;
  currencyCode: string;
  isProgramFixedCost?: boolean;
}

const FundingRequestDetails: FC<FundingRequestDetailsProps> = ({
  buyers,
  totalRequestAmount,
  totalFundingCost,
  totalPaymentValue,
  currencyCode,
  totalMarginCosts,
  totalBaseRateCosts,
  fixedDiscountRate,
  invoicesTotalFixedDiscountCost,
  isProgramFixedCost = false
}) => {
  const totalFields: string[] = [
    'Total amount requested',
    'Total cost of funding',
    'Total payment value'
  ];

  const totalFixedDiscountFields: string[] = [
    'Total amount requested',
    'Total early payment charge at',
    'Total payment value'
  ];
  const currencySymbol: string = CurrencySymbolsLookUp[currencyCode];

  const buildTotals: () => JSX.Element[] = () => {
    return totalFields.map((f, i) => (
      <FundingRequestDetailsRowContainer key={f}>
        {i !== 0 && <Divider />}
        <FundingRequestDetailsRow>
          {i + 1 !== totalFields.length ? (
            <>
              <FundingRequestDetailsText
                data-automation-id={`early-payment-request-p-label-${toKebabCase(f)}`}
              >
                {f}
              </FundingRequestDetailsText>
              <FundingRequestDetailsText data-automation-id="early-payment-request-p-value">
                {currencySymbol}
                {formatNumber(i === 0 ? totalRequestAmount : totalFundingCost, 2)}
              </FundingRequestDetailsText>
            </>
          ) : (
            <>
              <FundingRequestDetailsTextBold
                data-automation-id={`early-payment-request-p-label-${toKebabCase(f)}`}
              >
                {f}
              </FundingRequestDetailsTextBold>
              <FundingRequestDetailsTextBold data-automation-id="early-payment-request-p-value">
                {currencySymbol}
                {formatNumber(totalPaymentValue, 2)}
              </FundingRequestDetailsTextBold>
            </>
          )}
        </FundingRequestDetailsRow>
        {/* NOT IDEAL - DATA STRUCTURE NEEDS UPDATING NOW VALUES ARE REFERENCED CONDITIONALLY */}
        {i === 1 && (
          <FundingRequestMoreDetails
            currency={currencyCode}
            totalBaseRateCost={totalBaseRateCosts}
            totalMarginCost={totalMarginCosts}
          />
        )}
      </FundingRequestDetailsRowContainer>
    ));
  };

  const buildfixedDiscountTotals: () => JSX.Element[] = () => {
    return totalFixedDiscountFields.map((f, i) => (
      <FundingRequestDetailsRowContainer key={f} style={{ width: '80%' }}>
        {i === 2 && <Divider />}
        <FundingRequestDetailsRow>
          {i + 1 !== totalFields.length ? (
            <>
              <FundingRequestDetailsTextContainer>
                <FundingRequestDetailsText
                  data-automation-id={`early-payment-request-p-label-${toKebabCase(f)}`}
                >
                  {i === 1 ? `${f} ${fixedDiscountRate / 100}%` : f}
                </FundingRequestDetailsText>
                {i === 1 && (
                  <IconTooltip tooltipText="This is the amount you'll pay Marco Polo Network for requesting early payment." />
                )}
              </FundingRequestDetailsTextContainer>
              <FundingRequestDetailsText data-automation-id="early-payment-request-p-value">
                {i === 1 && '-'}
                {currencySymbol}
                {formatNumber(i === 0 ? totalRequestAmount : invoicesTotalFixedDiscountCost, 2)}
              </FundingRequestDetailsText>
            </>
          ) : (
            <>
              <FundingRequestDetailsTextBold
                data-automation-id={`early-payment-request-p-label-${toKebabCase(f)}`}
              >
                {f}
              </FundingRequestDetailsTextBold>
              <FundingRequestDetailsTextBold data-automation-id="early-payment-request-p-value">
                {currencySymbol}
                {formatNumber(totalPaymentValue, 2)}
              </FundingRequestDetailsTextBold>
            </>
          )}
        </FundingRequestDetailsRow>
      </FundingRequestDetailsRowContainer>
    ));
  };

  return (
    <FundingRequestDetailsContainer
      style={{
        flexDirection: isProgramFixedCost ? 'column-reverse' : 'row',
        gap: isProgramFixedCost ? '60px' : '10px'
      }}
    >
      <FundingRequestDetailsSegment>
        <FundingRequestDetailsH5 data-automation-id="early-payment-request-h5-buyers-title">
          Buyers({buyers.length})
        </FundingRequestDetailsH5>
        {buyers.map((b, i) => (
          <FundingRequestDetailsText
            data-automation-id={`early-payment-request-p-value-buyer-${toKebabCase(b)}`}
            key={i}
          >
            {b}
          </FundingRequestDetailsText>
        ))}
      </FundingRequestDetailsSegment>
      <FundingRequestDetailsSegment>
        <FundingRequestDetailsH5 data-automation-id="early-payment-request-h5-label-summary">
          Summary
        </FundingRequestDetailsH5>
        {!isProgramFixedCost ? buildTotals() : buildfixedDiscountTotals()}
      </FundingRequestDetailsSegment>
    </FundingRequestDetailsContainer>
  );
};

export default FundingRequestDetails;
