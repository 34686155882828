import { FC } from 'react';

const PaymentLedgerIcon: FC = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="28" fill="#E3E9FF" />
    <path
      d="M35 26.2996C34.95 25.8496 34.6 25.5996 34.05 25.5996C33.85 25.5996 33.7 25.5996 33.5 25.6496L32.45 25.7996C32.25 25.8496 32 25.8496 31.8 25.8996C31.15 25.9996 30.55 26.0996 29.9 26.1496C29.65 26.1496 29.45 26.1996 29.15 26.1996H29.05C28.65 25.8496 28.1 25.6496 27.55 25.6496C27 25.6496 26.5 25.8996 26.1 26.2996L26.05 26.3496C25.85 26.5996 25.65 26.8996 25.55 27.1996V27.2996C25.5 27.4996 25.5 27.6496 25.5 27.7996C25.5 28.2496 25.65 28.6496 25.9 28.9996C25.95 29.0496 25.95 29.0996 26 29.1496L26.15 29.2996L26.25 29.3996C26.45 29.5496 26.7 29.6996 26.95 29.7996C27.15 29.8496 27.4 29.8996 27.65 29.8996C28.4 29.8996 29.1 29.4496 29.45 28.7996C29.55 28.7496 29.7 28.6496 29.85 28.5996L29.95 28.5496C30.4 28.3496 30.9 28.1996 31.35 28.0496C32.25 27.7496 33.2 27.4996 34.2 27.2496C35 27.0996 35 26.5996 35 26.2996Z"
      fill="#0032A0"
    />
    <path
      d="M33.3501 37.9002C32.8501 37.7502 32.3501 37.5502 31.9001 37.4002L31.7001 37.3502L28.2501 36.1502C27.7501 35.9502 27.2001 36.0502 26.8501 36.3502C26.4501 36.6502 26.3501 37.1502 26.4501 37.7002C26.5001 37.8502 26.5001 37.9502 26.5501 38.1502L26.7001 39.0002H26.3001C21.5501 38.3502 17.7501 34.7502 16.8501 30.0502C16.7001 29.2002 16.6001 28.3502 16.6501 27.5002C16.7501 24.4002 18.1001 21.5502 20.4001 19.5002C22.4001 17.5002 25.0001 16.4502 27.6001 16.3502C28.3001 16.3502 28.9501 16.3502 29.6501 16.4502C29.9501 16.5002 30.2501 16.5502 30.6001 16.6502C31.2501 16.8002 31.9001 16.4502 32.1501 15.8502C32.3001 15.5002 32.2501 15.1002 32.1001 14.7502C31.9001 14.4002 31.6001 14.1502 31.2501 14.1002C30.5001 13.9502 29.7001 13.8502 28.9501 13.8002C26.5001 13.7002 24.3001 14.1002 22.3501 14.9502C21.4001 15.3502 20.4501 15.9502 19.4501 16.7502L19.2501 16.9502C18.7501 17.3502 18.3001 17.8002 17.9001 18.2502C14.7001 21.8502 13.5001 25.8502 14.3001 30.1002C14.5001 31.1002 14.8001 32.1502 15.2501 33.1502C16.9001 37.2502 20.0001 39.9002 24.4501 41.1502C25.0501 41.3002 25.7001 41.4002 26.3501 41.5502C26.6501 41.6002 26.9501 41.6502 27.3001 41.7002H27.3501L27.5001 42.6002C27.5501 42.8002 27.6001 43.0002 27.6001 43.1502C27.7001 43.7002 28.0501 44.1002 28.5001 44.2502C28.6001 44.3002 28.7501 44.3002 28.8501 44.3002C29.2001 44.3002 29.5501 44.1502 29.8501 43.9002C31.2001 42.7502 32.5501 41.5002 33.9501 40.3002C34.4001 39.9002 34.5501 39.4502 34.5001 39.0002C34.2501 38.4502 33.9001 38.0502 33.3501 37.9002Z"
      fill="#0032A0"
    />
    <path
      d="M37.8502 36.1505C37.5502 35.9005 37.2502 35.6005 36.9502 35.3505L36.7002 35.1505C36.5002 35.0505 36.3002 35.0005 36.0502 35.0005C35.7502 35.0005 35.5002 35.1505 35.3002 35.4005C34.9502 35.8505 34.9502 36.4505 35.3002 36.8505L35.7502 37.2505L36.4002 37.8005C36.6002 38.0005 36.8502 38.1005 37.1502 38.1005C37.5002 38.1005 37.8002 38.0005 38.0502 37.7505C38.4502 37.3005 38.3502 36.6005 37.8502 36.1505ZM40.5002 31.0005C40.1002 30.9005 39.7502 30.7505 39.3502 30.6505L39.0502 30.6005C38.8502 30.6005 38.6502 30.6005 38.4002 30.7505C38.1502 30.9005 37.9502 31.1505 37.9002 31.4005C37.7002 31.9505 38.0002 32.5005 38.5002 32.7005L39.0502 32.8505L39.8502 33.1005C40.1502 33.2005 40.4002 33.1505 40.6502 33.0505C40.9502 32.9505 41.2002 32.7005 41.3002 32.3505C41.5002 31.8005 41.1502 31.2005 40.5002 31.0005ZM40.6502 25.2005C40.2502 25.2505 39.8502 25.3005 39.4502 25.4005L39.1502 25.5005C38.9502 25.6005 38.8002 25.7005 38.6502 25.9005C38.5002 26.1505 38.4002 26.4005 38.4502 26.7005C38.5502 27.2505 39.0002 27.6505 39.6002 27.6005L40.1502 27.5005L41.0002 27.3505C41.3002 27.3005 41.5502 27.2005 41.7002 27.0005C41.9502 26.7505 42.0502 26.4505 42.0002 26.1005C41.9002 25.5005 41.3502 25.1005 40.6502 25.2005ZM38.4002 19.8505C38.0502 20.0505 37.7502 20.3005 37.4002 20.5005L37.1502 20.7005C37.0002 20.8505 36.9002 21.0505 36.8502 21.3005C36.8002 21.6005 36.8502 21.8505 37.0502 22.1005C37.3502 22.5505 37.9502 22.7005 38.5002 22.4505L39.0002 22.1505L39.7002 21.7005C39.9502 21.5505 40.1002 21.3005 40.2002 21.0505C40.3002 20.7505 40.2502 20.4005 40.0502 20.1005C39.6502 19.6005 38.9502 19.5005 38.4002 19.8505ZM34.0502 16.0005L33.4502 17.0505L33.3002 17.3505C33.2502 17.5505 33.2002 17.7505 33.2502 18.0005C33.3002 18.3005 33.5002 18.5005 33.7502 18.6505C34.2502 18.9505 34.8502 18.8005 35.2002 18.3505L35.5002 17.8505L35.9502 17.1505C36.1002 16.9005 36.1502 16.6505 36.1502 16.3505C36.1002 16.0005 35.9502 15.7505 35.6502 15.5505C35.0502 15.2505 34.4002 15.4505 34.0502 16.0005Z"
      fill="#0032A0"
    />
  </svg>
);
export default PaymentLedgerIcon;
