/* eslint-disable react-hooks/exhaustive-deps */
import { themeColors } from 'assets/theme/style';
import NavigationLinkButton from 'components/common/buttons/NavigationLinkButton';
import { ERPConnection } from 'hipster-sdk';
import { navigationData } from 'lib/data/navigationData';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { SubNavItemsContainer, SubNavStyled } from './styled';

const SubNav: FC = () => {
  const {
    onboardingComplete,
    g2fAccessToken,
    g2fCustUID,
    g2fFeatureHidden
  }: {
    onboardingComplete: boolean | null;
    g2fAccessToken: string | null;
    g2fCustUID: string | null;
    g2fFeatureHidden: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const programOpenAccount: boolean = useCheckProgramsContainsType(ProgramType.OPEN_ACCOUNT);

  return (
    <SubNavStyled role="navigation" data-testid="sp-subnav-container">
      <SubNavItemsContainer>
        {onboardingComplete &&
          navigationData
            .filter((item) => (!programOpenAccount ? item.text !== 'Purchase orders' : item.text))
            .map(({ text, path, dataAutomationTag }) => (
              <NavigationLinkButton
                fontWeight={500}
                color={themeColors.text.light.onInvert}
                key={text}
                text={text || ''}
                path={path || ''}
                testingTag={dataAutomationTag}
                isHeadingStyle
              />
            ))}
      </SubNavItemsContainer>
      {!g2fFeatureHidden && g2fAccessToken && g2fCustUID && (
        <ERPConnection
          setup={{
            token: g2fAccessToken,
            redirectPath: 'https://g2fauth-staging.finecta.dev/redirect',
            customerUID: g2fCustUID,
            platformRedirectPath: `${window.location.origin}/connections?isLinkFrom=true`,
            g2fDomain: `https://g2fapi-staging.finecta.dev`
          }}
        />
      )}
    </SubNavStyled>
  );
};

export default SubNav;
