import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const PurchaseOrderTitle = styled.h1`
  display: ${isMobile() ? 'none' : 'block'};
  font-size: ${fontSizes.xLarge};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
  }
`;

export const SearchLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
  }
`;

export const SearchAdvancedText = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.primary};
  cursor: pointer;
`;

export const PurchaseOrderCustomTableHeader = styled.th`
  display: flex;
  align-items: center;
  min-height: 42px;
  background-color: ${themeColors.mono5};
  color: ${themeColors.mono1};
  padding: 0 8px;
  width: 50px;
`;

export const PurchaseOrderCustomTableHeaderText = styled.p`
  ${typography.tableHeader};
`;


export const PurchaseOrderFilterButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;