import { fontWeights } from 'assets/theme/typography';
import React, { FC } from 'react';

interface ITypographyProps {
  tag: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span';
  weight?: 'default' | 'semibold' | 'bold';
  color?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'initial';
  children: React.ReactNode;
  testId: string;
}

const Typography: FC<ITypographyProps> = ({ tag, weight, color, children, testId, align }) => {
  const setWeight: () => number | 'default' = () => {
    switch (weight) {
      case 'semibold':
        return fontWeights.semiBold;
      case 'bold':
        return fontWeights.bold;

      default:
        return 'default';
    }
  };

  const constructStyles = {
    color,
    textAlign: align,
    fontWeight: setWeight()
  };

  const renderElement: () => JSX.Element = () => {
    switch (tag) {
      case 'h1':
        return (
          <h1 style={constructStyles} data-automation-id={testId}>
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2 style={constructStyles} data-automation-id={testId}>
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h3 style={constructStyles} data-automation-id={testId}>
            {children}
          </h3>
        );
      case 'h4':
        return (
          <h4 style={constructStyles} data-automation-id={testId}>
            {children}
          </h4>
        );
      case 'h5':
        return (
          <h5 style={constructStyles} data-automation-id={testId}>
            {children}
          </h5>
        );

      default:
        return (
          <p style={constructStyles} data-automation-id={testId}>
            {children}
          </p>
        );
    }
  };
  return renderElement();
};

export default Typography;
