import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const InfoIcon: FC<IconSvgProps> = ({
  height = '24',
  width = '24',
  color = themeColors.icon.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13.3333 6.66667C13.3333 7.40305 12.7364 8 12 8C11.2636 8 10.6667 7.40305 10.6667 6.66667C10.6667 5.93029 11.2636 5.33333 12 5.33333C12.7364 5.33333 13.3333 5.93029 13.3333 6.66667ZM13 10.6667C13 10.1144 12.5523 9.66667 12 9.66667C11.4477 9.66667 11 10.1144 11 10.6667V17.3333C11 17.8856 11.4477 18.3333 12 18.3333C12.5523 18.3333 13 17.8856 13 17.3333V10.6667Z"
      fill={color}
    />
  </svg>
);

export default InfoIcon;
