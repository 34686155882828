import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';

export const earlyPaymentRequestHeader: DataGridHeaderItem[] = [
  new DataGridHeaderItem(
    'Invoice Number',
    'invoiceNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Buyer', 'buyer', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem(
    'Maturity Date',
    'paymentDueDate',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Invoice Amount', 'invoiceAmount', {
    sort: true,
    sortThirdClickReset: true,
    filter: false,
    customBodyRender: (value: any, _tableMeta: any, _updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    )
  }),
  new DataGridHeaderItem('Funding Costs', 'fundingCosts', {
    sort: true,
    sortThirdClickReset: true,
    filter: false,
    customBodyRender: (value: any, _tableMeta: any, _updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    )
  }),
  new DataGridHeaderItem('Purchase Price', 'purchasePrice', {
    sort: true,
    sortThirdClickReset: true,
    filter: false,
    customBodyRender: (value: any, _tableMeta: any, _updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    )
  })
];
