import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import {
  LedgerDetailSummaryContainer,
  LedgerDetailSummaryLabel,
  LedgerDetailSummaryRow,
  LedgerDetailSummarySegment,
  LedgerDetailSummaryValue
} from '../../LedgerDetailSummary/styled';
import { FC } from 'react';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';

interface LedgerFeesAndChargesProps {
  value: number;
}

const LedgerFeesAndCharges: FC<LedgerFeesAndChargesProps> = ({ value }) => {
  return (
    <LedgerDetailSummaryContainer style={{ padding: 0 }}>
      <LedgerDetailSummaryRow style={{ marginBottom: 0 }} type={LedgerTypes.INVOICE}>
        <LedgerDetailSummarySegment>
          <LedgerDetailSummaryLabel
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}
            data-automation-id={`ledger-detail-fees-h5-label`}
          >
            Matching fee{' '}
            <IconTooltip tooltipText="Matching fee is charged and deducted from the maturity payment for each matched invoice" />
          </LedgerDetailSummaryLabel>
          <LedgerDetailSummaryValue>${formatNumber(value, 2)}</LedgerDetailSummaryValue>
        </LedgerDetailSummarySegment>
      </LedgerDetailSummaryRow>
    </LedgerDetailSummaryContainer>
  );
};

export default LedgerFeesAndCharges;
