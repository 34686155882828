import { themeColors } from 'assets/theme/style';
import React, { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const LeftArrowBoldIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '12px',
  width = '8px'
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-right-arrow-bold-icon"
    style={{ transform: 'rotate(180deg)' }}
  >
    <path d="M1.5 0L0.0899963 1.41L4.67 6L0.0899963 10.59L1.5 12L7.5 6L1.5 0Z" fill={color} />
  </svg>
);

export default LeftArrowBoldIcon;
