import { fontSizes } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface IReduced {
  reduced?: boolean;
}

export const LedgerDetailContentHeader = styled.div<IReduced>`
  display: flex;
  width: 100%;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  align-items: ${isMobile() ? 'start' : 'end'};
  justify-content: space-between;
  padding: ${({ reduced }) => (reduced ? 0 : isMobile() ? '16px' : '16px 24px')};
  gap: ${isMobile() ? '16px' : '24px'};
`;

export const LedgerDetailHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const LedgerDetailHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobile() ? 'flex-start' : 'flex-end'};
  gap: 4px;
  min-width: fit-content;
`;

export const LedgerDetailHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LedgerDetailHeaderAmountLabel = styled.p`
  text-align: end;
  font-size: ${fontSizes.regular};
`;

export const LedgerDetailHeaderNetAmountLabel = styled.p`
  text-align: end;
  font-size: ${fontSizes.regular};
`;

export const LedgerDetailHeaderAmountValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LedgerDetailHeaderAmountValue = styled.h2`
  text-align: end;
`;

export const LedgerDetailHeaderTitle = styled.h2`
  display: flex;
  align-self: flex-end;
`;

export const LedgerDetailHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LedgerDetailHeaderIcon = styled.div`
  display: flex;
`;
