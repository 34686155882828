import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import styled from 'styled-components';

interface ISaving {
  isSaving?: boolean;
}

export const CompanyDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  padding: ${isMobile() ? '0 16px' : 0};
`;

export const CompanyDetailsContainer = styled.div<ISaving>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSaving }) => (isSaving ? 'center' : 'flex-start')};
  align-items: flex-start;
  width: ${isMobile() ? '100%' : '30%'};
  min-width: ${isMobile() ? 'auto' : '350px'};
`;

export const CompanyDetailsTitle = styled.h2`
  margin-top: 24px;
  color: ${themeColors.text.light.body};
  margin-bottom: ${isMobile() ? '12px' : '32px'};
`;

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CompanyDetailsRegisteredAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CompanyLogoUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyLogoTitle = styled.h4`
  margin-bottom: 8px;
  color: ${themeColors.text.light.body};
`;

export const CompanyLogoDescription = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.body};
  margin-bottom: 24px;
`;

export const CompanyDetailsActionsContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  gap: 16px;
  align-items: center;
  margin-bottom: 33px;
`;

export const CompanyDetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isMobile() ? '100%' : 'auto'};
`;

export const CompanyDetailsButtonText = styled.p`
  ${typography.bodySemiBold};
`;

//FILE UPLOADER
export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${themeColors.iconBlue}20;
  border-radius: 12px;
  border: 1px dashed ${themeColors.iconBlue};
  height: 144px;
  gap: 12px;
`;

export const FileUploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadText = styled.h5``;

export const FileUploadButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CompanyDetailsLottieAnimationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CompanyDetailsLottieAnimation = styled(Lottie)`
  display: flex;
  height: 115px;
`;
