import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

interface IIndicatorColor {
  color: string;
}

export const OnboardingCompleteSetUpTitle = styled.h3`
  margin-bottom: 4px;
`;

export const OnboardingCompleteSetUpRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OnboardingCompleteStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const OnboardingCompleteStatusIndicator = styled.div<IIndicatorColor>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const OnboardingCompleteStatusIndicatorDescription = styled.p`
  color: ${themeColors.text.light.subtle};
`;

export const OnboardingCompleteDescription = styled.p`
  margin-top: 24px;
`;

export const OnboardingCompleteProgressionHeading = styled.h4`
  color: ${themeColors.text.light.subtle};
`;
