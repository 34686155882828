import { FC } from 'react';
import { FETCH_ENTITY_DETAILS } from 'store/actions';
import { useDispatch } from 'react-redux';
import { EntityDetailsTextLink } from './styled';

export interface EntityDetailsLinkProps {
  entityID?: string;
  title: string;
  type?: 'buyer' | 'funder' | 'beneficiary';
}
const EntityDetailsLink: FC<EntityDetailsLinkProps> = ({ entityID, title, type }) => {
  const dispatch = useDispatch();
  const clickHandler: () => void = () => {
    if (!entityID) return;
    dispatch({
      type: FETCH_ENTITY_DETAILS,
      payload: { id: entityID, entityType: type }
    });
  };
  return (
    <EntityDetailsTextLink
      data-automation-id="entity-link-p-link"
      data-testid="sp-entity-link"
      onClick={clickHandler}
    >
      {title}
    </EntityDetailsTextLink>
  );
};

export default EntityDetailsLink;
