import { typography, fontSizes } from 'assets/theme/typography';
import styled from 'styled-components';
import { themeColors, styling } from 'assets/theme/style';

interface FlatCardProps {
  isFlag?: boolean;
}

export const FlatCardContainer = styled.div<FlatCardProps>`
  display: flex;
  justify-content: space-between;
  padding: ${styling.defaultRadius} 0px ${styling.defaultRadius} ${styling.defaultRadius};
  border-radius: ${styling.defaultRadius};
  background-color: ${({ isFlag }) => (isFlag ? `${themeColors.bg.light.surface}` : '')};
  border: ${({ isFlag }) => (isFlag ? `1px solid ${themeColors.bg.primary}` : themeColors.border.light.inactive)};
  margin-bottom: 16px;
  cursor: pointer;
`;

export const FlatCardLeft = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const FlatCardRight = styled.div`
  display: block;

`;

export const FlatCardIcon = styled.div`
  display: flex;
`;

export const FlatCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FlatCardTitle = styled.div`
  display: flex;
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
`;

export const FlatCardValue = styled.div`
  display: flex;
  color: ${themeColors.text.light.subtle};
  ${typography.captionRegular};
`;

export const FlatCardFlag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
  padding-right: 10px;
`;
