import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const DocumentHighlightIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '40',
  width = '40'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} rx="20" fill="#E7EAF8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9342 11C16.956 11 16.978 11 17 11L23.0658 11C23.9523 11 24.7161 10.9999 25.3278 11.0821C25.9833 11.1703 26.6117 11.369 27.1213 11.8787C27.631 12.3883 27.8297 13.0167 27.9179 13.6722C28.0001 14.2839 28.0001 15.0477 28 15.9342V25.0658C28.0001 25.9523 28.0001 26.7161 27.9179 27.3278C27.8297 27.9833 27.631 28.6117 27.1213 29.1213C26.6117 29.631 25.9833 29.8297 25.3278 29.9179C24.7161 30.0001 23.9523 30.0001 23.0658 30H16.9342C16.0477 30.0001 15.2839 30.0001 14.6722 29.9179C14.0167 29.8297 13.3883 29.631 12.8787 29.1213C12.369 28.6117 12.1703 27.9833 12.0821 27.3278C11.9999 26.7161 12 25.9523 12 25.0658L12 16C12 15.978 12 15.956 12 15.9342C12 15.0477 11.9999 14.2839 12.0821 13.6722C12.1703 13.0167 12.369 12.3883 12.8787 11.8787C13.3883 11.369 14.0167 11.1703 14.6722 11.0821C15.2839 10.9999 16.0477 11 16.9342 11ZM14.9387 13.0643C14.505 13.1226 14.369 13.2168 14.2929 13.2929C14.2168 13.369 14.1226 13.505 14.0643 13.9387C14.0021 14.4012 14 15.0289 14 16V25C14 25.9711 14.0021 26.5988 14.0643 27.0613C14.1226 27.495 14.2168 27.631 14.2929 27.7071C14.369 27.7832 14.505 27.8774 14.9387 27.9357C15.4012 27.9979 16.0289 28 17 28H23C23.9711 28 24.5988 27.9979 25.0613 27.9357C25.495 27.8774 25.631 27.7832 25.7071 27.7071C25.7832 27.631 25.8774 27.495 25.9357 27.0613C25.9979 26.5988 26 25.9711 26 25V16C26 15.0289 25.9979 14.4012 25.9357 13.9387C25.8774 13.505 25.7832 13.369 25.7071 13.2929C25.631 13.2168 25.495 13.1226 25.0613 13.0643C24.5988 13.0021 23.9711 13 23 13H17C16.0289 13 15.4012 13.0021 14.9387 13.0643Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17C16 16.4477 16.4477 16 17 16H23C23.5523 16 24 16.4477 24 17C24 17.5523 23.5523 18 23 18H17C16.4477 18 16 17.5523 16 17Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 21C16 20.4477 16.4477 20 17 20H23C23.5523 20 24 20.4477 24 21C24 21.5523 23.5523 22 23 22H17C16.4477 22 16 21.5523 16 21Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 25C16 24.4477 16.4477 24 17 24H21C21.5523 24 22 24.4477 22 25C22 25.5523 21.5523 26 21 26H17C16.4477 26 16 25.5523 16 25Z"
      fill={color}
    />
  </svg>
);

export default DocumentHighlightIcon;
