import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: ${themeColors.bg.light.strong};
  display: block;
  padding: 20px;
`;

export const FooterNavItemContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: ${isMobile() ? '30px' : '100px'};
`;

export const FooterCopyrightContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

export const FooterNavItem = styled.div`
  color: ${themeColors.white};
  width: 184px;
  text-align: center;
  display: flex;
`;

export const FooterSeparator = styled.span`
  width: 1px;
  border-right: 1px solid white;
  margin: auto;
  height: 16px;
`;

export const CopyrightText = styled.p`
  margin: 0;
  color: ${themeColors.copyColor};
  font-size: ${fontSizes.regular};
`;
