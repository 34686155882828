import { NavigationSubMenu } from 'utils/interfaces/navigation/navigationSubmenu.interface';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const navigationProfileItems: NavigationSubMenu = {
  title: 'Profile',
  submenu: [
    {
      text: 'Company details',
      path: '/company-details',
      icon: <ChevronRightIcon />
    },
    {
      text: 'User details',
      path: '/personal-details',
      icon: <ChevronRightIcon />
    },
    {
      text: 'Connections',
      path: '/connections',
      icon: <ChevronRightIcon />
    },
    {
      text: 'Contact us',
      externalPath: 'https://kanexa.net/en/contact-us',
      icon: <ChevronRightIcon />
    },
    {
      text: 'Documents',
      externalPath: 'https://supplier.kanexa.net/site/suppliers-platform-documents',
      icon: <ChevronRightIcon />
    }
  ]
};
