import { Box, LinearProgress } from '@mui/material';
import CSVDownloader from 'components/CSVDownloader';
import { CSVHeader } from 'components/CSVDownloader/CSVDownloader';
import { isMobile } from 'lib/helpers/mobile';
import { FC, useEffect } from 'react';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import { DataGridProps } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  DataGridActionContainer,
  DataGridCountContainer,
  ExportDataButtonContainer,
  TableControlsRow,
  TableCount,
  TableHeaderStyled,
  TableMUIStyled,
  TableMobileSubTitle,
  TableMobileTitleWrapper,
  TableWrapper
} from './styled';
import CustomPagination from 'components/CustomPagination';

const DataGrid: FC<DataGridProps> = ({
  headers,
  data,
  exportData,
  page,
  rowsPerPage = 10,
  serverSide,
  totalRows = 0,
  loading,
  exportFilename,
  fixedHeader = true,
  hasControls = false,
  hasActions = false,
  smallDownloadButton = true,
  selectableRows = 'none',
  rowSelectedHandler,
  rowClickHandler,
  columnSortChangeClickHandler,
  dataAutomationTag,
  footerJSX,
  customOptions,
  changeRowsPerPageHandler,
  tableChangeHandler,
  changePageHandler,
  showFooter = false,
  margin
}) => {
  const tableOptions: any = {
    serverSide: serverSide || false,
    page: page || 0,
    resizableColumns: true,
    selectableRows: selectableRows,
    rowsPerPageOptions: [10, 15, 20, 25],
    rowHover: true,
    fixedHeader,
    responsive: 'standard',
    tableBodyMaxHeight: fixedHeader ? '900px' : '',
    rowsPerPage: rowsPerPage || 10,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: 'No records found.'
      }
    },
    selectToolbarPlacement: 'none',
    onTableChange: (action: any, tableState: any) => {
      if (tableChangeHandler) tableChangeHandler(action, tableState);
    },
    onChangePage(currentPage: any) {
      if (changePageHandler) changePageHandler(currentPage);
    },
    onChangeRowsPerPage(numberOfRows: any) {
      if (changeRowsPerPageHandler) changeRowsPerPageHandler(numberOfRows);
    },
    onRowSelectionChange: (currentSelect: any, allSelected: any) => {
      if (!rowSelectedHandler) return;
      const result = allSelected.map((item: any) => {
        return data.at(item.index);
      });
      const selectedItems = result.map((item: any) => {
        return item;
      });
      rowSelectedHandler(selectedItems);
    },
    onRowClick: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      if (!rowClickHandler) return;
      rowClickHandler(rowData, rowMeta);
    },
    onColumnSortChange: (changedColumn: string, direction: string) => {
      if (!columnSortChangeClickHandler) return;
      columnSortChangeClickHandler(changedColumn, direction);
    },
    customFooter: (
      count: any,
      page: any,
      rowsPerPage: any,
      changeRowsPerPage: any,
      changePage: any,
      textLabels: any
    ) => {
      return (
        footerJSX ||
        (showFooter ? (
          <CustomPagination
            totalRows={totalRows}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changePage={changePage}
            changeRowsPerPage={changeRowsPerPage}
            textLabels={textLabels}
          />
        ) : (
          <></>
        ))
      );
    },
    ...customOptions
  };

  useEffect(() => {
    const el = document.getElementsByTagName('table')[0];
    el.setAttribute('data-automation-id', dataAutomationTag || '');
  }, [dataAutomationTag]);

  useEffect(() => {
    const el = document.getElementsByTagName('table')[0];
    el.setAttribute('data-testid', 'sp-data-grid');
  }, []);

  const styledHeaders: () => DataGridHeaderItem[] = () => {
    return headers.map((header, i) => {
      const { options } = header;
      options['customHeadLabelRender'] = (columnMeta: any) => (
        <TableHeaderStyled data-automation-id={`data-grid-table-header-${i}`}>
          {columnMeta.label}
        </TableHeaderStyled>
      );
      return header;
    });
  };

  return (
    <TableWrapper
      sx={{ backgroundColor: 'transparent' }}
      data-automation-id="data-grid-div-wrapper"
    >
      {hasControls && (
        <TableControlsRow>
          {!isMobile() && totalRows > 0 && <TableCount>{totalRows || 0} items</TableCount>}
          {isMobile() && (
            <TableMobileTitleWrapper>
              <TableMobileSubTitle>Invoices</TableMobileSubTitle>
              <TableCount>{totalRows || 0} invoices</TableCount>
            </TableMobileTitleWrapper>
          )}
          <CSVDownloader
            data={exportData || data}
            headers={headers.map((h) => ({ label: h.label, key: h.name } as CSVHeader))}
            filenamePrefix={exportFilename || 'export'}
            isSmall={smallDownloadButton}
          />
        </TableControlsRow>
      )}
      {hasActions && (
        <DataGridActionContainer>
          <ExportDataButtonContainer data-automation-id="data-grid-div-export">
            <CSVDownloader
              data={exportData || data}
              headers={headers.map((h) => ({ label: h.label, key: h.name } as CSVHeader))}
              filenamePrefix={exportFilename || 'export'}
              isSmall={smallDownloadButton}
              label="Export data"
            />
          </ExportDataButtonContainer>
          {totalRows > 0 && (
            <DataGridCountContainer data-automation-id="data-grid-p-data-count">
              {totalRows} item{totalRows === 1 ? '' : 's'}
            </DataGridCountContainer>
          )}
        </DataGridActionContainer>
      )}
      {loading && (
        <Box sx={{ width: '100%', marginBottom: '-12px' }} data-testid="sp-table-loading">
          <LinearProgress />
        </Box>
      )}
      <TableMUIStyled
        title={''}
        data={data}
        columns={styledHeaders()}
        options={tableOptions}
        rowClickable={!!rowClickHandler}
        showFooter={showFooter}
        margin={margin}
      />
      {/* <caption>{ariaCaption}</caption> */}
    </TableWrapper>
  );
};

export default DataGrid;
