/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, AccordionDetails } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import React, { FC, useEffect, useState } from 'react';
import {
  EarlyPaymentRequestAccordionStyled,
  AccordionSummaryInvoices,
  AccordionSummaryContainer,
  ArrowWrapper,
  EarlyPaymentRequestGridRowGroupText,
  GroupedTable,
  BuyerBarContainer,
  BuyerName,
  BuyerSummary,
  EarlyPaymentMobileRowContainer
} from '../styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import FundingRequestDataGridItem from 'utils/classes/data-grid/fundingRequestDataGridItem';
import SelectableItem from 'components/common/list/SelectableItem';
import { isMobile } from 'lib/helpers/mobile';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { GroupedItem } from 'utils/interfaces/early-payment/earlyPayment.interface';

interface EarlyPaymentRequestAccordionProps {
  data: GroupedItem;
  rowChangedClickHandler: (expanded: boolean, data: any) => void;
  tableAccordionCheckboxClickHandler: (e: any, data: any) => void;
  tableBodyCheckboxClickHandler: (invoice: any) => void;
  fundingRequestCalculation: FundingRequestCalculation | null;
}

const EarlyPaymentRequestAccordion: FC<EarlyPaymentRequestAccordionProps> = ({
  data,
  rowChangedClickHandler,
  tableAccordionCheckboxClickHandler,
  tableBodyCheckboxClickHandler,
  fundingRequestCalculation
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [gridData, setGridData] = useState<FundingRequestDataGridItem[]>([]);

  useEffect(() => {
    if (fundingRequestCalculation) updateInvoicesWithFundingCostsFromCalculation();
  }, [fundingRequestCalculation, fundingRequestCalculation?.invoicesSummary]);

  const invoicesTotal: (invoices: FundingRequestDataGridItem[]) => string = (invoices) => {
    if (gridData.length === 0) return '';
    const sum: number = invoices
      .filter((inv) => inv.selected)
      .reduce((acc: number, val: FundingRequestDataGridItem) => acc + val.invoiceAmount, 0);
    return `${CurrencySymbolsLookUp[invoices[0].currencySymbol]}${formatNumber(sum, 2)}`;
  };

  const updateInvoicesWithFundingCostsFromCalculation: () => void = () => {
    if (!fundingRequestCalculation) return;
    const updated: FundingRequestDataGridItem[] = data.invoices.map((inv) => {
      const matchedIndex: number = fundingRequestCalculation.invoicesSummary.findIndex(
        (s) => s.id === inv.id
      );
      inv.fundingCosts =
        matchedIndex > -1
          ? fundingRequestCalculation.invoicesSummary[matchedIndex].fundingCosts
          : '-';
      return inv;
    });

    setGridData(updated);
  };

  const invoicesSelectedCount: (invoices: FundingRequestDataGridItem[]) => number = (invoices) =>
    invoices.filter((inv) => inv.selected).length;

  const rowClickHandler = (data: any) => {
    setOpen(!open);
    rowChangedClickHandler(open, data);
  };

  const mobileView: () => JSX.Element = () => (
    <>
      <BuyerBarContainer data-testid="sp-mobile-accordion-view">
        <BuyerName data-testid="sp-buyer-name">
          <Checkbox
            style={{
              color: themeColors.text.primary,
              backgroundColor: 'transparent'
            }}
            onChange={(e) => tableAccordionCheckboxClickHandler(e, data)}
            checked={data.selected}
            indeterminate={data.indeterminate}
            inputProps={
              {
                role: 'checkbox',
                'data-testid': 'sp-clickable-mobile-checkbox'
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
          {data.buyer}
        </BuyerName>
        <BuyerSummary>
          {invoicesTotal(gridData)} | {invoicesSelectedCount(gridData)} Invoice
          {gridData.length !== 1 ? 's' : ''}
        </BuyerSummary>
      </BuyerBarContainer>
      {gridData.map((inv, i) => (
        <EarlyPaymentMobileRowContainer key={i}>
          <SelectableItem
            clickHandler={() => tableBodyCheckboxClickHandler(inv)}
            id={inv.id}
            dataArrLength={gridData.length}
            index={i}
            isLast={i === gridData.length - 1}
            title={inv.invoiceNumber}
            subTitle={`Maturity Date: ${inv.paymentDueDate}`}
            value={`${CurrencySymbolsLookUp[gridData[0].currencySymbol]}${formatNumber(
              inv.invoiceAmount,
              2
            )}`}
            subValue={`Purchase Price: ${
              CurrencySymbolsLookUp[gridData[0].currencySymbol]
            }${formatNumber(inv.purchasePrice, 2)}`}
            checked={inv.selected}
          />
        </EarlyPaymentMobileRowContainer>
      ))}
    </>
  );

  const desktopView: () => JSX.Element = () => (
    <EarlyPaymentRequestAccordionStyled
      expanded={open}
      disableGutters
      data-testid="sp-desktop-accordion-view"
    >
      <AccordionSummaryInvoices aria-controls="panel1a-content" id="panel1a-header">
        <Checkbox
          style={{
            color: themeColors.text.primary,
            backgroundColor: 'transparent'
          }}
          checked={data.selected}
          indeterminate={data.indeterminate}
          onChange={(e) => tableAccordionCheckboxClickHandler(e, data)}
          inputProps={
            {
              role: 'checkbox',
              'data-testid': 'sp-clickable-checkbox'
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
        />
        <AccordionSummaryContainer
          onClick={rowClickHandler}
          data-testid="sp-accordion-summary-container"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ArrowWrapper opened={open}>
              <ArrowRightIcon style={{ fontSize: '1.5rem', color: themeColors.icon.primary }} />
            </ArrowWrapper>
            <EarlyPaymentRequestGridRowGroupText>{data.buyer}</EarlyPaymentRequestGridRowGroupText>
          </div>
          <EarlyPaymentRequestGridRowGroupText>
            {invoicesTotal(gridData)} | {invoicesSelectedCount(gridData)} Invoice
            {gridData.length !== 1 ? 's' : ''}
          </EarlyPaymentRequestGridRowGroupText>
        </AccordionSummaryContainer>
      </AccordionSummaryInvoices>
      <AccordionDetails style={{ marginTop: '24px' }}>
        <GroupedTable>
          <tbody>
            {gridData.map((inv: any) => {
              return (
                <tr key={inv.invoiceNumber}>
                  <td>
                    <Checkbox
                      style={{
                        color: themeColors.text.primary,
                        backgroundColor: 'transparent'
                      }}
                      checked={inv.selected}
                      onChange={() => tableBodyCheckboxClickHandler(inv)}
                      inputProps={
                        {
                          role: 'checkbox',
                          'data-testid': 'sp-clickable-checkbox'
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  </td>
                  <td style={{ width: 'calc(100% / 6)' }}>{inv.invoiceNumber}</td>
                  <td style={{ width: 'calc(100% / 6)' }}>{inv.buyer}</td>
                  <td style={{ width: 'calc(100% / 6)' }}>{inv.paymentDueDate}</td>
                  <td style={{ width: 'calc(100% / 6)', textAlign: 'end' }}>
                    {CurrencySymbolsLookUp[inv.currencySymbol]}
                    {formatNumber(inv.invoiceAmount, 2)}
                  </td>
                  {inv.fundingCosts !== '-' && (
                    <td style={{ width: 'calc(100% / 6)', textAlign: 'end' }}>
                      {CurrencySymbolsLookUp[inv.currencySymbol]}
                      {formatNumber(inv.fundingCosts, 2)}
                    </td>
                  )}
                  {inv.fundingCosts === '-' && (
                    <td style={{ width: 'calc(100% / 6)', textAlign: 'end' }}>
                      {inv.fundingCosts}
                    </td>
                  )}
                  <td style={{ width: 'calc(100% / 6)', textAlign: 'end' }}>
                    {CurrencySymbolsLookUp[inv.currencySymbol]}
                    {formatNumber(inv.purchasePrice, 2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </GroupedTable>
      </AccordionDetails>
    </EarlyPaymentRequestAccordionStyled>
  );

  return isMobile() ? mobileView() : desktopView();
};

export default EarlyPaymentRequestAccordion;
