import TextInput from 'components/forms/inputs/TextInput';
import { FC } from 'react';
import {
  BankAccountProps,
  BankAccountSortCodeProps
} from 'utils/interfaces/bank-account/bankAccount.interface';
import { BankAccountContainer } from '../styled';

interface AUBankDetailsProps {
  accountNumber: BankAccountProps;
  bsbCode: BankAccountSortCodeProps;
}

const AUBankDetails: FC<AUBankDetailsProps> = ({ accountNumber, bsbCode }) => {
  return (
    <BankAccountContainer data-testid="sp-bank-account-details">
      <TextInput
        label="Account number"
        placeholder="8 - 12  digit number"
        register={accountNumber.register}
        errorMessage={accountNumber.errorMessage}
        disabled={accountNumber.disabled}
        defaultValue={accountNumber.value}
        changeHandler={accountNumber.changeHandler}
        testingTagPage="bank-account-details"
      />
      <TextInput
        label="BSB code"
        placeholder="6 digit code"
        register={bsbCode.register}
        errorMessage={bsbCode.errorMessage}
        disabled={bsbCode.disabled}
        defaultValue={bsbCode.value}
        changeHandler={bsbCode.changeHandler}
        testingTagPage="bank-account-details"
      />
    </BankAccountContainer>
  );
};

export default AUBankDetails;
