import { Alert } from '@mui/material';
import { boxShadow, themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface Alignment {
  isTopAligned: boolean;
  isCenterAligned?: boolean;
}

interface StyleType {
  type?: 'success' | 'warning' | 'error';
}

export const SnackbarStyledWrapper = styled(Alert)<StyleType>`
  display: flex;
  flex-direction: row;
  &.MuiPaper-root {
    border: none;
    border-radius: 16px;
    background-color: ${({ type }) => colorBackground(type)};
    color: ${themeColors.text.light.body};
    box-shadow: ${boxShadow};
    padding: 12px !important;
  }
  .MuiAlert-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SnackbarIconContainer = styled.div<Alignment>`
  display: flex;
  align-items: ${({ isTopAligned }) => (isTopAligned ? 'flex-start' : 'center')};
  justify-content: center;
  z-index: 10;
  cursor: pointer;
`;

export const SnackbarCloseIconContainer = styled(SnackbarIconContainer)`
  align-items: ${({ isTopAligned, isCenterAligned }) =>
    isTopAligned && !isCenterAligned ? 'flex-start' : 'center'};
`;

export const SnackbarTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobile() && '80%'};
`;

export const SnackbarLeftContainer = styled.div<Alignment>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
`;

const color: (type: 'success' | 'warning' | 'error' | undefined) => string = (type) => {
  switch (type) {
    case 'error':
      return themeColors.border.error;
    case 'warning':
      return themeColors.border.warning;
    default:
      return themeColors.border.success;
  }
};

const colorBackground: (type: 'success' | 'warning' | 'error' | undefined) => string = (type) => {
  switch (type) {
    case 'error':
      return themeColors.bg.errorMuted;
    case 'warning':
      return themeColors.bg.warningMuted;
    default:
      return themeColors.bg.successMuted;
  }
};
