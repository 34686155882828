import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { PaymentStatusContainer, PaymentStatusIndicator, PaymentStatusText } from './styled';

interface PaymentsStatusProps {
  status: 'Paid' | 'Pending';
}

const PaymentsStatus: FC<PaymentsStatusProps> = ({ status }) => {
  const color: () => string = () => {
    switch (status) {
      case 'Paid':
        return themeColors.bg.success;
      case 'Pending':
        return themeColors.bg.warning;
      default:
        return themeColors.text.light.muted;
    }
  };
  return (
    <PaymentStatusContainer>
      <PaymentStatusIndicator data-automation-id="payments-div-indicator" color={color()} />
      <PaymentStatusText data-automation-id="payments-div-status">{status}</PaymentStatusText>
    </PaymentStatusContainer>
  );
};

export default PaymentsStatus;
