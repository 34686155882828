import { Checkbox, AccordionDetails } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import React, { FC, useState } from 'react';
import {
  OutgoingPaymentRequestAccordionStyled,
  AccordionSummaryInvoices,
  AccordionSummaryContainer,
  ArrowWrapper,
  OutgoingPaymentRequestGridRowGroupText,
  GroupedTable
} from '../styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { OutgoingPaymentGroupedItem } from '../OutgoingPaymentRequest';
import OutgoingPaymentDataGridItem from 'utils/classes/data-grid/outgoingPaymentDataGridItem';

interface OutgoingPaymentRequestAccordionProps {
  data: OutgoingPaymentGroupedItem;
  rowChangedClickHandler: (expanded: boolean, data: any) => void;
  tableAccordionCheckboxClickHandler: (e: any, data: any) => void;
  tableBodyCheckboxClickHandler: (invoice: any) => void;
}

const OutgoingPaymentRequestAccordion: FC<OutgoingPaymentRequestAccordionProps> = ({
  data,
  rowChangedClickHandler,
  tableAccordionCheckboxClickHandler,
  tableBodyCheckboxClickHandler
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const invoicesTotal: (invoices: OutgoingPaymentDataGridItem[]) => string = (invoices) => {
    const sum: number = invoices
      .filter((inv) => inv.selected)
      .reduce((acc: number, val: OutgoingPaymentDataGridItem) => acc + val.invoiceAmount, 0);
    return `${CurrencySymbolsLookUp[invoices[0].currencySymbol]}${formatNumber(sum, 2)}`;
  };

  const invoicesSelectedCount: (invoices: OutgoingPaymentDataGridItem[]) => number = (invoices) =>
    invoices.filter((inv) => inv.selected).length;

  const rowClickHandler = (data: any) => {
    setOpen(!open);
    rowChangedClickHandler(open, data);
  };

  return (
    <OutgoingPaymentRequestAccordionStyled expanded={open} disableGutters>
      <AccordionSummaryInvoices aria-controls="panel1a-content" id="panel1a-header">
        <Checkbox
          style={{
            color: themeColors.text.primary,
            backgroundColor: 'transparent'
          }}
          checked={data.selected}
          indeterminate={data.indeterminate}
          onChange={(e) => tableAccordionCheckboxClickHandler(e, data)}
          inputProps={
            {
              'data-testid': 'sp-clickable-checkbox'
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
        />
        <AccordionSummaryContainer
          onClick={rowClickHandler}
          data-testid="sp-accordion-summary-container"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ArrowWrapper opened={open}>
              <ArrowRightIcon style={{ fontSize: '1.5rem', color: themeColors.icon.primary }} />
            </ArrowWrapper>
            <OutgoingPaymentRequestGridRowGroupText>
              {data.buyer}
            </OutgoingPaymentRequestGridRowGroupText>
          </div>
          <OutgoingPaymentRequestGridRowGroupText>
            {invoicesTotal(data.invoices)} | {invoicesSelectedCount(data.invoices)} Invoice
            {data.invoices.length !== 1 ? 's' : ''}
          </OutgoingPaymentRequestGridRowGroupText>
        </AccordionSummaryContainer>
      </AccordionSummaryInvoices>
      <AccordionDetails style={{ marginTop: '24px' }}>
        <GroupedTable>
          <tbody>
            {data.invoices.map((inv: any) => {
              return (
                <tr key={inv.invoiceNumber}>
                  <td>
                    <Checkbox
                      style={{
                        color: themeColors.text.primary,
                        backgroundColor: 'transparent'
                      }}
                      checked={inv.selected}
                      onChange={() => tableBodyCheckboxClickHandler(inv)}
                      inputProps={
                        {
                          'data-testid': 'sp-clickable-checkbox'
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  </td>
                  <td style={{ width: 'calc(100% / 5)', textAlign: 'left' }}>
                    {inv.invoiceNumber}
                  </td>
                  <td style={{ width: 'calc(100% / 5)', textAlign: 'left' }}>{inv.buyer}</td>
                  <td style={{ width: 'calc(100% / 5)', textAlign: 'left' }}>
                    {inv.paymentDueDate}
                  </td>
                  <td style={{ width: 'calc(100% / 5)', textAlign: 'right' }}>
                    {CurrencySymbolsLookUp[inv.currencySymbol]}
                    {formatNumber(inv.invoiceAmount, 2)}
                  </td>
                  <td style={{ width: 'calc(100% / 5)', textAlign: 'left' }}></td>
                </tr>
              );
            })}
          </tbody>
        </GroupedTable>
      </AccordionDetails>
    </OutgoingPaymentRequestAccordionStyled>
  );
};

export default OutgoingPaymentRequestAccordion;
