/* eslint-disable react-hooks/exhaustive-deps */
import { Logistics } from 'api/interfaces/logistics/logistics.interface';
import WarningIcon from 'assets/icons/WarningIcon';
import { layoutSizes, themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import DataGrid from 'components/DataGrid';
import Chip from 'components/common/Chip';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { _matchingDiscrepantStatus } from 'lib/constants/matching';
import { ledgerLogisticsHeaders } from 'lib/data/ledger/ledgerLogisticsHeaders';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { FETCH_LOGISTICS_BY_DOCUMENT_ID, RESET_LOGISTICS_DATA } from 'store/actions';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  LedgerLogisticsContainer,
  LedgerLogisticsMobileContainer,
  LedgerLogisticsMobileItemBold,
  LedgerLogisticsMobileItemLeft,
  LedgerLogisticsMobileItemRegular,
  LedgerLogisticsMobileItemRight,
  LedgerLogisticsMobileWrapper,
  LedgerLogisticsNoData,
  LedgerLogisticsSubtitle,
  LedgerLogisticsTitle,
  LedgerLogisticsWrapper
} from './styled';
interface LedgerLogisticsProps {
  type: keyof typeof AssetEnum;
}
export interface LogisticItem {
  documentDescription: string;
  trackingNumber: string;
  status: string;
  createdDate: string;
  isDiscrepant: boolean;
}

const LedgerLogistics: FC<LedgerLogisticsProps> = ({ type }) => {
  const params: Readonly<Params<string>> = useParams();
  const layoutRef: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const [layoutWidth, setLayoutWidth]: [number, Dispatch<SetStateAction<number>>] =
    useState<number>(0);
  const dispatch = useDispatch();
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const { logistics, loading }: { logistics: Logistics[]; loading: boolean } = useSelector(
    (state: RootStateOrAny) => state.ledger
  );

  const {
    breadcrumbValues
  }: {
    breadcrumbValues: { [key: string]: string } | null;
  } = useSelector((state: RootStateOrAny) => state.breadcrumb);

  const clientWidth: number = layoutRef?.current?.clientWidth || 0;

  useEffect(() => {
    getLayoutSize();
    window.addEventListener('resize', getLayoutSize);

    return () => {
      window.removeEventListener('resize', getLayoutSize);
    };
  }, [clientWidth]);

  const getLayoutSize: () => void = () => {
    const newWidth: number = layoutRef?.current?.clientWidth || clientWidth;
    setLayoutWidth(newWidth);
  };

  useEffect(() => {
    if (!breadcrumbValues) return;
    if (!params.invoiceId && !params.purchaseOrderId) return;
    const payload = params.invoiceId
      ? {
          documentNumber: breadcrumbValues[':invoiceId'],
          documentType: LedgerTypes.INVOICE
        }
      : {
          documentNumber: breadcrumbValues[':purchaseOrderId'],
          documentType: LedgerTypes.PO
        };
    dispatch({
      type: FETCH_LOGISTICS_BY_DOCUMENT_ID,
      payload
    });

    return () => {
      dispatch({
        type: RESET_LOGISTICS_DATA
      });
    };
  }, [params.invoiceId, params.purchaseOrderId, breadcrumbValues]);

  useEffect(() => {
    if (!logistics) return;
    setData(mapLogisticItemsToGridModel());
  }, [logistics]);

  const mapLogisticItemsToGridModel: () => DataGridRowItem[] = () =>
    logistics &&
    logistics.map(
      (log): LogisticItem => ({
        documentDescription: log.data.subTemplate?.name || '',
        trackingNumber: log.data.trackingNumber || '-',
        status: log.linkedPrograms[0]?.logisticStatus || '',
        createdDate: formatDateTime(log.createdAt || '', 'yyyy/MM/dd HH:mm'),
        isDiscrepant: log.linkedPrograms[0]?.matchingStatus === _matchingDiscrepantStatus
      })
    );

  const renderTable: () => JSX.Element = () =>
    layoutWidth < layoutSizes.maxMobileWidth ? (
      <>
        {data.map((data, index, array) => (
          <LedgerLogisticsMobileWrapper key={index}>
            <LedgerLogisticsMobileContainer>
              <LedgerLogisticsMobileItemLeft>
                <LedgerLogisticsMobileItemBold>
                  {data.documentDescription}
                </LedgerLogisticsMobileItemBold>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                  {data.status && (
                    <Chip
                      type={data.status}
                      color={themeColors.text.primary}
                      radius="3px"
                      height="18px"
                      fontSize={fontSizes.small}
                      semibold="bold"
                      testingTag="ledger-logistic"
                    />
                  )}
                  {data.isDiscrepant && (
                    <WarningIcon color={themeColors.white} fill={themeColors.icon.error} />
                  )}
                </div>
              </LedgerLogisticsMobileItemLeft>
              <LedgerLogisticsMobileItemRight>
                <LedgerLogisticsMobileItemBold>{data.trackingNumber}</LedgerLogisticsMobileItemBold>
                <LedgerLogisticsMobileItemRegular>
                  {data.createdDate}
                </LedgerLogisticsMobileItemRegular>
              </LedgerLogisticsMobileItemRight>
            </LedgerLogisticsMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LedgerLogisticsMobileWrapper>
        ))}
      </>
    ) : (
      <DataGrid
        headers={ledgerLogisticsHeaders}
        data={data}
        ariaLabel="Ledger Logistics Artefacts Table"
        ariaCaption="A table that shows the logistics artefacts."
        totalRows={data.length}
        exportFilename={''}
        fixedHeader
        dataAutomationTag="ledger-logistics-table-ledger-items"
      />
    );

  return (
    <LedgerLogisticsWrapper>
      {loading && layoutWidth > 0 ? (
        <LoaderInPage />
      ) : (
        <LedgerLogisticsContainer ref={layoutRef}>
          {data.length > 0 ? (
            <>
              <LedgerLogisticsTitle data-automation-id="ledger-logistics-h4-page-title">
                Logistics
              </LedgerLogisticsTitle>
              <LedgerLogisticsSubtitle data-automation-id="ledger-logistics-p-page-description">
                The following documents are all related to this{' '}
                {params.invoiceId ? `invoice` : `purchase order`}.
              </LedgerLogisticsSubtitle>
              {renderTable()}
            </>
          ) : (
            <LedgerLogisticsNoData data-automation-id="ledger-logistics-h5-no-data">
              Logistic unavailable.
            </LedgerLogisticsNoData>
          )}
        </LedgerLogisticsContainer>
      )}
    </LedgerLogisticsWrapper>
  );
};

export default LedgerLogistics;
