import GetPaidNowFilledIcon from 'assets/icons/GetPaidNowFilledIcon';
import { themeColors } from 'assets/theme/style';
import BaseCard from 'components/common/cards/BaseCard';
import EarlyPaymentButton from 'components/early-payment/EarlyPaymentButton';
import Typography from 'components/typography';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { FC } from 'react';
import { PanelBannerRow, PanelBannerSegment } from '../styled';
import { useSelector, RootStateOrAny } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

const GetPaidNowAvailablePanel: FC = () => {
  const {
    activeCurrencyCode,
    advancedInvoicesTotal,
    internalLoading
  }: {
    activeCurrencyCode: string;
    advancedInvoicesTotal: number;
    internalLoading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);
  return (
    <BaseCard
      maxHeight="102px"
      backgroundColor={`${themeColors.bg.infoMuted}`}
      noBorder
      noBoxShadow
    >
      {internalLoading ? (
        <PanelBannerRow>
          <PanelBannerRow>
            <Skeleton variant="circular" width="40px" height="40px" />
            <Skeleton variant="rectangular" width="200px" />
          </PanelBannerRow>
          <Skeleton animation="wave" height="50px" width="10rem" />
        </PanelBannerRow>
      ) : (
        <PanelBannerRow>
          <PanelBannerRow>
            <GetPaidNowFilledIcon />
            <PanelBannerSegment>
              <Typography
                tag="h3"
                color={themeColors.text.light.body}
                testId="home-h4-get-paid-now-value"
              >
                {activeCurrencyCode} {formatNumber(advancedInvoicesTotal || 0, 2)}
              </Typography>
              <Typography
                tag="p"
                color={themeColors.text.light.body}
                testId="home-p-get-paid-now-description"
              >
                Available funds
              </Typography>
            </PanelBannerSegment>
          </PanelBannerRow>
          <PanelBannerSegment>
            <EarlyPaymentButton padding="16px" height="52px" borderRadius="6px" />
          </PanelBannerSegment>
        </PanelBannerRow>
      )}
    </BaseCard>
  );
};

export default GetPaidNowAvailablePanel;
