import HomeIcon from '@mui/icons-material/Home';
import { themeColors } from 'assets/theme/style';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { TenantCard, TenantSelectionContainer } from './styled';

const TenantSelection: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const tenants: any[] = useSelector((state: RootStateOrAny) => state.app.tenants);

  useEffect(() => {
    if (tenants?.length > 1) {
      setTimeout(() => setLoading(false), 1500);
    }
  }, [tenants]);

  const selectionHandler: (tenant: string) => void = (tenant) =>
    (window.location.href = `${window.location.origin}/app/${tenant}`);

  return (
    <LayoutViewContainer size="small" margin="16px 0 0 0">
      {loading ? (
        <LoaderFullPage />
      ) : (
        <>
          <h2 data-automation-id="tenant-select-view-h2-title">Please select a company</h2>
          <TenantSelectionContainer data-automation-id="tenant-select-view-div-container">
            {tenants.map((t) => (
              <TenantCard key={t.tenantId} onClick={() => selectionHandler(t.tenantId)}>
                <HomeIcon style={{ color: themeColors.icon.primary, fontSize: '4.5rem' }} />
                <h3>{t.name}</h3>
              </TenantCard>
            ))}
          </TenantSelectionContainer>
        </>
      )}
    </LayoutViewContainer>
  );
};

export default TenantSelection;
