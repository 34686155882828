/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import { IUser } from 'api/interfaces/users/user.interface';
import BannerIcon from 'assets/icons/BannerIcon';
import ProfileIcon from 'assets/icons/ProfileIcon';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import Sidebar from 'components/sidebar';
import { UserActionStatusesEnum, UserStatusesEnum } from 'lib/enums/user/userStatuses.enum';
import { getCookie, setCookie } from 'lib/helpers/cookieHelper';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { GET_USER, UPDATE_USER_ACTION } from 'store/actions';
import {
  AccordionStyled,
  AccordionSummaryHeader,
  AccordionSummaryTitle,
  AccordionSummaryTitleName,
  AccordionSummaryTitleWrapper
} from 'views/CompanyDetails/styled';
import PersonalDetailsEditDialog from './PersonalDetailsEditDialog';
import {
  PersonalDetailsActionRow,
  PersonalDetailsButtonContainer,
  PersonalDetailsContainer,
  PersonalDetailsContainerColumn,
  PersonalDetailsContentContainer,
  PersonalDetailsContentRow,
  PersonalDetailsContentRowLabel,
  PersonalDetailsContentRowSegment,
  PersonalDetailsContentRowValue,
  PersonalDetailsContentWrapper,
  PersonalDetailsError,
  PersonalDetailsPreferenceTitle,
  PersonalDetailsPreferenceValue,
  PersonalDetailsWrapper,
  StyledCheckbox
} from './styled';
import { useAuth } from 'react-oidc-context';

interface IIterableField {
  display: string;
  key: string;
}

const _iterableFields: IIterableField[] = [
  {
    display: 'Name',
    key: 'name'
  },
  {
    display: 'Email',
    key: 'user.email'
  }
];

const PersonalDetails: FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [poFlipEnabled, setPOFlipEnabled] = useState<boolean>(false);
  const [poFlipDisabled, setPOFlipDisabled] = useState<boolean>(false);
  const [invoiceFlipDisabled, setInvoiceFlipDisabled] = useState<boolean>(false);
  const [invoiceFlipEnabled, setInvoiceFlipEnabled] = useState<boolean>(false);
  const { loading, error }: { loading: boolean; error: string } = useSelector(
    (state: RootStateOrAny) => state.user
  );

  const {
    loading: appLoading,
    allProgramsWithDetails
  }: {
    loading: boolean;
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const lottieLoader: JSX.Element = <LoaderInPage />;

  useEffect(() => {
    const poFlip = getCookie('spPoFlipEnabled');
    const invoiceFlip = getCookie('spInvoiceFlipEnabled');
    const matchedProgramPOFlipRule =
      allProgramsWithDetails.map(
        (p) =>
          p?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value?.ruleByAssetType?.PURCHASE_ORDER
      ) || null;

    const matchedProgramInvoiceFlipRule =
      allProgramsWithDetails.map(
        (p) => p?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value?.ruleByAssetType?.INVOICE
      ) || null;

    const hasProgramLevelPOFlipOn =
      matchedProgramPOFlipRule.filter((r) => r?.suppliers?.length > 0 || r?.enabledForAllSuppliers)
        .length > 0;

    const hasProgramLevelInvoiceFlipOn =
      matchedProgramInvoiceFlipRule.filter(
        (r) => r?.suppliers?.length > 0 || r?.enabledForAllSuppliers
      ).length > 0;

    if (hasProgramLevelPOFlipOn) setCookie('spPoFlipEnabled', `true`, 365);
    if (hasProgramLevelInvoiceFlipOn) setCookie('spInvoiceFlipEnabled', `true`, 365);

    if (!hasProgramLevelPOFlipOn) setPOFlipDisabled(true);
    if (!hasProgramLevelInvoiceFlipOn) setInvoiceFlipDisabled(true);
    if (hasProgramLevelPOFlipOn && poFlip === 'true') setPOFlipEnabled(true);
    if (hasProgramLevelInvoiceFlipOn && invoiceFlip === 'true') setInvoiceFlipEnabled(true);
  }, []);

  const toggleDialog: () => void = () => setEditDialogOpen(!editDialogOpen);

  // const resetClickHandler: () => void = () => {
  //   if (!loggedInUser?.id) return;
  //   dispatch({
  //     type: UPDATE_USER_ACTION,
  //     payload: { userId: loggedInUser.id, type: UserActionStatusesEnum.RESET }
  //   });
  //   refetchLoggedInUser();
  // };

  const refetchLoggedInUser: () => void = () => dispatch({ type: GET_USER });

  const handlePoFlipChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    setPOFlipEnabled(event.target.checked);
    setCookie('spPoFlipEnabled', `${event.target.checked}`, 365);
  };

  const handleInvoiceFlipChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    setInvoiceFlipEnabled(event.target.checked);
    setCookie('spInvoiceFlipEnabled', `${event.target.checked}`, 365);
  };

  const renderProfileDetails: () => JSX.Element = () =>
    loading ? (
      lottieLoader
    ) : (
      <AccordionDetails data-testid="sp-company-details-accordion-container">
        <PersonalDetailsContentContainer data-testid="sp-personal-details-accordion-container">
          <PersonalDetailsContentRow>
            {_iterableFields.map((field) => (
              <PersonalDetailsContentRowSegment key={field.key}>
                <PersonalDetailsContentRowLabel
                  data-automation-id={`personal-details-p-accordion-summary-detail-label-${field.key}`}
                >
                  {field.display}
                </PersonalDetailsContentRowLabel>
                <PersonalDetailsContentRowValue
                  data-automation-id={`personal-details-p-accordion-summary-detail-value-${field.key}`}
                >
                  {(auth.user?.profile as any)[field.key] || '--'}
                </PersonalDetailsContentRowValue>
              </PersonalDetailsContentRowSegment>
            ))}
            <PersonalDetailsContentRowSegment>
              <PersonalDetailsContentRowLabel
                data-automation-id={`personal-details-p-accordion-summary-detail-label-account-status`}
              >
                Account status
              </PersonalDetailsContentRowLabel>
              <PersonalDetailsContentRowValue
                data-automation-id={`personal-details-p-accordion-summary-detail-value-account-status`}
              >
                Active
              </PersonalDetailsContentRowValue>
            </PersonalDetailsContentRowSegment>
          </PersonalDetailsContentRow>
          {/* <PersonalDetailsActionRow>
            <PersonalDetailsButtonContainer>
              <PrimaryButton
                clickHandler={toggleDialog}
                testingTag="personal-details-button-create-invoice"
                testingTagExt="-personal-details-create-invoice"
                text="Edit"
              />
            </PersonalDetailsButtonContainer>
            {isUserAdmin && loggedInUser?.status !== UserStatusesEnum.RECOVERY && (
              <PersonalDetailsButtonContainer>
                <SecondaryButton
                  backgroundColor="transparent"
                  clickHandler={resetClickHandler}
                  testingTag="personal-details-button-modified-invoice-selection-cancel"
                  disabled={!loggedInUser?.id || loggedInUser.status === UserStatusesEnum.RECOVERY}
                  text={'Reset password'}
                />
              </PersonalDetailsButtonContainer>
            )}
          </PersonalDetailsActionRow> */}
        </PersonalDetailsContentContainer>
      </AccordionDetails>
    );

  const renderProfileBannerDetails: () => JSX.Element = () =>
    loading ? (
      lottieLoader
    ) : (
      <AccordionDetails data-testid="sp-company-details-accordion-container">
        <PersonalDetailsContentContainer data-testid="sp-personal-details-accordion-container">
          <PersonalDetailsContentRow>
            <PersonalDetailsContainerColumn>
              <PersonalDetailsPreferenceTitle disabled={poFlipDisabled}>
                PO flip
              </PersonalDetailsPreferenceTitle>
              <PersonalDetailsPreferenceValue disabled={poFlipDisabled}>
                By enabling this option you will be able to generate invoices from purchase orders
              </PersonalDetailsPreferenceValue>
            </PersonalDetailsContainerColumn>

            <StyledCheckbox
              id="sp-po-flip-toggle"
              checked={poFlipEnabled}
              disabled={poFlipDisabled}
              onChange={handlePoFlipChange}
            />
          </PersonalDetailsContentRow>
          <PersonalDetailsContentRow>
            <PersonalDetailsContainerColumn>
              <PersonalDetailsPreferenceTitle disabled={invoiceFlipDisabled}>
                Invoice flip
              </PersonalDetailsPreferenceTitle>
              <PersonalDetailsPreferenceValue disabled={invoiceFlipDisabled}>
                By enabling this option you will be able to generate logistics artefacts from
                invoices
              </PersonalDetailsPreferenceValue>
            </PersonalDetailsContainerColumn>
            <StyledCheckbox
              id="sp-inv-flip-toggle"
              checked={invoiceFlipEnabled}
              disabled={invoiceFlipDisabled}
              onChange={handleInvoiceFlipChange}
            />
          </PersonalDetailsContentRow>
        </PersonalDetailsContentContainer>
      </AccordionDetails>
    );

  return (
    <PersonalDetailsWrapper
      data-automation-id="personal-details-div-container"
      data-testid="sp-personal-details-container"
    >
      <Sidebar />
      <PersonalDetailsContainer>
        <BaseCard noBorder noBoxShadow backgroundColor="transparent">
          {loading || appLoading ? (
            <LoaderInPage />
          ) : (
            <>
              {error ? (
                <PersonalDetailsError data-testid="sp-personal-details-error">
                  Can not retrieve your user details.
                </PersonalDetailsError>
              ) : (
                <PersonalDetailsContentWrapper>
                  <AccordionStyled defaultExpanded disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionSummaryHeader>
                        <ProfileIcon />
                        <AccordionSummaryTitleWrapper>
                          <AccordionSummaryTitle data-automation-id="company-details-h4-accordion-title-company-details">
                            My profile
                          </AccordionSummaryTitle>
                          <AccordionSummaryTitleName>
                            {auth.user?.profile.name || ''}{' '}
                          </AccordionSummaryTitleName>
                        </AccordionSummaryTitleWrapper>
                      </AccordionSummaryHeader>
                    </AccordionSummary>
                    {renderProfileDetails()}
                  </AccordionStyled>

                  <AccordionStyled defaultExpanded disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionSummaryHeader>
                        <BannerIcon />
                        <AccordionSummaryTitleWrapper>
                          <AccordionSummaryTitle data-automation-id="company-details-h4-accordion-title-company-details">
                            Flip controls
                          </AccordionSummaryTitle>
                        </AccordionSummaryTitleWrapper>
                      </AccordionSummaryHeader>
                    </AccordionSummary>
                    {renderProfileBannerDetails()}
                  </AccordionStyled>
                </PersonalDetailsContentWrapper>
              )}
            </>
          )}
        </BaseCard>
      </PersonalDetailsContainer>
      {editDialogOpen && (
        <FullViewDialog
          open={editDialogOpen}
          clickHandler={toggleDialog}
          dialogContent={<PersonalDetailsEditDialog closeHandler={toggleDialog} />}
          data-testid="sp-user-management-invite-view-dialog"
        />
      )}
    </PersonalDetailsWrapper>
  );
};

export default PersonalDetails;
