import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import { dateFormatForIso } from 'lib/helpers/formatters/datetimeFormatters';
import { DateTime } from 'luxon';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import WarningIcon from 'assets/icons/WarningIcon';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { getPOStatusTooltipText } from 'lib/tooltips/tooltips';

export const purchaseOrdersHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('', 'purchaseOrderId', {
    display: false
  }),
  new DataGridHeaderItem('', 'isDiscrepant', {
    display: false
  }),
  new DataGridHeaderItem(
    'PO Number',
    'purchaseOrderNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Status', 'status', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <Chip
            type={value}
            color={themeColors.text.primary}
            radius="3px"
            height="18px"
            fontSize={fontSizes.small}
            semibold="bold"
            testingTag="purchase-orders"
            tooltipText={getPOStatusTooltipText(value)}
          />
          {tableMeta.rowData[1] && (
            <IconTooltip
              tooltipText="There is at least one discrepant invoice associated with this Purchase Order."
              children={<WarningIcon color={themeColors.icon.error} height="16" width="16" />}
            />
          )}
        </div>
      );
    }
  }),
  new DataGridHeaderItem('Buyer', 'buyer', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'createdDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Expiry date', 'expiryDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Total Amount', 'totalAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Available Amount', 'availableAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  })
];
