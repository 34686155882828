import {
  MatchingRulesContainer,
  MatchingRulesDescription,
  MatchingRulesValuesContainer,
  MatchingRulesValuesTitle,
  MatchingRulesValuesWrapper,
  MatchingRulesRuleContainer,
  MatchingRulesRuleCaption,
  MatchingRulesRuleDescription,
  MatchingRulesValuesArrows,
  MatchingRulesValuesRow
} from './styled';
import { FC } from 'react';
import CompareCard from 'components/common/cards/CompareCard';
import { LeftLongArrowIcon, RightLongArrowIcon } from 'assets/icons/ArrowIcons';

export interface DiscrepantValuesType {
  title: string;
  info: string;
  subtitle: string;
  value: string;
  documentNumber: string;
  documentPath: string;
}

export interface MatchingRulesProps {
  title: string;
  rule: string;
  values: DiscrepantValuesType[];
}

const MatchingRules: FC<MatchingRulesProps> = ({ title, rule, values }) => {
  return (
    <MatchingRulesContainer data-automation-id="ledger-matching-div-matching-rules-container">
      {/* <MatchingRulesDescription data-automation-id="ledger-matching-div-matching-rules-description-title">
        {title}
      </MatchingRulesDescription> */}
      <MatchingRulesValuesContainer>
        <MatchingRulesValuesTitle data-automation-id="ledger-matching-h4-matching-rules-value-title">
          Values compared
        </MatchingRulesValuesTitle>
        <MatchingRulesValuesWrapper>
          {values
            .slice()
            .reverse()
            .map((item: DiscrepantValuesType, index, array) => (
              <MatchingRulesValuesRow key={`${index}-card`} flex={index === 0 ? '60%' : '47%'}>
                <CompareCard
                  title={item.title}
                  info={item.info}
                  subtitle={item.subtitle}
                  value={item.value}
                  documentNumber={item.documentNumber}
                  documentPath={item.documentPath}
                />
                {array.length > index + 1 && (
                  <MatchingRulesValuesArrows key={`${Math.random}-arrows`}>
                    <LeftLongArrowIcon />
                    <RightLongArrowIcon />
                  </MatchingRulesValuesArrows>
                )}
              </MatchingRulesValuesRow>
            ))}
        </MatchingRulesValuesWrapper>
      </MatchingRulesValuesContainer>
      <MatchingRulesRuleContainer>
        <MatchingRulesRuleCaption data-automation-id="ledger-matching-h4-matching-rules-rule-caption">
          Rule:
        </MatchingRulesRuleCaption>
        <MatchingRulesRuleDescription data-automation-id="ledger-matching-h4-matching-rules-rule-description">
          {rule}
        </MatchingRulesRuleDescription>
      </MatchingRulesRuleContainer>
    </MatchingRulesContainer>
  );
};

export default MatchingRules;
