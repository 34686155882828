import { Accordion } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    background-color: ${themeColors.bg.light.surface};
    border-radius: 16px!important;
    padding: 21px 0;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 24px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.light.default};
  }
  .MuiButtonBase-root {
    width: 100%;
    
    padding: ${isMobile() ? '0 16px' : '0 24px'};
    
  }
  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '0 16px' : '0 24px'};
    ${typography.bodyRegular};
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;
export const LedgerDetailAccordionTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LedgerDetailAccordionTitle = styled.h3`
  margin: 0;
  color: ${themeColors.text.light.body};
`;

export const LedgerDetailAccordionTitleRight = styled.p`
  margin: 0;
  padding: 0;
  ${typography.bodyRegular};
  color: ${themeColors.text.light.subtle};
`;

export const LedgerDetailAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
