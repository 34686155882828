import loader from 'assets/lottie-animations/vertical_loader.json';
import Lottie from 'lottie-react';
import { FC } from 'react';
import { FullPageLoaderContainer, FullPageLoaderHeading, FullPageLoaderText } from './styled';

interface LoaderFullPageProps {
  detail?: string;
}

const LoaderFullPage: FC<LoaderFullPageProps> = ({ detail }) => (
  <FullPageLoaderContainer>
    <FullPageLoaderHeading>Just a moment</FullPageLoaderHeading>
    <FullPageLoaderText>{detail || `We’re preparing your information`}</FullPageLoaderText>
    <Lottie
      style={{
        height: '10vh'
      }}
      loop
      animationData={loader}
      data-testid="sp-loader-full-page"
      data-automation-id="loader"
    />
  </FullPageLoaderContainer>
);

export default LoaderFullPage;
