import { Accordion } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Checkbox } from '@mui/material';
import Lottie from 'lottie-react';
import { isMobile } from 'lib/helpers/mobile';

interface ArrowProps {
  opened: boolean;
}

interface ButtonProps {
  height?: string;
  width?: string;
}

interface EarlyPaymentRequestNameValueRowProps {
  margin?: string;
}

export const EarlyPaymentRequestWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  padding: ${isMobile() ? '0 16px' : 0};
`;

export const EarlyPaymentRequestGridRowGroupText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const CheckboxStyled = styled(Checkbox)`
  color: ${themeColors.text.primary};
`;

export const AccordionSummaryInvoices = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    //expandIcon={<ArrowRightIcon style={{ fontSize: '1.5rem', color: themeColors.icon.primary }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '4px'
  }
}));

export const ArrowWrapper = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ opened }) => (opened ? 'rotate(90deg)' : '')};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
`;

export const GroupedTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    padding: 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
  }
  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
`;

export const EarlyPaymentRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${isMobile() ? 'space-between' : ''};
  flex: ${isMobile() ? 1 : 0.5};
`;

export const EarlyPaymentRequestTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.primary};
  margin-bottom: ${isMobile() ? '12px' : '32px'};
`;

export const EarlyPaymentRequestName = styled.p`
  margin: 0;
  ${typography.bodyRegular};
`;

export const EarlyPaymentRequestData = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const EarlyPaymentRequestAccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    margin: 0 -16px;
    ::before {
      display: none;
    }
  }
  .MuiButtonBase-root {
    width: calc(100% - 16px);
    padding: 0 16px;

    .MuiAccordionSummary-content {
      .MuiButtonBase-root {
        width: auto;
        padding: 0;
      }
    }
  }
`;

export const AccordionSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const EarlyPaymentInvoicesSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

export const EarlyPaymentRequestNameValueRow = styled.div<EarlyPaymentRequestNameValueRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const EarlyPaymentRequestActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const EarlyPaymentRequestButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || ''};
  font-family: 'Source Sans Pro', sans-serif;
`;

export const EarlyPaymentRequestButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
  font-family: 'Source Sans Pro', sans-serif;
`;

export const TableMUIStyled = styled(MUIDataTable)`
  min-width: 600px;
  .MuiToolbar-root {
    display: none;
  }
  th {
    padding: 0 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      :hover {
        background-color: ${themeColors.mono6} !important;
      }
    }
  }
  [class*='MUIDataTableResize-resizer'] {
    border: 1px solid transparent !important;
    cursor: ew-resize;
  }
`;

export const EarlyPaymentLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const GeneratingEarlyPaymentRequestLottieAnimation = styled(Lottie)`
  display: flex;
  height: 200px;
  margin-top: 25vh;
`;

export const EarlyPaymentNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
`;

export const EarlyPaymentRequestLoadingTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.primary};
  text-align: center;
  margin-top: 24px;
`;

export const EarlyPaymentRequestInvoicesRightSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

// MOBILE
export const EarlyPaymentRequestMobileActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const EarlyPaymentRequestMobileButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => height};
  font-family: 'Source Sans Pro', sans-serif;
`;

export const EarlyPaymentRequestMobileTopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EarlyPaymentRequestMobileBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const BuyerBarContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 34px;
  background-color: ${themeColors.lightBlue}80;
  padding: 8px 8px 8px 0;
  border-radius: 6px;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const BuyerName = styled.p`
  ${typography.bodySemiBold};
  margin: 0;
`;

export const BuyerSummary = styled.p`
  ${typography.bodySemiBold};
  margin: 0;
`;

export const EarlyPaymentMobileRowContainer = styled.div`
  display: flex;
  padding-left: 9px;
  flex-direction: column;
`;

export const EarlyPaymentRequestMobileTableViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const EarlyPaymentRequestMobileTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
`;

export const EarlyPaymentRequestMobileTableUpdateButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 16px;
  width: 90%;
`;
