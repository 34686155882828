import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const CloseIcon: FC<IconSvgProps> = ({
  color = themeColors.text.light.subtle,
  height = '12px',
  width = '12px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-close-icon"
    data-testid="sp-close-icon"
  >
    <path
      d="M11.8334 1.34166L10.6584 0.166664L6.00008 4.825L1.34175 0.166664L0.166748 1.34166L4.82508 6L0.166748 10.6583L1.34175 11.8333L6.00008 7.175L10.6584 11.8333L11.8334 10.6583L7.17508 6L11.8334 1.34166Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
