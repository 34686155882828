import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const Bin: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '18px',
  width = '18px'
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-bin-icon"
  >
    <path
      d="M5.48984 17.5569H12.5698C13.3798 17.5569 14.0098 16.8969 14.0098 16.1169V6.36688H14.6398C14.8198 6.36688 14.9398 6.24688 14.9398 6.06688V5.37688C14.9398 4.74688 14.4298 4.26688 13.8298 4.26688H11.2798V3.48688C11.2798 2.97688 10.8598 2.55688 10.3498 2.55688H7.67984C7.16984 2.55688 6.74984 2.97688 6.74984 3.48688V4.26688H4.19984C3.56984 4.26688 3.08984 4.77688 3.08984 5.37688V6.06688C3.08984 6.24688 3.20984 6.36688 3.38984 6.36688H4.01984V16.1169C4.01984 16.8969 4.67984 17.5569 5.48984 17.5569ZM13.4098 16.1169C13.4098 16.5969 13.0198 16.9569 12.5698 16.9569H5.48984C5.00984 16.9569 4.64984 16.5669 4.64984 16.1169V6.36688H13.4398V16.1169H13.4098ZM7.34984 3.48688C7.34984 3.30688 7.49984 3.15688 7.67984 3.15688H10.3498C10.5298 3.15688 10.6798 3.30688 10.6798 3.48688V4.26688H7.37984V3.48688H7.34984ZM3.68984 5.37688C3.68984 5.07688 3.92984 4.86688 4.19984 4.86688H13.7998C14.0998 4.86688 14.3098 5.10688 14.3098 5.37688V5.76688H3.68984V5.37688Z"
      fill={color}
    />
    <path
      d="M11.3704 15.5169C11.5504 15.5169 11.6704 15.3969 11.6704 15.2169V8.07686C11.6704 7.89686 11.5504 7.77686 11.3704 7.77686C11.1904 7.77686 11.0704 7.89686 11.0704 8.07686V15.2169C11.0704 15.3969 11.2204 15.5169 11.3704 15.5169ZM9.03035 15.5169C9.21035 15.5169 9.33035 15.3969 9.33035 15.2169V8.07686C9.33035 7.89686 9.21035 7.77686 9.03035 7.77686C8.85035 7.77686 8.73035 7.89686 8.73035 8.07686V15.2169C8.73035 15.3969 8.85035 15.5169 9.03035 15.5169ZM6.66035 15.5169C6.84035 15.5169 6.96035 15.3969 6.96035 15.2169V8.07686C6.96035 7.89686 6.84035 7.77686 6.66035 7.77686C6.48035 7.77686 6.36035 7.89686 6.36035 8.07686V15.2169C6.36035 15.3969 6.48035 15.5169 6.66035 15.5169Z"
      fill={color}
    />
  </svg>
);

export default Bin;
