export const POST_LOGISTIC_COMMENTS = '@@frontend/store/reducers/logistic/POST_LOGISTIC_COMMENTS';
export const POST_LOGISTIC_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/logistic/POST_LOGISTIC_COMMENTS_SUCCESS';
export const POST_LOGISTIC_COMMENTS_FAILURE =
  '@@frontend/store/reducers/logistic/POST_LOGISTIC_COMMENTS_FAILURE';
export const GET_LOGISTIC_COMMENTS = '@@frontend/store/reducers/logistic/GET_LOGISTIC_COMMENTS';
export const GET_LOGISTIC_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/logistic/GET_LOGISTIC_COMMENTS_SUCCESS';
export const GET_LOGISTIC_COMMENTS_FAILURE =
  '@@frontend/store/reducers/logistic/GET_LOGISTIC_COMMENTS_FAILURE';
  export const SET_LEDGER_LOGISTIC = '@@frontend/store/reducers/logistic/SET_LEDGER_LOGISTIC';
