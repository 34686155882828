import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import DownloadIcon from 'assets/icons/DownloadIcon';
import MPNLogo from 'assets/icons/MPNLogo';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum,
  OnboardingConfirmationStatesEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  CheckboxStyled,
  OnboardingButtonText,
  OnboardingContainer,
  OnboardingContentButtonWrapper,
  OnboardingContentContainer,
  OnboardingContentDownload,
  OnboardingContentDownloadText,
  OnboardingContentFooter,
  OnboardingContentTitle,
  OnboardingContentWrapper,
  OnboardingDescription,
  OnboardingHeader,
  OnboardingLogo,
  OnboardingTitle
} from '../../styled';
import { OnboardingStepProps } from '../OnboardingStepCreateAccount/OnboardingStepCreateAccount';
import {
  OnboardingTermsAndConditionsButtonWrapper,
  OnboardingTermsAndConditionsCheckboxMessage,
  OnboardingTermsAndConditionsContentContainer,
  OnboardingTermsAndConditionsScrollContainer
} from './styled';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OnboardingStepTermsConditions: FC<OnboardingStepProps> = ({ handleNext }) => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);
  const [pdfPath, setPdfPath] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);

  const {
    acceptTermsAndConditionsArtifactPdfBlob,
    acceptTermsAndConditionsArtifactPdfName
  }: {
    acceptTermsAndConditionsArtifactPdfBlob: ArrayBuffer | null;
    acceptTermsAndConditionsArtifactPdfName: string;
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    if (acceptTermsAndConditionsArtifactPdfBlob)
      setPdfPath(
        URL.createObjectURL(
          new Blob([acceptTermsAndConditionsArtifactPdfBlob], { type: 'application/pdf' })
        )
      );
  }, [acceptTermsAndConditionsArtifactPdfBlob]);

  const confirmClickHandler: () => void = () => {
    const putModel = new OnboardingActionEventPut(
      '',
      OnboardingConfirmationStatesEnum.confirmed,
      OnboardingActionStatusEnum.completed
    );
    handleNext(OnboardingActionsEnum.acceptTermsAndConditions, putModel);
  };

  const download: () => void = () => {
    if (!pdfPath) return;
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = pdfPath;
    anchor.download = acceptTermsAndConditionsArtifactPdfName || 'marco_polo_terms_and_conditions';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const scrollHandler: (e: any) => void = (e) =>
    setScrolledToBottom(e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight);

  return (
    <OnboardingContainer data-testid="sp-onboarding-terms-conditions">
      <OnboardingHeader>
        <OnboardingTitle data-automation-id="onboarding-terms-conditions-h3-onboarding-title">
          Terms of Service
        </OnboardingTitle>
        <OnboardingDescription data-automation-id="onboarding-terms-conditions-div-onboarding-description">
          This agreement explains the relationship between your company and Marco Polo Network -
          please read it carefully before accepting the terms.
        </OnboardingDescription>
      </OnboardingHeader>
      <OnboardingContentContainer>
        <BaseCard noPadding>
          <OnboardingContentWrapper style={{ height: '43rem' }}>
            <OnboardingLogo>
              <MPNLogo color={themeColors.text.primary} />
            </OnboardingLogo>
            <OnboardingContentTitle>Marco Polo Network Terms of Service</OnboardingContentTitle>
            {
              <OnboardingTermsAndConditionsScrollContainer
                id="onboarding-tandc-scroll-container"
                onContextMenu={(e) => e.preventDefault()}
                onScroll={scrollHandler}
              >
                <Document
                  renderMode="canvas"
                  file={pdfPath}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page scale={1.5} pageNumber={page} key={`page-${page}`} />
                    ))}
                </Document>
              </OnboardingTermsAndConditionsScrollContainer>
            }
          </OnboardingContentWrapper>
          <OnboardingContentFooter>
            <OnboardingContentDownload>
              <DownloadIcon />
              <OnboardingContentDownloadText onClick={download}>
                Download a copy
              </OnboardingContentDownloadText>
            </OnboardingContentDownload>
            <OnboardingContentButtonWrapper>
              <OnboardingTermsAndConditionsContentContainer>
                <CheckboxStyled
                  checked={isAccepted}
                  onChange={(event) => setIsAccepted(event.target.checked)}
                  data-testid="sp-checkbox-item"
                  inputProps={
                    {
                      'aria-label': 'controlled',
                      'data-testid': 'sp-clickable-checkbox'
                    } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                  data-automation-id="onboarding-terms-conditions-span-checkbox-administrator"
                />
                <OnboardingTermsAndConditionsCheckboxMessage
                  isDisabled={false}
                  data-automation-id="onboarding-terms-conditions-p-checkbox-message"
                >
                  I accept the Terms of Service
                </OnboardingTermsAndConditionsCheckboxMessage>
              </OnboardingTermsAndConditionsContentContainer>
              <OnboardingTermsAndConditionsButtonWrapper>
                <PrimaryButton
                  type="submit"
                  disabled={!isAccepted}
                  testingTag="onboarding-terms-conditions-button-confirm"
                  clickHandler={confirmClickHandler}
                  text="Confirm"
                />
              </OnboardingTermsAndConditionsButtonWrapper>
            </OnboardingContentButtonWrapper>
          </OnboardingContentFooter>
        </BaseCard>
      </OnboardingContentContainer>
    </OnboardingContainer>
  );
};

export default OnboardingStepTermsConditions;
