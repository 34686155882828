import { TableHead } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerPaymentInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

export const LedgerPaymentInvoicesTitle = styled.h3`
  margin: 0;
`;

export const TabledHeaderStyled = styled(TableHead)`
  th {
    padding: 8px;
    line-height: 1rem;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const SearchAdvancedText = styled.p`
  margin: 0;
  ${typography.bodyRegular};
  color: ${themeColors.text.primary};
  cursor: pointer;
`;
