import { FC, ReactNode } from 'react';
import { PrimaryButtonStyled } from './styled';
import { ButtonProps } from 'utils/interfaces/button/button.interface';

export interface PrimaryButtonProps extends ButtonProps {
  children?: ReactNode;
  testingTag?: string;
  testingTagExt?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  text = '',
  clickHandler = (e?: any) => {},
  textColor,
  disabledTextColor,
  borderColor,
  backgroundColor,
  disabled = false,
  children,
  padding,
  border,
  borderRadius,
  gap,
  minWidth,
  height,
  type,
  testingTag,
  testingTagExt,
  width,
  disabledBackgroundColor
}) => (
  <PrimaryButtonStyled
    data-automation-id={testingTag}
    data-testid={`sp-primary-button${testingTagExt || ''}`}
    onClick={(e) => clickHandler(e)}
    textColor={textColor}
    disabledTextColor={disabledTextColor}
    backgroundColor={backgroundColor}
    disabledBackgroundColor={disabledBackgroundColor}
    borderColor={borderColor}
    disabled={disabled}
    padding={padding}
    borderRadius={borderRadius}
    gap={gap}
    minWidth={minWidth}
    height={height}
    type={type}
    width={width}
    border={border}
  >
    {children || text}
  </PrimaryButtonStyled>
);

export default PrimaryButton;
