import Checkbox from '@mui/material/Checkbox';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const BrowserExtensionDialogWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 508px;
  padding: 16px 32px 32px 32px;
`;

export const BrowserExtensionDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BrowserExtensionDialogTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
`;

export const BrowserExtensionDialogTitle = styled.h2`
  text-align: center;
  color: ${themeColors.text.primary};
`;

export const BrowserExtensionDialogContentImage = styled.img`
  padding: 28px 0;
`;

export const BrowserExtensionDialogContentTitle = styled.h3`
  margin-bottom: 8px;
  color: ${themeColors.text.light.body};
`;

export const BrowserExtensionDialogContentText = styled.p``;

export const BrowserExtensionDialogTitleBold = styled.p`
  ${typography.bodySemiBold};
`;

export const  BrowserExtensionDialogButtonText = styled.p``;

export const  BrowserExtensionDialogButtonWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const BrowserExtensionDialogButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const BrowserExtensionDialogButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;


