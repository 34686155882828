import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const SentCircleFilledIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '32',
  width = '32'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill={color} fillOpacity="0.14" />
    <path
      d="M17.25 21.625C17.4187 21.625 17.575 21.6938 17.6937 21.8063C17.8062 21.925 17.875 22.0813 17.875 22.25C17.875 22.4187 17.8062 22.575 17.6937 22.6937C17.575 22.8062 17.4187 22.875 17.25 22.875H9.75C8.91875 22.875 8.125 22.5438 7.5375 21.9625C6.95625 21.375 6.625 20.5813 6.625 19.75V12.25C6.625 11.4188 6.95625 10.625 7.5375 10.0375C8.125 9.45625 8.91875 9.125 9.75 9.125H22.25C23.0812 9.125 23.875 9.45625 24.4625 10.0375C25.0438 10.625 25.375 11.4188 25.375 12.25V19.125C25.375 19.2937 25.3062 19.45 25.1937 19.5687C25.075 19.6812 24.9188 19.75 24.75 19.75C24.5812 19.75 24.425 19.6812 24.3063 19.5687C24.1938 19.45 24.125 19.2937 24.125 19.125V12.25C24.125 11.75 23.925 11.275 23.575 10.925C23.225 10.575 22.75 10.375 22.25 10.375H9.75C9.25 10.375 8.775 10.575 8.425 10.925C8.075 11.275 7.875 11.75 7.875 12.25V19.75C7.875 20.25 8.075 20.725 8.425 21.075C8.775 21.425 9.25 21.625 9.75 21.625H17.25Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M24.4688 11.6938C24.6188 11.6188 24.7875 11.6063 24.95 11.6563C25.1063 11.7125 25.2375 11.825 25.3063 11.9688C25.3813 12.1188 25.3938 12.2875 25.3438 12.45C25.2875 12.6063 25.175 12.7375 25.0313 12.8063L17.4 16.625C17.3938 16.625 17.3875 16.6313 17.3813 16.6313C16.95 16.8313 16.475 16.9313 16 16.925C15.525 16.9313 15.05 16.8313 14.6188 16.6313C14.6125 16.6313 14.6063 16.625 14.6 16.625L6.96875 12.8063C6.825 12.7375 6.7125 12.6063 6.65625 12.45C6.60625 12.2875 6.61875 12.1188 6.69375 11.9688C6.7625 11.825 6.89375 11.7125 7.05 11.6563C7.2125 11.6063 7.38125 11.6188 7.53125 11.6938L15.1563 15.5C15.4188 15.625 15.7063 15.6813 16 15.675C16.2938 15.6813 16.5813 15.625 16.8438 15.5L24.4688 11.6938ZM20.1938 22.4313L21.625 23.8688L24.3063 21.1813C24.425 21.0688 24.5813 21 24.75 21C24.9188 21 25.075 21.0688 25.1938 21.1813C25.3063 21.3 25.375 21.4563 25.375 21.625C25.375 21.7938 25.3063 21.95 25.1938 22.0688L21.625 25.6313L19.3063 23.3188C19.1938 23.2 19.125 23.0438 19.125 22.875C19.125 22.7063 19.1938 22.55 19.3063 22.4313C19.425 22.3188 19.5813 22.25 19.75 22.25C19.9188 22.25 20.075 22.3188 20.1938 22.4313ZM12.875 19.125C13.0438 19.125 13.2 19.1938 13.3188 19.3063C13.4313 19.425 13.5 19.5813 13.5 19.75C13.5 19.9188 13.4313 20.075 13.3188 20.1938C13.2 20.3063 13.0438 20.375 12.875 20.375H9.75C9.58125 20.375 9.425 20.3063 9.30625 20.1938C9.19375 20.075 9.125 19.9188 9.125 19.75C9.125 19.5813 9.19375 19.425 9.30625 19.3063C9.425 19.1938 9.58125 19.125 9.75 19.125H12.875Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);

export default SentCircleFilledIcon;
