import PaymentLedgerIcon from 'assets/icons/PaymentLedgerIcon/PaymentLedgerIcon';
import {
  LedgerPaymentHeaderAmountLabel,
  LedgerPaymentHeaderContainer,
  LedgerPaymentHeaderLabel,
  LedgerPaymentHeaderLeftContainer,
  LedgerPaymentHeaderRightContainer,
  LedgerPaymentHeaderRow,
  LedgerPaymentHeaderTitle
} from './styled';
import { FC } from 'react';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';

interface ILedgerPaymentHeader {
  paymentValue: number | null;
  paymentCurrency: string | null;
  paymentType: 'TRADE' | 'MATURING' | null;
}

const LedgerPaymentHeader: FC<ILedgerPaymentHeader> = ({
  paymentValue,
  paymentCurrency,
  paymentType
}) => {
  return (
    <LedgerPaymentHeaderContainer
      data-automation-id="ledger-payment-header-div-container"
      data-testid="sp-ledger-payment-header-container"
    >
      <LedgerPaymentHeaderRow>
        {paymentType === 'TRADE' && <PaymentLedgerIcon />}

        <LedgerPaymentHeaderLeftContainer data-automation-id="ledger-payment-header-div-header-left">
          <LedgerPaymentHeaderLabel data-automation-id="ledger-detail-header-h3-value-title">
            {paymentType === 'MATURING' ? 'Maturity payment' : 'Early payment'}
          </LedgerPaymentHeaderLabel>
          <LedgerPaymentHeaderTitle data-automation-id="ledger-detail-header-h2-document-number">
            {}
          </LedgerPaymentHeaderTitle>
        </LedgerPaymentHeaderLeftContainer>
      </LedgerPaymentHeaderRow>
      <LedgerPaymentHeaderRightContainer data-automation-id="ledger-payment-header-div-header-right">
        <LedgerPaymentHeaderAmountLabel data-automation-id="ledger-detail-header-h5-value-title">
          Payment amount
        </LedgerPaymentHeaderAmountLabel>
        <LedgerPaymentHeaderTitle data-automation-id="ledger-detail-header-h2-document-number">
          {paymentCurrency} {formatNumber(paymentValue || 0, 2)}
        </LedgerPaymentHeaderTitle>
      </LedgerPaymentHeaderRightContainer>
    </LedgerPaymentHeaderContainer>
  );
};

export default LedgerPaymentHeader;
