import styled from 'styled-components';

interface LinkProps {
  textColor: string;
  textDecoration: string;
  fontSize: string;
  fontWeight: number;
  typography?: any;
}

export const CustomLinkContainer = styled.span<LinkProps>`
  a {
    color: ${({ textColor }) => textColor};
    text-decoration: ${({ textDecoration }) => textDecoration};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ typography }) => typography};
  }
`;
