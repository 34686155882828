import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  activeLink?: boolean;
}

export const NavButtonContainer = styled.div<ButtonProps>`
  display: flex;
  ${typography.navLink};
  height: 44px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding-left: 10px;
  background-color: ${({ activeLink }) => (activeLink ? themeColors.lightBlue : '')};
  &:hover {
    background-color: ${themeColors.lightBlue};
  }
  justify-content: space-between;
`;

export const NavButtonLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const NavButtonRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  ${typography.captionRegular};
`;
