import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const LogisticsArtifactSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  align-self: center;
  gap: 16px;
`;

export const LogisticsArtifactSummaryTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  width: 50rem !important;
  margin-bottom: 8px;
  gap: 16px;
`;

export const LogisticsArtifactSummaryTitle = styled.h2`
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactSummaryDescription = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.subtle};
`;

export const LogisticsArtifactSummaryContentHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  align-items: ${isMobile() ? 'start' : 'end'};
  justify-content: space-between;
  gap: ${isMobile() ? '16px' : '24px'};
`;

export const LogisticsArtifactSummaryHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const LogisticsArtifactSummaryHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobile() ? 'flex-start' : 'flex-end'};
  gap: 4px;
  min-width: fit-content;
`;

export const LogisticsArtifactSummaryHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LogisticsArtifactSummaryBoldText = styled.h5`
  font-size: ${fontSizes.small};
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactSummaryBoldSmallText = styled.h5`
  font-size: ${fontSizes.small};
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactSummarySmallText = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.small};
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactSummaryHeaderTitle = styled.h3`
  color: ${themeColors.text.light.body};
`;

export const LogisticsArtifactSummaryHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LogisticsArtifactSummaryHeaderIcon = styled.div`
  display: flex;
`;

export const LogisticsArtifactSummaryDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const LogisticsArtifactSummaryDataItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 50%;
  width: 100%;
  margin-bottom: 24px;
  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const LogisticsArtifactSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
`;
