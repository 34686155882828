import { typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

interface ItemCardPropsType {
  titleBold: boolean;
}

export const ItemCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ItemCardTitle = styled.p<ItemCardPropsType>`
  ${({ titleBold }) => (!titleBold ? typography.bodyRegular : typography.bodySemiBold)};
`;

export const ItemCardValue = styled.p`
  ${typography.bodySemiBold};
`;

export const ItemCardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
export const ItemCardListItem = styled.p`
  ${typography.bodySemiBold};
  ${typography.link};
  color: ${themeColors.text.primary};
`;
