import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import SentCircleFilledIcon from 'assets/icons/SentCircleFilledIcon';
import TimeFillIcon from 'assets/icons/TimeFillIcon';
import { themeColors } from 'assets/theme/style';
import ActivityBar, { ActivityBarProps } from 'components/activity/ActivityBar/ActivityBar';
import DateBar from 'components/activity/DateBar';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import QueryBuilder from 'components/QueryBuilder';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { toTitleCase } from 'lib/helpers/formatters/stringFormatters';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QueryDateRange, QueryItem, QueryRow } from 'utils/classes/query/query';
import {
  SearchAdvancedText,
  SearchContainer,
  SearchLeftContainer
} from 'views/InvoicesView/styled';
import {
  AllRecentActivitySearchButtonTextWrapper,
  AllRecentActivitySearchInput,
  AllRecentActivityTitle,
  RecentActivityButtonContainer,
  RecentActivityButtonContentContainer,
  RecentActivityButtonText,
  RecentActivityHeader,
  RecentActivityIconContainer,
  RecentActivityItemContainer,
  RecentActivitySegmentContainer,
  RecentActivityTitle,
  RecentActivityWrapper
} from './styled';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';

export interface Activity {
  date: string;
  activities: ActivityBarProps[];
}

export interface FormattedActivity {
  date: string;
  data: {
    iconJSX: JSX.Element;
    activityName: string;
    date: string;
    time: string;
    id: string;
  }[];
}

interface RecentActivityProps {
  limit?: number;
  testingTagPage?: string;
}

const queryItems: QueryItem[] = [
  {
    filterName: 'date',
    filterValue: 'date',
    componentType: QueryComponents.DATE,
    defaultComparator: 'btw'
  }
];

const DISPLAY_DATE_FORMAT = 'dd LLL yyyy';

const RecentActivity: FC<RecentActivityProps> = ({ limit, testingTagPage }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [showAllActivities, setShowAllActivities] = useState<boolean>(false);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const [dateToSearch, setDateToSearch] = useState<Date | null>(null);
  const [dateFromSearch, setDateFromSearch] = useState<Date | null>(null);
  const [searchParams] = useSearchParams();
  const requestFundings: Funding[] = useSelector((state: RootStateOrAny) => state.app.fundings);
  const loading: boolean = useSelector((state: RootStateOrAny) => state.app.loading);

  useEffect(() => {
    setShowAllActivities(!!searchParams.get('viewAll'));
  }, [searchParams]);

  const clickHandler: (paymentId: string) => void = (paymentId) =>
    navigate(`/recent-activities/${paymentId}`);

  const viewAllClickHandler: () => void = () => navigate('/recent-activities?viewAll=true');

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => setSearchTerm(event.currentTarget.value);

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    setActiveQueries(queries);
    setFilterCriteria(queries);
  };

  const setFilterCriteria: (queries: QueryRow[]) => void = (queries) => {
    const dateFilter =
      (queries.find((q) => q.fieldValue === 'date')?.queryValue as QueryDateRange) || null;
    setDateFromSearch(dateFilter?.from || null);
    setDateToSearch(dateFilter?.to || null);
  };

  const filterByDate: (data: FormattedActivity[]) => FormattedActivity[] = (data) => {
    if (!dateFromSearch || !dateToSearch) return data;

    const [fromDate, toDate]: DateTime[] = [dateFromSearch, dateToSearch].map((date) =>
      DateTime.fromObject({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      })
    );

    return data.filter((d) => {
      if (!d.date) return true;
      const dateTime: DateTime = DateTime.fromFormat(d.date, DISPLAY_DATE_FORMAT);
      const dateTimeObj: DateTime = DateTime.fromObject({
        year: dateTime.year,
        month: dateTime.month,
        day: dateTime.day
      });
      return dateTimeObj >= fromDate && dateTimeObj <= toDate;
    });
  };

  const buildRecentActivity: () => JSX.Element = () => {
    const activityWithFormattedDates = requestFundings
      .filter((a) => !!a.createdAt)
      .map((activity) => {
        activity.formattedDate = DateTime.fromISO(
          `${activity.createdAt ? activity.createdAt : ''}`
        ).toFormat(DISPLAY_DATE_FORMAT);
        activity.formattedTime = formatDateTime(activity.createdAt, 'HH:mm');
        return activity;
      });

    const grouped: _.Dictionary<Funding[]> = limit
      ? _.groupBy(
          _.orderBy(activityWithFormattedDates, (activity) => activity.createdAt, 'desc').slice(
            0,
            showAllActivities ? requestFundings.length : limit
          ),
          'formattedDate'
        )
      : _.groupBy(activityWithFormattedDates, 'formattedDate');

    const mappedViewModel = Object.entries(grouped)
      .map(([key, value]) => ({
        date: key,
        data: value
          .map((v) => ({
            iconJSX: <SentCircleFilledIcon />,
            activityName: `Early payment request sent to ${v.funder}`,
            date: `${toTitleCase(
              DateTime.fromFormat(key, DISPLAY_DATE_FORMAT).toRelativeCalendar() || ''
            )} ${v.formattedTime}`,
            time: v.formattedTime ? v.formattedTime : '',
            id: v.id
          }))
          .sort((a, b) => (a.time < b.time ? 1 : -1))
      }))
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));

    const filteredData: () => FormattedActivity[] = (): FormattedActivity[] => {
      const fuzzySearchResults = mappedViewModel.map((groupedData) => ({
        ...groupedData,
        data: groupedData.data.filter(
          (data) => data?.activityName.toLowerCase().includes(searchTerm.toLowerCase()) || false
        )
      }));

      let searchResults = fuzzySearchResults;
      const filtersToApply = [filterByDate];
      let i = 0;
      do {
        filtersToApply[i](searchResults);
        searchResults = filtersToApply[i](searchResults);
        i++;
      } while (i < filtersToApply.length && searchResults.length !== 0);

      return searchResults;
    };

    return (
      <div
        data-testid="sp-recent-activity-segment"
        style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '24px' }}
      >
        {filteredData().map(({ date, data }, i) => (
          <RecentActivitySegmentContainer key={i}>
            {data.length > 0 && <DateBar key={date} date={date} />}
            {buildActivities(data, requestFundings.length - 1 === i)}
          </RecentActivitySegmentContainer>
        ))}
        {filteredData().length > 0 ? (
          !showAllActivities && (
            <RecentActivityButtonContainer data-testid="sp-oa-dashboard-recent-activity-button">
              <PrimaryButton
                clickHandler={viewAllClickHandler}
                testingTag="home-button-oa-recent-activity-link"
              >
                <RecentActivityButtonContentContainer>
                  <RecentActivityButtonText
                    data-automation-id="home-p-oa-recent-activity-button-text"
                    data-testid="sp-oa-dashboard-recent-activity-button-text"
                  >
                    View all
                  </RecentActivityButtonText>
                  <RecentActivityIconContainer
                    data-automation-id="home-div-oa-recent-activity-button-icon-container"
                    data-testid="sp-oa-dashboard-recent-activity-button-icon"
                  >
                    <RightLongArrowIcon color={themeColors.text.onPrimary} height="10" width="12" />
                  </RecentActivityIconContainer>
                </RecentActivityButtonContentContainer>
              </PrimaryButton>
            </RecentActivityButtonContainer>
          )
        ) : (
          <p
            style={{ textAlign: 'left' }}
            data-automation-id={`${testingTagPage}-p-no-recent-activity`}
          >
            No activites match your filter criteria. Please try expanding search to see more.
          </p>
        )}
      </div>
    );
  };

  const buildActivities: (activities: ActivityBarProps[], isLast: boolean) => JSX.Element[] = (
    activities,
    isLast
  ) =>
    activities.map(({ iconJSX, activityName, date, id }, i) => (
      <RecentActivityItemContainer
        onClick={() => clickHandler(id || '')}
        key={i + 100}
        index={i}
        length={activities.length}
        isLast={isLast}
        data-testid="sp-recent-activity-item"
      >
        <ActivityBar iconJSX={iconJSX} activityName={activityName} date={date} />
        {activities.length - 1 !== i && <Divider margin="16px 0 0 0" />}
      </RecentActivityItemContainer>
    ));

  return (
    <RecentActivityWrapper
      data-automation-id={`${testingTagPage}-div-${
        testingTagPage === 'home' ? 'recent-activity' : ''
      }container`}
      data-testid="sp-recent-activity-container"
    >
      <BaseCard>
        {testingTagPage === 'home' && (
          <RecentActivityHeader>
            <TimeFillIcon />
            <RecentActivityTitle
              data-automation-id={`${testingTagPage}-h3-${
                testingTagPage === 'home' ? 'recent-activity' : ''
              }-title`}
            >
              Tasks & activity
            </RecentActivityTitle>
          </RecentActivityHeader>
        )}
        {testingTagPage !== 'home' && (
          <AllRecentActivityTitle data-automation-id={`${testingTagPage}-h2-page-title`}>
            Recent Activity
          </AllRecentActivityTitle>
        )}
        {testingTagPage !== 'home' && (
          <SearchContainer>
            <SearchLeftContainer>
              <OutlinedInput
                id="outlined-adornment-search"
                dataTestId="sp-input-fuzzy-search"
                defaultValue={searchTerm}
                changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                  searchHandler(event)
                }
                startAdornment={
                  <InputAdornment
                    data-automation-id="early-payment-request-div-included-invoices-input-adornment"
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder="Search activity feed"
                dataAutomationId="recent-activities-input-fuzzy-search"
              />

              <SearchAdvancedText
                onClick={advancedSearchClickHandler}
                data-automation-id="recent-activities-p-advanced-search-text"
                data-testid="sp-advanced-search"
              >
                {showAdvancedSearch
                  ? 'Hide advanced search'
                  : `Advanced search ${
                      activeQueries.length > 0 ? `(${activeQueries.length})` : ''
                    }`}
              </SearchAdvancedText>
            </SearchLeftContainer>
          </SearchContainer>
        )}
        {testingTagPage !== 'home' && (
          <QueryBuilder
            queryItems={queryItems}
            activeQueriesHandler={activeQueriesHandler}
            closeClickHandler={advancedSearchClickHandler}
            open={showAdvancedSearch}
            testingTagPage="recent-activities"
          />
        )}

        {!showAdvancedSearch && testingTagPage !== 'home' && <Divider />}

        {loading ? (
          <LoaderInPage />
        ) : requestFundings.length > 0 ? (
          buildRecentActivity()
        ) : (
          <p
            style={{ textAlign: 'center' }}
            data-automation-id={`${testingTagPage}-p-no-recent-activity`}
          >
            You have no tasks or activities.
          </p>
        )}
      </BaseCard>
    </RecentActivityWrapper>
  );
};

export default RecentActivity;
