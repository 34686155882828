import { layoutSizes, styling, themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';
import { BaseCardProps } from './BaseCard';
import backgroundPaper from './paper_tile.jpg';
export interface BaseCardHeaderProps {
  noPadding?: boolean;
}

export const BaseCardContainer = styled.div<BaseCardProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => (flex ? flex : '1' )};
  padding: ${({ noPadding }) => (noPadding ? 0 : `24px`)};
  border: ${({ noBorder }) => (noBorder ? 0 : `1px solid ${themeColors.border.primary}`)};
  border-radius: ${isMobile() ? '0' : styling.defaultRadius};
  background: ${({ backgroundColor, paperStyle }) =>
    !paperStyle ? backgroundColor : `url(${backgroundPaper}) repeat`};
  color: ${({ textColor }) => textColor};
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => (minWidth ? layoutSizes.minDesktopWidth : 'auto')};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '')};
  max-height: ${({ maxHeight }) => maxHeight || ''};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || ''};
`;

export const BaseCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BaseCardHeaderMenu = styled.div<BaseCardHeaderProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 26px;
  padding: ${({ noPadding }) => (noPadding ? '16px 24px 12px 16px' : '0 8px 12px 16px')};
`;
