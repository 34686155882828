/* eslint-disable react-hooks/exhaustive-deps */
import AssetsApi from 'api/assets/assets.api';
import { IAttachment } from 'api/interfaces/attachment/attachment.interface';
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { Insurance } from 'api/interfaces/insurance/insurance.interface';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import { ProgramConfig, ProgramRuleTypeEnum } from 'api/interfaces/program/program.interface';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { getCookie } from 'lib/helpers/cookieHelper';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { getLedgerDataByType } from 'lib/services/ledger/ledger.service';
import { Dispatch, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { store } from 'store';
import {
  GET_INVOICE_COMMENTS,
  GET_LOGISTIC_COMMENTS,
  GET_PO_COMMENTS,
  SET_BREADCRUMB,
  SET_DOCUMENT_FOR_VIEW,
  SET_LEDGER_INVOICE,
  SET_LEDGER_LOGISTIC,
  SET_PO_FOR_VIEW,
  TRIGGER_SNACKBAR
} from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { SnackbarData } from 'store/reducers/app';
import { LedgerLineBuilder } from 'utils/classes/ledger/ledgerLineBuilder';
import { LedgerView } from 'utils/interfaces/ledger/ledgerView.interface';
import GatewayToFinanceButton from 'views/PurchaseOrders/PurchaseOrderDetail/GatewayToFinanceButton';
import SelectLogisticsArtifact from '../LedgerInvoice/InvoiceLogisticsDialog/SelectLogisticsArtifact';
import LedgerDetailAccordion from './LedgerDetailAccordion';
import LedgerDetailAttachmentsUploadDialog from './LedgerDetailAccordion/LedgerDetailAttachmentsUploadDialog';
import LedgerDetailOtherAccordion from './LedgerDetailAccordion/LedgerDetailOtherAccordion';
import LedgerDetailGatewayToFinanceBanner from './LedgerDetailGatewayToFinanceBanner';
import LedgerDetailHeader from './LedgerDetailHeader';
import { LedgerDetailHeaderProps } from './LedgerDetailHeader/LedgerDetailHeader';
import LedgerDetailLineChart from './LedgerDetailLineChart';
import LedgerDetailSummary from './LedgerDetailSummary';
import { LedgerSummaryItem } from './LedgerDetailSummary/LedgerDetailSummary';
import LedgerLogisticsFlipBanner from './LedgerLogisticsFlipBanner';
import { LedgerDetailContainer, OtherDetailsTitle } from './styled';
import { supplierInclusions } from 'lib/constants/entityInclusions';

interface LedgerDetailProps {
  type: keyof typeof AssetEnum;
  showEntities?: boolean;
  showSummaryAccordions?: boolean;
  showDetailAccordions?: boolean;
  hideBanner?: boolean;
  paramId?: string | undefined;
  showCreateInvoiceBanner?: boolean;
}

const LedgerDetail: FC<LedgerDetailProps> = ({
  type,
  showEntities,
  showCreateInvoiceBanner,
  showSummaryAccordions,
  showDetailAccordions,
  hideBanner,
  paramId
}) => {
  const params: Readonly<Params<string>> = useParams();
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [ledgerItem, setLedgerItem] = useState<
    ILedgerInvoice | ILedgerPurchaseOrder | ILedgerLogistic | null
  >(null);
  const [logistics, setLogistics] = useState<LedgerLogistics | null>(null);
  const [matchingFees, setMatchingFees] = useState<number | null>(null);
  const [insurance, setInsurance] = useState<Insurance | null>(null);
  const [pageLoad, setPageLoad] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState<LedgerSummaryItem[]>([]);
  const [numericSummaryData, setNumericSummaryData] = useState<LedgerSummaryItem[]>([]);
  const [summaryHeaderData, setSummaryHeaderData] = useState<LedgerDetailHeaderProps | null>(null);
  const [summaryBarData, setSummaryBarData] = useState<LedgerLineBuilder | null>(null);
  const [lineItems, setLineItems] = useState<LedgerLineItem[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [eventHistory, setEventHistory] = useState<any[]>([]);
  const [currency, setCurrency] = useState<string>('');
  const [logisticsFlipDialogOpen, setLogisticsFlipDialogOpen] = useState<boolean>(false);
  const [openAttachmentsDialog, setOpenAttachmentsDialog] = useState<boolean>(false);
  const [showInvoiceFlipBanner, setShowInvoiceFlipBanner] = useState<boolean>(false);
  const [logisticsFlipComplete, setLogisticsFlipComplete] = useState<boolean>(false);
  const [manualBannerHide, setManualBannerHide] = useState<boolean>(false);
  const [submittingAttachments, setSubmittingAttachments] = useState<boolean>(false);
  const [filesForAttachments, setFilesForAttachments] = useState<File[]>([]);
  const [uploadInvalid, setUploadInvalid] = useState<boolean>(false);
  const [hasMatchingLogisticsRules, setHasMatchingLogisticsRules] = useState<boolean>(false);
  const paymentDueDate: string =
    (ledgerItem as ILedgerInvoice | ILedgerPurchaseOrder)?.data.paymentDueDate || '';

  const {
    activeCurrencyCode,
    loading: appLoading,
    tenant,
    g2fFeatureHidden,
    allProgramsWithDetails
  }: {
    activeCurrencyCode: string;
    loading: string;
    tenant: string;
    g2fFeatureHidden: boolean;
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const ledgerLoading: boolean = useSelector((state: RootStateOrAny) => state.ledger.loading);

  const {
    programOwnerDetails
  }: {
    programOwnerDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.program);

  const {
    display
  }: {
    display: boolean;
  } = useSelector((state: RootStateOrAny) => state.createInvoice);

  const isProgramFixedCost: boolean = useCheckProgramsContainsType(ProgramType.FIXED_DISCOUNT);

  useEffect(() => {
    getCookie('spInvoiceFlipEnabled') === 'true'
      ? setShowInvoiceFlipBanner(true)
      : setShowInvoiceFlipBanner(false);

    return () => {
      setSubmittingAttachments(false);
    };
  }, []);

  useEffect(() => {
    if (!params.invoiceId && !params.purchaseOrderId && !params.logisticId) return;

    if (!appLoading && tenant) initData();
  }, [params.invoiceId, params.purchaseOrderId, params.logisticId, appLoading, tenant]);

  const initData: () => void = () => fetchData();

  const fetchData: () => Promise<void> = async () => {
    const res: LedgerView<ILedgerInvoice | ILedgerPurchaseOrder | ILedgerLogistic> | null =
      await getLedgerDataByType(params, type, paramId, store);

    if (!res) throw Error('LedgerDetail - no suitable data response.');

    if (type === AssetEnum.INVOICE) {
      const programId =
        (res.data.linkedPrograms as any[]).find((p) =>
          p.baseType.includes(ProgramType.OPEN_ACCOUNT)
        )?.programId || '';

      const programRules = allProgramsWithDetails
        .find((p) => p.id === programId)
        ?.rules?.find(
          (rule) => rule.type === ProgramRuleTypeEnum.LOGISTIC_TEMPLATE_CONFIGURATION_SUB
        )?.value;

      !programRules?.subTemplates.length
        ? setHasMatchingLogisticsRules(false)
        : setHasMatchingLogisticsRules(true);

      setData(res);
    } else {
      setData(res);
    }
  };

  const setData: (
    res: LedgerView<ILedgerInvoice | ILedgerPurchaseOrder | ILedgerLogistic>
  ) => void = (res) => {
    const {
      data,
      breadcrumbValue,
      header,
      summary,
      currency,
      numericSummary,
      attachments,
      eventHistory
    } = res;

    if (type === AssetEnum.INVOICE)
      dispatch({ type: GET_INVOICE_COMMENTS, payload: { invoiceId: paramId || params.invoiceId } });

    if (type === AssetEnum.PURCHASE_ORDER)
      dispatch({
        type: GET_PO_COMMENTS,
        payload: { purchaseOrderId: paramId || params.purchaseOrderId }
      });

    if (type === AssetEnum.LOGISTIC)
      dispatch({
        type: GET_LOGISTIC_COMMENTS,
        payload: { logisticId: paramId || params.logisticId }
      });

    setSummaryHeaderData(header);
    setSummaryData(summary);
    setNumericSummaryData(numericSummary);
    setLedgerItem(data);
    setEventHistory(eventHistory);

    if (type === AssetEnum.INVOICE) sendInvoiceToState(data as ILedgerInvoice);
    if (type === AssetEnum.LOGISTIC) sendLogisticToState(data as ILedgerLogistic);

    if (!paramId) setBreadcrumbValues(breadcrumbValue);

    if (type === AssetEnum.INVOICE || type === AssetEnum.PURCHASE_ORDER) {
      setLogistics((data as ILedgerInvoice | ILedgerPurchaseOrder).data?.logistics || null);
      setMatchingFees((data as ILedgerInvoice | ILedgerPurchaseOrder).matchingFees);
      setInsurance((data as ILedgerInvoice | ILedgerPurchaseOrder).data.insurance || null);
    }

    setLineItems(data.data.lineItems || []);
    setAttachments(attachments || []);
    setCurrency(currency || activeCurrencyCode);

    if (type === AssetEnum.PURCHASE_ORDER) {
      setSummaryBarData(barData(data as ILedgerPurchaseOrder));
      dispatch({ type: SET_PO_FOR_VIEW, payload: data });
    }
    dispatch({ type: SET_DOCUMENT_FOR_VIEW, payload: { header, data } });
    setPageLoad(false);
  };

  const sendInvoiceToState: (data: ILedgerInvoice) => void = (data) =>
    dispatch({ type: SET_LEDGER_INVOICE, payload: data });

  const sendLogisticToState: (data: ILedgerLogistic) => void = (data) => {
    dispatch({ type: SET_LEDGER_LOGISTIC, payload: data });
  };

  const barData: (item: ILedgerPurchaseOrder) => LedgerLineBuilder = (item) =>
    new LedgerLineBuilder(
      'Consumed vs available amount',
      item.data.amount,
      item.data.currency || '',
      {
        name: 'Consumed',
        key: 'consumed',
        value: item.consumption.fulfilledAmount,
        color: themeColors.chart.light.colour3
      },
      {
        name: 'Available',
        key: 'available',
        value: item.consumption.availableAmount,
        color: themeColors.chart.light.colour7
      }
    );

  const setBreadcrumbValues: (value: string) => void = (value) => {
    if (type === AssetEnum.INVOICE)
      dispatch({ type: SET_BREADCRUMB, payload: { ':invoiceId': value } });
    if (type === AssetEnum.PURCHASE_ORDER)
      dispatch({ type: SET_BREADCRUMB, payload: { ':purchaseOrderId': value } });
    if (type === AssetEnum.LOGISTIC)
      dispatch({ type: SET_BREADCRUMB, payload: { ':logisticId': value } });
  };

  const attachmentsFileHandler: (acceptedFiles: File[]) => void = (acceptedFiles) =>
    setFilesForAttachments(acceptedFiles);

  const addFilesButtonClickHandler: () => void = async () => {
    setSubmittingAttachments(true);
    const assetApi = new AssetsApi(store);
    let documentId: string;
    let errors = 'Failed to upload: ';
    const documentErrorSnackbarData: SnackbarData = {
      title: <h5>'Upload of attachment error.'</h5>,
      message: <p>{errors}</p>,
      leftIcon: <WarningIcon color={themeColors.icon.error} />,
      topAligned: true,
      type: 'error'
    };

    if ((ledgerItem as ILedgerInvoice)?.invoiceId)
      documentId = (ledgerItem as ILedgerInvoice).invoiceId;

    if ((ledgerItem as ILedgerPurchaseOrder)?.purchaseOrderId) {
      documentId = (ledgerItem as ILedgerPurchaseOrder).purchaseOrderId;
    }
    if ((ledgerItem as ILedgerLogistic)?.logisticId) {
      documentId = (ledgerItem as ILedgerLogistic).logisticId;
    }

    const responses = await Promise.all(
      filesForAttachments.map(async (attachment) => {
        const response = await assetApi.uploadDocumentAttachment(attachment, documentId, type);
        if (!response) return ` ${attachment.name} | `;
      })
    );

    if ((ledgerItem as ILedgerInvoice)?.invoiceId) {
      setAttachments(
        (await assetApi.getAttachments((ledgerItem as ILedgerInvoice).invoiceId, AssetEnum.INVOICE))
          .data.content
      );
    }

    if ((ledgerItem as ILedgerPurchaseOrder)?.purchaseOrderId) {
      setAttachments(
        (
          await assetApi.getAttachments(
            (ledgerItem as ILedgerPurchaseOrder).purchaseOrderId,
            AssetEnum.PURCHASE_ORDER
          )
        ).data.content
      );
    }

    if ((ledgerItem as ILedgerLogistic)?.logisticId) {
      setAttachments(
        (
          await assetApi.getAttachments(
            (ledgerItem as ILedgerLogistic).logisticId,
            AssetEnum.LOGISTIC
          )
        ).data.content
      );
    }

    setSubmittingAttachments(false);

    if (errors.split('|').length > 1) {
      errors = errors + responses.join(' ');
    }

    setOpenAttachmentsDialog(false);

    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: documentErrorSnackbarData } });
    setTimeout(() => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    }, 15000);
  };

  const getOAProgramForInvoice: () => ProgramConfig | null = () => {
    if (type !== AssetEnum.INVOICE) return null;

    const invoiceOAProgram = (ledgerItem as ILedgerInvoice).linkedPrograms.find((p) =>
      p.baseType.includes(ProgramType.OPEN_ACCOUNT)
    );

    if (!invoiceOAProgram) return null;

    const matchedProgram =
      allProgramsWithDetails.find((p) => p.id === invoiceOAProgram.programId) || null;

    return matchedProgram;
  };

  const canFlipPO: () => boolean = () => {
    if (
      type !== AssetEnum.PURCHASE_ORDER ||
      !display ||
      lineItems.length === 0 ||
      summaryBarData?.availableData.value === 0 ||
      !showCreateInvoiceBanner
    )
      return false;

    const inAppFlipControlForPoEnabled: boolean = getCookie('spPoFlipEnabled') === 'true';

    const poOAProgram = (ledgerItem as ILedgerPurchaseOrder).linkedPrograms.find((p) =>
      p.baseType.includes(ProgramType.OPEN_ACCOUNT)
    );

    if (!poOAProgram) return false;

    const matchedProgram =
      allProgramsWithDetails.find((p) => p.id === poOAProgram.programId) || null;

    const flipRuleForPO = matchedProgram?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value
      .ruleByAssetType?.PURCHASE_ORDER;

    const isSupplierInclusionsMatched =
      matchedProgram?.participants.find(
        (p) => p.role === 'SUPPLIER' && supplierInclusions.includes(p.registeredName.toUpperCase())
      ) !== undefined;

    if (
      (flipRuleForPO?.enabledForAllSuppliers || flipRuleForPO?.suppliers.length > 0) &&
      inAppFlipControlForPoEnabled &&
      isSupplierInclusionsMatched &&
      (ledgerItem as ILedgerPurchaseOrder).data.goodsDescription === 'FLIP'
    )
      return true;

    if (
      (flipRuleForPO?.enabledForAllSuppliers || flipRuleForPO?.suppliers.length > 0) &&
      inAppFlipControlForPoEnabled &&
      isSupplierInclusionsMatched &&
      (ledgerItem as ILedgerPurchaseOrder).data?.goodsDescription !== 'FLIP'
    )
      return false;

    if (
      (flipRuleForPO?.enabledForAllSuppliers || flipRuleForPO?.suppliers.length > 0) &&
      inAppFlipControlForPoEnabled
    )
      return true;

    return false;
  };

  const canFlipInvoice: () => boolean = () => {
    if (
      type !== AssetEnum.INVOICE ||
      !hasMatchingLogisticsRules ||
      lineItems.length === 0 ||
      !showInvoiceFlipBanner ||
      manualBannerHide
    )
      return false;

    const inAppFlipControlForInvoiceEnabled: boolean = getCookie('spInvoiceFlipEnabled') === 'true';

    const invoiceProgram = (ledgerItem as ILedgerInvoice).linkedPrograms.find((p) =>
      p.baseType.includes(ProgramType.OPEN_ACCOUNT)
    );

    if (!invoiceProgram || invoiceProgram?.invoiceStatus === 'APPROVED') return false;

    const matchedProgram =
      allProgramsWithDetails.find((p) => p.id === invoiceProgram.programId) || null;

    const flipRuleForInvoice = matchedProgram?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value
      .ruleByAssetType?.INVOICE;

    return (
      (flipRuleForInvoice?.enabledForAllSuppliers || flipRuleForInvoice?.suppliers.length > 0) &&
      inAppFlipControlForInvoiceEnabled
    );
  };

  return (
    <>
      <LedgerDetailContainer
        data-automation-id="ledger-detail-div-container"
        data-testid="sp-ledger-detail-container"
      >
        {ledgerLoading || appLoading || pageLoad ? (
          <LoaderInPage />
        ) : (
          <>
            {canFlipPO() && (
              // (ledgerItem as ILedgerPurchaseOrder).data?.goodsDescription === 'FLIP' &&
              <GatewayToFinanceButton />
            )}
            {canFlipInvoice() && (
              <LedgerLogisticsFlipBanner
                closeHandler={() => setManualBannerHide(true)}
                isFlipComplete={logisticsFlipComplete}
                createClickHandler={() => setLogisticsFlipDialogOpen(true)}
              />
            )}
            {!g2fFeatureHidden && type === AssetEnum.PURCHASE_ORDER && showCreateInvoiceBanner && (
              <LedgerDetailGatewayToFinanceBanner />
            )}
            {summaryHeaderData && (
              <LedgerDetailHeader
                {...summaryHeaderData}
                companyLogo={programOwnerDetails?.companyLogo || ''}
                type={type}
                supplier={
                  type === AssetEnum.INVOICE
                    ? (ledgerItem as ILedgerInvoice)?.supplierRef || ''
                    : (ledgerItem as ILedgerPurchaseOrder)?.data.buyerReference || ''
                }
              />
            )}
            {summaryBarData && <LedgerDetailLineChart {...summaryBarData} />}
            {summaryData && (
              <LedgerDetailSummary
                isProgramFixedCost={isProgramFixedCost}
                data={summaryData}
                numericSummaryData={numericSummaryData}
                outstandingAmount={
                  (ledgerItem as ILedgerInvoice | ILedgerPurchaseOrder)?.data.amount || 0
                }
                paymentDueDate={paymentDueDate ? new Date(paymentDueDate).toISOString() : ''}
                currency={currency}
                type={type}
              />
            )}
            {showSummaryAccordions && (
              <>
                <LedgerDetailAccordion
                  type={type}
                  assetId={
                    paramId || params.invoiceId || params.purchaseOrderId || params.logisticId || ''
                  }
                  lineItems={lineItems}
                  logistics={logistics}
                  comments={[]}
                  currencyCode={currency}
                  matchingFees={matchingFees}
                  uploadAttachmentButtonClickHandler={() =>
                    setOpenAttachmentsDialog(!openAttachmentsDialog)
                  }
                  attachments={attachments}
                  eventHistory={eventHistory}
                  ledgerItem={ledgerItem}
                />
              </>
            )}
            {showDetailAccordions && (
              <>
                <OtherDetailsTitle data-automation-id="ledger-detail-h3-other-details-title">
                  Other details
                </OtherDetailsTitle>
                <Divider margin="0 24px" />
                <LedgerDetailOtherAccordion
                  logistics={logistics}
                  insurance={insurance}
                  type={type}
                />
              </>
            )}{' '}
          </>
        )}
        {summaryHeaderData &&
          type === AssetEnum.INVOICE &&
          logisticsFlipDialogOpen &&
          getOAProgramForInvoice()?.id && (
            <FullViewDialog
              open={logisticsFlipDialogOpen}
              clickHandler={() => setLogisticsFlipDialogOpen(false)}
              dialogContent={
                <SelectLogisticsArtifact
                  {...summaryHeaderData}
                  invoiceOAProgram={getOAProgramForInvoice()}
                  isCompleteHandler={() => setLogisticsFlipComplete(true)}
                  closeDialogHandler={() => setLogisticsFlipDialogOpen(false)}
                />
              }
            />
          )}
        {openAttachmentsDialog && summaryHeaderData && (
          <FullViewDialog
            open={openAttachmentsDialog}
            clickHandler={() => {
              if (openAttachmentsDialog) setSubmittingAttachments(false);
              setOpenAttachmentsDialog(!openAttachmentsDialog);
            }}
            dialogContent={
              <LedgerDetailAttachmentsUploadDialog
                uploadInvalid={uploadInvalid}
                filesLength={filesForAttachments?.length || 0}
                setUploadInvalid={(isValid) => setUploadInvalid(isValid)}
                fileHandler={attachmentsFileHandler}
                addFilesButtonClickHandler={addFilesButtonClickHandler}
                submitting={submittingAttachments}
                headerJSX={
                  <LedgerDetailHeader
                    {...summaryHeaderData}
                    reduced
                    companyLogo={programOwnerDetails?.companyLogo || ''}
                    type={type}
                    supplier={
                      type === AssetEnum.INVOICE
                        ? (ledgerItem as ILedgerInvoice)?.supplierRef || ''
                        : (ledgerItem as ILedgerPurchaseOrder)?.data.supplierReference || ''
                    }
                  />
                }
              />
            }
          />
        )}
      </LedgerDetailContainer>
    </>
  );
};

export default LedgerDetail;
