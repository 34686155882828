import { FC } from 'react';
import _ from 'lodash';
import {
  MobileInvoiceBarContainer,
  MobileInvoiceBarSection,
  MobileInvoiceCaption,
  MobileInvoicesContainer,
  MobileInvoiceSemiBoldText
} from './styled';
import DateBar from 'components/activity/DateBar';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { themeColors } from 'assets/theme/style';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import CustomPagination from 'components/CustomPagination';

export interface InvoicesViewTableData {
  buyerName: string;
  currency: string;
  dueDate: string;
  invoiceAmount: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: string;
  selected: boolean;
}

interface InvoicesViewMobileProps {
  invoices: DataGridRowItem[];
  rowClickHandler?: (row: string) => void;
  totalRows: number;
  page: number;
  rowsPerPage: number;
  changePage: (action: any, tableState: any) => void;
  rowsPerPageHandler: (numberOfRows: any) => void;
}

interface InvoicesViewMobileListViewModel {
  date: string;
  invoiceData: DataGridRowItem[];
}

const InvoicesViewMobile: FC<InvoicesViewMobileProps> = ({
  invoices,
  rowClickHandler,
  totalRows,
  page,
  rowsPerPage,
  rowsPerPageHandler,
  changePage
}) => {
  // group invoices by duedate

  const mappedData: () => InvoicesViewMobileListViewModel[] = () => {
    const grouped = _.groupBy(invoices, 'dueDate');
    return Object.entries(grouped)
      .map(([key, value]) => ({
        date: key,
        invoiceData: value.map((v) => ({
          invoiceId: v.invoiceId,
          invoiceNumber: v.invoiceNumber,
          invoiceAmount: `${CurrencySymbolsLookUp[v.currency as string]}${v.invoiceAmount}`,
          buyerName: v.buyerName,
          invoiceStatus: v.invoiceStatus
        }))
      }))
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
  };
  const buildInvoiceBar: (viewModel: DataGridRowItem, key: number) => JSX.Element = (
    { invoiceId, invoiceNumber, invoiceAmount, buyerName, invoiceStatus },
    key
  ) => (
    <MobileInvoiceBarContainer
      key={key}
      onClick={() => rowClickHandler && rowClickHandler(invoiceId as string)}
      data-testid="sp-mobile-invoice-bar"
    >
      <MobileInvoiceBarSection>
        <MobileInvoiceSemiBoldText>{invoiceNumber}</MobileInvoiceSemiBoldText>
        <MobileInvoiceSemiBoldText>{invoiceStatus}</MobileInvoiceSemiBoldText>
      </MobileInvoiceBarSection>
      <MobileInvoiceBarSection>
        <MobileInvoiceCaption>{buyerName}</MobileInvoiceCaption>
        <MobileInvoiceCaption>{invoiceAmount}</MobileInvoiceCaption>
      </MobileInvoiceBarSection>
    </MobileInvoiceBarContainer>
  );

  const buildView: () => JSX.Element = () => {
    return (
      <>
        {mappedData().map(({ date, invoiceData }) => (
          <MobileInvoicesContainer key={date}>
            <DateBar date={date} toEdge color={themeColors.mono5} />
            {invoiceData.map((data, i) => buildInvoiceBar(data, i))}
          </MobileInvoicesContainer>
        ))}
        {totalRows > 15 && (
          <CustomPagination
            totalRows={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            changePage={changePage}
            changeRowsPerPage={rowsPerPageHandler}
          />
        )}
      </>
    );
  };

  return <div>{buildView()}</div>;
};

export default InvoicesViewMobile;
