import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { isMobile } from 'lib/helpers/mobile';
import { themeColors } from 'assets/theme/style';

export const GatewayToFinanceBarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
`;

export const GatewayToFinanceBarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GatewayToFinanceBarDescription = styled.p`
  ${typography.captionRegular};
`;

export const GatewayToFinanceBarButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const GatewayToFinanceBarButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const GatewayToFinanceBarButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const GatewayToFinanceBarButtonText = styled.p`
  color: ${themeColors.text.onPrimary};
  ${typography.bodySmallSemiBold};
`;

export const GatewayToFinanceBarIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;
