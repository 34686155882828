import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import { fontSizes, typography } from 'assets/theme/typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SelectStyled } from 'components/forms/inputs/Select/styled';
import { TextInputStyled } from 'components/forms/inputs/TextInput/styled';
import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface SelectStyledProps {
  disabled?: boolean;
}

export const QuerySelectStyled = styled(SelectStyled)<SelectStyledProps>`
  .MuiSelect-outlined {
    ${typography.bodyRegular};
    font-family: 'Source Sans Pro', sans-serif !important;
  }
`;

export const QueryTextInputStyled = styled(TextInputStyled)``;

export const QueryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const QueryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 60%;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  color: ${themeColors.text.light.subtle};
  cursor: pointer;
`;

export const QueryActionText = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
  cursor: pointer;
  display: inline-flex;
  width: fit-content;
`;

export const QuerySectionText = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body};
`;

export const QueryDescriptionText = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.body};
`;

export const QueryActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const QuerySaveFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const QueryActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const FilterIconStyled = styled(FilterListIcon)`
  color: ${themeColors.icon.primary};
`;

export const CalendarMonthIconStyled = styled(CalendarMonthIcon)`
  color: ${themeColors.iconBlue};
`;

export const QueryDateSearchRowSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 36px;
  .MuiOutlinedInput-root {
    min-width: 140px;
    height: 36px;
    ${typography.bodyRegular};
    font-family: 'Source Sans Pro', sans-serif;
  }
  .MuiOutlinedInput-input {
    height: 36px;
    border-radius: 6px;
    font-size: ${fontSizes.regular};
    padding: 0 6px 0 12px;
    &.Mui-disabled {
      background-color: ${themeColors.mono5};
      color: ${themeColors.mono1} !important;
      -webkit-text-fill-color: ${themeColors.mono1} !important;
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  display: flex;
  width: 100%;
  font-size: 14px !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  background: ${({ disabled }) => disabled && themeColors.mono5};
  overflow: ${({ disabled }) => disabled && 'hidden'};
  .MuiOutlinedInput-root {
    border: 1px solid ${themeColors.text.light.muted};
  }
  .MuiSelect-icon {
    color: ${themeColors.text.light.body};
  }
`;

export const QueryClearFiltersText = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.mono1};
  cursor: pointer;
`;

export const QueryTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
