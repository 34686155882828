import Chip from '@mui/material/Chip';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ChipsType {
  textColor: string;
  radius: string;
  transform: string;
  fontSize: string;
  height: string;
  semibold: string;
  background?: string;
}

export const ChipsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  .MuiButtonBase-root {
    padding: 0 !important;
  }
`;

export const ChipStyled = styled(Chip)<ChipsType>`
  display: flex;

  &.MuiChip-root {
    background-color: ${({ textColor, background }) =>  background ? background : `${themeColors.bg.info}25`};
    color: ${({ textColor }) => textColor};
    height: ${({ height }) => height};
    ${({ semibold }) => (semibold === 'bold' ? typography.bodySemiBold : typography.bodyRegular)};
    font-size: ${({ fontSize }) => fontSize};
    border-radius: ${({ radius }) => radius};

    svg {
      margin-left: 8px;
    }

    .MuiChip-label {
      padding-left: 6px;
      padding-right: 8px;
      line-height: initial;
      text-transform: ${({ transform }) => transform};
    }
  }
`;
