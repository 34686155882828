import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface Color {
  color: string;
}

interface Title {
  showSmallTitle?: boolean;
}

export const FundingRequestSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  padding: 0 24px;
`;
export const FundingRequestSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FundingRequestSummaryTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${isMobile() ? 'center' : 'flex-start'};
`;

export const FundingRequestSummaryTitle = styled.h2<Title>`
  margin: 0 0 16px 0;
  font-size: ${({ showSmallTitle }) => (showSmallTitle || isMobile()) && "18px"};
`;

export const FundingRequestSummaryAmount = styled.h2`
  margin: 0 0 16px 0;
  font-size: 18px;
`;

export const FundingRequestSummaryTotal = styled.h4`
  margin: 0;
`;
export const FundingRequestSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const FundingRequestSummaryH5 = styled.h5`
  margin: 0;
`;

export const FundingRequestSummaryText = styled.p`
  ${typography.bodyRegular};
  margin: 0;
`;

export const FundingRequestStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: ${isMobile() ? '24px' : '-8px'};
  width: ${isMobile() ? '54px' : ''};
  height: ${isMobile() ? '60px' : ''};
`;

export const FundingRequestStatus = styled.h4<Color>`
  margin: 0;
  color: ${({ color }) => color};
`;

export const FundingRequestSummaryRowSegment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
`;
