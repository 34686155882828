import { EntityBankAccount, EntityOwner } from 'api/interfaces/entity/entity.interface';
import {
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITY_DETAILS_FAILURE,
  FETCH_ENTITY_DETAILS_SUCCESS,
  DELETE_ENTITY_DETAILS,
  FETCH_BANK_ACCOUNT,
  FETCH_BANK_ACCOUNT_FAILURE,
  FETCH_BANK_ACCOUNT_SUCCESS
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface EntityReducerStateProps extends StateBase {
  entityType: 'buyer' | 'funder' | 'beneficiary' | undefined;
  entityDetails: EntityOwner | null;
  bankAccount: EntityBankAccount | null;
}

const DEFAULT_STATE: EntityReducerStateProps = {
  entityType: undefined,
  entityDetails: null,
  loading: false,
  bankAccount: null,
  error: ''
};

const entityReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case FETCH_ENTITY_DETAILS:
      return {
        ...DEFAULT_STATE,
        ...state,
        loading: true
      };
    case FETCH_ENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        entityType: payload.entityType,
        entityDetails: payload.entityDetails,
        loading: false,
        error: ''
      };
    case FETCH_ENTITY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case DELETE_ENTITY_DETAILS:
      return {
        ...DEFAULT_STATE,
        ...state,
        entityType: undefined,
        entityDetails: null,
        loading: true
      };
    case FETCH_BANK_ACCOUNT:
      return {
        ...DEFAULT_STATE,
        ...state,
        loading: true
      };
    case FETCH_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccount: payload.data,
        loading: false,
        error: ''
      };
    case FETCH_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export { entityReducer };
export type { EntityReducerStateProps };
