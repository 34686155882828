import { IOnboardingEntity } from 'api/interfaces/onboarding/onboarding.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import { AxiosResponse } from 'axios';
import { IPreInitialisationData } from 'store/epics/app';

export const appPreIntialisation: (accessToken: string) => Promise<IPreInitialisationData> = async (accessToken) => {
  const onboardingApi = new OnboardingApi(accessToken);

  const getLegalEntityByTenantIdResponse: AxiosResponse<IOnboardingEntity> =
    await onboardingApi.getLegalEntityByTenantId(accessToken);

  const programIds: string[] = Object.keys(
    getLegalEntityByTenantIdResponse.data.state.programStatus
  );

  const onboardingComplete: boolean =
    getLegalEntityByTenantIdResponse.data.state.onboardingStatus === 'complete' &&
    programIds.filter(
      (id) => getLegalEntityByTenantIdResponse.data.state.programStatus[id] === 'complete'
    ).length === programIds.length;

  return {
    getLegalEntityByTenantIdResponse,
    onboardingComplete
  };
};
