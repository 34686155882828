import { ILedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { LedgerSummaryItem } from 'components/ledger/LedgerDetail/LedgerDetailSummary';

import ledgerLogisticHeaderBuilder from 'utils/classes/ledger/ledgerLogisticHeaderBuilder';
import LedgerSummaryItemBuilder from 'utils/classes/ledger/ledgerSummaryItemBuilder';

export const mapLogisticToLedgerSummaryFormat: (
  logistic: ILedgerLogistic
) => LedgerSummaryItem[] = (logistic) => [
  new LedgerSummaryItemBuilder(
    'Document Description',
    'documentDescription',
    logistic.data?.subTemplate?.name || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Tracking Number',
    'trackingNumber',
    logistic.data.trackingNumber || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Consignee Reference',
    'consigneeReference',
    logistic.data.consigneeReference || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Consignee Name',
    'consignorName',
    logistic.data.consigneeName || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Consignor Reference',
    'consignorReference',
    logistic.data.consignorReference || '-',
    '',
    ''
  ),
  new LedgerSummaryItemBuilder(
    'Consignor Name',
    'consignorName',
    logistic.data.consignorName || '-',
    '',
    ''
  )
];

export const mapLogisticHeaderData: (logistic: ILedgerLogistic) => ledgerLogisticHeaderBuilder = (
  logistic
) => {
  return new ledgerLogisticHeaderBuilder(
    logistic?.data.trackingNumber || '-',
    logistic?.linkedPrograms[0]?.logisticStatus || '',
    logistic?.linkedPrograms[0]?.matchingStatus
  );
};
