import { themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const OnboardingTasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${isMobile() ? '95%' : '85%'};
  margin-top: 24px;
`;

export const OnboardingTasksSnackboxDescription = styled.p`
  color: ${themeColors.text.light.body};
`;

export const OnboardingTasksTitle = styled.h3``;
