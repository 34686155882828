import { styling, themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const PanelCard = styled.div<{ minimal?: string }>`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${styling.defaultLargePadding};
  border-radius: ${styling.defaultRadius};
  border: 1px solid ${themeColors.border.primary};
  background-color: ${themeColors.bg.light.surface};
  height: ${({ minimal }) => minimal || '455px'};
  gap: 16px;
  width: ${({ minimal }) => minimal || ''};
  flex: 0.3333333333;
  min-width: ${({ minimal }) => minimal || '200px'};
`;

export const PanelTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const PanelTitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export const PanelDataRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PanelDataSegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  width: 100%;
`;

export const PanelDataGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PanelContainer = styled.div<{ align?: string; padding?: string; gap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'flex-start'};
  padding: ${({ padding }) => padding || 'none'};
  gap: ${({ gap }) => gap || '24px'};
  flex: 1;
`;

export const PanelButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const PanelButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const PanelBannerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const PanelBannerSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PanelBannerButtonContainer = styled.div`
  display: flex;
  width: fit-content;
`;

export const PanelBannerButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const PanelBannerButtonText = styled.p`
  ${typography.bodySmallSemiBold};
`;

export const PanelBannerIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const PanelLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PanelLoaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PanelLoaderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-bottom: 8px;
`;
