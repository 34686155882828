import notFoundBg from 'assets/images/backgroundImages/kx-bg.png';
import { themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const NotFoundPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  flex: 1;
  align-items: center;
  height: 100%;
  background-image: url(${notFoundBg});
  margin-top: -24px;
  text-align: center;
`;

export const NotFoundPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: ${isMobile() ? '100%' : '65vw'};
  justify-content: center;
  align-items: center;
`;

export const NotFoundPageRightContainer = styled.div`
  display: flex;
`;

export const NotFoundPageContainerError = styled.h2`
  font-size: 7rem;
  color: ${themeColors.text.light.onImage};
`;

export const NotFoundPageContainerPageTitle = styled.h2`
  color: ${themeColors.text.light.onImage};
  font-size: 36px;
`;

export const NotFoundPageContainerDescription = styled.p`
  color: ${themeColors.text.light.onImage};
  font-size: 22px;
`;

export const NotFoundPageImage = styled.img`
  margin: 0;
  width: 100%;
  margin-bottom: ${isMobile() ? '5rem' : '0'};
`;

export const NotFoundPageContainerButtonText = styled.p``;
