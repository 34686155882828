import ErrorIcon from '@mui/icons-material/Error';
import AcceptedIcon from 'assets/icons/AcceptedIcon';
import Pending3Icon from 'assets/icons/Pending3Icon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import Chip from 'components/common/Chip';
import { DocumentStatus } from 'lib/enums/documentStatus.enum';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { FC } from 'react';
interface ChipsProps {
  type: string;
  testingTag?: string;
  ledgerType: keyof typeof LedgerTypes;
  isPlain?: boolean;
}

const MatchingChip: FC<ChipsProps> = ({ type, testingTag, ledgerType, isPlain = false }) => {
  const renderStatus: (type: string) => JSX.Element = (type) => {
    switch (type) {
      case DocumentStatus.pending:
        return (
          <Chip
            type={type}
            background={`${themeColors.bg.warning}30`}
            icon={<Pending3Icon />}
            color={themeColors.icon.warning}
            testingTag={testingTag}
            tooltipText={
              ledgerType === LedgerTypes.INVOICE
                ? "This invoice is almost ready, we're just creating it on Supplier Pay. "
                : ledgerType === LedgerTypes.PO
                ? "This purchase order is almost ready, we're just creating it on Supplier Pay."
                : "This logistic is almost ready, we're just creating it on Supplier Pay."
            }
          />
        );

      case DocumentStatus.accepted:
        return (
          <Chip
            type={type}
            icon={<AcceptedIcon />}
            background={`${themeColors.chart.light.colour7}30`}
            color={themeColors.icon.success}
            testingTag={testingTag}
            tooltipText={
              ledgerType === LedgerTypes.INVOICE ? '' : ledgerType === LedgerTypes.PO ? '' : ''
            }
          />
        );

      case DocumentStatus.matched:
        return (
          <Chip
            type={type}
            icon={<ApproveIcon height="16" width="16" />}
            background={`${themeColors.chart.light.colour7}30`}
            color={themeColors.icon.success}
            testingTag={testingTag}
          />
        );

      case DocumentStatus.discrepant:
        return (
          <Chip
            type={type}
            background={`${themeColors.icon.error}30`}
            icon={
              <ErrorIcon
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '0px',
                  color: themeColors.icon.error
                }}
              />
            }
            color={themeColors.bg.error}
            testingTag={testingTag}
            tooltipText={
              ledgerType === LedgerTypes.INVOICE
                ? 'An issue was found on this invoice during the matching process. Your buyer will review the issue(s) and accept it for payment or contact you to amend the invoice.'
                : ledgerType === LedgerTypes.PO
                ? 'An issue was found on the invoice(s) or logistics documents linked to this purchase order during the matching process.'
                : 'An issue was found on the invoice(s) or logistics documents linked to this purchase order during the matching process.'
            }
          />
        );

      case DocumentStatus.rejected:
        return (
          <Chip
            type={type}
            background={`${themeColors.icon.light.inactive}30`}
            icon={
              <ErrorIcon
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '0px',
                  color: themeColors.icon.light.muted
                }}
              />
            }
            color={themeColors.icon.light.muted}
            testingTag={testingTag}
          />
        );
      default:
        return (
          <Chip
            type={type}
            semibold="bold"
            color={themeColors.text.primary}
            testingTag={testingTag}
          />
        );
    }
  };

  const renderPlainStatus: (type: string) => JSX.Element = (type) => {
    switch (type) {
      case DocumentStatus.closed:
        return (
          <Chip
            type={type}
            color={themeColors.icon.light.muted}
            testingTag={testingTag}
            radius="3px"
            background={`${themeColors.icon.light.inactive}30`}
          />
        );
      default:
        return (
          <Chip
            type={type}
            color={themeColors.text.primary}
            testingTag={testingTag}
            radius="3px"
            background={themeColors.lightBlue}
          />
        );
    }
  };

  return isPlain ? renderPlainStatus(type.toLowerCase()) : renderStatus(type.toLowerCase());
};

export default MatchingChip;
