import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: -8px;
  color: ${themeColors.text.light.body};
  font-size: ${isMobile() ? fontSizes.large : fontSizes.xLarge};
`;
