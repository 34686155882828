import { typography } from 'assets/theme/typography';
import { Accordion } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const EntityDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
`;

export const EntityDetailsTitle = styled.h2`
  margin-bottom: 18px;
  color: ${themeColors.text.primary};
`;

export const EntityDetailsItemsTitle = styled.h5`
  margin: 0;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
    margin: 0;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 24px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
  }
  .MuiButtonBase-root {
    padding: 0;
    min-height: 40px;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionDetails-root {
    padding: 3px 0;
    ${typography.bodyRegular};
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;
export const AccordionSummaryTitle = styled.h4`
  margin: 0;
`;
