import { themeColors } from 'assets/theme/style';
import React, { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const UploadIcon: FC<IconSvgProps> = ({ color = themeColors.icon.primary }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.98816C15.3254 8.3136 15.3254 8.84123 15 9.16667C14.6746 9.49211 14.1469 9.49211 13.8215 9.16667L10.8333 6.17851L10.8333 14.1667C10.8333 14.6269 10.4602 15 10 15C9.53977 15 9.16667 14.6269 9.16667 14.1667L9.16667 6.17851L6.17852 9.16667C5.85308 9.49211 5.32544 9.49211 5.00001 9.16667C4.67457 8.84123 4.67457 8.3136 5.00001 7.98816L10 2.98816L15 7.98816Z"
        fill={color}
        id="fillHover"
      />
      <path
        d="M4.16666 17.5L15.8333 17.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        id="strokeHover"
      />
    </svg>
  );
};

export default UploadIcon;
