/* eslint-disable react-hooks/exhaustive-deps */
import {
  ITaskData,
  ITaskDataItem,
  TaskDetails
} from 'api/interfaces/funding-request/task.interface';
import {
  ILogisticsRule,
  ILogisticsRuleSubtemplate,
  ILogisticsRuleSubtemplateField,
  IProgramRule,
  IRuleField,
  ProgramConfig
} from 'api/interfaces/program/program.interface';
import OrchestrationApi from 'api/orchestration/orchestration.api';
import ProgramApi from 'api/program/program.api';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import Uploader from 'components/common/Uploader';
import { IUploaderFile, IUploaderLineValidationMessage } from 'components/common/Uploader/Uploader';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import ErrorMessageText from 'components/common/typography';
import Snackbar from 'components/snackbar';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { csvUploadMandatoryFieldInclusions } from 'lib/lookups/csvUpload.lookup';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { TRIGGER_SNACKBAR } from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import {
  DocumentUploadDialogContainer,
  DocumentUploadDialogH5,
  DocumentUploadDialogInfoWrapper,
  DocumentUploadDialogLeft,
  DocumentUploadDialogParagraph,
  DocumentUploadDialogTitle,
  DocumentUploadDialogWrapper,
  DocumentUploadProgramListContainer
} from './styled';
import InvoiceUploadIcon from 'assets/icons/InvoiceUploadIcon';
import LogisticUploadIcon from 'assets/icons/LogisticUploadIcon';
import PaymentUploadIcon from 'assets/icons/PaymentUploadIcon';
import { RightArrowBoldIcon } from 'assets/icons/ArrowIcons';

interface IError {
  reference: string;
  reasons: string[];
}

const noProgramSnackbarData: SnackbarData = {
  title: <h5>Can not get details for the selected program.</h5>,
  message: <p>Something has gone wrong when trying to get the selected program rules.</p>,
  leftIcon: <WarningIcon color={themeColors.icon.error} />,
  topAligned: true,
  type: 'error'
};

const DocumentUploadDialog: FC = () => {
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState<IUploaderFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [assetType, setAssetType] = useState<keyof typeof AssetEnum | null>(null);
  const [subtemplate, setSubtemplate] = useState<ILogisticsRuleSubtemplate | null>(null);
  const [selectedProgram, setSelectedProgram] = useState<ProgramConfig | null>(null);
  const [filteredPrograms, setFilteredPrograms] = useState<any[]>([]);
  const [uploaderSubmitting, setUploadSubmitting] = useState<number[]>([]);
  const [uploadInvalid, setUploadInvalid] = useState<boolean>(false);
  const [uploaderValidationFeedback, setUploaderValidationFeedback] = useState<
    IUploaderLineValidationMessage[]
  >([]);
  const [headerMetadata, setHeaderMetadata] = useState<
    { displayReferencePath: string; referencePath: string }[]
  >([]);
  const [rules, setRules] = useState<IProgramRule<any> | null>(null);

  const {
    allProgramsWithDetails,
    snackbarOpen
  }: {
    programs: ProgramConfig[];
    allProgramsWithDetails: ProgramConfig[];
    snackbarOpen: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const orchestrationApi = new OrchestrationApi(store);
  const programApi = new ProgramApi(store);

  useEffect(() => {
    return () => {
      setFileData([]);
      setIsSubmitting(false);
    };
  }, []);

  useEffect(() => {
    if (!Boolean(selectedProgram) && Boolean(assetType)) {
      retrieveProgramsByAssetType();
    }
  }, [assetType]);

  useEffect(() => {
    if (Boolean(selectedProgram) && Boolean(assetType)) {
      getDocumentRules();
    }

    if (
      Boolean(selectedProgram) &&
      Boolean(assetType) &&
      assetType === AssetEnum.LOGISTIC &&
      Boolean(subtemplate)
    ) {
      processTemplateRules(subtemplate?.fields || []);
    }
  }, [selectedProgram, assetType, subtemplate]);

  const filterMandatoryData: (data: any[]) => any[] = (data) => {
    // eslint-disable-next-line array-callback-return
    return data.filter((f) => {
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length === 0)
        return f;
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length > 0) {
        f.nestedFields = filterMandatoryData(f.nestedFields);
        return f;
      }
    });
  };

  const getDocumentRules: () => any = () => {
    const matchedProgram = allProgramsWithDetails.find((p) => p.id === selectedProgram?.id);

    if (!Boolean(matchedProgram)) {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: noProgramSnackbarData } });
      setSelectedProgram(null);
      setLoading(false);
      setTimeout(() => {
        dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
      }, 15000);
    } else {
      setLoading(true);

      if (assetType === AssetEnum.INVOICE) {
        const matchedRules: IProgramRule<any> | undefined = matchedProgram?.rules.find((rule) =>
          rule.type.includes('INVOICE_TEMPLATE')
        );

        if (!matchedRules) return;

        processTemplateRules(matchedRules.value.fields);

        setLoading(false);
      }

      if (assetType === AssetEnum.LOGISTIC) {
        const matchedRules: IProgramRule<any> | undefined = matchedProgram?.rules.find((rule) =>
          rule.type.includes('LOGISTIC_TEMPLATE')
        );

        if (!matchedRules) return;
        setRules(matchedRules);

        setLoading(false);
      }
    }
  };

  const processTemplateRules: (data: ILogisticsRuleSubtemplateField[]) => void = (data) => {
    setLoading(true);
    setHeaderMetadata(mappedHeaders(filterMandatoryData(data || [])));
    setLoading(false);
  };

  const retrieveProgramsByAssetType: () => void = async () => {
    if (!assetType) return;
    setLoading(true);
    const getProgramsByAssetTypeResponse = await programApi.getProgramsByAssetType(assetType);

    if (getProgramsByAssetTypeResponse) setFilteredPrograms(getProgramsByAssetTypeResponse.data);

    setLoading(false);
  };

  const mappedHeaders: (
    data: IRuleField[],
    parentDisplayReferencePath?: string,
    objectReferencePath?: string
  ) => { displayReferencePath: string; referencePath: string }[] = (
    data,
    parentDisplayReferencePath,
    objectReferencePath
  ) => {
    return data.flatMap((d) => {
      d.displayReferencePath = parentDisplayReferencePath
        ? `${parentDisplayReferencePath}.${d.displayName}`
        : `${d.displayName}`;
      d.referencePath = objectReferencePath ? `${objectReferencePath}.${d.name}` : `${d.name}`;
      if (d.nestedFields.length > 0)
        return mappedHeaders(d.nestedFields, d.displayReferencePath, d.referencePath);
      return { displayReferencePath: d.displayReferencePath, referencePath: d.referencePath };
    });
  };

  const filesHandler: (files: IUploaderFile[]) => void = (files) => {
    if (!files) return;
    const mappedModel: IUploaderFile[] = files.map((f) => {
      const postModel = f.parsedCSV?.data.map((d: any) => {
        const newModel: IUploaderFile | {} = {};

        for (const key in Object.keys(d)) {
          const path = headerMetadata.find((m) => m.displayReferencePath === Object.keys(d)[key]);
          if (path) _.set(newModel, path.referencePath, d[Object.keys(d)[key]]);
        }

        if ((newModel as any)?.lineItems) {
          (newModel as any).lineItems = [(newModel as any).lineItems];
        }

        return newModel;
      });

      const mappedModelsWithLineItems = lineItemsMapper(postModel, assetType).flatMap(
        (m: any) => m
      );

      f.postModel = mappedModelsWithLineItems;

      return f;
    });

    setFileData(mappedModel);
  };

  const lineItemsMapper: (models: any[], type: keyof typeof AssetEnum | null) => any = (
    models,
    type
  ) => {
    if (!type || !models) return [];

    return models.reduce((acc: any[], value) => {
      if (Boolean(acc.length)) {
        const matchedExistingValue = acc.findIndex(
          (a: any) =>
            csvUploadMandatoryFieldInclusions[type].filter(
              (inv) => a[0][inv.fieldName] === value[inv.fieldName]
            ).length === csvUploadMandatoryFieldInclusions[type].length
        );

        if (matchedExistingValue === -1) {
          acc.push([value]);
        }

        if (matchedExistingValue > -1) {
          acc[matchedExistingValue][0].lineItems.push(...value.lineItems);
        }
      } else {
        acc.push([value]);
      }

      return acc;
    }, []);
  };

  const errorJSXMapper: (errors: IError[]) => JSX.Element = (errors) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {errors.map((e, i) => {
          return (
            <React.Fragment key={i}>
              <h5>{e.reference}</h5>
              {e.reasons.map((r, index) => (
                <ErrorMessageText text={r} key={(index + i) * 100} />
              ))}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const errorMapper: (details: TaskDetails<ITaskData<ITaskDataItem>>) => JSX.Element | null = (
    details
  ) => {
    const topLevelErrors: IError[] = details.data.items.map((d) => ({
      reference: d.referenceName,
      reasons: d.reasons
    }));
    const programErrors: IError[] = details.data.items.flatMap((item) =>
      item.programStatuses.flatMap((p) => ({
        reference: `${item.referenceName} - ${p.programName}`,
        reasons: p.reasons
      }))
    );

    if (
      programErrors.map((p) => p.reasons).length === 0 &&
      topLevelErrors.map((e) => e.reasons).length === 0
    )
      return null;

    const errors = [
      ...(topLevelErrors.flatMap((p) => p.reasons).length > 0 ? topLevelErrors : []),
      ...(programErrors.flatMap((p) => p.reasons).length > 0 ? programErrors : [])
    ];

    return errorJSXMapper(errors);
  };

  const submitAssets: () => void = async () => {
    setIsSubmitting(true);
    if (!selectedProgram || !assetType || fileData.length === 0) return;

    fileData.forEach(async (csv, i) => {
      setUploadSubmitting((s) => [...s, i]);
      const response = await orchestrationApi.uploadAsset(
        selectedProgram?.id,
        assetType,
        csv.postModel
      );

      const errorMappedResult: JSX.Element | null = errorMapper(response);

      setUploaderValidationFeedback((v) => [...v, { index: i, jsx: errorMappedResult }]);

      const mutArr = [...uploaderSubmitting];
      const indexForRemoval = mutArr.indexOf(i);
      mutArr.splice(indexForRemoval, 1);
      setUploadSubmitting(mutArr);
    });

    setIsSubmitting(false);
  };

  const errorJSX = (
    <LayoutViewContainer size="small" minWidth="566px">
      <DocumentUploadDialogTitle>Something went wrong...</DocumentUploadDialogTitle>
      <DocumentUploadDialogParagraph>
        There was a problem retrieve the data to validate the CSV. Please contact us if this
        persists.
      </DocumentUploadDialogParagraph>
    </LayoutViewContainer>
  );

  const selectDocumentJSX = (
    <LayoutViewContainer size="small" minWidth="566px">
      <DocumentUploadDialogTitle>Select document type</DocumentUploadDialogTitle>
      <DocumentUploadDialogParagraph>
        What type of document would you like to upload:
      </DocumentUploadDialogParagraph>
      {Object.values(AssetEnum)
        .filter((a) => a !== AssetEnum.PURCHASE_ORDER && a !== AssetEnum.PAYMENT)
        .map((asset) => (
          <DocumentUploadProgramListContainer
            key={asset}
            onClick={() => {
              setAssetType(AssetEnum[asset]);
            }}
          >
            <BaseCard hasHover noPadding>
              <DocumentUploadDialogWrapper>
                <DocumentUploadDialogLeft>
                  {AssetEnum.INVOICE === asset && <InvoiceUploadIcon />}
                  {AssetEnum.LOGISTIC === asset && <LogisticUploadIcon />}
                  <DocumentUploadDialogInfoWrapper>
                    <DocumentUploadDialogH5>{asset}</DocumentUploadDialogH5>
                    {AssetEnum.INVOICE === asset && (
                      <DocumentUploadDialogParagraph>
                        Upload invoice data
                      </DocumentUploadDialogParagraph>
                    )}
                    {AssetEnum.PURCHASE_ORDER === asset && (
                      <DocumentUploadDialogParagraph>
                        Upload purchase order data
                      </DocumentUploadDialogParagraph>
                    )}
                    {AssetEnum.LOGISTIC === asset && (
                      <DocumentUploadDialogParagraph>
                        Upload logistics data
                      </DocumentUploadDialogParagraph>
                    )}
                  </DocumentUploadDialogInfoWrapper>
                </DocumentUploadDialogLeft>
                <RightArrowBoldIcon />
              </DocumentUploadDialogWrapper>
            </BaseCard>
          </DocumentUploadProgramListContainer>
        ))}
    </LayoutViewContainer>
  );

  const selectProgramJSX = (
    <LayoutViewContainer size="small" minWidth="566px">
      <DocumentUploadDialogTitle>Select program</DocumentUploadDialogTitle>
      {snackbarOpen && (
        <div style={{ width: '30rem' }}>
          <Snackbar />
        </div>
      )}
      <DocumentUploadDialogParagraph>
        Which program would you like to upload this document to:
      </DocumentUploadDialogParagraph>
      {filteredPrograms.map((program) => (
        <DocumentUploadProgramListContainer
          key={program.id}
          onClick={() => {
            setSelectedProgram(program);
          }}
        >
          <BaseCard hasHover noPadding>
            <DocumentUploadDialogWrapper>
              <DocumentUploadDialogInfoWrapper>
                <DocumentUploadDialogH5>{program.customName}</DocumentUploadDialogH5>
                <DocumentUploadDialogParagraph>
                  Currencies: {program.acceptedCurrencies.join(' | ')}
                </DocumentUploadDialogParagraph>
              </DocumentUploadDialogInfoWrapper>
              <RightArrowBoldIcon />
            </DocumentUploadDialogWrapper>
          </BaseCard>
        </DocumentUploadProgramListContainer>
      ))}
    </LayoutViewContainer>
  );

  const selectLogisticDocumentJSX = (
    <LayoutViewContainer size="small" minWidth="566px">
      <DocumentUploadDialogTitle>Select logistic document</DocumentUploadDialogTitle>
      {snackbarOpen && (
        <div style={{ width: '30rem' }}>
          <Snackbar />
        </div>
      )}
      <DocumentUploadDialogParagraph>
        Please select the logistic document that you would like to upload.
      </DocumentUploadDialogParagraph>
      {(rules?.value as ILogisticsRule)?.subTemplates.map((template) => (
        <DocumentUploadProgramListContainer
          key={template.templateReference}
          onClick={() => {
            setSubtemplate(template);
          }}
        >
          <BaseCard hasHover noPadding>
            <DocumentUploadDialogWrapper>
              <DocumentUploadDialogH5>{template.name}</DocumentUploadDialogH5>
            </DocumentUploadDialogWrapper>
          </BaseCard>
        </DocumentUploadProgramListContainer>
      ))}
    </LayoutViewContainer>
  );

  const uploadJSX = (
    <LayoutViewContainer size="small" minWidth="566px">
      <DocumentUploadDialogTitle>Upload data</DocumentUploadDialogTitle>
      <DocumentUploadDialogParagraph>
        Add the data you would like to upload.
      </DocumentUploadDialogParagraph>
      <Uploader
        multiple
        title="Drag files here"
        acceptedFileTypes={['csv']}
        limitBytes={5000000}
        fileDropHandler={filesHandler}
        selectedSimple
        csvHeaderValidationFields={headerMetadata.map((m) => m.displayReferencePath)}
        validationFeedback={uploaderValidationFeedback}
        submitting={uploaderSubmitting}
        isInvalidCallback={(isInvalid) => setUploadInvalid(isInvalid)}
      />
      {fileData?.length > 0 && (
        <PrimaryButton
          clickHandler={(e) => {
            e.preventDefault();
            submitAssets();
          }}
          testingTag={`document-upload-button-file-upload-submit`}
          text="Upload"
          width="fit-content"
          disabled={isSubmitting || uploadInvalid}
        />
      )}
    </LayoutViewContainer>
  );

  const renderView: () => JSX.Element = () => {
    if (!assetType) return selectDocumentJSX;

    if (assetType === AssetEnum.INVOICE) {
      if (!Boolean(selectedProgram) && assetType) return selectProgramJSX;
      if (selectedProgram && assetType && headerMetadata?.length > 0) return uploadJSX;
    }

    if (assetType === AssetEnum.LOGISTIC) {
      if (!Boolean(selectedProgram) && assetType) return selectProgramJSX;
      if (Boolean(selectedProgram) && assetType && !Boolean(subtemplate))
        return selectLogisticDocumentJSX;
      if (selectedProgram && Boolean(subtemplate) && rules && headerMetadata?.length > 0)
        return uploadJSX;
    }
    return errorJSX;
  };

  return loading ? (
    <LoaderFullPage />
  ) : (
    <DocumentUploadDialogContainer>{renderView()}</DocumentUploadDialogContainer>
  );
};

export default DocumentUploadDialog;
