import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';

export const footerNavItems: NavigationItem[] = [
  {
    text: 'Privacy policy',
    path: 'https://kanexa.net/en/privacy-policy/'
  },
  {
    text: 'Cookie policy',
    path: 'https://kanexa.net/en/cookie-policy/'
  }
  // {
  //   text: 'Accessibility',
  //   path: 'https://marcopolonetwork.com/accessibility/'
  // }
];
