import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const GetPaidNowIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '20px',
  width = '20px',
  disabled = false
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.375 8.3125C14.3438 8.03125 14.125 7.875 13.7812 7.875C13.6562 7.875 13.5625 7.875 13.4375 7.90625L12.7813 8C12.6563 8.03125 12.5 8.03125 12.375 8.0625C11.9687 8.125 11.5937 8.1875 11.1875 8.21875C11.0312 8.21875 10.9062 8.25 10.7187 8.25H10.6562C10.4062 8.03125 10.0625 7.90625 9.71875 7.90625C9.375 7.90625 9.0625 8.0625 8.8125 8.3125L8.78125 8.34375C8.65625 8.5 8.53125 8.6875 8.46875 8.875V8.9375C8.4375 9.0625 8.4375 9.15625 8.4375 9.25C8.4375 9.53125 8.53125 9.78125 8.6875 10C8.71875 10.0312 8.71875 10.0625 8.75 10.0937L8.84375 10.1875L8.90625 10.25C9.03125 10.3437 9.1875 10.4375 9.34375 10.5C9.46875 10.5312 9.625 10.5625 9.78125 10.5625C10.25 10.5625 10.6875 10.2812 10.9063 9.875C10.9688 9.84375 11.0625 9.78125 11.1563 9.75L11.2188 9.71875C11.5 9.59375 11.8125 9.5 12.0938 9.40625C12.6562 9.21875 13.25 9.0625 13.875 8.90625C14.375 8.8125 14.375 8.5 14.375 8.3125Z"
      fill={disabled ? themeColors.icon.primaryDisabled : color}
    />
    <path
      d="M13.3438 15.5621C13.0313 15.4684 12.7188 15.3434 12.4376 15.2496L12.3126 15.2184L10.1563 14.4684C9.84385 14.3434 9.5001 14.4059 9.28135 14.5934C9.03135 14.7809 8.96885 15.0934 9.03135 15.4371C9.0626 15.5309 9.0626 15.5934 9.09385 15.7184L9.1876 16.2496H8.9376C5.96885 15.8434 3.59385 13.5934 3.03135 10.6559C2.9376 10.1246 2.8751 9.59339 2.90635 9.06214C2.96885 7.12464 3.8126 5.34339 5.2501 4.06214C6.5001 2.81214 8.1251 2.15589 9.7501 2.09339C10.1876 2.09339 10.5938 2.09339 11.0313 2.15589C11.2188 2.18714 11.4063 2.21839 11.6251 2.28089C12.0313 2.37464 12.4376 2.15589 12.5938 1.78089C12.6876 1.56214 12.6563 1.31214 12.5626 1.09339C12.4376 0.874641 12.2501 0.718391 12.0313 0.687141C11.5626 0.593391 11.0626 0.530891 10.5938 0.499641C9.0626 0.437141 7.6876 0.687141 6.46885 1.21839C5.8751 1.46839 5.28135 1.84339 4.65635 2.34339L4.53135 2.46839C4.21885 2.71839 3.9376 2.99964 3.6876 3.28089C1.6876 5.53089 0.937598 8.03089 1.4376 10.6871C1.5626 11.3121 1.7501 11.9684 2.03135 12.5934C3.0626 15.1559 5.0001 16.8121 7.78135 17.5934C8.15635 17.6871 8.5626 17.7496 8.96885 17.8434C9.15635 17.8746 9.34385 17.9059 9.5626 17.9371H9.59385L9.6876 18.4996C9.71885 18.6246 9.7501 18.7496 9.7501 18.8434C9.8126 19.1871 10.0313 19.4371 10.3126 19.5309C10.3751 19.5621 10.4688 19.5621 10.5313 19.5621C10.7501 19.5621 10.9688 19.4684 11.1563 19.3121C12.0001 18.5934 12.8438 17.8121 13.7188 17.0621C14.0001 16.8121 14.0938 16.5309 14.0626 16.2496C13.9063 15.9059 13.6876 15.6559 13.3438 15.5621Z"
      fill={disabled ? themeColors.icon.primaryDisabled : color}
    />
    <path
      d="M16.156 14.4689C15.9685 14.3127 15.781 14.1252 15.5935 13.9689L15.4373 13.8439C15.3123 13.7814 15.1873 13.7502 15.031 13.7502C14.8435 13.7502 14.6873 13.8439 14.5623 14.0002C14.3435 14.2814 14.3435 14.6564 14.5623 14.9064L14.8435 15.1564L15.2498 15.5002C15.3748 15.6252 15.531 15.6877 15.7185 15.6877C15.9373 15.6877 16.1248 15.6252 16.281 15.4689C16.531 15.1877 16.4685 14.7502 16.156 14.4689ZM17.8123 11.2502C17.5623 11.1877 17.3435 11.0939 17.0935 11.0314L16.906 11.0002C16.781 11.0002 16.656 11.0002 16.4998 11.0939C16.3435 11.1877 16.2185 11.3439 16.1873 11.5002C16.0623 11.8439 16.2498 12.1877 16.5623 12.3127L16.906 12.4064L17.406 12.5627C17.5935 12.6252 17.7498 12.5939 17.906 12.5314C18.0935 12.4689 18.2498 12.3127 18.3123 12.0939C18.4373 11.7502 18.2185 11.3752 17.8123 11.2502ZM17.906 7.62518C17.656 7.65643 17.406 7.68768 17.156 7.75018L16.9685 7.81268C16.8435 7.87518 16.7498 7.93768 16.656 8.06268C16.5623 8.21893 16.4998 8.37518 16.531 8.56268C16.5935 8.90643 16.8748 9.15643 17.2498 9.12518L17.5935 9.06268L18.1248 8.96893C18.3123 8.93768 18.4685 8.87518 18.5623 8.75018C18.7185 8.59393 18.781 8.40643 18.7498 8.18768C18.6873 7.81268 18.3435 7.56268 17.906 7.62518ZM16.4998 4.28143C16.281 4.40643 16.0935 4.56268 15.8748 4.68768L15.7185 4.81268C15.6248 4.90643 15.5623 5.03143 15.531 5.18768C15.4998 5.37518 15.531 5.53143 15.656 5.68768C15.8435 5.96893 16.2185 6.06268 16.5623 5.90643L16.8748 5.71893L17.3123 5.43768C17.4685 5.34393 17.5623 5.18768 17.6248 5.03143C17.6873 4.84393 17.656 4.62518 17.531 4.43768C17.281 4.12518 16.8435 4.06268 16.4998 4.28143ZM13.781 1.87518L13.406 2.53143L13.3123 2.71893C13.281 2.84393 13.2498 2.96893 13.281 3.12518C13.3123 3.31268 13.4373 3.43768 13.5935 3.53143C13.906 3.71893 14.281 3.62518 14.4998 3.34393L14.6873 3.03143L14.9685 2.59393C15.0623 2.43768 15.0935 2.28143 15.0935 2.09393C15.0623 1.87518 14.9685 1.71893 14.781 1.59393C14.406 1.40643 13.9998 1.53143 13.781 1.87518Z"
      fill={disabled ? themeColors.icon.primaryDisabled : color}
    />
  </svg>
);

export default GetPaidNowIcon;
