/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import BrowserExtensionDialog from './BrowserExtensionDialog';
import ConnectAccountingSoftwareDialog from './ConnectAccountingSoftwareDialog';

export interface IConnectAccountingSoftwareProps {
  closeHandler: () => void;
}

const ConnectAccountingSoftware: FC<IConnectAccountingSoftwareProps> = ({ closeHandler }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  return !isConnected ? (
    <ConnectAccountingSoftwareDialog clickHandler={() => setIsConnected(true)} />
  ) : (
    <BrowserExtensionDialog closeHandler={closeHandler} />
  );
};

export default ConnectAccountingSoftware;
