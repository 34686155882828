import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import { History } from 'history';
import { appReducer } from './app';
import { fundingRequestReducer } from './fundingRequest';
import { programReducer } from './program';
import { ledgerReducer } from './ledger';
import { entityReducer } from './entity';
import { breadcrumbReducer } from './breadcrumb';
import { paymentReducer } from './payment';
import { errorReducer } from './error';
import { earlyPaymentReducer } from './earlyPayment';
import { purchaseOrderReducer } from './purchaseOrder';
import { logisticReducer } from './logistic';
import { invoiceReducer } from './invoice';
import { adjustmentsReducer } from './adjustment';
import { userReducer } from './user';
import { onboardingReducer } from './onboarding';
import { gatewayToFinanceReducer } from './gatewayToFinance';
import { contextReducer } from './context';
import { createInvoiceReducer } from './createInvoice';
export interface ReducerAction {
  type: string;
  payload?: any;
}

export interface StateBase {
  error: string;
  loading: boolean;
}

export const createRouteReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    fundingRequest: fundingRequestReducer,
    program: programReducer,
    ledger: ledgerReducer,
    entity: entityReducer,
    breadcrumb: breadcrumbReducer,
    payment: paymentReducer,
    error: errorReducer,
    earlyPayment: earlyPaymentReducer,
    purchaseOrder: purchaseOrderReducer,
    logistic: logisticReducer,
    invoice: invoiceReducer,
    adjustment: adjustmentsReducer,
    user: userReducer,
    onboarding: onboardingReducer,
    gatewayToFinance: gatewayToFinanceReducer,
    createInvoice: createInvoiceReducer,
    context: contextReducer
  });
