import { typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const EntityDetailsTextLink = styled.p`
  display: flex;
  color: ${themeColors.text.primary};
  ${typography.bodyBold};
  cursor: pointer;
`;
