import { themeColors } from 'assets/theme/style';
import { fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ITileSelected {
  active: boolean;
}

export const CurrencyTileContainer = styled.div<ITileSelected>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  align-items: flex-start;
  border-radius: 16px;
  border: ${({ active }) => (active ? `2px solid ${themeColors.border.primaryActive}` : `2px solid ${themeColors.border.primary}`)};
  background: ${({ active }) => (active ? themeColors.bg.light.surfaceRaised : themeColors.bg.light.surface)};
  height: 40px;

  span {
    background-repeat: no-repeat;
    background-position: center center;
  }
  &:hover {
    cursor: ${({ active }) => (active ? '' : 'pointer')};
    border: ${({ active }) => (active ? '' : `2px solid ${themeColors.border.primaryHover}`)};
  }
`;

export const CurrencyTileP = styled.p``;
