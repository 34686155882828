import { Collapse } from '@mui/material';
import ClearIcon from 'assets/icons/ClearIcon';
import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { TRIGGER_SNACKBAR } from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import {
  SnackbarIconContainer,
  SnackbarCloseIconContainer,
  SnackbarLeftContainer,
  SnackbarStyledWrapper,
  SnackbarTextContainer
} from './styled';

const Snackbar: FC = () => {
  const dispatch = useDispatch();
  const snackbarOpen: boolean = useSelector((state: RootStateOrAny) => state.app.snackbarOpen);
  const snackbarData: SnackbarData | null = useSelector(
    (state: RootStateOrAny) => state.app.snackbarData
  );

  const closeClickHandler: () => void = () =>
    dispatch({
      type: TRIGGER_SNACKBAR,
      payload: { open: false, data: null }
    });
  return (
    <Collapse in={snackbarOpen} data-testid="sp-snackbar">
      {snackbarData && (
        <SnackbarStyledWrapper
          type={snackbarData.type}
          icon={false}
          variant="outlined"
          severity={snackbarData.type}
          data-automation-id="snackbar-div-container"
        >
          <SnackbarLeftContainer isTopAligned={snackbarData.topAligned || false}>
            <SnackbarIconContainer isTopAligned={snackbarData.topAligned || false}>
              {snackbarData.leftIcon}
            </SnackbarIconContainer>
            <SnackbarTextContainer data-automation-id="snackbar-div-text">
              {snackbarData.title}
              {snackbarData.message}
            </SnackbarTextContainer>
          </SnackbarLeftContainer>
          <SnackbarCloseIconContainer
            isTopAligned={snackbarData.topAligned || false}
            isCenterAligned={snackbarData.centerAlignedClear || false}
            onClick={closeClickHandler}
            data-testid="sp-snackbar-clear-icon"
          >
            {snackbarData.closeIcon || <ClearIcon color={themeColors.icon.light.default} />}
          </SnackbarCloseIconContainer>
        </SnackbarStyledWrapper>
      )}
    </Collapse>
  );
};

export default Snackbar;
