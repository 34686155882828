import StoreBase from 'api/base/storeBase';
import { Funding, FundingOData } from 'api/interfaces/fundings/fundings.interface';
import { AxiosInstance } from 'axios';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { IAppState } from 'store/epics/app';

const _PATH: string = '/ledger/supplier-pay/odata/fundings';

export default class FundingApi extends StoreBase {
  public getFundingsByProgramId: (programId: string) => Promise<Funding[]> = async (
    programId: string
  ) => {
    const { data } = await this.axiosInstance.get<FundingOData>(
      `${_PATH}?$filter=linkedProgram/programId eq '${programId}'`
    );
    return data.value;
  };

  public getMatchedFundingByProgramIDPoll: (
    requestId: string,
    programId?: string
  ) => Promise<Funding> = async (requestId, programId) => {
    const fundingProgramId =
      (this.app as IAppState).programs.find((program) =>
        program.baseType.includes(ProgramType.SP_FUNDING)
      )?.id || '';
    let count: number = 0;
    return new Promise((resolve, reject) => {
      (async function check(matchedFundingFn: Function, axiosInstance: AxiosInstance) {
        const response = await axiosInstance.get<FundingOData>(
          `${_PATH}?$top=5000&$filter=linkedProgram/programId eq '${programId || fundingProgramId}'`
        );
        const { data, status } = response;
        if (count === 10) reject('10 requests made with no successful response.');
        if (status === 404 && count <= 10) {
          count++;
          console.info('POLLING: 404 - repeat request');
          setTimeout(() => {
            check(matchedFundingFn, axiosInstance);
          }, 10000);
          return;
        }

        const matchedIndex: number = matchedFundingFn(data.value, requestId);
        if (status === 200) {
          if (matchedIndex < 0 && count < 10) {
            count++;
            console.info(`POLLING: Early payment request: ${status}`);
            setTimeout(() => {
              check(matchedFundingFn, axiosInstance);
            }, 10000);
          } else {
            console.info(`POLLING COMPLETE: Early payment request: ${status}`);
            resolve(data.value[matchedIndex]);
          }
        }
      })(this.matchedFunding, this.axiosInstance);
    });
  };

  public matchedFunding: (data: Funding[], requestID: string) => number = (data, requestID) =>
    data.map((d) => d.id).indexOf(requestID);
}
