import { x500 } from 'utils/interfaces/x500.interface';

export const x500Formatter: (val: string) => x500 = (val) => {
  if (!val) return {};
  const mapped = val.split(',').map((a) => {
    const split = a.split('=');
    return [[split[0].trim()], split[1]];
  });
  return Object.fromEntries(mapped);
};
