import { FC } from 'react';
import { DividerLine } from './styled';

export interface DividerProps {
  color?: string;
  margin?: string;
  fullWidth?: boolean;
}

const Divider: FC<DividerProps> = ({ color, margin, fullWidth }) => (
  <DividerLine data-automation-id="divider-div" color={color} margin={margin} fullWidth={fullWidth} />
);

export default Divider;
