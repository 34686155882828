import { FC } from 'react';
import CustomLink from 'components/common/links/CustomLink';
import {
  CompareCardContainer,
  CompareCardTitle,
  CompareCardInfo,
  CompareCardSubtitle,
  CompareCardDocumentNumber,
  CompareCardValue
} from './styled';
import LinkIcon from 'assets/icons/LinkIcon';
import { typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';
import { fontWeights } from 'assets/theme/typography';

export interface CompareCardProps {
  title?: string;
  info?: string;
  documentNumber?: string;
  documentPath?: string;
  subtitle?: string;
  value?: string;
}

const CompareCard: FC<CompareCardProps> = ({
  title,
  info,
  documentNumber,
  documentPath,
  subtitle,
  value
}) => {
  return (
    <CompareCardContainer data-automation-id="ledger-matching-h4-compare-card-container">
      {title && (
        <CompareCardTitle data-automation-id="ledger-matching-h4-compare-card-title">
          {title}
        </CompareCardTitle>
      )}
      {info && (
        <CompareCardInfo data-automation-id="ledger-matching-div-compare-card-info">
          {info}
        </CompareCardInfo>
      )}
      {documentNumber && (
        <CompareCardDocumentNumber data-automation-id="ledger-matching-div-compare-card-document-number">
          <LinkIcon />
          <CustomLink
            title={documentNumber || ''}
            link={documentPath || ''}
            typography={typography.fontHindSemiBoldSmall}
            textDecoration="underline"
            textColor={themeColors.text.primary}
            fontWeight={fontWeights.semiBold}
            target
          />
        </CompareCardDocumentNumber>
      )}
      {subtitle && (
        <CompareCardSubtitle data-automation-id="ledger-matching-div-compare-card-subtitle">
          {subtitle}
        </CompareCardSubtitle>
      )}
      {value && (
        <CompareCardValue data-automation-id="ledger-matching-div-compare-card-subtitle-value">
          {value}
        </CompareCardValue>
      )}
    </CompareCardContainer>
  );
};

export default CompareCard;
