import { IUser } from 'api/interfaces/users/user.interface';
import UserManagementApi from 'api/user-management/userManagement.api';
import { AxiosResponse } from 'axios';
import { UserActionStatusesEnum } from 'lib/enums/user/userStatuses.enum';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  ADD_NEW_USER,
  ADD_NEW_USER_FAILURE,
  ADD_NEW_USER_SUCCESS,
  GET_LOGGED_IN_USER,
  GET_LOGGED_IN_USER_FAILURE,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ACTION,
  UPDATE_USER_ACTION_FAILURE,
  UPDATE_USER_ACTION_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchUsers: () => Promise<IUser[]> = async () => {
  const userManagementApi = new UserManagementApi(store);
  const res: AxiosResponse<IUser[]> = await userManagementApi.getUsers();
  return res.data;
};

export const fetchUser: (action: any) => Promise<IUser | null> = async (action) => {
  const userManagementApi = new UserManagementApi(store);
  const id: string = action.payload;
  if (id) {
    const res: AxiosResponse<IUser> = await userManagementApi.getUserById(id);
    return res.data;
  } else return null;
};

export const updateUserData: (action: any) => Promise<void> = async (action) => {
  const userManagementApi = new UserManagementApi(store);
  const { data, id, noCall } = action.payload;
  const res: AxiosResponse<string> = await userManagementApi.putUserData(data, id);
  if (res.status === 204 && !noCall) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
};

export const addNewUser: (action: any) => Promise<void> = async (action) => {
  const userManagementApi = new UserManagementApi(store);
  const res: AxiosResponse<IUser> = await userManagementApi.postNewUserData(action.payload);
  if (res.status === 200) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
};

export const updateUserActionByType: (action: any) => Promise<void> = async (action) => {
  const userManagementApi = new UserManagementApi(store);
  const { type, userId } = action.payload;
  const formattedType = (type as string).toUpperCase();

  if (formattedType === UserActionStatusesEnum.ACTIVATE) {
    const res: AxiosResponse<string> = await userManagementApi.putUserActivate(userId);
    if (res.status === 204) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
  }
  if (formattedType === UserActionStatusesEnum.RESET) {
    const res: AxiosResponse<string> = await userManagementApi.putUserReset(userId);
    if (res.status === 204) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
  }
  if (formattedType === UserActionStatusesEnum.SUSPEND) {
    const res: AxiosResponse<string> = await userManagementApi.putUserSuspend(userId);
    if (res.status === 204) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
  }
  if (formattedType === UserActionStatusesEnum.DEACTIVATE) {
    const res: AxiosResponse<string> = await userManagementApi.putUserDeactivate(userId);
    if (res.status === 204) setTimeout(() => store.dispatch({ type: GET_USERS }), 2000);
  }
};
//===================================================
//                      EPICS
//===================================================

const getUsers$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_USERS),
    switchMap(() =>
      from(fetchUsers()).pipe(
        map((payload) => ({ type: GET_USERS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_USERS_FAILURE, payload: error.message }))
      )
    )
  );

const getUser$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_USER),
    switchMap((action) =>
      from(fetchUser(action)).pipe(
        map((payload) => ({ type: GET_USER_SUCCESS, payload })),
        catchError((error) => of({ type: GET_USER_FAILURE, payload: error.message }))
      )
    )
  );

const getLoggedInUser$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_LOGGED_IN_USER),
    switchMap((action) =>
      from(fetchUser(action)).pipe(
        map((payload) => ({ type: GET_LOGGED_IN_USER_SUCCESS, payload })),
        catchError((error) => of({ type: GET_LOGGED_IN_USER_FAILURE, payload: error.message }))
      )
    )
  );

const updateUserDataEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_USER),
    switchMap((action) =>
      from(updateUserData(action)).pipe(
        map((payload) => ({ type: UPDATE_USER_SUCCESS, payload })),
        catchError((error) => of({ type: UPDATE_USER_FAILURE, payload: error.message }))
      )
    )
  );

const addNewUserEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_NEW_USER),
    switchMap((action) =>
      from(addNewUser(action)).pipe(
        map((payload) => ({ type: ADD_NEW_USER_SUCCESS, payload })),
        catchError((error) =>
          of({ type: ADD_NEW_USER_FAILURE, payload: error.response.data.detail })
        )
      )
    )
  );

const updateUserActionEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_USER_ACTION),
    switchMap((action) =>
      from(updateUserActionByType(action)).pipe(
        map((payload) => ({ type: UPDATE_USER_ACTION_SUCCESS, payload })),
        catchError((error) => of({ type: UPDATE_USER_ACTION_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(
  getUsers$,
  getUser$,
  updateUserDataEpic$,
  updateUserActionEpic$,
  addNewUserEpic$,
  getLoggedInUser$
);
