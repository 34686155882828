import EntityApi from 'api/entity/entity.api';
import { Entities, EntityCurrency, ICountries } from 'api/interfaces/entity/entity.interface';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import {
  LedgerLinkedFunding,
  ODataLedgerInvoices
} from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayableItem } from 'api/interfaces/ledger/ledgerPayableItems.interface';
import { LedgerPayment, ODataLedgerPayments } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import {
  BaseRatesProgramRule,
  IBaseRateConfig,
  IProgramRule,
  ProgramConfig
} from 'api/interfaces/program/program.interface';
import FundingApi from 'api/ledger/funding.api';
import InvoiceApi from 'api/ledger/invoices.api';
import PaymentApi from 'api/ledger/payment.api';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';
import SupplierPayApi from 'api/supplier-pay/supplierPay.api';
import Axios, { AxiosResponse } from 'axios';
import { getConnectionStatus } from 'hipster-sdk';
import { _MODULE_ID } from 'lib/constants/contants';
import { _matchingDiscrepantStatus } from 'lib/constants/matching';
import { BaseRatesEnum } from 'lib/enums/base-rates/baseRates.enum';
import { FundingStatuses } from 'lib/enums/fundingStatuses.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { acc } from 'lib/helpers/accumulator';
import { store } from 'store';
import { AppReducerStateProps } from 'store/reducers/app';
import { IBaseRates } from 'utils/interfaces/base-rates/baseRates.interface';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

const _acc: (acc: number, num: number) => number = (acc, num) => acc + num;

export const appLoadByCurrency: (
  selectedProgramByCurrency: IGroupedProgram,
  programs: ProgramConfig[],
  appState: AppReducerStateProps,
  accessToken: string
) => Promise<any> = async (selectedProgramByCurrency, programs, appState, accessToken) => {
  const entityApi = new EntityApi(store);
  const fundingApi = new FundingApi(store);
  const invoiceApi = new InvoiceApi(store);
  const paymentApi = new PaymentApi(store);
  const purchaseOrderApi = new PurchaseOrderApi(store);
  const programIds: string[] = selectedProgramByCurrency.programDetails.map(
    (program) => program.id
  );
  const allPrograms: string[] = programs.map((p) => p.id);

  let g2fConnectionResponse;
  let g2fRes;

  const selectedCurrency: string = selectedProgramByCurrency.currency;

  const groupedPrograms: IGroupedProgram[] = appState.groupedPrograms.map((p) => {
    p.active = p.currency === selectedProgramByCurrency.currency ? true : false;
    return p;
  });

  if (groupedPrograms.length === 0) selectedProgramByCurrency.active = true;

  const fundingProgramIndex = selectedProgramByCurrency.programDetails.findIndex(
    (program) =>
      program.baseType.includes(ProgramType.SP_FUNDING) ||
      program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
  );

  const fundingProgramDetails: ProgramConfig | undefined = programs.find(
    (p) => p.id === selectedProgramByCurrency.programDetails[fundingProgramIndex]?.id
  );

  const oaProgramIndex = selectedProgramByCurrency.programDetails.findIndex((program) =>
    program.baseType.includes(ProgramType.OPEN_ACCOUNT)
  );

  const oaProgramDetails = programs.find(
    (p) => p.id === selectedProgramByCurrency.programDetails[oaProgramIndex]?.id
  );

  //#endregion

  // FROM FUNDING PROGRAM ONLY NOT EXT_FUNDING

  //#region <FUNDING RELATED CALLS>

  const baseRates: IBaseRates | null =
    fundingProgramIndex > -1 && fundingProgramDetails
      ? await getBaseRates(selectedCurrency, fundingProgramDetails.rules, entityApi)
      : null;

  //#endregion

  //if dual - NEED DETAIL CALL
  const currenciesRuleConfig: string[] | undefined = oaProgramDetails?.rules.find(
    (r) => r.type === 'CURRENCIES'
  )?.value.acceptedCurrencies;

  const ownerResponse = await entityApi.getOwner(accessToken);

  const fetchCountriesResponse: ICountries = await entityApi.getCountries();
  if (!fetchCountriesResponse) console.warn('APP INIT - Can not get countries.');

  const fetchCurrenciesResponse: AxiosResponse<EntityCurrency[]> = await entityApi.getCurrencies();
  if (!fetchCurrenciesResponse) console.warn('APP INIT - Can not get currencies.');

  try {
    g2fRes = await Axios.get(
      `${window.API_PATH_INTERNAL}/connector?programid=${selectedProgramByCurrency.programDetails[0].id}&moduleid=${_MODULE_ID}&name=${ownerResponse.data.contact.registeredName}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
  } catch (error) {}

  if (g2fRes?.data.active) {
    g2fConnectionResponse = await getConnectionStatus(
      g2fRes.data.customerUid,
      g2fRes.data.auth.accessToken,
      `https://g2fapi-staging.finecta.dev`
    );
  }

  const fetchEligibleInvoicesByProgramsResponse: Invoice[] = [];

  if (!fetchEligibleInvoicesByProgramsResponse)
    console.warn('APP INIT - Can not get Eligible Invoices By Program.');

  const fetchEntitiesResponse: AxiosResponse<Entities> = await entityApi.getEntities();
  if (!fetchEntitiesResponse) console.warn('APP INIT - Can not get Entities.');

  const fetchEligibleAcceptedInvoicesResponse: AxiosResponse<ODataLedgerInvoices> =
    selectedProgramByCurrency.programDetails.find((p) =>
      p.baseType.includes(ProgramType.OPEN_ACCOUNT)
    )
      ? await invoiceApi.fetchOpenInvoices(programIds)
      : await invoiceApi.fetchEligibleAcceptedInvoices(programIds);

  const fetchApprovedInvoicesResponse: AxiosResponse<ODataLedgerInvoices> =
    await invoiceApi.getApprovedInvoices(allPrograms, selectedCurrency);

  if (!fetchEligibleAcceptedInvoicesResponse)
    console.warn('APP INIT - Can not get Eligible Accepted Invoices.');

  // PAYMENTS - NOT ABLE TO SEE linkedPrograms field

  const fetchPaymentLedgerByTypeTradeResponse: LedgerPayment[] = (
    await Promise.all(
      programIds.map(async (id) => await paymentApi.getPaymentsLedgerOfTypeTrade(id))
    )
  ).flatMap((r) => r.data.value);
  // await paymentApi.getPaymentsLedgerOfTypeTrade(selectedProgram.id);

  // const fetchPaymentsLedgerResponse: LedgerPayment[] = (
  //   await Promise.all(programIds.map(async (id) => await paymentApi.getPaymentsLedger(id)))
  // ).flatMap((r) => r.data.value);

  const fetchPaymentsLedgerResponse: AxiosResponse<ODataLedgerPayments> =
    await paymentApi.getPaymentsLedger(allPrograms);

  const fetchPayableItemsLedgerOfTypeTradeResponse: LedgerPayableItem[] = (
    await Promise.all(
      programIds.map(async (id) => await paymentApi.getPayableItemsLedgerOfTypeTrade(id))
    )
  ).flatMap((r) => r.data.value);

  const fetchPayableItemsLedgerOfTypeMaturingResponse: LedgerPayableItem[] = (
    await Promise.all(
      programIds.map(async (id) => await paymentApi.getPayableItemsLedgerOfTypeMaturing(id))
    )
  ).flatMap((r) => r.data.value);

  // ************************************************************

  const earlyPaymentsReceived: Funding[] = [];
  const earlyPaymentsReceivedIds: string[] = earlyPaymentsReceived.map((e) => e.id);
  const earlyPaymentLinkedFundingsResponse: AxiosResponse<ODataLedgerInvoices> | null =
    earlyPaymentsReceivedIds.length
      ? await invoiceApi.getInvoiceLedgerLinkedFundingsByFundingIds(
          programIds,
          earlyPaymentsReceivedIds
        )
      : null;

  const linkedFundings: LedgerLinkedFunding[] = earlyPaymentLinkedFundingsResponse
    ? (
        earlyPaymentLinkedFundingsResponse?.data.value
          .flatMap((funding) => funding.linkedFundings)
          .filter((a) => a !== undefined) as LedgerLinkedFunding[]
      ).filter((funding) => earlyPaymentsReceivedIds.includes(funding.fundingId))
    : [];

  const fetchInvoiceLedger: AxiosResponse<ODataLedgerInvoices> = await invoiceApi.getInvoiceLedger(
    allPrograms
  );

  const fetchPurchaseOrderLedger: ILedgerPurchaseOrder[] =
    oaProgramIndex > -1
      ? (await purchaseOrderApi.getPurchaseOrderLedger(programIds))?.data?.value
      : [];

  return {
    owner: ownerResponse.data,
    accessToken,
    activeCurrencyCode: selectedCurrency,
    g2fAccessToken: g2fRes?.data.auth.accessToken || '',
    g2fCustUID: g2fRes?.data.customerUid || '',
    g2fConnection: g2fConnectionResponse,
    baseRates,
    invoices: fetchEligibleInvoicesByProgramsResponse,
    invoiceLedger: fetchInvoiceLedger.data.value,
    purchaseOrderLedger: fetchPurchaseOrderLedger,
    entities: fetchEntitiesResponse.data.content,
    advancedInvoicesTotal: fetchEligibleInvoicesByProgramsResponse.length
      ? fetchEligibleInvoicesByProgramsResponse
          .map((r: any) => r.advancedInvoiceAmount)
          .reduce(acc, 0)
      : 0,
    acceptedEligibleInvoices: selectedProgramByCurrency.openInvoices,

    discrepantInvoicesTotalCount:
      fetchInvoiceLedger.data.value?.filter(
        (inv) =>
          inv.linkedProgram.matchingStatus === _matchingDiscrepantStatus &&
          inv.invoiceCurrency === selectedCurrency
      ).length || 0,
    discrepantInvoicesTotal: fetchInvoiceLedger.data.value
      ?.filter(
        (inv) =>
          inv.linkedProgram.matchingStatus === _matchingDiscrepantStatus &&
          inv.invoiceCurrency === selectedCurrency
      )
      .map((inv) => inv.data.amount || 0)
      .reduce(_acc, 0),

    pendingInvoicesTotal: fetchInvoiceLedger.data.value
      ?.filter(
        (inv) =>
          inv.linkedProgram.matchingStatus === 'PENDING' && inv.invoiceCurrency === selectedCurrency
      )
      .map((inv) => inv.data.amount || 0)
      .reduce(_acc, 0),
    pendingInvoicesTotalCount:
      fetchInvoiceLedger.data.value?.filter(
        (inv) =>
          inv.linkedProgram.matchingStatus === 'PENDING' && inv.invoiceCurrency === selectedCurrency
      ).length || 0,

    acceptedEligibleInvoicesTotal: selectedProgramByCurrency.openInvoicesTotal,
    approvedInvoices: fetchApprovedInvoicesResponse?.data.value
      ? fetchApprovedInvoicesResponse?.data?.value
      : [],
    approvedInvoicesTotal: fetchApprovedInvoicesResponse?.data?.value
      ? fetchApprovedInvoicesResponse.data.value.map((r: any) => r.data.amount).reduce(acc, 0)
      : 0,
    fundings: [],
    earlyPaymentsReceivedValue:
      earlyPaymentsReceived.length > 0
        ? earlyPaymentsReceived
            .flatMap((r) => r.linkedPayments.flatMap((p) => p.paymentItemAmount))
            .reduce(acc, 0)
        : 0,
    earlyPaymentsTenorValue:
      linkedFundings.length > 0 ? linkedFundings.map((f) => f.tenor).reduce(acc, 0) : 0,
    countries: fetchCountriesResponse,
    currencies: fetchCurrenciesResponse.data,
    programCurrencies: currenciesRuleConfig || [],
    payments: fetchPaymentsLedgerResponse?.data.value,
    paymentsTypeTrade: fetchPaymentLedgerByTypeTradeResponse,
    payableItemsTypeTrade: fetchPayableItemsLedgerOfTypeTradeResponse,
    payableItemsTypeMaturing: fetchPayableItemsLedgerOfTypeMaturingResponse,
    g2fFeatureHidden: !Boolean(g2fRes?.data.active),
    programs: selectedProgramByCurrency.programDetails,
    programIds,
    programType: selectedProgramByCurrency.type,
    programsWithDetails: programs.filter((program) =>
      selectedProgramByCurrency.programDetails.findIndex((d) => d.id === program.id)
    ),
    groupedPrograms,
    selectedProgramByCurrency
  };
};

const getBaseRates: (
  currency: string,
  rules: IProgramRule<BaseRatesProgramRule>[],
  api: EntityApi
) => Promise<IBaseRates | null> = async (currency, rules, api) => {
  const matchedRuleIndex: number = rules.findIndex((r) => r.type === 'BASE_RATES');
  if (matchedRuleIndex === -1) return null;
  const matchedAgainstCurrency: IBaseRateConfig = (rules[matchedRuleIndex].value as any)
    .perCurrency[currency];
  const { baseRateType, referenceId, value } = matchedAgainstCurrency;

  if (matchedAgainstCurrency.baseRateType === BaseRatesEnum.FIXED)
    return { type: baseRateType, rates: value } as IBaseRates;

  const { data } = await api.getReferenceBaseRatesByReferenceId(referenceId);
  return {
    type: matchedAgainstCurrency.baseRateType,
    rates: data
  } as IBaseRates;
};

const fetchEligibleInvoicesByPrograms: (
  programID: string,
  currency: string
) => Promise<Invoice[]> = async (programID, currency) => {
  const supplierPayApi = new SupplierPayApi(store);
  const { data } = await supplierPayApi.getEligibleInvoicesByPrograms(programID, currency);
  return data.content;
};
