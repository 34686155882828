export class BarChartData {
  constructor(public labels: string[], public datasets: BarChartDataSet[]) {}
}

export class BarChartDataSet {
  constructor(
    public label: string,
    public data: any[],
    public backgroundColor?: string[],
    public maxBarThickness?: number,
    public customProperty?: any,
    public borderColor?: string[],
    public borderWidth?: number,
    public hoverOffset?: number,
    public hoverBackgroundColor?: string[]
  ) {}
}
