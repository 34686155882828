import { FC, useEffect, useState } from 'react';
import { StyledBreadcrumbs, BreadcrumbsContainer } from './styled';
import { useLocation, Link } from 'react-router-dom';
import { RightArrowIcon } from 'assets/icons/ArrowIcons';
import { themeColors } from 'assets/theme/style';
import routes from 'routes/index.routes';
import { useSelector, RootStateOrAny } from 'react-redux';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { RouteBreadcrumb } from 'utils/interfaces/breadcrumbs/breadcrumb.interface';

interface BreadcrumbProps {
  name: string;
  path: string;
  component: JSX.Element;
  breadcrumbs?: RouteBreadcrumb[];
  breadcrumbShow?: boolean;
}

const Breadcrumb: FC = () => {
  const location = useLocation();
  const [matchedRoute, setMatchedRoute] = useState<BreadcrumbProps | undefined>(undefined);
  const {
    breadcrumbValues
  }: {
    breadcrumbValues: { [key: string]: string } | null;
  } = useSelector((state: RootStateOrAny) => state.breadcrumb);

  useEffect(() => {
    const matched: BreadcrumbProps | undefined = routes.find((item) => {
      if (item.pathRegex) {
        return location.pathname.match(item.pathRegex);
      } else {
        return item.path === location.pathname;
      }
    });

    setMatchedRoute(matched);
  }, [location.pathname]);

  const setCrumb: (crumb: RouteBreadcrumb) => string = (crumb) => {
    if (!breadcrumbValues) return '';
    return breadcrumbValues[crumb.name];
  };

  const breadcrumbs: () => JSX.Element | undefined = () => {
    if (!matchedRoute?.breadcrumbs) return;
    const crumbs: JSX.Element[] = [];
    if (location.pathname !== '/')
      crumbs.push(
        <Link to="/" key="home-key" data-automation-id="breadcrumb-a-home">
          Home
        </Link>
      );
    crumbs.push(
      ...Object.values(matchedRoute.breadcrumbs).map((crumb: RouteBreadcrumb, index) => {
        return (
          <div key={index}>
            {crumb.path !== '' && (
              <Link
                to={crumb.path}
                data-automation-id={`breadcrumbs-a-${toKebabCase(crumb.name || '')}`}
              >
                {crumb.name}
              </Link>
            )}
            {crumb.noPath && crumb.name}
            {crumb.path === '' && !crumb.noPath && crumb.dynamic && (
              <p
                color="text.primary"
                data-automation-id={`breadcrumbs-p-${toKebabCase(setCrumb(crumb) || '')}`}
              >
                {setCrumb(crumb)}
              </p>
            )}
            {crumb.path === '' && !crumb.noPath && matchedRoute.breadcrumbShow && crumb.name}
            {crumb.path === '' && !crumb.noPath && !crumb.dynamic && !matchedRoute.breadcrumbShow && (
              <p
                color="text.primary"
                data-automation-id={`breadcrumbs-p-${toKebabCase(crumb.name || '')}`}
              >
                {crumb.name}
              </p>
            )}
          </div>
        );
      })
    );

    return <StyledBreadcrumbs aria-label="breadcrumb">{crumbs}</StyledBreadcrumbs>;
  };

  return matchedRoute?.breadcrumbs && Object.values(matchedRoute.breadcrumbs)?.length > 0 ? (
    <BreadcrumbsContainer data-automation-id="breadcrumb-div-navigation">
      {breadcrumbs()}
      {/* {matchedRoute?.breadcrumbShow && <p color="text.primary">{matchedRoute?.name}</p>} */}
    </BreadcrumbsContainer>
  ) : (
    <></>
  );
};

export default Breadcrumb;
