/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Program } from 'api/interfaces/program/program.interface';
import AvatarIcon from 'assets/icons/AvatarIcon/AvatarIcon';
import { themeColors } from 'assets/theme/style';
import Divider from 'components/common/divider';
import { removeCookie } from 'lib/helpers/cookieHelper';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { Dispatch, FC, MouseEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { UPDATE_ACTIVE_NAV_BUTTON } from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { NavigationSubMenu } from 'utils/interfaces/navigation/navigationSubmenu.interface';
import {
  LogoutText,
  MenuItemLink,
  NavigationProfileContainer,
  ProfileContainer,
  ProfileInfoContainer,
  ProfileNameSurname,
  ProfileTitle,
  StyledMenu
} from './styled';
import { useAuth } from 'react-oidc-context';

interface MenuItemProps {
  menuItems: NavigationSubMenu;
}

const NavigationProfileLink: FC<MenuItemProps> = ({ menuItems }) => {
  const auth = useAuth();
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [active, setActive] = useState<boolean>(false);
  const {
    activeNavButton,
    username,
    tenant
  }: {
    programs: Program[];
    activeNavButton: NavigationItem;
    username: string | null;
    tenant: string | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    isActive();
  }, [activeNavButton]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isActive: () => void = () => {
    if (!activeNavButton) return setActive(false);
    if (
      menuItems.submenu &&
      Object.values(menuItems.submenu).some(
        (item: NavigationItem) =>
          item.path === (activeNavButton.text === item.text && window.location.pathname)
      )
    ) {
      return setActive(true);
    }
    if (!activeNavButton.path) return setActive(false);
    return setActive(
      menuItems.submenu
        ? Object.values(menuItems.submenu).some(
            (item: NavigationItem) => item.path === window.location.pathname
          )
        : false
    );
  };

  const menuLinkIsDisabled: (item: NavigationItem) => boolean = (item) => {
    return !(!!item.externalPath || !!item.path);
  };

  const clickHandler: (item: NavigationItem) => void = (item) => {
    if (!!item.path) {
      dispatch({ type: UPDATE_ACTIVE_NAV_BUTTON, payload: item });
    } else if (!!item.externalPath) {
      window.open(item.externalPath, '_blank', 'noopener,noreferrer');
    }
    setAnchorEl(null);
  };

  const handleClick: (event: MouseEvent<HTMLElement>) => void = (event) =>
    setAnchorEl(event.currentTarget);

  const handleClose: () => void = () => setAnchorEl(null);

  const logout: () => Promise<void> = async () => {
    auth.signoutRedirect();
    console.log('SIGNING OUT');
    removeCookie('supplier-platform');
  };

  return (
    <NavigationProfileContainer active={active} data-testid="sp-navigation-profile-link-container">
      <Button
        id="NavigationDropDownButton"
        aria-controls={open ? 'NavigationDropDownMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon style={{ color: themeColors.text.light.body }} />}
        disableRipple
        style={{ color: themeColors.text.light.body }}
        data-automation-id="navbar-button-profile-menu"
        data-testid="sp-navbar-button-profile-menu"
      >
        {menuItems.title}
      </Button>

      <StyledMenu
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="NavigationDropDownMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'NavigationDropDownButton'
        }}
        data-automation-id="navigation-dropDown-menu-div-presentation"
        data-testid="sp-navigation-dropDown-menu"
      >
        <ProfileContainer>
          <AvatarIcon />
          <ProfileInfoContainer>
            <ProfileNameSurname data-automation-id="profile-p-profile-name">
              {username || ''}
            </ProfileNameSurname>
            <ProfileTitle data-automation-id="profile-p-profile-title">{tenant || ''}</ProfileTitle>
          </ProfileInfoContainer>
        </ProfileContainer>
        <Divider margin="8px 0" />
        {menuItems.submenu &&
          Object.values(menuItems.submenu).map((item: NavigationItem, key) => (
            <MenuItemLink
              to={item.externalPath ? '' : item.path === '' ? '#' : `${item.path}`}
              onClick={() => clickHandler(item)}
              key={key}
              disabled={menuLinkIsDisabled(item)}
              data-automation-id={`profile-button-a-${toKebabCase(item.text || '')}`}
              data-testid="sp-navigation-link"
            >
              <MenuItem
                component="p"
                disableRipple
                disabled={menuLinkIsDisabled(item)}
                data-automation-id={`profile-button-li-${toKebabCase(item.text || '')}`}
              >
                {item.text}
                {item.icon}
              </MenuItem>
            </MenuItemLink>
          ))}
        <LogoutText onClick={logout} data-testid="sp-logout">
          Log out
        </LogoutText>
      </StyledMenu>
    </NavigationProfileContainer>
  );
};

export default NavigationProfileLink;
