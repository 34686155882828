import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import CustomLink from 'components/common/links/CustomLink';
import { footerNavItems } from 'lib/data/footerNavItems';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { FC } from 'react';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import {
  CopyrightText,
  FooterContainer,
  FooterCopyrightContainer,
  FooterNavItem,
  FooterNavItemContainer,
  FooterSeparator
} from './styled';

const Footer: FC = () => {
  return (
    <FooterContainer>
      <FooterNavItemContainer>
        {footerNavItems.map(({ text, path }: NavigationItem, key: number) => (
          <FooterNavItem
            data-automation-id={`footer-div-link-container-${toKebabCase(text || '')}`}
            key={`${key}-${text}`}
          >
            <CustomLink
              title={text || ''}
              link={path || ''}
              textDecoration="none"
              textColor={themeColors.white}
              fontWeight={fontWeights.semiBold}
              fontSize={fontSizes.regular}
            />
            {key < footerNavItems.length - 1 && <FooterSeparator></FooterSeparator>}
          </FooterNavItem>
        ))}
      </FooterNavItemContainer>

      <FooterCopyrightContainer>
        <CopyrightText>© 2024 MPN Technologies Limited</CopyrightText>
      </FooterCopyrightContainer>
    </FooterContainer>
  );
};

export default Footer;
