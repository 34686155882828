import MPNLogo from 'assets/icons/MPNLogo';
import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import NavigationLinkButton from 'components/common/buttons/NavigationLinkButton';
import NavigationProfileLink from 'components/common/links/NavigationProfileLink';
import { navigationProfileItems } from 'lib/data/navigationProfileItems';
import { FC, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavigationSubMenu } from 'utils/interfaces/navigation/navigationSubmenu.interface';
import { LogoContainer, NavbarStyled, NavigationLeft, NavigationRight } from '../styled';
import {
  NavbarLogoutText,
  NavigationButtonContentContainer,
  NavigationButtonText,
  NavigationLinkButtonWrapper,
  NavigationPlatformContainer,
  NavigationPlatformText,
  SelectStyled
} from './styled';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import DocumentUploadDialog from 'components/dialogs/DocumentUploadDialog';
import { SET_TENANT } from 'store/actions';
import { getCookie, removeCookie, setCookie } from 'lib/helpers/cookieHelper';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SecondaryButton } from 'components/common/buttons';
import UploadIcon from 'assets/icons/UploadIcon';
import { useAuth } from 'react-oidc-context';

const DesktopNavbar: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
  const {
    onboardingComplete,
    g2fFeatureHidden,
    tenants,
    isUserAdmin
  }: {
    onboardingComplete: boolean | null;
    g2fFeatureHidden: boolean;
    tenants: any[];
    isUserAdmin: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const selectedPlatformName: string = 'supplier-platform';
  const logout: () => Promise<void> = async () => {
    removeCookie(selectedPlatformName);
    auth.signoutRedirect();
  };

  const filteredNavItems: () => NavigationSubMenu = () => {
    if (!g2fFeatureHidden) return navigationProfileItems;
    const clone = { ...navigationProfileItems };

    if (!isUserAdmin) clone.submenu = clone?.submenu?.filter((m) => m.text !== 'User management');

    clone.submenu = clone?.submenu?.filter((m) => m.text !== 'Connections');
    return clone;
  };

  const updatePlatformChangeHandler: (event: SelectChangeEvent<unknown>) => void = (event) => {
    const value: any = event.target.value;
    dispatch({ type: SET_TENANT, payload: value });
    setCookie(selectedPlatformName, value, 365);
    window.location.href = `${window.location.origin}/app/${value}`;
  };

  return (
    <NavbarStyled data-automation-id="navbar-div-container" data-testid="sp-desktop-navbar">
      <NavigationLeft>
        <LogoContainer
          onClick={() => navigate('/')}
          data-automation-id="navbar-div-logo"
          data-testid="sp-navbar-logo"
        >
          <MPNLogo color={themeColors.text.primary} />
        </LogoContainer>

        {getCookie(selectedPlatformName) && onboardingComplete && tenants && tenants.length > 1 && (
          <NavigationPlatformContainer>
            <NavigationPlatformText>Company \</NavigationPlatformText>
            <SelectStyled
              id="g2f-select-platform"
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  style={{ color: themeColors.text.light.body, fontSize: fontSizes.large }}
                />
              )}
              label=""
              value={getCookie(selectedPlatformName)}
              onChange={(event: SelectChangeEvent<unknown>) => updatePlatformChangeHandler(event)}
            >
              {tenants.map((item, i) => (
                <MenuItem key={i} value={item.tenantId} data-automation-id={`select-li-item-${i}`}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectStyled>
          </NavigationPlatformContainer>
        )}
      </NavigationLeft>

      <NavigationRight>
        {/* <NavigationLinkButton
          text=""
          path="https://support.supplier.marcopolonetwork.com/"
          externalPage
          icon={<BellIcon color={themeColors.text.light.body} />}
          fontSize={`${fontSizes.small}`}
          testingTag="navbar-button-help-and-support"
          style={{
            color: themeColors.text.primary,
            backgroundColor: 'transparent'
          }}
        /> */}

        {onboardingComplete && (
          <SecondaryButton
            backgroundColor={themeColors.bg.light.surface}
            borderColor={themeColors.border.input}
            clickHandler={() => setUploadDialogOpen(!uploadDialogOpen)}
            width="117px"
          >
            <NavigationButtonContentContainer>
              <NavigationButtonText>Upload</NavigationButtonText>
              <UploadIcon color={themeColors.icon.primary} />
            </NavigationButtonContentContainer>
          </SecondaryButton>
        )}
        <NavigationLinkButtonWrapper>
          <NavigationLinkButton
            text="Help & support"
            path="https://supplier.support.kanexa.net"
            externalPage
            testingTag="navbar-button-help-and-support"
            fontWeight={fontWeights.regular}
            fontSize={fontSizes.regular}
          />
          {onboardingComplete && <NavigationProfileLink menuItems={filteredNavItems()} />}
          {!onboardingComplete && (
            <NavbarLogoutText onClick={logout} data-testid="sp-logout">
              Log out
            </NavbarLogoutText>
          )}
        </NavigationLinkButtonWrapper>
      </NavigationRight>
      {uploadDialogOpen && (
        <FullViewDialog
          open={uploadDialogOpen}
          clickHandler={() => setUploadDialogOpen(!uploadDialogOpen)}
          dialogContent={<DocumentUploadDialog />}
          data-testid="sp-file-upload-dialog"
        />
      )}
    </NavbarStyled>
  );
};

export default DesktopNavbar;
