import { ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ODataLedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ODataLedgerPurchaseOrders } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { ODataLogistics } from 'api/interfaces/logistics/logistics.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import LogisticsApi from 'api/ledger/logistics.api';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';
import { AxiosResponse } from 'axios';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { Epic, combineEpics, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY,
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE,
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS,
  FETCH_INVOICE_LEDGER,
  FETCH_INVOICE_LEDGER_FAILURE,
  FETCH_INVOICE_LEDGER_SUCCESS,
  FETCH_LOGISTICS_BY_DOCUMENT_ID,
  FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE,
  FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS,
  FETCH_LOGISTICS_BY_PROGRAM_IDS,
  FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE,
  FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS,
  FETCH_PURCHASE_ORDER_LEDGER,
  FETCH_PURCHASE_ORDER_LEDGER_FAILURE,
  FETCH_PURCHASE_ORDER_LEDGER_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchInvoiceByEligibilityLedger: (
  action: any
) => Promise<ODataLedgerInvoices> = async (action) => {
  const invoiceApi = new InvoiceApi(store);
  const { eligibilityStatuses, programIds, paymentDueDate } = action.payload;
  if (!eligibilityStatuses || !eligibilityStatuses.length)
    throw Error(
      'Can not get invoices as no eligibility statuses were provided for getting invoices by eligibility.'
    );

  if (!programIds || programIds.length === 0)
    throw Error('No program ID provided for getting invoices by eligibility.');

  if (!paymentDueDate)
    throw Error('No payment due date provided for getting invoices by eligibility.');

  const getInvoicesByEligibilityStatusResponse: AxiosResponse<ODataLedgerInvoices> =
    await invoiceApi.getInvoicesByEligibilityStatus(
      eligibilityStatuses,
      programIds,
      paymentDueDate
    );
  return getInvoicesByEligibilityStatusResponse.data;
};

export const fetchInvoiceLedger: (action: any) => Promise<ODataLedgerInvoices> = async (action) => {
  const invoiceApi = new InvoiceApi(store);
  const getInvoiceLedgerResponse = await invoiceApi.getInvoiceLedger(action.payload);
  return getInvoiceLedgerResponse.data;
};

export const fetchPurchaseOrderLedger: (action: any) => Promise<ODataLedgerPurchaseOrders> = async (
  action
) => {
  const purchaseOrderLedger = new PurchaseOrderApi(store);
  const getPurchaseOrderLedgerResponse = await purchaseOrderLedger.getPurchaseOrderLedger(
    action.payload
  );
  return getPurchaseOrderLedgerResponse.data;
};

export const fetchLogisticsLedgerByDocumentId: (action: any) => Promise<ODataLogistics> = async (
  action
) => {
  const logisticsApi = new LogisticsApi(store);
  const { documentNumber, documentType } = action.payload;
  let logisticsLedgerResponse;

  if (documentType === LedgerTypes.INVOICE)
    logisticsLedgerResponse = await logisticsApi.getLogisticsLedgerByInvoice(documentNumber);
  if (documentType === LedgerTypes.PO)
    logisticsLedgerResponse = await logisticsApi.getLogisticsLedgerByPO(documentNumber);

  if (!logisticsLedgerResponse) throw Error('Ledger Epic - can not get logistics data.');

  return logisticsLedgerResponse.data;
};

export const fetchLogisticsByProgramIds: (action: any) => Promise<ODataLedgerLogistic> = async (
  action
) => {
  const logisticsApi = new LogisticsApi(store);
  const { programIds } = action.payload;

  const logisticsLedgerResponse = await logisticsApi.getLogisticsByProgramIds(programIds);

  return logisticsLedgerResponse?.data;
};

export const fetchFilterLogistics: (action: any) => Promise<ODataLedgerLogistic> = async (
  action
) => {
  const logisticsApi = new LogisticsApi(store);
  const { programIds, rowsPerPage, page, filter, orderBy } = action.payload;

  const logisticsLedgerResponse = await logisticsApi.getFilterLogistics(
    programIds,
    rowsPerPage,
    page,
    filter,
    orderBy
  );

  return logisticsLedgerResponse?.data;
};

//===================================================
//                      EPICS
//===================================================

const getInvoicesByEligibilityStatusEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY),
    switchMap((action) =>
      from(fetchInvoiceByEligibilityLedger(action)).pipe(
        map((payload) => ({ type: FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS, payload })),
        catchError((error) =>
          of({ type: FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE, payload: error.message })
        )
      )
    )
  );

const getInvoiceLedgerEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_INVOICE_LEDGER),
    switchMap((action) =>
      from(fetchInvoiceLedger(action)).pipe(
        map((payload) => ({ type: FETCH_INVOICE_LEDGER_SUCCESS, payload })),
        catchError((error) => of({ type: FETCH_INVOICE_LEDGER_FAILURE, payload: error.message }))
      )
    )
  );

const getPurchaseOrderLedgerEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PURCHASE_ORDER_LEDGER),
    switchMap((action) =>
      from(fetchPurchaseOrderLedger(action)).pipe(
        map((payload) => ({ type: FETCH_PURCHASE_ORDER_LEDGER_SUCCESS, payload })),
        catchError((error) =>
          of({ type: FETCH_PURCHASE_ORDER_LEDGER_FAILURE, payload: error.message })
        )
      )
    )
  );

const getLogisticsLedgerByDocumentIdEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_LOGISTICS_BY_DOCUMENT_ID),
    switchMap((action) =>
      from(fetchLogisticsLedgerByDocumentId(action)).pipe(
        map((payload) => ({ type: FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS, payload })),
        catchError((error) =>
          of({ type: FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE, payload: error.message })
        )
      )
    )
  );

const getLogisticsByProgramIdsEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_LOGISTICS_BY_PROGRAM_IDS),
    switchMap((action) =>
      from(fetchLogisticsByProgramIds(action)).pipe(
        map((payload) => ({ type: FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS, payload })),
        catchError((error) =>
          of({ type: FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE, payload: error.message })
        )
      )
    )
  );

export default combineEpics(
  getInvoicesByEligibilityStatusEpic$,
  getInvoiceLedgerEpic$,
  getPurchaseOrderLedgerEpic$,
  getLogisticsLedgerByDocumentIdEpic$,
  getLogisticsByProgramIdsEpic$
);
