import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import DotMenuIcon from 'assets/icons/DotMenuIcon';
import UserManagementActionDialog from 'components/dialogs/UserManagementActionDialog';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import { kebabCase } from 'lodash';
import { FC, MouseEvent, useState } from 'react';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { MenuItemWrapper, NavigationProfileContainer, StyledMenu } from './styled';

interface MenuItemProps {
  menuItems?: NavigationItem[] | undefined;
  userManagementDialog?: boolean;
}

const ActionLink: FC<MenuItemProps> = ({ menuItems, userManagementDialog = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeLinkOpen, setActiveLinkOpen] = useState<boolean[]>([false]);

  const clickHandler: (key: number) => void = (key) => {
    setAnchorEl(null);
    const newActiveLinkOpen = activeLinkOpen;
    newActiveLinkOpen[key] = !newActiveLinkOpen[key];
    setActiveLinkOpen(JSON.parse(JSON.stringify(newActiveLinkOpen)));
  };

  const handleClick: (event: MouseEvent<HTMLElement>) => void = (event) =>
    setAnchorEl(event.currentTarget);

  const handleClose: () => void = () => setAnchorEl(null);

  return (
    <NavigationProfileContainer data-testid="sp-action-link-container">
      <Button
        id="ActionMenuButton"
        aria-controls={open ? 'ActionMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<DotMenuIcon />}
        disableRipple
        data-testid="sp-action-link-button"
      />

      <StyledMenu
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'ActionMenuButton'
        }}
        disableScrollLock
        data-automation-id="action-menu-div-presentation"
      >
        {menuItems &&
          menuItems.map((item: NavigationItem, index: number) => (
            <MenuItemWrapper key={`activemenu-${index}`}>
              <MenuItem
                disableRipple
                onClick={() => clickHandler(index)}
                data-automation-id={`action-menu-li-${kebabCase(item.text || '')}`}
                data-testid="sp-action-menu"
              >
                {item.text}
              </MenuItem>
              {userManagementDialog ? (
                <UserManagementActionDialog
                  open={activeLinkOpen[index]}
                  clickHandler={() => clickHandler(index)}
                  status={item.status || ''}
                  data-testid="sp-full-view-dialog-0"
                />
              ) : (
                <FullViewDialog
                  open={activeLinkOpen[index]}
                  clickHandler={() => clickHandler(index)}
                  dialogContent={<>{item.rightJSX}</>}
                  data-testid="sp-full-view-dialog-0"
                />
              )}
            </MenuItemWrapper>
          ))}
      </StyledMenu>
    </NavigationProfileContainer>
  );
};

export default ActionLink;
