export default class TimelineItem {
  constructor(
    public title: string,
    public info: string,
    public disabled: boolean,
    public displayChildren: boolean,
    public icon: JSX.Element[] | JSX.Element,
    public childJSX: JSX.Element[] | JSX.Element,
    public opacity?: boolean
  ) {}
}
