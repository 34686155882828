import { Paper, Table, TableBody } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';

interface TableMUIStyledProps {
  rowClickable?: boolean;
  showFooter?: boolean;
  margin?: string;
}

export const TableStyled = styled(Table)`
  min-width: 600px;
  background-color: ${themeColors.bg.light.body};
`;

export const TableMUIStyled = styled(MUIDataTable)<TableMUIStyledProps>`
  min-width: 600px;
  margin: ${({ margin }) => margin || ''};
  .MuiToolbar-root {
    display: none;
  }
  div[class*='MUIDataTable-responsiveBase'] {
    overflow-x: auto;
  }
  th {
    ${typography.tableHeader};
    background-color: ${themeColors.bg.light.surfaceRaised};
    border-right: 1px solid ${themeColors.border.light.surfaceRaised};
    height: 64px;
    border-bottom: none;
    text-transform: uppercase;
    vertical-align: top;

    &:first-child {
      border-top-left-radius: 8px;
    }

    p {
      text-align: left;
      line-height: 16px;
      margin-top: 16px;
      margin-left: 8px;
    }
    button {
      width: 100%;
      height: 64px;
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
      div {
        align-items: flex-start;

        .MuiSvgIcon-root {
          margin-top: 28px;
        }
      }
    }
    &:last-child {
      border-right: none;
      border-top-right-radius: 8px;
      padding-right: 8px;
    }
  }
  tbody {
    tr {
      background-color: ${themeColors.bg.light.page} !important;
      cursor: ${({ rowClickable }) => rowClickable && 'pointer'};
      :hover {
        background-color: ${themeColors.bg.light.surface} !important;
      }
      td {
        padding: 8px 16px !important;
        border-bottom: 1px solid ${themeColors.border.primary};

        p,
        div {
          color: ${themeColors.text.light.body}!important;
          ${typography.captionRegular};
          text-decoration: none !important;
        }
      }
    }
  }
  [class*='MUIDataTableResize-resizer'] {
    border: 1px solid transparent !important;
    cursor: ew-resize;
  }
  .MuiTableRow-root {
    vertical-align: baseline;
  }

  .MuiTableCell-head {
    padding: 0 !important;
  }

  .MuiTableCell-body {
    padding: 8px !important;
    vertical-align: middle;
  }

  .MuiButton-root {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 32px !important;

    > div:first-child {
      display: flex !important;
      width: 100% !important;
      justify-content: space-between !important;
    }
  }

  .MuiButtonBase-root {
    /* padding: 0 8px !important; */
  }

  .MuiCheckbox-root {
    color: ${themeColors.text.primary}!important;
    background-color: transparent;
    border-radius: 4px;
  }
  .MuiTableCell-paddingCheckbox {
    > td {
      vertical-align: top;
    }
  }
  .MuiTableFooter-root {
    display: ${({ showFooter }) => !showFooter && 'none'};
  }
`;

export const TableMobileSubTitle = styled.h4`
  margin: 0;
  padding-right: 8px;
  border-right: 4px solid ${themeColors.border.primary};
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled(Paper)`
  width: 100%;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .MuiPaper-root {
    background-color: transparent !important;
  }
`;

export const TableBodyStyled = styled(TableBody)`
  tr {
    :hover {
      background-color: ${themeColors.bg.light.surface};
    }
  }
`;

export const TableControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TableExportButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.text.primary};
  background-color: transparent;
  height: 24px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid ${themeColors.border.primary};
  align-self: flex-end;
  cursor: pointer;
`;

export const TableExportText = styled.h5`
  margin: 0;
`;

export const TableCount = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.subtle};
  margin: 0;
`;

export const TableMobileTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const TableHeaderStyled = styled.p`
  ${typography.tableHeader};
`;

export const ExportDataButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

export const ExportDataButtonText = styled.h5``;

export const DataGridActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 16px;
`;

export const DataGridCountContainer = styled.p`
  ${typography.bodySmallSemiBold};
  color: ${themeColors.text.light.subtle};
`;

export const DataGridCustomTableHeader = styled.th`
  display: flex;
  align-items: center;
  min-height: 42px;
  background-color: ${themeColors.bg.light.surfaceRaised};
  color: ${themeColors.mono1};
  padding: 0 8px;
  width: 50px;
`;

export const DataGridCustomTableHeaderText = styled.p`
  ${typography.tableHeader};
`;

export const DataGridValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataGridValueBold = styled.p`
  ${typography.bodyRegular};
`;

export const DataGridValueRegular = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.body};
`;
