/* eslint-disable react-hooks/exhaustive-deps */
import { AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { themeColors } from 'assets/theme/style';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  EarlyPaymentRequestNameValueRow,
  EarlyPaymentRequestName,
  EarlyPaymentRequestData,
  EarlyPaymentRequestAccordionStyled,
  AccordionSummaryContainer
} from '../styled';
import { EarlyPaymentRequestSummaryContainer, EarlyPaymentRequestSummaryH4 } from './styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FundingRequestMoreDetails from 'components/funding-request/FundingRequestMoreDetails';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import { FundingCosts } from 'utils/interfaces/early-payment/earlyPayment.interface';

interface EarlyPaymentRequestSummaryProps {
  currency: string;
  fundingCosts: FundingCosts;
}

const EarlyPaymentRequestSummary: FC<EarlyPaymentRequestSummaryProps> = ({
  currency,
  fundingCosts
}) => {
  const [funder, setFunder] = useState<string>('');
  const fundingRequestCalculation: FundingRequestCalculation | null = useSelector(
    (state: RootStateOrAny) => state.fundingRequest.fundingRequestCalculation
  );
  const program: ProgramConfig | null = useSelector((state: RootStateOrAny) => state.app.program);

  useEffect(() => {
    if (fundingRequestCalculation) setFunderName(fundingRequestCalculation);
  }, [fundingRequestCalculation]);

  const setFunderName: (calculation: FundingRequestCalculation) => void = (calculation) => {
    if (!program) return;
    const index = program.participants.findIndex((p) => p.role === 'FUNDER');
    setFunder(program.participants[index].registeredName);
  };

  const calculatePercentage: (val: number, total: number) => string = (val, total) =>
    `${((val / total) * 100).toFixed(2)}%`;

  return (
    <EarlyPaymentRequestSummaryContainer>
      <EarlyPaymentRequestSummaryH4 data-automation-id="early-payment-request-h4-summary-title">
        Summary
      </EarlyPaymentRequestSummaryH4>
      <EarlyPaymentRequestNameValueRow margin="0 0 16px 0">
        <EarlyPaymentRequestName
          data-automation-id="early-payment-request-p-funder-title"
          data-testid="sp-funder-title"
        >
          Funder
        </EarlyPaymentRequestName>
        <EarlyPaymentRequestData
          data-automation-id="early-payment-request-p-funder-value"
          data-testid="sp-funder-value"
        >
          {funder}
        </EarlyPaymentRequestData>
      </EarlyPaymentRequestNameValueRow>
      <EarlyPaymentRequestNameValueRow>
        <EarlyPaymentRequestName
          data-automation-id="early-payment-request-p-total-amount-title"
          data-testid="sp-total-amount-title"
        >
          Total amount requested:
        </EarlyPaymentRequestName>
        <EarlyPaymentRequestData
          data-automation-id="early-payment-request-p-total-amount-value"
          data-testid="sp-total-amount-value"
        >
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(fundingRequestCalculation ? fundingRequestCalculation.totalAmount : 0, 2)}
        </EarlyPaymentRequestData>
      </EarlyPaymentRequestNameValueRow>
      <EarlyPaymentRequestAccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: themeColors.icon.primary }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionSummaryContainer>
            <EarlyPaymentRequestName
              data-automation-id="early-payment-request-p-total-cost-title"
              data-testid="sp-total-cost-title"
            >
              Total cost of funding
            </EarlyPaymentRequestName>
            <EarlyPaymentRequestData
              data-automation-id="early-payment-request-p-total-cost-value"
              data-testid="sp-total-cost-value"
            >
              {CurrencySymbolsLookUp[currency]}
              {formatNumber(
                fundingRequestCalculation ? fundingRequestCalculation.totalFundingCosts : 0,
                2
              )}{' '}
              (
              {calculatePercentage(
                fundingRequestCalculation ? fundingRequestCalculation.totalFundingCosts : 0,
                fundingRequestCalculation ? fundingRequestCalculation.totalAmount : 0
              )}
              )
            </EarlyPaymentRequestData>
          </AccordionSummaryContainer>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: 0 }}>
          <FundingRequestMoreDetails currency={currency} {...fundingCosts} showLink />
        </AccordionDetails>
      </EarlyPaymentRequestAccordionStyled>
      <Divider />
      <EarlyPaymentRequestNameValueRow>
        <EarlyPaymentRequestName
          data-automation-id="early-payment-request-p-you-get-paid-title"
          data-testid="sp-you-get-paid-title"
        >
          You get paid:{' '}
        </EarlyPaymentRequestName>
        <EarlyPaymentRequestSummaryH4
          data-automation-id="early-payment-request-h4-you-get-paid-value"
          data-testid="sp-you-get-paid-value"
        >
          {CurrencySymbolsLookUp[currency]}
          {formatNumber(fundingRequestCalculation ? fundingRequestCalculation.purchasePrice : 0, 2)}
        </EarlyPaymentRequestSummaryH4>
      </EarlyPaymentRequestNameValueRow>
    </EarlyPaymentRequestSummaryContainer>
  );
};

export default EarlyPaymentRequestSummary;
