/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import {
  QueryComparators,
  QueryDateComparators,
  QueryDefinitiveBooleanComparators,
  QueryDefinitiveSimpleComparators,
  QuerySimpleComparators
} from 'lib/enums/query/queryComparators.enum';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { ChangeEvent, FC, useEffect, useState, KeyboardEvent } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import {
  CalendarMonthIconStyled,
  DatePickerStyled,
  DeleteIconStyled,
  QueryActionsContainer,
  QueryActionsRow,
  QueryActionText,
  QueryClearFiltersText,
  QueryContainer,
  QueryDateSearchRowSegment,
  QueryDescriptionText,
  QueryRowContainer,
  QuerySaveFilterContainer,
  QuerySectionText,
  QuerySelectStyled,
  QueryTextInputStyled,
  QueryTopRow
} from './styled';
import {
  QueryDateRange,
  QueryDropdownOption,
  QueryItem,
  QueryRow
} from 'utils/classes/query/query';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';

interface QueryBuilderProps {
  queryItems: QueryItem[];
  activeQueriesHandler: (queries: QueryRow[]) => void;
  closeClickHandler: () => void;
  open: boolean;
  testingTagPage?: string;
  currencies?: string[];
}

const QueryBuilder: FC<QueryBuilderProps> = ({
  queryItems,
  activeQueriesHandler,
  closeClickHandler,
  open,
  testingTagPage,
  currencies
}) => {
  const [queryableOptions, setQueryableOptions] = useState<QueryDropdownOption[]>([]);
  const [queryRows, setQueryRows] = useState<QueryRow[]>([]);
  const [fuzzyDropdownComparators, setFuzzyDropdownComparators] = useState<QueryDropdownOption[]>(
    []
  );
  const [numberComparators, setNumberComparators] = useState<QueryDropdownOption[]>([]);
  const [dateComparators, setDateComparators] = useState<QueryDropdownOption[]>([]);
  const [simpleComparators, setSimpleComparators] = useState<QueryDropdownOption[]>([]);
  const [definitiveSimpleComparators, setDefinitiveSimpleComparators] = useState<
    QueryDropdownOption[]
  >([]);

  const [booleanComparators, setBooleanComparators] = useState<QueryDropdownOption[]>([]);

  const booleanDropdownOptions: QueryDropdownOption[] = [
    {
      name: 'Yes',
      value: 'true'
    },
    {
      name: 'No',
      value: 'false'
    }
  ];

  const currenciesDropdownOptions: QueryDropdownOption[] = currencies
    ? currencies.map((c) => ({
        name: c,
        value: c
      }))
    : [];

  useEffect(() => {
    if (queryItems.length > 0) {
      determineDefaultOptions();
      setDefaultFiltersOnOpen();
    }
  }, [queryItems]);

  const determineDefaultOptions: () => void = () => {
    setQueryableOptions(
      queryItems
        .filter((m) => m.display !== false)
        .map((m) => ({ name: m.filterName, value: m.filterValue }))
    );
    setFuzzyDropdownComparators(
      Object.keys(QueryComparators).map(
        (key) => new QueryDropdownOption((QueryComparators as { [key: string]: string })[key], key)
      )
    );
    setNumberComparators(
      Object.keys(QueryComparators).map(
        (key) => new QueryDropdownOption((QueryComparators as { [key: string]: string })[key], key)
      )
    );
    setDateComparators(
      Object.keys(QueryDateComparators).map(
        (key) =>
          new QueryDropdownOption((QueryDateComparators as { [key: string]: string })[key], key)
      )
    );
    setSimpleComparators(
      Object.keys(QuerySimpleComparators).map(
        (key) =>
          new QueryDropdownOption((QuerySimpleComparators as { [key: string]: string })[key], key)
      )
    );
    setDefinitiveSimpleComparators(
      Object.keys(QueryDefinitiveSimpleComparators).map(
        (key) =>
          new QueryDropdownOption(
            (QueryDefinitiveSimpleComparators as { [key: string]: string })[key],
            key
          )
      )
    );
    setBooleanComparators(
      Object.keys(QueryDefinitiveBooleanComparators).map(
        (key) =>
          new QueryDropdownOption(
            (QueryDefinitiveBooleanComparators as { [key: string]: string })[key],
            key
          )
      )
    );
  };

  const setDefaultFiltersOnOpen: () => void = () => {
    const defaultAppliedFilters: QueryRow[] = queryItems
      .filter((m) => !!m.defaultValue && !!m.defaultComparator)
      .map(
        (query, i) =>
          new QueryRow(
            i,
            query.filterValue,
            query.defaultValue ? query.defaultValue : '',
            query.componentType,
            query.defaultComparator ? query.defaultComparator : '',
            query.options
          )
      );
    setQueryRows(defaultAppliedFilters);
    activeQueriesHandler(defaultAppliedFilters);
  };

  const addNewQueryClickHandler: () => void = () =>
    setQueryRows([...queryRows, new QueryRow(queryRows.length, '', '', '', '', [])]);

  const queryChangeHandler: (value: string, rowIndex: number) => void = (value, rowIndex) => {
    const clonedQueries: QueryRow[] = [...queryRows];
    const clonedRowItem: QueryRow = clonedQueries[rowIndex];
    const matchedIndex: number = queryItems.findIndex((m) => m.filterValue === value);

    clonedRowItem.fieldValue = value as string;
    clonedRowItem.queryValue =
      ~matchedIndex && queryItems[matchedIndex].componentType === QueryComponents.DATE
        ? { to: '', from: '' }
        : '';
    clonedRowItem.valueOptions = ~matchedIndex ? queryItems[matchedIndex].options : [];
    clonedRowItem.componentType = ~matchedIndex ? queryItems[matchedIndex].componentType : '';
    clonedRowItem.comparator =
      ~matchedIndex &&
      comparatorsForComponentType(queryItems[matchedIndex].componentType).length === 1
        ? (comparatorsForComponentType(queryItems[matchedIndex].componentType)[0].value as
            | keyof typeof QueryComparators
            | keyof typeof QueryDateComparators
            | keyof typeof QuerySimpleComparators
            | keyof typeof QueryDefinitiveSimpleComparators
            | '')
        : '';
    clonedQueries.splice(rowIndex, 1, clonedRowItem);
    setQueryRows(clonedQueries);
  };

  const comparatorChangeHandler: (value: string, rowIndex: number) => void = (value, rowIndex) => {
    const clonedQueries: QueryRow[] = [...queryRows];
    const clonedRowItem: QueryRow = clonedQueries[rowIndex];
    clonedRowItem.comparator = value as keyof typeof QueryComparators;
    clonedQueries.splice(rowIndex, 1, clonedRowItem);
    setQueryRows(clonedQueries);
  };

  const queryValueChangeHandler: (
    value: string | Date,
    rowIndex: number,
    type?: 'to' | 'from'
  ) => void = (value, rowIndex, type) => {
    const clonedQueries: QueryRow[] = [...queryRows];
    const clonedRowItem: QueryRow = clonedQueries[rowIndex];
    if (typeof value === 'string') clonedRowItem.queryValue = value as string;
    if (typeof value === 'object' && type === 'from')
      (clonedRowItem.queryValue as QueryDateRange).from = value as Date;
    if (typeof value === 'object' && type === 'to')
      (clonedRowItem.queryValue as QueryDateRange).to = value as Date;
    clonedQueries.splice(rowIndex, 1, clonedRowItem);
    setQueryRows(clonedQueries);
  };

  const datePickerValueChangeHandler: (
    value: boolean,
    rowIndex: number,
    type?: 'to' | 'from'
  ) => void = (value, rowIndex, type) => {
    const clonedQueries: QueryRow[] = [...queryRows];
    const clonedRowItem: QueryRow = clonedQueries[rowIndex];
    if (type === 'to') clonedRowItem.toPickerOpen = value;
    if (type === 'from') clonedRowItem.fromPickerOpen = value;
    clonedQueries.splice(rowIndex, 1, clonedRowItem);
    setQueryRows(clonedQueries);
  };

  const deleteRowClickHandler: (rowIndex: number) => void = (rowIndex) => {
    const clonedQueries: QueryRow[] = [...queryRows];
    clonedQueries.splice(rowIndex, 1);
    setQueryRows(clonedQueries);
    activeQueriesHandler(clonedQueries);
  };

  const clearRowsClickHandler: () => void = () => {
    setQueryRows([]);
    activeQueriesHandler([]);
  };

  const applyFilterClickHandler: () => void = () => {
    activeQueriesHandler(queryRows);
  };

  const cancelClickHandler: () => void = () => closeClickHandler();

  const canAddNewCondition: () => boolean = () =>
    queryRows.filter(
      (row) =>
        !!!row.fieldValue ||
        (hasComparatorsForSelect(row) && !!!row.comparator) ||
        !!!row.queryValue
    ).length === 0;

  const renderFieldSegment: (row: QueryRow, rowIndex: number) => JSX.Element = (row, rowIndex) => (
    <QuerySelectStyled
      value={row.fieldValue}
      style={{ flex: 0.3 }}
      onChange={(e: SelectChangeEvent<unknown>) =>
        queryChangeHandler(e.target.value as string, rowIndex)
      }
      data-automation-id={`${testingTagPage}-div-advanced-search-select-listbox`}
      data-testid="sp-search-select-listbox"
    >
      {queryableOptions.map((q, i) => (
        <MenuItem
          key={i}
          value={`${q.value}`}
          data-automation-id={`${testingTagPage}-li-advanced-search-select-item`}
        >
          {q.name}
        </MenuItem>
      ))}
    </QuerySelectStyled>
  );

  const hasComparatorsForSelect = (row: QueryRow): boolean =>
    comparatorsForComponentType(row.componentType).length > 0;

  const renderComparatorSegment: (row: QueryRow, rowIndex: number) => JSX.Element = (
    row,
    rowIndex
  ) => {
    const comparatorsForSelect: QueryDropdownOption[] = comparatorsForComponentType(
      row.componentType
    );

    return (
      <QuerySelectStyled
        data-automation-id={`${testingTagPage}-div-comparator-select-container`}
        value={row.comparator}
        style={{ display: 'flex', flex: 0.2 }}
        onChange={(e: SelectChangeEvent<unknown>) =>
          comparatorChangeHandler(e.target.value as keyof typeof QuerySimpleComparators, rowIndex)
        }
        data-testid="sp-comparator-select"
      >
        {comparatorsForSelect.map((q, i) => (
          <MenuItem
            data-automation-id={`${testingTagPage}-li-advanced-search-select-item`}
            key={i}
            value={`${q.value}`}
            disabled={comparatorsForSelect.length === 1}
          >
            {q.name}
          </MenuItem>
        ))}
      </QuerySelectStyled>
    );
  };

  const comparatorsForComponentType: (
    componentType: keyof typeof QueryComponents | ''
  ) => QueryDropdownOption[] = (componentType) => {
    switch (componentType) {
      case QueryComponents.DATE:
        return dateComparators;
      case QueryComponents.TEXT:
        return simpleComparators;
      case QueryComponents.SIMPLE_DROPDOWN:
        return simpleComparators;
      case QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN:
        return definitiveSimpleComparators;
      case QueryComponents.FUZZY_DROPDOWN:
        return fuzzyDropdownComparators;
      case QueryComponents.NUMBER:
        return numberComparators;
      case QueryComponents.BOOLEAN:
        return booleanComparators;
      default:
        return simpleComparators;
    }
  };

  const renderValueSegment: (row: QueryRow, rowIndex: number) => JSX.Element = (row, rowIndex) => {
    switch (row.componentType) {
      case QueryComponents.SIMPLE_DROPDOWN:
        return renderSelectComponent(row, rowIndex);
      case QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN:
        return renderSelectComponent(row, rowIndex);
      case QueryComponents.FUZZY_DROPDOWN:
        return renderSelectComponent(row, rowIndex);
      case QueryComponents.DATE:
        return renderDateSelectComponent(row, rowIndex);
      case QueryComponents.BOOLEAN:
        return renderSelectComponent({ ...row, valueOptions: booleanDropdownOptions }, rowIndex);
      case QueryComponents.CURRENCY:
        return renderSelectComponent({ ...row, valueOptions: currenciesDropdownOptions }, rowIndex);
      case QueryComponents.NUMBER:
        return renderNumberField(row, rowIndex);
      default:
        return renderTextField(row, rowIndex);
    }
  };

  const formattedDate: (date: Date | '') => string = (date) =>
    !date ? '' : formatDateTime(date.toISOString(), 'MM/dd/yyyy');

  const renderDateSelectComponent: (row: QueryRow, rowIndex: number) => JSX.Element = (
    row,
    rowIndex
  ) => (
    <>
      <QueryDescriptionText
        data-automation-id={`${testingTagPage}-p-advanced-search-description-text`}
      >
        from
      </QueryDescriptionText>
      <QueryDateSearchRowSegment
        id="from-date-segment"
        data-automation-id={`${testingTagPage}-div-advanced-search-from-date-segment`}
      >
        <OutlinedInput
          id="outlined-from-date-adornment-search"
          dataTestId="sp-advanced-search-from-date"
          defaultValue={formattedDate((row.queryValue as QueryDateRange).from)}
          endAdornment={
            <InputAdornment position="end">
              <CalendarMonthIconStyled />
            </InputAdornment>
          }
          placeholder="From date"
          onClick={() => datePickerValueChangeHandler(true, rowIndex, 'from')}
          width="100%"
        />

        <DatePickerStyled
          open={row.fromPickerOpen}
          selected={(row.queryValue as QueryDateRange).from || null}
          onChange={(date: Date) => {
            queryValueChangeHandler(date, rowIndex, 'from');
            datePickerValueChangeHandler(false, rowIndex, 'from');
          }}
          onClickOutside={() => datePickerValueChangeHandler(false, rowIndex, 'from')}
        />
      </QueryDateSearchRowSegment>
      <QueryDescriptionText
        data-automation-id={`${testingTagPage}-p-advanced-search-description-text`}
      >
        to
      </QueryDescriptionText>
      <QueryDateSearchRowSegment>
        <OutlinedInput
          id="outlined-to-date-adornment-search"
          dataTestId="sp-advanced-search-to-date"
          defaultValue={formattedDate((row.queryValue as QueryDateRange).to)}
          endAdornment={
            <InputAdornment position="end">
              <CalendarMonthIconStyled />
            </InputAdornment>
          }
          placeholder="To date"
          onClick={() => datePickerValueChangeHandler(true, rowIndex, 'to')}
          width="100%"
        />

        <DatePickerStyled
          open={row.toPickerOpen}
          selected={(row.queryValue as QueryDateRange).to || null}
          onChange={(date: Date) => {
            queryValueChangeHandler(date, rowIndex, 'to');
            datePickerValueChangeHandler(false, rowIndex, 'to');
          }}
          onClickOutside={() => datePickerValueChangeHandler(false, rowIndex, 'to')}
        />
      </QueryDateSearchRowSegment>
    </>
  );

  const renderSelectComponent: (row: QueryRow, rowIndex: number) => JSX.Element = (
    row,
    rowIndex
  ) => (
    <QuerySelectStyled
      value={row.queryValue}
      style={{ flex: 0.5 }}
      onChange={(e: SelectChangeEvent<unknown>) =>
        queryValueChangeHandler(e.target.value as string, rowIndex)
      }
      data-automation-id={`${testingTagPage}-div-advanced-search-select-listbox`}
    >
      {row.valueOptions &&
        row.valueOptions.map((q, i) => (
          <MenuItem
            key={i}
            value={`${q.value}`}
            data-automation-id={`${testingTagPage}-li-advanced-search-select-item`}
          >
            {q.name}
          </MenuItem>
        ))}
    </QuerySelectStyled>
  );

  const searchHandleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === 'Enter') {
      applyFilterClickHandler();
    }
  };

  const renderTextField: (row: QueryRow, rowIndex: number) => JSX.Element = (row, rowIndex) => (
    <QueryTextInputStyled
      defaultValue={row.queryValue}
      style={{ flex: 0.5 }}
      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        queryValueChangeHandler(e.target.value, rowIndex)
      }
      onKeyDown={searchHandleKeyDown}
    />
  );

  const renderNumberField: (row: QueryRow, rowIndex: number) => JSX.Element = (row, rowIndex) => (
    <QueryTextInputStyled
      type="number"
      defaultValue={row.queryValue}
      style={{ flex: 0.5 }}
      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        queryValueChangeHandler(e.target.value, rowIndex)
      }
      onKeyDown={searchHandleKeyDown}
    />
  );

  return open ? (
    queryableOptions.length > 0 ? (
      <BaseCard margin="0 0 16px 0" noBoxShadow>
        <QueryContainer>
          <QueryTopRow>
            <QuerySectionText
              data-automation-id={`${testingTagPage}-p-advanced-search-select-fields`}
            >
              Select Fields
            </QuerySectionText>
            {queryRows.length > 0 && (
              <QueryClearFiltersText
                onClick={() => {
                  clearRowsClickHandler();
                }}
                data-automation-id={`${testingTagPage}-p-advanced-search-clear-all-filters`}
                data-testid="sp-clear-all-filters"
              >
                Clear all filters
              </QueryClearFiltersText>
            )}
          </QueryTopRow>
          {queryRows.map((row, rowIndex) => (
            <QueryRowContainer key={rowIndex}>
              {renderFieldSegment(row, rowIndex)}
              {row.fieldValue &&
                hasComparatorsForSelect(row) &&
                renderComparatorSegment(row, rowIndex)}
              {row.fieldValue &&
                (row.comparator || !hasComparatorsForSelect(row)) &&
                renderValueSegment(row, rowIndex)}
              <DeleteIconStyled
                onClick={() => deleteRowClickHandler(rowIndex)}
                data-automation-id={`${testingTagPage}-svg-advanced-search-delete-icon`}
                data-testid="sp-delete-icon"
              />
            </QueryRowContainer>
          ))}
          {canAddNewCondition() && (
            <QueryActionText
              onClick={addNewQueryClickHandler}
              data-automation-id={`${testingTagPage}-p-advanced-search-add-condition`}
            >
              + Add condition
            </QueryActionText>
          )}
          <Divider margin="0 -24px 8px" />
          <QueryActionsRow>
            <QuerySaveFilterContainer>
              {/* remaining as it will be played in near future */}
              {/* <FilterIconStyled />
              <QueryActionText>Save Filter</QueryActionText> */}
            </QuerySaveFilterContainer>
            <QueryActionsContainer>
              <SecondaryButton
                clickHandler={cancelClickHandler}
                text="Cancel"
                testingTag={`${testingTagPage}-button-advanced-search-cancel`}
              />
              <PrimaryButton
                minWidth="fit-content"
                text="Apply filter"
                clickHandler={applyFilterClickHandler}
                testingTag={`${testingTagPage}-button-advanced-search-apply-filter`}
              />
            </QueryActionsContainer>
          </QueryActionsRow>
        </QueryContainer>
      </BaseCard>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default QueryBuilder;
