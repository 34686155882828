import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const FullPageForbiddenContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

export const FullPageForbiddenHeading = styled.h2`
  color: ${themeColors.text.primary};
`;

export const FullPageForbiddenText = styled.p``;
