import { DateTime } from "luxon";

export const getCookie: (name: string) => string = (name) => {
  const match: RegExpMatchArray | null = document.cookie.match(name + '=([^;]*)');
  return match ? match[1] : '';
};

export const setCookie: (cName: string, cValue: string | DateTime, expDays?: number) => void = (
  cName,
  cValue,
  expDays = 7
) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires: string = 'expires=' + date.toUTCString();
  document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
};

export const removeCookie: (name: string) => void = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
