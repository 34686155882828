import { IPurchaseOrderInvoice } from 'utils/interfaces/invoice/purchaseOrder.interface';

export const PurchaseOrderInvoiceFlipDictionary: IPurchaseOrderInvoice = {
  buyerName: 'buyerName',
  buyerReference: 'buyerReference',
  createdBy: 'supplierName',
  amount: 'amount',
  currency: 'currency',
  poNumber: 'poNumber',
  supplierName: 'supplierName',
  supplierReference: 'supplierReference',
  paymentTerms: 'paymentTerms',
  origin: 'origin',
  incoTerms: 'logistics.incoTerms',
  notifyPartyName: 'logistics.notifyPartyName',
  lineItems: 'lineItems',
  'logistics.contacts.notifyPartyContactDetails.firstName':
    'logistics.contacts.notifyPartyContactDetails.firstName',
  'logistics.contacts.notifyPartyContactDetails.lastName':
    'logistics.contacts.notifyPartyContactDetails.lastName',
  'logistics.contacts.notifyPartyContactDetails.emailAddress':
    'logistics.contacts.notifyPartyContactDetails.emailAddress',
  'logistics.contacts.notifyPartyContactDetails.contactNumber':
    'logistics.contacts.notifyPartyContactDetails.contactNumber',
  'logistics.addresses.shipToAddress.addressee': 'logistics.addresses.shipToAddress.addressee',
  'logistics.addresses.shipToAddress.addressLine1':
    'logistics.addresses.shipToAddress.addressLine1',
  'logistics.addresses.shipToAddress.addressLine2':
    'logistics.addresses.shipToAddress.addressLine2',
  'logistics.addresses.shipToAddress.addressLine3':
    'logistics.addresses.shipToAddress.addressLine3',
  'logistics.addresses.shipToAddress.addressLine4':
    'logistics.addresses.shipToAddress.addressLine4',
  'logistics.addresses.shipToAddress.code': 'logistics.addresses.shipToAddress.code',
  'logistics.addresses.shipToAddress.city': 'logistics.addresses.shipToAddress.city',
  'logistics.addresses.shipToAddress.region': 'logistics.addresses.shipToAddress.region',
  'logistics.addresses.shipToAddress.country': 'logistics.addresses.shipToAddress.country',
  'logistics.addresses.consignorAddress.addressee':
    'logistics.addresses.consignorAddress.addressee',
  'logistics.addresses.consignorAddress.addressLine1':
    'logistics.addresses.consignorAddress.addressLine1',
  'logistics.addresses.consignorAddress.addressLine2':
    'logistics.addresses.consignorAddress.addressLine2',
  'logistics.addresses.consignorAddress.addressLine3':
    'logistics.addresses.consignorAddress.addressLine3',
  'logistics.addresses.consignorAddress.addressLine4':
    'logistics.addresses.consignorAddress.addressLine4',
  'logistics.addresses.consignorAddress.code': 'logistics.addresses.consignorAddress.code',
  'logistics.addresses.consignorAddress.city': 'logistics.addresses.consignorAddress.city',
  'logistics.addresses.consignorAddress.region': 'logistics.addresses.consignorAddress.region',
  'logistics.addresses.consignorAddress.country': 'logistics.addresses.consignorAddress.country',
  'logistics.addresses.consigneeAddress.addressee':
    'logistics.addresses.consigneeAddress.addressee',
  'logistics.addresses.consigneeAddress.addressLine1':
    'logistics.addresses.consigneeAddress.addressLine1',
  'logistics.addresses.consigneeAddress.addressLine2':
    'logistics.addresses.consigneeAddress.addressLine2',
  'logistics.addresses.consigneeAddress.addressLine3':
    'logistics.addresses.consigneeAddress.addressLine3',
  'logistics.addresses.consigneeAddress.addressLine4':
    'logistics.addresses.consigneeAddress.addressLine4',
  'logistics.addresses.consigneeAddress.code': 'logistics.addresses.consigneeAddress.code',
  'logistics.addresses.consigneeAddress.city': 'logistics.addresses.consigneeAddress.city',
  'logistics.addresses.consigneeAddress.region': 'logistics.addresses.consigneeAddress.region',
  'logistics.addresses.consigneeAddress.country': 'logistics.addresses.consigneeAddress.country',
  'logistics.addresses.notifyPartyAddress.addressee':
    'logistics.addresses.notifyPartyAddress.addressee',
  'logistics.addresses.notifyPartyAddress.addressLine1':
    'logistics.addresses.notifyPartyAddress.addressLine1',
  'logistics.addresses.notifyPartyAddress.addressLine2':
    'logistics.addresses.notifyPartyAddress.addressLine2',
  'logistics.addresses.notifyPartyAddress.addressLine3':
    'logistics.addresses.notifyPartyAddress.addressLine3',
  'logistics.addresses.notifyPartyAddress.addressLine4':
    'logistics.addresses.notifyPartyAddress.addressLine4',
  'logistics.addresses.notifyPartyAddress.code': 'logistics.addresses.notifyPartyAddress.code',
  'logistics.addresses.notifyPartyAddress.city': 'logistics.addresses.notifyPartyAddress.city',
  'logistics.addresses.notifyPartyAddress.region': 'logistics.addresses.notifyPartyAddress.region',
  'logistics.addresses.notifyPartyAddress.country':
    'logistics.addresses.notifyPartyAddress.country',
  'logistics.addresses.logisticsServiceProviderAddress.addressee':
    'logistics.addresses.logisticsServiceProviderAddress.addressee',
  'logistics.addresses.logisticsServiceProviderAddress.addressLine1':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine1',
  'logistics.addresses.logisticsServiceProviderAddress.addressLine2':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine2',
  'logistics.addresses.logisticsServiceProviderAddress.addressLine3':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine3',
  'logistics.addresses.logisticsServiceProviderAddress.addressLine4':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine4',
  'logistics.addresses.logisticsServiceProviderAddress.code':
    'logistics.addresses.logisticsServiceProviderAddress.code',
  'logistics.addresses.logisticsServiceProviderAddress.city':
    'logistics.addresses.logisticsServiceProviderAddress.city',
  'logistics.addresses.logisticsServiceProviderAddress.region':
    'logistics.addresses.logisticsServiceProviderAddress.region',
  'logistics.addresses.logisticsServiceProviderAddress.country':
    'logistics.addresses.logisticsServiceProviderAddress.country',
  'logistics.addresses.manufacturerAddress.addressee':
    'logistics.addresses.manufacturerAddress.addressee',
  'logistics.addresses.manufacturerAddress.addressLine1':
    'logistics.addresses.manufacturerAddress.addressLine1',
  'logistics.addresses.manufacturerAddress.addressLine2':
    'logistics.addresses.manufacturerAddress.addressLine2',
  'logistics.addresses.manufacturerAddress.addressLine3':
    'logistics.addresses.manufacturerAddress.addressLine3',
  'logistics.addresses.manufacturerAddress.addressLine4':
    'logistics.addresses.manufacturerAddress.addressLine4',
  'logistics.addresses.manufacturerAddress.code': 'logistics.addresses.manufacturerAddress.code',
  'logistics.addresses.manufacturerAddress.city': 'logistics.addresses.manufacturerAddress.city',
  'logistics.addresses.manufacturerAddress.region':
    'logistics.addresses.manufacturerAddress.region',
  'logistics.addresses.manufacturerAddress.country':
    'logistics.addresses.manufacturerAddress.country',
  'logistics.locations.portOrPlaceOfLoading.name': 'logistics.locations.portOrPlaceOfLoading.name',
  'logistics.locations.portOrPlaceOfDestination.name':
    'logistics.locations.portOrPlaceOfDestination.name'
};
