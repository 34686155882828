/* eslint-disable react-hooks/exhaustive-deps */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Checkbox, Collapse, InputAdornment } from '@mui/material';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import {
  IInvoiceRule,
  ILogisticsRuleSubtemplateField,
  ProgramConfig,
  ProgramRuleTypeEnum
} from 'api/interfaces/program/program.interface';
import HideMoreIcon from 'assets/icons/HideMoreIcon';
import ShowMoreIcon from 'assets/icons/ShowMoreIcon';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import TextInput from 'components/forms/inputs/TextInput';
import {
  CheckboxFieldRow,
  CheckboxFieldText,
  CreateInvoiceDetailDateSegment
} from 'components/ledger/LedgerCreateInvoice/LedgerCreateInvoiceField/styled';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { PurchaseOrderInvoiceFlipDictionary } from 'lib/lookups/poFlip.lookup';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_DRAFT_INVOICE_MODAL, SET_DRAFT_PO } from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { CheckboxStyled } from 'views/Onboarding/styled';
import { HeaderCheckbox } from './CreateInvoiceDialog';
import {
  CreateInvoiceDialogActionButtonContainer,
  CreateInvoiceDialogActionContainer,
  CreateInvoiceDialogContainer,
  CreateInvoiceDialogDescription,
  CreateInvoiceDialogDescriptionDetail,
  CreateInvoiceDialogInvoiceDetailWrapper,
  CreateInvoiceDialogInvoiceDetailsContainer,
  CreateInvoiceDialogInvoiceDetailsRow,
  CreateInvoiceDialogLineItemsContainer,
  CreateInvoiceDialogLineItemsErrorMessage,
  CreateInvoiceDialogLineItemsHeader,
  CreateInvoiceDialogShowMoreContainer,
  CreateInvoiceDialogSummaryContainer,
  CreateInvoiceDialogSummaryInfo,
  CreateInvoiceDialogSummaryTitle,
  CreateInvoiceDialogTable,
  CreateInvoiceDialogTitle,
  CreateInvoiceDialogTitleBold,
  CreateInvoiceDialogTitleRegular,
  CreateInvoiceDialogTitleWrapper,
  GroupTableContainer,
  GroupedTable
} from './styled';
import { _DATE_FORMAT } from 'lib/constants/contants';

interface IFirstPoFlipDialog {
  po: ILedgerPurchaseOrder;
  closeHandler: () => void;
  saveDialog: () => void;
  purchaseOrderId: string;
}

/*
 TO DO:

 SET THIS VALUE TO DISABLE LINE ITEMS
 matchedItem['excluded'] = item.lineItemPrincipalStatus === 'FULFILLED';
*/

const FirstPoFlipDialog: FC<IFirstPoFlipDialog> = ({
  po,
  closeHandler,
  saveDialog,
  purchaseOrderId
}) => {
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [fieldsFromRules, setFieldsFromRules] = useState<ILogisticsRuleSubtemplateField[]>([]);
  const [invoiceNumberJSX, setInvoiceNumberJSX] = useState<JSX.Element | null>(null);
  const [lineItemsJSX, setLineItemsJSX] = useState<JSX.Element | null>(null);
  // DO NOT REMOVE TEST JSX!!!!!
  const [testJSX, setTestJSX] = useState<JSX.Element[]>([]);
  const [open, setOpen] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [headerCheckbox, setHeaderCheckbox] = useState<HeaderCheckbox>({
    indeterminate: false,
    checked: true
  });
  const [mandatoryFieldsComplete, setMandatoryFieldsComplete] = useState<boolean>(false);
  const [hasMinimumLineItems, setHasMinimumLineItems] = useState<boolean>(false);
  const [lineItemsMandatoryFieldsComplete, setLineItemsMandatoryFieldsComplete] =
    useState<boolean>(false);
  const {
    allProgramsWithDetails
  }: {
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const registeredName: string | null = useSelector(
    (state: RootStateOrAny) => state.program.programOwnerDetails?.contact?.registeredName
  );

  const {
    draftInvoiceModal
  }: {
    draftInvoiceModal: ILogisticsRuleSubtemplateField[];
  } = useSelector((state: RootStateOrAny) => state.createInvoice);

  const {
    register,
    formState: { errors }
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  useEffect(() => {
    getPORules();
  }, [allProgramsWithDetails]);

  useEffect(() => {
    if (draftInvoiceModal) {
      setLineItemsMandatoryFieldsComplete(isLineItemMandatoryDataSupplied(draftInvoiceModal));
    }
  }, []);

  useEffect(() => {
    if (fieldsFromRules) {
      setTestJSX(renderFields(fieldsFromRules));
      setInvoiceNumberJSX(renderInvoiceNumberJSX());
      setLineItemsJSX(renderLineItems());
      setMandatoryFieldsComplete(isInvoiceNumberPopulated());
      setLineItemsMandatoryFieldsComplete(isLineItemMandatoryDataSupplied(fieldsFromRules));
      setHasMinimumLineItems(hasMinimumLineItemsSelected());
    }
  }, [fieldsFromRules]);

  useEffect(() => {
    if (fieldsFromRules) setLineItemsJSX(renderLineItems());
  }, [fieldsFromRules, open]);

  const savedraftInvoice: () => void = () => {
    dispatch({
      type: SET_DRAFT_INVOICE_MODAL,
      payload: { data: fieldsFromRules, id: purchaseOrderId }
    });
    dispatch({
      type: SET_DRAFT_PO,
      payload: { purchaseOrder: po }
    });

    saveDialog();
  };

  const removeNegative: (value: string) => string = (value) => {
    return value.replace(/[^0-9.]/g, '');
  };

  const hasMinimumLineItemsSelected: () => boolean = () => {
    const matched = fieldsFromRules.find((r) => r.name === 'lineItems');

    if (!matched) return false;

    return (matched.value as any[]).filter((v) => v.selected).length > 0;
  };

  const subtotalAmount: () => number = () =>
    (fieldsFromRules.find((f) => f.name === 'lineItems')?.value as any[])
      ? (fieldsFromRules.find((f) => f.name === 'lineItems')?.value as any[])
          .filter((v) => v.selected)
          .reduce(
            (accum, item) =>
              accum +
              parseFloat(item.unitPrice?.toString() || '0') *
                parseFloat(item.quantity?.toString() || '0'),
            0
          )
      : 0;

  const getPORules: () => any = () => {
    if (draftInvoiceModal) {
      setFieldsFromRules(draftInvoiceModal);
    } else {
      const oaProgramOnPoId = po.linkedPrograms.find((p) =>
        p.baseType.includes(ProgramType.OPEN_ACCOUNT)
      )?.programId;

      if (!oaProgramOnPoId) throw Error('THERE ARE NO LINKED OA PROGRAMS ON THE PO.');

      const matchedPOProgram = allProgramsWithDetails.find((p) => p.id === oaProgramOnPoId);

      if (!matchedPOProgram)
        throw Error('THERE ARE NO OA PROGRAM RULES FOR LOGISTICS CONFIGURED IN THE PROGRAM');

      const clone: ProgramConfig = structuredClone<ProgramConfig>(matchedPOProgram);
      const invoiceRules: IInvoiceRule | undefined = clone.rules?.find(
        (rule) => rule.type === ProgramRuleTypeEnum.OA_INVOICE_TEMPLATE_CONFIGURATION
      )?.value;

      if (!invoiceRules) throw Error('THERE ARE NO RULES FOR INVOICE CONFIGURED IN THE PROGRAM');

      const newObj: IInvoiceRule = Object.assign({}, invoiceRules);
      newObj.fields = filterMandatoryData(invoiceRules.fields);

      setFieldsFromRules(newObj.fields);
    }

    setLoading(false);
  };

  const filterMandatoryData: (
    data: ILogisticsRuleSubtemplateField[]
  ) => ILogisticsRuleSubtemplateField[] = (data) => {
    // eslint-disable-next-line array-callback-return
    return data.filter((f) => {
      if (
        f.name === 'createdBy' ||
        // f.name === 'creditNoteFlag' ||
        // f.name === 'uploadingTime' ||
        // f.name === 'versionDate' ||
        f.name === 'createdDate' ||
        f.name === 'paymentTerms' ||
        f.name === 'poNumber' ||
        f.name === 'supplierReference' ||
        f.name === 'buyerReference' ||
        f.name === 'unitPrice'
      )
        return f;
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length === 0)
        return f;
      if (f.constraint === 'MANDATORY' && f.name !== 'subTemplate' && f.nestedFields.length > 0) {
        f.nestedFields = filterMandatoryData(f.nestedFields);
        return f;
      }
    });
  };

  const renderFields: (
    fieldsTemplate: ILogisticsRuleSubtemplateField[],
    parentReferencePath?: string,
    index?: number
  ) => JSX.Element[] = (fieldsTemplate, parentReferencePath, index) => {
    return fieldsTemplate.map((f, i) => {
      f.referencePath = parentReferencePath ? `${parentReferencePath}.${f.name}` : `${f.name}`;
      if (f.type === 'OBJECT') {
        return (
          <div
            key={f.name}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '32px',
              padding: '0 0 16px 0',
              borderTop: `1px solid ${themeColors.text.light.muted}`,
              borderBottom: index === undefined ? `1px solid ${themeColors.text.light.muted}` : '',
              marginTop: index === undefined ? '16px' : '',
              gap: index === undefined ? 0 : '16px'
            }}
          >
            <h5
              style={{
                padding: '16px 0',
                backgroundColor: index === undefined ? themeColors.mono5 : ''
              }}
            >
              {f.displayName}
            </h5>
            {renderFields(f.nestedFields, f.referencePath, i)}
          </div>
        );
      }

      if (f.type === 'ARRAY') {
        f.nestedFields = configureArrayTypeReferencePaths(f.nestedFields, f.referencePath, i);
      }

      if (f.value === undefined) f.value = setValueFromPO(f);

      if (f.name === 'createdDate') f.value = new Date();
      if (f.type === 'BOOLEAN' && f.value === undefined) f.value = false;
      if (f.type === 'DOUBLE' && (f.value === undefined || f.value === null)) f.value = 0;

      return renderField(f);
    });
  };

  const setValueFromPO: (field: ILogisticsRuleSubtemplateField) => string = (field) => {
    if (!po || !field.referencePath) return '';
    const name: string = PurchaseOrderInvoiceFlipDictionary[field.referencePath];

    let dataFromPO = _.get(po.data, name);

    if (field.name === 'createdBy') dataFromPO = registeredName || '';

    if (field.name === 'lineItems') {
      const requiredFields: string[] = [...field.nestedFields.map((f) => f.name)];

      dataFromPO = (dataFromPO as LedgerLineItem[]).map((o) => {
        const newObj = Object.assign({});

        newObj['excluded'] = o.lineItemPrincipalStatus === 'FULFILLED';

        requiredFields.forEach((r) => {
          const rule = field.nestedFields.find((f) => f.name === r);
          const reference = rule?.referencePath?.slice();
          const p = reference?.split('.');

          p?.shift();

          if (rule?.type !== 'OBJECT') {
            if (r === 'selected') {
              _.set(newObj, 'selected', true);
            } else {
              if (r === 'poLineItemNumber') {
                _.set(newObj, 'poLineItemNumber', o.lineItemNumber);
              } else {
                _.set(newObj, p?.join('.') || '', o[p?.join('.') || '']);
              }
            }
          }

          if (rule?.type === 'OBJECT') {
            _.set(newObj, p?.join('.') || '', {});

            const populateNestedData = (
              fields: ILogisticsRuleSubtemplateField[],
              fieldName: string,
              data: LedgerLineItem,
              obj: any
            ) => {
              fields.forEach((f) => {
                const subObjReference = f?.referencePath?.slice();
                const subObjPath = subObjReference?.split('.');

                subObjPath?.shift();

                if (f.type === 'OBJECT') {
                  if (subObjPath?.join('.')) _.set(newObj, subObjPath?.join('.'), {});

                  const fieldNameClone = f.name.slice();

                  populateNestedData(
                    f.nestedFields,
                    fieldNameClone,
                    o,
                    obj[subObjPath?.join('.') || '']
                  );
                } else {
                  _.set(
                    newObj,
                    subObjPath?.join('.') || '',
                    data[subObjPath?.join('.') || ''] || ''
                  );
                }
              });
            };
            const matchedObjectRule = field.nestedFields.find(
              (nfr) => nfr.name === p?.join('.') || ''
            );

            if (matchedObjectRule)
              populateNestedData(
                matchedObjectRule.nestedFields,
                p?.join('.') || '',
                o,
                newObj[p?.join('.') || '']
              );
          }
        });

        //Prepopulate values here
        newObj['poNumber'] = po.data.poNumber;
        newObj['selected'] = newObj.excluded || newObj.disabled ? false : true;
        return newObj;
      });
    }

    return dataFromPO;
  };

  const configureArrayTypeReferencePaths: (
    fieldsTemplate: ILogisticsRuleSubtemplateField[],
    parentReferencePath?: string,
    index?: number
  ) => ILogisticsRuleSubtemplateField[] = (fieldsTemplate, parentReferencePath, index) => {
    return fieldsTemplate.map((t) => {
      t.referencePath = parentReferencePath ? `${parentReferencePath}.${t.name}` : `${t.name}`;
      if (t.type === 'OBJECT' || t.type === 'ARRAY')
        configureArrayTypeReferencePaths(t.nestedFields, t.referencePath, index);

      return t;
    });
  };

  const updateHandler: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (value, fieldName, referencePath, fieldsData) => {
    setFieldsFromRules(updateFieldValueFromInput(value, fieldName, referencePath, fieldsData));
  };

  const updateFieldValueFromInput: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => ILogisticsRuleSubtemplateField[] = (value, fieldName, referencePath, fieldsData) => {
    const mutArr = [...fieldsData];
    return mutArr.map((f, i) => {
      if (f.type === 'OBJECT')
        updateFieldValueFromInput(value, fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.value = value;

      return f;
    });
  };

  const isInvoiceNumberPopulated: () => boolean = () => {
    const rule = fieldsFromRules.find((r) => r.name === 'invoiceNumber');

    if (!rule) return false;

    return Boolean(rule.value);
  };

  const renderField: (field: ILogisticsRuleSubtemplateField) => JSX.Element = (field) => {
    switch (field.type) {
      case 'STRING':
        return (
          <TextInput
            testingTagPage="ledger-invoice-logistics-flip"
            key={field.name}
            label={field.displayName}
            disabled={
              field.name === 'buyerReference' ||
              field.name === 'buyerName' ||
              field.name === 'supplierReference' ||
              field.name === 'supplierName'
            }
            defaultValue={field.value as string}
            tooltipText={field.description}
            register={
              field.name !== 'invoiceNumber' ? register(field.name, { required: true }) : {}
            }
            errorMessage={
              field.name !== 'invoiceNumber'
                ? errors[field.name]
                  ? `${field.displayName} is a mandatory field`
                  : ''
                : !isInvoiceNumberPopulated()
                ? `${field.displayName} is a mandatory field`
                : ''
            }
            required={field.name === 'invoiceNumber' ? !isInvoiceNumberPopulated() : true}
            changeHandler={(e) =>
              updateHandler(e.target.value, field.name, field.referencePath, fieldsFromRules)
            }
          />
        );
      case 'BOOLEAN':
        return (
          <CheckboxFieldRow key={field.name}>
            <CheckboxFieldText data-automation-id="ledger-invoice-logistics-flip-p-checkbox-message">
              {field.displayName}
            </CheckboxFieldText>
            <CheckboxStyled
              checked={field.value as boolean}
              onChange={(e) =>
                updateHandler(e.target.checked, field.name, field.referencePath, fieldsFromRules)
              }
              data-testid="sp-checkbox-item"
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'sp-clickable-checkbox'
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              data-automation-id={`logistics-artifact-form-span-checkbox-${field.name
                .split(' ')
                .join('')}`}
            />
          </CheckboxFieldRow>
        );
      case 'DATE':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                field.value ? (field.value as Date).toISOString() : '',
                'yyyy/MM/dd HH:mm'
              )}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(field.value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DATE_TIME':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                field.value ? (field.value as Date).toISOString() : '',
                'yyyy/MM/dd HH:mm'
              )}
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(field.value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              showTimeSelect
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DOUBLE':
        return (
          <TextInput
            key={field.name}
            type="number"
            defaultValue={field.value as string}
            placeholder={field.exampleValue}
            label={field.displayName}
            tooltipText={field.description}
            changeHandler={(e) =>
              updateHandler(
                removeNegative(e.target.value),
                field.name,
                field.referencePath,
                fieldsFromRules
              )
            }
            register={register(field.name, { required: true })}
            testingTagPage="ledger-invoice-logistics-flip"
          />
        );

      default:
        return <React.Fragment key={field.name} />;
    }
  };

  const renderLineItemField: (
    field: ILogisticsRuleSubtemplateField,
    values: any[],
    index: number,
    parentName?: string,
    parentId?: string
  ) => JSX.Element = (field, values, index, parentName, parentId) => {
    let level: number = 0;

    const mutReference = field.referencePath?.slice().split('.') || [];

    mutReference.shift();

    switch (field.type) {
      case 'STRING':
        return (
          <TextInput
            testingTagPage="ledger-invoice-logistics-flip"
            key={field.referencePath}
            disabled={
              !values[index].selected ||
              field.name === 'sku' ||
              field.name === 'poNumber' ||
              field.name === 'invoiceNumber' ||
              field.name === 'unitsOfMeasure'
            }
            label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            defaultValue={_.get(values[index], mutReference) as string}
            tooltipText={field.description}
            placeholder={field.exampleValue}
            errorMessage={values[index].selected ? lineItemErrorMessage(field, values, index) : ''}
            required={values[index].selected ? true : field.name !== 'poNumber'}
            changeHandler={(e) =>
              updateLineItemHandler(
                e.target.value,
                field.name,
                field.referencePath,
                fieldsFromRules,
                index
              )
            }
          />
        );
      case 'BOOLEAN':
        return (
          <CheckboxFieldRow key={field.referencePath}>
            <CheckboxFieldText data-automation-id="ledger-invoice-logistics-flip-p-checkbox-message">
              {parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            </CheckboxFieldText>
            <CheckboxStyled
              checked={!!field.value}
              onChange={(e) =>
                updateLineItemHandler(
                  e.target.checked,
                  field.name,
                  field.referencePath,
                  fieldsFromRules,
                  index
                )
              }
              data-testid="sp-checkbox-item"
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'sp-clickable-checkbox'
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              data-automation-id="ledger-create-invoices-detail-span-checkbox-administrator"
            />
          </CheckboxFieldRow>
        );
      case 'DATE':
        return (
          <CreateInvoiceDetailDateSegment key={field.referencePath}>
            <TextInput
              label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                ((_.get(values[index], mutReference) as Date) || new Date()).toISOString(),
                'yyyy/MM/dd'
              )}
              errorMessage={
                values[index].selected ? lineItemErrorMessage(field, values, index) : ''
              }
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(_.get(values[index], mutReference) as Date) || new Date()}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DATE_TIME':
        return (
          <CreateInvoiceDetailDateSegment key={field.name}>
            <TextInput
              label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
              tooltipText={field.description}
              defaultValue={formatDateTime(
                ((values[index][field.name] as Date) || new Date()).toISOString(),
                'yyyy/MM/dd HH:mm'
              )}
              errorMessage={
                values[index].selected ? lineItemErrorMessage(field, values, index) : ''
              }
              onClick={() =>
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules)
              }
              testingTagPage="ledger-invoice-logistics-flip"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              open={field.isOpen}
              selected={(values[index][field.name] as Date) || new Date()}
              onChange={(date) => {
                updateFieldValueFromInput(date, field.name, field.referencePath, fieldsFromRules);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(field.name, field.referencePath, fieldsFromRules);
              }}
              showTimeSelect
            />
          </CreateInvoiceDetailDateSegment>
        );
      case 'DOUBLE':
        return (
          <TextInput
            key={field.name}
            type="number"
            defaultValue={_.get(values[index], mutReference) as string}
            placeholder={field.exampleValue}
            label={parentName ? `${parentName} - ${field.displayName}` : field.displayName}
            tooltipText={field.description}
            changeHandler={(e) =>
              updateLineItemHandler(
                removeNegative(e.target.value),
                field.name,
                field.referencePath,
                fieldsFromRules,
                index
              )
            }
            errorMessage={values[index].selected ? lineItemErrorMessage(field, values, index) : ''}
            testingTagPage="ledger-invoice-logistics-flip"
          />
        );
      case 'OBJECT':
        level = level + 1;
        if (parentName)
          field.displayName =
            parentName === undefined ? field.displayName : `${parentName} - ${field.displayName}`;

        if (parentId)
          field.referencePath = parentId === undefined ? field.name : `${parentId}.${field.name}`;

        if (field.displayName.split(' - ').length > 2)
          field.displayName = field.displayName.split(' - ').slice(-2).join(' - ');

        return (
          <>
            {field.nestedFields.map((f: ILogisticsRuleSubtemplateField, i: number) =>
              renderLineItemField(f, values, index * level, field.displayName, parentId)
            )}
          </>
        );

      default:
        return <React.Fragment key={field.name} />;
    }
  };

  const updateLineItemHandler: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[],
    index: number
  ) => void = (value, fieldName, referencePath, fieldsData, index) => {
    const mutArr = [...fieldsFromRules];
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined = mutArr.find(
      (r) => r.name === 'lineItems'
    );

    if (!lineItemsRule) return;

    const mutReferencePath = referencePath?.slice().split('.');
    mutReferencePath?.shift();

    if (lineItemsRule.value)
      _.set((lineItemsRule.value as any[])[index], mutReferencePath || '', value);

    setFieldsFromRules(mutArr);

    setLineItemsMandatoryFieldsComplete(isLineItemMandatoryDataSupplied(mutArr));
  };

  const isLineItemMandatoryDataSupplied: (data: ILogisticsRuleSubtemplateField[]) => boolean = (
    data
  ) => {
    const lineItems = data.find((d) => d.name === 'lineItems');

    if (!lineItems) return false;

    const completedArr: (data: any) => string[] = (data) => {
      const keys: string[] = Object.keys(data);
      return keys.flatMap((key) => {
        if (data[key] === null || data[key] === '') return 'false';
        if (typeof data[key] === 'object') return completedArr(data[key]);
        return 'true';
      });
    };

    return !completedArr((lineItems.value as any[])?.filter((v) => v.selected)).includes('false');
  };

  const isCompleted: (data: ILogisticsRuleSubtemplateField[]) => boolean = (data) => {
    if (!data || data.length === 0) {
      return false;
    }
    for (const field of data) {
      if (field.type !== 'ARRAY' && field.name !== 'sku' && field.name !== 'poNumber') {
        if (field.type === 'OBJECT') {
          return isCompleted(field.nestedFields);
        } else {
          if (field.value === null || field.value === undefined || field.value === '') {
            return false;
          }
        }
      }
    }

    return true;
  };

  const lineItemErrorMessage: (
    field: ILogisticsRuleSubtemplateField,
    values: any[],
    index: number
  ) => string = (field, values, index) => {
    if (field.name === 'poNumber') return '';
    const mutReference = field.referencePath?.slice().split('.') || [];

    mutReference.shift();

    return (_.get(values[index], mutReference) as string)
      ? ''
      : `${mutReference.map((r) => toSentenceCase(r)).join(' - ')} is a mandatory field`;
  };

  const controlledLineItemErrorMessage: (field: string, index: number) => string = (
    field,
    index
  ) => {
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined = fieldsFromRules.find(
      (r) => r.name === 'lineItems'
    );
    // const mutReference = field.referencePath?.slice().split('.') || [];

    if (
      (lineItemsRule?.value as any)[index][field] === '' ||
      (lineItemsRule?.value as any)[index][field] === undefined ||
      (lineItemsRule?.value as any)[index][field] === null
    )
      return `${toSentenceCase(field)} is a mandatory field`;

    return '';

    // mutReference.shift();

    // return (_.get(values[index], mutReference) as string)
    //   ? ''
    //   : `${mutReference.map((r) => toSentenceCase(r)).join(' - ')} is a mandatory field`;
  };

  const renderInvoiceNumberJSX: () => JSX.Element = () => {
    const field = fieldsFromRules.find((f) => f.name === 'invoiceNumber');
    if (!field) return <></>;
    return (
      <CreateInvoiceDialogInvoiceDetailsContainer data-automation-id="create-invoice-dialog-div-invoice-detail-container">
        <CreateInvoiceDialogInvoiceDetailsRow>
          <CreateInvoiceDialogInvoiceDetailWrapper>
            {renderField(field)}
          </CreateInvoiceDialogInvoiceDetailWrapper>
        </CreateInvoiceDialogInvoiceDetailsRow>
      </CreateInvoiceDialogInvoiceDetailsContainer>
    );
  };

  const updatePickerOpenValue: (
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (fieldName, referencePath, fieldsData) => {
    const updatedFields = [...fieldsData].map((f, i) => {
      if (f.type === 'OBJECT') updatePickerOpenValue(fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.isOpen = !f.isOpen;

      return f;
    });
    setFieldsFromRules(updatedFields);
  };

  const renderLineItemsInputFields: (lineItem: any, index: number) => JSX.Element[] = (
    lineItem,
    index
  ) => {
    const lineItemsRule: ILogisticsRuleSubtemplateField | undefined = fieldsFromRules.find(
      (r) => r.name === 'lineItems'
    );

    if (!lineItemsRule) return [];

    const keysFromRule = lineItemsRule.nestedFields.map((f) => f.name);

    let newObj = {};

    keysFromRule.forEach((k) => {
      newObj = { ...newObj, [k]: lineItem[k] };
    });

    const fields = lineItemsRule.nestedFields
      .filter(
        (field) =>
          field.name !== 'quantity' &&
          field.name !== 'lineItemNumber' &&
          field.name !== 'unitOfMeasure' &&
          field.name !== 'unitPrice' &&
          field.name !== 'amount'
      )
      .map((n) => renderLineItemField(n, lineItemsRule.value as any[], index));

    return fields;
  };

  const headerCheckboxClickHandler: () => void = () => {
    setHeaderCheckbox({ indeterminate: false, checked: !headerCheckbox.checked });
    const mutRules = [...fieldsFromRules];
    const lineItemsRule = mutRules.find((r) => r.name === 'lineItems');

    if (!lineItemsRule) return;

    (lineItemsRule.value as any[]).forEach((v) => (v.selected = !headerCheckbox.checked));

    setFieldsFromRules(mutRules);
  };

  const rowCheckboxClickHandler: (lineItem: any) => void = (lineItem) => {
    const mutRules = [...fieldsFromRules];
    const lineItemsRule = mutRules.find((r) => r.name === 'lineItems');

    if (!lineItemsRule) return;

    const matchedValue = (lineItemsRule.value as any[]).find(
      (v) => v.lineItemNumber === lineItem.lineItemNumber
    );

    if (!matchedValue || matchedValue.excluded) return;

    matchedValue.selected = !matchedValue.selected;

    const checkForAllState: boolean =
      (lineItemsRule.value as any[]).filter((r) => r.selected === matchedValue.selected).length ===
      (lineItemsRule.value as any[]).length;

    if (checkForAllState)
      setHeaderCheckbox({ indeterminate: false, checked: matchedValue.selected });

    setFieldsFromRules(mutRules);
  };

  const toggleShowMoreClickHandler: (index: number) => void = (index) => {
    let openCopy: number[];
    if (open.includes(index)) {
      openCopy = open.filter((element) => {
        return element !== index;
      });
      setOpen(openCopy);
    } else {
      openCopy = [...open];
      openCopy.push(index);
      setOpen(openCopy);
    }
  };

  const lineItemChangeHandler: (value: any, fieldName: string, index: number) => void = (
    value,
    fieldName,
    index
  ) => {
    const mutArr = [...fieldsFromRules];
    const lineItems = mutArr.find((r) => r.name === 'lineItems');

    if (!lineItems) return;

    (lineItems.value as any[])[index][fieldName] = value;
    setFieldsFromRules(mutArr);
  };

  const calculateLineItemTotalPrice = (data: LedgerLineItem): string => {
    const totalPrice =
      parseFloat(data.unitPrice?.toString() || '0') * parseFloat(data.quantity?.toString() || '0');
    return formatNumber(totalPrice, 2);
  };

  const renderTableBody: () => JSX.Element = () => {
    return (
      <tbody>
        {((fieldsFromRules.find((r) => r.name === 'lineItems')?.value as any[]) || []).map(
          (d, index) => (
            <React.Fragment key={`${index}-fragment`}>
              <tr
                key={`${index}-lineItem`}
                style={{
                  borderBottom: `${open.includes(index) ? 'none' : '1px solid #E4E4E4'}`
                }}
              >
                <td>
                  {!d.excluded ? (
                    <Checkbox
                      style={{
                        color:
                          d.excluded || d.disabled
                            ? themeColors.text.light.muted
                            : themeColors.text.primary,
                        backgroundColor: 'transparent'
                      }}
                      disabled={d.excluded || d.disabled}
                      checked={d.selected}
                      onChange={() => rowCheckboxClickHandler(d)}
                      inputProps={
                        {
                          role: 'checkbox',
                          'data-testid': 'sp-clickable-checkbox'
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  ) : (
                    <IconTooltip tooltipText="Line item has a status of 'fulfilled' and can no longer by included in flip." />
                  )}
                </td>
                <td
                  className="accordion-row"
                  onClick={() => toggleShowMoreClickHandler(index)}
                  data-testid="sp-create-invoice-show-more"
                >
                  <CreateInvoiceDialogShowMoreContainer>
                    <CreateInvoiceDialogDescriptionDetail>
                      <TextInput
                        defaultValue={d.lineItemNumber}
                        disabled={!d.selected || d.excluded || d.disabled}
                        placeholder="1"
                        changeHandler={(event) =>
                          lineItemChangeHandler(event.target.value, 'lineItemNumber', index)
                        }
                        errorMessage={controlledLineItemErrorMessage('lineItemNumber', index)}
                        required
                      />
                    </CreateInvoiceDialogDescriptionDetail>
                    {open.includes(index) ? <HideMoreIcon /> : <ShowMoreIcon />}
                  </CreateInvoiceDialogShowMoreContainer>
                </td>
                <td className="accordion-row">
                  <TextInput
                    defaultValue={d.quantity}
                    disabled={!d.selected || d.excluded || d.disabled}
                    type="number"
                    placeholder="0"
                    changeHandler={(event) =>
                      lineItemChangeHandler(removeNegative(event.target.value), 'quantity', index)
                    }
                    errorMessage={controlledLineItemErrorMessage('quantity', index)}
                    required
                    // register={register(`${index}-lineItems/quantity`, {
                    //   required: checkMandatoryField('quantity') && !d.excluded
                    // })}
                    // error={
                    //   checkMandatoryField('quantity')
                    //     ? getLineItemsFieldData(index, 'quantity') === '' ||
                    //       getLineItemsFieldData(index, 'quantity') === null
                    //     : false
                    // }
                    // testingTagPage="create-invoice-dialog"
                  />
                </td>

                <td className="accordion-row">
                  <TextInput
                    defaultValue={d.unitOfMeasure === 0 ? '' : d.unitOfMeasure}
                    disabled={true}
                    placeholder=""
                    changeHandler={(event) =>
                      lineItemChangeHandler(event.target.value, 'unitOfMeasure', index)
                    }
                    testingTagPage="create-invoice-dialog"
                  />
                </td>
                <td className="accordion-row">
                  <TextInput
                    defaultValue={d.unitPrice}
                    disabled={!d.selected || d.excluded || d.disabled}
                    placeholder="0.00"
                    type="number"
                    changeHandler={(event) =>
                      lineItemChangeHandler(removeNegative(event.target.value), 'unitPrice', index)
                    }
                    errorMessage={controlledLineItemErrorMessage('unitPrice', index)}
                    required
                    // register={register(`${index}-lineItems/unitPrice`, {
                    //   required: checkMandatoryField('unitPrice') && !d.excluded
                    // })}
                    // error={
                    //   checkMandatoryField('unitPrice')
                    //     ? getLineItemsFieldData(index, 'unitPrice') === '' ||
                    //       getLineItemsFieldData(index, 'unitPrice') === null
                    //     : false
                    // }
                    // testingTagPage="create-invoice-dialog"
                  />
                </td>
                <td className="accordion-row text-align-right">
                  {CurrencySymbolsLookUp[po.data?.currency || '']}
                  {calculateLineItemTotalPrice(d)}
                </td>
              </tr>
              {open.includes(index) && (
                <tr aria-colspan={2}>
                  <td></td>
                  <td className="accordion-row text-align-right more-detail">
                    <Collapse in={open.includes(index)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          textAlign: 'start',
                          paddingBottom: '16px'
                        }}
                      >
                        {renderLineItemsInputFields(d, index)}
                      </div>
                    </Collapse>
                  </td>
                </tr>
              )}
            </React.Fragment>
          )
        )}
      </tbody>
    );
  };

  const renderLineItems: () => JSX.Element = () => (
    <CreateInvoiceDialogLineItemsContainer>
      <CreateInvoiceDialogLineItemsHeader data-automation-id="create-invoice-dialog-div-header">
        {/* <CreateInvoiceDialogTitleBold>
          {data ? `${selectedLineItems.length} of ${data.length}` : '0'} item
          {data && data.length > 1 && 's'} selected
        </CreateInvoiceDialogTitleBold> */}
      </CreateInvoiceDialogLineItemsHeader>
      <CreateInvoiceDialogLineItemsErrorMessage>
        {/* {selectedLineItems.length === 0 && 'Please select at least one line item.'} */}
        {!lineItemsMandatoryFieldsComplete && (
          <p style={{ color: themeColors.text.error, marginBottom: '16px' }}>
            * Some mandatory information has not been supplied. You may need to expand the row to
            provide this information.
          </p>
        )}
      </CreateInvoiceDialogLineItemsErrorMessage>
      <CreateInvoiceDialogTable>
        <GroupTableContainer>
          <GroupedTable data-automation-id="create-invoice-dialog-table-grouped-invoice-table">
            <thead style={{ textAlign: 'left' }}>
              <tr>
                <th style={{ width: '2%' }}>
                  <Checkbox
                    style={{
                      color: themeColors.text.primary,
                      backgroundColor: 'transparent',
                      zIndex: 10
                    }}
                    {...headerCheckbox}
                    onChange={() => headerCheckboxClickHandler()}
                    inputProps={
                      {
                        'data-testid': 'sp-clickable-header-checkbox'
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                    data-testid="sp-header-checkbox"
                  />
                </th>
                <th style={{ width: '30%' }}>LINE ITEM NUMBER</th>
                <th style={{ width: '12%' }}>QTY</th>
                <th style={{ width: '11%' }}>UNIT OF MEASURE</th>
                <th style={{ width: '12%' }}>UNIT PRICE</th>
                <th style={{ width: '23%' }}>AMOUNT</th>
              </tr>
            </thead>
            {renderTableBody()}
          </GroupedTable>
        </GroupTableContainer>
      </CreateInvoiceDialogTable>

      <CreateInvoiceDialogSummaryContainer data-automation-id="create-invoice-dialog-div-summary-container">
        <CreateInvoiceDialogSummaryTitle data-automation-id="create-invoice-dialog-h4-summary-title">
          Summary
        </CreateInvoiceDialogSummaryTitle>
        <CreateInvoiceDialogSummaryInfo>
          <CreateInvoiceDialogTitleRegular data-automation-id="create-invoice-dialog-p-total-extended-amount-title">
            Total amount
          </CreateInvoiceDialogTitleRegular>
          <CreateInvoiceDialogTitleBold data-automation-id="create-invoice-dialog-p-total-extended-amount-value">
            {po.data.currency ? CurrencySymbolsLookUp[po.data.currency] : ''}
            {formatNumber(subtotalAmount() || 0, 2)}
          </CreateInvoiceDialogTitleBold>
        </CreateInvoiceDialogSummaryInfo>
      </CreateInvoiceDialogSummaryContainer>
    </CreateInvoiceDialogLineItemsContainer>
  );

  return (
    <LayoutViewContainer size="small" margin="0 auto" data-testid="create-invoice-dialog">
      {!loading ? (
        <form id="create-invoice-form">
          <CreateInvoiceDialogContainer>
            <CreateInvoiceDialogTitleWrapper>
              <CreateInvoiceDialogTitle data-automation-id="create-invoice-dialog-h2-page-title">
                Create a new invoice
              </CreateInvoiceDialogTitle>
            </CreateInvoiceDialogTitleWrapper>
            {invoiceNumberJSX ? invoiceNumberJSX : <></>}
            {lineItemsJSX ? lineItemsJSX : <></>}
            {/* <CreateInvoiceDialogInvoiceDetailCommentsWrapper>
          <CreateInvoiceDialogInvoiceDetailTitle>
            Comments (optional)
          </CreateInvoiceDialogInvoiceDetailTitle>

          <OutlinedInput
            id="invoice-create-invoice-details"
            dataTestId="invoice-create-invoice-details"
            // defaultValue={initialDataModal?.comments || ''}
            // changeHandler={(event) => updateInvoiceDataModel('comments', event.target.value)}
            width="100%"
            multiline
            minHeight="36px"
            height="100%"
          />
        </CreateInvoiceDialogInvoiceDetailCommentsWrapper> */}
            <CreateInvoiceDialogActionContainer>
              <CreateInvoiceDialogActionButtonContainer>
                <PrimaryButton
                  clickHandler={savedraftInvoice}
                  testingTag="create-invoice-dialog-button-send-request"
                  disabled={
                    !hasMinimumLineItems ||
                    !lineItemsMandatoryFieldsComplete ||
                    !mandatoryFieldsComplete
                  }
                  // disabled={!checkEmptyFields() || selectedLineItems.length === 0}
                  text="Next"
                />
              </CreateInvoiceDialogActionButtonContainer>
              <CreateInvoiceDialogActionButtonContainer>
                <SecondaryButton
                  clickHandler={draftInvoiceModal ? saveDialog : closeHandler}
                  text="Cancel"
                  testingTag="create-invoice-dialog-button-cancel"
                />
              </CreateInvoiceDialogActionButtonContainer>
            </CreateInvoiceDialogActionContainer>
          </CreateInvoiceDialogContainer>
        </form>
      ) : (
        <LoaderFullPage />
      )}
    </LayoutViewContainer>
  );
};

export default FirstPoFlipDialog;
