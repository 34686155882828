import {
  UPDATE_REQUEST_ERROR,
  UPDATE_REQUEST_ERROR_APP_TERMINATE,
  UPDATE_REQUEST_ERROR_CODE
} from 'store/actions';
import { ReducerAction } from '.';

interface ErrorReducerStateProps {
  error: string;
  terminationError: string | null;
  errorCode: number | null;
}

const DEFAULT_STATE: ErrorReducerStateProps = {
  error: '',
  terminationError: null,
  errorCode: null
};

const errorReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case UPDATE_REQUEST_ERROR:
      return {
        ...state,
        error: payload
      };
    case UPDATE_REQUEST_ERROR_APP_TERMINATE:
      return {
        ...state,
        terminationError: payload
      };
    case UPDATE_REQUEST_ERROR_CODE:
      return {
        ...state,
        errorCode: payload
      };
    default:
      return state;
  }
};

export { errorReducer };
export type { ErrorReducerStateProps };
