
import Step from '@mui/material/Step';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface PanelType {
  panel?: 'small' | 'large';
  display?: boolean;
}

export const WelcomeGuidancePanelWrapper =styled.div<PanelType>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const WelcomeGuidancePanelContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  width: 600px;
  min-height: 300px;
  height: auto;
  padding: 20px;
  bottom: 0;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: ${themeColors.white};
  box-shadow: 0px 12px 24px -4px rgba(24, 39, 75, 0.04), 0px 6px 10px -6px rgba(24, 39, 75, 0.12);
`;

export const WelcomeGuidancePanelTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WelcomeGuidancePanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WelcomeGuidancePanelContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WelcomeGuidancePanelContentDescription = styled.p`
  ${typography.bodyRegular};
  margin: 0;
`;

export const WelcomeGuidancePanelContentVideo = styled.div`
  display: flex;
`;

export const WelcomeGuidancePanelContentLink = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.primary};
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
`;

export const WelcomeGuidancePanelFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const WelcomeGuidancePanelSkipLink = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.secondary};
  cursor: pointer;
`;

export const WelcomeGuidancePanelButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const WelcomeGuidancePanelTitle = styled.h4`
  margin: 0;
`;

export const QontoStepIconRoot = styled.div<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: themeColors.text.light.muted,
    display: 'flex',
    width: '6px',
    height: '6px',
    alignItems: 'center',
    ...(ownerState.active && {
      color: themeColors.royalGold,
      width: '25px',
      height: '6px',
    }),
    '& .QontoStepIcon-circle': {
      color: themeColors.royalGold,
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    }
  })
);

export const StepContainer = styled(Step)`
  &.MuiStep-horizontal {
    padding-left: 0!important;
    padding-right: 0!important;
    flex: none!important;
  }

  .MuiStepConnector-line {
    display: none;
  }
`;



