import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import React, { FC } from 'react';
import { LedgerDetailAttachmentsUploadDialogContainer } from './styled';
import Uploader from 'components/common/Uploader';
import { PrimaryButton } from 'components/common/buttons';
import { themeColors } from 'assets/theme/style';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import BaseCard from 'components/common/cards/BaseCard';

interface ILedgerDetailAttachmentsUploadDialogProps {
  headerJSX: JSX.Element;
  fileHandler: (acceptedFiles: File[]) => void;
  addFilesButtonClickHandler: () => void;
  submitting: boolean;
  setUploadInvalid: (isValid: boolean) => void;
  uploadInvalid: boolean;
  filesLength: number;
}

const LedgerDetailAttachmentsUploadDialog: FC<ILedgerDetailAttachmentsUploadDialogProps> = ({
  headerJSX,
  fileHandler,
  addFilesButtonClickHandler,
  submitting,
  setUploadInvalid,
  uploadInvalid,
  filesLength
}) => {
  return (
    <LedgerDetailAttachmentsUploadDialogContainer>
      <LayoutViewContainer size="small" minWidth="566px">
        {headerJSX}
        <Uploader
          multiple
          title="Drag files here"
          acceptedFileTypes={['jpg', 'jpeg', 'png', 'pdf']}
          limitBytes={2000000}
          fileDropHandler={fileHandler}
          selectedSimple
          isInvalidCallback={(isInvalid) => setUploadInvalid(isInvalid)}
        />
        {!submitting && (
          <PrimaryButton
            clickHandler={addFilesButtonClickHandler}
            testingTag={`ledger-detail-upload-document-dialog-button-file-upload-choose-file`}
            width="105px"
            text="Add"
            disabled={filesLength === 0 || uploadInvalid}
          />
        )}
        {submitting && (
          <>
            <LoaderInPage />
            {<p style={{ textAlign: 'center' }}>Submitting your attachments...</p>}
          </>
        )}
      </LayoutViewContainer>
    </LedgerDetailAttachmentsUploadDialogContainer>
  );
};

export default LedgerDetailAttachmentsUploadDialog;
