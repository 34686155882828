import styled from 'styled-components';
import { styling, boxShadow } from 'assets/theme/style';

export const TenantSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;

export const TenantCard = styled.div`
  height: 180px;
  width: 180px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  box-shadow: ${boxShadow};
  border-radius: ${styling.defaultRadius};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 18px -6px rgba(130, 213, 227, 0.12),
      0px 12px 42px -4px rgba(130, 213, 227, 0.12);
  }
`;
