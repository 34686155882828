import StoreBase from 'api/base/storeBase';
import {
  ProgramConfig,
  Programs
} from 'api/interfaces/program/program.interface';
import { AxiosResponse } from 'axios';
import { _MODULE_ID } from 'lib/constants/contants';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { UpdateProgramSupplierBankAccountRule } from 'utils/classes/program/rules';

const _PATH: string = '/program/SP';
const NO_SP_PATH: string = '/program';

export default class ProgramApi extends StoreBase {
  public getPrograms: () => Promise<AxiosResponse<Programs>> = async () => {
    return await this.axiosInstance.get<Programs>(`${_PATH}?size=200`);
  };

  public getProgram: (programId: string) => Promise<AxiosResponse<ProgramConfig>> = async (
    programId
  ) => await this.axiosInstance.get<ProgramConfig>(`${_PATH}/${programId}`);

  public getProgramsByIds: (
    programIds: string[]
  ) => Promise<AxiosResponse<ProgramConfig[]>> = async (programIds) =>
    await this.axiosInstance.post<ProgramConfig[]>(
      `${NO_SP_PATH}/${_MODULE_ID}/by-ids`,
      programIds
    );

  public getProgramsByAssetType: (
    assetType: keyof typeof AssetEnum
  ) => Promise<AxiosResponse<any[]>> = async (assetType) =>
    await this.axiosInstance.get<any[]>(
      `${NO_SP_PATH}/${_MODULE_ID}/for-data-import?dataType=${assetType}`
    );

  public putProgram: (programId: string, data: any) => Promise<any> = async (programId, data) =>
    await this.axiosInstance.put(`${_PATH}/${programId}/draft/status`, data);

  public patchProgram: (
    programId: string,
    data: UpdateProgramSupplierBankAccountRule[]
  ) => Promise<AxiosResponse<any>> = async (programId, data) =>
    await this.axiosInstance.patch<any>(`${_PATH}/${programId}/draft`, {
      rules: [...data]
    });
}
