import LedgerDetail from 'components/ledger/LedgerDetail';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

export const logisticTabs: TabsItem[] = [
  {
    text: 'Logistics',
    children: <LedgerDetail type={AssetEnum.LOGISTIC} showEntities showSummaryAccordions />
  }
];
