import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ApproveIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.success,
  height = '24px',
  width = '24px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-approve-icon"
  >
    <ellipse cx="12" cy="12.411" rx="12" ry="12.411" fill={color} />
    <g clipPath="url(#clip0_2477_61659)">
      <path
        d="M9.50001 16.0049L6.02501 12.4109L4.84167 13.6262L9.50001 18.444L19.5 8.10156L18.325 6.88632L9.50001 16.0049Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2477_61659">
        <rect width="20" height="20.6849" fill="white" transform="translate(2 2.06846)" />
      </clipPath>
    </defs>
  </svg>
);

export default ApproveIcon;
