import { Dialog } from '@mui/material';
import { font } from 'assets/theme/typography';
import styled from 'styled-components';

interface IDialog {
  height?: string;
  width?: string;
}

export const AlertDialogDialogStyled = styled(Dialog)<IDialog>`
  ${font.body};
  ${font.heading};
  .MuiDialog-paper {
    padding: 24px;
    margin-bottom: 10px;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
  }
`;

export const AlertDialogTitle = styled.h4``;

export const AlertDialogText = styled.p``;

export const AlertDialogButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 24px;
`;
