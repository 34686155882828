import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import EntityApi from 'api/entity/entity.api';
import { EntityBankAccount, EntityOwner } from 'api/interfaces/entity/entity.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import ProgramApi from 'api/program/program.api';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT_SUCCESS,
  FETCH_PROGRAM_OWNER_DETAILS,
  FETCH_PROGRAM_OWNER_DETAILS_FAILURE,
  FETCH_PROGRAM_OWNER_DETAILS_SUCCESS,
  FETCH_PROOF_OF_ACCOUNT,
  FETCH_PROOF_OF_ACCOUNT_FAILURE,
  FETCH_PROOF_OF_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_COMPANY_LOGO,
  UPDATE_COMPANY_LOGO_FAILURE,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_PROGRAM_OWNER_DETAILS,
  UPDATE_PROGRAM_OWNER_DETAILS_FAILURE,
  UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS
} from 'store/actions';
import {
  CurrencyRule,
  getSupplierBankAccountCurrencyRules,
  UpdateProgramSupplierBankAccountRule
} from 'utils/classes/program/rules';
import _ from 'lodash';

interface OwnerDetails {
  ownerDetails: EntityOwner;
  ownerBankAccounts: any;
}

//===================================================
//                      API CALLS
//===================================================

export const fetchProgramOwnerDetails: (action: any) => Promise<OwnerDetails> = async (action) => {
  const entityApi = new EntityApi(store);
  const ownerBankAccountsByLatestVersions: any[] = [];
  const accessToken = action.payload;

  console.log('PROGRAM OWNER', action);

  const getOwnerResponse = await entityApi.getOwner(accessToken);

  if (!getOwnerResponse.data.contact.registeredName)
    throw Error('Company does not have a registered name.');

  const getOwnerBankAccountsResponse = await entityApi.getAllOwnerBankAccounts(accessToken);

  const grouped = _.groupBy(getOwnerBankAccountsResponse.data.content, 'bankAccountId');

  Object.keys(grouped).forEach((key) => {
    const latestVersionOfAccount = grouped[key].sort((a: any, b: any) =>
      a.version > b.version ? 1 : -1
    )[grouped[key].length - 1];
    ownerBankAccountsByLatestVersions.push(latestVersionOfAccount);
  });

  getOwnerResponse.data.companyLogo = null;

  return {
    ownerDetails: getOwnerResponse.data,
    ownerBankAccounts: ownerBankAccountsByLatestVersions
  };
};

export const updateProgramOwnerDetails: (action: any) => Promise<EntityOwner> = async (action) => {
  const entityApi = new EntityApi(store);
  const updateResponse = await entityApi.putOwnerDetails(action.payload);
  return updateResponse.data;
};

export const addNewBankAccount: (action: any) => Promise<EntityBankAccount> = async (action) => {
  const { app }: { app: any } = store.getState();
  const entityApi = new EntityApi(store);
  const programApi = new ProgramApi(store);
  const onboardingApi = new OnboardingApi();
  const { data, isDefault, identifier, programId, proof, existingBankAccount } = action.payload;
  const isOnboarding: boolean = !!action.payload?.isOnboarding;
  const config = {
    headers: {
      isCurrencyOverrideConfirmed: true
    }
  };
  let addNewBankAccountResponse;

  if (!existingBankAccount)
    addNewBankAccountResponse = await entityApi.postBankAccount(data, isDefault ? config : null);

  if (typeof addNewBankAccountResponse === 'string' && addNewBankAccountResponse.includes('ERROR'))
    throw Error(addNewBankAccountResponse);

  if (isOnboarding) {
    const formData = new FormData();

    const json: string = (action.payload.onboardingBankAccountData.data as FormData).get(
      'json'
    ) as string;
    const obj = JSON.parse(json);
    obj['bankAccountId'] =
      existingBankAccount?.bankAccountId || addNewBankAccountResponse.data.bankAccountId;
    formData.append('json', JSON.stringify(obj));

    await onboardingApi.postBankAccount(
      formData,
      action.payload.onboardingBankAccountData.artifactId
    );

    await postProofOfAccount(
      onboardingApi,
      proof,
      action.payload.onboardingBankAccountData.artifactId
    );

    await onboardingApi.putEntityOnboardingActionEventByProgram(
      OnboardingActionsEnum.bankAccount,
      new OnboardingActionEventPut(
        '',
        OnboardingActionStatusEnum.completed,
        OnboardingActionStatusEnum.initiated
      ),
      action.payload.onboardingActionEventData.programId
    );
    await onboardingApi.putArtifactReady(action.payload.onboardingBankAccountData.artifactId);
  }
  if (!isDefault || isOnboarding) return existingBankAccount || addNewBankAccountResponse.data;

  const preExistingCurrencyRules: CurrencyRule[] = getSupplierBankAccountCurrencyRules(
    app.program,
    identifier
  );

  const { currencies = [], bankAccountId = null } =
    existingBankAccount || addNewBankAccountResponse.data;

  if (currencies.length === 0 || !!!bankAccountId)
    throw new Error('Cannot add default currency as some information is missing');

  const rules: UpdateProgramSupplierBankAccountRule[] = [
    new UpdateProgramSupplierBankAccountRule(
      identifier,
      [new CurrencyRule(currencies[0].currency, bankAccountId)],
      preExistingCurrencyRules
    ).value
  ];

  await programApi.patchProgram(programId, rules);
  await programApi.putProgram(programId, { value: 'ACTIVE' });

  return existingBankAccount || addNewBankAccountResponse.data;
};

const postProofOfAccount: (
  onboardingApi: OnboardingApi,
  proof: Blob,
  artifactId: string
) => Promise<any> = (onboardingApi, proof, artifactId) => {
  const formData = new FormData();
  formData.append('file', proof);

  return new Promise(async (res, rej) => {
    const response = await onboardingApi.postBankAccount(formData, artifactId);
    res(response);
  });
};

export const updateBankAccount: (action: any) => Promise<any> = async (action) => {
  const entityApi = new EntityApi(store);
  const programApi = new ProgramApi(store);
  const { data, id, overrideDefaultCurrency, identifier, programId } = action.payload;
  const updateBankAccountResponse = await entityApi.patchBankAccount(
    data,
    id,
    overrideDefaultCurrency
  );

  const rules: UpdateProgramSupplierBankAccountRule[] = [
    new UpdateProgramSupplierBankAccountRule(identifier, [
      new CurrencyRule(
        updateBankAccountResponse.data.currencies[0].currency,
        updateBankAccountResponse.data.bankAccountId
      )
    ]).value
  ];

  if (overrideDefaultCurrency) {
    await programApi.patchProgram(programId, rules);
    await programApi.putProgram(programId, { value: 'ACTIVE' });
  }

  return updateBankAccountResponse.data;
};

export const updateCompanyLogo: (action: any) => Promise<any> = async (action) => {
  const entityApi = new EntityApi(store);
  const { data, id } = action.payload;
  const updateCompanyLogoResponse = await entityApi.putCompanyLogo(data, id);
  return updateCompanyLogoResponse.data;
};

export const fetchProofOfAccount: (action: any) => Promise<any> = async (action) => {
  const entityApi = new EntityApi(store);
  const getProofOfAccountResponse = await entityApi.getProofOfAccount(action.payload);
  const getProofOfAccountDocumentResponse = await entityApi.getProofOfAccountDocument(
    action.payload
  );
  return { metadata: getProofOfAccountResponse.data, file: getProofOfAccountDocumentResponse.data };
};

//===================================================
//                      EPICS
//===================================================

const getProgramOwnerEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PROGRAM_OWNER_DETAILS),
    switchMap((action) =>
      from(fetchProgramOwnerDetails(action)).pipe(
        map((payload) => ({ type: FETCH_PROGRAM_OWNER_DETAILS_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: FETCH_PROGRAM_OWNER_DETAILS_FAILURE,
            payload: `INTERNAL_SP_APP - ${error.message}`
          })
        )
      )
    )
  );

const updateProgramOwnerEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_PROGRAM_OWNER_DETAILS),
    switchMap((action) =>
      from(updateProgramOwnerDetails(action)).pipe(
        map((payload) => ({ type: UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS, payload })),
        catchError((error) =>
          of({ type: UPDATE_PROGRAM_OWNER_DETAILS_FAILURE, payload: error.message })
        )
      )
    )
  );

const addBankAccountEpic$: Epic = (action$) =>
  action$.pipe(
    ofType(ADD_BANK_ACCOUNT),
    switchMap((action) =>
      from(addNewBankAccount(action)).pipe(
        map((payload) => ({ type: ADD_BANK_ACCOUNT_SUCCESS, payload })),
        catchError((error) => of({ type: ADD_BANK_ACCOUNT_FAILURE, payload: error.message }))
      )
    )
  );

const updateBankAccountEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_BANK_ACCOUNT),
    switchMap((action) =>
      from(updateBankAccount(action)).pipe(
        map((payload) => ({ type: UPDATE_BANK_ACCOUNT_SUCCESS, payload })),
        catchError((error) => of({ type: UPDATE_BANK_ACCOUNT_FAILURE, payload: error.message }))
      )
    )
  );

const updateCompanyLogoEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_COMPANY_LOGO),
    switchMap((action) =>
      from(updateCompanyLogo(action)).pipe(
        map((payload) => ({ type: UPDATE_COMPANY_LOGO_SUCCESS, payload })),
        catchError((error) => of({ type: UPDATE_COMPANY_LOGO_FAILURE, payload: error.message }))
      )
    )
  );

const getProofOfAccountEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_PROOF_OF_ACCOUNT),
    switchMap((action) =>
      from(fetchProofOfAccount(action)).pipe(
        map((payload) => ({ type: FETCH_PROOF_OF_ACCOUNT_SUCCESS, payload })),
        catchError((error) => of({ type: FETCH_PROOF_OF_ACCOUNT_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(
  getProgramOwnerEpic$,
  updateProgramOwnerEpic$,
  addBankAccountEpic$,
  updateBankAccountEpic$,
  updateCompanyLogoEpic$,
  getProofOfAccountEpic$
);
