export const themeColors = {
  bg: {
    light: {
      page: '#FFFBFF',
      body: '#FFFFFF',
      surface: 'rgba(61, 58, 255, 0.05)',
      surfaceInvert: '#171717',
      surfaceRaised: 'rgba(61, 58, 255, 0.14)',
      strong: '#0C0C33',
      subtle: '#F5F5F5',
      muted: '#E5E5E5',
      input: '#FFFFFF',
      inputHover: '#F5F5F5',
      inputFocus: '#FFFFFF',
      inputDisabled: '#F5F5F5'
    },
    primary: '#3D3AFF',
    primaryHover: '#252399',
    primarySubtle: '#6461FF',
    primarySubtleHover: '#312ECC',
    primaryActive: '#312ECC',
    primaryDisabled: '#E5E5E5',
    primaryMuted: '#D8D8FF',
    success: '#22C55E',
    successHover: '#16A34A',
    successMuted: '#BBF7D0',
    warning: '#FCD34D',
    warningHover: '#FACC15',
    warningMuted: '#FDE68A',
    error: '#EF4444',
    errorHover: '#DC2626',
    errorMuted: '#FECACA',
    info: '#1D4ED8',
    infoHover: '#2563EB',
    infoMuted: '#BFDBFE'
  },
  border: {
    light: {
      container: '#A3A3A3',
      strong: '#171717',
      subtle: '#F5F5F5',
      inactive: '#D4D4D4',
      surfaceRaised: 'rgba(61, 58, 255, 0.28)'
    },
    primary: '#D8D8FF',
    primaryHover: '#252399',
    primarySubtle: '#6461FF',
    primarySubtleHover: '#312ECC',
    primaryFocus: '#8B89FF',
    primaryActive: '#252399',
    primaryDisabled: '#A3A3A3',
    success: '#4ADE80',
    warning: '#FACC15',
    error: '#F87171',
    info: '#60A5FA',
    input: '#D4D4D4',
    inputHover: '#A3A3A3',
    inputFocus: '#BFDBFE',
    inputDisabled: '#D4D4D4'
  },
  icon: {
    light: {
      default: '#171717',
      muted: '#737373',
      inactive: '#D4D4D4',
      neutral: '#FFFFFF',
      onSubtle: '#404040',
      onInvert: '#E5E5E5',
      onImage: '#FFFFFF'
    },
    success: '#22C55E',
    warning: '#EAB308',
    error: '#EF4444',
    info: '#1D4ED8',
    primary: '#3D3AFF',
    primaryHover: '#252399',
    primaryDisabled: '#737373',
    primaryActive: '#252399',
    onPrimary: '#FFFFFF',
    secondary: '#6366F1',
    tertiary: '#1D4ED8'
  },
  text: {
    light: {
      body: '#181766',
      secondary: '#262626',
      subtle: '#525252',
      onSubtle: '#171717',
      onImage: '#FFFFFF',
      onInvert: '#FFFFFF',
      muted: '#A3A3A3',
      inactive: '#D4D4D4',
      input: '#737373',
      inputHover: '#525252',
      inputFocus: '#171717',
      readableDisabled: '#808080'
    },
    primary: '#3D3AFF',
    primaryHover: '#252399',
    primaryLink: '#3D3AFF',
    primaryLinkHover: '#312ECC',
    primaryActive: '#252399',
    primaryDisabled: '#737373',
    onPrimary: '#FFFFFF',
    success: '#22C55E',
    warning: '#EAB308',
    error: '#EF4444',
    info: '#1D4ED8'
  },
  chart: {
    light: {
      colour1: '#6461FF',
      colour2: '#EF4444',
      colour3: '#FBBF24',
      colour4: '#A78BFA',
      colour5: '#60A5FA',
      colour6: '#A3E635',
      colour7: '#34D399',
      colour8: '#D946EF',
      colour9: '#FFA950'
    }
  },
  deepBlue: '#122337',
  midBlue: '#20376B',
  borderBlue: '#7185D4',
  footerBackgroundBlue: '#002984',
  copyColor: '#00c5ff',
  iconBlue: '#7185D4',
  lightBlue: '#E7EAF8',
  royalGold: '#C09C2F',
  chartBlue: '#3B4DC4',
  chartLightBlue: '#BDB7E3',
  dark: '#212121',
  mono1: '#474747',
  mono5: '#D9D9D9',
  mono6: '#FCFCFC',
  white: '#FFFFFF',
  pink: '#DF1642',
  greyBlue: '#A2A8B7',
  chartColor3: '#FBBF24',
  chartColor7: '#34D399'
};

export const styling = {
  defaultRadius: '12px',
  defaultPadding: '16px',
  defaultLargePadding: '24px'
};

export const layoutSizes = {
  maxMobileWidth: 650,
  minDesktopWidth: '1000px'
};

export const boxShadow: string = `0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)`;
