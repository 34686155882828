/* eslint-disable react-hooks/exhaustive-deps */
import {
  ILedgerInvoice,
  LedgerInvoiceLinkedProgram
} from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { OALinkedProgram, ProgramConfig } from 'api/interfaces/program/program.interface';
import AcceptedIcon from 'assets/icons/AcceptedIcon';
import GetPaidNowIcon from 'assets/icons/GetPaidNowIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import StopClockIcon from 'assets/icons/StopClockIcon';
import WarningIconFilled from 'assets/icons/WarningIconFilled';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import { FundingStatuses } from 'lib/enums/fundingStatuses.enum';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { getFundingParticipantName, getManagingBankName } from 'lib/helpers/getEntityNames';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_EARLY_PAYMENT_DIALOG_OPEN } from 'store/actions';
import { LedgerDetailHeaderProps } from '../LedgerDetail/LedgerDetailHeader';
import AlertIcon from 'assets/icons/AlertIcon';
interface ILedgerNotificationsProps {
  matchingClickHandler: () => void;
}

const LedgerNotifications: FC<ILedgerNotificationsProps> = ({ matchingClickHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bannerForView, setBannerForView] = useState<JSX.Element>(<></>);
  const {
    programType,
    programs
  }: {
    loading: boolean;
    g2fFeatureHidden: boolean;
    programType: string;
    programs: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    documentForViewHeaderData
  }: {
    documentForViewHeaderData: {
      header: LedgerDetailHeaderProps | null;
      data: ILedgerInvoice | ILedgerPurchaseOrder | null;
    } | null;
  } = useSelector((state: RootStateOrAny) => state.context);

  const bankName = getManagingBankName(programs);
  const buyerName = getFundingParticipantName(programs, 'BUYER');

  useEffect(() => {
    if (documentForViewHeaderData) setBannerForView(renderBanner());
    return () => {
      setBannerForView(<></>);
    };
  }, [documentForViewHeaderData]);

  const toggleAutoPaymentDialog: () => void = () => {
    dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: true });
  };

  const renderBanner: () => JSX.Element = () => {
    if (!documentForViewHeaderData?.data) return <></>;

    let docType: keyof typeof LedgerTypes | null = null;
    if ((documentForViewHeaderData?.data as ILedgerPurchaseOrder)?.purchaseOrderId) {
      docType = LedgerTypes.PO;
    }

    if ((documentForViewHeaderData?.data as ILedgerInvoice)?.invoiceId) {
      docType = LedgerTypes.INVOICE;
    }

    if (
      !(documentForViewHeaderData?.data as ILedgerPurchaseOrder)?.purchaseOrderId &&
      !(documentForViewHeaderData?.data as ILedgerInvoice)?.invoiceId
    )
      return <></>;

    if (!docType) return <></>;

    let document: ILedgerInvoice | ILedgerPurchaseOrder = (
      documentForViewHeaderData?.data as ILedgerPurchaseOrder
    )?.purchaseOrderId
      ? (documentForViewHeaderData?.data as ILedgerPurchaseOrder)
      : (documentForViewHeaderData?.data as ILedgerInvoice);

    switch (programType) {
      case ProgramType.OA_EXTERNAL_FUNDING:
        return documentForViewHeaderData?.data?.linkedPrograms.length === 1
          ? renderOABanner(documentForViewHeaderData.data, docType)
          : renderExternalFundingBanner(document as any);
      case ProgramType.OA_FUNDING:
        if (documentForViewHeaderData?.data?.linkedPrograms.length === 2)
          return renderFundingBanner(document as any);

        const matchedProgramTypeIndex = document?.linkedPrograms.findIndex((program) =>
          program.baseType.includes(ProgramType.OPEN_ACCOUNT)
        );

        return matchedProgramTypeIndex > -1
          ? renderOABanner(documentForViewHeaderData.data, docType)
          : renderFundingBanner(document as any);
      case ProgramType.SP_FUNDING:
        return renderFundingBanner(document as any);
      case ProgramType.OPEN_ACCOUNT:
        return (documentForViewHeaderData?.data as ILedgerPurchaseOrder)?.purchaseOrderId
          ? renderOABanner(document as ILedgerPurchaseOrder, docType)
          : renderOABanner(document as ILedgerInvoice, docType);
      default:
        // return <></>;
        return renderFundingBanner(document as any);
    }
  };

  const clickViewRequestHandler: (paymentId: string) => void = (paymentId) =>
    navigate(`/recent-activities/${paymentId}`);

  const renderOABanner: (
    document: ILedgerInvoice | ILedgerPurchaseOrder,
    docType: keyof typeof LedgerTypes
  ) => JSX.Element = (document, docType) =>
    docType === LedgerTypes.INVOICE
      ? renderOAInvoiceBanner(document as ILedgerInvoice)
      : renderOAPOBanner(document as ILedgerPurchaseOrder);

  const renderExternalFundingBanner: (document: ILedgerInvoice) => JSX.Element = (document) => {
    const matchedProgramByType = document?.linkedPrograms.find((program) =>
      program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    ) as LedgerInvoiceLinkedProgram | undefined;

    if (!matchedProgramByType) return <></>;

    const { invoiceStatus } = matchedProgramByType;

    if (invoiceStatus === 'CLOSED')
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.infoMuted}
          color={themeColors.text.light.body}
          icon={<InfoIcon color={themeColors.icon.info} />}
          title="Closed"
          description={`This invoice is being managed by ${bankName}.
      Please contact ${buyerName} or ${bankName} for further information.`}
        />
      );

    return (
      <Notification
        noShadow
        backgroundColor={themeColors.bg.infoMuted}
        color={themeColors.text.light.body}
        icon={<InfoIcon color={themeColors.icon.info} />}
        description={`This invoice is being managed by ${bankName}.
        Please contact ${buyerName} or ${bankName} for further information.`}
      />
    );
  };

  const renderFundingBanner: (document: ILedgerInvoice) => JSX.Element = (document) => {
    const matchedProgramByType = document?.linkedPrograms.find((program) =>
      program.baseType.includes(ProgramType.SP_FUNDING)
    ) as LedgerInvoiceLinkedProgram | undefined;

    const fundingId: string = document?.activeFunding?.fundingId || '';

    if (!matchedProgramByType) return <></>;

    const isInvoiceEligible = matchedProgramByType.tags.includes(FundingStatuses.ELIGIBLE);
    const isFundingRequested = matchedProgramByType.tags.includes(
      FundingStatuses.FUNDING_REQUESTED
    );
    const isFundingResponseAccepted = matchedProgramByType.tags.includes(
      FundingStatuses.FUNDING_RESPONSE_ACCEPTED
    );
    const isFundingResponseSent = matchedProgramByType.tags.includes(
      FundingStatuses.TRADE_PAYMENT_SENT_BY_FUNDER
    );
    const isFundingResponseSettled =
      matchedProgramByType.tags.includes(FundingStatuses.MATURING_PAYMENT_SENT_BY_BUYER) ||
      matchedProgramByType.tags.includes(FundingStatuses.MATURING_PAYMENT_RECEIVED_BY_SUPPLIER) ||
      matchedProgramByType.tags.includes(FundingStatuses.MATURING_PAYMENT_RECEIVED_BY_FUNDER);

    const isFundingRequestRejected = matchedProgramByType.tags.includes(
      FundingStatuses.FUNDING_REQUEST_REJECTED
    );

    if (isInvoiceEligible) {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.successMuted}
          color={themeColors.text.success}
          titleLarge
          descriptionLarge
          icon={<GetPaidNowIcon color={themeColors.text.onPrimary} />}
          title="Eligible for early payment"
          description={`This invoice has been approved and is eligible for early payment.`}
          hasButton
          buttonText="Get paid early"
          clickHandler={toggleAutoPaymentDialog}
        />
      );
    } else if (isFundingRequested) {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.successMuted}
          color={themeColors.text.success}
          icon={<ApproveIcon />}
          titleLarge
          descriptionLarge
          title="Early payment requested"
          description={`You’ve requested early payment for this invoice.`}
          hasSecondaryButton
          buttonText="View request"
          clickHandler={() => fundingId && clickViewRequestHandler(fundingId)}
        />
      );
    } else if (isFundingResponseAccepted) {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.successMuted}
          color={themeColors.text.success}
          icon={<ApproveIcon />}
          titleLarge
          descriptionLarge
          title="Early payment request accepted"
          description={`Your early payment request for this invoice has been accepted.`}
          hasSecondaryButton
          buttonText="View request"
          clickHandler={() => fundingId && clickViewRequestHandler(fundingId)}
        />
      );
    } else if (isFundingResponseSent) {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.successMuted}
          color={themeColors.text.success}
          titleLarge
          descriptionLarge
          icon={<ApproveIcon />}
          title="Early payment sent"
          description={`The early payment has been sent for this invoice.`}
        />
      );
    } else if (isFundingResponseSettled) {
      return (
        <Notification
          noShadow
          color={themeColors.text.success}
          titleLarge
          descriptionLarge
          icon={<ApproveIcon />}
          title="Settled"
          description={`This invoice has been settled.`}
        />
      );
    } else if (isFundingRequestRejected) {
      return (
        <Notification
          noShadow
          color={themeColors.text.error}
          titleLarge
          descriptionLarge
          icon={<WarningIconFilled />}
          title="Early payment request rejected"
          description={`The Funder has rejected your early payment request.
        You can request early payment again for this invoice.`}
          hasButton
          buttonText="Get paid early"
          clickHandler={toggleAutoPaymentDialog}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderOAInvoiceBanner: (document: ILedgerInvoice) => JSX.Element = (document) => {
    const matchedProgramByType = document?.linkedPrograms.find((program) =>
      program.baseType.includes(ProgramType.OPEN_ACCOUNT)
    ) as LedgerInvoiceLinkedProgram | undefined;

    if (!matchedProgramByType) return <></>;

    const { invoiceStatus, matchingStatus } = matchedProgramByType;

    if (invoiceStatus === 'APPROVED' && matchingStatus === 'MATCHED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.successMuted}
          noShadow
          icon={<ApproveIcon />}
          title="Matched"
          description={`This invoice will be paid at maturity.`}
        />
      );
    }

    if (invoiceStatus === 'OPEN' && matchingStatus === 'PENDING') {
      return (
        <Notification
          backgroundColor={themeColors.bg.warningMuted}
          icon={<StopClockIcon color={themeColors.icon.light.default} />}
          title="Pending"
          description={`Awaiting more information required for matching.`}
        />
      );
    }

    if (invoiceStatus === 'OPEN' && matchingStatus === 'MATCHED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.successMuted}
          icon={<ApproveIcon />}
          title="Matched"
          description={`Awaiting approval.`}
        />
      );
    }

    if (invoiceStatus === 'OPEN' && matchingStatus === 'DISCREPANT') {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.errorMuted}
          icon={<WarningIconFilled color={themeColors.icon.light.default} />}
          title="Discrepant"
          description={`Some of the Buyer's matching rules have not been satisfied.`}
          hasSecondaryButton
          buttonText="View matching"
          clickHandler={matchingClickHandler}
        />
      );
    }

    if (invoiceStatus === 'CLOSED' && matchingStatus === 'REJECTED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.infoMuted}
          icon={<InfoIcon color={themeColors.border.info} />}
          title="Rejected"
          description={`The buyer has rejected this invoice.`}
        />
      );
    }

    // IS THIS RIGHT?
    if (invoiceStatus === 'APPROVED' && matchingStatus === 'ACCEPTED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.successMuted}
          icon={<AcceptedIcon />}
          title="Accepted"
          description={`The Buyer has approved this invoice.`}
        />
      );
    }

    if (invoiceStatus === 'CLOSED' && matchingStatus === 'MATCHED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.successMuted}
          icon={<ApproveIcon />}
          title="Maturity payment sent"
          description={`A payment instruction has been sent for this invoice.`}
        />
      );
    }

    return <></>;
  };

  const renderOAPOBanner: (document: ILedgerPurchaseOrder) => JSX.Element = (document) => {
    const matchedProgramByType = document?.linkedPrograms.find((program) =>
      program.baseType.includes(ProgramType.OPEN_ACCOUNT)
    ) as OALinkedProgram | undefined;

    if (!matchedProgramByType) return <></>;

    const { purchaseOrderStatus, isDiscrepant } = matchedProgramByType;

    if (purchaseOrderStatus === 'OPEN' && !isDiscrepant) {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.infoMuted}
          icon={<InfoIcon color={themeColors.icon.info} />}
          title="Open"
          description={`Waiting for invoices.`}
        />
      );
    }

    if (purchaseOrderStatus === 'FULFILLED') {
      return (
        <Notification
          noShadow
          backgroundColor={themeColors.bg.infoMuted}
          icon={<InfoIcon color={themeColors.icon.info} />}
          title="Fulfilled"
          description={`This order is complete.`}
        />
      );
    }

    if (purchaseOrderStatus === 'CANCELLED') {
      return (
        <Notification
          backgroundColor={themeColors.bg.infoMuted}
          icon={<InfoIcon color={themeColors.icon.info} />}
          title="Cancelled"
          description={`This purchase order has been cancelled.`}
        />
      );
    }

    if (purchaseOrderStatus === 'OPEN' && isDiscrepant) {
      return (
        <Notification
          backgroundColor={themeColors.bg.errorMuted}
          icon={<AlertIcon />}
          title="Discrepant"
          description={`Some of the Buyer's matching rules have not been satisfied.`}
          hasSecondaryButton
          noShadow
          buttonText="View matching"
          clickHandler={matchingClickHandler}
        />
      );
    }

    return <></>;
  };

  return bannerForView;
};

export default LedgerNotifications;
