import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import MatchingChip from 'components/Chips/MatchingChip';
import Chip from 'components/common/Chip';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';

export const logisticsViewHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Logistic Id', 'logisticId', {
    display: false
  }),
  new DataGridHeaderItem(
    'Tracking Number',
    'trackingNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem(
    'Document Description',
    'documentDescription',
    new DataGridHeaderOptions(false, false, true)
  ),
  new DataGridHeaderItem('Status', 'status', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <Chip
            type={value}
            color={themeColors.text.primary}
            radius="3px"
            height="18px"
            fontSize={fontSizes.small}
            semibold="bold"
            testingTag="ledger-logistics-header"
          />
        </div>
      );
    }
  }),
  new DataGridHeaderItem('Matching Status', 'matchingStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip type={value} testingTag="logistic-ledger" ledgerType={LedgerTypes.LOGISTIC} />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem(
    'Consignor Name',
    'consignorName',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem(
    'Consignee Name',
    'consigneeName',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem(
    'Program Name',
    'programName',
    new DataGridHeaderOptions(false, false, true)
  )
];
