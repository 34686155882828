import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const InvoicesPageTitle = styled.h1`
  display: ${isMobile() ? 'none' : 'block'};
  font-size: ${fontSizes.xLarge};
`;

export const GroupTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
  width: 70rem;
`;

export const GroupedTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    padding: 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
      cursor: pointer;
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
  }
  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
`;

export const ExportDataButtonText = styled.h5``;

export const ExportDataButtonButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

export const InvoicesActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin: 16px 0;
`;

export const InvoicesCountContainer = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.mono1};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin: 16px 0;

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
  }
`;

export const InvoicesViewTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
  }
`;

export const SearchAdvancedText = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.primary};
  cursor: pointer;
`;

export const InvoiceLedgerFilterButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
