import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ActiveStepIcon: FC<IconSvgProps> = ({
  height = '6',
  width = '25',
  color = themeColors.icon.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} rx="3" fill={color} />
  </svg>
);

export default ActiveStepIcon;
