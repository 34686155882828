import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FC } from 'react';
import { AutocompleteContainer, AutocompleteLabel, AutocompleteMessage } from './styled';

interface SelectMenuItem {
  name: string;
  value: any;
  group: string;
}

export interface SelectProps {
  options: SelectMenuItem[];
  label?: string;
  selectID?: string;
  message?: string;
  errorMessage?: string;
  changeHandler?: (event: React.SyntheticEvent) => void;
  register?: any;
  value?: any;
  disabled?: boolean;
  testingTag?: string;
  placeholder?: string;
}

const Select: FC<SelectProps> = ({
  options,
  label,
  selectID,
  message,
  errorMessage,
  changeHandler,
  register,
  value,
  disabled,
  testingTag,
  placeholder
}) => {
  const getSelectedItem: () => SelectMenuItem = () => {
    const item: SelectMenuItem | undefined = options.find((v: SelectMenuItem) => v.value === value);
    return item || { name: '', value: '', group: '' };
  };

  return (
    <AutocompleteContainer disabled={disabled} data-automation-id={testingTag}>
      <AutocompleteLabel data-automation-id="autocomplete-p-label">{label}</AutocompleteLabel>
      <Autocomplete
        style={{ maxHeight: '280px' }}
        id={selectID}
        disableClearable
        options={options.sort((a: SelectMenuItem, b: SelectMenuItem): number =>
          b.group.localeCompare(a.group)
        )}
        getOptionLabel={(option: SelectMenuItem): string => option.name}
        isOptionEqualToValue={(option: SelectMenuItem, value: SelectMenuItem): boolean =>
          option.name === value.name
        }
        value={getSelectedItem()}
        onChange={(event, value) => changeHandler && changeHandler(value?.value)}
        disabled={disabled}
        groupBy={(option: SelectMenuItem): string => option.group}
        noOptionsText="No Options"
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <TextField
            {...params}
            value={value}
            {...register}
            placeholder={placeholder || ''}
            variant="outlined"
          />
        )}
        data-automation-id="autocomplete-div-listbox"
        data-testid="sp-autocomplete-listbox"
      />

      <AutocompleteMessage errorMessage={errorMessage}>
        {message || getSelectedItem().value !== '' || errorMessage}
      </AutocompleteMessage>
    </AutocompleteContainer>
  );
};

export default Select;
