import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import Divider from 'components/common/divider';
import MatchingRules from 'components/MatchingRules';
import { ILedgerMatching } from 'utils/interfaces/ledger/matching/matching.interface';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { FC } from 'react';
import {
  AccordionStyled,
  LedgerDetailAccordionContainer,
  LedgerDetailAccordionTitle,
  LedgerDetailAccordionTitleLeft,
  LedgerDetailAccordionTitleWrapper,
  MatchingRulesDescription,
  MatchingRulesDescriptionItem,
  MatchingRulesRows
} from './styled';

interface LedgerMatchingAccordionProps {
  discrepant: ILedgerMatching[];
  matched: ILedgerMatching[];
  matchingDateTime: string;
}

const renderMatchingGroupedLineItem: (
  data: ILedgerMatching[],
  matchingDateTime: string
) => JSX.Element = (data, matchingDateTime) => {
  const groupedByIsLineItemRule = _.groupBy(data, 'isLineItemRule');
  const groupedByIsLineItemRuleTrue =
    groupedByIsLineItemRule['true'] && _.groupBy(groupedByIsLineItemRule['true'], 'lineItemNumber');

  return (
    <MatchingRulesRows>
      {groupedByIsLineItemRule['false'] &&
        groupedByIsLineItemRule['false'].map((item: ILedgerMatching, index: number) => (
          <MatchingRules
            key={`${index}-${item.title}`}
            title={item.title}
            rule={item.rule}
            values={item.values}
          />
        ))}

      {groupedByIsLineItemRuleTrue &&
        Object.entries(groupedByIsLineItemRuleTrue).map(([lineItemId, items]) => (
          <div key={lineItemId}>
            <MatchingRulesDescription data-automation-id="ledger-matching-div-matching-rules-description-title">
              <MatchingRulesDescriptionItem>Line Item - {lineItemId}</MatchingRulesDescriptionItem>
              <MatchingRulesDescriptionItem>
                Matching Date and Time -{' '}
                {DateTime.fromISO(items[0].lineItemMatchingDateTime || '').toFormat(
                  `${_DATE_FORMAT} HH:mm:ss`
                )}
              </MatchingRulesDescriptionItem>
            </MatchingRulesDescription>

            {items.map((item: ILedgerMatching, index: number) => (
              <MatchingRules
                key={`${index}-${item.title}`}
                title={item.title}
                rule={item.rule}
                values={item.values}
              />
            ))}
          </div>
        ))}
    </MatchingRulesRows>
  );
};

const renderMatchingAccordion: (
  data: ILedgerMatching[],
  title: string,
  icon: JSX.Element,
  expanded: boolean,
  divider: boolean,
  matchingDateTime: string
) => JSX.Element | null = (data, title, icon, expanded, divider, matchingDateTime) =>
  data ? (
    <>
      <AccordionStyled
        disableGutters
        defaultExpanded={expanded}
        data-automation-id={`ledger-matching-div-accordion-container-${toKebabCase(title || '')}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-automation-id="ledger-matching-div-accordion-summary"
        >
          <LedgerDetailAccordionTitleWrapper>
            <LedgerDetailAccordionTitleLeft>
              {icon}
              <LedgerDetailAccordionTitle
                data-automation-id={`ledger-matching-h4-accordion-title-${toKebabCase(
                  title || ''
                )}`}
              >
                {title} ({data.length})
              </LedgerDetailAccordionTitle>
            </LedgerDetailAccordionTitleLeft>
          </LedgerDetailAccordionTitleWrapper>
        </AccordionSummary>
        <AccordionDetails>{renderMatchingGroupedLineItem(data, matchingDateTime)}</AccordionDetails>
      </AccordionStyled>
    </>
  ) : null;

const LedgerMatchingAccordion: FC<LedgerMatchingAccordionProps> = ({
  discrepant,
  matched,
  matchingDateTime
}) => {
  const setUniqueData: (data: any[], comparedItems: any[]) => ILedgerMatching[] = (
    data,
    comparedItems
  ) => {
    const checkedItems: any = {};
    const uniqueItems: any[] = [];

    data.forEach((item) => {
      const key = comparedItems.map((comparedItem) => item[comparedItem]).join('|');

      if (!checkedItems[key]) {
        uniqueItems.push(item);
        checkedItems[key] = true;
      }
    });

    return uniqueItems;
  };
  const uniqueDiscrepant: ILedgerMatching[] = setUniqueData(discrepant, ['rule', 'title']);
  const uniqueMatched: ILedgerMatching[] = setUniqueData(matched, ['rule', 'title']);
  return (
    <LedgerDetailAccordionContainer data-automation-id="ledger-matching-div-accordion-container">
      {renderMatchingAccordion(
        uniqueDiscrepant,
        'Discrepant',
        <WarningIcon width="20" height="20" color={themeColors.icon.error} />,
        true,
        true,
        matchingDateTime
      )}
      {renderMatchingAccordion(
        uniqueMatched,
        'Matched',
        <ApproveIcon width="20" height="20" color={themeColors.icon.success} />,
        true,
        false,
        matchingDateTime
      )}
    </LedgerDetailAccordionContainer>
  );
};

export default LedgerMatchingAccordion;
