import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { BankAccountCurrency } from 'api/interfaces/bank-account/bankAccountBase.api';
import { EntityBankAccount, EntityOwner } from 'api/interfaces/entity/entity.interface';
import CheckIcon from 'assets/icons/CheckIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import CollapsibleCards from 'components/common/CollapsibleCards';
import FlatCard from 'components/common/FlatCard';
import { SecondaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import Sidebar from 'components/sidebar';
import Snackbar from 'components/snackbar';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PROGRAM_OWNER_DETAILS,
  RESET_PROOF_OF_ACCOUNT,
  TRIGGER_SNACKBAR,
  UPDATE_BANK_ACCOUNT_FOR_EDIT
} from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import CompanyBankAccountDetailReadonly from 'views/CompanyDetails/CompanyBankAccountDetailReadonly';
import CompanyBankAccountDetails from './CompanyBankAccountDetails';
import CompanyDetailsEdit from './CompanyDetailsEdit';
import {
  AccordionDetailsWrapper,
  AccordionStyled,
  AccordionSummaryDetailLabel,
  AccordionSummaryDetailLeft,
  AccordionSummaryDetailRight,
  AccordionSummaryDetailRow,
  AccordionSummaryDetailValue,
  AccordionSummaryHeader,
  AccordionSummaryTitle,
  CompanyDetailsButtonContainer,
  CompanyDetailsButtonText,
  CompanyDetailsContainer,
  CompanyDetailsContentContainer,
  CompanyDetailsContentWrapper,
  CompanyDetailsHeader,
  CompanyDetailsHeaderName,
  CompanyDetailsMainWrapper,
  CompanyDetailsNotificationWrapper,
  CompanyDetailsPageTitle,
  CompanyDetailsWrapper,
  NonDefaultAccountsBar,
  NonDefaultAccountsCollapse,
  NonDefaultAccountsContainer,
  NonDefaultAccountsTitle
} from './styled';
import BaseCard from 'components/common/cards/BaseCard';
import CompanyIcon from 'assets/icons/CompanyIcon';
import BankAccountsIcon from 'assets/icons/BankAccountsIcon';
import { useAuth } from 'react-oidc-context';

export interface CompanyInformation {
  registeredCompanyName: string;
  tradingName: string;
  registeredAddressLine1: string;
  registeredAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  companyID: string;
  companyTaxID: string;
  website: string;
  contactNumber: string;
  country: string;
  companyLogo?: any | null;
}

const CompanyDetails: FC = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const [companyEditOpen, setCompanyEditOpen] = useState<boolean>(false);
  const [companyAddBankDetailsOpen, setCompanyAddBankDetailsOpen] = useState<boolean>(false);
  const [details, setDetails] = useState<CompanyInformation>({
    registeredCompanyName: '',
    tradingName: '',
    registeredAddressLine1: '',
    registeredAddressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    companyID: '',
    companyTaxID: '',
    website: '',
    contactNumber: '',
    country: '',
    companyLogo: null
  });
  const [bankDetailOpen, setBankDetailOpen] = useState<boolean>(false);
  const [nonDefaultExpanded, setNonDefaultExpanded] = useState<boolean>(false);
  const [defaultProgramOwnerBankAccounts, setDefaultProgramOwnerBankAccounts] = useState<
    EntityBankAccount[]
  >([]);
  const [nonDefaultProgramOwnerBankAccounts, setNonDefaultProgramOwnerBankAccounts] = useState<
    EntityBankAccount[]
  >([]);

  const {
    programOwnerBankAccounts,
    programOwnerDetails,
    loading
  }: {
    programOwnerBankAccounts: EntityBankAccount[];
    programOwnerDetails: EntityOwner | null;
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.program);
  const programError: string = useSelector((state: RootStateOrAny) => state.program.error);
  const error: string = useSelector((state: RootStateOrAny) => state.error.error);

  const {
    countries,
    snackbarOpen
  }: {
    countries: Record<string, string>;
    snackbarOpen: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);
  const lottieLoader: JSX.Element = <LoaderInPage />;

  useEffect(() => {
    dispatch({ type: FETCH_PROGRAM_OWNER_DETAILS, payload: auth.user?.access_token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateOwnerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programOwnerDetails, countries]);

  useEffect(() => {
    setGroupedAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programOwnerBankAccounts]);

  // const toggleBankDetailClickHandler: (account?: EntityBankAccount | null) => void = (
  //   account = null
  // ) => {
  //   dispatch({
  //     type: UPDATE_BANK_ACCOUNT_FOR_EDIT,
  //     payload: account?.bankAccountId ? account : null
  //   });
  //   setBankDetailOpen(!bankDetailOpen);
  // };

  const toggleEditSelectedBankDetailHandler: () => void = () => {
    toggleAddBankDetailsClickHandler();
    setTimeout(() => setBankDetailOpen(false), 1000);
  };

  const bankDetailsSnackbarData: SnackbarData = {
    title: (
      <h5>
        {!!programError.length ? 'Error saving bank details.' : 'Bank details successfully updated'}
      </h5>
    ),
    message: (
      <p>
        {!!programError.length && !!error.length
          ? error
          : 'You have successfully updated the bank details. You can view or edit these details below.'}
      </p>
    ),
    leftIcon: !!programError.length ? (
      <WarningIcon color={themeColors.icon.error} />
    ) : (
      <CheckIcon />
    ),
    topAligned: true,
    type: !!programError ? 'error' : 'success'
  };

  const bankAddNewSnackbarData: SnackbarData = {
    title: (
      <h5>
        {!!programError.length ? 'Error adding bank account.' : 'Bank account successfully updated'}
      </h5>
    ),
    message: (
      <p>
        {!!programError.length
          ? 'An error occurred adding the bank account. Please try again.'
          : 'You have successfully added the bank account. You can view or edit these details below.'}
      </p>
    ),
    leftIcon: !!programError.length ? (
      <WarningIcon color={themeColors.icon.error} />
    ) : (
      <CheckIcon />
    ),
    topAligned: true,
    type: !!programError ? 'error' : 'success'
  };

  const editSaveClickHandler: () => void = () => {
    dispatch({
      type: UPDATE_BANK_ACCOUNT_FOR_EDIT,
      payload: null
    });
    toggleAddBankDetailsClickHandler();
    dispatch({ type: FETCH_PROGRAM_OWNER_DETAILS, payload: auth.user?.access_token });
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: bankDetailsSnackbarData } });
    setTimeout(() => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    }, 15000);
  };

  const toggleEditClickHandler: () => void = () => {
    setCompanyEditOpen(!companyEditOpen);
  };

  const toggleAddBankDetailsClickHandler: () => void = () =>
    setCompanyAddBankDetailsOpen(!companyAddBankDetailsOpen);

  const bankAccountSaveHandler: () => void = () => {
    dispatch({ type: FETCH_PROGRAM_OWNER_DETAILS, payload: auth.user?.access_token });
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: bankAddNewSnackbarData } });
    setTimeout(() => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    }, 15000);
    setCompanyAddBankDetailsOpen(!companyAddBankDetailsOpen);
  };

  const updateOwnerDetails: () => void = () => {
    if (!programOwnerDetails || !programOwnerDetails.identifier) return;

    const { registeredName, tradingName, phoneNumber, siteUrl } = programOwnerDetails.contact;
    const { line1, line2, city, state, zipCode, countryIso } =
      programOwnerDetails.registeredAddress;
    const { taxId, registrationId } = programOwnerDetails.identifier;

    setDetails({
      registeredCompanyName: registeredName || '',
      tradingName: tradingName || '',
      registeredAddressLine1: line1 || '',
      registeredAddressLine2: line2 || '',
      city: city || '',
      state: state || '',
      zipCode: zipCode || '',
      companyID: registrationId || '',
      companyTaxID: taxId || '',
      website: siteUrl || '',
      contactNumber: phoneNumber || '',
      country: countryIso || '',
      companyLogo: programOwnerDetails.companyLogo ? programOwnerDetails.companyLogo : null
    });
  };

  const companyDetailsSnackbarData: SnackbarData = {
    title: <h5>Company details successfully updated</h5>,
    message: (
      <p>
        You have successfully updated your company details. You can view or edit these details
        below.
      </p>
    ),
    leftIcon: <CheckIcon />,
    topAligned: true
  };

  const companyDetailsSaveClickHandler: () => void = () => {
    setCompanyEditOpen(false);
    dispatch({ type: TRIGGER_SNACKBAR, payload: { open: true, data: companyDetailsSnackbarData } });
    setTimeout(() => {
      dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
    }, 15000);
  };

  const closeDialogClickHandler: () => void = () => {
    dispatch({ type: RESET_PROOF_OF_ACCOUNT });
    bankAddNewSnackbarData && setBankDetailOpen(false);
  };

  const renderCompanyDetails: () => JSX.Element = () =>
    loading ? (
      lottieLoader
    ) : (
      <AccordionDetails data-testid="sp-company-details-accordion-container">
        <AccordionSummaryDetailRow>
          <AccordionSummaryDetailLeft>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Registered company name
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.registeredCompanyName || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailLeft>
          <AccordionSummaryDetailRight>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Trading name
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.tradingName || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailRight>
        </AccordionSummaryDetailRow>
        <AccordionSummaryDetailRow>
          <AccordionSummaryDetailLeft>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Company tax ID
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.companyTaxID || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailLeft>
          <AccordionSummaryDetailRight>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Website
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.website || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailRight>
        </AccordionSummaryDetailRow>
        <AccordionSummaryDetailRow>
          <AccordionSummaryDetailLeft>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Registered company address
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.registeredAddressLine1 || '--'} <br />
              {details.registeredAddressLine2 || '--'}
              <br />
              {details.city || '--'}
              <br />
              {details.state || '--'}
              <br />
              {countries && details?.country ? countries[details.country] : ''}
              <br />
              {details.zipCode || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailLeft>
          <AccordionSummaryDetailRight>
            <AccordionSummaryDetailLabel data-automation-id="company-details-p-accordion-summary-detail-label">
              Contact number
            </AccordionSummaryDetailLabel>
            <AccordionSummaryDetailValue data-automation-id="company-details-p-accordion-summary-detail-value">
              {details.contactNumber || '--'}
            </AccordionSummaryDetailValue>
          </AccordionSummaryDetailRight>
        </AccordionSummaryDetailRow>
        {/* TEMPORARY REMOVAL */}
        <CompanyDetailsButtonContainer>
          <SecondaryButton
            text="Edit"
            clickHandler={toggleEditClickHandler}
            testingTag="company-details-button-edit"
          />
        </CompanyDetailsButtonContainer>
      </AccordionDetails>
    );

  const setDefaultCurrency: (currencies: BankAccountCurrency[]) => string = (currencies) => {
    const filtered: BankAccountCurrency | undefined = currencies.find((c) => c.isDefault);
    return filtered ? `${filtered.currency} DEFAULT` : '';
  };

  const setGroupedAccounts: () => void = () => {
    const grouped = _.groupBy(programOwnerBankAccounts, 'currencies[0].isDefault');
    setDefaultProgramOwnerBankAccounts(grouped['true'] || []);
    setNonDefaultProgramOwnerBankAccounts(grouped['false'] || []);
  };

  const renderDefaultAccounts: () => JSX.Element[] = () =>
    defaultProgramOwnerBankAccounts
      ? defaultProgramOwnerBankAccounts.map((p: EntityBankAccount, i: number) => (
          <FlatCard
            key={p.accountName}
            title={p.accountName || ''}
            value={`${p.bankName} | ${p.accountNumber || p.iban} | ${p.currencies[0].currency}`}
            flag={setDefaultCurrency(p.currencies)}
            clickHandler={() => {}}
            // clickHandler={() => toggleBankDetailClickHandler(p)}
            testingTagPage="company-details"
          />
        ))
      : [<></>];

  const renderNonDefaultAccounts: () => JSX.Element = () => (
    <NonDefaultAccountsContainer>
      <NonDefaultAccountsBar>
        <NonDefaultAccountsTitle data-automation-id="company-details-h5-title-other-bank-accounts">
          Other bank accounts
        </NonDefaultAccountsTitle>
        <NonDefaultAccountsCollapse
          onClick={() => setNonDefaultExpanded(false)}
          data-automation-id="company-details-p-title-collapse"
          data-testid="sp-collapse"
        >
          Collapse
        </NonDefaultAccountsCollapse>
      </NonDefaultAccountsBar>
      {nonDefaultProgramOwnerBankAccounts.map((p: EntityBankAccount, i: number) => (
        <FlatCard
          key={`${p.accountName}-${Math.random() * 100}`}
          title={p.accountName || ''}
          value={`${p.bankName} | ${p.accountNumber || p.iban} | ${p.currencies[0].currency}`}
          flag={setDefaultCurrency(p.currencies)}
          clickHandler={() => {}}
          // clickHandler={() => toggleBankDetailClickHandler(p)}
          testingTagPage="company-details"
        />
      ))}
    </NonDefaultAccountsContainer>
  );

  const renderCollapsedNonDefaultAccounts: () => JSX.Element = () => (
    <CollapsibleCards
      title="Other bank accounts"
      value={`${nonDefaultProgramOwnerBankAccounts.length} accounts`}
      clickHandler={() => setNonDefaultExpanded(true)}
      testingTagPage="company-details"
    />
  );

  const renderCompanyBankAccounts: () => JSX.Element = () =>
    loading ? (
      lottieLoader
    ) : (
      <AccordionDetails>
        <AccordionDetailsWrapper>
          {renderDefaultAccounts()}
          {nonDefaultExpanded ? renderNonDefaultAccounts() : renderCollapsedNonDefaultAccounts()}
          {/* DISABLED UNTIL 4.0.0 */}
          {/* <CompanyDetailsButtonContainer>
            <PrimaryButton
              text="Add new"
              clickHandler={() => {
                if (!companyAddBankDetailsOpen)
                  dispatch({
                    type: UPDATE_BANK_ACCOUNT_FOR_EDIT,
                    payload: null
                  });
                  toggleAddBankDetailsClickHandler();
              }}
              testingTag="company-details-button-add-new"
              />
            </CompanyDetailsButtonContainer> */}
        </AccordionDetailsWrapper>
        <p style={{ color: themeColors.mono1, textAlign: 'right', paddingRight: '8px' }}>
          *Please contact support@kanexa.com if you would like to change your bank account details
        </p>
      </AccordionDetails>
    );

  return (
    <CompanyDetailsMainWrapper>
      <CompanyDetailsWrapper>
        <Sidebar />
        <CompanyDetailsContainer>
          <BaseCard noBorder noBoxShadow backgroundColor="transparent">
            {snackbarOpen && (
              <CompanyDetailsNotificationWrapper data-automation-id="company-details-div-notification">
                <Snackbar />
              </CompanyDetailsNotificationWrapper>
            )}
            <CompanyDetailsContentContainer>
              <AccordionStyled defaultExpanded disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionSummaryHeader>
                    <CompanyIcon />
                    <AccordionSummaryTitle data-automation-id="company-details-h4-accordion-title-company-details">
                      Company profile
                    </AccordionSummaryTitle>
                  </AccordionSummaryHeader>
                </AccordionSummary>
                {renderCompanyDetails()}
              </AccordionStyled>

              <AccordionStyled disableGutters defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionSummaryHeader>
                    <BankAccountsIcon />
                    <AccordionSummaryTitle data-automation-id="company-details-h4-accordion-title-bank-details">
                      Bank accounts
                    </AccordionSummaryTitle>
                  </AccordionSummaryHeader>
                </AccordionSummary>
                {renderCompanyBankAccounts()}
              </AccordionStyled>
            </CompanyDetailsContentContainer>
          </BaseCard>
        </CompanyDetailsContainer>
        {companyEditOpen && (
          <FullViewDialog
            open={companyEditOpen}
            clickHandler={toggleEditClickHandler}
            dialogContent={
              <CompanyDetailsEdit
                info={details}
                closeHandler={toggleEditClickHandler}
                saveClickHandler={companyDetailsSaveClickHandler}
              />
            }
          />
        )}
        {companyAddBankDetailsOpen && (
          <FullViewDialog
            open={companyAddBankDetailsOpen}
            clickHandler={toggleAddBankDetailsClickHandler}
            dialogContent={
              <CompanyBankAccountDetails
                closeHandler={(edited?: boolean) =>
                  edited ? editSaveClickHandler() : toggleAddBankDetailsClickHandler()
                }
                saveHandler={bankAccountSaveHandler}
              />
            }
          />
        )}
        {bankDetailOpen && (
          <FullViewDialog
            open={bankDetailOpen}
            clickHandler={closeDialogClickHandler}
            dialogContent={
              <CompanyBankAccountDetailReadonly
                pageTitle={`Business Current Account`}
                closeHandler={() => toggleEditSelectedBankDetailHandler()}
              />
            }
          />
        )}
      </CompanyDetailsWrapper>
    </CompanyDetailsMainWrapper>
  );
};
export default CompanyDetails;
