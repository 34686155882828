import { IUser } from 'api/interfaces/users/user.interface';
import {
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER_ACTION,
  UPDATE_USER_ACTION_SUCCESS,
  UPDATE_USER_ACTION_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  RESET_USERS,
  GET_LOGGED_IN_USER,
  GET_LOGGED_IN_USER_FAILURE,
  GET_LOGGED_IN_USER_SUCCESS
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface UserReducerStateProps extends StateBase {
  users: IUser[];
  currentUser: IUser | null;
  loggedInUser: IUser | null;
  usersError: string;
}

const DEFAULT_STATE: UserReducerStateProps = {
  users: [],
  currentUser: null,
  loggedInUser: null,
  error: '',
  loading: false,
  usersError: ''
};

const userReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  const deprovisionedToDeactivatedConverter: (val: string | null) => string = (val) =>
    val && val === 'DEPROVISIONED' ? 'DEACTIVATED' : val === null ? '' : val;

  switch (type) {
    case GET_LOGGED_IN_USER:
      return {
        ...state,
        loading: true
      };
    case GET_LOGGED_IN_USER_SUCCESS:
      if (payload) (payload as IUser).status = deprovisionedToDeactivatedConverter(payload.status);
      return {
        ...state,
        loggedInUser: payload,
        loading: false,
        usersError: '',
        error: ''
      };
    case GET_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        error: payload,
        usersError: payload,
        loading: false
      };
    case GET_USERS:
      return {
        ...state,
        loading: true
      };
    case GET_USERS_SUCCESS:
      const mappedData = (payload as IUser[]).map((p) => {
        p.status = deprovisionedToDeactivatedConverter(p.status);
        return p;
      });
      return {
        ...state,
        users: mappedData,
        loading: false,
        usersError: ''
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        usersError: payload,
        loading: false
      };
    case RESET_USERS:
      return {
        ...state,
        users: [],
        usersError: '',
        loading: false
      };
    case GET_USER:
      return {
        ...state,
        loading: true
      };
    case GET_USER_SUCCESS:
      if (payload) (payload as IUser).status = deprovisionedToDeactivatedConverter(payload.status);
      return {
        ...state,
        currentUser: payload,
        loading: false
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: ''
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case ADD_NEW_USER:
      return {
        ...state,
        loading: true
      };
    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        error: ''
      };
    case ADD_NEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_USER_ACTION:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_ACTION_SUCCESS:
      return {
        ...state,
        error: ''
      };
    case UPDATE_USER_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};

export { userReducer };
export type { UserReducerStateProps };
