import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { themeColors } from 'assets/theme/style';

interface IDisabled {
  disabled?: boolean;
}

export const PersonalDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${isMobile() ? '1fr' : 'fit-content(354px) auto'};
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
`;

export const PersonalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: ${isMobile() ? '100%' : '80%'};
  min-width: ${isMobile() ? 'auto' : '760px'};
  max-width: 1200px;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 'fit-content';
  &.Mui-checked {
    color: ${themeColors.text.primary} !important;
  }
  &.Mui-disabled {
    color: ${themeColors.text.light.muted} !important;
  }
`;

export const PersonalDetailsPageTitle = styled.h2`
  margin: 0;
  display: ${isMobile() ? 'none' : 'block'};
`;

export const PersonalDetailsHeader = styled.div`
  display: flex;
  align-items: ${isMobile() ? 'flex-start' : 'center'};
  justify-content: space-between;
  flex-direction: ${isMobile() ? 'column-reverse' : 'row'};
  gap: 16px;
  padding-bottom: 24px;
`;

export const PersonalDetailsHeaderName = styled.h3`
  font-weight: ${fontWeights.bold};
`;

export const PersonalDetailsHeaderLogo = styled.div`
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: ${isMobile() ? '12px' : '16px 24px'};
  gap: 17px;

  img {
    width: ${isMobile() ? '35px' : '50px'};
  }
`;

export const PersonalDetailsContentRow = styled.div`
  display: grid;
  grid-template-columns: ${isMobile() ? '1fr' : '1fr 1fr'};
  gap: 24px;
  margin-bottom: 16px;
`;

export const PersonalDetailsContentRowSegment = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  :nth-child(2n + 1) {
    padding-right: 32px;
  }
`;

export const PersonalDetailsContentRowLabel = styled.p`
  display: flex;
  ${typography.bodySemiBold};
  text-align: left;
`;

export const PersonalDetailsContentRowValue = styled.p`
  display: flex;
  ${typography.bodyRegular};
  text-align: right;
`;

export const PersonalDetailsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonalDetailsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PersonalDetailsError = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PersonalDetailsButtonContainer = styled.div`
  display: flex;
`;

export const PersonalDetailsButtonText = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.small};
`;

export const PersonalDetailsButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const PersonalDetailsActionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`;

export const PersonalDetailsPreferenceTitle = styled.p<IDisabled>`
  ${typography.bodySemiBold};
  color: ${({ disabled }) => (disabled ? themeColors.text.light.muted : '')};
`;

export const PersonalDetailsPreferenceValue = styled.p<IDisabled>`
  ${typography.captionRegular};
  color: ${({ disabled }) => (disabled ? themeColors.text.light.muted : '')};
`;

export const PersonalDetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PersonalDetailsSectionTitle = styled.h3`
  margin-bottom: 16px;
`;
