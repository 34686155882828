import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import styled from 'styled-components';

export const TransactionHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: ${isMobile() ? '16px 0 0 0' : '18px 24px 0 24px'};
`;

export const TransactionHistoryRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

export const TransactionHistoryDetail = styled.div`
  display: flex;
  flex-direction: column;
  ${typography.bodyRegular};
  margin: 0 16px 16px 40px;
  width: ${isMobile() ? 'auto' : '50%'};
`;

export const TransactionPaymentDetail = styled.div`
  ${typography.captionRegular};
  margin: 16px 16px 16px 40px;
  width: ${isMobile() ? 'auto' : '80%'};
`;

export const TransactionBankAccountUnknown = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.subtle};
  margin: 16px 16px 16px 40px;
`;

export const FundingRequestLottieAnimation = styled(Lottie)`
  display: flex;
  height: 25px;
`;
