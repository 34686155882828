import { GET_ADJUSTMENTS, GET_ADJUSTMENTS_FAILURE, GET_ADJUSTMENTS_SUCCESS } from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface AdjustmentsReducerStateProps extends StateBase {
  adjustments: any[] | null;
}

const DEFAULT_STATE: AdjustmentsReducerStateProps = {
  adjustments: null,
  error: '',
  loading: false
};

const adjustmentsReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case GET_ADJUSTMENTS:
      return {
        ...state,
        loading: true
      };
    case GET_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        adjustments: payload,
        loading: false
      };
    case GET_ADJUSTMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
};

export { adjustmentsReducer };
export type { AdjustmentsReducerStateProps };
