import StoreBase from 'api/base/storeBase';
import { ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ODataLedgerPayableItems } from 'api/interfaces/ledger/ledgerPayableItems.interface';
import { ODataLedgerPayments } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ODataLedgerPaymentItem } from 'api/interfaces/ledger/ledgerPaymentItems.interface';
import { AxiosResponse } from 'axios';

const _PATH: string = '/ledger/payment-service/odata';
const _SP_PATH: string = '/ledger/supplier-pay/odata';

export default class PaymentApi extends StoreBase {
  public getPaymentsLedgerOfTypeTrade: (
    programId: string
  ) => Promise<AxiosResponse<ODataLedgerPayments>> = async (programId) =>
    this.axiosInstance.get(
      `${_PATH}/payments?$top=5000&$filter=linkedProgram/programId eq '${programId}' and type eq 'TRADE'`
    );

  public getPaymentsByPaymentIds: (
    paymentIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerPayments>> = async (paymentIds) =>
    this.axiosInstance.get(`${_PATH}/payments?$top=5000&$filter=id eq '${paymentIds.join(',')}'`);

  public getPayableItemsLedgerOfTypeTrade: (
    programId: string
  ) => Promise<AxiosResponse<ODataLedgerPayableItems>> = async (programId) =>
    this.axiosInstance.get(
      `${_PATH}/payable-items?$top=5000&$filter=paymentType eq 'TRADE' and programId eq '${programId}' and outstandingAmount ne 0 and status eq 'OPEN'`
    );

  public getPayableItemsLedgerOfTypeMaturing: (
    programId: string
  ) => Promise<AxiosResponse<ODataLedgerPayableItems>> = async (programId) =>
    this.axiosInstance.get(
      `${_PATH}/payable-items?$top=5000&$filter=paymentType eq 'MATURING' and programId eq '${programId}' and outstandingAmount ne 0 and status eq 'OPEN'`
    );

  public getPaymentsLedger: (programIds: string[]) => Promise<AxiosResponse<ODataLedgerPayments>> =
    async (programIds) => {
      const filterProgramIds: string = programIds
        ? programIds.map((id) => `linkedProgram/programId eq '${id}'`).join(' or ')
        : '';
      return this.axiosInstance.get(
        `${_PATH}/payments?$count=true&$top=5000&$filter=${filterProgramIds}`
      );
    };

  public getPaymentsLedgerPaymentItem: (
    paymentId: string
  ) => Promise<AxiosResponse<ODataLedgerPaymentItem>> = async (paymentId) =>
    this.axiosInstance.get<ODataLedgerPaymentItem>(
      `${_PATH}/payment-items?$top=5000&$filter=paymentId eq '${paymentId}'`
    );

  public getPaymentInvoicesByPaymentIds: (
    paymentIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerInvoices>> = async (paymentIds) =>
    this.axiosInstance.get<ODataLedgerInvoices>(
      `${_SP_PATH}/payment-invoices?$filter=invoiceId in ('${paymentIds.join("','")}')`
    );

  public getFilterPayments: (
    programIds: string[],
    rowsPerPage: number | null,
    page: number | null,
    filter: string,
    orderBy: string
  ) => Promise<AxiosResponse<ODataLedgerPayments>> = async (
    programIds,
    rowsPerPage,
    page,
    filter,
    orderBy
  ) => {
    const filterProgramIds: string = programIds
      .map((id) => `linkedProgram/programId eq '${id}'`)
      .join(' or ');

    return this.axiosInstance.get(
      `${_PATH}/payments?$count=true&$top=${rowsPerPage || 5000}&$orderby=${orderBy}&$filter=(${filterProgramIds})${
        filter || ''
      }&$skip=${(page && rowsPerPage !== null) ? rowsPerPage * page : 0}`
    );
  };
}
