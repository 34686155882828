import { SET_BREADCRUMB } from 'store/actions';
import { ReducerAction } from '.';

interface BreadcrumbReducerStateProps {
  breadcrumbValues: { [key: string]: string } | null;
}

const DEFAULT_STATE: BreadcrumbReducerStateProps = {
  breadcrumbValues: null
};

const breadcrumbReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumbValues: payload
      };
    default:
      return state;
  }
};

export { breadcrumbReducer };
export type { BreadcrumbReducerStateProps };
