import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const RightLongArrowIcon: FC<IconSvgProps> = ({
  color = themeColors.text.light.muted,
  height = '13px',
  width = '18px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-right-long-arrow-icon"
  >
    <path
      id="fillHover"
      d="M0 5.5H14.17L10.59 1.91L12 0.5L18 6.5L12 12.5L10.59 11.09L14.17 7.5H0V5.5Z"
      fill={color}
    />
  </svg>
);

export default RightLongArrowIcon;
