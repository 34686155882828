import ToDo from './todo';

export default class GroupedToDo {
  constructor(
    public groupedName: string,
    public groupDescription: string,
    public todos: ToDo[],
    public groupIcon?: string
  ) {}
}
