import StoreBase from 'api/base/storeBase';
import { IAdjustment } from 'api/interfaces/open-account/adjustments/adjustments.interface';
import { AxiosResponse } from 'axios';
import { AssetEnum } from 'lib/enums/asset/asset.enum';

const _PATH: string = '/open-accounts/adjustments';

export default class OpenAccountAdjustmentsApi extends StoreBase {
  public getOpenAccountAdjustmentsById: (
    type: keyof typeof AssetEnum,
    assetId: string
  ) => Promise<AxiosResponse<IAdjustment[]>> = async (type, assetId) =>
    this.axiosInstance.get<IAdjustment[]>(`${_PATH}?appliedType=${type}&appliedId=${assetId}`);
}
