import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const NotFoundPageContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile() ? "column" : "row"};
  justify-content: center;
  width: 80%;
  align-items: center;
  gap: ${isMobile() ? "5rem" : "12rem"};
  height: ${isMobile() ? "100%" : "60vh"};
`;

export const NotFoundPageLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NotFoundPageRightContainer = styled.div`
  display: flex;
`;

export const NotFoundPageContainerError = styled.h2`
  font-size: ${fontSizes.xLarge};
  color: ${themeColors.text.primary};
`;

export const NotFoundPageContainerPageTitle = styled.h1`
  font-size: 3.75rem; //60px
`;

export const NotFoundPageContainerDescription = styled.p``;

export const NotFoundPageImage = styled.img`
  margin: 0;
  width: 100%;
  margin-bottom: ${isMobile() ? "5rem" : "0"};
`;

export const NotFoundPageContainerButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-top: 1rem;
`;

export const NotFoundPageContainerButtonText = styled.p``;