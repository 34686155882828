import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { getInvoiceStatusTooltipText, getPOStatusTooltipText } from 'lib/tooltips/tooltips';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  LedgerDetailContentHeader,
  LedgerDetailHeaderAmountLabel,
  LedgerDetailHeaderAmountValue,
  LedgerDetailHeaderAmountValueContainer,
  LedgerDetailHeaderIcon,
  LedgerDetailHeaderLeft,
  LedgerDetailHeaderRight,
  LedgerDetailHeaderTitle,
  LedgerDetailHeaderTitleWrapper
} from './styled';
import MatchingChip from 'components/Chips/MatchingChip';
import CurrencyFlag from 'react-currency-flags';
import InfoIcon from 'assets/icons/InfoIcon';
import TooltipIcon from 'assets/icons/TooltipIcon';

export interface LedgerDetailHeaderProps {
  identifier: string;
  valueTitle?: string;
  value?: number;
  currency?: string;
  status?: string;
  isDiscrepant?: boolean;
  reduced?: boolean;
  type?: string;
  netAmount?: number;
  supplier?: string;
}
export interface LedgerDetailHeaderAllProps extends LedgerDetailHeaderProps {
  companyLogo: string;
  matchingStatus?: string;
}

const LedgerDetailHeader: FC<LedgerDetailHeaderAllProps> = ({
  companyLogo,
  identifier,
  valueTitle,
  value,
  currency,
  status,
  matchingStatus,
  isDiscrepant,
  reduced,
  type,
  netAmount,
  supplier
}) => {
  const {
    programType
  }: {
    programType: keyof typeof ProgramType;
  } = useSelector((state: RootStateOrAny) => state.app);

  return (
    <LedgerDetailContentHeader
      reduced={reduced}
      data-automation-id="ledger-detail-header-div-container"
      data-testid="sp-ledger-detail-header-container"
    >
      <LedgerDetailHeaderLeft data-automation-id="ledger-detail-header-div-header-left">
        <LedgerDetailHeaderAmountLabel data-automation-id="ledger-detail-header-h5-value-title">
          {supplier}
        </LedgerDetailHeaderAmountLabel>
        <LedgerDetailHeaderTitleWrapper>
          <LedgerDetailHeaderTitle data-automation-id="ledger-detail-header-h2-document-number">
            {identifier || ''}
          </LedgerDetailHeaderTitle>
          {status && (
            <Chip
              type={status}
              background={`${themeColors.bg.info}25`}
              color={themeColors.text.light.body}
              radius="4px"
              height="28px"
              fontSize={fontSizes.small}
              semibold="bold"
              testingTag="ledger-detail-header"
              tooltipText={
                type === LedgerTypes.INVOICE
                  ? getInvoiceStatusTooltipText(status)
                  : getPOStatusTooltipText(status)
              }
            />
          )}
          {matchingStatus && type === LedgerTypes.LOGISTIC && (
            <MatchingChip
              type={matchingStatus}
              testingTag="invoice-ledger"
              ledgerType={LedgerTypes.LOGISTIC}
            />
          )}
          {isDiscrepant &&
            (programType === ProgramType.OPEN_ACCOUNT ||
              programType === ProgramType.OA_EXTERNAL_FUNDING ||
              programType === ProgramType.OA_FUNDING) && (
              <IconTooltip
                tooltipText={
                  type
                    ? type === LedgerTypes.INVOICE
                      ? 'An issue was found on this invoice during the matching process. Your buyer will review the issue(s) and accept it for payment or contact you to amend the invoice.'
                      : 'An issue was found on the invoice(s) or logistics documents linked to this purchase order during the matching process.'
                    : ''
                }
                children={
                  <LedgerDetailHeaderIcon>
                    <TooltipIcon width="20px" height="20px" />
                  </LedgerDetailHeaderIcon>
                }
              />
            )}
        </LedgerDetailHeaderTitleWrapper>
      </LedgerDetailHeaderLeft>
      {!reduced && value && (
        <LedgerDetailHeaderRight data-automation-id="ledger-detail-header-div-header-right">
          <LedgerDetailHeaderAmountLabel data-automation-id="ledger-detail-header-h5-value-title">
            {valueTitle}
          </LedgerDetailHeaderAmountLabel>
          <LedgerDetailHeaderAmountValueContainer>
            <CurrencyFlag
              style={{ borderRadius: '50%', width: '24px', height: '24px' }}
              currency={currency || ''}
            />
            <LedgerDetailHeaderAmountValue data-automation-id="ledger-detail-header-h2-value">
              {currency} {formatNumber(value || 0, 2)}
            </LedgerDetailHeaderAmountValue>
          </LedgerDetailHeaderAmountValueContainer>
        </LedgerDetailHeaderRight>
      )}
    </LedgerDetailContentHeader>
  );
};

export default LedgerDetailHeader;
