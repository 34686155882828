import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ProfileIcon: FC<IconSvgProps> = ({
  height = '40',
  width = '40',
  color = themeColors.bg.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill={color}
      fillOpacity="0.14"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 24C15.3431 24 14 25.3431 14 27C14 27.5523 14.4477 28 15 28H25C25.5523 28 26 27.5523 26 27C26 25.3431 24.6569 24 23 24H17ZM12 27C12 24.2386 14.2386 22 17 22H23C25.7614 22 28 24.2386 28 27C28 28.6569 26.6569 30 25 30H15C13.3431 30 12 28.6569 12 27Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C18.3431 12 17 13.3431 17 15C17 16.6569 18.3431 18 20 18C21.6569 18 23 16.6569 23 15C23 13.3431 21.6569 12 20 12ZM15 15C15 12.2386 17.2386 10 20 10C22.7614 10 25 12.2386 25 15C25 17.7614 22.7614 20 20 20C17.2386 20 15 17.7614 15 15Z"
      fill={color}
    />
  </svg>
);

export default ProfileIcon;
