/* eslint-disable react-hooks/exhaustive-deps */
import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import {
  IOnboardingEntity,
  IOnboardingEntityStateProgramStatus,
  IOnboardingProgramData
} from 'api/interfaces/onboarding/onboarding.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import CheckIcon from 'assets/icons/CheckIcon';
import Pending2Icon from 'assets/icons/Pending2Icon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import ToDos from 'components/ToDos';
import {
  ToDoButtonContentContainer,
  ToDoButtonIconContainer,
  ToDoButtonText
} from 'components/ToDos/ToDo/styled';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import {
  OnboardingActionStatusEnum,
  OnboardingActionsEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  TRIGGER_SNACKBAR,
  UPDATE_BANK_ACCOUNT_FOR_EDIT,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM
} from 'store/actions';
import { SnackbarData } from 'store/reducers/app';
import GroupedToDo from 'utils/classes/todos/groupedToDo';
import ToDoBuilder from 'utils/classes/todos/todo';
import CompanyBankAccountDetails from 'views/CompanyDetails/CompanyBankAccountDetails';
import {
  OnboardingCompleteDescription,
  OnboardingCompleteProgressionHeading,
  OnboardingCompleteSetUpRow,
  OnboardingCompleteSetUpTitle,
  OnboardingCompleteStatusContainer,
  OnboardingCompleteStatusIndicator,
  OnboardingCompleteStatusIndicatorDescription
} from './styled';

interface IOnboardingCompleteSetUp {
  completeHandler: (isComplete: boolean) => void;
  accessToken:string;
}

const OnboardingCompleteSetUp: FC<IOnboardingCompleteSetUp> = ({ completeHandler, accessToken }) => {
  const dispatch = useDispatch();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [allToDos, setAllToDos] = useState<ToDoBuilder[]>([]);
  const [groupedToDos, setGroupedToDos] = useState<GroupedToDo[]>([]);
  const [companyAddBankDetailsOpen, setCompanyAddBankDetailsOpen] = useState<boolean>(false);
  const [submittedBankAccount, setSubmittedBankAccount] = useState<boolean>(false);
  const onboardingError: string = useSelector((state: RootStateOrAny) => state.onboarding.error);
  const {
    legalEntityInit,
    entityOnboardingActionsStateByProgram,
    platformAvailable
  }: {
    legalEntityInit: IOnboardingEntity | null;
    entityOnboardingActionsStateByProgram: IOnboardingProgramData[] | null;
    platformAvailable: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);
  const {
    loading: bankAccountLoading
  }: {
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.program);

  const bankAddNewSnackbarData: SnackbarData = {
    title: (
      <h5>
        {!!onboardingError.length
          ? 'Error adding bank account.'
          : 'Bank account successfully updated'}
      </h5>
    ),
    message: (
      <p>
        {!!onboardingError.length
          ? 'An error occurred adding the bank account. Please try again.'
          : 'You have successfully added the bank account. You can view or edit these details below.'}
      </p>
    ),
    leftIcon: !!onboardingError.length ? (
      <WarningIcon color={themeColors.icon.error} />
    ) : (
      <CheckIcon />
    ),
    topAligned: true,
    type: !!onboardingError ? 'error' : 'success'
  };

  const summaryToDo: ToDoBuilder = new ToDoBuilder(
    'Look out for our email',
    `Thanks for adding your bank account details. We'll be in touch shortly.`,
    false,
    <></>,
    <Pending2Icon />
  );

  const mockToDos: (name: string, programId: string) => GroupedToDo = (name, programId) => {
    if (!entityOnboardingActionsStateByProgram)
      return new GroupedToDo(name, 'Requirements from your buyer', []);

    const matchedProgram = entityOnboardingActionsStateByProgram.find(
      (d) => d.programOnboardingData.programId === programId
    );

    if (!matchedProgram)
      throw new Error(
        'Configuration error: can not find a onboarding by program config to match the program.'
      );

    return new GroupedToDo(name, 'Requirements from your buyer', [
      new ToDoBuilder(
        'Set up your receiving bank account',
        'Add a bank account so that you can receive payments from your buyers.',
        matchedProgram.programOnboardingData.state.actions.bankAccount.rawState === 'uploaded' ||
          matchedProgram.programOnboardingData.state.actions.bankAccount.rawState === 'completed',
        (
          <PrimaryButton
            clickHandler={() => {
              if (!companyAddBankDetailsOpen)
                dispatch({
                  type: UPDATE_BANK_ACCOUNT_FOR_EDIT,
                  payload: null
                });

              if (legalEntityInit) {
                dispatch({
                  type: UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM,
                  payload: {
                    actionName: OnboardingActionsEnum.bankAccount,
                    data: new OnboardingActionEventPut(
                      '',
                      '',
                      OnboardingActionStatusEnum.initiated
                    ),
                    programId
                  }
                });
              }
              toggleAddBankDetailsClickHandler();
            }}
            disabled={!platformAvailable}
            testingTag="onboarding-button-todo"
          >
            <ToDoButtonContentContainer>
              <ToDoButtonText
                color={
                  platformAvailable
                    ? `${themeColors.text.onPrimary}`
                    : `${themeColors.text.primaryDisabled}`
                }
                data-automation-id="onboarding-button-todo-text"
                data-testid="sp-onboarding-button-todo-text"
              >
                Add bank account
              </ToDoButtonText>
              <ToDoButtonIconContainer
                data-automation-id="onboarding-button-todo-icon-container"
                data-testid="onboarding-button-todo-button-icon"
              >
                <RightLongArrowIcon
                  color={
                    platformAvailable
                      ? themeColors.text.onPrimary
                      : themeColors.text.primaryDisabled
                  }
                  height="16"
                  width="16"
                />
              </ToDoButtonIconContainer>
            </ToDoButtonContentContainer>
          </PrimaryButton>
        )
      )
    ]);
  };

  useEffect(() => {
    init(legalEntityInit?.state.programStatus || {});
  }, []);

  useEffect(() => {
    if (submittedBankAccount && !bankAccountLoading) window.location.reload();
  }, [bankAccountLoading, submittedBankAccount]);

  const init: (data: IOnboardingEntityStateProgramStatus) => void = async (data) => {
    const onboardingApi = new OnboardingApi(accessToken);
    const onboardingProgramResponse = await Promise.all(
      Object.keys(data).map(async (d) => await onboardingApi.getEntityOnboardingProgramDetails(d))
    );

    const grouped: GroupedToDo[] = onboardingProgramResponse.map((p) =>
      mockToDos(p.spec.name, p.id)
    );

    const flattened = grouped.flatMap((g) => g.todos);

    setGroupedToDos(grouped);
    setIsComplete(flattened.map((t) => t.isComplete).every((f) => f));
    completeHandler(flattened.map((t) => t.isComplete).every((f) => f));
    setAllToDos(flattened);
  };

  useEffect(() => {
    if (onboardingError) {
      dispatch({
        type: TRIGGER_SNACKBAR,
        payload: { open: true, data: bankAddNewSnackbarData }
      });
      setTimeout(() => {
        dispatch({ type: TRIGGER_SNACKBAR, payload: { open: false, data: null } });
      }, 15000);
    }
  }, [onboardingError]);

  const calculateCompletedToDos: () => number = () => allToDos.filter((t) => t.isComplete).length;

  const toggleAddBankDetailsClickHandler: () => void = () =>
    setCompanyAddBankDetailsOpen(!companyAddBankDetailsOpen);

  const bankAccountSaveHandler: () => void = () => {
    setCompanyAddBankDetailsOpen(!companyAddBankDetailsOpen);
    setSubmittedBankAccount(true);
  };

  return submittedBankAccount ? (
    <LoaderFullPage />
  ) : (
    <BaseCard noBorder noBoxShadow margin="0 0 50px 0">
      <OnboardingCompleteSetUpTitle data-automation-id="onboarding-h3-complete-set-up">
        Complete setup
      </OnboardingCompleteSetUpTitle>
      <OnboardingCompleteSetUpRow>
        <OnboardingCompleteStatusContainer>
          <OnboardingCompleteStatusIndicator
            data-automation-id="onboarding-div-complete-set-up-indicator"
            color={isComplete ? themeColors.bg.success : themeColors.bg.warning}
          />
          <OnboardingCompleteStatusIndicatorDescription data-automation-id="onboarding-p-complete-set-up-indicator-description">
            {isComplete ? 'Completed' : 'In progress'}
          </OnboardingCompleteStatusIndicatorDescription>
        </OnboardingCompleteStatusContainer>
        <OnboardingCompleteProgressionHeading data-automation-id="onboarding-h4-complete-set-up-progression-text">
          {calculateCompletedToDos()} / {allToDos.length} complete
        </OnboardingCompleteProgressionHeading>
      </OnboardingCompleteSetUpRow>
      {!isComplete && (
        <OnboardingCompleteDescription>
          Before you can transact on Supplier Pay, you need to complete the following steps:
        </OnboardingCompleteDescription>
      )}
      {groupedToDos && <ToDos groupedToDos={groupedToDos} summaryToDo={summaryToDo} />}
      {companyAddBankDetailsOpen && (
        <FullViewDialog
          open={companyAddBankDetailsOpen}
          clickHandler={toggleAddBankDetailsClickHandler}
          dialogContent={
            <CompanyBankAccountDetails
              closeHandler={toggleAddBankDetailsClickHandler}
              saveHandler={bankAccountSaveHandler}
              isOnboarding
            />
          }
        />
      )}
    </BaseCard>
  );
};

export default OnboardingCompleteSetUp;
