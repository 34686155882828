import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const CompanyIcon: FC<IconSvgProps> = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#3D3AFF"
      fillOpacity="0.14"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5528 10.1056C21.1827 9.79069 21.918 10.2081 21.9936 10.8849L22 11.0001V14.4649L27.5547 18.168C27.7981 18.3303 27.9565 18.5896 27.9923 18.8759L28 19.0001V28.0001H29C29.5523 28.0001 30 28.4478 30 29.0001C30 29.5129 29.614 29.9356 29.1166 29.9933L29 30.0001H27.002L27 30.0001L26.998 30.0001H21H13H11C10.4477 30.0001 10 29.5524 10 29.0001C10 28.4872 10.386 28.0646 10.8834 28.0068L11 28.0001H12V15.0001C12 14.6634 12.1691 14.3524 12.4446 14.1685L12.5528 14.1056L20.5528 10.1056ZM26 28.0001V19.5351L22 16.8685V28.0001H26ZM20 14.9846C19.9999 14.9943 19.9999 15.004 20 15.0137V28.0001H14V15.6181L20 12.6181V14.9846ZM17 16.0001C17.5128 16.0001 17.9355 16.3861 17.9933 16.8834L18 17.0101C18 17.5624 17.5523 18.0101 17 18.0101C16.4872 18.0101 16.0645 17.624 16.0067 17.1267L16 17.0001C16 16.4478 16.4477 16.0001 17 16.0001ZM17.9933 19.8834C17.9355 19.3861 17.5128 19.0001 17 19.0001C16.4477 19.0001 16 19.4478 16 20.0001L16.0067 20.1267C16.0645 20.624 16.4872 21.0101 17 21.0101C17.5523 21.0101 18 20.5624 18 20.0101L17.9933 19.8834ZM17 22.0001C17.5128 22.0001 17.9355 22.3861 17.9933 22.8834L18 23.0101C18 23.5624 17.5523 24.0101 17 24.0101C16.4872 24.0101 16.0645 23.624 16.0067 23.1267L16 23.0001C16 22.4478 16.4477 22.0001 17 22.0001ZM17.9933 25.8834C17.9355 25.3861 17.5128 25.0001 17 25.0001C16.4477 25.0001 16 25.4478 16 26.0001L16.0067 26.1267C16.0645 26.624 16.4872 27.0101 17 27.0101C17.5523 27.0101 18 26.5624 18 26.0101L17.9933 25.8834Z"
      fill="#3D3AFF"
    />
  </svg>
);

export default CompanyIcon;
