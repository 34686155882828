import { Breadcrumbs } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { fontWeights, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  padding-left: ${isMobile() ? '16px' : '100px'};
  padding-bottom: ${isMobile() ? '16px' : '0'};
  margin-bottom: ${isMobile() ? '16px' : '0'};
  margin-top: ${isMobile() ? '0' : '-12px'};
  background-color: ${isMobile() ? `${themeColors.white}` : 'transparent'};
`;
export const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  flex-direction: row;
  padding: 0;
  color: ${themeColors.text.light.subtle};
  ${typography.captionRegular};

  .MuiBreadcrumbs-separator {
    margin-left: 6px;
    margin-right: 6px;
    font-weight: ${fontWeights.bold};
    color: ${themeColors.icon.light.default};
  }

  .MuiBreadcrumbs-li {
    color: ${themeColors.text.light.subtle};
    ${typography.captionRegular};
  }
  a {
    color: ${themeColors.text.light.subtle};
    ${typography.captionRegular};
    text-decoration: none;

    &:hover {
      color: ${themeColors.text.light.onSubtle};
    }
  }
  p {
    color: ${themeColors.text.light.subtle};
    ${typography.captionRegular};
    margin: 0;
  }
`;
