import { LedgerDetailHeaderProps } from 'components/ledger/LedgerDetail/LedgerDetailHeader';
import { RESET_DOCUMENT_FOR_VIEW, SET_DOCUMENT_FOR_VIEW } from 'store/actions';
import { ReducerAction } from '.';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';

interface ContextReducerStateProps {
  documentForViewHeaderData: {
    header: LedgerDetailHeaderProps | null;
    data: ILedgerInvoice | ILedgerPurchaseOrder | null;
  } | null;
}

const DEFAULT_STATE: ContextReducerStateProps = {
  documentForViewHeaderData: null
};

const contextReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case SET_DOCUMENT_FOR_VIEW:
      return {
        ...state,
        documentForViewHeaderData: payload
      };
    case RESET_DOCUMENT_FOR_VIEW:
      return {
        ...state,
        documentForViewHeaderData: null
      };
    default:
      return state;
  }
};

export { contextReducer };
export type { ContextReducerStateProps };
