export const convertNumberToShortString: (val: number, decimalPlaces: number) => string = (
  val: number,
  decimalPlaces: number
) =>
{

  if (val < 1000) {
    return val.toFixed(0);
  } else if (val < 1000000) {
    return (val / 1000).toFixed(decimalPlaces) + 'K';
  } else if (val < 1000000000) {
    return (val / 1000000).toFixed(decimalPlaces) + 'M';
  } else {
    return (val / 1000000000).toFixed(decimalPlaces) + 'B';
  }
};

