import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  NotFoundPageContainer,
  NotFoundPageContainerDescription,
  NotFoundPageContainerError,
  NotFoundPageContainerPageTitle,
  NotFoundPageContentContainer
} from './styled';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();

  return (
    <NotFoundPageContainer
      data-automation-id="NotFound-div-container"
      data-testid="sp-notFound-container"
    >
      <NotFoundPageContentContainer>
        <NotFoundPageContainerError>404</NotFoundPageContainerError>
        <NotFoundPageContainerPageTitle>Page not found</NotFoundPageContainerPageTitle>
        <NotFoundPageContainerDescription>
          We're sorry, but the page you are looking for doesn't seem to exist. This might be due to
          a mistyped address or an outdated link.
        </NotFoundPageContainerDescription>
        <PrimaryButton
          clickHandler={() => navigate('/')}
          testingTag="not-found-page-button-home"
          testingTagExt="-not-found-page-button-home"
          padding="16px 24px"
          height="4rem"
          width='fit-content'
        >
          <NotFoundPageContainerDescription>Back to home page</NotFoundPageContainerDescription>
        </PrimaryButton>
        <NotFoundPageContainerDescription>
          If you believe this is an error, please{' '}
          <a
            style={{ color: themeColors.text.primaryLink }}
            href="https://supplier.support.kanexa.net/hc/en-us/requests/new"
          >
            report this issue
          </a>{' '}
          so we can resolve it promptly.
        </NotFoundPageContainerDescription>
      </NotFoundPageContentContainer>
    </NotFoundPageContainer>
  );
};

export default NotFoundPage;
