/* eslint-disable react-hooks/exhaustive-deps */
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EntityApi from 'api/entity/entity.api';
import { EntityBankAccount } from 'api/interfaces/entity/entity.interface';
import { FundingRequest as FundingRequestType } from 'api/interfaces/funding-request/fundingRequest.interface';
import { FundingRequestCalculation } from 'api/interfaces/funding-request/fundingRequestCalculation.interface';
import { FundingResponse as FundingResponseType } from 'api/interfaces/funding-response/fundingResponse.interface';
import { LinkedPayments as LinkedPaymentsType } from 'api/interfaces/linked-payments/linkedPayments.interface';
import PaymentPendingIcon from 'assets/icons/PaymentPendingIcon';
import Pending2Icon from 'assets/icons/Pending2Icon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import Timeline from 'components/common/Timeline';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import TransactionSummaryDetail from 'components/funding-request/TransactionHistory/TransactionSummaryDetail';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { x500Formatter } from 'lib/helpers/formatters/x500Formatter';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { store } from 'store';
import TimelineItem from 'utils/classes/timeline/timelineItem';
import {
  TransactionBankAccountUnknown,
  TransactionHistoryContainer,
  TransactionHistoryDetail,
  TransactionHistoryRow,
  TransactionPaymentDetail
} from './styled';

export interface TransactionProps {
  paymentType?: 'MATURING' | 'POST_EARLY_PAYMENT' | 'TRADE';
  currencyCode: string;
  loading: boolean;
  funder?: string;
  status?: string;
  fundingRequest?: FundingRequestType;
  fundingResponse?: FundingResponseType;
  linkedPayments?: LinkedPaymentsType[];
  paidDate?: string;
  bankAccountId?: string | null;
  isEarlyPaymentSent?: boolean;
  earlyPaymentData?: FundingRequestCalculation;
  entityName?: string;
}

export interface MappedSummary {
  label: string;
  value: string;
}

const TransactionHistory: FC<TransactionProps> = ({
  paymentType,
  currencyCode,
  loading,
  funder,
  fundingRequest,
  fundingResponse,
  linkedPayments,
  paidDate,
  bankAccountId,
  isEarlyPaymentSent,
  earlyPaymentData,
  status,
  entityName
}) => {
  const [bankAccount, setBankAccount] = useState<EntityBankAccount | null>(null);
  const [bankAccountView, setBankAccountView] = useState<boolean>(false);

  const formatDate: string = 'ff a';
  const currencySymbol: string = CurrencySymbolsLookUp[currencyCode];
  const requestCreatedAt: string = formatDateTime(fundingRequest?.createdAt || '', formatDate);
  const responseCreatedAt: string = fundingResponse
    ? formatDateTime(fundingResponse?.expectedTradePaymentDate || '', formatDate)
    : requestCreatedAt;
  const invoicesTotalCount: number = fundingRequest?.invoicesTotalCount || 0;
  const invoicesTotalPurchasePrice: number = fundingRequest?.invoicesTotalPurchasePrice || 0;
  const invoicesTotalFundingCost: number = fundingRequest?.invoicesTotalFundingCost || 0;

  const bankAccountLoading: boolean = useSelector((state: RootStateOrAny) => state.entity.loading);

  useEffect(() => {
    if (bankAccountId) retrieveBankAccount(bankAccountId);
  }, [bankAccountId]);

  const retrieveBankAccount: (id: string) => void = async (id) => {
    const entityApi = new EntityApi(store);
    const response = await entityApi.getBankAccountById(id);
    setBankAccount(response.data);
  };

  const unknownAccount: JSX.Element = (
    <TransactionBankAccountUnknown data-automation-id="transaction-history-p-unknown-account">
      The bank account is unknown.
    </TransactionBankAccountUnknown>
  );

  const summaryData: MappedSummary[] = [
    {
      label: 'Total payment value',
      value: `${currencySymbol}${formatNumber(invoicesTotalPurchasePrice, 2)}`
    },
    {
      label: 'Total cost of funding',
      value: `${currencySymbol}${formatNumber(invoicesTotalFundingCost, 2)}`
    },
    {
      label: 'Funder',
      value: `${funder || ''}`
    }
  ];

  const earlyPaymentSummaryData: MappedSummary[] = [
    {
      label: 'Total payment value',
      value: `${CurrencySymbolsLookUp[earlyPaymentData?.currency || '']}${formatNumber(
        earlyPaymentData?.purchasePrice || 0,
        2
      )}`
    },
    {
      label: 'Total cost of funding',
      value: `${CurrencySymbolsLookUp[earlyPaymentData?.currency || '']}${formatNumber(
        earlyPaymentData?.totalFundingCosts || 0,
        2
      )}`
    },
    {
      label: 'Funder',
      value: `${
        earlyPaymentData?.invoicesSummary[0]?.funderX500
          ? x500Formatter(earlyPaymentData?.invoicesSummary[0].funderX500).OU
          : ''
      }`
    }
  ];

  const renderBankDetailItem: (label: string, item: string, viewable?: boolean) => JSX.Element = (
    label,
    item,
    viewable
  ) =>
    item && bankAccount ? (
      <p
        data-automation-id={`transaction-history-p-label-value-${toKebabCase(label)}`}
        style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}
      >
        {`${label}: ${item}`}{' '}
        {viewable ? (
          bankAccountView ? (
            <VisibilityOffIcon
              onClick={() => setBankAccountView(!bankAccountView)}
              style={{ color: themeColors.icon.error, fontSize: '20px', cursor: 'pointer' }}
            />
          ) : (
            <VisibilityIcon
              onClick={() => setBankAccountView(!bankAccountView)}
              style={{
                color: themeColors.icon.light.inactive,
                fontSize: '20px',
                cursor: 'pointer'
              }}
            />
          )
        ) : (
          ''
        )}
      </p>
    ) : (
      <></>
    );

  const bankDetails: JSX.Element = (
    <TransactionPaymentDetail data-automation-id="transaction-history-div-bank-account">
      {paymentType === 'TRADE' &&
        `${entityName} has approved your early payment request. Payment will be made to your bank account:`}
      {paymentType === 'MATURING' && `${entityName} will make this payment to your bank account:`}
      <br />
      <br />
      {renderBankDetailItem('Account name', bankAccount?.accountName || '')}
      {renderBankDetailItem(
        'Account number',
        bankAccount?.accountNumber
          ? bankAccountView
            ? bankAccount.accountNumber
            : bankAccount.accountNumber.replace(/.(?=.{4,}$)/g, 'X')
          : '',
        true
      )}
      {renderBankDetailItem('Sort code', bankAccount?.sortCode || '')}
      {renderBankDetailItem('IBAN', bankAccount?.iban || '')}
      {renderBankDetailItem('Routing Number', bankAccount?.routingNumber || '')}
    </TransactionPaymentDetail>
  );

  const earlyPaymentTimelineData: TimelineItem[] = [
    new TimelineItem(
      earlyPaymentData?.invoicesCount === 1
        ? `You requested early payment on ${earlyPaymentData?.invoicesCount} invoice`
        : `You requested early payment on ${earlyPaymentData?.invoicesCount || 0} invoices`,
      formatDateTime(DateTime.now().toISO(), formatDate),
      true,
      true,
      <ApproveIcon color={themeColors.icon.success} />,
      <></>,
      true
    ),
    new TimelineItem(
      'We calculated the terms for early payment',
      formatDateTime(DateTime.now().toISO(), formatDate),
      false,
      false,
      <ApproveIcon color={themeColors.icon.success} />,
      (
        <TransactionHistoryDetail>
          {earlyPaymentSummaryData.map((summary, key) => (
            <TransactionSummaryDetail label={summary.label} value={summary.value} key={key} />
          ))}
        </TransactionHistoryDetail>
      ),
      true
    ),
    new TimelineItem(
      'We sent the early payment request to the funder',
      'Pending since ' + formatDateTime(DateTime.now().toISO(), formatDate),
      true,
      true,
      <Pending2Icon />,
      <></>,
      true
    ),
    new TimelineItem(
      "We're waiting to hear from the funder",
      'Pending',
      true,
      false,
      <PaymentPendingIcon />,
      <></>,
      true
    ),
    new TimelineItem(
      'Payment instruction will be sent',
      'Pending',
      true,
      false,
      <PaymentPendingIcon />,
      <></>,
      true
    )
  ];

  const fundersResponse: () => TimelineItem = () => {
    switch (status) {
      case 'FUNDING_REQUEST_REJECTED':
        return new TimelineItem(
          `The funder rejected this early payment request`,
          fundingRequest?.rejectionReason || 'No rejection comment left by funder.',
          true,
          false,
          (
            <HighlightOffIcon
              data-automation-id="transaction-history-rejected-svg-status"
              style={{ color: themeColors.icon.error }}
            />
          ),
          <></>,
          true
        );
      case 'FUNDING_REQUEST_EXPIRED':
        return new TimelineItem(
          `The funding request has expired.`,
          `Expired on: ${formatDateTime(fundingRequest?.expires || '', formatDate)}`,
          true,
          false,
          (
            <HighlightOffIcon
              data-automation-id="transaction-history-rejected-svg-status"
              style={{ color: themeColors.icon.error }}
            />
          ),
          <></>,
          true
        );
      default:
        return new TimelineItem(
          fundingResponse
            ? 'The funder has approved your early payment request'
            : "We're waiting to hear from the funder",
          fundingResponse ? responseCreatedAt : 'Pending since ' + responseCreatedAt,
          true,
          false,
          fundingResponse ? <ApproveIcon color={themeColors.icon.success} /> : <Pending2Icon />,
          <></>,
          true
        );
    }
  };

  const fundingData: TimelineItem[] = [
    new TimelineItem(
      invoicesTotalCount === 1
        ? `You requested early payment on ${invoicesTotalCount} invoice`
        : `You requested early payment on ${invoicesTotalCount} invoices`,
      requestCreatedAt,
      true,
      true,
      <ApproveIcon color={themeColors.icon.success} />,
      <></>,
      true
    ),
    new TimelineItem(
      'Early payment calculated',
      requestCreatedAt,
      false,
      false,
      <ApproveIcon color={themeColors.icon.success} />,
      (
        <TransactionHistoryDetail>
          {summaryData.map((summary, key) => (
            <TransactionSummaryDetail label={summary.label} value={summary.value} key={key} />
          ))}
        </TransactionHistoryDetail>
      ),
      true
    ),
    new TimelineItem(
      'Early payment request sent',
      requestCreatedAt,
      true,
      true,
      <ApproveIcon color={themeColors.icon.success} />,
      <></>,
      true
    ),
    fundersResponse()
  ];

  if (status !== 'FUNDING_REQUEST_REJECTED' && status !== 'FUNDING_REQUEST_EXPIRED')
    fundingData.push(
      new TimelineItem(
        paidDate ? 'Payment instruction sent' : 'Payment instruction will be sent',
        paidDate
          ? formatDateTime(
              paidDate ? DateTime.fromFormat(paidDate, 'yyyy-MM-dd HH:mm').toISO() : '',
              formatDate
            )
          : 'Pending',
        !Boolean(bankAccountId),
        false,
        paidDate ? <ApproveIcon color={themeColors.icon.success} /> : <PaymentPendingIcon />,
        Boolean(bankAccountId) ? bankDetails : unknownAccount,
        paidDate ? true : false
      )
    );

  const maturingFundingData: TimelineItem[] = [
    new TimelineItem(
      'Payment instruction sent',
      formatDateTime(
        paidDate ? DateTime.fromFormat(paidDate, 'yyyy-MM-dd HH:mm').toISO() : '',
        formatDate
      ),
      !Boolean(bankAccountId),
      false,
      <ApproveIcon color={themeColors.icon.success} />,
      Boolean(bankAccountId) ? bankDetails : unknownAccount,
      true
    )
  ];

  const setMappedFundingData: () => TimelineItem[] = () => {
    switch (paymentType) {
      case 'MATURING':
        return maturingFundingData;
      case 'POST_EARLY_PAYMENT':
        return earlyPaymentTimelineData;
      case 'TRADE':
        return earlyPaymentTimelineData;
      default:
        return fundingData;
    }
  };

  return (
    <TransactionHistoryContainer>
      {loading && bankAccountLoading ? (
        <LoaderInPage />
      ) : (
        setMappedFundingData().map((transaction, index, array) => (
          <TransactionHistoryRow key={index}>
            <Timeline
              title={transaction.title}
              info={transaction.info}
              disabled={transaction.disabled}
              displayChildren={transaction.displayChildren}
              icon={transaction.icon}
              opacity={transaction.opacity || false}
              childJSX={transaction.childJSX}
            />
            {array.length > index + 1 ? <Divider /> : <span></span>}
          </TransactionHistoryRow>
        ))
      )}
    </TransactionHistoryContainer>
  );
};

export default TransactionHistory;
