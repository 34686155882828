import OutlinedInput from '@mui/material/OutlinedInput';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Lottie from 'lottie-react';
import styled from 'styled-components';

interface RecentActivityItemContainerProps {
  index: number;
  length: number;
  isLast: boolean;
}

export const RecentActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const RecentActivityItemContainer = styled.div<RecentActivityItemContainerProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: ${({ index, length, isLast }) => (length - 1 === index && !isLast ? '16px' : '')};
`;

export const RecentActivitySegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RecentActivityHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const RecentActivityTitle = styled.h3`
  margin: 0;
`;

export const AllRecentActivityTitle = styled.h4``;

export const RecentActivityLink = styled.p`
  color: ${themeColors.text.primary};
  ${typography.link};
  margin: 0;
  transform: translateY(1px);
`;

export const LottieAnimation = styled(Lottie)`
  display: flex;
  height: 25px;
`;

export const RecentActivityButtonText = styled.p`
  color: ${themeColors.text.onPrimary};
  ${typography.bodySmallSemiBold};
`;

export const RecentActivityButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : '206px'};
  margin-top: 32px;
`;

export const RecentActivityIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const RecentActivityButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const AllRecentActivitySearchButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const AllRecentActivitySearchInput = styled(OutlinedInput)`
  display: flex;
  flex: 1;
  background-color: ${themeColors.bg.light.input}!important;  
  border: 1px solid ${themeColors.border.input}!important; 
  color: ${themeColors.text.light.input}!important; 
  
  &:hover {
    background-color: ${themeColors.bg.light.inputHover}!important; 
    border: 1px solid ${themeColors.border.inputHover}!important; 
    color: ${themeColors.text.light.inputHover}!important;  
  }

  &:focus, &:focus-visible {
    background-color: ${themeColors.bg.light.inputFocus}!important; 
    border: 1px solid ${themeColors.border.inputFocus}!important;  
    color: ${themeColors.text.light.inputFocus}!important;  
    outline: 1px solid ${themeColors.border.inputFocus}!important;  
  }
`;
