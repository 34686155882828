import styled from 'styled-components';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';

interface IWidth {
  fullWidth?: boolean;
}

export const GetPaidNowButtonWrapper = styled.div<IWidth>`
  display: flex;
  border-radius: 24px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '')};
`;

export const GetPaidNowText = styled.p`
  ${typography.bodySmallSemiBold};
  margin: 6px 0;
`;
