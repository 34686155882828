import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';
import img from './KX-logo.png';

// const MPNLogo: FC<IconSvgProps> = () => <img height="50px" src={img} alt="logo" />;

// export default MPNLogo;

const MPNLogo: FC<IconSvgProps> = () => (
  <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_298_2323)">
      <path
        d="M1.12422 27.6971C0.50746 27.6971 0.00738525 27.1969 0.00738525 26.58V1.13913C0.00738525 0.52226 0.50746 0.0220947 1.12422 0.0220947C1.74098 0.0220947 2.24105 0.52226 2.24105 1.13913V26.5809C2.24105 27.1977 1.74098 27.6979 1.12422 27.6979V27.6971Z"
        fill="url(#paint0_linear_298_2323)"
      />
      <path
        d="M16.1274 27.6971C15.8415 27.6971 15.5556 27.5879 15.3381 27.3703C14.9022 26.9343 14.9022 26.2274 15.3381 25.7914L20.7206 20.408C22.0266 19.1017 22.0266 16.976 20.7206 15.6698L15.3381 10.2863C14.9022 9.85035 14.9022 9.14345 15.3381 8.70748C15.774 8.2715 16.4808 8.2715 16.9167 8.70748L22.2991 14.0909C24.4761 16.2683 24.4761 19.8103 22.2991 21.9869L16.9167 27.3703C16.6983 27.5887 16.4133 27.6971 16.1274 27.6971Z"
        fill="url(#paint1_linear_298_2323)"
      />
      <path
        d="M33.5891 27.6971C33.3032 27.6971 33.0174 27.5879 32.7998 27.3703L27.4174 21.9869C25.2404 19.8095 25.2404 16.2675 27.4174 14.0909L32.7998 8.70748C33.2357 8.2715 33.9425 8.2715 34.3784 8.70748C34.8143 9.14345 34.8143 9.85035 34.3784 10.2863L28.9959 15.6698C27.6899 16.976 27.6899 19.1017 28.9959 20.408L34.3784 25.7914C34.8143 26.2274 34.8143 26.9343 34.3784 27.3703C34.16 27.5887 33.875 27.6971 33.5891 27.6971Z"
        fill="url(#paint2_linear_298_2323)"
      />
      <path
        d="M11.8983 27.6971C11.6124 27.6971 11.3266 27.5879 11.109 27.3703L5.72656 21.9869C3.54956 19.8095 3.54956 16.2675 5.72656 14.0909L11.109 8.70748C11.5449 8.2715 12.2517 8.2715 12.6876 8.70748C13.1235 9.14345 13.1235 9.85035 12.6876 10.2863L7.30512 15.6698C5.9991 16.976 5.9991 19.1017 7.30512 20.408L12.6876 25.7914C13.1235 26.2274 13.1235 26.9343 12.6876 27.3703C12.4692 27.5887 12.1842 27.6971 11.8983 27.6971Z"
        fill="url(#paint3_linear_298_2323)"
      />
      <path
        d="M43.5532 21.9808V13.2632H45.7913V18.8424H45.8039L49.576 15.8286H52.4428L48.8845 18.4688L52.644 21.9808H49.5257L47.2373 19.6893L45.7913 20.7603V21.9808H43.5532Z"
        fill="#808093"
      />
      <path
        d="M61.3924 22.1053C59.1039 22.1053 58.425 21.0343 58.425 20.1501C58.425 19.1413 59.0662 18.3567 60.814 18.3567H64.2969V18.2696C64.2969 17.4352 63.7939 17.0989 62.3731 17.0989C61.0654 17.0989 60.7385 17.4601 60.7385 17.8212C60.7385 17.8337 60.7385 17.871 60.7511 17.9333H58.6387C58.6261 17.8212 58.6261 17.7341 58.6261 17.7091C58.6261 16.5385 59.6949 15.7041 62.4611 15.7041C65.1645 15.7041 66.535 16.7004 66.535 18.4813V21.9808H64.2969C64.322 21.5573 64.3597 20.9969 64.3597 20.6108H64.3472C64.0957 21.4951 63.3538 22.1053 61.3924 22.1053ZM62.021 20.7105C63.5299 20.7105 64.2969 20.0629 64.2969 19.5523V19.5025H61.4929C60.7385 19.5025 60.4116 19.627 60.4116 20.0255C60.4116 20.4116 60.8391 20.7105 62.021 20.7105Z"
        fill="#808093"
      />
      <path
        d="M73.0055 21.9808V15.8286H75.2437L75.2185 17.622H75.2437C75.6334 16.4513 76.589 15.7041 78.538 15.7041C80.9395 15.7041 81.7317 16.8249 81.7317 18.3194V21.9808H79.4936V18.9172C79.4936 18.0828 79.0283 17.4227 77.5195 17.4227C76.2998 17.4227 75.2437 17.9458 75.2437 19.1787V21.9808H73.0055Z"
        fill="#808093"
      />
      <path
        d="M96.3209 18.8549V19.3655H90.3484C90.4616 20.1376 90.9645 20.7105 92.4608 20.7105C93.8313 20.7105 94.2462 20.3742 94.3343 19.9508H96.3209C96.258 21.1339 95.2144 22.1053 92.4608 22.1053C89.091 22.1053 88.0851 20.5237 88.0851 18.967C88.0851 16.9619 89.6191 15.7041 92.335 15.7041C95.0761 15.7041 96.3209 16.8498 96.3209 18.8549ZM94.3343 18.2197V18.1699C94.3343 17.6095 93.9067 17.0989 92.4608 17.0989C91.1531 17.0989 90.5873 17.4974 90.3987 18.2197H94.3343Z"
        fill="#808093"
      />
      <path
        d="M101.788 21.9808L104.781 18.8051L101.914 15.8286H104.818L106.428 17.6593L108.012 15.8286H110.539L107.61 18.8051L110.665 21.9808H107.761L106.076 20.0504L104.466 21.9808H101.788Z"
        fill="#808093"
      />
      <path
        d="M119.168 22.1053C116.88 22.1053 116.201 21.0343 116.201 20.1501C116.201 19.1413 116.842 18.3567 118.59 18.3567H122.073V18.2696C122.073 17.4352 121.57 17.0989 120.149 17.0989C118.842 17.0989 118.515 17.4601 118.515 17.8212C118.515 17.8337 118.515 17.871 118.527 17.9333H116.415C116.402 17.8212 116.402 17.7341 116.402 17.7091C116.402 16.5385 117.471 15.7041 120.237 15.7041C122.941 15.7041 124.311 16.7004 124.311 18.4813V21.9808H122.073C122.098 21.5573 122.136 20.9969 122.136 20.6108H122.123C121.872 21.4951 121.13 22.1053 119.168 22.1053ZM119.797 20.7105C121.306 20.7105 122.073 20.0629 122.073 19.5523V19.5025H119.269C118.515 19.5025 118.188 19.627 118.188 20.0255C118.188 20.4116 118.615 20.7105 119.797 20.7105Z"
        fill="#808093"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_298_2323"
        x1="32.1197"
        y1="-1.93772"
        x2="-12.1727"
        y2="20.6291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFF" />
        <stop offset="0.34" stopColor="#006DE6" />
        <stop offset="1" stopColor="#2200C9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_298_2323"
        x1="37.3097"
        y1="7.24199"
        x2="-6.9827"
        y2="29.8096"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFF" />
        <stop offset="0.34" stopColor="#006DE6" />
        <stop offset="1" stopColor="#2200C9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_298_2323"
        x1="40.9094"
        y1="14.3077"
        x2="-3.38304"
        y2="36.8745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFF" />
        <stop offset="0.34" stopColor="#006DE6" />
        <stop offset="1" stopColor="#2200C9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_298_2323"
        x1="36.0311"
        y1="5.73817"
        x2="-8.26216"
        y2="28.3058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFF" />
        <stop offset="0.34" stopColor="#006DE6" />
        <stop offset="1" stopColor="#2200C9" />
      </linearGradient>
      <clipPath id="clip0_298_2323">
        <rect width="124.526" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MPNLogo;
