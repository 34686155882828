import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const MobileInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MobileInvoiceBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 56px;
  border-bottom: 1px solid ${themeColors.bg.light.muted};
`;

export const MobileInvoiceBarSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MobileInvoiceSemiBoldText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const MobileInvoiceCaption = styled.p`
  margin: 0;
  ${typography.captionRegular};
`;

export const MobileInvoiceBarColumn = styled.div`
  display: flex;
  gap: 12px;
`;
