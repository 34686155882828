import {
  EntityBankAccount,
  EntityOwner,
  EntityProofOfAccount
} from 'api/interfaces/entity/entity.interface';
import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT_SUCCESS,
  FETCH_PROGRAM_OWNER_DETAILS,
  FETCH_PROGRAM_OWNER_DETAILS_FAILURE,
  FETCH_PROGRAM_OWNER_DETAILS_SUCCESS,
  FETCH_PROOF_OF_ACCOUNT,
  FETCH_PROOF_OF_ACCOUNT_FAILURE,
  FETCH_PROOF_OF_ACCOUNT_SUCCESS,
  RESET_PROOF_OF_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_FOR_EDIT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_COMPANY_LOGO,
  UPDATE_COMPANY_LOGO_FAILURE,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_PROGRAM_OWNER_DETAILS,
  UPDATE_PROGRAM_OWNER_DETAILS_FAILURE,
  UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface ProgramReducerStateProps extends StateBase {
  programOwnerDetails: EntityOwner | null;
  programOwnerBankAccounts: EntityBankAccount[];
  bankAccountForEdit: EntityBankAccount | null;
  proofOfAccount: EntityProofOfAccount | null;
  proofOfAccountFile: any;
}

const DEFAULT_STATE: ProgramReducerStateProps = {
  programOwnerDetails: null,
  programOwnerBankAccounts: [],
  bankAccountForEdit: null,
  proofOfAccount: null,
  proofOfAccountFile: null,
  loading: false,
  error: ''
};

const programReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case FETCH_PROGRAM_OWNER_DETAILS:
      return {
        ...DEFAULT_STATE,
        ...state,
        loading: true
      };
    case FETCH_PROGRAM_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        bankAccountForEdit: null,
        programOwnerDetails: payload.ownerDetails,
        programOwnerBankAccounts: payload.ownerBankAccounts,
        loading: false,
        error: ''
      };
    case FETCH_PROGRAM_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_PROGRAM_OWNER_DETAILS:
      return {
        ...state,
        loading: true
      };
    case UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        programOwnerDetails: payload,
        loading: false,
        error: ''
      };
    case UPDATE_PROGRAM_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case RESET_PROOF_OF_ACCOUNT:
      return {
        ...state,
        proofOfAccount: null
      };
    case FETCH_PROOF_OF_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case FETCH_PROOF_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        proofOfAccount: payload.metadata,
        proofOfAccountFile: payload.file,
        loading: false,
        error: ''
      };
    case FETCH_PROOF_OF_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_COMPANY_LOGO:
      return {
        ...state,
        loading: true
      };
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case UPDATE_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case ADD_BANK_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case ADD_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case UPDATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case UPDATE_BANK_ACCOUNT_FOR_EDIT:
      return {
        ...state,
        bankAccountForEdit: payload,
        loading: false,
        error: ''
      };
    default:
      return state;
  }
};

export { programReducer };
export type { ProgramReducerStateProps };
