import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface IDisabled {
  isDisabled: boolean;
}

export const OnboardingTermsAndConditionsButtonWrapper = styled.div`
  display: flex;
  width: 5.5rem; //88px
`;

export const OnboardingTermsAndConditionsContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const OnboardingTermsAndConditionsScrollContainer = styled.div`
  z-index: 100;
  background-color: transparent;
  height: 34rem;
  overflow-y: auto;
  margin-top: 16px;
`;

export const OnboardingTermsAndConditionsCheckboxMessage = styled.p<IDisabled>`
  margin: 0;
  ${typography.captionRegular};
  color: ${({ isDisabled }) => (isDisabled ? themeColors.text.light.muted : themeColors.text.light.body)};
`;
