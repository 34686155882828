/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';
import DataGrid from 'components/DataGrid';
import QueryBuilder from 'components/QueryBuilder';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import PurchaseOrderViewMobile from 'components/purchaseOrder/InvoicesViewMobile/purchaseOrderMobile';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { purchaseOrdersHeaders } from 'lib/data/purchase-orders/purchaseOrdersHeaders';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { isMobile } from 'lib/helpers/mobile';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState, KeyboardEvent } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router';
import { store } from 'store';
import { QueryDateRange, QueryItem, QueryRow } from 'utils/classes/query/query';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

import {
  PurchaseOrderFilterButtonTextWrapper,
  PurchaseOrderTitle,
  SearchAdvancedText,
  SearchContainer,
  SearchLeftContainer
} from './styled';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';
import Divider from 'components/common/divider/Divider';

const fieldDictionary: any = {
  purchaseOrderNumber: 'data/poNumber',
  buyer: 'data/buyerName',
  createdDate: 'data/createdDate',
  expiryDate: 'data/expiryDate',
  currency: 'data/currency',
  totalAmount: 'data/amount',
  availableAmount: 'consumption/availableAmount'
};

interface PurchaseOrdersProps {}

const PurchaseOrders: FC<PurchaseOrdersProps> = () => {
  const navigate: NavigateFunction = useNavigate();
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const [exportData, setExportData] = useState<DataGridRowItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const [orderBy, setOrderBy] = useState<string>('data/createdDate desc');
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [purchaseOrderLedger, setPurchaseOrderLedger] = useState<ILedgerPurchaseOrder[]>([]);
  const [purchaseOrderCount, setPurchaseOrderCount] = useState<number>(0);
  const [purchaseOrderLoading, setPurchaseOrderLoading] = useState<boolean>(false);
  const [purchaseOrderInitialLoading, setPurchaseOrderInitialLoading] = useState<boolean>(false);
  const [initialise, setInitialise] = useState<boolean>(false);
  const [querySet, setQuerySet] = useState<QueryItem[]>([]);

  const {
    allProgramsWithDetails,
    loading: appLoading,
    groupedPrograms
  }: {
    allProgramsWithDetails: ProgramConfig[];
    loading: boolean;
    groupedPrograms: IGroupedProgram[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const purchaseOrderApi = new PurchaseOrderApi(store);

  useEffect(() => {
    if (allProgramsWithDetails && !initialise) {
      getPurchaseOrderLedger(true);
      setInitialise(true);
    }
  }, [allProgramsWithDetails]);

  useEffect(() => {
    if (initialise) {
      getPurchaseOrderLedger();
    }
  }, [rowsPerPage, page, orderBy, activeQueries]);

  useEffect(() => {
    const hydratedQueryItems = applyQueryParamFilters(queryItems);
    setQuerySet(hydratedQueryItems);
  }, []);

  const applyQueryParamFilters: (queryItems: QueryItem[]) => QueryItem[] = (queryItems) => {
    const queryParams = new URLSearchParams(window.location.search);
    return [...queryItems].map((queryItem) => {
      const presetValue: string = queryParams.get(queryItem.filterValue) || '';
      return presetValue
        ? {
            ...queryItem,
            defaultComparator: 'eq',
            defaultValue: presetValue.toUpperCase()
          }
        : queryItem;
    });
  };

  const currencies: string[] =
    groupedPrograms.length > 1 ? groupedPrograms.map((program) => program.currency) : [];

  const queryItems: QueryItem[] = [
    {
      filterName: 'PO Number',
      filterValue: 'poNumber',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'PO Status',
      filterValue: 'poStatus',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Open',
          value: 'open'
        },
        {
          name: 'Fulfilled',
          value: 'fulfilled'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ]
    },
    {
      filterName: 'Buyer',
      filterValue: 'buyer',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Created Date',
      filterValue: 'createdDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Expiry Date',
      filterValue: 'expiryDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Discrepant',
      filterValue: 'isDiscrepant',
      componentType: QueryComponents.BOOLEAN
    },
    {
      filterName: 'Currency',
      filterValue: 'currency',
      componentType: QueryComponents.CURRENCY,
      display: currencies.length > 0
    },
    {
      filterName: 'Total Amount',
      filterValue: 'totalAmount',
      componentType: QueryComponents.NUMBER
    },
    {
      filterName: 'Available Amount',
      filterValue: 'availableAmount',
      componentType: QueryComponents.NUMBER
    }
  ];

  const searchHandler: (value: string) => void = (value) => {
    setSearchTerm(value);
  };

  const searchBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue === '') {
      getPurchaseOrderLedger();
      setPage(0);
    }
  };

  const searchHandleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === 'Enter') {
      getPurchaseOrderLedger();
      setPage(0);
    }
  };

  const clickSearchHandle: () => void = () => {
    getPurchaseOrderLedger();
    setPage(0);
  };

  const mapLedgerData: (ledger: ILedgerPurchaseOrder[]) => DataGridRowItem[] = (ledger) => {
    return ledger.map((po) => ({
      purchaseOrderId: po.purchaseOrderId,
      isDiscrepant:
        po.linkedPrograms.find((program) => program.baseType.includes(ProgramType.OPEN_ACCOUNT))
          ?.isDiscrepant || false,
      purchaseOrderNumber: po.data.poNumber,
      status: po.linkedPrograms[0].purchaseOrderStatus,
      buyer: po.data.buyerName,
      createdDate: po.data.createdDate
        ? formatDateTime(
            DateTime.fromFormat(po.data.createdDate || '', _DATE_FORMAT).toISO(),
            _DATE_FORMAT
          )
        : '',
      expiryDate: formatDateTime(
        DateTime.fromFormat(po.data.expiryDate || '', _DATE_FORMAT).toISO(),
        _DATE_FORMAT
      ),
      currency: po.data.currency,
      totalAmount: formatNumber(po.data.amount, 2),
      availableAmount:
        po.consumption?.availableAmount === null
          ? '-'
          : formatNumber(po.consumption?.availableAmount, 2),
      programName: po.linkedPrograms[0]?.programName
    }));
  };

  const getPurchaseOrderLedger: (initialLoading?: boolean) => void = async (
    initialLoading = false
  ) => {
    initialLoading ? setPurchaseOrderInitialLoading(true) : setPurchaseOrderLoading(true);

    const programIds = allProgramsWithDetails.map((p) => p.id);
    let filterSearch = ``;

    if (searchTerm.length > 0) {
      filterSearch += `and (contains(data/poNumber,'${searchTerm}') or contains(data/buyerName,'${searchTerm}') or contains(data/currency,'${searchTerm}'))`;
    }

    if (activeQueries.length > 0) {
      setShowAdvancedSearch(true);

      activeQueries.forEach((q: QueryRow) => {
        if (q.fieldValue === 'poNumber') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/poNumber, '${q.queryValue}')`
              : `and (data/poNumber ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'isDiscrepant') {
          filterSearch += `and linkedPrograms/any(f:f/isDiscrepant ${q?.comparator} ${q?.queryValue})`;
        } else if (q.fieldValue === 'poStatus') {
          filterSearch += `and linkedPrograms/any(f:f/purchaseOrderStatus ${q?.comparator} '${(
            q?.queryValue as string
          ).toLocaleUpperCase()}')`;
        } else if (q.fieldValue === 'createdDate') {
          filterSearch += ` and ((data/createdDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (data/createdDate le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          )
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'expiryDate') {
          filterSearch += ` and ((data/expiryDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (data/expiryDate le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          )
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'buyer') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/buyerName, '${q.queryValue}')`
              : `and (data/buyerName ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'currency') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/currency, '${q.queryValue}')`
              : `and (data/currency ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'totalAmount') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/amount, ${q.queryValue})`
              : `and (data/amount ${q.comparator} ${q.queryValue})`;
        } else if (q.fieldValue === 'availableAmount') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(consumption/availableAmount, ${q.queryValue})`
              : `and (consumption/availableAmount ${q.comparator} ${q.queryValue})`;
        }
      });
    }

    const purchaseOrderResponse = await purchaseOrderApi.getFilterPurchaseOrderLedger(
      programIds || '',
      rowsPerPage,
      page,
      filterSearch,
      orderBy
    );

    const purchaseOrderResponseAll = await purchaseOrderApi.getFilterPurchaseOrderLedger(
      programIds || '',
      null,
      null,
      filterSearch,
      orderBy
    );

    setData(mapLedgerData(purchaseOrderResponse?.data?.value || []));
    setExportData(mapLedgerData(purchaseOrderResponseAll?.data?.value || []));

    setPurchaseOrderLedger(purchaseOrderResponse?.data?.value || []);

    setPurchaseOrderCount(purchaseOrderResponse?.data['@odata.count'] || 0);

    initialLoading ? setPurchaseOrderInitialLoading(false) : setPurchaseOrderLoading(false);
  };

  const rowClickHandler: (row: any) => void = (row) => {
    const data: any = isMobile() ? row : row[0];
    const matchedIndex: number = purchaseOrderLedger.findIndex((p) => p.purchaseOrderId === row[0]);
    if (!~matchedIndex) return;
    navigate(`/purchase-orders/${data}`);
  };

  const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
    setPage(0);
  };

  const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) => {
    setPage(tableState.page);
  };

  const changePage: (page: number) => void = (page) => {
    setPage(page);
  };

  const columnSortChangeClickHandler = (col: any) => {
    const odataField: string | undefined = fieldDictionary[col];
    const sortable = Boolean(odataField);
    if (!sortable) return;
    const direction = orderDirection === 'asc' ? 'desc' : 'asc';
    setOrderDirection(direction);
    setOrderBy(`${fieldDictionary[col]} ${direction}`);
  };

  const renderTable: () => JSX.Element = () =>
    isMobile() ? (
      <PurchaseOrderViewMobile
        data={data}
        rowClickHandler={rowClickHandler}
        totalRows={purchaseOrderCount}
        page={page}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        rowsPerPageHandler={rowsPerPageHandler}
      />
    ) : (
      <DataGrid
        headers={purchaseOrdersHeaders}
        data={data}
        exportData={exportData}
        ariaLabel={''}
        loading={purchaseOrderLoading}
        ariaCaption={''}
        fixedHeader
        rowClickHandler={rowClickHandler}
        tableChangeHandler={tableChangeHandler}
        columnSortChangeClickHandler={columnSortChangeClickHandler}
        hasActions
        exportFilename="purchase_orders_ledger"
        smallDownloadButton={false}
        dataAutomationTag="purchase-order-ledger-table-ledger-items"
        page={page === -1 ? 0 : page}
        rowsPerPage={rowsPerPage}
        totalRows={purchaseOrderCount}
        changeRowsPerPageHandler={rowsPerPageHandler}
        showFooter={purchaseOrderCount > 15}
      />
    );

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    const clone = [...queries];

    const isUpdated =
      clone.length === activeQueries.length &&
      clone.some((cloneValue) => {
        return activeQueries.some(
          (activeValue) =>
            cloneValue.fieldValue === activeValue.fieldValue &&
            cloneValue.queryValue === activeValue.queryValue
        );
      });

    if (isUpdated || activeQueries.length !== clone.length) {
      setActiveQueries(clone);
      setPage(0);
    }
  };

  return purchaseOrderInitialLoading || appLoading ? (
    <LoaderFullPage />
  ) : (
    <LayoutViewContainer size="xlarge">
      <BaseCard noBorder noBoxShadow>
        <PurchaseOrderTitle
          data-automation-id="purchase-orders-page-title"
          data-testid="sp-page-title"
        >
          Purchase orders
        </PurchaseOrderTitle>
        <SearchContainer>
          <SearchLeftContainer>
            <OutlinedInput
              id="outlined-adornment-search"
              dataAutomationId="purchase-orders-input-fuzzy-search"
              dataTestId="sp-purchase-orders-input-fuzzy-search"
              defaultValue={searchTerm}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => searchBlurHandler(event)}
              changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                searchHandler(event.currentTarget.value)
              }
              handleKeyDown={(event: KeyboardEvent<HTMLInputElement>) => searchHandleKeyDown(event)}
              placeholder="Search by po number, buyer, currency"
            />

            <PrimaryButton
              clickHandler={clickSearchHandle}
              disabled={searchTerm === '' || searchTerm.length < 3}
              height="40px"
              width="40px"
            >
              <PurchaseOrderFilterButtonTextWrapper>
                <SearchIcon />
              </PurchaseOrderFilterButtonTextWrapper>
            </PrimaryButton>

            <SearchAdvancedText
              onClick={advancedSearchClickHandler}
              data-automation-id="ledger-items-advanced-search-text"
              data-testid="sp-advanced-search"
            >
              {showAdvancedSearch
                ? 'Hide advanced search'
                : `Advanced search ${activeQueries.length > 0 ? `(${activeQueries.length})` : ''}`}
            </SearchAdvancedText>
          </SearchLeftContainer>
        </SearchContainer>
        <QueryBuilder
          queryItems={querySet}
          activeQueriesHandler={activeQueriesHandler}
          closeClickHandler={advancedSearchClickHandler}
          open={showAdvancedSearch}
          testingTagPage="purchase-orders"
          currencies={currencies}
        />
        {!showAdvancedSearch && <Divider />}
        {renderTable()}
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default PurchaseOrders;
