import StoreBase from 'api/base/storeBase';
import { IUser } from 'api/interfaces/users/user.interface';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { oidcConfig } from 'lib/oidc/oidc.config';
import { store } from 'store';

const _PATH: string = '/users';

export default class UserManagementApi extends StoreBase {
  public getUsers: () => Promise<AxiosResponse<IUser[]>> = async () => {
    const nonInterceptedAxiosObj: AxiosInstance = Axios.create();
    const { app }: { app: any } = store.getState();

    nonInterceptedAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${app.accessToken}`;

    return await nonInterceptedAxiosObj.get<IUser[]>(`${window.API_PATH_INTERNAL}${_PATH}`);
  };

  // WHAT IS REQUIRED AT THE TIME FOR PW RESET IN KEYCLOAK
  public resetPassword: () => Promise<AxiosResponse<any>> = async () => {
    const nonInterceptedAxiosObj: AxiosInstance = Axios.create();

    return await nonInterceptedAxiosObj.get<any>(
      `${
        (oidcConfig as any).authority
      }/auth/realms/<realm>/protocol/openid-connect/auth?client_id=${
        (oidcConfig as any).client_id
      }&redirect_uri=${
        (oidcConfig as any).redirect_uri
      }&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`
    );
  };

  public getUserById: (userId: string) => Promise<AxiosResponse<IUser>> = async (userId) =>
    await this.axiosInstance.get<IUser>(`${window.API_PATH_INTERNAL}${_PATH}/${userId}`);

  public putUserData: (data: IUser, userId: string) => Promise<AxiosResponse<string>> = async (
    data,
    userId
  ) => await this.axiosInstance.put<string>(`${window.API_PATH_INTERNAL}${_PATH}/${userId}`, data);

  public postNewUserData: (data: IUser) => Promise<AxiosResponse<IUser>> = async (data) =>
    await this.axiosInstance.post<IUser>(`${window.API_PATH_INTERNAL}${_PATH}`, data);

  public putUserActivate: (userId: string) => Promise<AxiosResponse<string>> = async (userId) =>
    await this.axiosInstance.put<string>(`${window.API_PATH_INTERNAL}${_PATH}/${userId}/activate`);

  public putUserDeactivate: (userId: string) => Promise<AxiosResponse<string>> = async (userId) =>
    await this.axiosInstance.put<string>(
      `${window.API_PATH_INTERNAL}${_PATH}/${userId}/deactivate`
    );

  public putUserSuspend: (userId: string) => Promise<AxiosResponse<string>> = async (userId) =>
    await this.axiosInstance.put<string>(`${window.API_PATH_INTERNAL}${_PATH}/${userId}/suspend`);

  public putUserReset: (userId: string) => Promise<AxiosResponse<string>> = async (userId) =>
    await this.axiosInstance.put<string>(`${window.API_PATH_INTERNAL}${_PATH}/${userId}/reset`);
}
