import { FC } from 'react';
import { Link } from 'react-router-dom';
import { themeColors } from 'assets/theme/style';
import { CustomLinkContainer } from './styled';
export interface CustomLinkProps {
  link: string;
  title?: string;
  typography?: any;
  textColor?: string;
  textDecoration?: string;
  fontSize?: string;
  fontWeight?: number;
  target?: boolean;
}
const CustomLink: FC<CustomLinkProps> = ({
  link,
  title,
  textColor = themeColors.text.primary,
  textDecoration = 'none',
  fontSize = '16px',
  fontWeight = 700,
  typography,
  target
}) => (
  <CustomLinkContainer
    textColor={textColor}
    textDecoration={textDecoration}
    fontWeight={fontWeight}
    fontSize={fontSize}
    typography={typography}
  >
    {/^https?:\/\//.test(link) ? (
      <a
        data-automation-id="custom-link-a-link"
        data-testid="sp-custom-link"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {title}
      </a>
    ) : (
      <Link
        data-automation-id="custom-link-a-link"
        data-testid="sp-custom-link"
        to={link}
        target={target ? '_blank' : '_self'}
      >
        {title}
      </Link>
    )}
  </CustomLinkContainer>
);

export default CustomLink;
