import { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  DoughnutChartInnerContainer,
  DoughnutChartInnerTitle,
  DoughnutChartInnerValue,
  DoughnutChartTitle,
  DoughnutChartContainer,
  DoughnutStyled,
  DoughnutChartWrapper,
  DoughnutChartDataWrapper,
  DoughnutChartDataContainer,
  DoughnutChartDataIcon,
  DoughnutChartDataValueContainer,
  DoughnutChartDataValueBold,
  DoughnutChartDataValue,
  DoughnutChartDataLeft,
  DoughnutChartViewWrapper,
  SkeletonStyled,
  SkeletonCircularStyled
} from './styled';
import { themeColors } from 'assets/theme/style';
import { BarChartData } from 'utils/classes/charts/barChart';
import ButtonRightArrowIcon from 'assets/icons/ArrowIcons/ButtonRightArrowIcon';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { useNavigate } from 'react-router';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { RootStateOrAny, useSelector } from 'react-redux';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { convertNumberToShortString } from 'lib/helpers/formatters/convertNumberToShortString';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

interface IDoughnutChartProps {
  alignItems?: string;
  direction?: 'column' | 'row';
  justifyContent?: string;
}

const DoughnutChart: FC<IDoughnutChartProps> = ({
  alignItems = 'center',
  direction = 'column',
  justifyContent = 'space-around'
}) => {
  const navigate = useNavigate();

  const {
    activeCurrencyCode,
    approvedInvoicesTotal,
    discrepantInvoicesTotal,
    discrepantInvoicesTotalCount,
    pendingInvoicesTotal,
    pendingInvoicesTotalCount,
    approvedInvoices,
    internalLoading,
    groupedPrograms
  }: {
    approvedInvoices: ILedgerInvoice[];
    approvedInvoicesTotal: number;
    discrepantInvoicesTotal: number;
    discrepantInvoicesTotalCount: number;
    pendingInvoicesTotal: number;
    pendingInvoicesTotalCount: number;
    activeCurrencyCode: string;
    internalLoading: boolean;
    groupedPrograms: IGroupedProgram[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const approvedInvoicesTotalCount: number = approvedInvoices ? approvedInvoices.length : 0;
  const unpaidInvoicesTotal: number =
    pendingInvoicesTotal + approvedInvoicesTotal + discrepantInvoicesTotal;
  const isNoData: boolean = unpaidInvoicesTotal === 0;

  ChartJS.register(ArcElement, Tooltip, Legend);
  const data: BarChartData = {
    labels: ['Pending invoices', 'Discrepant invoices', 'Approved invoices'],
    datasets: [
      {
        label: '',
        data: isNoData
          ? [1]
          : [pendingInvoicesTotal, discrepantInvoicesTotal, approvedInvoicesTotal],
        backgroundColor: isNoData
          ? [themeColors.bg.light.surface]
          : [themeColors.chart.light.colour9, themeColors.icon.error, themeColors.bg.primary],
        borderColor: !isNoData ? ['#ffffff', '#ffffff', '#ffffff'] : [themeColors.bg.light.surface],
        borderWidth: !isNoData ? 1 : 0,
        hoverBackgroundColor: isNoData
          ? [themeColors.bg.light.surface]
          : [themeColors.chart.light.colour9, themeColors.icon.error, themeColors.bg.primary]
      }
    ]
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    responsive: true
  };

  const activeCurrencyParam: string =
    groupedPrograms.length > 1 ? `&currency=${activeCurrencyCode}` : '';

  const clickHandlerDiscrepant: () => void = () =>
    navigate(`/invoices?matchingStatus=Discrepant${activeCurrencyParam}`);
  const clickHandlerApproved: () => void = () =>
    navigate(`/invoices?invoiceStatus=Approved${activeCurrencyParam}`);
  const clickHandlerPending: () => void = () =>
    navigate(`/invoices?matchingStatus=Pending${activeCurrencyParam}`);

  return (
    <DoughnutChartWrapper data-testid="sp-doughnut-chart-container" direction={direction}>
      {!internalLoading && <DoughnutChartTitle>Invoice summary</DoughnutChartTitle>}
      <DoughnutChartViewWrapper
        alignItems={alignItems}
        direction={direction}
        justifyContent={justifyContent}
      >
        <DoughnutChartContainer>
          {internalLoading ? (
            <SkeletonCircularStyled variant="circular" />
          ) : (
            <DoughnutStyled data={data} options={options} />
          )}
          {!internalLoading && (
            <DoughnutChartInnerContainer>
              <DoughnutChartInnerTitle>
                Total unpaid
                <br />
                invoices
              </DoughnutChartInnerTitle>
              <DoughnutChartInnerValue>
                {activeCurrencyCode} {convertNumberToShortString(unpaidInvoicesTotal, 2)}
              </DoughnutChartInnerValue>
            </DoughnutChartInnerContainer>
          )}
        </DoughnutChartContainer>
        <DoughnutChartDataWrapper>
          <IconTooltip
            placement="top"
            backgroundColor={themeColors.bg.light.strong}
            color={themeColors.text.onPrimary}
            tooltipText="Invoices that have not been matched"
            children={
              internalLoading ? (
                <SkeletonStyled variant="rectangular" direction={direction} />
              ) : (
                <DoughnutChartDataContainer
                  direction={direction}
                  onClick={() => clickHandlerPending()}
                >
                  <DoughnutChartDataLeft>
                    <DoughnutChartDataIcon
                      colour={themeColors.chart.light.colour9}
                    ></DoughnutChartDataIcon>

                    <DoughnutChartDataValueContainer>
                      <DoughnutChartDataValueBold>
                        {activeCurrencyCode} {formatNumber(pendingInvoicesTotal || 0, 2)}
                      </DoughnutChartDataValueBold>
                      <DoughnutChartDataValue>
                        {pendingInvoicesTotalCount} Pending invoices
                      </DoughnutChartDataValue>
                    </DoughnutChartDataValueContainer>
                  </DoughnutChartDataLeft>
                  <ButtonRightArrowIcon />
                </DoughnutChartDataContainer>
              )
            }
          />
          <IconTooltip
            backgroundColor={themeColors.bg.light.strong}
            color={themeColors.text.onPrimary}
            placement="top"
            tooltipText="Invoices that have been matched but have discrepancies"
            children={
              internalLoading ? (
                <SkeletonStyled variant="rectangular" direction={direction} />
              ) : (
                <DoughnutChartDataContainer
                  direction={direction}
                  onClick={() => clickHandlerDiscrepant()}
                >
                  <DoughnutChartDataLeft>
                    <DoughnutChartDataIcon colour={themeColors.icon.error}></DoughnutChartDataIcon>

                    <DoughnutChartDataValueContainer>
                      <DoughnutChartDataValueBold>
                        {activeCurrencyCode} {formatNumber(discrepantInvoicesTotal || 0, 2)}
                      </DoughnutChartDataValueBold>
                      <DoughnutChartDataValue>
                        {discrepantInvoicesTotalCount} Discrepant invoices
                      </DoughnutChartDataValue>
                    </DoughnutChartDataValueContainer>
                  </DoughnutChartDataLeft>
                  <ButtonRightArrowIcon />
                </DoughnutChartDataContainer>
              )
            }
          />
          <IconTooltip
            placement="top"
            backgroundColor={themeColors.bg.light.strong}
            color={themeColors.text.onPrimary}
            tooltipText="Invoices that have been matched and approved"
            children={
              internalLoading ? (
                <SkeletonStyled variant="rectangular" direction={direction} />
              ) : (
                <DoughnutChartDataContainer
                  direction={direction}
                  onClick={() => clickHandlerApproved()}
                >
                  <DoughnutChartDataLeft>
                    <DoughnutChartDataIcon colour={themeColors.bg.primary}></DoughnutChartDataIcon>

                    <DoughnutChartDataValueContainer>
                      <DoughnutChartDataValueBold>
                        {activeCurrencyCode} {formatNumber(approvedInvoicesTotal || 0, 2)}
                      </DoughnutChartDataValueBold>
                      <DoughnutChartDataValue>
                        {approvedInvoicesTotalCount} Approved invoices
                      </DoughnutChartDataValue>
                    </DoughnutChartDataValueContainer>
                  </DoughnutChartDataLeft>
                  <ButtonRightArrowIcon />
                </DoughnutChartDataContainer>
              )
            }
          />
        </DoughnutChartDataWrapper>
      </DoughnutChartViewWrapper>
    </DoughnutChartWrapper>
  );
};

export default DoughnutChart;
