import { themeColors } from 'assets/theme/style';
import Divider from 'components/common/divider';
import { FC } from 'react';
import {
  SelectableItemTextBold,
  SelectableItemTextCaption,
  SelectableItemContainer,
  SelectableItemSegment,
  SelectableItemWrapper,
  SelectableItemLeftContainer,
  CheckboxStyled
} from './styled';

interface SelectableItemProps {
  clickHandler: (id: string) => void;
  id: string;
  checked: boolean;
  dataArrLength: number;
  index: number;
  isLast: boolean;
  title: string;
  subTitle: string;
  value: string;
  subValue: string;
}

const SelectableItem: FC<SelectableItemProps> = ({
  clickHandler,
  id,
  checked,
  dataArrLength,
  index,
  isLast,
  title,
  subTitle,
  value,
  subValue
}) => {
  return (
    <SelectableItemContainer index={index} length={dataArrLength} isLast={isLast}>
      <SelectableItemWrapper>
        <SelectableItemLeftContainer>
          <CheckboxStyled
            style={{
              color: themeColors.text.light.muted,
              backgroundColor: 'transparent',
              zIndex: 10
            }}
            checked={checked}
            onChange={() => clickHandler(id || '')}
            data-testid="sp-selectable-item"
            inputProps={
              {
                'data-testid': 'sp-clickable-selectable-item'
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
          <SelectableItemSegment>
            {title && <SelectableItemTextBold>{title}</SelectableItemTextBold>}
            {subTitle && <SelectableItemTextCaption>{subTitle}</SelectableItemTextCaption>}
          </SelectableItemSegment>
        </SelectableItemLeftContainer>
        <SelectableItemSegment style={{ textAlign: 'right' }}>
          <SelectableItemTextBold>{value}</SelectableItemTextBold>
          <SelectableItemTextCaption>{subValue}</SelectableItemTextCaption>
        </SelectableItemSegment>
      </SelectableItemWrapper>
      {dataArrLength !== index && <Divider margin="8px 0" />}
    </SelectableItemContainer>
  );
};

export default SelectableItem;
