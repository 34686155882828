import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IInvoiceMatchingResult } from 'api/interfaces/open-account/invoices/invoices.interface';
import {
  GET_INVOICE_COMMENTS,
  GET_INVOICE_COMMENTS_FAILURE,
  GET_INVOICE_COMMENTS_SUCCESS,
  GET_INVOICE_MATCHING_RESULTS,
  GET_INVOICE_MATCHING_RESULTS_FAILURE,
  GET_INVOICE_MATCHING_RESULTS_SUCCESS,
  POST_INVOICE_COMMENTS,
  POST_INVOICE_COMMENTS_FAILURE,
  POST_INVOICE_COMMENTS_SUCCESS,
  RESET_LEDGER_INVOICE,
  RESET_MATCHING_RESULTS,
  SET_INVOICE_COMMENTS,
  SET_LEDGER_INVOICE
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface InvoiceReducerStateProps extends StateBase {
  invoiceMatchingResults: IInvoiceMatchingResult | null;
  invoiceCommentsLoading: boolean;
  invoiceComments: IComment[];
  ledgerInvoice: ILedgerInvoice | null;
}

const DEFAULT_STATE: InvoiceReducerStateProps = {
  invoiceMatchingResults: null,
  invoiceCommentsLoading: false,
  invoiceComments: [],
  ledgerInvoice: null,
  error: '',
  loading: false
};

const invoiceReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case GET_INVOICE_MATCHING_RESULTS:
      return {
        ...state,
        loading: true
      };
    case GET_INVOICE_MATCHING_RESULTS_SUCCESS:
      return {
        ...state,
        invoiceMatchingResults: payload,
        loading: false
      };
    case GET_INVOICE_MATCHING_RESULTS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case SET_INVOICE_COMMENTS:
      return {
        ...state,
        invoiceComments: payload
      };
    case SET_LEDGER_INVOICE:
      return {
        ...state,
        ledgerInvoice: payload
      };
    case RESET_LEDGER_INVOICE:
      return {
        ...state,
        ledgerInvoice: null
      };
    case POST_INVOICE_COMMENTS:
      return {
        ...state,
        invoiceCommentsLoading: true
      };
    case POST_INVOICE_COMMENTS_SUCCESS:
      return {
        ...state,
        invoiceCommentsLoading: false,
        invoiceComments: payload.data
      };
    case POST_INVOICE_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        invoiceCommentsLoading: false
      };
    case GET_INVOICE_COMMENTS:
      return {
        ...state,
        invoiceCommentsLoading: true
      };
    case GET_INVOICE_COMMENTS_SUCCESS:
      return {
        ...state,
        invoiceCommentsLoading: false,
        invoiceComments: payload.data
      };
    case GET_INVOICE_COMMENTS_FAILURE:
      return {
        ...state,
        error: payload,
        invoiceCommentsLoading: false
      };
    case RESET_MATCHING_RESULTS:
      return {
        ...state,
        invoiceMatchingResults: null,
        loading: false
      };
    default:
      return state;
  }
};

export { invoiceReducer };
export type { InvoiceReducerStateProps };
