import StoreBase from 'api/base/storeBase';
import { ODataLedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ODataLogistics } from 'api/interfaces/logistics/logistics.interface';
import { AxiosResponse } from 'axios';

const _PATH: string = '/ledger/supplier-pay/odata/logistics';

export default class LogisticsApi extends StoreBase {
  public getLogisticsLedgerByPO: (poNumber: string) => Promise<AxiosResponse<ODataLogistics>> =
    async (poNumber) =>
      this.axiosInstance.get(
        `${_PATH}?$top=5000&$filter=data/lineItems/any(d: d/poNumber in ('${poNumber}'))`
      );

  public getLogisticsLedgerByInvoice: (
    invoiceNumber: string
  ) => Promise<AxiosResponse<ODataLogistics>> = async (invoiceNumber) =>
    this.axiosInstance.get(
      `${_PATH}?$top=5000&$filter=data/lineItems/any(d: d/invoiceNumber in ('${invoiceNumber}'))`
    );

  public getLogisticById: (id: string) => Promise<AxiosResponse<ODataLedgerLogistic>> =
  async (id) =>
    this.axiosInstance.get<ODataLedgerLogistic>(
      `${_PATH}?$top=5000&$filter=logisticId eq '${id}'`);

  public getLogisticsByProgramIds: (
    programIds: string[]
  ) => Promise<AxiosResponse<ODataLedgerLogistic>> = async (programIds) =>
    this.axiosInstance.get(
      `${_PATH}?$count=true&$top=5000&$filter=${this.odataFilterBuilderForObjectsInArray(
        'linkedPrograms',
        'programId',
        programIds
      )}`
    );

    public getFilterLogistics: (
      programIds: string[],
      rowsPerPage: number | null,
      page: number | null,
      filter: string,
      orderBy: string
    ) => Promise<AxiosResponse<ODataLedgerLogistic>> = async (programIds, rowsPerPage, page, filter, orderBy) =>
      this.axiosInstance.get(
        `${_PATH}?$count=true&$top=${rowsPerPage || 5000}&$orderby=${orderBy}&$filter=${this.odataFilterBuilderForObjectsInArray(
          'linkedPrograms',
          'programId',
          programIds
        )}${filter || ''}&$skip=${
          page && rowsPerPage !== null  ? rowsPerPage * page : 0
      }`
      );
    
}
