import { styling, themeColors } from 'assets/theme/style';
import styled from 'styled-components';

//DESKTOP STYLES
export const LedgerDetailAddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
`;

export const LedgerDetailAddressesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const LedgerDetailAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${themeColors.border.primary};
  border-radius: ${styling.defaultRadius};
  background-color: ${themeColors.bg.light.surface};
  padding: 24px;
  width: 240px;
`;

export const LedgerDetailAddressesTitle = styled.h4``;
export const LedgerDetailAddressesValue = styled.p``;

export const LedgerDetailAddressValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;
