export const barChartAxisLookup = [
  { maxValue: 100, range: [20, 40, 60, 80, 100] },
  { maxValue: 200, range: [40, 80, 120, 160, 200] },
  { maxValue: 500, range: [100, 200, 300, 400, 500] },
  { maxValue: 1000, range: [200, 400, 600, 800, 1000] },
  { maxValue: 5000, range: [1000, 2000, 3000, 4000, 5000] },
  { maxValue: 10000, range: [2000, 4000, 6000, 8000, 10000] },
  { maxValue: 50000, range: [10000, 20000, 30000, 40000, 50000] },
  { maxValue: 100000, range: [20000, 40000, 60000, 80000, 100000] },
  { maxValue: 500000, range: [100000, 200000, 300000, 400000, 500000] },
  { maxValue: 1000000, range: [200000, 400000, 600000, 800000, 1000000] },
  { maxValue: 5000000, range: [1000000, 2000000, 3000000, 4000000, 5000000] },
  { maxValue: 10000000, range: [2000000, 4000000, 6000000, 8000000, 10000000] },
  { maxValue: 50000000, range: [10000000, 20000000, 30000000, 40000000, 50000000] },
  { maxValue: 100000000, range: [20000000, 40000000, 60000000, 80000000, 100000000] },
  { maxValue: 500000000, range: [100000000, 200000000, 300000000, 400000000, 500000000] },
  { maxValue: 1000000000, range: [200000000, 400000000, 600000000, 80000000, 1000000000] }
];

export const emptyBarChartAxisLookup = [
  { maxValue: 10000, range: [2000, 4000, 6000, 8000, 10000] }
];
