import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import { SideBarData } from 'utils/interfaces/sidebar/sidebar.interface';

const links: NavigationItem[] = [
  {
    text: 'Company details',
    path: '/company-details'
  },
  {
    text: 'User details',
    path: '/personal-details'
  },
  {
    text: 'Connections',
    path: '/connections'
  }
];

export const sideBarNavigationData: SideBarData[] = [
  {
    path: '/company-details',
    pathname: 'Company details',
    links
  },
  {
    path: '/personal-details',
    pathname: 'User details',
    links
  },
  {
    path: '/connections',
    pathname: 'Connections',
    links
  }
];
