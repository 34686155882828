
import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const LogisticsViewTitle = styled.h1`
  display: ${isMobile() ? 'none' : 'block'};
  font-size: ${fontSizes.xLarge};
`;

export const LogisticsViewSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin: 16px 0;
  width: 100%;
`;

export const LogisticsViewFilterButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

//MOBILE STYLES
export const LogisticsViewMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const LogisticsViewMobileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 4px;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LogisticsViewMobileItemLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LogisticsViewMobileItemRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const LogisticsViewMobileItemBold = styled.p`
  ${typography.bodySemiBold};
`;

export const LogisticsViewMobileItemRegular = styled.p`
  ${typography.captionRegular};
`;

export const LogisticsViewNoData = styled.h5`
  padding: 24px;
`;
