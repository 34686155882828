export enum DocumentStatus {
  closed = 'closed',
  cancelled = 'cancelled',
  expired = 'expired',
  approved = 'approved',
  pending = 'pending',
  accepted = 'accepted',
  matched = 'matched',
  discrepant = 'discrepant',
  rejected = 'rejected'
}
