import { FC } from 'react';
import {
  ActivityBarActivity,
  ActivityBarCompany,
  ActivityBarLeftContainer,
  ActivityBarLeftTextContainer,
  ActivityBarWrapper
} from './styled';

export interface ActivityBarProps {
  activityName?: string;
  date?: string;
  iconContainerColor?: string;
  iconJSX: JSX.Element;
  time?: string;
  value?: string;
  id?: string;
}

const ActivityBar: FC<ActivityBarProps> = ({ activityName, date, iconJSX }) => {
  return (
    <ActivityBarWrapper>
      <ActivityBarLeftContainer data-testid="activity-bar-div-icon-container">
        {iconJSX}
        <ActivityBarLeftTextContainer>
          {activityName && (
            <ActivityBarActivity data-automation-id="activity-bar-p-activity-name">
              {activityName}
            </ActivityBarActivity>
          )}
          {date && (
            <ActivityBarCompany data-automation-id="activity-bar-p-date">{date}</ActivityBarCompany>
          )}
        </ActivityBarLeftTextContainer>
      </ActivityBarLeftContainer>
    </ActivityBarWrapper>
  );
};

export default ActivityBar;
