export const GET_USERS = '@@frontend/store/reducers/users/GET_USERS';
export const GET_USERS_SUCCESS = '@@frontend/store/reducers/users/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = '@@frontend/store/reducers/users/GET_USERS_FAILURE';
export const GET_USER = '@@frontend/store/reducers/users/GET_USER';
export const GET_USER_SUCCESS = '@@frontend/store/reducers/users/GET_USER_SUCCESS';
export const GET_USER_FAILURE = '@@frontend/store/reducers/users/GET_USER_FAILURE';
export const UPDATE_USER = '@@frontend/store/reducers/users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = '@@frontend/store/reducers/users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = '@@frontend/store/reducers/users/UPDATE_USER_FAILURE';
export const UPDATE_USER_ACTION = '@@frontend/store/reducers/users/UPDATE_USER_ACTION';
export const UPDATE_USER_ACTION_SUCCESS =
  '@@frontend/store/reducers/users/UPDATE_USER_ACTION_SUCCESS';
export const UPDATE_USER_ACTION_FAILURE =
  '@@frontend/store/reducers/users/UPDATE_USER_ACTION_FAILURE';
export const ADD_NEW_USER = '@@frontend/store/reducers/users/ADD_NEW_USER';
export const ADD_NEW_USER_SUCCESS = '@@frontend/store/reducers/users/ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAILURE = '@@frontend/store/reducers/users/ADD_NEW_USER_FAILURE';
export const GET_LOGGED_IN_USER = '@@frontend/store/reducers/users/GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_SUCCESS =
  '@@frontend/store/reducers/users/GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAILURE =
  '@@frontend/store/reducers/users/GET_LOGGED_IN_USER_FAILURE';
export const RESET_USERS = '@@frontend/store/reducers/users/RESET_USERS';
