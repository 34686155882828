import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';

export const CSVDownloaderSmallStyled = styled(CSVLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.text.primary};
  background-color: transparent;
  height: 24px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid ${themeColors.border.primary};
  align-self: flex-end;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    border: 1px solid ${themeColors.border.primaryHover};
    color: ${themeColors.text.primaryHover};
  }
`;

export const CSVDownloaderStyled = styled(CSVLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.text.primary};
  background-color: transparent;
  height: 24px;
  padding: 10px 8px;
  border-radius: 6px;
  align-self: flex-end;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid ${themeColors.border.primary};

  &:hover{
    border: 1px solid ${themeColors.border.primaryHover};
    color: ${themeColors.text.primaryHover};
  }
`;

export const CSVDownloaderText = styled.p`
  margin: 0;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.bold};
`;
