import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const PaymentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentHeaderSegment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export const PaymentHeaderTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const PaymentHeaderH3 = styled.h3``;

export const PaymentHeaderH3Blue = styled.h3`
  color: ${themeColors.text.primary};
`;

export const PaymentSubtitle = styled.p`
  color: ${themeColors.text.light.secondary};
`;

export const PaymentInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 90px;
`;

export const PaymentSummaryDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  gap: 24px;
  margin-bottom: 16px;
`;

export const PaymentSummaryDetailTitle = styled.h4``;

export const PaymentSummaryDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentSummaryDetailLabelContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const PaymentSummaryDetailLabel = styled.p`
  ${typography.captionBold};
`;

export const PaymentSummaryDetailValue = styled.p`
  ${typography.captionRegular};
`;

export const PaymentSummaryDetailBoldValue = styled.p`
  ${typography.captionBold};
`;

export const PaymentInvoicesHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const PaymentInvoicesTotalItems = styled.p`
  ${typography.bodySmallSemiBold};
  color: ${themeColors.mono1};
`;
