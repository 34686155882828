export const CREATE_NEW_INVOICE = '@@frontend/store/reducers/createInvoice/CREATE_NEW_INVOICE';
export const CREATE_NEW_INVOICE_SUCCESS =
  '@@frontend/store/reducers/createInvoice/CREATE_NEW_INVOICE_SUCCESS';
export const CREATE_NEW_INVOICE_FAILURE =
  '@@frontend/store/reducers/createInvoice/CREATE_NEW_INVOICE_FAILURE';
export const SET_DRAFT_INVOICE_MODAL = '@@frontend/store/reducers/createInvoice/SET_DRAFT_INVOICE_MODAL';
export const SET_DRAFT_ASYNCTASKID = '@@frontend/store/reducers/createInvoice/SET_DRAFT_ASYNCTASKID';
export const RESET_NEW_INVOICE_ID = '@@frontend/store/reducers/createInvoice/RESET_NEW_INVOICE_ID';
export const HIDE_NEW_INVOICE_BAR = '@@frontend/store/reducers/createInvoice/HIDE_NEW_INVOICE_BAR';
export const SHOW_NEW_INVOICE_BAR = '@@frontend/store/reducers/createInvoice/SHOW_NEW_INVOICE_BAR';
export const RESET_CREATE_NEW_INVOICE_ERROR =
  '@@frontend/store/reducers/createInvoice/RESET_CREATE_NEW_INVOICE_ERROR';
export const SET_DRAFT_PO =
  '@@frontend/store/reducers/createInvoice/SET_DRAFT_PO';