import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

//DESKTOP STYLES
export const LedgerLineItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LedgerLineItemContainer = styled.div`
  display: flex;
`;

//MOBILE STYLES
export const LedgerLineItemMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
`;

export const LedgerLineItemMobileRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LedgerLineItemMobileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 4px;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LedgerLineItemMobileItemLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LedgerLineItemMobileItemRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const LedgerLineItemMobileItemBold = styled.p`
  ${typography.bodySemiBold};
`;

export const LedgerLineItemMobileItemRegular = styled.p`
  ${typography.captionRegular};
`;
