import Axios, { AxiosInstance } from 'axios';
import { UPDATE_REQUEST_ERROR, UPDATE_REQUEST_ERROR_CODE } from 'store/actions';

export default class StoreBase {
  axiosInstance: AxiosInstance = Axios.create();
  app: any;

  constructor(public _store: any, accessToken?: string) {
    this.app = _store.getState().app;
    this.axiosInstance.defaults.baseURL = `https://${this.app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${
      accessToken || this.app.accessToken
    }`;
    this.axiosInstance.interceptors.response.use(
      (next: any) => {
        return Promise.resolve(next);
      },
      (error: any) => {
        if (error.response.status !== 400) {
          _store.dispatch({
            type: UPDATE_REQUEST_ERROR,
            payload: error.message
          });
          _store.dispatch({
            type: UPDATE_REQUEST_ERROR_CODE,
            payload: error.response.status
          });
          return Promise.reject(error);
        } else {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.message &&
            error?.response?.data?.errors[0]?.details?.length > 0
          ) {
            if (error?.response?.data?.errors[0].details.join(' ').includes('currency')) {
              _store.dispatch({
                type: UPDATE_REQUEST_ERROR,
                payload: `Please check the currency.`
              });
            } else {
              _store.dispatch({
                type: UPDATE_REQUEST_ERROR,
                payload: `${
                  error.response.data.errors[0].message
                } - ${error.response.data.errors[0].details.join(' - ')}`
              });
            }
          } else {
            _store.dispatch({
              type: UPDATE_REQUEST_ERROR_CODE,
              payload: error.response.status
            });
          }
        }
      }
    );
  }

  public odataFilterBuilderForObjectsInArray(
    arrayField: string,
    objectProperty: string,
    values: string[]
  ) {
    return `${arrayField}/any(f: f/${objectProperty} in ('${values.join("','")}'))`;
  }
}
