/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { LedgerPaymentItemExternalReference } from 'api/interfaces/ledger/ledgerPaymentItems.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import PaymentApi from 'api/ledger/payment.api';
import DataGrid from 'components/DataGrid';
import QueryBuilder from 'components/QueryBuilder';
import { PrimaryButton } from 'components/common/buttons';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';
import PaymentsViewMobile from 'components/payments/PaymentsViewMobile';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { isMobile } from 'lib/helpers/mobile';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { DateTime } from 'luxon';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { store } from 'store';
import { GET_PAYMENT_LEDGER_PAYMENT_ITEMS } from 'store/actions';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { QueryDateRange, QueryItem, QueryRow } from 'utils/classes/query/query';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { SearchAdvancedText } from 'views/InvoicesView/styled';
import {
  PaymentsSearchContainer,
  PaymentsSearchFilterButtonTextWrapper,
  PaymentsTitle
} from './styled';
import PillButton from 'components/common/buttons/PillButton';
import { themeColors } from 'assets/theme/style';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

const fieldDictionary: any = {
  paymentType: 'type',
  created: 'sentDate',
  paymentIssuer: 'fromParticipant/registeredName',
  expectedPaymentDate: 'expectedPaymentDate',
  currency: 'currency',
  totalPaymentValue: 'amount',
  status: 'status'
};

const Payments: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [paymentForNavigate, setPaymentForNavigate] = useState<LedgerPayment | null>(null);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const [exportData, setExportData] = useState<DataGridRowItem[]>([]);
  const [querySet, setQuerySet] = useState<QueryItem[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [orderBy, setOrderBy] = useState<string>('sentDate desc');
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [paymentsLedgerCount, setPaymentsLedgerCount] = useState<number>(0);
  const [paymentsLoading, setPaymentLoading] = useState<boolean>(false);
  const [paymentsInitialLoading, setPaymentsInitialLoading] = useState<boolean>(false);
  const [initialise, setInitialise] = useState<boolean>(false);

  const {
    loading: appLoading,
    programIds,
    allProgramsWithDetails,
    groupedPrograms,
    selectedProgramByCurrency
  }: {
    loading: boolean;
    programIds: string[];
    allProgramsWithDetails: ProgramConfig[];
    groupedPrograms: IGroupedProgram[];
    selectedProgramByCurrency: IGroupedProgram | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const paymentLedgerPaymentItems = useSelector((state: RootStateOrAny) => {
    return state.payment.paymentLedgerPaymentItems;
  });
  const paymentItemsLoading = useSelector((state: RootStateOrAny) => state.payment.loading);

  const paymentApi = new PaymentApi(store);

  const currencies: string[] =
    groupedPrograms.length > 1 ? groupedPrograms.map((program) => program.currency) : [];

  const headers: DataGridHeaderItem[] = [
    new DataGridHeaderItem(
      'Payment Reference',
      'paymentReference',
      new DataGridHeaderOptions(false, true, true)
    ),
    new DataGridHeaderItem('Payment Type', 'paymentType', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p>{value === 'TRADE' ? 'EARLY' : 'MATURITY'}</p>
      )
    }),
    new DataGridHeaderItem(
      'Payment Issuer',
      'paymentIssuer',
      new DataGridHeaderOptions(false, true, true)
    ),
    new DataGridHeaderItem('Created Date/Time', 'created', {
      sort: true,
      sortThirdClickReset: true,
      filter: false
    }),
    new DataGridHeaderItem(
      'Expected Payment Date',
      'expectedPaymentDate',
      new DataGridHeaderOptions(false, true, true)
    ),
    new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
    new DataGridHeaderItem('Total Payment Value', 'totalPaymentValue', {
      ...new DataGridHeaderOptions(false, true, true),
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
        <p style={{ textAlign: 'right' }}>{value}</p>
      )
    }),
    new DataGridHeaderItem('Status', 'status', new DataGridHeaderOptions(false, true, true)),
    new DataGridHeaderItem('Item', 'item', {
      display: false
    })
  ];

  const applyQueryParamFilters: (queryItems: QueryItem[]) => QueryItem[] = (queryItems) => {
    const queryParams = new URLSearchParams(window.location.search);
    return [...queryItems].map((queryItem) => {
      const presetValue: string = queryParams.get(queryItem.filterValue) || '';
      return presetValue
        ? {
            ...queryItem,
            defaultComparator: 'eq',
            defaultValue: presetValue.toUpperCase()
          }
        : queryItem;
    });
  };
  const now = DateTime.now();

  const queryItems: QueryItem[] = [
    {
      filterName: 'Payment Type',
      filterValue: 'type',
      defaultValue: searchParams.get('type') ? (searchParams.get('type') as string) : '',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Maturing',
          value: 'MATURING'
        },
        {
          name: 'Trade',
          value: 'TRADE'
        }
      ]
    },
    {
      filterName: 'Payment Issuer',
      filterValue: 'paymentIssuer',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Created Date',
      filterValue: 'sentDate',
      defaultValue:
        searchParams.get('date') === 'last24hours'
          ? {
              from: now.minus({ hours: 24 }).toJSDate(),
              to: now.toJSDate()
            }
          : '',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Currency',
      filterValue: 'currency',
      componentType: QueryComponents.CURRENCY,
      display: currencies.length > 0
    },
    {
      filterName: 'Expected Payment Date',
      filterValue: 'expectedPaymentDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Status',
      filterValue: 'status',
      defaultValue: searchParams.get('status')
        ? (searchParams.get('status') as string).toUpperCase()
        : '',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Sent',
          value: 'SENT'
        },
        {
          name: 'Pending',
          value: 'PENDING'
        },
        {
          name: 'Paid',
          value: 'PAID'
        }
      ]
    },
    {
      filterName: 'Total Payment Value',
      filterValue: 'totalPaymentValue',
      componentType: QueryComponents.NUMBER
    }
  ];

  useEffect(() => {
    const hydratedQueryItems = applyQueryParamFilters(queryItems);
    setQuerySet(hydratedQueryItems);
  }, []);

  useEffect(() => {
    if (paymentLedgerPaymentItems && paymentForNavigate && !paymentItemsLoading) {
      const { externalReferences } = paymentLedgerPaymentItems[0];
      navigateToView(paymentForNavigate, externalReferences);
    }
  }, [paymentForNavigate, paymentLedgerPaymentItems]);

  useEffect(() => {
    if (programIds && !initialise) {
      getPaymentsResults(true);
      setInitialise(true);
    }
  }, [programIds]);

  useEffect(() => {
    initialise && getPaymentsResults();
  }, [rowsPerPage, page, searchParams, activeQueries, orderBy]);

  const mapToViewModel: (data: LedgerPayment[]) => DataGridRowItem[] = (data) =>
    data.map((p) => ({
      paymentType: p.type || '',
      paymentIssuer: p.fromParticipant.registeredName || '',
      created: p.sentDate ? formatDateTime(p.sentDate, `${_DATE_FORMAT} HH:mm`) : '',
      expectedPaymentDate: p.expectedPaymentDate
        ? formatDateTime(p.expectedPaymentDate, _DATE_FORMAT)
        : '',
      totalPaymentValue: formatNumber(p.amount || 0, 2),
      currency: p.currency || '',
      status: p.status,
      item: p,
      paymentReference: p.paymentReferences.join(', ')
    }));

  const isTypeInActiveQueries: (field: string) => boolean = (field) => {
    return activeQueries.find((query) => query.fieldValue.includes(field)) !== undefined;
  };

  const getPaymentsResults: (initialLoading?: boolean) => void = async (initialLoading = false) => {
    initialLoading ? setPaymentsInitialLoading(true) : setPaymentLoading(true);

    let filterSearch: string = ``;

    const status = searchParams.get('status');
    const hasSearchTerm = searchTerm.length > 0;

    if (status && !hasSearchTerm) {
      filterSearch += `and (contains(status, '${status}'))`;
    }

    if (hasSearchTerm && !status) {
      filterSearch += `and (contains(fromParticipant/registeredName, '${searchTerm}') or contains(status, '${searchTerm}'))`;
    }

    if (hasSearchTerm && status) {
      filterSearch += `and (contains(fromParticipant/registeredName, '${searchTerm}') and contains(status, '${status}'))`;
    }

    if (searchParams.get('type') && !isTypeInActiveQueries('type')) {
      filterSearch += ` and (type eq '${searchParams.get('type')}')`;
    }

    if (searchParams.get('date') && !isTypeInActiveQueries('sentDate')) {
      filterSearch += ` and ((sentDate ge ${DateTime.fromJSDate(
        now.minus({ hours: 24 }).toJSDate()
      ).toISO({
        includeOffset: false
      })}Z) and (sentDate le ${DateTime.fromJSDate(now.toJSDate())
        .plus({ days: 1 })
        .toISO({ includeOffset: false })}Z))`;
    }

    if (activeQueries.length > 0) {
      setShowAdvancedSearch(true);

      activeQueries.forEach((q: QueryRow) => {
        if (q.fieldValue === 'type') {
          filterSearch += ` and (type ${q?.comparator} '${q?.queryValue}')`;
        } else if (q.fieldValue === 'sentDate') {
          filterSearch += ` and ((sentDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (sentDate le ${DateTime.fromJSDate((q.queryValue as QueryDateRange)?.to as Date)
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'expectedPaymentDate') {
          filterSearch += ` and ((expectedPaymentDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (expectedPaymentDate le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          )
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'paymentIssuer') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(fromParticipant/registeredName, '${q.queryValue}')`
              : `and (fromParticipant/registeredName ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'status') {
          filterSearch += ` and (status ${q?.comparator} '${q?.queryValue}')`;
        } else if (q.fieldValue === 'currency') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(currency, '${q.queryValue}')`
              : `and (currency ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'totalPaymentValue') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(amount, ${q.queryValue})`
              : `and (amount ${q.comparator} ${q.queryValue})`;
        }
      });
    }

    const allProgramIds = allProgramsWithDetails.map((p) => p.id);

    const paymentsResponse = await paymentApi.getFilterPayments(
      allProgramIds || '',
      rowsPerPage,
      page,
      filterSearch || '',
      orderBy
    );

    const paymentsResponseAll = await paymentApi.getFilterPayments(
      allProgramIds,
      null,
      null,
      filterSearch,
      orderBy
    );

    setData(mapToViewModel(paymentsResponse?.data?.value || []));
    setExportData(mapToViewModel(paymentsResponseAll?.data?.value || []));

    setPaymentsLedgerCount(paymentsResponse?.data['@odata.count'] || 0);

    initialLoading ? setPaymentsInitialLoading(false) : setPaymentLoading(false);
  };

  const columnSortChangeClickHandler = (col: any) => {
    const odataField: string | undefined = fieldDictionary[col];
    const sortable = Boolean(odataField);
    if (!sortable) return;
    const direction = orderDirection === 'asc' ? 'desc' : 'asc';
    setOrderDirection(direction);
    setOrderBy(`${fieldDictionary[col]} ${direction}`);
  };

  const clickSearchHandle: () => void = () => {
    getPaymentsResults();
    setPage(0);
  };

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => {
    const inputValue = event.currentTarget.value;
    setSearchTerm(inputValue);
  };

  const searchBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue === '') {
      getPaymentsResults();
      setPage(0);
    }
  };

  const searchHandleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === 'Enter') {
      getPaymentsResults();
      setPage(0);
    }
  };

  const rowClickHandler: (row: any) => void = async (row) => {
    const payment: LedgerPayment = row[row.length - 1];

    if (!payment.id) return;
    setPaymentForNavigate(payment);
    dispatch({ type: GET_PAYMENT_LEDGER_PAYMENT_ITEMS, payload: payment.id });
  };

  const navigateToView: (
    payment: LedgerPayment,
    externalReferences: LedgerPaymentItemExternalReference[]
  ) => void = (payment, externalReferences) => {
    setPaymentForNavigate(null);
    if (!payment.type) return;
    switch (payment.type) {
      case 'TRADE':
        const tradeMatchedId = externalReferences.find(
          (ex) => ex.referenceKind === 'TRADE_ID'
        )?.referenceValue;
        if (!tradeMatchedId) break;
        navigate(
          `/payments/${tradeMatchedId}?type=TRADE&programId=${payment.linkedProgram.programId}&paymentId=${payment.id}`
        );
        break;

      case 'MATURING':
        const maturingMatchedId = externalReferences.find(
          (ex) => ex?.referenceKind === 'INVOICE_ID'
        )?.referenceValue;
        if (!maturingMatchedId) break;
        navigate(
          `/payments/${payment.id}?type=MATURING&programId=${payment.linkedProgram.programId}&paymentId=${payment.id}`
        );
        break;

      default:
        break;
    }
  };

  const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
    setPage(0);
  };

  const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) => {
    setPage(tableState.page);
  };

  const changePage: (page: number) => void = (page) => {
    setPage(page);
  };

  const renderTable: () => JSX.Element = () =>
    isMobile() ? (
      <PaymentsViewMobile
        payments={data}
        rowClickHandler={rowClickHandler}
        totalRows={paymentsLedgerCount}
        page={page}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        rowsPerPageHandler={rowsPerPageHandler}
      />
    ) : (
      <DataGrid
        headers={headers}
        data={data}
        exportData={exportData}
        ariaLabel="Payments ledger Data Table"
        ariaCaption="A table that shows the payments ledger."
        totalRows={paymentsLedgerCount}
        loading={paymentsLoading}
        exportFilename="payments_ledger"
        hasActions
        fixedHeader
        rowClickHandler={rowClickHandler}
        tableChangeHandler={tableChangeHandler}
        columnSortChangeClickHandler={columnSortChangeClickHandler}
        dataAutomationTag="payments-ledger-table-ledger-items"
        page={page === -1 ? 0 : page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPageHandler={rowsPerPageHandler}
        showFooter={paymentsLedgerCount > 15}
      />
    );

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    const clone = [...queries];

    const isUpdated =
      clone.length === activeQueries.length &&
      clone.some((cloneValue) => {
        return activeQueries.some(
          (activeValue) =>
            cloneValue.fieldValue === activeValue.fieldValue &&
            cloneValue.queryValue === activeValue.queryValue
        );
      });

    if (isUpdated || activeQueries.length !== clone.length) {
      setActiveQueries(clone);
      setPage(0);
    }
  };

  return paymentsInitialLoading || appLoading ? (
    <LoaderFullPage />
  ) : (
    <LayoutViewContainer size="xlarge">
      <BaseCard noBoxShadow noBorder>
        <PaymentsTitle data-automation-id="payments-page-title">Payments</PaymentsTitle>
        <PaymentsSearchContainer>
          <OutlinedInput
            id="outlined-adornment-search"
            dataAutomationId="payments-input-fuzzy-search"
            dataTestId="sp-payments-input-fuzzy-search"
            defaultValue={searchTerm}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => searchBlurHandler(event)}
            changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              searchHandler(event)
            }
            placeholder="Search by payment issuer and status"
            handleKeyDown={(event: KeyboardEvent<HTMLInputElement>) => searchHandleKeyDown(event)}
          />
          <PrimaryButton
            clickHandler={clickSearchHandle}
            disabled={searchTerm === '' || searchTerm.length < 3}
            height="40px"
            width="40px"
          >
            <PaymentsSearchFilterButtonTextWrapper>
              <SearchIcon />
            </PaymentsSearchFilterButtonTextWrapper>
          </PrimaryButton>
          {!isMobile() && (
            <SearchAdvancedText
              data-automation-id="payments-p-advanced-search-link-button"
              onClick={advancedSearchClickHandler}
              data-testid="sp-advanced-search"
            >
              {showAdvancedSearch
                ? 'Hide advanced search'
                : `Advanced search ${activeQueries.length > 0 ? `(${activeQueries.length})` : ''}`}
            </SearchAdvancedText>
          )}
        </PaymentsSearchContainer>
        <QueryBuilder
          queryItems={querySet}
          activeQueriesHandler={activeQueriesHandler}
          closeClickHandler={advancedSearchClickHandler}
          open={showAdvancedSearch}
          testingTagPage="payments"
          currencies={currencies}
        />
        {!showAdvancedSearch && <Divider />}

        {renderTable()}
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default Payments;
