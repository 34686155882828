import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import InfoIcon from 'assets/icons/InfoIcon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import { PrimaryButton } from 'components/common/buttons';
import { FC } from 'react';
import {
  LedgerDetailCreateInvoiceBannerButtonContainer,
  LedgerDetailCreateInvoiceBannerButtonContentContainer,
  LedgerDetailCreateInvoiceBannerButtonText,
  LedgerDetailCreateInvoiceBannerButtonWrapper,
  LedgerDetailCreateInvoiceBannerContainer,
  LedgerDetailCreateInvoiceBannerContentContainer,
  LedgerDetailCreateInvoiceBannerDescription,
  LedgerDetailCreateInvoiceBannerIconContainer
} from '../LedgerDetailCreateInvoiceBanner/styled';

interface LedgerLogisticsFlipBannerProps {
  createClickHandler: () => void;
  closeHandler: () => void;
  isFlipComplete: boolean;
}

const LedgerLogisticsFlipBanner: FC<LedgerLogisticsFlipBannerProps> = ({
  createClickHandler,
  isFlipComplete,
  closeHandler
}) => {
  const renderAddLogisticsBanner: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        backgroundColor={themeColors.bg.light.surface}
        borderColor={themeColors.bg.info}
        padding="16px"
        border
        noShadow
        hasClosed
        titleLarge
        descriptionLarge
        color={themeColors.text.light.body}
        title="Create logistics information"
        closeHandler={closeHandler}
        icon={<InfoIcon height="24px" width="24px" color={themeColors.icon.primary} />}
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              You can generate logistics documents from this invoice.
            </LedgerDetailCreateInvoiceBannerDescription>
            <LedgerDetailCreateInvoiceBannerButtonWrapper>
              <LedgerDetailCreateInvoiceBannerButtonContainer data-testid="sp-g2f-banner-button">
                <PrimaryButton
                  clickHandler={createClickHandler}
                  testingTag="-ledger-detail-g2f-banner-button"
                >
                  <LedgerDetailCreateInvoiceBannerButtonContentContainer>
                    <LedgerDetailCreateInvoiceBannerButtonText
                      data-automation-id="ledger-detail-G2F-banner-button-text"
                      data-testid="sp-g2f-banner-button-text"
                    >
                      Add logistics
                    </LedgerDetailCreateInvoiceBannerButtonText>
                    <LedgerDetailCreateInvoiceBannerIconContainer
                      data-automation-id="ledger-detail-div-g2f-banner-button-icon-container"
                      data-testid="sp-g2f-banner-button-icon"
                    >
                      <RightLongArrowIcon
                        color={themeColors.text.onPrimary}
                        height="10"
                        width="12"
                      />
                    </LedgerDetailCreateInvoiceBannerIconContainer>
                  </LedgerDetailCreateInvoiceBannerButtonContentContainer>
                </PrimaryButton>
              </LedgerDetailCreateInvoiceBannerButtonContainer>
            </LedgerDetailCreateInvoiceBannerButtonWrapper>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );
  const renderCompleteBanner: () => JSX.Element = () => (
    <LedgerDetailCreateInvoiceBannerContainer>
      <Notification
        backgroundColor="rgba(21, 182, 114, 0.05)"
        padding="16px"
        border
        titleLarge
        descriptionLarge
        noShadow
        hasClosed
        color={themeColors.text.success}
        closeHandler={closeHandler}
        title="Success! The logistics document is now live on the platform"
        icon={
          <ApproveIcon
            height="24px"
            width="24px"
            color={themeColors.text.success}
            fill={themeColors.icon.success}
          />
        }
        descriptionJSX={
          <LedgerDetailCreateInvoiceBannerContentContainer>
            <LedgerDetailCreateInvoiceBannerDescription>
              Logistics document is now live on the platform
            </LedgerDetailCreateInvoiceBannerDescription>
          </LedgerDetailCreateInvoiceBannerContentContainer>
        }
      />
    </LedgerDetailCreateInvoiceBannerContainer>
  );

  return isFlipComplete ? renderCompleteBanner() : renderAddLogisticsBanner();
};

export default LedgerLogisticsFlipBanner;
