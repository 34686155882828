import { FC } from 'react';
import _ from 'lodash';
import {
  FRMobileInvoiceBarContainer,
  FRMobileInvoiceBarSection,
  FRMobileInvoiceCaption,
  FRMobileInvoicesContainer,
  FRMobileInvoiceSemiBoldText
} from './styled';
import DateBar from 'components/activity/DateBar';
import { themeColors } from 'assets/theme/style';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { useNavigate } from 'react-router';

interface FundingRequestIncludedInvoicesMobileProps {
  invoices: DataGridRowItem[];
}

interface FundingRequestMobileInvoiceListViewModel {
  date: string;
  invoiceData: DataGridRowItem[];
}

const FundingRequestIncludedInvoicesMobile: FC<FundingRequestIncludedInvoicesMobileProps> = ({
  invoices
}) => {
  const navigate = useNavigate();

  // group invoices by duedate
  const mappedData: () => FundingRequestMobileInvoiceListViewModel[] = () => {
    const grouped = _.groupBy(invoices, 'paymentDueDate');
    return Object.entries(grouped)
      .map(([key, value]) => ({
        date: key,
        invoiceData: value.map((v) => ({
          invoiceId: v.id,
          invoiceNumber: v.invoiceNumber,
          invoiceAmount: `${v.invoiceAmount}`,
          buyerName: v.buyer,
          purchasePrice: `${v.purchasePrice}`
        }))
      }))
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
  };

  const rowClickHandler: (invoiceId: string) => void = (invoiceId) => {
    navigate(`/invoices/${invoiceId}`);
  };

  const buildInvoiceBar: (viewModel: DataGridRowItem, key: number) => JSX.Element = (
    { invoiceId, invoiceNumber, invoiceAmount, buyerName, purchasePrice },
    key
  ) => (
    <FRMobileInvoiceBarContainer key={key} onClick={() => rowClickHandler(invoiceId)}>
      <FRMobileInvoiceBarSection>
        <FRMobileInvoiceSemiBoldText>{invoiceNumber}</FRMobileInvoiceSemiBoldText>
        <FRMobileInvoiceSemiBoldText>{invoiceAmount}</FRMobileInvoiceSemiBoldText>
      </FRMobileInvoiceBarSection>
      <FRMobileInvoiceBarSection>
        <FRMobileInvoiceCaption>{buyerName}</FRMobileInvoiceCaption>
        <FRMobileInvoiceCaption>Purchase Price: {purchasePrice}</FRMobileInvoiceCaption>
      </FRMobileInvoiceBarSection>
    </FRMobileInvoiceBarContainer>
  );

  const buildView: () => JSX.Element = () => {
    return (
      <>
        {mappedData().map(({ date, invoiceData }) => (
          <FRMobileInvoicesContainer key={date}>
            <DateBar date={date} toEdge color={themeColors.mono5} />
            {invoiceData.map((data, i) => buildInvoiceBar(data, i))}
          </FRMobileInvoicesContainer>
        ))}
      </>
    );
  };

  return <div>{buildView()}</div>;
};

export default FundingRequestIncludedInvoicesMobile;
