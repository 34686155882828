import { FC, ReactNode } from 'react';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { SecondaryButtonStyled } from './styled';

export interface SecondaryButtonProps extends ButtonProps {
  children?: ReactNode;
  testingTag?: string;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
  text = '',
  clickHandler = () => {},
  textColor,
  borderColor,
  backgroundColor,
  disabled = false,
  children,
  padding,
  height,
  textButton,
  testingTag,
  width
}) => (
  <SecondaryButtonStyled
    data-automation-id={testingTag}
    data-testid="sp-secondary-button"
    onClick={clickHandler}
    textColor={textColor}
    backgroundColor={backgroundColor}
    disabled={disabled}
    padding={padding}
    height={height}
    textButton={textButton}
    width={width}
    borderColor={borderColor}
  >
    {children || text}
  </SecondaryButtonStyled>
);

export default SecondaryButton;
