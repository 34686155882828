import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';

export const ledgerAdjustmentsTableHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem(
    'Reason for Adjustment',
    'reason',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Comment', 'comment', {
    ...new DataGridHeaderOptions(false, true, true)
  }),
  new DataGridHeaderItem('Type', 'type', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Value', 'value', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return <p style={{ textAlign: 'right' }}>{value}</p>;
    }
  })
];
