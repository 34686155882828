import StoreBase from 'api/base/storeBase';
import {
  Entities,
  EntityBankAccount,
  EntityCurrency,
  EntityLogo,
  EntityOwner,
  EntityProofOfAccount,
  IAssetMetadata,
  ICountries
} from 'api/interfaces/entity/entity.interface';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { store } from 'store';
import { UPDATE_REQUEST_ERROR } from 'store/actions';
import { IBaseRateReferenced } from 'utils/interfaces/base-rates/baseRates.interface';

export default class EntityApi extends StoreBase {
  public getOwner: (accessToken: string) => Promise<AxiosResponse<EntityOwner>> = async (
    accessToken
  ) => {
    const { app }: { app: any } = store.getState();
    const pvtAxiosObj: AxiosInstance = Axios.create();
    pvtAxiosObj.defaults.baseURL = `https://${app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    return await pvtAxiosObj.get<EntityOwner>(`/entity-management/owner`);
  };

  public getEntities: () => Promise<AxiosResponse<Entities>> = async () =>
    await this.axiosInstance.get<Entities>(`/entity-management/entities`);

  public putOwnerDetails: (data: EntityOwner) => Promise<AxiosResponse<any>> = async (data) =>
    await this.axiosInstance.put<EntityOwner>(`/entity-management/entities/${data.id}`, data);

  public getOwnerBankAccounts: (registeredName: string) => Promise<AxiosResponse<any>> = async (
    registeredName
  ) =>
    await this.axiosInstance.get<EntityOwner>(
      `/platform-owner-settings/bank-account?registeredName=${encodeURIComponent(registeredName)}`
    );

  public getAllOwnerBankAccounts: (accessToken: string) => Promise<AxiosResponse<any>> = async (
    accessToken
  ) => {
    const { app }: { app: any } = store.getState();
    const pvtAxiosObj: AxiosInstance = Axios.create();
    pvtAxiosObj.defaults.baseURL = `https://${app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    return await pvtAxiosObj.get<EntityOwner>(`/platform-owner-settings/bank-account/all`);
  };

  public postBankAccount: (data: FormData, config?: any) => any = async (data, config) => {
    const pvtAxiosObj: AxiosInstance = Axios.create();
    const { app }: { app: any } = store.getState();
    pvtAxiosObj.defaults.baseURL = `https://${app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${app.accessToken}`;
    pvtAxiosObj.interceptors.response.use(
      (next: any) => {
        return Promise.resolve(next);
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    try {
      const response = config
        ? await pvtAxiosObj.post(`/platform-owner-settings/bank-account`, data, config)
        : await pvtAxiosObj.post(`/platform-owner-settings/bank-account`, data);
      return response;
    } catch (error: any) {
      return `ERROR ${
        error?.response?.data?.errors[0]?.message
          ? ': ' +
            error?.response?.data?.errors[0]?.message +
            ' - ' +
            error?.response?.data?.errors[0]?.details.join(' | ')
          : ''
      }`;
    }
  };

  public patchBankAccount: (
    data: FormData,
    bankAccountId: string,
    overrideDefaultCurrency: boolean
  ) => any = async (data, bankAccountId, overrideDefaultCurrency) => {
    const config = {
      headers: {
        isCurrencyOverrideConfirmed: true
      }
    };
    return overrideDefaultCurrency
      ? await this.axiosInstance.patch(
          `/platform-owner-settings/bank-account/${bankAccountId}`,
          data,
          config
        )
      : await this.axiosInstance.patch(
          `/platform-owner-settings/bank-account/${bankAccountId}`,
          data
        );
  };

  public getBankAccountById: (bankAccountId: string) => Promise<AxiosResponse<EntityBankAccount>> =
    async (bankAccountId) =>
      await this.axiosInstance.get<EntityBankAccount>(
        `/platform-owner-settings/bank-account/${bankAccountId}`
      );

  public getReferenceBaseRatesByReferenceId: (
    referenceId: string
  ) => Promise<AxiosResponse<IBaseRateReferenced>> = async (referenceId) =>
    await this.axiosInstance.get<IBaseRateReferenced>(
      `/platform-owner-settings/base-rates/${referenceId}`
    );

  public getCurrencies: () => Promise<AxiosResponse<EntityCurrency[]>> = async () =>
    await this.axiosInstance.get<EntityCurrency[]>(`/entity-management/currencies`);

  public getCountries: () => Promise<ICountries> = async () => {
    const { data } = await this.axiosInstance.get(`/entity-management/countries?size=500`);
    return data;
  };

  public getEntityDetailsById: (id: string) => Promise<AxiosResponse<EntityOwner>> = async (id) =>
    await this.axiosInstance.get<EntityOwner>(`/entity-management/entities/${id}`);

  public putCompanyLogo: (
    formData: FormData,
    companyID: string
  ) => Promise<AxiosResponse<EntityLogo>> = async (data, companyID) => {
    const pvtAxiosObj: AxiosInstance = Axios.create();
    const { app }: { app: any } = store.getState();
    // pvtAxiosObj.defaults.baseURL = `https://${app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    pvtAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${app.accessToken}`;
    pvtAxiosObj.interceptors.response.use(
      (next: any) => {
        return Promise.resolve(next);
      },
      (error: any) => {
        store.dispatch({
          type: UPDATE_REQUEST_ERROR,
          payload: error.message
        });
        return Promise.reject(error);
      }
    );
    return await pvtAxiosObj.put<EntityLogo>(
      `${window.API_PATH_INTERNAL}/company/${app.tenant}`,
      data
    );
  };

  public getCompanyLogo: (companyID: string) => Promise<AxiosResponse<EntityLogo>> = async (
    companyID
  ) => {
    const nonInterceptedAxiosObj: AxiosInstance = Axios.create();
    const { app }: { app: any } = store.getState();
    // nonInterceptedAxiosObj.defaults.baseURL = `https://${app.tenant}.${window.MARCOPOLO_DOMAIN}/api/v1`;
    nonInterceptedAxiosObj.defaults.headers.common['Authorization'] = `Bearer ${app.accessToken}`;
    return await nonInterceptedAxiosObj.get<EntityLogo>(
      `${window.API_PATH_INTERNAL}/company/${app.tenant}`
    );
  };

  public getProofOfAccount: (
    proofOfAccountId: string
  ) => Promise<AxiosResponse<EntityProofOfAccount>> = async (proofOfAccountId) =>
    await this.axiosInstance.get<EntityProofOfAccount>(
      `/platform-owner-settings/bank-account/proof/metadata/${proofOfAccountId}`
    );

  public getProofOfAccountDocument: (
    proofOfAccountId: string
  ) => Promise<AxiosResponse<EntityProofOfAccount>> = async (proofOfAccountId) =>
    await this.axiosInstance.get<EntityProofOfAccount>(
      `/platform-owner-settings/bank-account/proof/data/${proofOfAccountId}`
    );

  public getProgramMetadata: (
    programType: string,
    moduleId: string,
    assetType: keyof typeof AssetEnum
  ) => Promise<AxiosResponse<IAssetMetadata[]>> = async (programType, moduleId, assetType) => {
    return await this.axiosInstance.get<IAssetMetadata[]>(
      `/platform-owner-settings/data/metadata/${moduleId}/${assetType.toLowerCase()}s/${programType}/v2.5`
    );
  };
}
