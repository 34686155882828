import MPNLogo from 'assets/icons/MPNLogo';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileIconContainer, MobileNavBarStyled } from '../styled';

const MobileNavBar: FC = () => {
  const navigate = useNavigate();

  return (
    <MobileNavBarStyled
      data-automation-id="navbar-div-mobile-container"
      data-testid="sp-mobile-navbar"
    >
      <MobileIconContainer
        onClick={() => navigate('/')}
        data-automation-id="navbar-div-mobile-logo"
        data-testid="sp-navbar-logo"
      >
        <MPNLogo />
      </MobileIconContainer>
    </MobileNavBarStyled>
  );
};

export default MobileNavBar;
