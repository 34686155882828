/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import BaseCard from 'components/common/cards/BaseCard';
import LedgerDetail from 'components/ledger/LedgerDetail';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { logisticTabs } from 'lib/data/logisticTabs';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { Dispatch, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SET_BREADCRUMB, SET_DOCUMENT_FOR_VIEW } from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

const LogisticDetail: FC = () => {
  const [searchParams] = useSearchParams();
  const searchTab = searchParams.get('tab');
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [defaultTab, setDefaultTab] = useState<number>(0);
  const isProgramOpenAccount: boolean = useCheckProgramsContainsType(ProgramType.OPEN_ACCOUNT);
  // const {
  //   documentForViewHeaderData
  // }: {
  //   documentForViewHeaderData: {
  //     header: LedgerDetailHeaderProps | null;
  //     data: ILedgerInvoice | ILedgerPurchaseOrder | null;
  //   } | null;
  // } = useSelector((state: RootStateOrAny) => state.context);

  useEffect(() => {
    return () => {
      dispatch({ type: SET_BREADCRUMB, payload: null });
      dispatch({ type: SET_DOCUMENT_FOR_VIEW, payload: null });
    };
  }, []);

  useEffect(() => {
    searchTab && setDefaultTab(Number(searchTab));
  }, [searchTab]);

  const mapTabs: () => TabsItem[] = () => {
    if (isProgramOpenAccount) return logisticTabs.map((tab) => tab);
    return [
      {
        text: 'Logistics',
        children: <LedgerDetail type={AssetEnum.LOGISTIC} showEntities showSummaryAccordions />
      }
    ];
  };

  return (
    <LayoutViewContainer size="xlarge">
      {/* {documentForViewHeaderData && (
        <LedgerNotifications matchingClickHandler={() => setDefaultTab(1)} />
      )} */}
      <BaseCard
        noBorder
        noPadding
        minWidth
        tabs={
          <Tabs
            tabItems={mapTabs()}
            documentName="Logistic"
            defaultValue={defaultTab}
            testingTagPage="logistic-detail"
          />
        }
      />
    </LayoutViewContainer>
  );
};

export default LogisticDetail;
