import { IInvoiceLogistics } from 'utils/interfaces/invoice/invoice.interface';

export const InvoiceLogisticsFlipDictionary: IInvoiceLogistics = {
  consigneeName: 'buyerName',
  consigneeReference: 'buyerRef',
  consignorName: 'supplierName',
  consignorReference: 'supplierRef',
  incoTerms: 'logistics.incoTerms',
  notifyPartyName: 'logistics.notifyPartyName',
  origin: 'origin',
  lineItems: 'data.lineItems',
  invoiceNumber: 'invoiceNumber',
  'purchaseOrders[0]?.purchaseOrderNumber': 'poNumber',
  'contacts.notifyPartyContactDetails.firstName':
    'logistics.contacts.notifyPartyContactDetails.firstName',
  'contacts.notifyPartyContactDetails.lastName':
    'logistics.contacts.notifyPartyContactDetails.lastName',
  'contacts.notifyPartyContactDetails.emailAddress':
    'logistics.contacts.notifyPartyContactDetails.emailAddress',
  'contacts.notifyPartyContactDetails.contactNumber':
    'logistics.contacts.notifyPartyContactDetails.contactNumber',
  'addresses.shipToAddress.addressee': 'logistics.addresses.shipToAddress.addressee',
  'addresses.shipToAddress.addressLine1': 'logistics.addresses.shipToAddress.addressLine1',
  'addresses.shipToAddress.addressLine2': 'logistics.addresses.shipToAddress.addressLine2',
  'addresses.shipToAddress.addressLine3': 'logistics.addresses.shipToAddress.addressLine3',
  'addresses.shipToAddress.addressLine4': 'logistics.addresses.shipToAddress.addressLine4',
  'addresses.shipToAddress.code': 'logistics.addresses.shipToAddress.code',
  'addresses.shipToAddress.city': 'logistics.addresses.shipToAddress.city',
  'addresses.shipToAddress.region': 'logistics.addresses.shipToAddress.region',
  'addresses.shipToAddress.country': 'logistics.addresses.shipToAddress.country',
  'addresses.consignorAddress.addressee': 'logistics.addresses.consignorAddress.addressee',
  'addresses.consignorAddress.addressLine1': 'logistics.addresses.consignorAddress.addressLine1',
  'addresses.consignorAddress.addressLine2': 'logistics.addresses.consignorAddress.addressLine2',
  'addresses.consignorAddress.addressLine3': 'logistics.addresses.consignorAddress.addressLine3',
  'addresses.consignorAddress.addressLine4': 'logistics.addresses.consignorAddress.addressLine4',
  'addresses.consignorAddress.code': 'logistics.addresses.consignorAddress.code',
  'addresses.consignorAddress.city': 'logistics.addresses.consignorAddress.city',
  'addresses.consignorAddress.region': 'logistics.addresses.consignorAddress.region',
  'addresses.consignorAddress.country': 'logistics.addresses.consignorAddress.country',
  'addresses.consigneeAddress.addressee': 'logistics.addresses.consigneeAddress.addressee',
  'addresses.consigneeAddress.addressLine1': 'logistics.addresses.consigneeAddress.addressLine1',
  'addresses.consigneeAddress.addressLine2': 'logistics.addresses.consigneeAddress.addressLine2',
  'addresses.consigneeAddress.addressLine3': 'logistics.addresses.consigneeAddress.addressLine3',
  'addresses.consigneeAddress.addressLine4': 'logistics.addresses.consigneeAddress.addressLine4',
  'addresses.consigneeAddress.code': 'logistics.addresses.consigneeAddress.code',
  'addresses.consigneeAddress.city': 'logistics.addresses.consigneeAddress.city',
  'addresses.consigneeAddress.region': 'logistics.addresses.consigneeAddress.region',
  'addresses.consigneeAddress.country': 'logistics.addresses.consigneeAddress.country',
  'addresses.notifyPartyAddress.addressee': 'logistics.addresses.notifyPartyAddress.addressee',
  'addresses.notifyPartyAddress.addressLine1':
    'logistics.addresses.notifyPartyAddress.addressLine1',
  'addresses.notifyPartyAddress.addressLine2':
    'logistics.addresses.notifyPartyAddress.addressLine2',
  'addresses.notifyPartyAddress.addressLine3':
    'logistics.addresses.notifyPartyAddress.addressLine3',
  'addresses.notifyPartyAddress.addressLine4':
    'logistics.addresses.notifyPartyAddress.addressLine4',
  'addresses.notifyPartyAddress.code': 'logistics.addresses.notifyPartyAddress.code',
  'addresses.notifyPartyAddress.city': 'logistics.addresses.notifyPartyAddress.city',
  'addresses.notifyPartyAddress.region': 'logistics.addresses.notifyPartyAddress.region',
  'addresses.notifyPartyAddress.country': 'logistics.addresses.notifyPartyAddress.country',
  'addresses.logisticsServiceProviderAddress.addressee':
    'logistics.addresses.logisticsServiceProviderAddress.addressee',
  'addresses.logisticsServiceProviderAddress.addressLine1':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine1',
  'addresses.logisticsServiceProviderAddress.addressLine2':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine2',
  'addresses.logisticsServiceProviderAddress.addressLine3':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine3',
  'addresses.logisticsServiceProviderAddress.addressLine4':
    'logistics.addresses.logisticsServiceProviderAddress.addressLine4',
  'addresses.logisticsServiceProviderAddress.code':
    'logistics.addresses.logisticsServiceProviderAddress.code',
  'addresses.logisticsServiceProviderAddress.city':
    'logistics.addresses.logisticsServiceProviderAddress.city',
  'addresses.logisticsServiceProviderAddress.region':
    'logistics.addresses.logisticsServiceProviderAddress.region',
  'addresses.logisticsServiceProviderAddress.country':
    'logistics.addresses.logisticsServiceProviderAddress.country',
  'addresses.manufacturerAddress.addressee': 'logistics.addresses.manufacturerAddress.addressee',
  'addresses.manufacturerAddress.addressLine1':
    'logistics.addresses.manufacturerAddress.addressLine1',
  'addresses.manufacturerAddress.addressLine2':
    'logistics.addresses.manufacturerAddress.addressLine2',
  'addresses.manufacturerAddress.addressLine3':
    'logistics.addresses.manufacturerAddress.addressLine3',
  'addresses.manufacturerAddress.addressLine4':
    'logistics.addresses.manufacturerAddress.addressLine4',
  'addresses.manufacturerAddress.code': 'logistics.addresses.manufacturerAddress.code',
  'addresses.manufacturerAddress.city': 'logistics.addresses.manufacturerAddress.city',
  'addresses.manufacturerAddress.region': 'logistics.addresses.manufacturerAddress.region',
  'addresses.manufacturerAddress.country': 'logistics.addresses.manufacturerAddress.country',
  'locations.portOrPlaceOfLoading.name': 'logistics.locations.portOrPlaceOfLoading.name',
  'locations.portOrPlaceOfDestination.name': 'logistics.locations.portOrPlaceOfDestination.name'
};
