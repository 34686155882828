import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerPurchaseOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LedgerPurchaseOrderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

export const LedgerPurchaseOrderTitle = styled.h4``;

export const LedgerPurchaseOrderSubtitle = styled.p`
  ${typography.fontHindRegular};
  margin-bottom: 8px;
`;

//MOBILE STYLES
export const LedgerPurchaseOrderMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const LedgerPurchaseOrderMobileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 4px;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LedgerPurchaseOrderMobileItemLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LedgerPurchaseOrderMobileItemRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const LedgerPurchaseOrderMobileItemBold = styled.p`
  ${typography.bodySemiBold};
`;

export const LedgerPurchaseOrderMobileItemRegular = styled.p`
  ${typography.captionRegular};
`;

export const LedgerPurchaseOrderNoData = styled.h5`
  padding: 24px;
`;
