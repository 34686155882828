import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const LedgerPaymentHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${isMobile() ? 'column' : 'row'};
  align-items: start;
  justify-content: space-between;
  padding: ${isMobile() ? '16px' : '16px 24px'};
  gap: ${isMobile() ? '16px' : '24px'};
`;

export const LedgerPaymentHeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const LedgerPaymentHeaderRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobile() ? 'flex-start' : 'flex-end'};
  gap: 4px;
  min-width: fit-content;
`;

export const LedgerPaymentHeaderRow = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  gap: 16px;
`;

export const LedgerPaymentHeaderAmountLabel = styled.h5`
  text-align: end;
`;

export const LedgerPaymentHeaderLabel = styled.h3`
  text-align: start;
`;

export const LedgerPaymentHeaderTitle = styled.h2``;
