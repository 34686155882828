import Select from '@mui/material/Select';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface ButtonProps {
  disabled: boolean;
}

export const CustomPaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
`;

export const CustomPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CustomPaginationLabel = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.subtle};
  margin: 0;
`;

export const CustomPaginationButtonLabel = styled.p`
  ${typography.captionRegular};
  color: ${themeColors.text.light.subtle};
  margin: 0;
`;

export const CustomPaginationButton = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && 'none'};

  svg {
    path {
      fill: ${({ disabled }) => disabled ? `${themeColors.text.light.muted}` : `${themeColors.text.light.subtle}`};
    }
  }

  p {
      color: ${({ disabled }) => disabled ? `${themeColors.text.light.muted}` : `${themeColors.text.light.subtle}`};
    }

  &:hover {
    
    svg {
      path {
        fill: ${themeColors.text.light.body}!important;
      }
    }
    p {
      color: ${themeColors.text.light.body}!important;
    }
  }
`;

export const SelectStyled = styled(Select)`
  min-width: 60px;
  margin: 0 auto;
  padding: 0 4px;

  .MuiSelect-select {
    ${typography.captionRegular};
    font-family: 'Source Sans Pro', sans-serif;
    background-color: ${themeColors.white};
    color: ${themeColors.text.light.subtle};
    padding: 3px;  
  }
  
`;
