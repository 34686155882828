import { IUser } from 'api/interfaces/users/user.interface';
import { sideBarNavigationData } from 'lib/data/sideBarNavigationData';
import { isMobile } from 'lib/helpers/mobile';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SideBarData } from 'utils/interfaces/sidebar/sidebar.interface';
import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';
import { NavigationContainer } from './styled';

const Sidebar: FC = () => {
  const location = useLocation();
  const pathname: string = location.pathname;
  const [sideBarMenu, setSideBarMenu] = useState<SideBarData>();
  const { g2fFeatureHidden, isUserAdmin }: { g2fFeatureHidden: boolean; isUserAdmin: boolean } =
    useSelector((state: RootStateOrAny) => state.app);
  const { loggedInUser }: { loggedInUser: IUser | null } = useSelector(
    (state: RootStateOrAny) => state.user
  );

  useEffect(() => {
    pathname && getSideBarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getSideBarData: () => void = () => {
    const data: SideBarData | undefined = sideBarNavigationData.find((item: SideBarData) =>
      pathname.includes(item.path)
    );
    data && configureAccess(data);
  };

  const configureAccess: (data: SideBarData) => void = (data) =>
    setSideBarMenu(configureDemoAccess(configureAdminAccess(data)));

  const configureAdminAccess: (data: SideBarData) => SideBarData = (data) => {
    if (loggedInUser && isUserAdmin) return data;

    data.links = data.links.filter((l) => l.text !== 'User management');

    return data;
  };

  const configureDemoAccess: (data: SideBarData) => SideBarData = (data) => {
    if (!g2fFeatureHidden) return data;

    data.links = data.links.filter((l) => l.text !== 'Connections');

    return data;
  };

  return (
    <NavigationContainer>
      {isMobile() ? (
        <SidebarMobile sideBarMenuData={sideBarMenu} pathname={pathname} />
      ) : (
        <SidebarDesktop sideBarMenuData={sideBarMenu} pathname={pathname} />
      )}
    </NavigationContainer>
  );
};

export default Sidebar;
