/* eslint-disable react-hooks/exhaustive-deps */
import { IUser, IUserData, IUserForm, IUserProfile } from 'api/interfaces/users/user.interface';
import DotIcon from 'assets/icons/DotIcon';
import WarningIcon from 'assets/icons/WarningIcon';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import ActionLink from 'components/common/links/ActionLink';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import { FullPageLoaderCenteringContainer } from 'components/common/loader/LoaderFullPage/styled';
import TextInput from 'components/forms/inputs/TextInput';
import {
  userManagementOnlyActivateLink,
  userManagementOnlyDeactivateLink,
  userManagementOnlyResetPasswordLink
} from 'lib/data/user-management/userManagementActiveLink';
import { _user } from 'lib/data/user-management/userModel';
import { UserActionStatusesEnum, UserStatusesEnum } from 'lib/enums/user/userStatuses.enum';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { GET_LOGGED_IN_USER, UPDATE_USER, UPDATE_USER_ACTION } from 'store/actions';
import { NavigationItem } from 'utils/interfaces/navigation/navigation.interface';
import {
  CheckboxStyled,
  FormStyled,
  NotificationContainer,
  PersonalDetailsEditActionsContainer,
  PersonalDetailsEditButtonWrapper,
  PersonalDetailsEditCheckboxMessage,
  PersonalDetailsEditContainer,
  PersonalDetailsEditDefaultRow,
  PersonalDetailsEditHeader,
  PersonalDetailsEditTitle,
  PersonalDetailsEditUserContainer,
  PersonalDetailsEditUserEmail,
  PersonalDetailsEditUserInfo,
  PersonalDetailsEditUserStatus,
  PersonalDetailsEditWrapper
} from './styled';

interface PersonalDetailsEditDialogProps {
  closeHandler: () => void;
}

const PersonalDetailsEditDialog: FC<PersonalDetailsEditDialogProps> = ({ closeHandler }) => {
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState<string>('');
  const [userDataModel, setUserDataModel] = useState<IUser>(_user);
  const [originalDataModel, setOriginalDataModel] = useState<IUser>(_user);

  const { loggedInUser, loading }: { loggedInUser: IUser | null; loading: boolean } = useSelector(
    (state: RootStateOrAny) => state.user
  );

  const {
    tenant,
    userSubId,
    isUserAdmin
  }: { tenant: string | null; userSubId: string | null; isUserAdmin: boolean } = useSelector(
    (state: RootStateOrAny) => state.app
  );

  const tenants: () => number = () =>
    (loggedInUser && loggedInUser.profile?.tenants.filter((t) => t === tenant).length) || 0;

  const isUsersOwnProfile: boolean =
    (tenants() > 0 && isUserAdmin && loggedInUser?.id === userSubId) || false;

  const {
    handleSubmit,
    formState: { errors }
  } = useForm<IUserData>({ mode: 'onChange', reValidateMode: 'onChange' });
  useEffect(() => {
    if (!loggedInUser) return;
    setUserStatus(loggedInUser?.status || '');
    setUserDataModel(loggedInUser);
    setOriginalDataModel(loggedInUser);
  }, [loggedInUser]);

  const onSubmit: SubmitHandler<IUserData> = () => {
    if (!loggedInUser) return;
    const adminChanged: boolean = loggedInUser.isAdmin !== userDataModel.isAdmin;
    const { firstName, lastName, mobilePhone, title } = userDataModel.profile;
    const payload: IUserForm = {
      id: loggedInUser.id,
      data: {
        isAdmin: userDataModel.isAdmin,
        profile: {
          firstName,
          lastName,
          mobilePhone,
          title
        }
      }
    };

    if (!adminChanged || !isUsersOwnProfile) delete payload.data.isAdmin;
    dispatch({ type: UPDATE_USER, payload: { ...payload, noCall: true } });

    setTimeout(() => {
      dispatch({ type: GET_LOGGED_IN_USER, payload: loggedInUser.id });
      closeHandler();
    }, 2000);
  };

  const updateProfileModel: (key: string, value: string) => void = (key, value) => {
    const clone: IUser = { ...userDataModel };
    const updatedProfile: IUserProfile = { ...clone.profile, [key]: value };
    clone.profile = updatedProfile;
    setUserDataModel(clone);
  };

  const updateAdminStatus: (value: boolean) => void = (value) => {
    const clone: IUser = { ...userDataModel };
    clone.isAdmin = value;
    setUserDataModel(clone);
  };

  const setActionHandler: (userId: string | null, type: string) => void = (userId, type) => {
    if (!userId) return;
    userId &&
      dispatch({
        type: UPDATE_USER_ACTION,
        payload: { userId: userId, type: type }
      });
  };

  const getStatusColor: () => string = () => {
    switch (userStatus) {
      case UserStatusesEnum.ACTIVE:
        return themeColors.icon.success;
      default:
        return themeColors.icon.error;
    }
  };

  const setActiveLink: () => NavigationItem[] | undefined = () => {
    if (
      userStatus === UserStatusesEnum.SUSPENDED ||
      userStatus === UserStatusesEnum.PASSWORD_EXPIRED ||
      userStatus === UserStatusesEnum.LOCKED_OUT ||
      userStatus === UserStatusesEnum.RECOVERY
    )
      return userManagementOnlyDeactivateLink;
    if (userStatus === UserStatusesEnum.DEACTIVATED) return userManagementOnlyActivateLink;
    if (userStatus === UserStatusesEnum.ACTIVE) return userManagementOnlyResetPasswordLink;
    if (userStatus === UserStatusesEnum.PROVISIONED) return userManagementOnlyDeactivateLink;
  };

  const setNotification: () => JSX.Element = () => {
    switch (userStatus) {
      case UserStatusesEnum.SUSPENDED:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.error}
              icon={<WarningIcon color={themeColors.icon.error} />}
              hasButton={true}
              clickHandler={() =>
                setActionHandler(loggedInUser?.id || null, UserActionStatusesEnum.ACTIVATE)
              }
              buttonText="Activate"
              title="Account suspended"
              description="This user has been suspended. You can activate this user at anytime to give them access to Supplier Pay."
            />
          </NotificationContainer>
        );
      case UserStatusesEnum.DEACTIVATED:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.error}
              icon={<WarningIcon color={themeColors.icon.error} />}
              title="Account deactivated"
              description="This user has been deactivated. You will have to create a new user account if this user requires access in the future."
            />
          </NotificationContainer>
        );
      case UserStatusesEnum.PASSWORD_EXPIRED:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.warning}
              icon={<WarningIcon color={themeColors.icon.warning} />}
              hasButton={true}
              clickHandler={() =>
                setActionHandler(loggedInUser?.id || null, UserActionStatusesEnum.RESET)
              }
              buttonText="Reset password"
              title="Password expired"
              description="This user’s password has expired. They will need to reset their password on next log in."
            />
          </NotificationContainer>
        );
      case UserStatusesEnum.RECOVERY:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.warning}
              icon={<WarningIcon color={themeColors.icon.warning} />}
              title="Account in recovery mode"
              description="This user has been activated previously but is in password reset mode. when the user sets a new password they will become active."
            />
          </NotificationContainer>
        );
      case UserStatusesEnum.PROVISIONED:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.warning}
              icon={<WarningIcon color={themeColors.icon.warning} />}
              title="Awaiting user registration"
              description="An account has been created for this user but they have not yet completed registration of their account."
            />
          </NotificationContainer>
        );
      case UserStatusesEnum.LOCKED_OUT:
        return (
          <NotificationContainer>
            <Notification
              noShadow
              color={themeColors.text.warning}
              icon={<WarningIcon color={themeColors.icon.warning} />}
              hasButton={true}
              clickHandler={() =>
                setActionHandler(loggedInUser?.id || null, UserActionStatusesEnum.RESET)
              }
              buttonText="Reset password"
              title="Account locked"
              description="This user has been locked out after attempting to enter their password too many times. Reset their password to unlock their account."
            />
          </NotificationContainer>
        );
      default:
        return <></>;
    }
  };

  return loading ? (
    <FullPageLoaderCenteringContainer>
      <LoaderFullPage />
    </FullPageLoaderCenteringContainer>
  ) : (
    <PersonalDetailsEditWrapper>
      <PersonalDetailsEditContainer>
        <PersonalDetailsEditHeader>
          <PersonalDetailsEditTitle data-automation-id="personal-details-user-invite-h2-page-title">
            Edit User
          </PersonalDetailsEditTitle>
          {setActiveLink() && <ActionLink menuItems={setActiveLink()} userManagementDialog />}
        </PersonalDetailsEditHeader>
        <FormStyled
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
          }}
          data-testid="sp-personal-details-user-edit-form"
        >
          <PersonalDetailsEditUserInfo>
            <PersonalDetailsEditUserEmail>
              {loggedInUser?.profile?.email || ''}
            </PersonalDetailsEditUserEmail>
            <PersonalDetailsEditUserStatus>
              {userStatus && <DotIcon color={getStatusColor()} />}
              {userStatus}
            </PersonalDetailsEditUserStatus>
          </PersonalDetailsEditUserInfo>
          {setNotification()}
          <PersonalDetailsEditUserContainer>
            <TextInput
              label="First Name"
              defaultValue={userDataModel?.profile.firstName || ''}
              changeHandler={(event) => updateProfileModel('firstName', event.target.value?.trim())}
              testingTagPage="personal-details-user-invite"
            />
            <TextInput
              label="Last Name"
              defaultValue={userDataModel?.profile.lastName || ''}
              changeHandler={(event) => updateProfileModel('lastName', event.target.value?.trim())}
              testingTagPage="personal-details-user-invite"
            />
          </PersonalDetailsEditUserContainer>
          <TextInput
            label="Contact Number"
            defaultValue={userDataModel?.profile.mobilePhone || ''}
            changeHandler={(event) => updateProfileModel('mobilePhone', event.target.value)}
            testingTagPage="personal-details-user-invite"
          />
          <TextInput
            label="Job Title"
            defaultValue={userDataModel?.profile.title || ''}
            changeHandler={(event) => updateProfileModel('title', event.target.value)}
            testingTagPage="personal-details-user-invite"
          />
          {isUserAdmin && (
            <PersonalDetailsEditDefaultRow>
              <CheckboxStyled
                checked={userDataModel?.isAdmin}
                disabled={isUsersOwnProfile}
                onChange={(event) => updateAdminStatus(event.target.checked)}
                data-testid="sp-checkbox-item"
                inputProps={
                  {
                    'aria-label': 'controlled',
                    'data-testid': 'sp-clickable-checkbox'
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                data-automation-id="personal-details-user-invite-span-checkbox-administrator"
              />
              <PersonalDetailsEditCheckboxMessage data-automation-id="personal-details-user-invite-p-checkbox-message">
                Administrator
              </PersonalDetailsEditCheckboxMessage>
            </PersonalDetailsEditDefaultRow>
          )}
          <PersonalDetailsEditActionsContainer>
            <PersonalDetailsEditButtonWrapper>
              <PrimaryButton
                type="submit"
                disabled={
                  !!Object.keys(errors).length ||
                  JSON.stringify(userDataModel) === JSON.stringify(originalDataModel)
                }
                text="Save"
                testingTag="personal-details-user-invite-button-save"
              />
            </PersonalDetailsEditButtonWrapper>
            <PersonalDetailsEditButtonWrapper>
              <SecondaryButton
                clickHandler={closeHandler}
                testingTag="personal-details-user-invite-button-cancel"
                text="Cancel"
              />
            </PersonalDetailsEditButtonWrapper>
          </PersonalDetailsEditActionsContainer>
        </FormStyled>
      </PersonalDetailsEditContainer>
    </PersonalDetailsEditWrapper>
  );
};

export default PersonalDetailsEditDialog;
