import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ButtonRightArrowIcon: FC<IconSvgProps> = ({
  color = themeColors.bg.primaryActive,
  height = '14px',
  width = '8px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.653377 -0.0675907 1.22061 -0.0953203 1.6129 0.209705L1.70711 0.292893L7.70711 6.29289C8.06759 6.65338 8.09532 7.22061 7.7903 7.6129L7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0675907 13.3466 -0.0953203 12.7794 0.209705 12.3871L0.292893 12.2929L5.585 7L0.292893 1.70711C-0.0675907 1.34662 -0.0953203 0.779392 0.209705 0.387101L0.292893 0.292893Z"
      fill={color}
    />
  </svg>
);

export default ButtonRightArrowIcon;
