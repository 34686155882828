import { FC } from 'react';
import { TabPanelContainer } from './styled';

interface TabPanelProps {
  children: JSX.Element;
  value: number;
  index: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <TabPanelContainer data-testid={`sp-tab-panel`}>
      {value === index && children}
    </TabPanelContainer>
  );
};

export default TabPanel;
