import { FC } from 'react';
import { themeColors } from 'assets/theme/style';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const ShowMoreIcon: FC<IconSvgProps> = ({
  height = '12',
  width = '12',
  color = themeColors.icon.primary
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 12 12`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.304482 2.46927C0 3.20435 0 4.13623 0 6C0 7.86377 0 8.79565 0.304482 9.53073C0.710458 10.5108 1.48915 11.2895 2.46927 11.6955C3.20435 12 4.13623 12 6 12C7.86377 12 8.79565 12 9.53073 11.6955C10.5108 11.2895 11.2895 10.5108 11.6955 9.53073C12 8.79565 12 7.86377 12 6C12 4.13623 12 3.20435 11.6955 2.46927C11.2895 1.48915 10.5108 0.710458 9.53073 0.304482C8.79565 0 7.86377 0 6 0C4.13623 0 3.20435 0 2.46927 0.304482C1.48915 0.710458 0.710458 1.48915 0.304482 2.46927ZM8.66667 6.66667C9.03486 6.66667 9.33333 6.36819 9.33333 6C9.33333 5.63181 9.03486 5.33333 8.66667 5.33333L3.33333 5.33333C2.96514 5.33333 2.66667 5.63181 2.66667 6C2.66667 6.36819 2.96514 6.66667 3.33333 6.66667H8.66667Z"
      fill={color}
    />
  </svg>
);

export default ShowMoreIcon;
