import { LedgerDetailHeaderProps } from 'components/ledger/LedgerDetail/LedgerDetailHeader';

export default class LedgerHeaderBuilder implements LedgerDetailHeaderProps {
  constructor(
    public identifier: string,
    public valueTitle: string,
    public value: number,
    public currency: string,
    public status: string,
    public isDiscrepant: boolean,
    public netAmount?: number
  ) {}
}
