import { styling, themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const CollapsibleCardContainerBackground = styled.div`
  display: flex;
  width: 99%;
  justify-content: space-between;
  padding: ${styling.defaultRadius} 0px ${styling.defaultRadius} ${styling.defaultRadius};
  border-radius: ${styling.defaultRadius};
  border: 1px solid ${themeColors.bg.light.muted};
  background-color: ${themeColors.mono6};
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
  height: 72px;
`;
export const CollapsibleCardContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  background-color: ${themeColors.white};
  width: 100%;
  justify-content: space-between;
  padding: ${styling.defaultRadius} 0px ${styling.defaultRadius} ${styling.defaultRadius};
  border-radius: ${styling.defaultRadius};
  border: 1px solid ${themeColors.bg.light.muted};
  margin-bottom: 16px;
  cursor: pointer;
  height: 72px;
`;

export const CollapsibleCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const CollapsibleCardRight = styled.div`
  display: block;
`;

export const CollapsibleCardTitle = styled.div`
  display: flex;
  color: ${themeColors.text.light.body};
  ${typography.bodySemiBold};
`;

export const CollapsibleCardValue = styled.div`
  display: flex;
  color: ${themeColors.text.light.subtle};
  ${typography.captionRegular};
`;

export const CollapsibleCardExpand = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.text.info};
  ${typography.bodySemiBold};
  padding-right: 16px;
  cursor: pointer;
`;
