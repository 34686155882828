import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { FC } from 'react';
import {
  SessionTimeoutDialogButtonWrapper,
  SessionTimeoutDialogDialogStyled,
  SessionTimeoutDialogText,
  SessionTimeoutDialogTitle
} from './styled';

interface SessionTimeoutDialogProps {
  open: boolean;
  clickHandler: () => void;
}

const SessionTimeoutDialog: FC<SessionTimeoutDialogProps> = ({ open = false, clickHandler }) => (
  <SessionTimeoutDialogDialogStyled open={open} onClose={clickHandler} disableEscapeKeyDown>
    <SessionTimeoutDialogTitle>Your session has expired</SessionTimeoutDialogTitle>
    <SessionTimeoutDialogText>
      After a period of inactivity your session has expired. Please click on the button below to log
      in again.
    </SessionTimeoutDialogText>
    <SessionTimeoutDialogButtonWrapper>
      <PrimaryButton text="Log In" clickHandler={() => clickHandler()}></PrimaryButton>
    </SessionTimeoutDialogButtonWrapper>
  </SessionTimeoutDialogDialogStyled>
);

export default SessionTimeoutDialog;
