export const GET_ONBOARDING_ACTION_EVENT =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT';
export const GET_ONBOARDING_ACTION_EVENT_SUCCESS =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT_SUCCESS';
export const GET_ONBOARDING_ACTION_EVENT_FAILURE =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT_FAILURE';
export const GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM';
export const GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS';
export const GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE =
  '@@frontend/store/reducers/onboarding/GET_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE';
export const UPDATE_ONBOARDING_ACTION_EVENT =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT';
export const UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS';
export const UPDATE_ONBOARDING_ACTION_EVENT_FAILURE =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT_FAILURE';
export const UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM';
export const UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS';
export const UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE =
  '@@frontend/store/reducers/onboarding/UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE';
export const ONBOARDING_ADD_BANK_ACCOUNT =
  '@@frontend/store/reducers/onboarding/ONBOARDING_ADD_BANK_ACCOUNT';
export const ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS =
  '@@frontend/store/reducers/onboarding/ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS';
export const ONBOARDING_ADD_BANK_ACCOUNT_FAILURE =
  '@@frontend/store/reducers/onboarding/ONBOARDING_ADD_BANK_ACCOUNT_FAILURE';
export const RESET_ONBOARDING_ERROR = '@@frontend/store/reducers/onboarding/RESET_ONBOARDING_ERROR';
export const RESET_ONBOARDING_DATA = '@@frontend/store/reducers/onboarding/RESET_ONBOARDING_DATA';
