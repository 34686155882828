import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerLogisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LedgerLogisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
`;

export const LedgerLogisticsTitle = styled.h3``;

export const LedgerLogisticsSubtitle = styled.p`
  ${typography.fontHindRegular};
  margin-bottom: 8px;
`;

//MOBILE STYLES
export const LedgerLogisticsMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const LedgerLogisticsMobileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 4px;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LedgerLogisticsMobileItemLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LedgerLogisticsMobileItemRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const LedgerLogisticsMobileItemBold = styled.p`
  ${typography.bodySemiBold};
`;

export const LedgerLogisticsMobileItemRegular = styled.p`
  ${typography.captionRegular};
`;

export const LedgerLogisticsNoData = styled.h5``;
