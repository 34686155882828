import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import OpenAccountLogisticsApi from 'api/open-account/logistics/logistics';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  POST_LOGISTIC_COMMENTS,
  POST_LOGISTIC_COMMENTS_FAILURE,
  POST_LOGISTIC_COMMENTS_SUCCESS,
  GET_LOGISTIC_COMMENTS,
  GET_LOGISTIC_COMMENTS_FAILURE,
  GET_LOGISTIC_COMMENTS_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const postLogisticComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountLogisticOrdersApi = new OpenAccountLogisticsApi(store);
  const { logisticId, data } = action.payload;
  await openAccountLogisticOrdersApi.postLogisticComment(logisticId, data);
  return await getLedgerLogisticComments(action);
};

export const getLedgerLogisticComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountLogisticApi = new OpenAccountLogisticsApi(store);
  return openAccountLogisticApi.getLogisticComments(action.payload.logisticId);
};

//===================================================
//                      EPICS
//===================================================

const postLogisticCommentsEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(POST_LOGISTIC_COMMENTS),
    switchMap((action) =>
      from(postLogisticComments(action)).pipe(
        map((payload) => ({ type: POST_LOGISTIC_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: POST_LOGISTIC_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

const getLogisticCommentsEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_LOGISTIC_COMMENTS),
    switchMap((action) =>
      from(getLedgerLogisticComments(action)).pipe(
        map((payload) => ({ type: GET_LOGISTIC_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_LOGISTIC_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(postLogisticCommentsEpic$, getLogisticCommentsEpic$);
