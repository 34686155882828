import { RequiredAuth } from 'components/routes/SecureRoute';
import { Navigate } from 'react-router';
import { IRoute } from 'utils/interfaces/route/route.interface';
import LockedOut from 'views/Forbidden';

const forbiddenAppRoutes: IRoute[] = [
  { path: '/', name: 'Home', component: <RequiredAuth />, secure: <LockedOut /> },
  {
    path: 'login/callback',
    name: 'Okta Callback',
    component: <RequiredAuth />
  },
  // {
  //   path: 'login/callback',
  //   name: 'Okta Callback',
  //   component: <LoginCallback loadingElement={<LoaderFullPage />} />
  // },
  {
    path: '*',
    name: 'Redirect',
    component: <Navigate to="/" />
  }
];

export default forbiddenAppRoutes;
