import LedgerDetail from 'components/ledger/LedgerDetail';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';
import FundingDetail from 'views/InvoicesView/InvoiceDetail/FundingDetail';

export const invoiceDetailFixedCostTabs: TabsItem[] = [
  {
    text: 'Invoice',
    children: <LedgerDetail type={AssetEnum.INVOICE} showEntities showSummaryAccordions />
  },
  {
    text: 'Funding',
    children: <FundingDetail />
  }
  // {
  //   text: 'Payment',
  //   children: <InvoicePaymentDetail />
  // }
];
