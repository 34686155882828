import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { Logistics } from 'api/interfaces/logistics/logistics.interface';
import {
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY,
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE,
  FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS,
  FETCH_INVOICE_LEDGER,
  FETCH_INVOICE_LEDGER_FAILURE,
  FETCH_INVOICE_LEDGER_SUCCESS,
  FETCH_LOGISTICS_BY_DOCUMENT_ID,
  FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE,
  FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS,
  FETCH_LOGISTICS_BY_PROGRAM_IDS,
  FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE,
  FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS,
  FETCH_PURCHASE_ORDER_LEDGER,
  FETCH_PURCHASE_ORDER_LEDGER_FAILURE,
  FETCH_PURCHASE_ORDER_LEDGER_SUCCESS,
  RESET_LOGISTICS_DATA
} from 'store/actions';
import { ReducerAction, StateBase } from '.';

interface LedgerReducerStateProps extends StateBase {
  paymentsLedger: LedgerPayment[];
  invoiceLedger: ILedgerInvoice[];
  invoicesByEligibility: ILedgerInvoice[];
  purchaseOrderLedger: ILedgerPurchaseOrder[];
  logistics: Logistics | null;
  logisticLedger: Logistics | null;
}

const DEFAULT_STATE: LedgerReducerStateProps = {
  paymentsLedger: [],
  invoiceLedger: [],
  invoicesByEligibility: [],
  purchaseOrderLedger: [],
  logistics: null,
  logisticLedger: null,
  loading: false,
  error: ''
};

const ledgerReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case FETCH_INVOICE_LEDGER:
      return {
        ...state,
        loading: true
      };
    case FETCH_INVOICE_LEDGER_SUCCESS:
      return {
        ...state,
        invoiceLedger: payload.value,
        loading: false,
        error: ''
      };
    case FETCH_INVOICE_LEDGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case FETCH_PURCHASE_ORDER_LEDGER:
      return {
        ...state,
        loading: true
      };
    case FETCH_PURCHASE_ORDER_LEDGER_SUCCESS:
      return {
        ...state,
        purchaseOrderLedger: payload.value,
        loading: false,
        error: ''
      };
    case FETCH_PURCHASE_ORDER_LEDGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY:
      return {
        ...state,
        loading: true
      };
    case FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        invoicesByEligibility: payload.value,
        loading: false,
        error: ''
      };
    case FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case FETCH_LOGISTICS_BY_DOCUMENT_ID:
      return {
        ...state,
        loading: true
      };
    case FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS:
      return {
        ...state,
        logistics: payload.value,
        loading: false,
        error: ''
      };
    case FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case RESET_LOGISTICS_DATA:
      return {
        ...state,
        logistics: null
      };
      case FETCH_LOGISTICS_BY_PROGRAM_IDS:
        return {
          ...state,
          loading: true
        };
      case FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS:
        return {
          ...state,
          logisticLedger: payload.value,
          loading: false,
          error: ''
        };
      case FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        };
      
    default:
      return state;
  }
};

export { ledgerReducer };
export type { LedgerReducerStateProps };
