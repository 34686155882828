import { FC } from 'react';
import { ErrorMessageTextStyled } from './styled';

interface ErrorMessageTextProps {
  text: string;
}

const ErrorMessageText: FC<ErrorMessageTextProps> = ({ text }) => (
  <ErrorMessageTextStyled>{text}</ErrorMessageTextStyled>
);

export default ErrorMessageText;
