import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IPurchaseOrderMatchingResult } from 'api/interfaces/open-account/purchase-orders/purchaseOrders.interface';
import OpenAccountPurchaseOrdersApi from 'api/open-account/purchase-orders/purchaseOrders';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { store } from 'store';
import {
  GET_PO_COMMENTS,
  GET_PO_COMMENTS_FAILURE,
  GET_PO_COMMENTS_SUCCESS,
  GET_PO_MATCHING_RESULTS,
  GET_PO_MATCHING_RESULTS_FAILURE,
  GET_PO_MATCHING_RESULTS_SUCCESS,
  POST_PO_COMMENTS,
  POST_PO_COMMENTS_FAILURE,
  POST_PO_COMMENTS_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const fetchPurchaseOrderMatchingResults: (
  action: any
) => Promise<IPurchaseOrderMatchingResult> = async (action) => {
  const openAccountPurchaseOrdersApi = new OpenAccountPurchaseOrdersApi(store);
  const res: AxiosResponse<IPurchaseOrderMatchingResult> =
    await openAccountPurchaseOrdersApi.getOpenAccountPurchaseOrderMatchingById(action.payload);
  return res.data;
};

export const postPoComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountPurchaseOrdersApi = new OpenAccountPurchaseOrdersApi(store);
  const { purchaseOrderId, data } = action.payload;
  await openAccountPurchaseOrdersApi.postPoComment(purchaseOrderId, data);
  return await getPoComments(action);
};

export const getPoComments: (action: any) => Promise<AxiosResponse<IComment[]>> = async (
  action
) => {
  const openAccountPurchaseOrdersApi = new OpenAccountPurchaseOrdersApi(store);
  return openAccountPurchaseOrdersApi.getPoComments(action.payload.purchaseOrderId);
};

//===================================================
//                      EPICS
//===================================================

const getPurchaseOrderMatchingResults$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_PO_MATCHING_RESULTS),
    switchMap((action) =>
      from(fetchPurchaseOrderMatchingResults(action)).pipe(
        map((payload) => ({ type: GET_PO_MATCHING_RESULTS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_PO_MATCHING_RESULTS_FAILURE, payload: error.message }))
      )
    )
  );

const postPoComments$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(POST_PO_COMMENTS),
    switchMap((action) =>
      from(postPoComments(action)).pipe(
        map((payload) => ({ type: POST_PO_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: POST_PO_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

const getPoComments$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_PO_COMMENTS),
    switchMap((action) =>
      from(getPoComments(action)).pipe(
        map((payload) => ({ type: GET_PO_COMMENTS_SUCCESS, payload })),
        catchError((error) => of({ type: GET_PO_COMMENTS_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(getPurchaseOrderMatchingResults$, postPoComments$, getPoComments$);
