/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  AccordionStyled,
  LedgerDetailAdditionalInformationContentContainer,
  LedgerDetailAdditionalInformationTitle,
  LedgerDetailAdditionalInformationWrapper,
  LedgerDetailsAdditionalInformationKeyValueRow,
  LedgerDetailsAdditionalInformationTextRegular,
  LedgerDetailsAdditionalInformationTextSemiBold
} from './styled';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';

interface ILedgerDetailAdditionalInformation {
  ledgerItem: any;
  type: keyof typeof AssetEnum;
}

const participantInclusionFields: string[] = ['buyer', 'supplier'];

const paymentDetailsInclusionFields: string[] = [
  'paymentDueDate',
  'paymentTerms',
  'paymentReferences'
];

const trackingDetailsInclusionFields: string[] = ['tracking', 'origin'];

const LedgerDetailAdditionalInformation: FC<ILedgerDetailAdditionalInformation> = ({
  ledgerItem,
  type
}) => {
  const { loading: appLoading }: { loading: string } = useSelector(
    (state: RootStateOrAny) => state.app
  );

  const renderParticipants: () => JSX.Element[] = () =>
    Object.keys(ledgerItem.data)
      .filter((k) => participantInclusionFields.some((m) => k.includes(m)))
      .filter(
        (k) =>
          Boolean(ledgerItem.data[k]) &&
          typeof ledgerItem.data[k] !== 'object' &&
          !Array.isArray(ledgerItem.data[k])
      )
      .map((r) => (
        <LedgerDetailsAdditionalInformationKeyValueRow key={r}>
          <LedgerDetailsAdditionalInformationTextSemiBold>
            {toSentenceCase(r)}
          </LedgerDetailsAdditionalInformationTextSemiBold>
          <LedgerDetailsAdditionalInformationTextRegular>
            {ledgerItem.data[r]}
          </LedgerDetailsAdditionalInformationTextRegular>
        </LedgerDetailsAdditionalInformationKeyValueRow>
      ));

  const renderLedgerItemDetails: () => JSX.Element[] = () => {
    const data = Object.keys(ledgerItem.data)
      .filter((k) =>
        [...paymentDetailsInclusionFields, ...participantInclusionFields].some(
          (m) => !k.includes(m)
        )
      )
      .filter(
        (k) =>
          Boolean(ledgerItem.data[k]) &&
          typeof ledgerItem.data[k] !== 'object' &&
          !Array.isArray(ledgerItem.data[k])
      )
      .map((r) => {
        return (
          <LedgerDetailsAdditionalInformationKeyValueRow key={r}>
            <LedgerDetailsAdditionalInformationTextSemiBold>
              {toSentenceCase(r)}
            </LedgerDetailsAdditionalInformationTextSemiBold>
            <LedgerDetailsAdditionalInformationTextRegular>
              {typeof ledgerItem.data[r] === 'number' && r !== 'paymentTerms' && r !== 'version'
                ? formatNumber(ledgerItem.data[r], 2)
                : ledgerItem.data[r]}
            </LedgerDetailsAdditionalInformationTextRegular>
          </LedgerDetailsAdditionalInformationKeyValueRow>
        );
      });

    const program = ledgerItem.linkedPrograms[ledgerItem.linkedPrograms.length - 1].programName;

    if (program)
      data.push(
        <LedgerDetailsAdditionalInformationKeyValueRow key={program}>
          <LedgerDetailsAdditionalInformationTextSemiBold>
            Program
          </LedgerDetailsAdditionalInformationTextSemiBold>
          <LedgerDetailsAdditionalInformationTextRegular>
            {program}
          </LedgerDetailsAdditionalInformationTextRegular>
        </LedgerDetailsAdditionalInformationKeyValueRow>
      );

    return data;
  };

  const renderPaymentDetails: () => JSX.Element[] = () => [
    ...Object.keys(ledgerItem.data)
      .filter((k) => paymentDetailsInclusionFields.some((m) => k.includes(m)))
      .filter((k) => Boolean(ledgerItem.data[k]))
      .map((r) =>
        r && ledgerItem.data[r] ? (
          <LedgerDetailsAdditionalInformationKeyValueRow key={r}>
            <LedgerDetailsAdditionalInformationTextSemiBold>
              {toSentenceCase(r)}
            </LedgerDetailsAdditionalInformationTextSemiBold>
            <LedgerDetailsAdditionalInformationTextRegular>
              {typeof ledgerItem.data[r] === 'number' && r !== 'paymentTerms' && r !== 'version'
                ? formatNumber(ledgerItem.data[r], 2)
                : ledgerItem.data[r]}
            </LedgerDetailsAdditionalInformationTextRegular>
          </LedgerDetailsAdditionalInformationKeyValueRow>
        ) : (
          <></>
        )
      ),
    ...(ledgerItem.linkedPayments && ledgerItem.linkedPayments[0]
      ? Object.keys(ledgerItem.linkedPayments[0])
          .filter((k) => paymentDetailsInclusionFields.some((m) => k.includes(m)))
          .filter((k) => Boolean(ledgerItem.linkedPayments[0][k]))
          .map((r) =>
            r && ledgerItem.linkedPayments[0] && ledgerItem.linkedPayments[0][r] ? (
              <LedgerDetailsAdditionalInformationKeyValueRow key={r}>
                <LedgerDetailsAdditionalInformationTextSemiBold>
                  {toSentenceCase(r)}
                </LedgerDetailsAdditionalInformationTextSemiBold>
                <LedgerDetailsAdditionalInformationTextRegular>
                  {Array.isArray(ledgerItem.linkedPayments[0][r])
                    ? ledgerItem.linkedPayments[0][r].join(', ')
                    : ledgerItem.linkedPayments[0][r]}
                </LedgerDetailsAdditionalInformationTextRegular>
              </LedgerDetailsAdditionalInformationKeyValueRow>
            ) : (
              <></>
            )
          )
      : [])
  ];

  const renderTrackingDetails: () => JSX.Element[] = () => {
    const dataCheck = Object.keys(ledgerItem.data)
      .filter((k) => trackingDetailsInclusionFields.some((m) => k.includes(m)))
      .filter(
        (k) =>
          Boolean(ledgerItem.data[k]) &&
          typeof ledgerItem.data[k] !== 'object' &&
          !Array.isArray(ledgerItem.data[k])
      );

    return dataCheck.map((r) =>
      r && ledgerItem.data[r] ? (
        <LedgerDetailsAdditionalInformationKeyValueRow key={r}>
          <LedgerDetailsAdditionalInformationTextSemiBold>
            {toSentenceCase(r)}
          </LedgerDetailsAdditionalInformationTextSemiBold>
          <LedgerDetailsAdditionalInformationTextRegular>
            {ledgerItem.data[r]}
          </LedgerDetailsAdditionalInformationTextRegular>
        </LedgerDetailsAdditionalInformationKeyValueRow>
      ) : (
        <></>
      )
    );
  };

  return appLoading ? (
    <LoaderInPage />
  ) : (
    <LedgerDetailAdditionalInformationWrapper data-testid="sp-ledger-detail-addresses">
      {renderParticipants().length > 0 && (
        <AccordionStyled disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-automation-id="ledger-detail-lineItems-accordion-summary"
          >
            <LedgerDetailAdditionalInformationTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
              Participants
            </LedgerDetailAdditionalInformationTitle>
          </AccordionSummary>
          <AccordionDetails>
            <LedgerDetailAdditionalInformationContentContainer>
              {renderParticipants()}
            </LedgerDetailAdditionalInformationContentContainer>
          </AccordionDetails>
        </AccordionStyled>
      )}
      <AccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-automation-id="ledger-detail-lineItems-accordion-summary"
        >
          <LedgerDetailAdditionalInformationTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
            {type === AssetEnum.INVOICE && 'Invoice'} {type === AssetEnum.LOGISTIC && 'Logistic'}{' '}
            {type === AssetEnum.PURCHASE_ORDER && 'Purchase order'} details
          </LedgerDetailAdditionalInformationTitle>
        </AccordionSummary>
        <AccordionDetails>
          <LedgerDetailAdditionalInformationContentContainer>
            {renderLedgerItemDetails()}
          </LedgerDetailAdditionalInformationContentContainer>
        </AccordionDetails>
      </AccordionStyled>
      {renderPaymentDetails().length > 0 && (
        <AccordionStyled disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-automation-id="ledger-detail-lineItems-accordion-summary"
          >
            <LedgerDetailAdditionalInformationTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
              Payments
            </LedgerDetailAdditionalInformationTitle>
          </AccordionSummary>
          <AccordionDetails>
            <LedgerDetailAdditionalInformationContentContainer>
              {renderPaymentDetails()}
            </LedgerDetailAdditionalInformationContentContainer>
          </AccordionDetails>
        </AccordionStyled>
      )}
    </LedgerDetailAdditionalInformationWrapper>
  );
};

export default LedgerDetailAdditionalInformation;
