import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const BankAccountsIcon: FC<IconSvgProps> = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#3D3AFF"
      fillOpacity="0.14"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6061 10.0809C19.8157 9.99102 20.0484 9.97604 20.2657 10.0359L20.3939 10.0809L27.3939 13.0809C27.9015 13.2984 28.1367 13.8863 27.9191 14.3939C27.7171 14.8653 27.1958 15.1017 26.7159 14.9589L26.6061 14.9191L20 12.088L13.3939 14.9191C12.9225 15.1212 12.382 14.9328 12.133 14.4985L12.0809 14.3939C11.8788 13.9225 12.0672 13.382 12.5015 13.133L12.6061 13.0809L19.6061 10.0809ZM30 18C30 17.4477 29.5523 17 29 17H28H12H11L10.8834 17.0067C10.386 17.0645 10 17.4872 10 18C10 18.5523 10.4477 19 11 19V28L10.8834 28.0067C10.386 28.0645 10 28.4872 10 29C10 29.5523 10.4477 30 11 30H12H28H29L29.1166 29.9933C29.614 29.9355 30 29.5128 30 29C30 28.4477 29.5523 28 29 28V19L29.1166 18.9933C29.614 18.9355 30 18.5128 30 18ZM13 28V19H27V28H13ZM16.9933 21.8834C16.9355 21.386 16.5128 21 16 21C15.4477 21 15 21.4477 15 22V25L15.0067 25.1166C15.0645 25.614 15.4872 26 16 26C16.5523 26 17 25.5523 17 25V22L16.9933 21.8834ZM20 21C20.5128 21 20.9355 21.386 20.9933 21.8834L21 22V25C21 25.5523 20.5523 26 20 26C19.4872 26 19.0645 25.614 19.0067 25.1166L19 25V22C19 21.4477 19.4477 21 20 21ZM24.9933 21.8834C24.9355 21.386 24.5128 21 24 21C23.4477 21 23 21.4477 23 22V25L23.0067 25.1166C23.0645 25.614 23.4872 26 24 26C24.5523 26 25 25.5523 25 25V22L24.9933 21.8834Z"
      fill="#3D3AFF"
    />
  </svg>
);

export default BankAccountsIcon;
