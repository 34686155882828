import { IInvoiceRuleField } from 'api/interfaces/program/program.interface';
import { _DATE_FORMAT } from 'lib/constants/contants';
import _ from 'lodash';
import { DateTime } from 'luxon';

const newModel: any = {};

export const createInvoiceModel: (rules: IInvoiceRuleField[]) => any = (rules) => {
  rules.forEach((f, i) => {
    if (!f.referencePath) return;
    if (f.type === 'OBJECT') createInvoiceModel(f.nestedFields);
    if (f.type === 'ARRAY' && f.name === 'lineItems') {
      _.set(
        newModel,
        f.referencePath,
        (f.value as any[]).filter((v) => v.selected)
      );
    }

    if (f.type !== 'ARRAY' && f.type !== 'OBJECT') {
      if (f.type === 'DATE_TIME') {
        _.set(newModel, f.referencePath, DateTime.fromJSDate(f.value as Date).toISO());
      } else if (f.type === 'BOOLEAN') {
        _.set(newModel, f.referencePath, !!f.value);
      } else if (f.type === 'DATE') {
        _.set(
          newModel,
          f.referencePath,
          DateTime.fromJSDate(f.value as Date).toFormat(_DATE_FORMAT)
        );
      } else {
        if (typeof f.value === 'string') {
          _.set(newModel, f.referencePath, f.value.trim());
        } else {
          _.set(newModel, f.referencePath, f.value);
        }
      }
      // (newModel as any)[f.referencePath] = f.value;
    }

    return f;
  });

  return newModel;
};
