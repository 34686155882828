import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const SelectLogisticsDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  align-self: center;
  gap: 48px;
`;

export const SelectLogisticsArtifactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 32px;
  align-self: center;
  gap: 48px;
`;

export const SelectLogisticsArtifactTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 30rem !important;
  margin-bottom: 8px;
`;

export const SelectLogisticsArtifactTitle = styled.h2`
  text-align: center;
  color: ${themeColors.text.light.body};
`;

export const SelectLogisticsArtifactListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const SelectLogisticsArtifactListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background-color: ${themeColors.bg.light.surface};
  justify-content: space-between;
  cursor: pointer;
`;

export const SelectLogisticsArtifactListItemIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectLogisticsArtifactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectLogisticsArtifactTextTitle = styled.div`
  ${typography.bodySmallSemiBold};
  color: ${themeColors.text.light.body};
`;

export const SelectLogisticsArtifactTextDescription = styled.div`
  ${typography.captionRegular};
  color: ${themeColors.text.light.muted};
`;

export const SelectLogisticsArtifactButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const SelectLogisticsArtifactButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
