import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const GroupedTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    padding: 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
  }
  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
`;
