export const convertNumberToShortStringFileSize: (
  value: number,
  decimalPlaces: number
) => string = (value: number, decimalPlaces: number) => {
  let newValue: string | number = value;
  const suffixes = ['b', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (value >= 1000) {
    const suffixNum = Math.floor(Math.floor(value).toString().length / 3);
    let shortValue: string | number = 0;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
      );
      let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(decimalPlaces);
    newValue = shortValue + suffixes[suffixNum];
    return newValue.toString();
  }
  return value + suffixes[0];
};
