import { Accordion } from '@mui/material';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { themeColors } from 'assets/theme/style';

interface AccordionPropsType {
  opacity: string;
}

export const TimelineAccordionHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TimelineAccordionTitle = styled.h5`
  margin: 0;
`;

export const TimelineAccordionStatus = styled.p`
  margin: 0;
  ${typography.captionRegular};
`;

export const TimelineAccordionText = styled.div<AccordionPropsType>`
  display: flex;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none;
    margin: 0 -16px;
    ::before {
      display: none;
    }
  }
  .MuiButtonBase-root {
    width: 100%;
    min-height: 32px;
    align-items: flex-start;
    ${typography.bodyRegular};
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.text.primary};
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionSummary-root {
    &.Mui-disabled {
      opacity: 1;
    }
  }
  &.Mui-disabled {
    background-color: transparent !important;
  }
`;
