import { FC, useEffect, useState } from 'react';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import InfoIcon from 'assets/icons/InfoIcon';
import InvoiceApi from 'api/ledger/invoices.api';
import { store } from 'store';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getCookie, setCookie } from 'lib/helpers/cookieHelper';
import { DateTime } from 'luxon';
import CustomLink from 'components/common/links/CustomLink';
import { FirstTimeUsersBannerContainer } from './styled';

const FirstTimeUsersBanner: FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const remindFirstTimeUserDate: any = getCookie('remindFirstTimeUserDate');

  const {
    loading: appLoading,
    allProgramsWithDetails
  }: {
    allProgramsWithDetails: ProgramConfig[];
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    if (allProgramsWithDetails) {
      getInvoiceLedger();
    }
  }, [allProgramsWithDetails]);

  const getInvoiceLedger: () => void = async () => {
    const programIds = allProgramsWithDetails.map((p) => p.id);
    const invoiceApi = new InvoiceApi(store);

    if (!remindFirstTimeUserDate) {
      const nextMonth = DateTime.local().plus({ months: 1 });
      setCookie('spRemindFirstTimeUserDate', nextMonth, 365);
    }

    const invoiceResponse = await invoiceApi.getFilterInvoiceLedger(
      programIds || '',
      null,
      null,
      '',
      ''
    );

    if (
      invoiceResponse &&
      invoiceResponse?.data['@odata.count'] &&
      invoiceResponse?.data['@odata.count'] > 0
    ) {
      setCookie('spRemindFirstTimeUsers', 'true', 365);
    } else {
      const today: DateTime = DateTime.local();
      remindFirstTimeUserDate && today > remindFirstTimeUserDate
        ? setCookie('spRemindFirstTimeUsers', 'true', 365)
        : setShowBanner(true);
    }
  };

  return !appLoading && showBanner ? (
    <Notification
      noShadow
      centeredButton
      hasClosed
      closeHandler={() => setShowBanner(false)}
      backgroundColor={themeColors.bg.infoMuted}
      color={themeColors.text.primary}
      icon={<InfoIcon />}
      descriptionJSX={
        <FirstTimeUsersBannerContainer>
          Not finding what you are looking for? Try our
          <CustomLink
            title="Help and support "
            link="https://supplier.support.kanexa.net"
            textDecoration="none"
            target
          />
          section.
        </FirstTimeUsersBannerContainer>
      }
    />
  ) : (
    <></>
  );
};

export default FirstTimeUsersBanner;
