import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const CheckIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.success,
  width = '24px',
  height = '24px'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    data-automation-id="icon-svg-check-icon"
  >
    <g transform="matrix(1.7142857142857142,0,0,1.7142857142857142,0,0)">
      <g>
        <path
          d="M4,8,6.05,9.64a.48.48,0,0,0,.4.1.5.5,0,0,0,.34-.24L10,4"
          style={{ fill: 'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round' }}
        ></path>
        <circle
          cx="7"
          cy="7"
          r="6.5"
          style={{ fill: 'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round' }}
        ></circle>
      </g>
    </g>
  </svg>
);

export default CheckIcon;
