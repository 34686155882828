import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface Type {
  type?: keyof typeof AssetEnum;
}

export const LedgerDetailSummaryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-bottom: 16px;
  gap: 48px;
`;

export const LedgerDetailColumn = styled.div<{ isTotals?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${({ isTotals }) => (isTotals ? `1px dashed ${themeColors.text.light.muted}` : '')};
`;

export const LedgerDetailsSummaryTextSemiBold = styled.p`
  ${typography.bodySemiBold};
  font-size: ${fontSizes.regular};
`;
export const LedgerDetailsSummaryTextRegular = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.regular};
  text-align: end;
`;

export const LedgerDetailsKeyValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const LedgerDetailsSegmentContainer = styled.div<{ isTotals?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 16px;
  border-radius: 12px;
  background-color: ${({ isTotals }) => (isTotals ? themeColors.bg.light.surface : '')};
  min-height: 235px;
  justify-content: ${({ isTotals }) => (isTotals ? 'space-between' : '')};
`;

export const LedgerDetailSummaryRow = styled.div<Type>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ type }) => (type === LedgerTypes.INVOICE ? '32px' : '')};
  width: 100%;
`;

export const LedgerDetailSummarySegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 50%;
  width: 100%;
  margin-bottom: 24px;
  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const LedgerDetailSummaryLabel = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body};
`;

export const LedgerDetailSummaryValue = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.body};
`;

export const LedgerDetailSummaryValueContainer = styled.div`
  display: flex;
  ${typography.bodyRegular};
  text-align: left;
`;

export const LedgerDetailSummaryInfo = styled.h3``;

export const LedgerDetailSummaryLabelContainer = styled.div`
  display: flex;
  gap: 10px;
`;
