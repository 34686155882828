import { layoutSizes } from 'assets/theme/style';
import { isMobile, isSmallerView } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface Size {
  size?: 'xlarge' | 'large' | 'medium' | 'small';
  margin?: string;
  padding?: string;
  paddingBottom?: string;
  minWidth? :string;
}

export const LayoutViewContainerStyled = styled.div<Size>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  gap: 24px;
  min-width: ${({ minWidth }) => isMobile() ? layoutSizes.maxMobileWidth : minWidth ? minWidth : layoutSizes.minDesktopWidth};
  width: ${({ size }) =>
    isMobile() || isSmallerView() ? '100%' : sizeSetterWidth(size || 'large')};
  padding: ${({ size, padding }) =>
    isMobile() ? '0' : padding ? padding : sizeSetterPadding(size || 'medium')};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || ''};
`;

const sizeSetterPadding: (size: 'xlarge' | 'large' | 'medium' | 'small') => string = (size) => {
  switch (size) {
    case 'xlarge':
      return '16px 100px 32px 100px';
    case 'large':
      return '16px 100px 32px 100px';
    default:
      return '16px 32px 32px 32px';
  }
};

const sizeSetterWidth: (size: 'xlarge' | 'large' | 'medium' | 'small') => string = (size) => {
  switch (size) {
    case 'xlarge':
      return '100%';
    case 'medium':
      return '65%';
    case 'small':
      return '50%';
    default:
      return '80%';
  }
};
