import { RequiredAuth } from 'components/routes/SecureRoute';
import { Navigate } from 'react-router';
import { IRoute } from 'utils/interfaces/route/route.interface';
import Onboarding from 'views/Onboarding';
import Home from "../views/Home";

const onboardingRoutes: IRoute[] = [
  { path: '/', name: 'Home', component: <RequiredAuth />, secure: <Onboarding /> },
  {
    path: 'login/callback',
    name: 'Okta Callback',
    component: <Navigate to="/" />
  },
  {
    path: '*',
    name: 'Redirect',
    component: <Navigate to="/" />
  }
];

export default onboardingRoutes;
