import AwardIcon from 'assets/icons/AwardIcon';
import PendingIcon from 'assets/icons/PendingIcon';
import EntityDetailsLink from 'components/EntityDetails/EntityDetailsLink';
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { useSelector, RootStateOrAny } from 'react-redux';
import { FC } from 'react';
import {
  FundingRequestStatus,
  FundingRequestStatusContainer,
  FundingRequestSummaryAmount,
  FundingRequestSummaryContainer,
  FundingRequestSummaryH5,
  FundingRequestSummaryRow,
  FundingRequestSummaryRowSegment,
  FundingRequestSummaryText,
  FundingRequestSummaryTitle,
  FundingRequestSummaryTitleWrapper,
  FundingRequestSummaryWrapper
} from './styled';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { themeColors } from 'assets/theme/style';
import CustomLink from 'components/common/links/CustomLink';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';

export interface FundingRequestSummaryProps {
  pageTitle: string;
  paymentId: string;
  funder: string;
  createdDateTime: string;
  expectedPaymentDate: string;
  status: string;
  buyer?: string;
  supplier?: string;
  paymentSentDate?: string;
  invoicesTotalCount?: number;
  isProgramFixedCost?: boolean;
  isSmallTitle?: boolean;
  paymentAmount?: number | undefined;
  currency?: string;
}

const FundingRequestSummary: FC<FundingRequestSummaryProps> = ({
  pageTitle,
  paymentAmount,
  paymentId,
  funder,
  createdDateTime,
  expectedPaymentDate,
  status,
  supplier,
  buyer,
  paymentSentDate,
  invoicesTotalCount,
  isProgramFixedCost = false,
  isSmallTitle = false,
  currency
}) => {
  const {
    entities
  }: {
    entities: EntityOwner[] | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const getEntityID: string | null | undefined =
    entities && entities.find((e) => e.contact.registeredName === funder)?.id;

  const getStatusIcon: (status: string | undefined) => JSX.Element = (status) => {
    if (
      status === 'TRADE_PAYMENT_SENT' ||
      status === 'SENT' ||
      status === 'TRADE_PAYMENT_RECEIVED'
    ) {
      return (
        <FundingRequestStatusContainer>
          <FundingRequestStatus
            data-automation-id="early-payment-request-summary-h4-status"
            color={themeColors.text.success}
          >
            Paid
          </FundingRequestStatus>
          <AwardIcon data-automation-id="early-payment-request-summary-svg-status" />
        </FundingRequestStatusContainer>
      );
    }

    if (status === 'FUNDING_REQUEST_EXPIRED' || status === 'FUNDING_REQUEST_REJECTED') {
      return (
        <FundingRequestStatusContainer>
          <IconTooltip
            tooltipText={
              status === 'FUNDING_REQUEST_EXPIRED'
                ? 'This early payment request has expired. Please message us for more information.'
                : ''
            }
            children={
              <>
                <FundingRequestStatus
                  data-automation-id="early-payment-request-summary-h4-status"
                  color={themeColors.text.error}
                >
                  {status === 'FUNDING_REQUEST_EXPIRED' ? 'Expired' : 'Rejected'}
                </FundingRequestStatus>
                <HighlightOffIcon
                  data-automation-id="early-payment-request-summary-svg-status"
                  style={{ color: themeColors.icon.error }}
                />
              </>
            }
          />
        </FundingRequestStatusContainer>
      );
    }

    return (
      <FundingRequestStatusContainer>
        <IconTooltip
          tooltipText="This early payment request has been submitted. "
          children={
            <>
              <FundingRequestStatus
                data-automation-id="early-payment-request-summary-h4-status"
                color={themeColors.royalGold}
              >
                Pending
              </FundingRequestStatus>
              <PendingIcon data-automation-id="early-payment-request-summary-svg-status" />
            </>
          }
        />
      </FundingRequestStatusContainer>
    );
  };

  const renderFundingRequestSummary: () => JSX.Element = () => (
    <FundingRequestSummaryContainer>
      <FundingRequestSummaryTitleWrapper>
        <FundingRequestSummaryTitle
          data-automation-id="early-payment-request-summary-h3-page-title"
          showSmallTitle={isSmallTitle}
        >
          {pageTitle}
        </FundingRequestSummaryTitle>
        <FundingRequestSummaryAmount>
          {currency + ' ' + formatNumber(paymentAmount || 0, 2)}
        </FundingRequestSummaryAmount>

        {/* {getStatusIcon(status)} */}
      </FundingRequestSummaryTitleWrapper>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-request-id">
            Payment Reference
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-request-id">
            {paymentId || '-'}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-entity">
            Payment issuer
          </FundingRequestSummaryH5>
          {getEntityID && !buyer && (
            <EntityDetailsLink
              entityID={getEntityID}
              title={funder || ''}
              type="funder"
            ></EntityDetailsLink>
          )}
          {buyer && (
            <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-buyer">
              {buyer}
            </FundingRequestSummaryText>
          )}
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-payment">
            {paymentSentDate ? 'Payment instruction sent' : 'Date requested'}
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-payment">
            {paymentSentDate || createdDateTime}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-expected-payment-date">
            Payment expected
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-expected-payment-date">
            {expectedPaymentDate}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
    </FundingRequestSummaryContainer>
  );

  const renderFundingRequestFixedCostSummary: () => JSX.Element = () => (
    <FundingRequestSummaryContainer>
      <FundingRequestSummaryTitleWrapper>
        <FundingRequestSummaryTitle data-automation-id="early-payment-request-summary-h3-page-title">
          {pageTitle}
        </FundingRequestSummaryTitle>
        {/* {getStatusIcon(status)} */}
      </FundingRequestSummaryTitleWrapper>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-funder">
            Funder
          </FundingRequestSummaryH5>
          {getEntityID && !buyer && (
            <EntityDetailsLink
              entityID={getEntityID}
              title={funder || ''}
              type="funder"
            ></EntityDetailsLink>
          )}
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-payment">
            {paymentSentDate ? 'Payment instruction sent' : 'Date requested'}
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-payment">
            {paymentSentDate || createdDateTime}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-expected-payment-date">
            Payment expected
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-expected-payment-date">
            {expectedPaymentDate}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-paid-to">
            Paid To
          </FundingRequestSummaryH5>
          <CustomLink title={supplier} link="/company-details" />
        </FundingRequestSummaryRowSegment>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-expected-paid-by">
            Paid By
          </FundingRequestSummaryH5>
          {getEntityID && !buyer && (
            <EntityDetailsLink
              entityID={getEntityID}
              title={funder || ''}
              type="funder"
            ></EntityDetailsLink>
          )}
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
      <FundingRequestSummaryRow>
        <FundingRequestSummaryRowSegment>
          <FundingRequestSummaryH5 data-automation-id="early-payment-request-summary-h5-title-paid-to">
            Number of invoices
          </FundingRequestSummaryH5>
          <FundingRequestSummaryText data-automation-id="early-payment-request-summary-p-value-paid-to">
            {invoicesTotalCount}
          </FundingRequestSummaryText>
        </FundingRequestSummaryRowSegment>
      </FundingRequestSummaryRow>
    </FundingRequestSummaryContainer>
  );

  return (
    <FundingRequestSummaryWrapper>
      {isProgramFixedCost ? renderFundingRequestFixedCostSummary() : renderFundingRequestSummary()}
      <FundingRequestSummaryContainer>
        {/* <FundingRequestSummaryInvoices>{numberOfInvoices} Invoices</FundingRequestSummaryInvoices>
        <FundingRequestSummaryStatus>{status}</FundingRequestSummaryStatus> */}
      </FundingRequestSummaryContainer>
    </FundingRequestSummaryWrapper>
  );
};

export default FundingRequestSummary;
