import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface FileUpload {
  borderColor?: string;
}

export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const UploaderFilesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UploaderFilesListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UploaderFilesListItemLeftSegment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const UploaderFilesIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploaderFilesFilename = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.primary};
`;

export const FileUploadContainer = styled.div<FileUpload>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${themeColors.iconBlue}20;
  border-radius: 12px;
  border: 1px dashed ${({ borderColor }) => borderColor || themeColors.border.primary};
  padding: 16px 12px;
  gap: 12px;
`;

export const FileUploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploaderFilesDeleteIconContainer = styled(FileUploadIconContainer)`
  cursor: pointer;
`;

export const FileUploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadText = styled.h5``;

export const FileUploadButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const FileUploadSelectedSimpleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileUploadSelectedSimpleTitle = styled.h3`
  margin-bottom: 16px;
  color: ${themeColors.text.light.body};
`;

export const FileUploadSelectedSimpleParagragh = styled.p`
  font-size: ${fontSizes.regular};
`;
export const FileUploadSelectedSimpleParagraghError = styled.p`
  font-size: ${fontSizes.small};
  color: ${themeColors.text.error};
  padding-bottom: 8px;
`;

export const FileUploadSelectedSimpleParagraghInfo = styled.p`
  font-size: ${fontSizes.regular};
  color: ${themeColors.text.light.subtle};
`;

export const FileUploadSelectedSimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;
  color: ${themeColors.text.light.body};
  border-bottom: 1px solid ${themeColors.bg.light.surfaceRaised};
`;

export const FileUploadSelectedSimpleSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const SimpleFileUploadContainer = styled.div<FileUpload>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px dashed ${themeColors.border.light.container};
  padding: 40px 50px;
  gap: 8px;
  /* box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1); */
  height: 244px;
  width: 100%;
  max-width: 500px;
`;

export const SimpleFileUploadText = styled.p`
  ${typography.bodyRegular};
  color: ${themeColors.text.light.body};
`;

export const SimpleFileUploadLink = styled.span`
  color: ${themeColors.text.primary};
  ${typography.bodySemiBold};
`;

export const SimpleFileUploadDescription = styled.p`
  color: ${themeColors.text.light.subtle};
  ${typography.captionRegular};
`;
