import { ChangeEvent, MouseEvent, FocusEvent, FC } from 'react';
import InputMask from 'react-input-mask';
import { TextInputContainer, TextInputLabel, TextInputMessage, TextInputStyled } from './styled';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
export interface TextInputProps {
  defaultValue?: string | number;
  props?: {};
  message?: string;
  errorMessage?: string;
  blurHandler?: (event: FocusEvent<HTMLInputElement>) => void;
  changeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  register?: any;
  label?: string;
  type?: 'number';
  placeholder?: string;
  readonly?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  mask?: string;
  tooltipText?: string;
  testingTagPage?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  endIcon?: any;
  error?: boolean;
  required?: boolean;
  name?: string;
  focused?: boolean;
  errorBorderOnly?: boolean;
}

const TextInput: FC<TextInputProps> = ({
  defaultValue,
  tooltipText,
  props,
  message,
  errorMessage = '',
  register,
  label,
  type,
  placeholder,
  readonly,
  disabled,
  mask,
  changeHandler,
  blurHandler,
  testingTagPage,
  onClick,
  endIcon,
  multiline,
  error = false,
  name = '',
  focused,
  errorBorderOnly
}) => (
  <TextInputContainer
    data-automation-id={`${testingTagPage ? testingTagPage : 'text-input'}-div-${toKebabCase(
      label || 'input-container'
    )}`}
  >
    {label && (
      <TextInputLabel
        data-testid="sp-input-label"
        data-automation-id={`${
          testingTagPage ? testingTagPage : 'text-input'
        }-div-input-label-${toKebabCase(label || '')}`}
      >
        {label}
        {tooltipText && <IconTooltip tooltipText={tooltipText} />}
      </TextInputLabel>
    )}

    {mask ? (
      <InputMask
        mask={mask}
        value={defaultValue}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        disabled={disabled}
        type={type}
      >
        {() => (
          <TextInputStyled
            inputProps={register}
            type={type}
            disabled={disabled ? disabled : false}
            data-testid="sp-input-mask"
            placeholder={placeholder}
            error={errorBorderOnly || errorMessage !== '' || error}
            name={name || ''}
          />
        )}
      </InputMask>
    ) : (
      <TextInputStyled
        placeholder={placeholder || ''}
        focused={focused}
        value={defaultValue}
        type={type}
        disabled={disabled}
        onClick={onClick}
        onChange={changeHandler}
        onBlur={blurHandler}
        inputProps={register}
        data-automation-id={`${testingTagPage ? testingTagPage : 'text-input'}-div-input-field`}
        data-testid="sp-input-field"
        InputProps={endIcon}
        multiline={multiline}
        error={errorMessage !== '' || error}
        name={name || ''}
      />
    )}
    {(message || errorMessage) && (
      <TextInputMessage
        errorMessage={errorMessage || ''}
        data-automation-id={`${testingTagPage ? testingTagPage : 'text-input'}-span-message`}
      >
        {message || errorMessage}
      </TextInputMessage>
    )}
  </TextInputContainer>
);

export default TextInput;
