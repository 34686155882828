import { createInvoice, createInvoiceWithExternalCustomerReference } from 'hipster-sdk';
export default class GatewayToFinanceApi {
  public postGatewayToFinance: (
    data: any,
    buyerName: string,
    setup: any,
    stage: number,
    externalCustomerRef?: string
  ) => Promise<any> = async (data, buyerName, setup, stage, externalCustomerRef) =>
    (await stage) === 1
      ? createInvoice(data, buyerName, setup)
      : createInvoiceWithExternalCustomerReference(data, externalCustomerRef, setup, buyerName);
  // return await axiosInstance.post<any>(
  //   `${window.API_PATH_INTERNAL}/G2F/CreateInvoice/BF00724C-DA35-4956-9EF7-C37D4447682F`,
  //   data
  // );
}
