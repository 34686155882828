import { fontSizes } from 'assets/theme/typography';
import { FC } from 'react';
import IconTooltip from '../tooltip/IconTooltip';
import { ChipsContainer, ChipStyled } from './styled';

interface ChipsProps {
  icon?: JSX.Element;
  headerIcon?: JSX.Element;
  type: string;
  background?: string;
  color: string;
  radius?: string;
  textTransform?: string;
  fontSize?: string;
  height?: string;
  semibold?: string;
  testingTag?: string;
  tooltipText?: string;
}

const Chip: FC<ChipsProps> = ({
  icon,
  headerIcon,
  type,
  color,
  radius = '12px',
  textTransform = 'uppercase',
  fontSize = fontSizes.small,
  height = '26px',
  semibold = 'bold',
  testingTag,
  tooltipText,
  background
}) => {
  return (
    <ChipsContainer
      data-automation-id={`${testingTag ? testingTag : 'chip'}-div-chip-container`}
      data-testid="sp-chip-container"
    >
      {tooltipText ? (
        <IconTooltip
          tooltipText={tooltipText}
          children={
            <>
              <ChipStyled
                icon={icon}
                label={type}
                textColor={color}
                background={background}
                radius={radius}
                transform={textTransform}
                fontSize={fontSize}
                height={height}
                semibold={semibold}
              />
              {headerIcon}
            </>
          }
        />
      ) : (
        <>
          <ChipStyled
            icon={icon}
            label={type}
            textColor={color}
            background={background}
            radius={radius}
            transform={textTransform}
            fontSize={fontSize}
            height={height}
            semibold={semibold}
          />
          {headerIcon}
        </>
      )}
    </ChipsContainer>
  );
};

export default Chip;
