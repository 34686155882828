export default class OutgoingPaymentDataGridItem {
  constructor(
    public invoiceNumber: string,
    public buyer: string,
    public paymentDueDate: string,
    public invoiceAmount: number,
    public id: string,
    public currencySymbol: string,
    public selected: boolean
  ) {}
}
