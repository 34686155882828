import ClearIcon from 'assets/icons/ClearIcon';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { FC } from 'react';
import {
  NotificationButtonContainer,
  NotificationButtonText,
  NotificationButtonsContainer,
  NotificationCloseContainer,
  NotificationContainer,
  NotificationDescriptionContainer,
  NotificationDescriptionJSXText,
  NotificationDescriptionLargeText,
  NotificationDescriptionText,
  NotificationDescriptionTitle,
  NotificationDescriptionTitleLarge,
  NotificationIconContainer,
  NotificationLeftSegment,
  NotificationWrapper
} from './styled';
import CloseOutlineIcon from 'assets/icons/CloseOutlineIcon';
import { light } from '@mui/material/styles/createPalette';

export interface NotificationProps {
  color?: string;
  backgroundColor?: string;
  icon?: JSX.Element;
  title?: string;
  description?: string;
  descriptionJSX?: JSX.Element;
  buttonIcon?: JSX.Element;
  buttonText?: string;
  clickHandler?: () => void;
  closeHandler?: () => void;
  hasButton?: boolean;
  hasSecondaryButton?: boolean;
  hasClosed?: boolean;
  centeredButton?: boolean;
  padding?: string;
  border?: boolean;
  borderColor?: string;
  fullContent?: boolean;
  multiButtons?: JSX.Element;
  titleLarge?: boolean;
  descriptionLarge?: boolean;
  buttonHeight?: string;
  noShadow?: boolean;
}

const Notification: FC<NotificationProps> = ({
  color,
  backgroundColor,
  icon,
  title,
  description,
  descriptionJSX,
  buttonIcon,
  buttonText,
  clickHandler,
  closeHandler,
  hasButton,
  hasSecondaryButton,
  hasClosed,
  centeredButton,
  padding,
  border,
  fullContent,
  multiButtons,
  titleLarge,
  descriptionLarge,
  buttonHeight,
  noShadow,
  borderColor
}) => (
  <NotificationWrapper
    data-automation-id="notification-div-wrapper"
    data-testid="sp-notification-wrapper"
    color={color}
    backgroundColor={backgroundColor}
    padding={padding}
    border={border}
    borderColor={borderColor}
    noShadow={noShadow}
  >
    <NotificationContainer
      padding={padding}
      color={color}
      centeredButton={centeredButton}
      hasButton={hasButton || hasSecondaryButton}
      data-automation-id="notification-div-container"
      data-testid="sp-notification-container"
    >
      <NotificationLeftSegment
        fullContent={fullContent}
        description={description}
        descriptionJSX={descriptionJSX}
      >
        <NotificationIconContainer
          data-automation-id="notification-div-icon"
          data-testid="sp-notification-icon"
        >
          {icon}
        </NotificationIconContainer>
        <NotificationDescriptionContainer fullContent={fullContent}>
          {title && titleLarge && (
            <NotificationDescriptionTitleLarge
              data-automation-id="notification-title"
              data-testid="notification-title"
              className="embolden"
            >
              {title}
            </NotificationDescriptionTitleLarge>
          )}
          {title && !titleLarge && (
            <NotificationDescriptionTitle
              data-automation-id="notification-title"
              data-testid="notification-title"
              className="embolden"
            >
              {title}
            </NotificationDescriptionTitle>
          )}
          {description && !descriptionLarge && (
            <NotificationDescriptionText data-automation-id="notification-p-description">
              {description}
            </NotificationDescriptionText>
          )}
          {description && descriptionLarge && (
            <NotificationDescriptionLargeText data-automation-id="notification-p-description">
              {description}
            </NotificationDescriptionLargeText>
          )}

          {descriptionJSX && (
            <NotificationDescriptionJSXText data-automation-id="notification-p-description">
              {descriptionJSX}
            </NotificationDescriptionJSXText>
          )}
        </NotificationDescriptionContainer>
      </NotificationLeftSegment>
      {hasButton && !multiButtons && (
        <NotificationButtonContainer
          centeredButton={centeredButton}
          data-testid="sp-notification-button-container"
        >
          <PrimaryButton
            padding="6px 16px"
            borderRadius="6px"
            gap="8px"
            minWidth="92px"
            backgroundColor={themeColors.bg.light.surfaceInvert}
            height={buttonHeight}
            clickHandler={clickHandler}
            testingTag={`notification-button-${toKebabCase(buttonText || '')}`}
          >
            {buttonIcon && buttonIcon}
            <NotificationButtonText data-automation-id="notification-p-button-text">
              {buttonText}
            </NotificationButtonText>
          </PrimaryButton>
        </NotificationButtonContainer>
      )}
      {hasSecondaryButton && !multiButtons && (
        <NotificationButtonContainer
          centeredButton={centeredButton}
          data-testid="sp-notification-button-container"
        >
          <PrimaryButton
            padding="6px 16px"
            borderRadius="6px"
            gap="8px"
            minWidth="92px"
            backgroundColor={themeColors.bg.light.surfaceInvert}
            clickHandler={clickHandler}
            testingTag={`notification-button-${toKebabCase(buttonText || '')}`}
          >
            {buttonIcon && buttonIcon}
            <NotificationButtonText data-automation-id="notification-p-button-text">
              {buttonText}
            </NotificationButtonText>
          </PrimaryButton>
        </NotificationButtonContainer>
      )}
      {!hasButton && hasClosed && (
        <NotificationCloseContainer
          onClick={closeHandler}
          data-automation-id="notification-div-icon-close"
          data-testid="sp-notification-button-close"
        >
          <CloseOutlineIcon color={themeColors.icon.light.default} />
        </NotificationCloseContainer>
      )}
    </NotificationContainer>
    {multiButtons && <NotificationButtonsContainer>{multiButtons}</NotificationButtonsContainer>}
  </NotificationWrapper>
);

export default Notification;
