import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import MatchingChip from 'components/Chips/MatchingChip';
import Chip from 'components/common/Chip';
import { LedgerTypes } from 'lib/enums/ledger/ledger.enum';
import { dateFormatForIso } from 'lib/helpers/formatters/datetimeFormatters';
import { getInvoiceStatusTooltipText } from 'lib/tooltips/tooltips';
import { DateTime } from 'luxon';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';

//OA
export const invoiceLedgerOAHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Invoice Id', 'invoiceId', {
    display: false
  }),
  new DataGridHeaderItem(
    'Invoice Number',
    'invoiceNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Invoice Status', 'invoiceStatus', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <Chip
          type={value}
          color={themeColors.text.primary}
          radius="6px"
          fontSize={fontSizes.small}
          height="18px"
          semibold="bold"
          testingTag="invoice-ledger"
          tooltipText={getInvoiceStatusTooltipText(value)}
        />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Matching Status', 'matchingStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip type={value} testingTag="invoice-ledger" ledgerType={LedgerTypes.INVOICE} />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Buyer', 'buyerName', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'invoiceDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'dueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Maturity Date', 'maturityDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Invoiced Amount', 'invoiceAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem(
    'Payment Reference',
    'paymentId',
    new DataGridHeaderOptions(false, true, true)
  )
];

export const invoiceLedgerOAExternalFundingHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Invoice Id', 'invoiceId', {
    display: false
  }),
  new DataGridHeaderItem(
    'Invoice Number',
    'invoiceNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Invoice Status', 'invoiceStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <Chip
          type={value}
          color={themeColors.text.primary}
          radius="6px"
          fontSize={fontSizes.small}
          height="18px"
          semibold="bold"
          testingTag="invoice-ledger"
          tooltipText={getInvoiceStatusTooltipText(value)}
        />
      ) : (
        '-'
      )
  }),
  //OA FIELD
  new DataGridHeaderItem('Matching Status', 'matchingStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip type={value} testingTag="invoice-ledger" ledgerType={LedgerTypes.INVOICE} />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Buyer', 'buyerName', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'invoiceDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'dueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Invoiced Amount', 'invoiceAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Net Amount', 'netAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  })
];

//Funding
export const invoiceLedgerFundedHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Invoice Id', 'invoiceId', {
    display: false
  }),
  new DataGridHeaderItem('Invoice Number', 'invoiceNumber', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (value ? `${value}` : '-')
  }),
  new DataGridHeaderItem('Invoice  Status', 'invoiceStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip
          type={value}
          testingTag="invoice-ledger"
          ledgerType={LedgerTypes.INVOICE}
          isPlain
        />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Buyer', 'buyerName', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'invoiceDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'dueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Maturity Date', 'maturityDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Net Invoice Amount', 'netAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem(
    'Payment Reference',
    'paymentId',
    new DataGridHeaderOptions(false, true, true)
  )
];

//OA-FUNDING
export const invoiceLedgerOAFundingHeaders: DataGridHeaderItem[] = [
  new DataGridHeaderItem('Invoice Id', 'invoiceId', {
    display: false
  }),
  new DataGridHeaderItem('Invoice Number', 'invoiceNumber', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? <p>{value}</p> : '-'
  }),
  new DataGridHeaderItem('Invoice  Status', 'invoiceStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip
          type={value}
          testingTag="invoice-ledger"
          ledgerType={LedgerTypes.INVOICE}
          isPlain
        />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Matching Status', 'matchingStatus', {
    ...new DataGridHeaderOptions(false, false, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
      value ? (
        <MatchingChip type={value} testingTag="invoice-ledger" ledgerType={LedgerTypes.INVOICE} />
      ) : (
        '-'
      )
  }),
  new DataGridHeaderItem('Buyer', 'buyerName', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Created Date', 'invoiceDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'dueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Maturity Date', 'maturityDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Net Invoice Amount', 'netAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem(
    'Payment Reference',
    'paymentId',
    new DataGridHeaderOptions(false, true, true)
  )
];
