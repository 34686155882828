export const FETCH_PROGRAM_OWNER_DETAILS =
  '@@frontend/store/reducers/program/FETCH_PROGRAM_OWNER_DETAILS';
export const FETCH_PROGRAM_OWNER_DETAILS_SUCCESS =
  '@@frontend/store/reducers/program/FETCH_PROGRAM_OWNER_DETAILS_SUCCESS';
export const FETCH_PROGRAM_OWNER_DETAILS_FAILURE =
  '@@frontend/store/reducers/program/FETCH_PROGRAM_OWNER_DETAILS_FAILURE';
export const UPDATE_PROGRAM_OWNER_DETAILS =
  '@@frontend/store/reducers/program/UPDATE_PROGRAM_OWNER_DETAILS';
export const UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS =
  '@@frontend/store/reducers/program/UPDATE_PROGRAM_OWNER_DETAILS_SUCCESS';
export const UPDATE_PROGRAM_OWNER_DETAILS_FAILURE =
  '@@frontend/store/reducers/program/UPDATE_PROGRAM_OWNER_DETAILS_FAILURE';
export const ADD_BANK_ACCOUNT = '@@frontend/store/reducers/program/ADD_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_SUCCESS =
  '@@frontend/store/reducers/program/ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILURE =
  '@@frontend/store/reducers/program/ADD_BANK_ACCOUNT_FAILURE';
export const UPDATE_BANK_ACCOUNT = '@@frontend/store/reducers/program/UPDATE_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT_SUCCESS =
  '@@frontend/store/reducers/program/UPDATE_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAILURE =
  '@@frontend/store/reducers/program/UPDATE_BANK_ACCOUNT_FAILURE';
export const UPDATE_COMPANY_LOGO = '@@frontend/store/reducers/program/UPDATE_COMPANY_LOGO';
export const UPDATE_COMPANY_LOGO_SUCCESS =
  '@@frontend/store/reducers/program/UPDATE_COMPANY_LOGO_SUCCESS';
export const UPDATE_COMPANY_LOGO_FAILURE =
  '@@frontend/store/reducers/program/UPDATE_COMPANY_LOGO_FAILURE';
export const FETCH_PROOF_OF_ACCOUNT = '@@frontend/store/reducers/program/FETCH_PROOF_OF_ACCOUNT';
export const FETCH_PROOF_OF_ACCOUNT_SUCCESS =
  '@@frontend/store/reducers/program/FETCH_PROOF_OF_ACCOUNT_SUCCESS';
export const FETCH_PROOF_OF_ACCOUNT_FAILURE =
  '@@frontend/store/reducers/program/FETCH_PROOF_OF_ACCOUNT_FAILURE';
export const UPDATE_BANK_ACCOUNT_FOR_EDIT =
  '@@frontend/store/reducers/program/UPDATE_BANK_ACCOUNT_FOR_EDIT';
export const RESET_PROOF_OF_ACCOUNT = '@@frontend/store/reducers/program/RESET_PROOF_OF_ACCOUNT';
