/* eslint-disable react-hooks/exhaustive-deps */
import { Insurance } from 'api/interfaces/insurance/insurance.interface';
import {
  LedgerDetailSummaryContainer,
  LedgerDetailSummaryLabel,
  LedgerDetailSummaryRow,
  LedgerDetailSummarySegment,
  LedgerDetailSummaryValue
} from 'components/ledger/LedgerDetail/LedgerDetailSummary/styled';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { toKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { FC } from 'react';

interface LedgerDetailLineItemsProps {
  insurance: Insurance;
  type: keyof typeof AssetEnum;
}

const insuranceDictionary = {
  insuranceRequired: 'Insurance Required',
  insuranceProviderReference: 'Insurance Provider Reference',
  insuranceProviderName: 'Insurance Provider Name',
  insuranceProviderX500: 'Insurance Provider X500',
  insurancePolicyNumber: 'Insurance Policy Number',
  insurancePolicyName: 'Insurance Policy Name',
  insuredAmount: 'Insured Amount',
  insuredPercentage: 'Insured Percentage',
  insuredCurrency: 'Insured Currency',
  insuranceFeeAmount: 'Insured Fee Amount',
  insuranceStartDate: 'Insurance Start Date',
  insuranceExpiryDate: 'Insurance Expiry Date',
  insurancePolicyHolder: 'Insurance Policy Holder',
  insuranceLossPayee: 'Insurance Loss Payee',
  instituteCargoClause: 'Institute Cargo Clause',
  insuranceJurisdiction: 'Insurance Jurisdication'
};

const LedgerDetailInsurance: FC<LedgerDetailLineItemsProps> = ({ insurance, type }) => {
  const renderInsuranceDetails: () => JSX.Element[] = () =>
    Object.keys(insurance)
      .filter((ins) => ins !== 'clauses' && ins !== 'references')
      .map((key) => {
        return (
          <LedgerDetailSummarySegment key={key}>
            <LedgerDetailSummaryLabel
              data-automation-id={`ledger-detail-insurance-h5-label-${toKebabCase(
                (insuranceDictionary as any)[key]
              )}`}
            >
              {(insuranceDictionary as any)[key]}
            </LedgerDetailSummaryLabel>
            {requiresFormatting(key) && (
              <LedgerDetailSummaryValue>
                {key === 'insuranceRequired' && formatBoolean(insurance[key])}
                {(key === 'insuranceStartDate' || key === 'insuranceExpiryDate') &&
                  formatDateTime(insurance[key] || '', _DATE_FORMAT)}
                {(key === 'insuredAmount' || key === 'insuranceFeeAmount') &&
                  formatNumber(insurance[key] || 0, 2)}
              </LedgerDetailSummaryValue>
            )}
            {!requiresFormatting(key) && (
              <LedgerDetailSummaryValue>{(insurance as any)[key]}</LedgerDetailSummaryValue>
            )}
          </LedgerDetailSummarySegment>
        );
      });

  const requiresFormatting: (key: string) => boolean = (key) =>
    key === 'insuranceRequired' ||
    key === 'insuranceStartDate' ||
    key === 'insuranceExpiryDate' ||
    key === 'insuredAmount' ||
    key === 'insuranceFeeAmount';

  const formatBoolean: (val: boolean) => string = (val) => (val ? 'Yes' : 'No');

  return (
    <LedgerDetailSummaryContainer>
      <LedgerDetailSummaryRow type={type}>{renderInsuranceDetails()}</LedgerDetailSummaryRow>
    </LedgerDetailSummaryContainer>
  );
};

export default LedgerDetailInsurance;
