import { FC } from 'react';

export interface IconSvgProps {
  color?: string;
}

const CommentIcon: FC<IconSvgProps> = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#3D3AFF" fillOpacity="0.05" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0103 28.0001L20.0102 28C20.3336 27.9999 20.6224 27.8441 20.8054 27.604L26.7103 21.6991C27.1003 21.2991 27.1003 20.6691 26.7103 20.2791C26.3103 19.8801 25.6803 19.8801 25.2903 20.2801L21.0098 24.5606V13C21.0098 12.44 20.5598 12 20.0098 12C19.4498 12 19.0098 12.44 19.0098 13V24.5786L14.72 20.2889C14.32 19.8899 13.69 19.8899 13.3 20.2899C12.9 20.6799 12.9 21.3099 13.3 21.7099L19.2843 27.6942C19.2888 27.6988 19.2933 27.7033 19.2979 27.7078L19.3 27.7099C19.3217 27.7316 19.3446 27.7523 19.3685 27.7719C19.4318 27.8238 19.5024 27.8681 19.578 27.9035C19.6911 27.9572 19.8163 27.9905 19.9492 27.9982C19.9695 27.9995 19.9899 28.0001 20.0103 28.0001Z"
      fill="#3D3AFF"
    />
  </svg>
);

export default CommentIcon;
