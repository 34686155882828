import { RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import ConnectionIcon from 'assets/icons/ConnectionIcon';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import Notification from 'components/Notification';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import ConnectAccountingSoftware from 'views/Home/ConnectAccountingSoftware';

import {
  GatewayToFinanceBarButtonContainer,
  GatewayToFinanceBarButtonContentContainer,
  GatewayToFinanceBarContainer,
  GatewayToFinanceBarButtonText,
  GatewayToFinanceBarIconContainer,
  GatewayToFinanceBarContentContainer,
  GatewayToFinanceBarDescription,
  GatewayToFinanceBarButtonWrapper
} from './styled';

export interface IGatewayToFinanceBarProps {
  closeHandler: () => void;
}

const GatewayToFinanceBar: FC<IGatewayToFinanceBarProps> = ({ closeHandler }) => {
  const navigate = useNavigate();
  const [accountingDialogOpen, setAccountingDialogOpen] = useState<boolean>(false);

  const toggleAccountingDialog: () => void = () => {
    setAccountingDialogOpen(!accountingDialogOpen);
  };

  const navigateToConnections: () => void = () => {
    navigate('/connections');
  };

  return (
    <GatewayToFinanceBarContainer>
      <Notification
        fullContent
        padding="18px"
        hasClosed
        closeHandler={closeHandler}
        icon={<ConnectionIcon />}
        backgroundColor={themeColors.bg.successMuted}
        color={themeColors.icon.success}
        title="Connect your accounting software"
        descriptionJSX={
          <GatewayToFinanceBarContentContainer>
            <GatewayToFinanceBarDescription>
              Did you know you can connect to your accounting software to push your invoices into
              the Marco Polo Network for early payment.
            </GatewayToFinanceBarDescription>
            <GatewayToFinanceBarButtonWrapper>
              <GatewayToFinanceBarButtonContainer data-testid="sp-gateway-to-finance-bar-button">
                <PrimaryButton
                  backgroundColor={themeColors.bg.light.surfaceInvert}
                  clickHandler={navigateToConnections}
                  padding="16px"
                  height="54px"
                  testingTag="gateway-to-finance-bar-button"
                >
                  <GatewayToFinanceBarButtonContentContainer>
                    <GatewayToFinanceBarButtonText
                      data-automation-id="gateway-to-finance-bar-button-text"
                      data-testid="sp-gateway-to-finance-bar-button-text"
                    >
                      Connect accounting software
                    </GatewayToFinanceBarButtonText>
                    <GatewayToFinanceBarIconContainer
                      data-automation-id="gateway-to-finance-bar-div-button-icon-container"
                      data-testid="sp-gateway-to-finance-bar-button-icon"
                    >
                      <RightLongArrowIcon color={themeColors.icon.primary} height="10" width="12" />
                    </GatewayToFinanceBarIconContainer>
                  </GatewayToFinanceBarButtonContentContainer>
                </PrimaryButton>
              </GatewayToFinanceBarButtonContainer>
            </GatewayToFinanceBarButtonWrapper>
          </GatewayToFinanceBarContentContainer>
        }
      />
      {accountingDialogOpen && (
        <FullViewDialog
          open={accountingDialogOpen}
          clickHandler={toggleAccountingDialog}
          dialogContent={<ConnectAccountingSoftware closeHandler={closeHandler} />}
          data-testid="sp-gateway-to-finance-bar-dialog"
        />
      )}
    </GatewayToFinanceBarContainer>
  );
};

export default GatewayToFinanceBar;
