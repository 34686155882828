import { PaginationBase } from '../base/paginationBase.interface';
import { LedgerInvoiceData } from '../ledger/ledgerInvoice.interface';

export interface LinkedProgram {
  programId: string;
  programName: string;
}

export interface OALinkedProgram extends LinkedProgram {
  baseType: string;
  programParticipants: ProgramParticipant[];
  purchaseOrderStatus?: string;
  logisticStatus?: string;
  matchingStatus?: string;
  isDiscrepant?: boolean;
}

export interface Programs extends PaginationBase {
  content: Program[];
}

export interface ProgramCreator {
  x500: string;
  registeredName: string;
  role: string;
}

export interface Program {
  id: string;
  type: string;
  name: string;
  customName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  creator: ProgramCreator;
  alternativeRevisions: any[];
}

export interface LinkedProgram {
  programId: string;
}

export interface ProgramConfig {
  alternativeRevisions: any[];
  baseType: string;
  approvals: any[];
  createdAt: string;
  creatorRole: string;
  customName: string;
  description: string;
  id: string;
  name: string;
  participants: ProgramConfigParticipant[];
  pendingUpdateAsyncTaskId: string | null;
  rules: IProgramRule<any>[];
  status: string;
  updatedAt: string;
  currency?: string;
}

export interface ProgramConfigParticipant {
  registeredName: string;
  role: string;
  x500: string;
}

export interface ProgramParticipant {
  networkName: string | null;
  registeredName: string | null;
  type: string | null;
}

export enum ProgramRuleTypeEnum {
  CURRENCIES = 'CURRENCIES',
  FUNDER_BANK_ACCOUNTS = 'FUNDER_BANK_ACCOUNTS',
  SUPPLIER_BANK_ACCOUNTS = 'SUPPLIER_BANK_ACCOUNTS',
  FUNDING_REQUEST_ACTIVE_TIME = 'FUNDING_REQUEST_ACTIVE_TIME',
  SIMPLE_MATURITY_PAYMENT_PROCESSING_DAYS = 'SIMPLE_MATURITY_PAYMENT_PROCESSING_DAYS',
  FUNDING_RESPONSE_AUTO_ACCEPT = 'FUNDING_RESPONSE_AUTO_ACCEPT',
  INVOICE_TEMPLATE_CONFIGURATION = 'INVOICE_TEMPLATE_CONFIGURATION',
  BASE_RATES = 'BASE_RATES',
  INTEREST_DAYS = 'INTEREST_DAYS',
  LOGISTIC_TEMPLATE_CONFIGURATION_SUB = 'LOGISTIC_TEMPLATE_CONFIGURATION_SUB',
  OA_INVOICE_TEMPLATE_CONFIGURATION = 'OA_INVOICE_TEMPLATE_CONFIGURATION',
  MANAGING_BANK = 'MANAGING_BANK',
  OA_ASSET_FLIP = 'OA_ASSET_FLIP'
}

export interface FundingRequestActiveTimeProgramRule
  extends IProgramRule<FundingRequestActiveTime> {
  type: ProgramRuleTypeEnum.FUNDING_REQUEST_ACTIVE_TIME;
}

export interface SimpleMaturityPaymentProcessingDaysProgramRule
  extends IProgramRule<SimpleMaturityPaymentProcessingDays> {
  type: ProgramRuleTypeEnum.SIMPLE_MATURITY_PAYMENT_PROCESSING_DAYS;
}

export interface FundingResponseAutoAcceptProgramRule
  extends IProgramRule<FundingResponseAutoAccept> {
  type: ProgramRuleTypeEnum.FUNDING_RESPONSE_AUTO_ACCEPT;
}
export interface BankAccountProgramRule extends IProgramRule<BankAccounts> {
  type: ProgramRuleTypeEnum.FUNDER_BANK_ACCOUNTS | ProgramRuleTypeEnum.SUPPLIER_BANK_ACCOUNTS;
}
export interface CurrencyProgramRule extends IProgramRule<Currency> {
  type: ProgramRuleTypeEnum.CURRENCIES;
}

export interface InvoiceTemplateConfigurationProgramRule
  extends IProgramRule<InvoiceTemplateConfiguration> {
  type: ProgramRuleTypeEnum.INVOICE_TEMPLATE_CONFIGURATION;
}

export interface BaseRatesProgramRule extends IProgramRule<BaseRates> {
  type: ProgramRuleTypeEnum.BASE_RATES;
}

export interface InterestDaysProgramRule extends IProgramRule<InterestDays> {
  type: ProgramRuleTypeEnum.INTEREST_DAYS;
}

export interface IProgramRule<TValue> {
  type: ProgramRuleTypeEnum;
  value: TValue;
}

export interface InterestDays {
  perCurrency: PerCurrencyConfig;
}

export interface PerCurrencyConfig {
  [key: string]: number;
}
export interface BaseRates {
  perCurrency: BaseRate;
}
export interface BaseRate {
  [key: string]: IBaseRateConfig;
}

export interface IBaseRateConfig {
  baseRateType: string;
  referenceId: string;
  calculationMethod: string;
  floor: boolean;
  floorValue: number;
  value: number;
}
export interface FundingRequestActiveTime {
  durationInHours: number;
}

export interface SimpleMaturityPaymentProcessingDays {
  default: number;
}

export interface FundingResponseAutoAccept {
  enabled: boolean;
}

export interface Currency {
  acceptedCurrencies: string[];
}

export interface BankAccounts {
  bankAccounts: {
    [id: string]: {
      perCurrency: {
        [id: string]: {
          bankAccountId: string;
        };
      };
    };
  };
}

export interface InvoiceTemplateConfiguration {
  fields: IRuleField[];
}
export interface IRuleField {
  name: string;
  type: string;
  constraint: string;
  displayName: string;
  system: boolean;
  description: string;
  calculated: boolean;
  platformRequired: true;
  nestedFields: any[];
  exampleValue: string;
  custom: boolean;
  additionalProperties: IRuleField;
  displayReferencePath?: string;
  referencePath?: string;
}

export interface IInvoiceRule {
  fields: ILogisticsRuleSubtemplateField[];
}

export interface IInvoiceRuleSubtemplate {
  fields: ILogisticsRuleSubtemplateField[];
  type?: string;
  values: any;
  saveDraft: (data: LedgerInvoiceData) => void;
}
export interface IInvoiceRuleField {
  additionalProperties: any;
  calculated: boolean;
  constraint: 'MANDATORY' | 'OPTIONAL';
  custom: boolean;
  description: string;
  displayName: string;
  exampleValue: string;
  name: string;
  nestedFields: IInvoiceRuleField[];
  platformRequired: boolean;
  system: boolean;
  type: 'STRING' | 'DATE' | 'DOUBLE' | 'OBJECT' | 'DATE_TIME' | 'ARRAY' | 'CURRENCY' | 'BOOLEAN';
  referencePath?: string;
  value?: string | number | boolean | any[] | Date;
  isOpen?: boolean;
  selected?: boolean;
  excluded?: boolean;
  disabled?: boolean;
}

export interface ILogisticsRule {
  subTemplates: ILogisticsRuleSubtemplate[];
}

export interface ILogisticsRuleSubtemplate {
  templateReference: string;
  name: string;
  fields: ILogisticsRuleSubtemplateField[];
}

export interface ILogisticsRuleSubtemplateField {
  name: string;
  type: 'STRING' | 'DATE' | 'DOUBLE' | 'OBJECT' | 'DATE_TIME' | 'ARRAY' | 'BOOLEAN' | 'ARRAY';
  constraint: 'MANDATORY' | 'OPTIONAL';
  displayName: string;
  system: boolean;
  description: string;
  calculated: boolean;
  platformRequired: boolean;
  nestedFields: ILogisticsRuleSubtemplateField[];
  exampleValue: string;
  custom: boolean;
  additionalProperties: any;
  referencePath?: string;
  value?: string | number | boolean | any[] | Date;
  isOpen?: boolean;
}
