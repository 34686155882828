import { FC, useEffect, useState } from 'react';
import {
  BarChartTitle,
  BarChartContainer,
  BarChartWrapper,
  BarChartInfo,
  SkeletonHorizontalStyled,
  SkeletonVerticalStyled,
  SkeletonContainer
} from './styled';
import { useNavigate } from 'react-router';
import { RootStateOrAny, useSelector } from 'react-redux';
import BarChart from 'components/charts/bar-chart';
import { DateTime } from 'luxon';
import { BarChartDataSet } from 'utils/classes/charts/barChart';
import { themeColors } from 'assets/theme/style';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import { store } from 'store';
import { ProgramConfig } from 'api/interfaces/program/program.interface';

export interface TwelveWeekData {
  [key: string]: {
    approved: number;
  };
}

const BarChartDashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<BarChartDataSet[]>([]);
  const [data, setData] = useState<TwelveWeekData>({});
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const {
    allProgramsWithDetails,
    payments,
    activeCurrencyCode,
    internalLoading,
    loading: appLoading
  }: {
    allProgramsWithDetails: ProgramConfig[];
    internalLoading: boolean;
    loading: boolean;
    activeCurrencyCode: string;
    payments: LedgerPayment[];
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    setLoading(true);
    get12WeeksInvoices();
  }, [allProgramsWithDetails, activeCurrencyCode]);

  const get12WeeksInvoices: () => void = async () => {
    const invoiceApi = new InvoiceApi(store);
    const response = await invoiceApi.getApprovedInvoicesForNext12Weeks(
      allProgramsWithDetails.map((p) => p.id)
    );

    set12WeeksData(response?.data.value);
  };

  const set12WeeksData: (invoices: ILedgerInvoice[]) => void = (invoices) => {
    let twelveWeekLabels: string[] = [];
    const dataObj: TwelveWeekData = {};
    const currentWeekNumber: number = DateTime.utc().weekNumber;
    const currentYearNumber: number = DateTime.utc().year;
    // the date of the Sunday from todays date.
    let count: number = 0;
    do {
      const dt: DateTime = DateTime.fromObject({
        // adjusts the year based on if the week number should roll into following year
        weekYear: currentWeekNumber + count > 52 ? currentYearNumber + 1 : currentYearNumber,
        // adjusts the week number to set the correct week number if the year rolls into a new year
        weekNumber:
          currentWeekNumber + count > 52
            ? currentWeekNumber + count - 52
            : currentWeekNumber + count
      });

      const previousWeek: DateTime = DateTime.fromObject({
        // adjusts the year based on if the week number should roll into following year
        weekYear: currentWeekNumber - 1 + count > 52 ? currentYearNumber + 1 : currentYearNumber,
        // adjusts the week number to set the correct week number if the year rolls into a new year
        weekNumber:
          currentWeekNumber - 1 + count > 52
            ? currentWeekNumber - 1 + count - 52
            : currentWeekNumber - 1 + count
      }).endOf('week');
      const endOfCurrentWeek: string = dt.endOf('week').toFormat('dd MMM yy');

      const dataWithinWeekEnd = invoices.filter(
        (p: ILedgerInvoice) =>
          DateTime.fromISO(p.maturityDate || '') < dt.endOf('week') &&
          DateTime.fromISO(p.maturityDate || '') > previousWeek &&
          p.data.currency === activeCurrencyCode
      );

      setIsNoData(dataWithinWeekEnd.length === 0);

      const approvedDataWithinWeekEnd = dataWithinWeekEnd
        .map((item) => item.netAmount)
        .reduce((val, acc) => (val || 0) + (acc || 0), 0);

      twelveWeekLabels.push(endOfCurrentWeek);
      (dataObj[endOfCurrentWeek] as any) = {
        approved: approvedDataWithinWeekEnd,
        invoices: {
          approved: dataWithinWeekEnd
        }
      };
      count += 1;
    } while (count < 12);
    setData(dataObj);
    setChartDataset(dataObj);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const setChartDataset: (data: TwelveWeekData) => void = (data) => {
    const chartDataset: BarChartDataSet[] = [
      new BarChartDataSet(
        'Payments due',
        valueArr(data, 'approved'),
        setBackgroundColors(themeColors.chart.light.colour1, data),
        39,
        {
          data,
          dataStatus: 'approved'
        }
      )
    ];
    setChartData(chartDataset);
  };

  const setBackgroundColors: (value: string, data: any) => string[] = (value, data) => [
    ...Array(Object.keys(data).length).fill(value)
  ];

  const valueArr: (dataObj: any, key: string) => number[] = (dataObj, key) => {
    return Object.keys(dataObj).map((o) => dataObj[o][key]);
  };

  return (
    <BarChartWrapper data-testid="sp-bar-chart-container">
      {!internalLoading && !loading ? (
        <>
          <BarChartTitle>Maturity payment forecast</BarChartTitle>
          <BarChartInfo>Next 12 weeks</BarChartInfo>
        </>
      ) : (
        <SkeletonContainer>
          <SkeletonVerticalStyled variant="rectangular" />
          <SkeletonHorizontalStyled variant="rectangular" />
        </SkeletonContainer>
      )}

      <BarChartContainer>
        {!internalLoading && !loading && (
          <BarChart
            chartStyle="dashboard"
            labels={Object.keys(data)}
            datasets={chartData}
            noData={isNoData}
          />
        )}
      </BarChartContainer>
    </BarChartWrapper>
  );
};

export default BarChartDashboard;
