import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import { OutlinedInputProps } from './OutlinedInput';
import { isSmallerView } from 'lib/helpers/mobile';


export const OutlinedInputContainer = styled.div<OutlinedInputProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ? width : isSmallerView() ? '100%' : '80%' };
`;

export const OutlinedInputStyled = styled(OutlinedInput)<OutlinedInputProps>`
  display: flex;
  box-sizing: border-box;
  font-size: ${fontSizes.regular};
  box-shadow: ${({ showShadow }) => showShadow ? '0px 2px 4px 0px #3031331A,0px 0px 1px 0px #3031330D' : 'none'};
  border: ${({ disabled, noBorder }) => noBorder ? "none" : `2px solid ${disabled ? themeColors.border.inputDisabled : themeColors.border.input}!important}`};
  padding: 0 6px 0 12px;
  background-color: ${({ disabled }) => disabled ? themeColors.bg.light.inputDisabled : themeColors.bg.light.input}!important;
  color: ${themeColors.text.light.input}!important;;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  overflow: ${({ disabled }) => disabled && 'hidden'};
  width: 100%;
  height: ${({ height }) => height ? height : "40px"};
  min-height: ${({ minHeight }) => minHeight ? minHeight : "40px"};

  &.MuiOutlinedInput-root {
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : "6px"};
  }
  
  &:hover, &:-webkit-autofill:hover{
    background-color: ${themeColors.bg.light.inputHover}!important;
    border: ${({ disabled, noBorder }) => noBorder ? "none" : `2px solid ${disabled ? themeColors.border.inputDisabled : themeColors.border.inputHover}!important}`};
    color: ${themeColors.text.light.inputHover}!important; 
  }

  &:-webkit-autofill{
    -webkit-text-fill-color:  ${themeColors.text.light.input}!important; 
    background-color: ${themeColors.bg.light.input}!important;
    border: ${({ noBorder }) => noBorder ? "none" : `${themeColors.border.input}!important`};
    color: ${themeColors.text.light.input}!important;
  }
  &:focus, &:focus-visible, &:-webkit-autofill:focus{
    -webkit-text-fill-color:  ${themeColors.text.light.inputFocus}!important; 
    background-color: ${themeColors.bg.light.inputFocus}!important;
    border: 2px solid ${themeColors.border.inputFocus}!important; 
    color: ${themeColors.text.light.inputFocus}!important; 
    outline: none; 
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiButtonBase-root {
    color: ${themeColors.text.light.input}!important;
    background-color: transparent !important;
    &:hover,
    &:focus {
      background-color: transparent !important;
      box-shadow: none;
    }
  }
`;

export const OutlinedInputLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${typography.bodySemiBold};
  margin-bottom: 4px;
`;

export const OutlinedInputMessage = styled.span<OutlinedInputProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.text.error};
  ${typography.captionRegular};
`;
