import StoreBase from 'api/base/storeBase';
import { AsyncTask } from 'api/interfaces/asyncTask.interface';
import { IAttachments } from 'api/interfaces/attachment/attachment.interface';
import { AxiosResponse } from 'axios';
import { AssetEnum } from 'lib/enums/asset/asset.enum';

const _PATH = '/assets';

export default class AssetsApi extends StoreBase {
  public uploadLogisticAttachment: (file: File, logisticId: string) => Promise<AsyncTask> = async (
    file,
    logisticId
  ) => {
    const form = new FormData();
    form.append('file', file);

    const { data }: { data: AsyncTask } = await this.axiosInstance.post<
      any,
      AxiosResponse<AsyncTask>,
      FormData
    >(`${_PATH}/attachments/import/LOGISTIC/${logisticId}`, form);
    return data;
  };

  public uploadDocumentAttachment: (
    file: File,
    documentId: string,
    documentType: keyof typeof AssetEnum
  ) => Promise<AsyncTask> = async (file, documentId, documentType) => {
    const form = new FormData();
    form.append('file', file);

    const { data }: { data: AsyncTask } = await this.axiosInstance.post<
      any,
      AxiosResponse<AsyncTask>,
      FormData
    >(`${_PATH}/attachments/import/${documentType}/${documentId}`, form);
    return data;
  };

  public getAttachments: (
    documentId: string,
    documentType: keyof typeof AssetEnum
  ) => Promise<AxiosResponse<IAttachments>> = async (purchaseOrderId, documentType) =>
    await this.axiosInstance.get<any, AxiosResponse<IAttachments>>(
      `${_PATH}/attachments/${documentType}/${purchaseOrderId}`
    );

  public getAttachmentById: (attachmentId: string) => Promise<AxiosResponse<Blob>> = async (
    attachmentId
  ) =>
    await this.axiosInstance.get<any, AxiosResponse<Blob>>(
      `${_PATH}/attachments/data/${attachmentId}`,
      {
        responseType: 'blob'
      }
    );
}
