import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InputAdornment from '@mui/material/InputAdornment';
import { LedgerInvoiceData } from 'api/interfaces/ledger/ledgerInvoice.interface';
import {
  IInvoiceRuleSubtemplate,
  ILogisticsRuleSubtemplateField
} from 'api/interfaces/program/program.interface';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import TextInput from 'components/forms/inputs/TextInput';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { camelCaseToKebabCase } from 'lib/helpers/formatters/stringFormatters';
import { FC, useState } from 'react';
import {
  CheckboxFieldRow,
  CheckboxFieldText,
  CheckboxStyled,
  CreateInvoiceDetailDateSegment,
  DatePickerStyled,
  InvoiceCreateFieldLabel,
  InvoiceCreateFieldLabelContainer,
  InvoiceCreateFieldSegment,
  InvoiceCreateFieldValue,
  InvoiceCreateFieldWrapper
} from './styled';

const LedgerCreateInvoiceField: FC<IInvoiceRuleSubtemplate> = ({
  fields,
  type,
  values,
  saveDraft
}) => {
  const [draftInvoiceDataModal, setDraftInvoiceDataModal] = useState<LedgerInvoiceData | any>(
    values
  );

  const getType: (field: ILogisticsRuleSubtemplateField) => JSX.Element = (field) => {
    if (type === 'TEXT') {
      return renderField(field);
    } else {
      return renderFieldInput(field);
    }
  };

  const renderFields: () => JSX.Element[] = () => fields.map((f) => getType(f));

  const formatName: (name: string) => string = (name) => {
    const labels: string[] = name.split('.');
    const lastTwoLabels: string[] = labels.slice(-2).map((label) =>
      label
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .split(' ')
        .map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    );
    return lastTwoLabels.join(' / ');
  };

  const updatePickerOpenValue: (
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (fieldName, referencePath, fieldsData) => {
    const updatedFields = [...fieldsData].map((f, i) => {
      if (f.type === 'OBJECT') updatePickerOpenValue(fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.isOpen = !f.isOpen;

      return f;
    });
    // setDraftInvoiceDataModal(updatedFields);
    saveDraft(updatedFields);
  };

  const updateHandler: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => void = (value, fieldName, referencePath, fieldsData) => {
    saveDraft(updateFieldValueFromInput(value, fieldName, referencePath, fieldsData));
  };

  const updateFieldValueFromInput: (
    value: any,
    fieldName: string,
    referencePath: string | undefined,
    fieldsData: ILogisticsRuleSubtemplateField[]
  ) => ILogisticsRuleSubtemplateField[] = (value, fieldName, referencePath, fieldsData) => {
    const mutArr = [...fieldsData];
    return mutArr.map((f, i) => {
      if (f.type === 'OBJECT')
        updateFieldValueFromInput(value, fieldName, referencePath, f.nestedFields);

      if (f.name === fieldName && f.referencePath === referencePath) f.value = value;

      return f;
    });
  };

  const renderFieldInput: (field: ILogisticsRuleSubtemplateField) => JSX.Element = (field) => {
    const {
      type,
      name,
      displayName,
      exampleValue,
      description,
      nestedFields,
      referencePath = name,
      value,
      isOpen
    } = field;

    switch (type) {
      case 'DOUBLE':
        return (
          <TextInput
            key={`form-${name}`}
            type="number"
            name={name || ''}
            defaultValue={value as string}
            placeholder={exampleValue}
            label={referencePath ? formatName(referencePath) : displayName}
            tooltipText={description}
            changeHandler={(e) =>
              updateHandler(e.target.value, name, referencePath, draftInvoiceDataModal)
            }
            blurHandler={() => saveDraft(draftInvoiceDataModal)}
            testingTagPage="ledger-create-invoices-detail"
            errorMessage={value === '' ? `${displayName} is required` : ''}
          />
        );

      case 'BOOLEAN':
        return (
          <CheckboxFieldRow key={`form-${name}`}>
            <CheckboxFieldText data-automation-id="ledger-create-invoices-detail-p-checkbox-message">
              {referencePath ? formatName(referencePath) : displayName}
            </CheckboxFieldText>
            <CheckboxStyled
              checked={field.value as boolean}
              onChange={(e) =>
                updateHandler(e.target.checked, name, referencePath, draftInvoiceDataModal)
              }
              data-testid="sp-checkbox-item"
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'sp-clickable-checkbox'
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              data-automation-id="ledger-create-invoices-detail-span-checkbox-administrator"
            />
          </CheckboxFieldRow>
        );

      case 'DATE_TIME':
        return (
          <CreateInvoiceDetailDateSegment key={`form-${name}`}>
            <TextInput
              name={name || ''}
              label={referencePath ? formatName(referencePath) : displayName}
              defaultValue={formatDateTime(
                field.value ? (value as Date).toISOString() : '',
                'yyyy/MM/dd HH:mm'
              )}
              onClick={() => updatePickerOpenValue(name, referencePath, draftInvoiceDataModal)}
              blurHandler={() => saveDraft(draftInvoiceDataModal)}
              testingTagPage="create-invoice-dialog"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
              errorMessage={value === '' ? `${displayName} is required` : ''}
            />
            <DatePickerStyled
              open={isOpen}
              selected={(value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, name, referencePath, draftInvoiceDataModal);
                updatePickerOpenValue(name, referencePath, draftInvoiceDataModal);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(name, referencePath, draftInvoiceDataModal);
              }}
              showTimeSelect
            />
          </CreateInvoiceDetailDateSegment>
        );

      case 'OBJECT':
        return (
          <>
            {nestedFields &&
              nestedFields.map((item: ILogisticsRuleSubtemplateField) => {
                return renderFieldInput(item);
              })}
          </>
        );

      case 'DATE':
        return (
          <CreateInvoiceDetailDateSegment key={`form-${name}`}>
            <TextInput
              name={name || ''}
              label={referencePath ? formatName(referencePath) : displayName}
              defaultValue={formatDateTime(
                field.value ? (value as Date).toISOString() : '',
                'yyyy/MM/dd'
              )}
              onClick={() => updatePickerOpenValue(name, referencePath, draftInvoiceDataModal)}
              blurHandler={() => saveDraft(draftInvoiceDataModal)}
              testingTagPage="create-invoice-dialog"
              endIcon={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
              errorMessage={value === '' ? `${displayName} is required` : ''}
            />
            <DatePickerStyled
              open={isOpen}
              selected={(value as Date) || null}
              onChange={(date) => {
                updateFieldValueFromInput(date, name, referencePath, draftInvoiceDataModal);
                updatePickerOpenValue(name, referencePath, draftInvoiceDataModal);
              }}
              onClickOutside={() => {
                updatePickerOpenValue(name, referencePath, draftInvoiceDataModal);
              }}
            />
          </CreateInvoiceDetailDateSegment>
        );

      default:
        return (
          <TextInput
            key={`form-${name}`}
            name={name || ''}
            defaultValue={value as string}
            label={referencePath ? formatName(referencePath) : displayName}
            tooltipText={description}
            changeHandler={(e) =>
              updateHandler(e.target.value, name, referencePath, draftInvoiceDataModal)
            }
            blurHandler={() => saveDraft(draftInvoiceDataModal)}
            testingTagPage="ledger-create-invoices-detail"
            errorMessage={value === '' ? `${displayName} is required` : ''}
          />
        );
    }
  };

  const renderField: (field: ILogisticsRuleSubtemplateField) => JSX.Element = (field) => {
    const { nestedFields, value, type, name, displayName, description } = field;
    const fieldData: string | number | boolean | any[] | Date | undefined = value || '';

    const renderValue = () => {
      switch (type) {
        case 'DATE':
          return formatDateTime(
            fieldData ? new Date(fieldData as Date).toISOString() : '',
            'yyyy-MM-dd'
          );

        case 'BOOLEAN':
          return fieldData ? 'Yes' : 'No';

        case 'DOUBLE':
          return formatNumber((fieldData as number) || 0, 2);

        case 'OBJECT':
          return (
            <>
              {nestedFields &&
                nestedFields.map((item: ILogisticsRuleSubtemplateField, index: number) =>
                  renderField(item)
                )}
            </>
          );

        default:
          return fieldData;
      }
    };

    return (
      <InvoiceCreateFieldSegment
        key={`form-${name}`}
        data-automation-id={`ledger-create-invoices-detail-div-segment-${camelCaseToKebabCase(
          name
        )}`}
      >
        <InvoiceCreateFieldLabelContainer>
          <InvoiceCreateFieldLabel
            data-automation-id={`ledger-create-invoices-detail-h5-label-${camelCaseToKebabCase(
              name
            )}`}
          >
            {displayName}
          </InvoiceCreateFieldLabel>
          {description && <IconTooltip tooltipText={description} />}
        </InvoiceCreateFieldLabelContainer>
        <InvoiceCreateFieldValue
          data-automation-id={`ledger-create-invoices-detail-p-value-${camelCaseToKebabCase(name)}`}
        >
          {renderValue()}
        </InvoiceCreateFieldValue>
      </InvoiceCreateFieldSegment>
    );
  };

  return values && fields ? (
    <InvoiceCreateFieldWrapper
      data-automation-id={`ledger-create-invoices-detail-div-invoice-create-field-wrapper`}
    >
      {renderFields()}
    </InvoiceCreateFieldWrapper>
  ) : (
    <LoaderInPage />
  );
};

export default LedgerCreateInvoiceField;
