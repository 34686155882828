import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Skeleton from '@mui/material/Skeleton';

interface IDoughnutChartProps {
  colour?: string;
  alignItems?: string;
  direction?: 'column' | 'row';
  justifyContent?: string;
}

export const DoughnutStyled = styled(Doughnut)`
  position: relative;
  z-index: 999;
`;

export const DoughnutChartWrapper= styled.div<IDoughnutChartProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${themeColors.bg.light.surface};
  gap: 24px;
  padding: 16px;
  border-radius: 16px;
  width: ${isMobile() ? '100%' : ({ direction }) => direction !== 'column' ? "100%" : "384px"};
`;

export const DoughnutChartViewWrapper= styled.div<IDoughnutChartProps>`
  display: flex;
  justify-content: ${isMobile() ? 'center' : ({justifyContent}) => justifyContent || "center"}; 
  flex-direction: ${isMobile() ? 'column' : ({ direction }) => direction || 'column'};
  align-items: ${({ alignItems }) =>  alignItems || 'center'};
  gap: 24px;
`;

export const DoughnutChartDataWrapper= styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SkeletonCircularStyled= styled(Skeleton)`
  width: 280px!important; 
  height: 280px!important;
  background-color: ${themeColors.bg.light.surface};
`;

export const SkeletonStyled= styled(Skeleton)<IDoughnutChartProps>`
  width: ${isMobile() ? '280px' : ({direction})=> direction === 'column' ? '352px' : '547px'};
  display: flex;
  padding: 38px 24px 38px 24px;
  background-color: ${themeColors.bg.light.surface};
  border-radius: 16px;
  border: 1px solid transparent;
`;

export const DoughnutChartDataContainer= styled.div<IDoughnutChartProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${isMobile() ? '280px' : ({direction})=> direction === 'column' ? '352px' : '547px'};
  height: 78px;
  border-radius: 16px;
  gap: 16px;
  padding: 16px 24px 16px 24px;
  border: 1px solid transparent;
  background-color: ${themeColors.bg.light.surface};
  cursor: pointer;

  svg {
    display: none;
  }

  &:hover {
    background-color: ${themeColors.bg.light.surfaceRaised};
    border: 1px solid ${themeColors.bg.primary};
    border-radius: 16px;

    svg {
      display: block;
    }
  }
`;

export const DoughnutChartDataIcon = styled.div<IDoughnutChartProps>`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ colour }) => colour || themeColors.icon.primary};
`;

export const DoughnutChartDataValueContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const DoughnutChartDataValueBold =  styled.h3``;

export const DoughnutChartDataValue =  styled.span`
  ${typography.captionRegular};
`;

export const DoughnutChartDataLeft = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const DoughnutChartTitle = styled.h3``;

export const DoughnutChartContainer= styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  position: relative;
  width: 280px; 
  height: 280px;
`;

export const DoughnutChartInnerContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: absolute;
  width: 180px;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DoughnutChartInnerTitle = styled.h3`
  font-weight: 500;
  text-align: center;
`;

export const DoughnutChartInnerValue = styled.h2``;

