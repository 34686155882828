import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const PlayIcon: FC<IconSvgProps> = ({ height = '89', width = '90', color = themeColors.white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 90 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_470)">
      <path
        d="M37.6356 61.2748L59.6728 44.747L37.6356 28.2191V61.2748ZM44.9813 8.01845C24.7072 8.01845 8.25282 24.4728 8.25282 44.747C8.25282 65.0211 24.7072 81.4755 44.9813 81.4755C65.2555 81.4755 81.7099 65.0211 81.7099 44.747C81.7099 24.4728 65.2555 8.01845 44.9813 8.01845ZM44.9813 74.1298C28.7841 74.1298 15.5985 60.9442 15.5985 44.747C15.5985 28.5497 28.7841 15.3642 44.9813 15.3642C61.1786 15.3642 74.3642 28.5497 74.3642 44.747C74.3642 60.9442 61.1786 74.1298 44.9813 74.1298Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_470">
        <rect
          width="88.1485"
          height="88.1485"
          fill={color}
          transform="translate(0.907272 0.672714)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PlayIcon;
