/* eslint-disable react-hooks/exhaustive-deps */
import LoaderInPage from 'components/common/loader/LoaderInPage';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  LedgerDetailAddressContainer,
  LedgerDetailAddressValueContainer,
  LedgerDetailAddressesContainer,
  LedgerDetailAddressesTitle,
  LedgerDetailAddressesValue,
  LedgerDetailAddressesWrapper
} from './styled';
import { Address, Addresses } from 'api/interfaces/address/address.interface';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';

interface ILedgerDetailAddresses {
  addresses: Addresses | null;
}

interface ILedgerAddress extends Address {
  name: string;
}

const LedgerDetailAddresses: FC<ILedgerDetailAddresses> = ({ addresses }) => {
  const [data, setData] = useState<ILedgerAddress[]>([]);
  const { loading: appLoading }: { loading: string } = useSelector(
    (state: RootStateOrAny) => state.app
  );

  useEffect(() => {
    if (addresses && Object.keys(addresses).length > 0) setData(mapData(addresses));
  }, [addresses]);

  const mapData: (addressesObj: Addresses) => ILedgerAddress[] = (addressesObj) =>
    Object.keys(addressesObj)
      .filter(
        (key) =>
          Boolean((addressesObj as any)[key]?.addressLine1) ||
          Boolean((addressesObj as any)[key]?.addressLine2) ||
          Boolean((addressesObj as any)[key]?.addressLine3) ||
          Boolean((addressesObj as any)[key]?.addressLine4) ||
          Boolean((addressesObj as any)[key]?.addressee) ||
          Boolean((addressesObj as any)[key]?.code) ||
          Boolean((addressesObj as any)[key]?.city) ||
          Boolean((addressesObj as any)[key]?.region) ||
          Boolean((addressesObj as any)[key]?.country)
      )
      .map((k: any) => ({
        name: k,
        addressLine1: (addressesObj as any)[k]?.addressLine1 || '',
        addressLine2: (addressesObj as any)[k]?.addressLine2 || '',
        addressLine3: (addressesObj as any)[k]?.addressLine3 || '',
        addressLine4: (addressesObj as any)[k]?.addressLine4 || '',
        addressee: (addressesObj as any)[k]?.addressee || '',
        code: (addressesObj as any)[k]?.code || '',
        city: (addressesObj as any)[k]?.city || '',
        region: (addressesObj as any)[k]?.region || '',
        country: (addressesObj as any)[k]?.country || ''
      }));

  return appLoading ? (
    <LoaderInPage />
  ) : (
    <LedgerDetailAddressesWrapper data-testid="sp-ledger-detail-addresses">
      <LedgerDetailAddressesContainer>
        {data.map((d) => (
          <LedgerDetailAddressContainer>
            <LedgerDetailAddressesTitle>{toSentenceCase(d.name)}</LedgerDetailAddressesTitle>
            <LedgerDetailAddressValueContainer>
              <LedgerDetailAddressesValue>{d.addressee}</LedgerDetailAddressesValue>
              <LedgerDetailAddressesValue>{d.addressLine1}</LedgerDetailAddressesValue>
              <LedgerDetailAddressesValue>{d.addressLine2}</LedgerDetailAddressesValue>
              {d.addressLine3 && (
                <LedgerDetailAddressesValue>{d.addressLine3}</LedgerDetailAddressesValue>
              )}
              {d.addressLine4 && (
                <LedgerDetailAddressesValue>{d.addressLine4}</LedgerDetailAddressesValue>
              )}
              <LedgerDetailAddressesValue>{d.code}</LedgerDetailAddressesValue>
              <LedgerDetailAddressesValue>{d.city}</LedgerDetailAddressesValue>
              <LedgerDetailAddressesValue>{d.country}</LedgerDetailAddressesValue>
            </LedgerDetailAddressValueContainer>
          </LedgerDetailAddressContainer>
        ))}
      </LedgerDetailAddressesContainer>
    </LedgerDetailAddressesWrapper>
  );
};

export default LedgerDetailAddresses;
