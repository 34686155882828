export const GET_INVOICE_MATCHING_RESULTS =
  '@@frontend/store/reducers/invoice/GET_INVOICE_MATCHING_RESULTS';
export const GET_INVOICE_MATCHING_RESULTS_SUCCESS =
  '@@frontend/store/reducers/invoice/GET_INVOICE_MATCHING_RESULTS_SUCCESS';
export const GET_INVOICE_MATCHING_RESULTS_FAILURE =
  '@@frontend/store/reducers/invoice/GET_INVOICE_MATCHING_RESULTS_FAILURE';
export const POST_INVOICE_COMMENTS = '@@frontend/store/reducers/invoice/POST_INVOICE_COMMENTS';
export const POST_INVOICE_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/invoice/POST_INVOICE_COMMENTS_SUCCESS';
export const POST_INVOICE_COMMENTS_FAILURE =
  '@@frontend/store/reducers/invoice/POST_INVOICE_COMMENTS_FAILURE';
export const GET_INVOICE_COMMENTS = '@@frontend/store/reducers/invoice/GET_INVOICE_COMMENTS';
export const GET_INVOICE_COMMENTS_SUCCESS =
  '@@frontend/store/reducers/invoice/GET_INVOICE_COMMENTS_SUCCESS';
export const GET_INVOICE_COMMENTS_FAILURE =
  '@@frontend/store/reducers/invoice/GET_INVOICE_COMMENTS_FAILURE';
export const RESET_MATCHING_RESULTS = '@@frontend/store/reducers/invoice/RESET_MATCHING_RESULTS';
export const SET_INVOICE_COMMENTS = '@@frontend/store/reducers/invoice/SET_INVOICE_COMMENTS';
export const SET_LEDGER_INVOICE = '@@frontend/store/reducers/invoice/SET_LEDGER_INVOICE';
export const RESET_LEDGER_INVOICE = '@@frontend/store/reducers/invoice/RESET_LEDGER_INVOICE';
