import { fontSizes, typography } from 'assets/theme/typography';
import { styling, themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const CompareCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  border: 1px solid ${themeColors.bg.light.muted};
  border-radius: ${styling.defaultRadius};
  gap: ${styling.defaultPadding};
  background-color: ${themeColors.white};
  min-height: 155px;
  padding: ${styling.defaultPadding};
`;

export const CompareCardTitle = styled.h4`
  margin: 0;
`;

export const CompareCardInfo = styled.div`
  ${typography.fontHindSmall};
  color: ${themeColors.text.light.subtle};
`;

export const CompareCardSubtitle = styled.div`
  ${typography.bodyBold};
  color: ${themeColors.text.light.subtle};
  font-size: ${fontSizes.small};
`;

export const CompareCardValue = styled.div`
  ${typography.fontHindRegular};
  color: ${themeColors.text.light.body};
`;

export const CompareCardDocumentNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  ${typography.fontHindRegular};
  color: ${themeColors.text.light.body};
`;
