import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import BaseCard from 'components/common/cards/BaseCard';
import ItemCard from 'components/common/cards/ItemCard';
import Divider from 'components/common/divider';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { EntityDetailsContainer, EntityDetailsItemsTitle, EntityDetailsTitle } from './styled';
export interface EntityDetailsType {
  registeredCompanyName: string;
  tradingName: string;
  contactNumber: string;
  website: string;
  registeredAddressLine1: string;
  registeredAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

const EntityDetails: FC = () => {
  const [details, setDetails] = useState<EntityDetailsType>({
    registeredCompanyName: '',
    tradingName: '',
    contactNumber: '',
    website: '',
    registeredAddressLine1: '',
    registeredAddressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  });
  const {
    entityDetails
  }: {
    entityDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.entity);

  const {
    countries
  }: {
    countries: Record<string, string>;
  } = useSelector((state: RootStateOrAny) => state.app);

  useEffect(() => {
    updateEntityDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityDetails, countries]);

  const updateEntityDetails: () => void = () => {
    if (!entityDetails || !entityDetails.contact || !entityDetails.registeredAddress) return;

    const { registeredName, tradingName, phoneNumber, siteUrl } = entityDetails.contact;
    const { line1, line2, city, state, zipCode, countryIso } = entityDetails.registeredAddress;

    setDetails({
      registeredCompanyName: registeredName || '',
      tradingName: tradingName || '',
      registeredAddressLine1: line1 || '',
      registeredAddressLine2: line2 || '',
      city: city || '',
      state: state || '',
      zipCode: zipCode || '',
      website: siteUrl || '',
      contactNumber: phoneNumber || '',
      country: countryIso || ''
    });
  };

  const renderView: () => JSX.Element = () => (
    <LayoutViewContainer size="small" margin="0 auto" data-testid="sp-entity-details">
      <BaseCard noBoxShadow>
        <EntityDetailsTitle data-automation-id="entity-details-h2-title">
          {details.tradingName || ''}
        </EntityDetailsTitle>
        <EntityDetailsContainer>
          <EntityDetailsItemsTitle>Company details</EntityDetailsItemsTitle>
          <ItemCard title="Registered name" value={details.registeredCompanyName || ''} />
          <ItemCard title="Trading name" value={details.tradingName || ''} />
          <ItemCard title="Phone number" value={details.contactNumber || ''} />
          <ItemCard
            title="Website"
            list={[{ name: details.website || '', path: details.website || '' }]}
          />
          <EntityDetailsItemsTitle>Registered address</EntityDetailsItemsTitle>
          <ItemCard title="Address line 1" value={details.registeredAddressLine1 || ''} />
          <ItemCard title="City" value={details.city || ''} />
          <ItemCard title="State / Region" value={details.state || ''} />
          <ItemCard
            title="Country"
            value={countries && details?.country ? countries[details.country] : ''}
          />
          <ItemCard title="ZIP / Post code" value={details.zipCode || ''} />
        </EntityDetailsContainer>
        <Divider margin="16px 0" />
      </BaseCard>
    </LayoutViewContainer>
  );

  return renderView();
};

export default EntityDetails;
