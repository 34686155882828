import { themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

interface IComplete {
  isComplete: boolean;
}
interface IButton {
  color: string;
}

export const ToDoIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-top: 4px;
`;

export const ToDoButtonText = styled.p<IButton>`
  margin-right: 3rem;
  color: ${({ color }) => color};
`;

export const ToDoButtonContainer = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const ToDoButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(1px);
`;

export const ToDoButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const ToDoSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const ToDoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ToDoHeader = styled.h4<IComplete>`
  color: ${({ isComplete }) => (isComplete ? themeColors.text.light.subtle : themeColors.text.light.body)};
`;

export const ToDoTextDescription = styled.p<IComplete>`
  color: ${({ isComplete }) => (isComplete ? themeColors.text.light.subtle : themeColors.text.light.body)};
`;
