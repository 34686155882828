import TooltipIcon from 'assets/icons/TooltipIcon';
import { themeColors } from 'assets/theme/style';
import { FC, useState, ReactNode } from 'react';
import { LightTooltip, LightTooltipContainer } from './styled';

export interface TooltipProps {
  tooltipText?: any;
  children?: ReactNode;
  placement?:
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'right-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'top-end'
    | 'top-start'
    | undefined;
  backgroundColor?: string;
  color?: string;
}

const IconTooltip: FC<TooltipProps> = ({
  tooltipText,
  children,
  placement,
  backgroundColor = themeColors.white,
  color = themeColors.text.light.body
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleTooltipClose: () => void = () => setOpen(false);
  const handleTooltipOpen: () => void = () => setOpen(true);

  return (
    <LightTooltip
      title={tooltipText}
      placement={placement || 'right-start'}
      onClose={handleTooltipClose}
      open={open}
      data-testid="sp-tooltip"
      arrow
      backgroundColor={backgroundColor}
      color={color}
    >
      <LightTooltipContainer
        style={{ padding: '0', width: 'fit-content', borderRadius: '16px' }}
        onClick={handleTooltipOpen}
        onMouseOver={handleTooltipOpen}
        data-testid="sp-tooltip-button"
      >
        {children ? children : <TooltipIcon />}
      </LightTooltipContainer>
    </LightTooltip>
  );
};
export default IconTooltip;
