/* eslint-disable react-hooks/exhaustive-deps */
import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import {
  IOnboardingEntity,
  IOnboardingIdentity
} from 'api/interfaces/onboarding/onboarding.interface';
import { themeColors } from 'assets/theme/style';
import { fontWeights } from 'assets/theme/typography';
import { PrimaryButton } from 'components/common/buttons';
import CustomLink from 'components/common/links/CustomLink';
import TextInput from 'components/forms/inputs/TextInput';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum,
  OnboardingConfirmationStatesEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  OnboardingButtonText,
  OnboardingButtonWrapper,
  OnboardingContainer,
  OnboardingContentContainer,
  OnboardingDescription,
  OnboardingHeader,
  OnboardingTitle
} from 'views/Onboarding/styled';
import { OnboardingStepProps } from '../OnboardingStepCreateAccount/OnboardingStepCreateAccount';
import {
  FormStyled,
  OnboardingStepCompanyDetailsInput,
  OnboardingStepCompanyDetailsInputWrapper
} from './styled';

const OnboardingStepCompanyDetails: FC<OnboardingStepProps> = ({ handleNext }) => {
  const {
    legalEntityInit,
    countries
  }: {
    legalEntityInit: IOnboardingEntity | null;
    countries: Record<string, string> | null;
  } = useSelector((state: RootStateOrAny) => state.app);

  const [data, setData] = useState<any>({
    registeredCompanyName: '',
    dunsNumber: '',
    streetAddress: '',
    town: '',
    region: '',
    postcode: '',
    country: '',
    taxId: '',
    contactNumber: '',
    website: ''
  });

  useEffect(() => {
    if (legalEntityInit) {
      const { line1, line2, locality, region, zipCode, country } = legalEntityInit.spec.address;
      const x: { type: string; value: string }[] = legalEntityInit.spec.contacts;
      const { name, duns } = legalEntityInit.spec;
      const phoneNumber: any = x.find((y) => y.type === 'telephoneNumber');
      const website: any = x.find((y) => y.type === 'siteUrl');

      setData({
        registeredCompanyName: name,
        dunsNumber: duns,
        streetAddress: `${line1} ${line2 ? `, ${line2}` : ''}`,
        town: locality,
        region,
        postcode: zipCode,
        country: countries ? countries[country] : '',
        taxId: '',
        contactNumber: phoneNumber ? phoneNumber.value : '',
        website: website ? website.value : ''
      });
    }
  }, [legalEntityInit]);

  const { handleSubmit } = useForm<IOnboardingIdentity>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const onSubmit: SubmitHandler<IOnboardingIdentity> = () => {
    const putModel = new OnboardingActionEventPut(
      '',
      OnboardingConfirmationStatesEnum.confirmed,
      OnboardingActionStatusEnum.completed
    );
    handleNext(OnboardingActionsEnum.companyDetails, putModel);
  };

  const detailsDisputedClickHandler: () => void = () => {
    const putModel = new OnboardingActionEventPut(
      '',
      OnboardingConfirmationStatesEnum.disputed,
      OnboardingActionStatusEnum.initiated
    );
    handleNext(OnboardingActionsEnum.companyDetails, putModel);
  };

  return (
    <OnboardingContainer data-testid="sp-onboarding-company-details">
      <OnboardingHeader>
        <OnboardingTitle data-automation-id="onboarding-h3-company-details-title">
          Check and confirm your company details
        </OnboardingTitle>
        <OnboardingDescription data-automation-id="onboarding-p-company-details-description">
          Your buyer has given us the following details about your company.
          <br />
          Please{' '}
          <CustomLink
            title="let us know"
            link="https://supplier.support.kanexa.net/hc/en-us/requests/new"
            textDecoration="none"
          />{' '}
          if any need updating.
        </OnboardingDescription>
      </OnboardingHeader>
      <OnboardingContentContainer>
        <FormStyled
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
          }}
          data-testid="sp-onboarding-company-details-form"
          data-automation-id="onboarding-form-company-details"
        >
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Registered company name"
                disabled
                defaultValue={data.registeredCompanyName}
                testingTagPage="onboarding-company-details"
                tooltipText="This is your company's official name and how you're known on Supplier Pay."
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="DUNS number"
                disabled
                defaultValue={data.dunsNumber}
                testingTagPage="onboarding-company-details"
                tooltipText="Your Dun & Bradstreet D-U-N-S number is a unique 9-digit code which identifies your business and its locations."
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Street Address"
                disabled
                defaultValue={data.streetAddress}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Town"
                disabled
                defaultValue={data.town}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Region/State"
                disabled
                defaultValue={data.region}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Zip/Post code"
                disabled
                defaultValue={data.postcode}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Country"
                disabled
                defaultValue={data.country}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Company tax ID"
                disabled
                defaultValue={data.taxId}
                testingTagPage="onboarding-company-details"
                tooltipText="This is your company's official tax ID."
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Contact number"
                disabled
                defaultValue={data.contactNumber}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingStepCompanyDetailsInputWrapper>
            <OnboardingStepCompanyDetailsInput>
              <TextInput
                label="Company website"
                disabled
                defaultValue={data.website}
                testingTagPage="onboarding-company-details"
              />
            </OnboardingStepCompanyDetailsInput>
          </OnboardingStepCompanyDetailsInputWrapper>
          <OnboardingHeader>
            <OnboardingDescription data-automation-id="onboarding-p-company-details-description">
              Your buyer has given us the following details about your company.
              <br />
              Please{' '}
              <CustomLink
                title="let us know"
                link="https://supplier.support.kanexa.net/hc/en-us/requests/new"
                textDecoration="none"
              />{' '}
              if any need updating.
            </OnboardingDescription>
          </OnboardingHeader>
          <OnboardingButtonWrapper>
            <PrimaryButton
              type="submit"
              testingTag="onboarding-button-company-details-next"
              text="Confirm"
            />
          </OnboardingButtonWrapper>
        </FormStyled>
      </OnboardingContentContainer>
    </OnboardingContainer>
  );
};

export default OnboardingStepCompanyDetails;
