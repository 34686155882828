import StoreBase from 'api/base/storeBase';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { AxiosResponse } from 'axios';
import { CommentPostModel } from 'utils/classes/open-account/comment/comment';

const _PATH: string = '/open-accounts/logistics';

export default class OpenAccountLogisticsApi extends StoreBase {
  public postLogisticComment: (
    logisticId: string,
    data: CommentPostModel
  ) => Promise<AxiosResponse<IComment>> = async (logisticsId, data) =>
    this.axiosInstance.post<IComment>(`${_PATH}/${logisticsId}/comments`, data);

  public getLogisticComments: (logisticsId: string) => Promise<AxiosResponse<IComment[]>> = async (
    logisticsId
  ) => this.axiosInstance.get<IComment[]>(`${_PATH}/${logisticsId}/comments`);

  public getOpenAccountLogisticsMatchingById: (logisticId: string) => Promise<AxiosResponse<any>> =
    async (logisticId) => this.axiosInstance.get<any>(`${_PATH}/${logisticId}/matching-results`);
}
