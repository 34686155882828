import { RequiredAuth } from 'components/routes/SecureRoute';
import { IRoute } from 'utils/interfaces/route/route.interface';
import AllRecentActivities from 'views/AllRecentActivities';
import CompanyDetails from 'views/CompanyDetails';
import CompanyUserManagement from 'views/CompanyDetails/CompanyUserManagement';
import Connections from 'views/Connections';
import ESG from 'views/ESG';
import Home from 'views/Home';
import InvoicesView from 'views/InvoicesView';
import InvoiceDetail from 'views/InvoicesView/InvoiceDetail';
import NotFoundPage from 'views/NotFound';
import OutgoingPayments from 'views/OutgoingPayments';
import Onboarding from 'views/Onboarding';
import Payments from 'views/Payments';
import PaymentDetail from 'views/Payments/PaymentDetail';
import PurchaseOrders from 'views/PurchaseOrders';
import PurchaseOrderDetail from 'views/PurchaseOrders/PurchaseOrderDetail';
import PersonalDetails from 'views/User/PersonalDetails';
import LogisticsView from 'views/LogisticsView';
import LogisticDetail from 'views/LogisticsView/LogisticDetail';

const routes: IRoute[] = [
  { path: '/', name: 'Home', component: <RequiredAuth />, secure: <Home /> },
  { path: '/app/:appId', name: 'Tenanted Home', component: <RequiredAuth />, secure: <Home /> },
  {
    path: '/app/:appId/connections',
    name: 'Tenanted connections',
    component: <RequiredAuth />,
    secure: <Connections />,
    pathRegex: /^\/app\/(?:([^/]+?))(?=\/|$)\/connections/i,
    breadcrumbShow: false,
    breadcrumbs: [
      {
        name: 'Connections',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: 'login/callback',
    name: 'OIDC Callback',
    component: <Home />
  },
  // {
  //   path: 'login/callback',
  //   name: 'Okta Callback',
  //   component: <LoginCallback loadingElement={<LoaderFullPage />} />
  // },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: <Onboarding />
  },
  {
    path: '/company-details',
    name: 'Company Details',
    component: <RequiredAuth />,
    secure: <CompanyDetails />,
    breadcrumbShow: false,
    breadcrumbs: [
      {
        name: 'Company details',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/personal-details',
    name: 'User Details',
    component: <RequiredAuth />,
    secure: <PersonalDetails />,
    breadcrumbShow: false,
    breadcrumbs: [
      {
        name: 'User details',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/connections',
    name: 'Connections',
    component: <RequiredAuth />,
    secure: <Connections />,
    breadcrumbShow: false,
    breadcrumbs: [
      {
        name: 'Connections',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/recent-activities',
    name: 'Recent activity',
    component: <RequiredAuth />,
    secure: <AllRecentActivities />,
    breadcrumbs: [
      {
        name: 'Recent activity',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/esg',
    name: 'ESG',
    component: <RequiredAuth />,
    secure: <ESG />
  },
  {
    path: '/get-paid',
    name: 'Get Paid',
    component: <RequiredAuth />,
    secure: <NotFoundPage />
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: <RequiredAuth />,
    secure: <InvoicesView />,
    breadcrumbs: [
      {
        name: 'Invoices',
        path: '',
        component: <InvoicesView />
      }
    ]
  },
  {
    path: '/invoices/:invoiceId',
    name: 'Invoice Detail',
    pathRegex: /^\/invoices\/(?:([^/]+?))(?=\/|$)/i,
    component: <RequiredAuth />,
    secure: <InvoiceDetail />,
    breadcrumbs: [
      {
        name: 'Invoices',
        path: '/invoices',
        component: <InvoicesView />
      },
      {
        name: ':invoiceId',
        path: '',
        component: <></>,
        dynamic: true
      }
    ]
  },
  {
    path: '/app/:appId/invoices/:invoiceId',
    name: 'Tenanted invoice detail',
    component: <RequiredAuth />,
    secure: <InvoiceDetail />,
    pathRegex: /^\/app\/(?:([^/]+?))(?=\/|$)\/invoices\/(?:([^/]+?))(?=\/|$)/i,
    breadcrumbs: [
      {
        name: 'Invoices',
        path: '/invoices',
        component: <InvoicesView />
      },
      {
        name: ':invoiceId',
        path: '',
        component: <></>,
        dynamic: true
      }
    ]
  },
  {
    path: '/outgoing-payments',
    name: 'Outgoing Payments',
    component: <RequiredAuth />,
    secure: <OutgoingPayments />,
    breadcrumbs: [
      {
        name: 'Outgoing Payments',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/payments',
    name: 'Payments',
    component: <RequiredAuth />,
    secure: <Payments />,
    breadcrumbs: [
      {
        name: 'Payments',
        path: '',
        component: <></>
      }
    ]
    // children: [
    //   {
    //     path: '/payments/:paymentId',
    //     name: 'Payment Detail',
    //     component: <PaymentDetail />,
    //     breadcrumbs: [
    //       {
    //         name: 'Payments',
    //         path: '',
    //         component: <Payments />
    //       }
    //     ]
    //   }
    // ]
  },
  {
    path: '/payments/:paymentId',
    name: 'Payment Detail',
    component: <RequiredAuth />,
    secure: <PaymentDetail />,
    pathRegex: /^\/payments\/(?:([^/]+?))(?=\/|$)/i,
    breadcrumbs: [
      {
        name: 'Payments',
        path: '/payments',
        component: <Payments />
      },
      {
        name: 'Payment',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/recent-activities/:paymentId',
    name: 'Recent Activity',
    component: <RequiredAuth />,
    secure: <PaymentDetail />,
    pathRegex: /^\/recent-activities\/(?:([^/]+?))(?=\/|$)/i,
    breadcrumbs: [
      {
        name: 'Recent activity',
        path: '/recent-activities',
        component: <AllRecentActivities />
      },
      {
        name: 'Early payment request',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/purchase-orders',
    name: 'Purchase orders',
    component: <RequiredAuth />,
    secure: <PurchaseOrders />,
    breadcrumbs: [
      {
        name: 'Purchase orders',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/logistics',
    name: 'Logistics',
    component: <RequiredAuth />,
    secure: <LogisticsView />,
    breadcrumbs: [
      {
        name: 'Logistics',
        path: '',
        component: <></>
      }
    ]
  },
  {
    path: '/logistics/:logisticId',
    name: 'Logistic Detail',
    pathRegex: /^\/logistics\/(?:([^/]+?))(?=\/|$)/i,
    component: <RequiredAuth />,
    secure: <LogisticDetail />,
    breadcrumbs: [
      {
        name: 'Logistics',
        path: '/logistics',
        component: <LogisticsView />
      },
      {
        name: ':logisticId',
        path: '',
        component: <></>,
        dynamic: true
      }
    ]
  },
  {
    path: '/purchase-orders/:purchaseOrderId',
    name: 'Purchase Order Detail',
    component: <RequiredAuth />,
    secure: <PurchaseOrderDetail />,
    pathRegex: /^\/purchase-orders\/(?:([^/]+?))(?=\/|$)/i,
    breadcrumbs: [
      {
        name: 'Purchase orders',
        path: '/purchase-orders',
        component: <PurchaseOrders />
      },
      {
        name: ':purchaseOrderId',
        path: '',
        component: <></>,
        dynamic: true
      }
    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: <NotFoundPage />
  }
];

export default routes;
