import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const CompanyBankAccountDetailWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  padding: ${isMobile() ? '0 16px' : 0};
`;

export const CompanyBankAccountDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${isMobile() ? '100%' : '30%'};
  min-width: ${isMobile() ? 'auto' : '350px'};
`;

export const CompanyBankAccountDetailTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.primary};
  margin-bottom: ${isMobile() ? '24px' : '32px'};
`;

export const CompanyBankAccountDetailSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${isMobile() ? '24px' : '32px'};
  width: 100%;
`;

export const CompanyBankAccountDetailSubtitle = styled.h4``;

export const CompanyBankAccountDetailButtonWrapper = styled.div`
  width: ${isMobile() ? '100%' : 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 33px;
`;

export const CompanyBankAccountDetailButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const CompanyBankAccountDetailInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ItemCardRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;
