/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import InvoiceApi from 'api/ledger/invoices.api';
import InfoIcon from 'assets/icons/InfoIcon';
import { themeColors } from 'assets/theme/style';
import DataGrid from 'components/DataGrid';
import Notification from 'components/Notification';
import QueryBuilder from 'components/QueryBuilder';
import BarChart from 'components/charts/bar-chart';
import { PrimaryButton } from 'components/common/buttons';
import PillButton from 'components/common/buttons/PillButton';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import EarlyPaymentBanner from 'components/early-payment/EarlyPaymentBanner';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';
import InvoicesViewMobile from 'components/invoices/InvoicesViewMobile';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { _DATE_FORMAT } from 'lib/constants/contants';
import {
  invoiceLedgerFundedHeaders,
  invoiceLedgerOAExternalFundingHeaders,
  invoiceLedgerOAFundingHeaders,
  invoiceLedgerOAHeaders
} from 'lib/data/invoice-ledger/invoiceLedgerHeaders';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { QueryComponents } from 'lib/enums/query/queryComponents.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { isMobile } from 'lib/helpers/mobile';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { DateTime } from 'luxon';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { store } from 'store';
import { BarChartDataSet } from 'utils/classes/charts/barChart';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import { QueryDateRange, QueryItem, QueryRow } from 'utils/classes/query/query';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';
import {
  InvoiceLedgerFilterButtonTextWrapper,
  InvoicesPageTitle,
  InvoicesViewTitleRow,
  SearchAdvancedText,
  SearchContainer,
  SearchLeftContainer
} from './styled';

export interface HeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
}

export interface TwelveWeekData {
  [key: string]: {
    eligible: number;
    ineligible: number;
  };
}

const fieldDictionary: any = {
  invoiceNumber: 'invoiceNumber',
  buyerName: 'buyerRef',
  invoiceDate: 'createdAt',
  invoiceAmount: 'data/amount',
  dueDate: 'data/paymentDueDate',
  currency: 'invoiceCurrency',
  netAmount: 'netAmount',
  maturityDate: 'maturityDate'
};

const Invoices: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [querySet, setQuerySet] = useState<QueryItem[]>([]);
  const [subsetInvoices, setSubsetInvoices] = useState<ILedgerInvoice[]>([]);
  const [ledgerInvoices, setLedgerInvoices] = useState<DataGridRowItem[]>([]);
  const [activeQueries, setActiveQueries] = useState<QueryRow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [data, setData] = useState<TwelveWeekData>({});
  const [exportData, setExportData] = useState<DataGridRowItem[]>([]);
  const [chartData, setChartData] = useState<BarChartDataSet[]>([]);
  const programFunding: boolean = useCheckProgramsContainsType(ProgramType.SP_FUNDING);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [invoiceLedger, setInvoiceLedger] = useState<ILedgerInvoice[]>([]);
  const [invoiceInitialLoading, setInvoiceInitialLoading] = useState<boolean>(false);
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const [invoiceLedgerCount, setInvoiceLedgerCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('createdAt desc');
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [invoicesByEligibility, setInvoicesByEligibility] = useState<ILedgerInvoice[]>([]);
  const [initialise, setInitialise] = useState<boolean>(false);

  const {
    programType,
    loading: appLoading,
    programs,
    groupedPrograms,
    allProgramsWithDetails
  }: {
    programType: string;
    programIds: string[];
    loading: boolean;
    programs: ProgramConfig[];
    groupedPrograms: IGroupedProgram[];
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const ledgerLoading: boolean = useSelector((state: RootStateOrAny) => state.ledger.loading);

  const invoiceApi = new InvoiceApi(store);

  const currencies: string[] =
    groupedPrograms.length > 1 ? groupedPrograms.map((program) => program.currency) : [];

  const queryItems: QueryItem[] = [
    {
      filterName: 'Invoice Number',
      filterValue: 'invoiceNumber',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Invoice Status',
      filterValue: 'invoiceStatus',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      options: [
        {
          name: 'Open',
          value: 'OPEN'
        },
        {
          name: 'Approved',
          value: 'APPROVED'
        },
        {
          name: 'Closed',
          value: 'CLOSED'
        }
      ]
    },
    {
      filterName: 'Matching Status',
      filterValue: 'matchingStatus',
      componentType: QueryComponents.DEFINITIVE_SIMPLE_DROPDOWN,
      display: ProgramType.SP_FUNDING !== programType,
      options: [
        {
          name: 'Accepted',
          value: 'ACCEPTED'
        },
        {
          name: 'Pending',
          value: 'PENDING'
        },
        {
          name: 'Matched',
          value: 'MATCHED'
        },
        {
          name: 'Rejected',
          value: 'REJECTED'
        },
        {
          name: 'Discrepant',
          value: 'DISCREPANT'
        }
      ]
    },
    {
      filterName: 'Buyer',
      filterValue: 'buyer',
      componentType: QueryComponents.TEXT
    },
    {
      filterName: 'Created Date',
      filterValue: 'invoiceDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Payment Due Date',
      filterValue: 'paymentDueDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw'
    },
    {
      filterName: 'Maturity Date',
      filterValue: 'maturityDate',
      componentType: QueryComponents.DATE,
      defaultComparator: 'btw',
      display: ProgramType.OA_EXTERNAL_FUNDING !== programType
    },
    {
      filterName: 'Currency',
      filterValue: 'currency',
      componentType: QueryComponents.CURRENCY,
      display: currencies.length > 0
    },
    {
      filterName: 'Amount',
      filterValue: 'invoicedAmount',
      componentType: QueryComponents.NUMBER
    },
    {
      filterName: 'Payment Reference',
      filterValue: 'paymentReferences',
      componentType: QueryComponents.TEXT
    }
  ];

  const determineHeaders: () => DataGridHeaderItem[] = () => {
    switch (programType) {
      case ProgramType.OPEN_ACCOUNT:
        return invoiceLedgerOAHeaders;
      case ProgramType.OA_EXTERNAL_FUNDING:
        return invoiceLedgerOAExternalFundingHeaders;
      case ProgramType.OA_FUNDING:
        return invoiceLedgerOAFundingHeaders;
      default:
        return invoiceLedgerFundedHeaders;
    }
  };

  const funderName = programs
    .find(
      (program) =>
        program.baseType.includes(ProgramType.SP_FUNDING) ||
        program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    )
    ?.participants.find((p) => p.role === 'FUNDER')?.registeredName;

  const buyerName = programs
    .find(
      (program) =>
        program.baseType.includes(ProgramType.SP_FUNDING) ||
        program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    )
    ?.participants.find((p) => p.role === 'BUYER')?.registeredName;

  useEffect(() => {
    const hydratedQueryItems = applyQueryParamFilters(queryItems);
    setQuerySet(hydratedQueryItems);
  }, []);

  useEffect(() => {
    if (allProgramsWithDetails && !initialise) {
      setInitialise(true);
      getInvoiceLedger(true);
      getInvoicesByEligibilityStatus();
    }
  }, [allProgramsWithDetails]);

  useEffect(() => {
    if (!ledgerLoading && !appLoading) {
      if (programFunding) set12WeeksData();
      setLoading(false);
      setLedgerInvoices(mapInvoicesToViewModel(invoiceLedger));
    }
  }, [invoiceLedger, invoicesByEligibility]);

  useEffect(() => {
    if (initialise) {
      getInvoiceLedger();
    }
  }, [rowsPerPage, page, activeQueries, orderBy]);

  useEffect(() => {
    if (programFunding)
      subsetInvoices.length > 0
        ? setLedgerInvoices(mapInvoicesToViewModel(subsetInvoices))
        : getInvoiceLedger();
  }, [subsetInvoices]);

  const getInvoicesByEligibilityStatus: () => void = async () => {
    const programIds = allProgramsWithDetails.map((p) => p.id);
    const invoiceResponse = await invoiceApi.getInvoicesByEligibilityStatus(
      ['ELIGIBLE', 'INELIGIBLE'],
      programIds,
      twelveWeeksFromEndOfCurrentWeek()
    );

    invoiceResponse && setInvoicesByEligibility(invoiceResponse?.data?.value);
  };

  const isTypeInActiveQueries: (field: string) => boolean = (field) => {
    return activeQueries.find((query) => query.fieldValue.includes(field)) !== undefined;
  };

  const getInvoiceLedger: (initialLoading?: boolean) => void = async (initialLoading = false) => {
    initialLoading ? setInvoiceInitialLoading(true) : setInvoiceLoading(true);
    const programIds = allProgramsWithDetails.map((p) => p.id);
    const invoiceStatus = searchParams.get('invoiceStatus');
    const matchingStatus = searchParams.get('matchingStatus');
    const paramCurrency = searchParams.get('currency');

    let filterSearch = ``;

    if (invoiceStatus && !isTypeInActiveQueries('invoiceStatus') && activeQueries.length > 0) {
      filterSearch += ` and (linkedPrograms/any(f: f/invoiceStatus eq '${invoiceStatus}'))`;
    }

    if (matchingStatus && !isTypeInActiveQueries('matchingStatus') && activeQueries.length > 0) {
      filterSearch += ` and (linkedPrograms/any(f: f/matchingStatus eq '${matchingStatus}'))`;
    }

    if (paramCurrency && !isTypeInActiveQueries('currency') && activeQueries.length > 0) {
      filterSearch += ` and (data/currency eq '${paramCurrency}')`;
    }

    if (searchTerm.length > 0) {
      filterSearch += `and (contains(invoiceNumber,'${searchTerm}') or contains(buyerRef,'${searchTerm}'))`;
    }

    if (activeQueries.length > 0) {
      setShowAdvancedSearch(true);

      activeQueries.forEach((q: QueryRow) => {
        if (q.fieldValue === 'invoiceStatus') {
          filterSearch += ` and (linkedPrograms/any(f: f/invoiceStatus ${q?.comparator} '${q?.queryValue}'))`;
        } else if (q.fieldValue === 'matchingStatus') {
          filterSearch += `and linkedPrograms/any(f:f/matchingStatus ${q?.comparator} '${q?.queryValue}')`;
        } else if (q.fieldValue === 'invoiceDate') {
          filterSearch += ` and ((createdAt ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (createdAt le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          )
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'buyer') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/buyerName, '${q.queryValue}')`
              : `and (data/buyerName ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'currency') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/currency, '${q.queryValue}')`
              : `and (data/currency ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'paymentDueDate') {
          filterSearch += ` and ((data/paymentDueDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (data/paymentDueDate le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          )
            .plus({ days: 1 })
            .toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'maturityDate') {
          filterSearch += ` and ((maturityDate ge ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.from as Date
          ).toISO({
            includeOffset: false
          })}Z) and (maturityDate le ${DateTime.fromJSDate(
            (q.queryValue as QueryDateRange)?.to as Date
          ).toISO({ includeOffset: false })}Z))`;
        } else if (q.fieldValue === 'invoiceNumber') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/invoiceNumber, '${q.queryValue}')`
              : `and (data/invoiceNumber ${q.comparator} '${q.queryValue}')`;
        } else if (q.fieldValue === 'invoicedAmount') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and contains(data/amount, ${q.queryValue})`
              : `and (data/amount ${q.comparator} ${q.queryValue})`;
        } else if (q.fieldValue === 'paymentReferences') {
          filterSearch +=
            q.comparator === 'ct'
              ? `and (linkedPayments/any(f: f/paymentReferences/any(p: contains(p, '${q.queryValue}'))))`
              : ` and (linkedPayments/any(f: f/paymentReferences/any(p: p ${q.comparator} '${q.queryValue}')))`;
        }
      });
    }

    const invoiceResponse = await invoiceApi.getFilterInvoiceLedger(
      programIds,
      rowsPerPage,
      page,
      filterSearch,
      orderBy
    );

    const invoiceResponseAll = await invoiceApi.getFilterInvoiceLedger(
      programIds || '',
      null,
      null,
      filterSearch,
      orderBy
    );

    setExportData(mapInvoicesToViewModel(invoiceResponseAll?.data?.value || []));

    invoiceResponse && setInvoiceLedger(invoiceResponse?.data?.value);
    setInvoiceLedgerCount(invoiceResponse?.data['@odata.count'] || 0);

    initialLoading ? setInvoiceInitialLoading(false) : setInvoiceLoading(false);
  };

  const advancedSearchClickHandler: () => void = () => setShowAdvancedSearch(!showAdvancedSearch);

  const applyQueryParamFilters: (queryItems: QueryItem[]) => QueryItem[] = (queryItems) => {
    const queryParams = new URLSearchParams(window.location.search);
    return [...queryItems].map((queryItem) => {
      const presetValue: string = queryParams.get(queryItem.filterValue) || '';
      return presetValue
        ? {
            ...queryItem,
            defaultComparator: 'eq',
            defaultValue: presetValue.toUpperCase()
          }
        : queryItem;
    });
  };

  const columnSortChangeClickHandler = (col: any) => {
    const odataField: string | undefined = fieldDictionary[col];
    const sortable = Boolean(odataField);
    if (!sortable) return;
    const direction = orderDirection === 'asc' ? 'desc' : 'asc';
    setOrderDirection(direction);
    setOrderBy(`${fieldDictionary[col]} ${direction}`);
  };

  const twelveWeeksFromEndOfCurrentWeek: () => string = () => {
    const weeksAhead: number = 12;
    const currentWeekNumber: number = DateTime.utc().weekNumber;
    const currentYearNumber: number = DateTime.utc().year;
    const futureDate: string = DateTime.fromObject({
      // adjusts the year based on if the week number should roll into following year
      weekYear: currentWeekNumber + weeksAhead > 52 ? currentYearNumber + 1 : currentYearNumber,
      // adjusts the week number to set the correct week number if the year rolls into a new year
      weekNumber:
        currentWeekNumber + weeksAhead > 52
          ? currentWeekNumber + weeksAhead - 52
          : currentWeekNumber + weeksAhead
    }).toISO();
    return DateTime.fromISO(futureDate, { zone: 'utc' }).toISO();
  };

  const mapInvoicesToViewModel: (data: ILedgerInvoice[]) => DataGridRowItem[] = (data) =>
    data.map((d) => {
      const {
        invoiceNumber,
        buyerRef,
        createdAt,
        data,
        invoiceCurrency,
        invoiceId,
        linkedPrograms,
        maturityDate,
        netAmount,
        linkedPayments
      } = d;

      return {
        invoiceId,
        invoiceNumber,
        buyerName: buyerRef,
        matchingStatus:
          linkedPrograms.find((program) => program.baseType.includes(ProgramType.OPEN_ACCOUNT))
            ?.matchingStatus || '',
        invoiceDate: formatDateTime(createdAt, _DATE_FORMAT),
        currency: invoiceCurrency,
        invoiceStatus:
          linkedPrograms.length === 1
            ? linkedPrograms[0].invoiceStatus
            : linkedPrograms.find((program) => !program.baseType.includes(ProgramType.OPEN_ACCOUNT))
                ?.invoiceStatus || '',
        invoiceAmount: formatNumber(data.amount || 0, 2),
        dueDate: formatDateTime(data.paymentDueDate || '', _DATE_FORMAT),
        maturityDate: formatDateTime(
          DateTime.fromFormat(maturityDate || '', _DATE_FORMAT).toISO(),
          _DATE_FORMAT
        ),
        selected: true,
        paymentId:
          linkedPayments && linkedPayments[0] ? linkedPayments[0].paymentReferences[0] : '-',
        netAmount: formatNumber(netAmount || 0, 2)
      };
    });

  const changeHandler: (value: ILedgerInvoice[]) => void = (value) => {
    setSubsetInvoices(value);
    setInvoiceLedgerCount(value.length);
  };

  const set12WeeksData: () => void = () => {
    setLoading(true);
    let twelveWeekLabels: string[] = [];
    const dataObj: TwelveWeekData = {};
    const currentWeekNumber: number = DateTime.utc().weekNumber;
    const currentYearNumber: number = DateTime.utc().year;
    // the date of the Sunday from todays date.
    let count: number = 0;
    do {
      const dt: DateTime = DateTime.fromObject({
        // adjusts the year based on if the week number should roll into following year
        weekYear: currentWeekNumber + count > 52 ? currentYearNumber + 1 : currentYearNumber,
        // adjusts the week number to set the correct week number if the year rolls into a new year
        weekNumber:
          currentWeekNumber + count > 52
            ? currentWeekNumber + count - 52
            : currentWeekNumber + count
      });
      const previousWeek: DateTime = DateTime.fromObject({
        // adjusts the year based on if the week number should roll into following year
        weekYear: currentWeekNumber - 1 + count > 52 ? currentYearNumber + 1 : currentYearNumber,
        // adjusts the week number to set the correct week number if the year rolls into a new year
        weekNumber:
          currentWeekNumber - 1 + count > 52
            ? currentWeekNumber - 1 + count - 52
            : currentWeekNumber - 1 + count
      }).endOf('week');
      const endOfCurrentWeek: string = dt.endOf('week').toFormat('MMM dd');

      const dataWithinWeekEnd = invoicesByEligibility.filter(
        (invoice) =>
          DateTime.fromISO(invoice.data.paymentDueDate || '') < dt.endOf('week') &&
          DateTime.fromISO(invoice.data.paymentDueDate || '') > previousWeek
      );

      const eligibileDataWithinWeekEnd = dataWithinWeekEnd
        .filter((d) => d.linkedProgram.isEligible)
        .map((item) => item.data.amount)
        .reduce((val, acc) => (val || 0) + (acc || 0), 0);
      const ineligibileDataWithinWeekEnd = dataWithinWeekEnd
        .filter((d) => !d.linkedProgram.isEligible)
        .map((item) => item.data.amount)
        .reduce((val, acc) => (val || 0) + (acc || 0), 0);

      twelveWeekLabels.push(endOfCurrentWeek);
      (dataObj[endOfCurrentWeek] as any) = {
        eligible: eligibileDataWithinWeekEnd,
        ineligible: ineligibileDataWithinWeekEnd,
        invoices: {
          eligible: dataWithinWeekEnd.filter((d) => d.linkedProgram.isEligible),
          ineligible: dataWithinWeekEnd.filter((d) => !d.linkedProgram.isEligible)
        }
      };
      count += 1;
    } while (count < 12);
    setData(dataObj);
    setChartDataset(dataObj);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const setBackgroundColors: (value: string, data: TwelveWeekData) => string[] = (value, data) => [
    ...Array(Object.keys(data).length).fill(value)
  ];

  const setChartDataset: (data: TwelveWeekData) => void = (data) => {
    const chartDataset: BarChartDataSet[] = [
      new BarChartDataSet(
        'Available for early payment',
        valueArr(data, 'eligible'),
        setBackgroundColors(themeColors.chart.light.colour1, data),
        39,
        {
          data,
          dataStatus: 'eligible'
        }
      ),
      new BarChartDataSet(
        'Unavailable for early payment',
        valueArr(data, 'ineligible'),
        setBackgroundColors(themeColors.chart.light.colour4, data),
        39,
        {
          data,
          dataStatus: 'ineligible'
        }
      )
    ];
    setChartData(chartDataset);
  };

  const valueArr: (dataObj: any, key: string) => number[] = (dataObj, key) => {
    return Object.keys(dataObj).map((o) => dataObj[o][key]);
  };

  const renderLoadingView: () => JSX.Element = () => <LoaderFullPage />;

  const rowClickHandler: (row: any) => void = (row) => {
    let data: any = isMobile() ? row : row[0];
    navigate(`/invoices/${data}`);
  };

  const searchHandler: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void = (event) => {
    const inputValue = event.currentTarget.value;
    setSearchTerm(inputValue);
  };

  const searchBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue === '') {
      getInvoiceLedger();
      setPage(0);
    }
  };

  const searchHandleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void = (event) => {
    if (event.key === 'Enter') {
      getInvoiceLedger();
      setPage(0);
    }
  };

  const clickSearchHandle: () => void = () => {
    getInvoiceLedger();
    setPage(0);
  };

  const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
    setPage(0);
  };

  const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) => {
    setPage(tableState.page);
  };

  const changePage: (page: number) => void = (page) => {
    setPage(page);
  };

  const renderTable: () => JSX.Element = () =>
    isMobile() ? (
      <InvoicesViewMobile
        invoices={ledgerInvoices}
        rowClickHandler={rowClickHandler}
        totalRows={invoiceLedgerCount}
        page={page}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        rowsPerPageHandler={rowsPerPageHandler}
      />
    ) : (
      <DataGrid
        headers={determineHeaders()}
        data={ledgerInvoices}
        exportData={exportData}
        ariaLabel={''}
        ariaCaption={''}
        fixedHeader
        loading={invoiceLoading}
        rowClickHandler={rowClickHandler}
        tableChangeHandler={tableChangeHandler}
        columnSortChangeClickHandler={columnSortChangeClickHandler}
        hasActions
        exportFilename="invoice_ledger"
        smallDownloadButton={false}
        dataAutomationTag="invoice-ledger-table-ledger-items"
        page={page === -1 ? 0 : page}
        rowsPerPage={rowsPerPage}
        totalRows={invoiceLedgerCount}
        changeRowsPerPageHandler={rowsPerPageHandler}
        showFooter={invoiceLedgerCount > 15}
      />
    );

  const activeQueriesHandler: (queries: QueryRow[]) => void = (queries) => {
    const clone = [...queries];

    const isUpdated =
      clone.length === activeQueries.length &&
      clone.some((cloneValue) => {
        return activeQueries.some(
          (activeValue) =>
            cloneValue.fieldValue === activeValue.fieldValue &&
            cloneValue.queryValue === activeValue.queryValue
        );
      });

    if (isUpdated || activeQueries.length !== clone.length) {
      setActiveQueries(clone);
      setPage(0);
    }
  };

  return !loading && !ledgerLoading && !appLoading && !invoiceInitialLoading ? (
    <>
      {programType === ProgramType.OA_EXTERNAL_FUNDING && (
        <LayoutViewContainer size="xlarge" paddingBottom="16px">
          <Notification
            backgroundColor={themeColors.bg.infoMuted}
            noShadow
            color={themeColors.text.primary}
            icon={<InfoIcon />}
            description={`All your approved invoices are managed by ${funderName}.
        Please contact ${buyerName} or ${funderName} for further information.`}
          />
        </LayoutViewContainer>
      )}

      <LayoutViewContainer size="xlarge">
        {programFunding && <EarlyPaymentBanner />}
        <BaseCard noBorder noBoxShadow>
          <InvoicesViewTitleRow>
            <InvoicesPageTitle data-automation-id="invoice-ledger-title">
              Invoices
            </InvoicesPageTitle>
          </InvoicesViewTitleRow>
          {programFunding && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BarChart
                title="12 week payment forecast"
                changeHandler={changeHandler}
                labels={Object.keys(data)}
                datasets={chartData}
                showRestart
              />
            </div>
          )}
          <SearchContainer>
            <SearchLeftContainer>
              <OutlinedInput
                id="outlined-adornment-search"
                dataAutomationId="invoice-ledger-input-fuzzy-search"
                dataTestId="sp-invoice-ledger-input-fuzzy-search"
                defaultValue={searchTerm}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => searchBlurHandler(event)}
                changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                  searchHandler(event)
                }
                handleKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
                  searchHandleKeyDown(event)
                }
                placeholder="Search by invoice number and buyer"
              />
              <PrimaryButton
                clickHandler={clickSearchHandle}
                disabled={searchTerm === '' || searchTerm.length < 3}
                height="40px"
                width="40px"
              >
                <InvoiceLedgerFilterButtonTextWrapper>
                  <SearchIcon />
                </InvoiceLedgerFilterButtonTextWrapper>
              </PrimaryButton>
              <SearchAdvancedText
                onClick={advancedSearchClickHandler}
                data-automation-id="ledger-items-advanced-search-text"
                data-testid="sp-advanced-search"
              >
                {showAdvancedSearch
                  ? 'Hide advanced search'
                  : `Advanced search ${
                      activeQueries.length > 0 ? `(${activeQueries.length})` : ''
                    }`}
              </SearchAdvancedText>
            </SearchLeftContainer>
          </SearchContainer>
          <QueryBuilder
            queryItems={querySet}
            activeQueriesHandler={activeQueriesHandler}
            closeClickHandler={advancedSearchClickHandler}
            open={showAdvancedSearch}
            testingTagPage="invoices"
            currencies={currencies}
          />
          {!showAdvancedSearch && <Divider />}
          {renderTable()}
        </BaseCard>
      </LayoutViewContainer>
    </>
  ) : (
    renderLoadingView()
  );
};

export default Invoices;
