/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from 'assets/icons/CheckIcon';
import ClearIcon from 'assets/icons/ClearIcon';
import FileUploadIcon from 'assets/icons/FileUploadIcon';
import ApproveIcon from 'assets/icons/approve/ApproveIcon';
import Bin from 'assets/icons/delete/Bin/Bin';
import { themeColors } from 'assets/theme/style';
import { fontWeights } from 'assets/theme/typography';
import { convertNumberToShortStringFileSize } from 'lib/helpers/formatters/convertNumberToShortStringFileSize';
import Papa from 'papaparse';
import { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { PrimaryButton } from '../buttons';
import LoaderInPage from '../loader/LoaderInPage';
import ErrorMessageText from '../typography';
import {
  FileUploadButtonContainer,
  FileUploadButtonText,
  FileUploadContainer,
  FileUploadIconContainer,
  FileUploadSelectedSimpleContainer,
  FileUploadSelectedSimpleParagragh,
  FileUploadSelectedSimpleParagraghInfo,
  FileUploadSelectedSimpleRow,
  FileUploadSelectedSimpleSegment,
  FileUploadSelectedSimpleTitle,
  FileUploadText,
  SimpleFileUploadContainer,
  SimpleFileUploadDescription,
  SimpleFileUploadLink,
  SimpleFileUploadText,
  UploaderContainer,
  UploaderFilesDeleteIconContainer,
  UploaderFilesFilename,
  UploaderFilesIconContainer,
  UploaderFilesListContainer,
  UploaderFilesListItem,
  UploaderFilesListItemLeftSegment
} from './styled';

interface UploaderProps {
  fileDropHandler: (acceptedFile: File[]) => void;
  singlePreviewMode?: boolean;
  limitBytes?: number;
  preloaded?: string;
  preloadedBlob?: File | Blob;
  acceptedFileTypes?: string[];
  testingTagPage?: string;
  borderColor?: string;
  title?: string;
  multiple?: boolean;
  selectedSimple?: boolean;
  csvHeaderValidationFields?: string[];
  validationFeedback?: IUploaderLineValidationMessage[];
  submitting?: number[];
  isInvalidCallback?: (isInvalid: boolean) => void;
}

export interface IUploaderFile extends File {
  validationError?: JSX.Element;
  parsing?: boolean;
  parsedCSV?: any;
  postModel?: any;
  success?: boolean;
}

export interface IUploaderLineValidationMessage {
  index: number;
  jsx: JSX.Element | null;
}

const _IMAGE_TYPES: string[] = ['jpg', 'jpeg', 'png'];

const Uploader: FC<UploaderProps> = ({
  fileDropHandler,
  singlePreviewMode,
  limitBytes = 5000000,
  preloaded,
  preloadedBlob,
  acceptedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'],
  testingTagPage,
  borderColor,
  title = 'Drag and drop file here',
  multiple,
  selectedSimple,
  csvHeaderValidationFields,
  validationFeedback,
  submitting,
  isInvalidCallback
}) => {
  const [uploaded, setUploaded] = useState<IUploaderFile[]>([]);
  const [uploaderValidationFeedback, setUploaderValidationFeedback] = useState<
    IUploaderLineValidationMessage[]
  >([]);

  useEffect(() => {
    if (preloadedBlob) setUploaded([preloadedBlob as File]);
  }, [preloadedBlob]);

  useEffect(() => {
    if (isInvalidCallback)
      isInvalidCallback(
        (validationFeedback && validationFeedback.length > 0) ||
          (uploaderValidationFeedback && uploaderValidationFeedback.length > 0)
      );
  }, [validationFeedback, uploaderValidationFeedback]);

  const fileUploadHandler: (acceptedFiles: File[]) => void = (acceptedFiles) => {
    if (singlePreviewMode && acceptedFiles[0].size > limitBytes)
      return alert(`File must be less than ${limitBytes / 1000}KB`);
    if (singlePreviewMode && acceptedFileTypes.indexOf(acceptedFiles[0].type.split('/')[1]) < 0) {
      return alert(`File must be one of the following formats: ${acceptedFileTypes.join(' | ')}`);
    }
    setUploaded([...uploaded, ...acceptedFiles]);
    fileDropHandler([...uploaded, ...acceptedFiles]);
  };

  const multipleFileUploadHandler: (acceptedFiles: IUploaderFile[]) => void = async (
    acceptedFiles
  ) => {
    const mappedFiles: IUploaderFile[] = (await Promise.all(
      acceptedFiles.map((af, index) => {
        return new Promise((resolve, rej) => {
          af.parsing = true;
          if (af.size > limitBytes)
            setUploaderValidationFeedback((f) => [
              ...f,
              {
                index,
                jsx: (
                  <ErrorMessageText
                    text={`File (${af.name}) must be less than ${convertNumberToShortStringFileSize(
                      limitBytes,
                      1
                    )}`}
                  />
                )
              }
            ]);

          if (acceptedFileTypes.indexOf(af.type.split('/')[1]) < 0)
            setUploaderValidationFeedback((f) => [
              ...f,
              {
                index,
                jsx: (
                  <ErrorMessageText
                    text={`File (${
                      af.name
                    }) must be one of the following formats: ${acceptedFileTypes.join(' | ')}`}
                  />
                )
              }
            ]);

          if (af.type.split('/')[1] === 'csv' && csvHeaderValidationFields?.length) {
            const commonConfig: Papa.ParseLocalConfig<any, Papa.LocalFile> = {
              header: true,
              skipEmptyLines: true,
              delimitersToGuess: [',', ';'],
              worker: true,
              complete: (result: any) => {
                const fieldErrors: string[] = [];
                const metadata = result.meta.fields as string[];

                if (result?.data?.length !== 0) {
                  csvHeaderValidationFields?.forEach((field) => {
                    if (!metadata.includes(field)) fieldErrors.push(field);
                  });

                  if (fieldErrors.length > 0) {
                    setUploaderValidationFeedback((f) => [
                      ...f,
                      {
                        index,
                        jsx: (
                          <ErrorMessageText
                            text={`The following are mandatory fields that are missing from the csv: ${fieldErrors.join(
                              ' | '
                            )}`}
                          />
                        )
                      }
                    ]);
                  }
                } else {
                  setUploaderValidationFeedback((f) => [
                    ...f,
                    {
                      index,
                      jsx: <ErrorMessageText text="There is no data in the CSV." />
                    }
                  ]);
                }

                af.parsedCSV = result;
                af.parsing = false;
                resolve(af);
              }
            };
            Papa.parse(af, commonConfig);
          }

          if (af.type.split('/')[1] !== 'csv') {
            af.parsing = false;
            resolve(af);
          }
        });
      })
    )) as unknown as IUploaderFile[];

    if (!multiple && mappedFiles.filter((file) => Boolean(file.validationError))) {
      alert(mappedFiles.map((f) => f.validationError).join(' | '));
      return;
    }

    setUploaded([...uploaded, ...mappedFiles]);

    fileDropHandler([...uploaded, ...mappedFiles]);
  };

  const isImage: (file: File) => boolean = (file: File) =>
    _IMAGE_TYPES.indexOf(file.type.split('/')[1]) > 0;

  const removeFileHandler: (index: number) => void = (index) => {
    const mutArr: File[] = uploaded.slice();
    const mutArrValidation: IUploaderLineValidationMessage[] = uploaderValidationFeedback.slice();
    mutArr.splice(index, 1);
    mutArrValidation.splice(index, 1);
    setUploaded(mutArr);
    setUploaderValidationFeedback(mutArrValidation);
    fileDropHandler(mutArr);
  };

  const renderDropzone: () => boolean = () =>
    (singlePreviewMode && !!!uploaded.length && !!!preloaded) || !singlePreviewMode;

  return (
    <UploaderContainer data-automation-id={`${testingTagPage}-div-file-upload`}>
      {renderDropzone() && !selectedSimple && (
        <Dropzone
          onDrop={(acceptedFiles: File[]) =>
            !multiple ? fileUploadHandler(acceptedFiles) : multipleFileUploadHandler(acceptedFiles)
          }
        >
          {({ getRootProps, getInputProps }: any) => (
            <FileUploadContainer {...getRootProps()} borderColor={borderColor}>
              <FileUploadIconContainer>
                <FileUploadIcon />
              </FileUploadIconContainer>
              <FileUploadText
                data-automation-id={`${testingTagPage}-h5-file-upload-text-drag-and-drop-file-here`}
              >
                {title}
              </FileUploadText>
              <FileUploadButtonContainer>
                <input {...getInputProps()} data-testid="drop-input" />
                <PrimaryButton
                  clickHandler={(e) => e.preventDefault()}
                  testingTag={`${testingTagPage}-button-file-upload-choose-file`}
                  text={`Choose file${multiple ? 's' : ''}`}
                />
              </FileUploadButtonContainer>
              <p style={{ color: themeColors.text.light.subtle }}>
                <span style={{ fontWeight: 600 }}>Max file size:</span> {limitBytes / 1000000}MB
              </p>
              <p style={{ color: themeColors.text.light.subtle }}>
                <span style={{ fontWeight: 600 }}>Allowed formats:</span>{' '}
                {acceptedFileTypes.join(', ')}
              </p>
            </FileUploadContainer>
          )}
        </Dropzone>
      )}
      {renderDropzone() && selectedSimple && (
        <Dropzone
          onDrop={(acceptedFiles: File[]) =>
            !multiple ? fileUploadHandler(acceptedFiles) : multipleFileUploadHandler(acceptedFiles)
          }
        >
          {({ getRootProps, getInputProps }: any) => (
            <>
              <SimpleFileUploadContainer {...getRootProps()} borderColor={borderColor}>
                <SimpleFileUploadText
                  data-automation-id={`${testingTagPage}-h5-file-upload-text-drag-and-drop-file-here`}
                >
                  <SimpleFileUploadLink>Upload a file</SimpleFileUploadLink> or drag and drop
                </SimpleFileUploadText>
                <SimpleFileUploadDescription>
                  Only ({acceptedFileTypes.join(', ')}) files are currently supported with a max
                  file size of {convertNumberToShortStringFileSize(limitBytes, 1)}
                </SimpleFileUploadDescription>
                <input {...getInputProps()} data-testid="drop-input" />
                <PrimaryButton text="Browse to upload" clickHandler={(e) => e.preventDefault()} />
              </SimpleFileUploadContainer>
            </>
          )}
        </Dropzone>
      )}
      {((singlePreviewMode && !!uploaded.length && isImage(uploaded[0])) ||
        (preloaded && singlePreviewMode)) && (
        <img
          height={85}
          style={{ objectFit: 'contain', objectPosition: 'left' }}
          src={!preloaded ? URL.createObjectURL(uploaded[0]) : preloaded}
          alt=""
          data-automation-id={`${testingTagPage}-img-file-upload-image`}
          data-testid="sp-upload-image"
        />
      )}
      {!selectedSimple && (
        <UploaderFilesListContainer>
          {uploaded.map(({ name, validationError }, index) => (
            <FileUploadSelectedSimpleContainer key={index}>
              <UploaderFilesListItem>
                <UploaderFilesListItemLeftSegment>
                  <UploaderFilesIconContainer>
                    <ApproveIcon color={themeColors.icon.success} height="15" width="15" />
                  </UploaderFilesIconContainer>
                  <UploaderFilesFilename
                    data-automation-id={`${testingTagPage}-p-file-upload-file-name`}
                  >
                    {name}
                  </UploaderFilesFilename>
                </UploaderFilesListItemLeftSegment>
                <UploaderFilesDeleteIconContainer
                  onClick={() => removeFileHandler(index)}
                  data-testid="sp-delete-file"
                >
                  <Bin />
                </UploaderFilesDeleteIconContainer>
                {validationError && validationError}
              </UploaderFilesListItem>
            </FileUploadSelectedSimpleContainer>
          ))}
        </UploaderFilesListContainer>
      )}
      {selectedSimple && uploaded.length > 0 && (
        <FileUploadSelectedSimpleContainer>
          <FileUploadSelectedSimpleTitle>Documents</FileUploadSelectedSimpleTitle>
          {uploaded.map((u, index) => (
            <FileUploadSelectedSimpleContainer key={index}>
              <FileUploadSelectedSimpleRow>
                <FileUploadSelectedSimpleParagragh>{u.name}</FileUploadSelectedSimpleParagragh>
                {submitting && submitting.indexOf(index) > -1 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      alignItems: 'center'
                    }}
                  >
                    <LoaderInPage height="2vh" />
                    <FileUploadSelectedSimpleParagraghInfo>
                      Validating file...
                    </FileUploadSelectedSimpleParagraghInfo>
                  </div>
                )}
                {u.parsing && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      alignItems: 'center'
                    }}
                  >
                    <LoaderInPage height="2vh" />
                    <FileUploadSelectedSimpleParagraghInfo>
                      Processing file...
                    </FileUploadSelectedSimpleParagraghInfo>
                  </div>
                )}
                {!u.success && (
                  <FileUploadSelectedSimpleSegment>
                    <FileUploadSelectedSimpleParagraghInfo>
                      {convertNumberToShortStringFileSize(u.size, 1)}
                    </FileUploadSelectedSimpleParagraghInfo>
                    <span style={{ cursor: 'pointer' }} onClick={() => removeFileHandler(index)}>
                      <ClearIcon
                        color={
                          Boolean(validationFeedback?.find((f) => f.index === index))
                            ? themeColors.icon.error
                            : themeColors.icon.light.muted
                        }
                        height="14"
                        width="14"
                      />
                    </span>
                  </FileUploadSelectedSimpleSegment>
                )}
                {u.success && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      alignItems: 'center'
                    }}
                  >
                    <FileUploadSelectedSimpleParagraghInfo>
                      Successfully uploaded file
                    </FileUploadSelectedSimpleParagraghInfo>
                    <CheckIcon height="14" width="14" color={themeColors.icon.success} />
                  </div>
                )}
              </FileUploadSelectedSimpleRow>
              {validationFeedback && validationFeedback.find((f) => f.index === index)?.jsx}
              {uploaderValidationFeedback &&
                uploaderValidationFeedback.find((f) => f.index === index)?.jsx}
            </FileUploadSelectedSimpleContainer>
          ))}
        </FileUploadSelectedSimpleContainer>
      )}
    </UploaderContainer>
  );
};

export default Uploader;
