import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import EntityDetails from 'components/EntityDetails';
import { DELETE_ENTITY_DETAILS } from 'store/actions';
import { useDispatch } from 'react-redux';
import { FC } from 'react';
export interface FullViewEntityProps {
  entityOpen: boolean;
  entityType?: 'buyer' | 'funder';
}

const FullViewEntity: FC<FullViewEntityProps> = ({ entityOpen, entityType }) => {
  const dispatch = useDispatch();

  const closeFullViewDialog: () => void = () => {
    dispatch({ type: DELETE_ENTITY_DETAILS });
  };

  const getComponent: (type: string | undefined) => JSX.Element = (type) => {
    switch (type) {
      case 'buyer':
        return <EntityDetails />;
      case 'funder':
        return <EntityDetails />;
      default:
        return <EntityDetails />;
    }
  };

  return (
    <FullViewDialog
      open={entityOpen}
      clickHandler={closeFullViewDialog}
      dialogContent={getComponent(entityType)}
    />
  );
};

export default FullViewEntity;
