import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';
import { themeColors } from 'assets/theme/style';

export const PrimaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap }) => gap};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};

  &:hover {
    background-color: ${themeColors.bg.primaryHover};
  }
`;
