/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { IAttachment } from 'api/interfaces/attachment/attachment.interface';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerLogistic } from 'api/interfaces/ledger/ledgerLogistic.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { LedgerLineItem } from 'api/interfaces/line-item/lineItem';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { POST_INVOICE_COMMENTS, POST_LOGISTIC_COMMENTS, POST_PO_COMMENTS } from 'store/actions';
import { CommentPostModel } from 'utils/classes/open-account/comment/comment';
import LedgerDetailAdditionalInformation from './LedgerDetailAdditionalInformation';
import LedgerDetailAdjustments from './LedgerDetailAdjustments';
import LedgerDetailAttachments from './LedgerDetailAttachments';
import { ILedgerDetailAttachmentsProps } from './LedgerDetailAttachments/LedgerDetailAttachments';
import LedgerDetailComments from './LedgerDetailComments';
import LedgerDetailAddComment from './LedgerDetailComments/LedgerDetailAddComment';
import LedgerDetailViewComments from './LedgerDetailComments/LedgerDetailViewComments';
import LedgerDetailEventHistory from './LedgerDetailEventHistory';
import LedgerDetailLineItems from './LedgerDetailLineItems';
import LedgerDetailLogistic from './LedgerDetailLogistic';
import LedgerFeesAndCharges from './LedgerFeesAndCharges';
import {
  AccordionStyled,
  LedgerDetailAccordionContainer,
  LedgerDetailAccordionTitle,
  LedgerDetailAccordionTitleRight,
  LedgerDetailAccordionTitleWrapper
} from './styled';

interface LedgerDetailAccordionProps extends ILedgerDetailAttachmentsProps {
  lineItems: LedgerLineItem[];
  logistics: LedgerLogistics | null;
  currencyCode: string;
  comments: IComment[];
  type: keyof typeof AssetEnum;
  assetId: string;
  matchingFees: number | null;
  attachments: IAttachment[];
  eventHistory: any[];
  ledgerItem?: ILedgerInvoice | ILedgerPurchaseOrder | ILedgerLogistic | null;
}

const LedgerDetailAccordion: FC<LedgerDetailAccordionProps> = ({
  type,
  assetId,
  lineItems,
  logistics,
  comments,
  currencyCode,
  matchingFees,
  uploadAttachmentButtonClickHandler,
  attachments,
  eventHistory,
  ledgerItem
}) => {
  const dispatch = useDispatch();
  const [addCommentDialogOpen, setAddCommentDialogOpen] = useState<boolean>(false);
  const [viewCommentsDialogOpen, setViewCommentsDialogOpen] = useState<boolean>(false);
  const [commentsToDisplay, setCommentsToDisplay] = useState<IComment[]>([]);
  const {
    invoiceComments,
    invoiceCommentsLoading
  }: { invoiceComments: IComment[]; invoiceCommentsLoading: boolean } = useSelector(
    (state: RootStateOrAny) => state.invoice
  );
  const { poComments, poCommentsLoading }: { poComments: IComment[]; poCommentsLoading: boolean } =
    useSelector((state: RootStateOrAny) => state.purchaseOrder);

  const {
    logisticComments,
    logisticCommentsLoading
  }: { logisticComments: IComment[]; logisticCommentsLoading: boolean } = useSelector(
    (state: RootStateOrAny) => state.logistic
  );

  useEffect(() => {
    if (type === AssetEnum.INVOICE && invoiceComments?.length > 0) {
      setCommentsToDisplay(invoiceComments);
      return;
    }
    if (type === AssetEnum.PURCHASE_ORDER && poComments?.length > 0) {
      setCommentsToDisplay(poComments);
      return;
    }
    if (type === AssetEnum.LOGISTIC && logisticComments?.length > 0) {
      setCommentsToDisplay(logisticComments);
      return;
    }
  }, [invoiceComments, poComments, logisticComments]);

  const addCommentClickHandler: () => void = () => {
    setAddCommentDialogOpen(true);
    closeViewCommentsFullViewDialogHandler();
  };

  const viewCommentsClickHandler: () => void = () => {
    setViewCommentsDialogOpen(true);
    closeAddCommentFullViewDialogHandler();
  };

  const closeAddCommentFullViewDialogHandler: () => void = () => {
    setAddCommentDialogOpen(false);
  };

  const closeViewCommentsFullViewDialogHandler: () => void = () => {
    setViewCommentsDialogOpen(false);
  };

  const addCommentSubmitClickHandler: (comment: string) => void = (comment) => {
    switch (type) {
      case AssetEnum.INVOICE:
        dispatch({
          type: POST_INVOICE_COMMENTS,
          payload: { invoiceId: assetId, data: new CommentPostModel(comment) }
        });
        closeAddCommentFullViewDialogHandler();
        break;
      case AssetEnum.PURCHASE_ORDER:
        dispatch({
          type: POST_PO_COMMENTS,
          payload: { purchaseOrderId: assetId, data: new CommentPostModel(comment) }
        });
        closeAddCommentFullViewDialogHandler();
        break;
      case AssetEnum.LOGISTIC:
        dispatch({
          type: POST_LOGISTIC_COMMENTS,
          payload: { logisticId: assetId, data: new CommentPostModel(comment) }
        });
        closeAddCommentFullViewDialogHandler();
        break;
      default:
        break;
    }
  };

  return (
    <LedgerDetailAccordionContainer data-automation-id="ledger-detail-view-div-accordion-container">
      {lineItems && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              data-automation-id="ledger-detail-lineItems-accordion-summary"
            >
              <LedgerDetailAccordionTitleWrapper>
                <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
                  Line items
                </LedgerDetailAccordionTitle>
                <LedgerDetailAccordionTitleRight data-automation-id="ledger-detail-view-h4-accordion-value-line-items">
                  {lineItems.length} item{lineItems.length === 1 ? '' : 's'}
                </LedgerDetailAccordionTitleRight>
              </LedgerDetailAccordionTitleWrapper>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerDetailLineItems
                assetId={assetId}
                lineItems={lineItems}
                currencyCode={currencyCode}
                type={type}
              />
            </AccordionDetails>
          </AccordionStyled>
        </>
      )}
      <AccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-automation-id="ledger-detail-logistics-accordion-summary"
        >
          <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-logistics">
            Additional information
          </LedgerDetailAccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <LedgerDetailAdditionalInformation ledgerItem={ledgerItem} type={type} />
        </AccordionDetails>
      </AccordionStyled>
      {logistics && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              data-automation-id="ledger-detail-logistics-accordion-summary"
            >
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-logistics">
                Logistics
              </LedgerDetailAccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              {logistics && <LedgerDetailLogistic logistics={logistics} />}
            </AccordionDetails>
          </AccordionStyled>
        </>
      )}
      <AccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-automation-id="ledger-detail-logistics-accordion-summary"
        >
          <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-logistics">
            Documents & attachments
          </LedgerDetailAccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <LedgerDetailAttachments
            documentId={assetId}
            documentType={type}
            uploadAttachmentButtonClickHandler={uploadAttachmentButtonClickHandler}
            attachments={attachments}
          />
        </AccordionDetails>
      </AccordionStyled>
      {/* {eventHistory.length > 0 && (
        <AccordionStyled disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            data-automation-id="ledger-detail-logistics-accordion-summary"
          >
            <LedgerDetailAccordionTitleWrapper>
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-logistics">
                Event history
              </LedgerDetailAccordionTitle>
              <LedgerDetailAccordionTitleRight data-automation-id="ledger-detail-view-h4-accordion-value-line-items">
                {eventHistory.length} item{eventHistory.length === 1 ? '' : 's'}
              </LedgerDetailAccordionTitleRight>
            </LedgerDetailAccordionTitleWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <LedgerDetailEventHistory eventHistory={eventHistory} />
          </AccordionDetails>
        </AccordionStyled>
      )} */}
      {type !== AssetEnum.LOGISTIC && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              data-automation-id="ledger-detail-adjustments-accordion-summary"
            >
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-adjustments">
                Adjustments
              </LedgerDetailAccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerDetailAdjustments assetId={assetId} type={type} currency={currencyCode} />
            </AccordionDetails>
          </AccordionStyled>
        </>
      )}
      {matchingFees !== null && type !== AssetEnum.PURCHASE_ORDER && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              data-automation-id="ledger-detail-fees-accordion-summary"
            >
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-fees">
                Fees & charges
              </LedgerDetailAccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerFeesAndCharges value={matchingFees} />
            </AccordionDetails>
          </AccordionStyled>
        </>
      )}
      <AccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          data-automation-id="ledger-detail-comments-accordion-summary"
        >
          <LedgerDetailAccordionTitle data-automation-id="ledger-detail-view-h4-accordion-title-comments">
            Comments
          </LedgerDetailAccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          {invoiceCommentsLoading || poCommentsLoading || logisticCommentsLoading ? (
            <LoaderInPage />
          ) : (
            <LedgerDetailComments
              comments={commentsToDisplay}
              assetType={type}
              assetId={assetId}
              inView
              addCommentClickHandler={addCommentClickHandler}
              viewCommentsClickHandler={viewCommentsClickHandler}
              addCommentSubmitClickHandler={addCommentSubmitClickHandler}
            />
          )}
        </AccordionDetails>
      </AccordionStyled>
      {addCommentDialogOpen && (
        <FullViewDialog
          open={addCommentDialogOpen}
          clickHandler={closeAddCommentFullViewDialogHandler}
          dialogContent={
            <LedgerDetailAddComment addCommentClickHandler={addCommentSubmitClickHandler} />
          }
        />
      )}
      {viewCommentsDialogOpen && (
        <FullViewDialog
          open={viewCommentsDialogOpen}
          clickHandler={closeViewCommentsFullViewDialogHandler}
          dialogContent={
            <LedgerDetailViewComments>
              <LedgerDetailComments
                comments={commentsToDisplay.length === 0 ? comments : commentsToDisplay}
                assetType={type}
                assetId={assetId}
                addCommentClickHandler={addCommentClickHandler}
                viewCommentsClickHandler={viewCommentsClickHandler}
                addCommentSubmitClickHandler={addCommentSubmitClickHandler}
              />
            </LedgerDetailViewComments>
          }
        />
      )}
    </LedgerDetailAccordionContainer>
  );
};

export default LedgerDetailAccordion;
