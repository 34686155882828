import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { DateTime } from 'luxon';
import { dateFormatForIso } from 'lib/helpers/formatters/datetimeFormatters';
import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import Chip from 'components/common/Chip';
import WarningIcon from 'assets/icons/WarningIcon';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { getInvoiceStatusTooltipText } from 'lib/tooltips/tooltips';

export const ledgerInvoiceHeader: DataGridHeaderItem[] = [
  new DataGridHeaderItem('invoiceId', 'invoiceId', {
    display: false
  }),
  new DataGridHeaderItem('MatchingStatus', 'matchingStatus', {
    display: false
  }),
  new DataGridHeaderItem(
    'Invoice Number',
    'invoiceNumber',
    new DataGridHeaderOptions(false, true, true)
  ),
  new DataGridHeaderItem('Invoice Status', 'invoiceStatus', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <Chip
            type={value}
            color={themeColors.text.primary}
            radius="3px"
            height="18px"
            fontSize={fontSizes.small}
            semibold="bold"
            testingTag="ledger-logistics-header"
            tooltipText={getInvoiceStatusTooltipText(value)}
          />
          {!tableMeta.rowData[1] && (
            <IconTooltip
              tooltipText="An issue was found on this invoice during the matching process. Your buyer will review the issue(s) and accept it for payment or contact you to amend the invoice."
              children={<WarningIcon color={themeColors.icon.error} />}
            />
          )}
        </div>
      );
    }
  }),
  new DataGridHeaderItem('Created Date', 'invoiceDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('CCY', 'currency', new DataGridHeaderOptions(false, true, true)),
  new DataGridHeaderItem('Total Amount', 'totalAmount', {
    ...new DataGridHeaderOptions(false, true, true),
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <p style={{ textAlign: 'right' }}>{value}</p>
    ),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = parseFloat(obj1.data.split(',').join(''));
        const val2: number = parseFloat(obj2.data.split(',').join(''));
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Payment Due Date', 'dueDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  new DataGridHeaderItem('Maturity Date', 'maturityDate', {
    ...new DataGridHeaderOptions(false, true, true),
    sortCompare: (order: string) => {
      return (obj1: { data: string }, obj2: { data: string }) => {
        const val1: number = DateTime.fromISO(dateFormatForIso(obj1.data)).toMillis();
        const val2: number = DateTime.fromISO(dateFormatForIso(obj2.data)).toMillis();
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
  }),
  //OA FIELD
  new DataGridHeaderItem('Payment ID', 'paymentId', new DataGridHeaderOptions(false, true, true))
];
