import { Select } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const OutgoingPaymentsTitle = styled.h2`
  color: ${themeColors.text.primary};
`;

export const OutgoingPaymentsSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const OutgoingPaymentsSelectionSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const OutgoingPaymentsText = styled.p`
  ${typography.bodyRegular};
`;

export const OutgoingPaymentsKey = styled.p`
  ${typography.bodySemiBold};
`;

export const OutgoingPaymentTotalPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
export const OutgoingPaymentTotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
export const OutgoingPaymentSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

export const SelectStyled = styled(Select)`
  .MuiSelect-select {
    padding: 8px 24px;
    ${typography.bodyRegular};
    font-family: 'Source Sans Pro', sans-serif;
  }
  .MuiList-root {
    ${typography.bodyRegular};
  }
`;

export const OutgoingPaymentActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export const OutgoingPaymentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const OutgoingPaymentButtonText = styled.p`
  ${typography.bodySemiBold};
`;

export const OutgoingPaymentSnackbarTitle = styled.h5``;

export const OutgoingPaymentSnackbarText = styled.p`
  span {
    ${typography.bodyBold};
  }
`;

export const DatePickerStyled = styled(DatePicker)``;
