import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';

export const SecondaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : `1px solid ${themeColors.border.primary}`)};
  color: ${({ textColor }) => textColor ? textColor : themeColors.text.primary};
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : themeColors.white};

  &:hover {
    border: 1px solid ${themeColors.border.primaryHover};
    color: ${themeColors.text.primaryHover};

    #fillHover {
      fill: ${themeColors.text.primaryHover};
    }
    #strokeHover {
      stroke: ${themeColors.text.primaryHover};
    }
  }
  
  &:active {
    color: ${themeColors.text.primaryActive};
  }

  &:focus {
    border: 1px solid ${themeColors.border.inputFocus};
  }

`;
