import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserBarContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 12px;
  flex-direction: row;
  width: 100%;
`;

export const LayoutViewsContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;
