import React from 'react';

type Props = {};

const Pending2Icon = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-pending2-icon"
  >
    <g filter="url(#filter0_i_2477_61697)">
      <path
        d="M12 0.480011C10.6214 0.480011 9.27358 0.720971 7.99294 1.19617L8.32702 2.09617C9.50248 1.66039 10.7463 1.43818 12 1.44001C17.8229 1.44001 22.56 6.17713 22.56 12C22.56 17.8229 17.8229 22.56 12 22.56C6.1771 22.56 1.43998 17.8229 1.43998 12C1.4409 10.4441 1.7856 8.90769 2.44943 7.50054C3.11326 6.09339 4.07982 4.8503 5.27998 3.86017V7.20001H6.23998V2.40001H1.43998V3.36001H4.38046C3.15473 4.44115 2.17303 5.77067 1.50054 7.26031C0.828051 8.74995 0.480165 10.3656 0.47998 12C0.47998 18.3519 5.64814 23.52 12 23.52C18.3518 23.52 23.52 18.3519 23.52 12C23.52 5.64817 18.3518 0.480011 12 0.480011Z"
        fill="#C09C2F"
      />
    </g>
    <g filter="url(#filter1_i_2477_61697)">
      <path
        d="M9.11997 15.84H8.15997V16.8H15.84V15.84H14.88V14.2013L12.6787 12L14.88 9.79873V8.16001H15.84V7.20001H8.15997V8.16001H9.11997V9.79873L11.3213 12L9.11997 14.2013V15.84ZM10.08 9.40129V8.16001H13.92V9.40129L12 11.3213L10.08 9.40129ZM12 12.6787L13.92 14.5987V15.84H10.08V14.5987L12 12.6787ZM9.11997 18.72H10.08V19.68H9.11997V18.72ZM11.52 18.72H12.48V19.68H11.52V18.72ZM13.92 18.72H14.88V19.68H13.92V18.72Z"
        fill="#C09C2F"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_2477_61697"
        x="0.47998"
        y="0.480011"
        width="23.54"
        height="23.54"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.591667 0 0 0 0 0.483194 0 0 0 0 0.0986111 0 0 0 0.6 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2477_61697" />
      </filter>
      <filter
        id="filter1_i_2477_61697"
        x="8.15997"
        y="7.20001"
        width="8.17999"
        height="12.98"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.5" dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.591667 0 0 0 0 0.483194 0 0 0 0 0.0986111 0 0 0 0.6 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2477_61697" />
      </filter>
    </defs>
  </svg>
);

export default Pending2Icon;
