import { RootStateOrAny, useSelector } from 'react-redux';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';

export const useCheckProgramsContainsType: (type: string) => boolean = (type) => {
  const programTypes: string[] = useSelector(
    (state: RootStateOrAny) =>
      (state.app.selectedProgramByCurrency as IGroupedProgram | null)?.programDetails.map(
        (p) => p.baseType
      ) || ['']
  );

  return Boolean(programTypes.find((p) => p.includes(type)));
};
