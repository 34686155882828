import { FC, useEffect, useState } from 'react';
import {
  QontoStepIconRoot,
  StepContainer,
  WelcomeGuidancePanelButtonWrapper,
  WelcomeGuidancePanelContainer,
  WelcomeGuidancePanelContentContainer,
  WelcomeGuidancePanelContentDescription,
  WelcomeGuidancePanelContentLink,
  WelcomeGuidancePanelContentVideo,
  WelcomeGuidancePanelFooter,
  WelcomeGuidancePanelHeader,
  WelcomeGuidancePanelSkipLink,
  WelcomeGuidancePanelTitle,
  WelcomeGuidancePanelTopContainer,
  WelcomeGuidancePanelWrapper
} from './styled';
import Stepper from '@mui/material/Stepper';
import { StepIconProps } from '@mui/material/StepIcon';

import { themeColors } from 'assets/theme/style';
import ActiveStepIcon from 'assets/icons/ActiveStepIcon';
import StepLabel from '@mui/material/StepLabel';
import { LeftLongArrowIcon, RightLongArrowIcon } from 'assets/icons/ArrowIcons';
import PrimaryButton from 'components/common/buttons/PrimaryButton';
import homepagePreview from 'assets/videos/video-homepage.png';
import invoicesPreview from 'assets/videos/video-invoices.png';
import paymentsPreview from 'assets/videos/video-payments.png';
import logisticsPreview from 'assets/videos/video-logistics.png';
import poPreview from 'assets/videos/video-po.png';
import ReactPlayer from 'react-player/lazy';
import PlayIcon from 'assets/icons/PlayIcon';
import { setCookie } from 'lib/helpers/cookieHelper';

interface IGuidanceSteps {
  label: string;
  description: string;
  linkLabel: string;
  link: string;
  video: boolean;
}

const QontoStepIcon = (props: StepIconProps) => {
  const { active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <ActiveStepIcon />
      ) : (
        <div
          style={{
            height: '6px',
            width: '6px',
            borderRadius: '50%',
            backgroundColor: themeColors.text.light.muted
          }}
        />
      )}
    </QontoStepIconRoot>
  );
};

const WelcomeGuidancePanel: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [displayDialog, setDisplayDialog] = useState<boolean>(true);

  const guidanceSteps: IGuidanceSteps[] = [
    {
      label: 'Let’s get started',
      description:
        'To help you get started we wanted to share with you some of the key tutorial videos that will get you on your way.',
      linkLabel: '',
      link: '',
      video: false
    },
    {
      label: 'Homepage navigation',
      description: '',
      linkLabel: 'More information about homepage navigation',
      link: 'https://supplier.support.kanexa.net/hc/en-us/articles/18510591830162-Homepage',
      video: true
    },
    {
      label: 'Purchase order',
      description: '',
      linkLabel: 'More information about purchase order',
      link: 'https://supplier.support.kanexa.net/hc/en-us/articles/18406502394258-Purchase-orders',
      video: true
    },
    {
      label: 'Invoices',
      description: '',
      linkLabel: 'More information about invoices',
      link: 'https://supplier.support.kanexa.net/hc/en-us/articles/18553506274706-Invoices',
      video: true
    },
    {
      label: 'Logistics',
      description: '',
      linkLabel: 'More information about logistics',
      link: 'https://supplier.support.kanexa.net/hc/en-us/articles/18478009048850-Logistics',
      video: true
    },
    {
      label: 'Payments',
      description: '',
      linkLabel: 'More information about payments',
      link: 'https://supplier.support.kanexa.net/hc/en-us/articles/18401849403282-Payments',
      video: true
    },
    {
      label: 'Need more help',
      description: 'More helpful information is available on our support site including FAQs.',
      linkLabel: 'Help & support',
      link: 'https://supplier.support.kanexa.net',
      video: false
    }
  ];

  useEffect(() => {
    activeStep === 6 && setCookie('spOnboardWelcomeGuidance', 'completed', 365);
  }, [activeStep]);

  const handleSkipDialogClick: () => void = () => {
    setDisplayDialog(false);
    setCookie('spOnboardWelcomeGuidance', 'completed', 365);
  };

  return (
    <WelcomeGuidancePanelWrapper display={displayDialog}>
      <WelcomeGuidancePanelContainer>
        <WelcomeGuidancePanelTopContainer>
          <WelcomeGuidancePanelHeader>
            <Stepper alternativeLabel activeStep={activeStep} style={{ gap: '6px' }}>
              {guidanceSteps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};

                return (
                  <StepContainer key={step.label} {...stepProps}>
                    <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                  </StepContainer>
                );
              })}
            </Stepper>
            <WelcomeGuidancePanelTitle>
              {guidanceSteps[activeStep]?.label}
            </WelcomeGuidancePanelTitle>
          </WelcomeGuidancePanelHeader>
          <WelcomeGuidancePanelContentContainer>
            {guidanceSteps[activeStep]?.description && (
              <WelcomeGuidancePanelContentDescription>
                {guidanceSteps[activeStep]?.description}
              </WelcomeGuidancePanelContentDescription>
            )}
            {guidanceSteps[activeStep]?.video && (
              <WelcomeGuidancePanelContentVideo>
                {activeStep === 1 && (
                  <ReactPlayer
                    url="https://vimeo.com/941150491/d4599e8ccd"
                    width="560px"
                    height="315px"
                    playing
                    light={homepagePreview}
                    playIcon={<PlayIcon />}
                    style={{ justifyContent: 'center' }}
                    controls={true}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'true'
                        }
                      }
                    }}
                  />
                )}
                {activeStep === 2 && (
                  <ReactPlayer
                    url="https://vimeo.com/941151750/03113a0772"
                    width="560px"
                    height="315px"
                    playing
                    light={poPreview}
                    playIcon={<PlayIcon />}
                    style={{ justifyContent: 'center' }}
                    controls={true}
                  />
                )}
                {activeStep === 3 && (
                  <ReactPlayer
                    url="https://vimeo.com/941153279/aa5450a5c9"
                    width="560px"
                    height="315px"
                    playing
                    light={invoicesPreview}
                    playIcon={<PlayIcon />}
                    style={{ justifyContent: 'center' }}
                    controls={true}
                  />
                )}

                {activeStep === 4 && (
                  <ReactPlayer
                    url="https://vimeo.com/940759077/256eeb3e2c"
                    width="560px"
                    height="315px"
                    playing
                    light={logisticsPreview}
                    playIcon={<PlayIcon />}
                    style={{ justifyContent: 'center' }}
                    controls={true}
                  />
                )}
                {activeStep === 5 && (
                  <ReactPlayer
                    url="https://vimeo.com/941153838/38ce065d0e"
                    width="560px"
                    height="315px"
                    playing
                    light={paymentsPreview}
                    playIcon={<PlayIcon />}
                    style={{ justifyContent: 'center' }}
                    controls={true}
                  />
                )}
              </WelcomeGuidancePanelContentVideo>
            )}
            <WelcomeGuidancePanelContentLink
              onClick={() => window.open(guidanceSteps[activeStep]?.link, '_BLANK')}
            >
              {guidanceSteps[activeStep]?.linkLabel}
            </WelcomeGuidancePanelContentLink>
          </WelcomeGuidancePanelContentContainer>
        </WelcomeGuidancePanelTopContainer>
        <WelcomeGuidancePanelFooter>
          <WelcomeGuidancePanelSkipLink onClick={handleSkipDialogClick}>
            Skip
          </WelcomeGuidancePanelSkipLink>
          <WelcomeGuidancePanelButtonWrapper>
            {activeStep > 0 && (
              <PrimaryButton
                borderRadius="50px"
                width="40px"
                height="40px"
                padding="0"
                clickHandler={(e) => setActiveStep(activeStep - 1)}
              >
                <LeftLongArrowIcon color={themeColors.text.onPrimary} />
              </PrimaryButton>
            )}
            {activeStep < guidanceSteps.length - 1 && (
              <PrimaryButton
                borderRadius="50px"
                width="40px"
                height="40px"
                padding="0"
                clickHandler={(e) => setActiveStep(activeStep + 1)}
              >
                <RightLongArrowIcon color={themeColors.text.onPrimary} />
              </PrimaryButton>
            )}
          </WelcomeGuidancePanelButtonWrapper>
        </WelcomeGuidancePanelFooter>
      </WelcomeGuidancePanelContainer>
    </WelcomeGuidancePanelWrapper>
  );
};

export default WelcomeGuidancePanel;
