import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerDetailAddCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const LedgerDetailCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
  gap: 16px;
`;

export const LedgerDetailCommentTextArea = styled.textarea`
  border-radius: 3px;
  border: 1px solid ${themeColors.greyBlue};
  padding: 8px 12px;
  ${typography.bodyRegular};
  :focus {
    outline: none;
    outline: 1px solid ${themeColors.border.primaryFocus};
    border: 1px solid ${themeColors.border.primary};
  }
`;

export const LedgerDetailAddCommentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
