import {
  BankAccountProgramRule,
  ProgramConfig,
  IProgramRule
} from 'api/interfaces/program/program.interface';

const SUPPLIER_BANK_ACCOUNT_TYPE = 'SUPPLIER_BANK_ACCOUNTS';

export class UpdateProgramSupplierBankAccountRule {
  value: any;

  constructor(
    public x500: string,
    public currencies: CurrencyRule[],
    preExistingCurrencyRules: CurrencyRule[] = []
  ) {
    this.value = {
      type: SUPPLIER_BANK_ACCOUNT_TYPE,
      value: {
        bankAccounts: {
          [x500]: {
            perCurrency: [...currencies, ...preExistingCurrencyRules].reduce(
              (map: any, obj: CurrencyRule) => {
                map[obj.currencyIsoCode] = { bankAccountId: obj.bankAccountId };
                return map;
              },
              {}
            )
          }
        }
      }
    };
  }
}

export class CurrencyRule {
  constructor(public currencyIsoCode: string, public bankAccountId: string) {}
}

export const getSupplierBankAccountCurrencyRules: (
  program: ProgramConfig,
  identifier: string
) => CurrencyRule[] = (program, identifier) => {
  const currencyRules: CurrencyRule[] = [];
  const supplierBankAccountRule = program.rules.find(
    (rule: IProgramRule<BankAccountProgramRule>) => rule.type === SUPPLIER_BANK_ACCOUNT_TYPE
  );
  if (
    !!supplierBankAccountRule &&
    Object.keys(supplierBankAccountRule.value.bankAccounts).length > 0
  ) {
    const perCurrencyRules =
      supplierBankAccountRule.value.bankAccounts[`${identifier}`].perCurrency;
    Object.keys(perCurrencyRules).forEach((key) => {
      currencyRules.push(new CurrencyRule(key, perCurrencyRules[key].bankAccountId));
    });
  }

  return currencyRules;
};
