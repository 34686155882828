import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { DateBarProps } from './DateBar';

export const DateBarContainer = styled.div<DateBarProps>`
  display: flex;
  align-items: center;
  max-height: 32px;
  background-color: ${({ color }) => (color ? color : `${themeColors.bg.light.surface}`)};
  padding: 8px;
  border-radius: ${({ toEdge }) => (toEdge ? '0' : '6px')};
  margin-left: ${({ toEdge }) => (toEdge ? '-16px' : '')};
  margin-right: ${({ toEdge }) => (toEdge ? '-16px' : '')};
`;

export const DateValue = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.text.light.body}
  margin: 0;
`;
