import { isMobile } from 'lib/helpers/mobile';
import { typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { Select } from '@mui/material';

export const NavigationContainer = styled.div`
  width: ${isMobile() ? '100%' : '354px'};
  display: flex;
  flex-direction: column;
  padding: ${isMobile() ? '0' : '25px 0 0 100px'};
`;

export const SidebarLinksContainer = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  ${isMobile() && 'padding: 0 16px;'};
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  margin: 0 auto;

  .MuiSelect-select {
    padding: ${isMobile() ? '8px 12px' : '8px 24px'};
    ${typography.bodyRegular};
    font-family: 'Source Sans Pro', sans-serif;
    background-color: ${themeColors.white};
  }
  .MuiList-root {
    ${typography.bodyRegular};
  }
  .MuiSelect-icon {
    color: ${themeColors.text.light.body};
  }
`;
