import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const InvoiceUploadIcon: FC<IconSvgProps> = ({
  color = themeColors.icon.primary,
  height = '20px',
  width = '16px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 19.9992V20.0002H3C1.34 20.0002 0 18.6502 0 17.0002V3.00016C0 1.34016 1.34 0.000163127 3 0.000163127H9.98134L10 -1.16415e-10V-0.00083692C10.26 -0.0108369 10.52 0.0991632 10.71 0.289163L15.71 5.28916C15.807 5.38621 15.8837 5.50652 15.9338 5.63911C15.9726 5.74018 15.9954 5.84953 15.9994 5.9643C15.9998 5.9759 16 5.98752 16 5.99916V6V16.9992C16 18.6492 14.65 19.9992 13 19.9992ZM3 2.00016H9V5C9 6.1 9.89 7 11 7H14V17.0002C14 17.5502 13.55 18.0002 13 18.0002H3C2.44 18.0002 2 17.5502 2 17.0002V3.00016C2 2.44016 2.44 2.00016 3 2.00016ZM11 5V3.41016L12.5898 5H11ZM5 6H6C6.55 6 7 5.55 7 5C7 4.44 6.55 4 6 4H5C4.44 4 4 4.44 4 5C4 5.55 4.44 6 5 6ZM11 12H5C4.44 12 4 11.55 4 11C4 10.44 4.44 10 5 10H11C11.55 10 12 10.44 12 11C12 11.55 11.55 12 11 12ZM8 15C8 15.55 8.44 16 9 16H11C11.55 16 12 15.55 12 15C12 14.44 11.55 14 11 14H9C8.44 14 8 14.44 8 15Z"
      fill={color}
    />
  </svg>
);

export default InvoiceUploadIcon;
