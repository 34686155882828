export const FETCH_INVOICE_LEDGER = '@@frontend/store/reducers/ledger/FETCH_INVOICE_LEDGER';
export const FETCH_INVOICE_LEDGER_SUCCESS =
  '@@frontend/store/reducers/ledger/FETCH_INVOICE_LEDGER_SUCCESS';
export const FETCH_INVOICE_LEDGER_FAILURE =
  '@@frontend/store/reducers/ledger/FETCH_INVOICE_LEDGER_FAILURE';
export const FETCH_PURCHASE_ORDER_LEDGER =
  '@@frontend/store/reducers/ledger/FETCH_PURCHASE_ORDER_LEDGER';
export const FETCH_PURCHASE_ORDER_LEDGER_SUCCESS =
  '@@frontend/store/reducers/ledger/FETCH_PURCHASE_ORDER_LEDGER_SUCCESS';
export const FETCH_PURCHASE_ORDER_LEDGER_FAILURE =
  '@@frontend/store/reducers/ledger/FETCH_PURCHASE_ORDER_LEDGER_FAILURE';
export const FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY =
  '@@frontend/store/reducers/ledger/FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY';
export const FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS =
  '@@frontend/store/reducers/ledger/FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_SUCCESS';
export const FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE =
  '@@frontend/store/reducers/ledger/FETCH_ACCEPTED_INVOICES_BY_ELIGIBILITY_FAILURE';
export const FETCH_LOGISTICS_BY_DOCUMENT_ID =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_DOCUMENT_ID';
export const FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_DOCUMENT_ID_SUCCESS';
export const FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_DOCUMENT_ID_FAILURE';
export const RESET_LOGISTICS_DATA = '@@frontend/store/reducers/ledger/RESET_LOGISTICS_DATA';
export const FETCH_LOGISTICS_BY_PROGRAM_IDS =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_PROGRAM_IDS';
export const FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_PROGRAM_IDS_SUCCESS';
export const FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE =
  '@@frontend/store/reducers/ledger/FETCH_LOGISTICS_BY_PROGRAM_IDS_FAILURE';
