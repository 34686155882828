import Accordion from '@mui/material/Accordion';
import { themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const LineItemsDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  align-self: center;
  gap: 48px;
`;

export const LedgerItemsDialogTitle = styled.p`
  ${typography.bodySemiBold}
`;

export const LedgerItemsDialogValue = styled.p`
  text-align: right;
`;

export const LedgerItemsDialogContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LedgerItemsDialogKeyValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
`;

export const LedgerItemsDialogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: 35rem !important;
  margin-bottom: 8px;
`;

export const LedgerItemsTitle = styled.h2`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 30rem !important;
  margin-bottom: 8px;
  color: ${themeColors.text.light.body};
`;

export const LedgerItemsDialogTextSemiBold = styled.p`
  ${typography.bodySemiBold};
  font-size: ${fontSizes.regular};
`;

export const LedgerItemsDialogTextRegular = styled.p`
  ${typography.bodyRegular};
  font-size: ${fontSizes.regular};
  text-align: end;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 16px !important;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;

    ::before {
      display: none;
    }
    &.Mui-expanded {
      margin: 0;
      padding-bottom: 12px;
    }
    .MuiAccordionSummary-root {
      background-color: ${themeColors.bg.light.surface};
      flex-direction: row-reverse;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.light.default};
  }
  .MuiButtonBase-root {
    width: 100%;
    border-radius: 6px;
    padding: ${isMobile() ? '0 16px' : '0 24px'};
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
    transform: rotate(-90deg);
    margin-right: 8px;
    &.Mui-expanded {
      transform: rotate(360deg);
      margin-right: 8px;
    }
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .MuiAccordionDetails-root {
    padding: ${isMobile() ? '0 32px' : '0 48px'};
    ${typography.bodyRegular};
    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: 10px 0;
  }
`;
