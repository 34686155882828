import { Accordion } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none;
    margin: 0;
    padding:  0 16px 8px 16px;
    ::before {
      display: none;
    }
  }
  .MuiButtonBase-root {
    width: 100%;
    padding: 0px 16px;
    min-height: 32px;
    ${typography.bodyRegular};
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.icon.primary};
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
