/* eslint-disable react-hooks/exhaustive-deps */
import EntityApi from 'api/entity/entity.api';
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import Uploader from 'components/common/Uploader';
import Autocomplete from 'components/forms/inputs/Autocomplete';
import TextInput from 'components/forms/inputs/TextInput';
import { popularCountries } from 'lib/constants/popularCountries';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { UPDATE_PROGRAM_OWNER_DETAILS } from 'store/actions';
import { AutocompleteItem } from 'utils/interfaces/input/autocomplete.interface';
import { CompanyInformation } from '../CompanyDetails';
import {
  CompanyDetailsActionsContainer,
  CompanyDetailsButtonText,
  CompanyDetailsButtonWrapper,
  CompanyDetailsContainer,
  CompanyDetailsLottieAnimationContainer,
  CompanyDetailsRegisteredAddressContainer,
  CompanyDetailsTitle,
  CompanyDetailsWrapper,
  CompanyLogoDescription,
  CompanyLogoTitle,
  CompanyLogoUploadContainer,
  FormStyled
} from './styled';

interface CompanyDetailsEditProps {
  info: CompanyInformation;
  closeHandler: () => void;
  saveClickHandler: () => void;
}

const CompanyDetailsEdit: FC<CompanyDetailsEditProps> = ({
  info,
  closeHandler,
  saveClickHandler
}) => {
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState<File[]>([]);
  const [logoChanged, setLogoChanged] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);
  const [apiCallSubmitted, setApiCallSubmitted] = useState<boolean>(false);
  const [countryItems, setCountryItems] = useState<AutocompleteItem[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CompanyInformation>({ mode: 'onChange', reValidateMode: 'onChange' });
  const {
    programOwnerDetails,
    loading
  }: {
    programOwnerDetails: EntityOwner | null;
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.program);
  const { countries } = useSelector((state: RootStateOrAny) => state.app);
  const [modifiedDetails, setModifiedDetails] = useState<CompanyInformation>({
    registeredCompanyName: '',
    tradingName: '',
    registeredAddressLine1: '',
    registeredAddressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    companyID: '',
    companyTaxID: '',
    website: '',
    contactNumber: '',
    country: '',
    companyLogo: ''
  });
  const entityApi = new EntityApi(store);

  useEffect(() => {
    if (modifiedDetails) reset();
  }, [modifiedDetails]);

  const fileDropHandler: (acceptedFiles: File[]) => void = (acceptedFiles) => {
    setLogoChanged(true);
    setUploaded(acceptedFiles);
  };

  useEffect(() => {
    if (countries) countryDropdownMenuItems();
  }, [countries]);

  useEffect(() => {
    // to create clone with new reference
    setModifiedDetails(JSON.parse(JSON.stringify(info)));
  }, [info]);

  useEffect(() => {
    if (apiCallSubmitted && !loading) saveClickHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallSubmitted, loading]);

  useEffect(() => {
    JSON.stringify(info) === JSON.stringify(modifiedDetails)
      ? setModified(false)
      : setModified(true);
  }, [modifiedDetails, info]);

  const countryDropdownMenuItems: () => void = () => {
    if (!countries) return [];
    const mappedItems: AutocompleteItem[] = Object.keys(countries).map((key) =>
      popularCountries.includes(key)
        ? { name: countries[key], value: key, group: 'Quick selection' }
        : { name: countries[key], value: key, group: 'All countries' }
    );
    setCountryItems(mappedItems);
  };

  const updateValue: (key: string, value: string | boolean) => void = (key, value) =>
    setModifiedDetails({ ...modifiedDetails, [key]: value });

  const onSubmit: SubmitHandler<CompanyInformation> = () => {
    const mappedModel: EntityOwner | undefined = mapToModel();
    if (!mappedModel) return;
    if (modified) dispatch({ type: UPDATE_PROGRAM_OWNER_DETAILS, payload: mappedModel });
    if (logoChanged && uploaded.length > 0) submitLogo();
    setApiCallSubmitted(true);
  };

  const submitLogo: () => void = async () => {
    if (!programOwnerDetails?.id) throw Error('No owner ID available');
    const formData = new FormData();
    formData.append('file', uploaded[0]);
    await entityApi.putCompanyLogo(formData, programOwnerDetails.id);
  };

  const mapToModel: () => EntityOwner | undefined = () => {
    if (!programOwnerDetails) return;
    const {
      contactNumber,
      registeredCompanyName,
      website,
      tradingName,
      registeredAddressLine1,
      registeredAddressLine2,
      state,
      zipCode,
      city,
      country,
      companyTaxID,
      companyLogo
    } = modifiedDetails;

    const entity: EntityOwner = programOwnerDetails;

    entity.contact = {
      phoneNumber: contactNumber,
      registeredName: registeredCompanyName,
      siteUrl: website,
      tradingName
    };
    entity.registeredAddress = {
      line1: registeredAddressLine1,
      line2: registeredAddressLine2,
      state,
      zipCode,
      city,
      countryIso: country
    };
    entity.identifier.taxId = companyTaxID;
    entity.companyLogo = companyLogo;

    return entity;
  };

  const saving: () => JSX.Element = () => (
    <CompanyDetailsLottieAnimationContainer data-testid="sp-company-details-loading">
      <LoaderFullPage />
    </CompanyDetailsLottieAnimationContainer>
  );

  return (
    <CompanyDetailsWrapper>
      <CompanyDetailsContainer isSaving={loading}>
        {loading && saving()}
        {!loading && (
          <>
            <CompanyDetailsTitle data-automation-id="company-details-edit-h2-page-title">
              Edit company details
            </CompanyDetailsTitle>
            <FormStyled onSubmit={handleSubmit(onSubmit)} data-testid="sp-contact-details-form">
              <TextInput
                disabled
                defaultValue={modifiedDetails.registeredCompanyName}
                label="Registered company name"
                testingTagPage="company-details"
                tooltipText="The legal name of your company"
              />
              <TextInput
                defaultValue={modifiedDetails.tradingName}
                label="Trading name"
                register={register('tradingName', { required: true })}
                errorMessage={errors.tradingName && 'This field is required'}
                changeHandler={(event) => updateValue('tradingName', event.target.value)}
                testingTagPage="company-details"
                tooltipText="The company name your customers know you by"
              />
              <CompanyDetailsRegisteredAddressContainer>
                <TextInput
                  disabled
                  defaultValue={modifiedDetails.registeredAddressLine1}
                  label="Registered address"
                  testingTagPage="company-details"
                />
                <TextInput
                  disabled
                  defaultValue={modifiedDetails.registeredAddressLine2}
                  // register={register('registeredAddressLine2', { required: true })}
                  // errorMessage={errors.registeredAddressLine2 && 'This field is required'}
                  // changeHandler={(event) =>
                  //   updateValue('registeredAddressLine2', event.target.value)
                  // }
                  testingTagPage="company-details"
                />
                <TextInput
                  disabled
                  defaultValue={modifiedDetails.city}
                  testingTagPage="company-details"
                />
                <TextInput
                  disabled
                  defaultValue={modifiedDetails.state}
                  testingTagPage="company-details"
                />
              </CompanyDetailsRegisteredAddressContainer>
              <Autocomplete
                disabled
                label="Country"
                options={countryItems}
                value={modifiedDetails.country}
                changeHandler={(value: any) => updateValue('country', value)}
              />
              <TextInput
                disabled
                defaultValue={modifiedDetails.zipCode}
                label="Zip code"
                testingTagPage="company-details"
              />
              <TextInput
                defaultValue={modifiedDetails.companyTaxID}
                label="Company tax ID"
                register={register('companyTaxID', { required: true })}
                errorMessage={errors.companyTaxID && 'This field is required'}
                changeHandler={(event) => updateValue('companyTaxID', event.target.value)}
                testingTagPage="company-details"
                tooltipText={`Your company's unique taxpayer reference`}
              />
              <TextInput
                defaultValue={modifiedDetails.website}
                label="Website"
                register={register('website', { required: true })}
                errorMessage={errors.website && 'This field is required'}
                changeHandler={(event) => updateValue('website', event.target.value)}
                testingTagPage="company-details"
              />
              <TextInput
                defaultValue={modifiedDetails.contactNumber.toString()}
                label="Contact number"
                register={register('contactNumber', { required: true })}
                errorMessage={errors.contactNumber && 'This field is required'}
                changeHandler={(event) => updateValue('contactNumber', event.target.value)}
                testingTagPage="company-details"
              />

              <CompanyDetailsActionsContainer>
                <CompanyDetailsButtonWrapper>
                  <PrimaryButton
                    type="submit"
                    disabled={(!modified && !logoChanged) || !!Object.keys(errors).length}
                    testingTag="company-details-button-save"
                    text="Save"
                  />
                </CompanyDetailsButtonWrapper>
                <CompanyDetailsButtonWrapper>
                  <SecondaryButton
                    text="Cancel"
                    clickHandler={closeHandler}
                    testingTag="company-details-button-cancel"
                  />
                </CompanyDetailsButtonWrapper>
              </CompanyDetailsActionsContainer>
            </FormStyled>
          </>
        )}
      </CompanyDetailsContainer>
    </CompanyDetailsWrapper>
  );
};

export default CompanyDetailsEdit;
