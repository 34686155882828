import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import MPNLogo from 'assets/icons/MPNLogo';
import { themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import { FC } from 'react';

export interface FullScreenDialogAppBarProps {
  clickHandler: () => void;
}

const FullScreenDialogAppBar: FC<FullScreenDialogAppBarProps> = ({ clickHandler }) => (
  <AppBar
    style={{
      position: 'relative',
      backgroundColor: themeColors.white,
      height: '84px',
      display: 'flex',
      justifyContent: 'center',
      boxShadow: 'none',
      borderBottom: `1px solid ${themeColors.bg.light.muted}`
    }}
    data-automation-id="full-view-dialog-header-container"
  >
    <Toolbar
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: `0 ${isMobile() ? 2 : 5}rem`
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={clickHandler}
        aria-label="close"
        style={{ cursor: 'pointer' }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          data-testid="company-details-img-company-logo"
        >
          <MPNLogo color={themeColors.icon.primary} />
        </div>
      </IconButton>
      <CloseIcon
        onClick={clickHandler}
        style={{ color: themeColors.icon.primary, cursor: 'pointer' }}
        data-testid="sp-full-view-dialog-button-close"
      />
    </Toolbar>
  </AppBar>
);

export default FullScreenDialogAppBar;
