/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from '@mui/material';
import { IAdjustment } from 'api/interfaces/open-account/adjustments/adjustments.interface';
import { layoutSizes } from 'assets/theme/style';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import DataGrid from 'components/DataGrid';
import { ledgerAdjustmentsTableHeaders } from 'lib/data/ledger/ledgerAdjustmentsHeaders';
import { AdjustmentsEnum } from 'lib/enums/adjustments.enum';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { acc } from 'lib/helpers/accumulator';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { GET_ADJUSTMENTS } from 'store/actions';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  LedgerAdjustmentContainer,
  LedgerAdjustmentMobileContainer,
  LedgerAdjustmentMobileItemBold,
  LedgerAdjustmentMobileItemLeft,
  LedgerAdjustmentMobileItemRegular,
  LedgerAdjustmentMobileItemRight,
  LedgerAdjustmentMobileRow,
  LedgerAdjustmentMobileWrapper,
  LedgerAdjustmentsWrapper
} from './styled';

interface ILedgerDetailAdjustments {
  assetId: string;
  type: keyof typeof AssetEnum;
  currency: string;
}

const LedgerDetailAdjustments: FC<ILedgerDetailAdjustments> = ({ assetId, type, currency }) => {
  const dispatch = useDispatch();
  const layoutRef: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const [layoutWidth, setLayoutWidth]: [number, Dispatch<SetStateAction<number>>] =
    useState<number>(0);
  const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
    useState<DataGridRowItem[]>([]);
  const headers: DataGridHeaderItem[] = ledgerAdjustmentsTableHeaders;
  const {
    loading: adjustmentLoading,
    adjustments
  }: { loading: string; adjustments: IAdjustment[] } = useSelector(
    (state: RootStateOrAny) => state.adjustment
  );
  const { loading: appLoading }: { loading: string } = useSelector(
    (state: RootStateOrAny) => state.app
  );

  useEffect(() => {
    getLayoutSize();
    window.addEventListener('resize', getLayoutSize);
    dispatch({ type: GET_ADJUSTMENTS, payload: { type, assetId } });
    return () => {
      window.removeEventListener('resize', getLayoutSize);
    };
  }, []);

  useEffect(() => {
    if (adjustments)
      setData(mapAdjustmentsToGridModel().sort((a, b) => (a.reason > b.reason ? 1 : -1)));
  }, [adjustments]);

  const getLayoutSize: () => void = () => {
    const newWidth: number = layoutRef?.current?.clientWidth || 0;
    setLayoutWidth(newWidth);
  };

  const mapAdjustmentsToGridModel: () => DataGridRowItem[] = () =>
    adjustments
      .map((line) => ({
        reason: line.reason,
        comment: line.comment,
        type: line.type,
        value: `${line.type === AdjustmentsEnum.DEDUCTION ? '-' : ''}${formatNumber(
          line.amount === null ? 0 : line.amount,
          2
        )}`
      }))
      .sort((a, b) => (a.reason > b.reason ? 1 : -1));

  const renderTableFooter: () => JSX.Element = () => {
    const deductionSum: number = (adjustments ? adjustments : [])
      .filter((a) => a.type === AdjustmentsEnum.DEDUCTION)
      .map((d) => d.amount)
      .reduce(acc, 0);
    const creditSum: number = (adjustments ? adjustments : [])
      .filter((a) => a.type === AdjustmentsEnum.CREDIT)
      .map((d) => d.amount)
      .reduce(acc, 0);
    const sum: number = -deductionSum + creditSum;
    const formattedNegative: string | null =
      sum < 0 ? `-${formatNumber(sum, 2).split('-').join(CurrencySymbolsLookUp[currency])}` : null;

    return (
      <tfoot>
        <tr>
          <td style={{ fontWeight: 600, textAlign: 'right', padding: '8px' }}>
            Total adjustments <span style={{ marginLeft: '4rem' }}>{formattedNegative || sum}</span>
          </td>
        </tr>
      </tfoot>
    );
  };

  const renderTableFooterMobile: () => JSX.Element = () => {
    const deductionSum: number = adjustments
      .filter((a) => a.type === AdjustmentsEnum.DEDUCTION)
      .map((d) => d.amount)
      .reduce(acc, 0);
    const creditSum: number = adjustments
      .filter((a) => a.type === AdjustmentsEnum.CREDIT)
      .map((d) => d.amount)
      .reduce(acc, 0);
    const sum: number = -deductionSum + creditSum;
    const formattedNegative: string | null =
      sum < 0 ? `-${formatNumber(sum, 2).split('-').join(CurrencySymbolsLookUp[currency])}` : null;

    return (
      <p style={{ fontWeight: 600, textAlign: 'right', padding: '8px' }}>
        Total Value <span style={{ marginLeft: '4rem' }}>{formattedNegative || sum}</span>
      </p>
    );
  };

  const renderTable: () => JSX.Element = () =>
    layoutWidth < layoutSizes.maxMobileWidth ? (
      <LedgerAdjustmentMobileWrapper>
        {adjustments.map((data, index, array) => (
          <LedgerAdjustmentMobileRow
            key={index}
            data-automation-id="ledger-detail-adjustments-div-adjustments-mobile"
          >
            <LedgerAdjustmentMobileContainer>
              <LedgerAdjustmentMobileItemLeft>
                <LedgerAdjustmentMobileItemBold data-automation-id="ledger-detail-adjustments-p-adjustment-type-mobile">
                  {data.type}
                </LedgerAdjustmentMobileItemBold>
                <LedgerAdjustmentMobileItemRegular data-automation-id="ledger-detail-adjustments-p-adjustment-reason-mobile">
                  {data.reason}
                </LedgerAdjustmentMobileItemRegular>
              </LedgerAdjustmentMobileItemLeft>
              <LedgerAdjustmentMobileItemRight>
                <LedgerAdjustmentMobileItemBold data-automation-id="ledger-detail-line-items-p-line-item-amount-mobile">
                  {`${data.type === AdjustmentsEnum.DEDUCTION ? '-' : ''}${
                    CurrencySymbolsLookUp[currency]
                  }${formatNumber(data.amount, 2)}`}
                </LedgerAdjustmentMobileItemBold>
                <LedgerAdjustmentMobileItemRegular data-automation-id="ledger-detail-line-items-p-line-item-price-mobile">
                  {data.comment}
                </LedgerAdjustmentMobileItemRegular>
              </LedgerAdjustmentMobileItemRight>
            </LedgerAdjustmentMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LedgerAdjustmentMobileRow>
        ))}
        {renderTableFooterMobile()}
      </LedgerAdjustmentMobileWrapper>
    ) : (
      <DataGrid
        headers={headers}
        data={data}
        ariaLabel="Ledger adjustments table"
        ariaCaption="A table that shows the adjustments."
        totalRows={data.length}
        exportFilename={''}
        footerJSX={renderTableFooter()}
        fixedHeader
        dataAutomationTag="ledger-detail-table-ledger-adjustments"
        hasActions
      />
    );

  return adjustmentLoading || appLoading ? (
    <LoaderInPage />
  ) : (
    <LedgerAdjustmentsWrapper ref={layoutRef} data-testid="sp-ledger-detail-adjustments">
      <LedgerAdjustmentContainer>{layoutWidth > 0 && renderTable()}</LedgerAdjustmentContainer>
    </LedgerAdjustmentsWrapper>
  );
};

export default LedgerDetailAdjustments;
