import { themeColors } from 'assets/theme/style';
import { fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';

export const DocumentUploadDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  margin: 0 auto;
`;

export const DocumentUploadDialogTitle = styled.h1`
  color: ${themeColors.text.light.body};
`;

export const DocumentUploadDialogH5 = styled.h5``;
export const DocumentUploadDialogParagraph = styled.p``;

export const DocumentUploadProgramListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: ${themeColors.greyBlue}15;
  }
`;

export const DocumentUploadDialogWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 72px;
  padding: 16px;
  align-items: center;
`;

export const DocumentUploadDialogLeft = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const DocumentUploadDialogInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
