export enum OnboardingActionsEnum {
  vendorID = 'vendorID',
  personalDetails = 'personalDetails',
  companyDetails = 'companyDetails',
  acceptTermsAndConditions = 'acceptTermsAndConditions',
  bankAccount = 'bankAccount',
  bankDetails = 'bankDetails'
}

export enum OnboardingActionStatusEnum {
  initiated = 'initiated',
  completed = 'completed',
  rejected = 'rejected'
}

export enum OnboardingConfirmationStatesEnum {
  confirmed = 'confirmed',
  disputed = 'disputed',
  rejected = 'rejected'
}

export enum OnboardingArtifactApprovalStatusEnum {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired'
}
