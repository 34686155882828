import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const FileUploadIcon: FC<IconSvgProps> = ({ height = '30', width = '25' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-automation-id="icon-svg-file-upload-icon"
  >
    <path
      d="M7.15625 9C6.74207 9 6.40625 9.33582 6.40625 9.75C6.40625 10.1642 6.74207 10.5 7.15625 10.5H10.1562C10.5704 10.5 10.9062 10.1642 10.9062 9.75C10.9062 9.33582 10.5704 9 10.1562 9H7.15625ZM17.6562 28.5H4.90625C3.25024 28.4979 1.90836 27.156 1.90625 25.5V4.5C1.90836 2.84399 3.25024 1.50211 4.90625 1.5H13.9062V7.00049C13.9087 8.93216 15.4741 10.4975 17.4058 10.5H22.9062V15.75C22.9062 16.1642 23.2421 16.5 23.6562 16.5C24.0704 16.5 24.4062 16.1642 24.4062 15.75V9.75C24.4062 9.55106 24.3272 9.36035 24.1865 9.21973L15.1865 0.219727C15.0459 0.0790099 14.8552 0 14.6562 0H4.90625C2.42206 0.00274665 0.408997 2.01581 0.40625 4.5V25.5C0.408997 27.9842 2.42206 29.9973 4.90625 30H17.6562C18.0704 30 18.4062 29.6642 18.4062 29.25C18.4062 28.8358 18.0704 28.5 17.6562 28.5ZM15.4062 2.56055L21.8458 9H17.4058C16.3018 8.99909 15.4072 8.10443 15.4062 7.00049V2.56055ZM23.6562 24C23.2421 24 22.9062 24.3358 22.9062 24.75V29.25C22.9062 29.2503 22.9062 29.2506 22.9062 29.2508C22.9065 29.6648 23.2422 30.0003 23.6562 30C23.6565 30 23.6568 30 23.6572 30C24.0711 29.9997 24.4065 29.664 24.4062 29.25V24.75C24.4062 24.3358 24.0704 24 23.6562 24ZM23.6562 22.6875C24.174 22.6875 24.5938 22.2677 24.5938 21.75C24.5938 21.2323 24.174 20.8125 23.6562 20.8125C23.1385 20.8125 22.7188 21.2323 22.7188 21.75C22.7188 22.2677 23.1385 22.6875 23.6562 22.6875ZM17.6562 21H7.15625C6.74207 21 6.40625 21.3358 6.40625 21.75C6.40625 22.1642 6.74207 22.5 7.15625 22.5H17.6562C18.0704 22.5 18.4062 22.1642 18.4062 21.75C18.4062 21.3358 18.0704 21 17.6562 21ZM17.6562 15H7.15625C6.74207 15 6.40625 15.3358 6.40625 15.75C6.40625 16.1642 6.74207 16.5 7.15625 16.5H17.6562C18.0704 16.5 18.4062 16.1642 18.4062 15.75C18.4062 15.3358 18.0704 15 17.6562 15Z"
      fill="url(#paint0_linear_4198_96689)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4198_96689"
        x1="24.5242"
        y1="-2.62502"
        x2="4.0127"
        y2="-1.60572"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56A4ED" />
        <stop offset="1" stopColor="#0842C1" />
      </linearGradient>
    </defs>
  </svg>
);

export default FileUploadIcon;
