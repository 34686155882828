import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import { FC } from 'react';
import {
  AlertDialogButtonWrapper,
  AlertDialogDialogStyled,
  AlertDialogText,
  AlertDialogTitle
} from './styled';
import { Description } from '@mui/icons-material';
import Divider from 'components/common/divider';

interface AlertDialogProps {
  title: string;
  description: string;
  primaryButtonText?: string;
  secondButtonText?: string;
  open: boolean;
  clickHandler: () => void;
  clickSecondHandler: () => void;
  height?: string;
  width?: string;
}

const AlertDialog: FC<AlertDialogProps> = ({
  title,
  description,
  primaryButtonText = 'Confirm',
  secondButtonText = 'Back',
  open = false,
  clickHandler,
  clickSecondHandler,
  height = '230px',
  width = '400px'
}) => (
  <AlertDialogDialogStyled
    open={open}
    onClose={clickHandler}
    disableEscapeKeyDown
    height={height}
    width={width}
  >
    <AlertDialogTitle>{title}</AlertDialogTitle>
    <Divider margin="20px 0" />
    <AlertDialogText>{description}</AlertDialogText>
    <AlertDialogButtonWrapper>
      <PrimaryButton
        text={primaryButtonText}
        backgroundColor={themeColors.bg.light.surfaceInvert}
        clickHandler={() => clickHandler()}
        padding="16px"
        width="fit-content"
        height="54px"
      ></PrimaryButton>
      <SecondaryButton
        text={secondButtonText}
        clickHandler={() => clickSecondHandler()}
        padding="16px"
        width="fit-content"
        height="54x"
      ></SecondaryButton>
    </AlertDialogButtonWrapper>
  </AlertDialogDialogStyled>
);

export default AlertDialog;
