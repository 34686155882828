import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const ConnectionsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${isMobile() ? '1fr' : 'fit-content(354px) auto'};
  width: 100%;
  margin: 0 auto;
`;

export const ConnectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  width: ${isMobile() ? '100%' : '80%'};
  min-width: ${isMobile() ? 'auto' : '760px'};
  max-width: 1200px;
`;

export const ConnectionsPageTitle = styled.h2`
  display: ${isMobile() ? 'none' : 'block'};
`;

export const ConnectionsFlatCardValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typography.captionRegular};
`;

export const ConnectionsFlatCardIcon = styled.img`
  margin: 0;
`;

export const ConnectionsNotConnectedTitle = styled.h4``;

export const ConnectionsNotConnectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ConnectionsNotConnectedContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColors.bg.light.page};
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 50px;
  gap: 30px;
`;

export const ConnectionsNotConnectedButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ConnectionsNotConnectedButton = styled.div`
  display: flex;
  width: ${isMobile() ? '' : 'fit-content'};
`;

export const ConnectionsNotConnectedButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const ConnectionsNotConnectedButtonText = styled.p``;

export const ConnectionsNotConnectedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ConnectionsNotConnectedContentTitle = styled.h3``;

export const ConnectionsNotConnectedContentText = styled.p``;
