import { FC } from 'react';
import CustomLink from 'components/common/links/CustomLink';
import {
  ItemCardContainer,
  ItemCardTitle,
  ItemCardValue,
  ItemCardList,
  ItemCardListItem
} from './styled';

export interface ItemListProps {
  name: string;
  path?: string;
}

export interface ItemCardProps {
  title: string;
  titleBold?: boolean;
  itemLinkStyle?: boolean;
  value?: string;
  list?: ItemListProps[] | undefined;
}

const ItemCard: FC<ItemCardProps> = ({
  title,
  value,
  list,
  titleBold = false,
  itemLinkStyle = false
}) => {
  return (
    <ItemCardContainer>
      <ItemCardTitle titleBold={titleBold} data-automation-id="item-card-p-title">
        {title}
      </ItemCardTitle>
      {list ? (
        <ItemCardList>
          {list.map((item: ItemListProps, index) => (
            <span key={index}>
              {!item.path && (
                <ItemCardListItem data-automation-id={`item-card-p-value`}>
                  {item.name}
                </ItemCardListItem>
              )}
              {item.path && <CustomLink title={item.name || ''} link={item.path} />}
            </span>
          ))}
        </ItemCardList>
      ) : (
        <ItemCardValue data-automation-id="item-card-p-value">{value}</ItemCardValue>
      )}
    </ItemCardContainer>
  );
};

export default ItemCard;
