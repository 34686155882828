import OnboardingActionEventPut from 'api/classes/onboardingActionEventPut';
import { IOnboardingEntityEvent } from 'api/interfaces/onboarding/onboarding.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import { AxiosResponse } from 'axios';
import {
  OnboardingActionsEnum,
  OnboardingActionStatusEnum
} from 'lib/enums/onboarding/onboarding.enum';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';
import {
  ONBOARDING_ADD_BANK_ACCOUNT,
  ONBOARDING_ADD_BANK_ACCOUNT_FAILURE,
  ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS,
  UPDATE_ONBOARDING_ACTION_EVENT,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE,
  UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS,
  UPDATE_ONBOARDING_ACTION_EVENT_FAILURE,
  UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS
} from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

export const updateOnboardingActionEvent: (action: {
  payload: { actionName: keyof typeof OnboardingActionsEnum; data: OnboardingActionEventPut };
}) => Promise<IOnboardingEntityEvent> = async (action) => {
  const onboardingApi = new OnboardingApi();
  const { actionName, data } = action.payload;
  const res: AxiosResponse<IOnboardingEntityEvent> =
    await onboardingApi.putEntityOnboardingActionEvent(actionName, data);
  return res.data;
};

export const updateOnboardingActionEventByProgram: (action: {
  payload: {
    actionName: keyof typeof OnboardingActionsEnum;
    data: OnboardingActionEventPut;
    programId: string;
  };
}) => Promise<IOnboardingEntityEvent> = async (action) => {
  const onboardingApi = new OnboardingApi();
  const { actionName, data, programId } = action.payload;
  const res: AxiosResponse<IOnboardingEntityEvent> =
    await onboardingApi.putEntityOnboardingActionEventByProgram(actionName, data, programId);

  res.data.programId = programId;
  
  return res.data;
};

export const postNewBankAccount: (action: {
  payload: { data: FormData; artifactId: string; programId: string };
}) => Promise<any> = async (action) => {
  const onboardingApi = new OnboardingApi();
  const res: AxiosResponse<any> = await onboardingApi.postBankAccount(
    action.payload.data,
    action.payload.artifactId
  );
  await onboardingApi.putEntityOnboardingActionEventByProgram(
    OnboardingActionsEnum.bankAccount,
    new OnboardingActionEventPut(
      '',
      OnboardingActionStatusEnum.completed,
      OnboardingActionStatusEnum.initiated
    ),
    action.payload.programId
  );
  await onboardingApi.putArtifactReady(action.payload.artifactId);
  return res.data;
};

//===================================================
//                      EPICS
//===================================================

const updateOnboardingActionEvent$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ONBOARDING_ACTION_EVENT),
    switchMap((action) =>
      from(updateOnboardingActionEvent(action)).pipe(
        map((payload) => ({ type: UPDATE_ONBOARDING_ACTION_EVENT_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: UPDATE_ONBOARDING_ACTION_EVENT_FAILURE,
            payload: error.response?.data?.detail || ''
          })
        )
      )
    )
  );

const updateOnboardingActionEventByProgram$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM),
    switchMap((action) =>
      from(updateOnboardingActionEventByProgram(action)).pipe(
        map((payload) => ({ type: UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: UPDATE_ONBOARDING_ACTION_EVENT_BY_PROGRAM_FAILURE,
            payload: error.response?.data?.detail || ''
          })
        )
      )
    )
  );

const postNewBankAccount$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ONBOARDING_ADD_BANK_ACCOUNT),
    switchMap((action) =>
      from(postNewBankAccount(action)).pipe(
        map((payload) => ({ type: ONBOARDING_ADD_BANK_ACCOUNT_SUCCESS, payload })),
        catchError((error) =>
          of({
            type: ONBOARDING_ADD_BANK_ACCOUNT_FAILURE,
            payload: error.response?.data?.detail || ''
          })
        )
      )
    )
  );

export default combineEpics(
  updateOnboardingActionEvent$,
  postNewBankAccount$,
  updateOnboardingActionEventByProgram$
);
