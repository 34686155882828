import { boxShadow, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { NotificationProps } from './Notification';
import { isMobile } from 'lib/helpers/mobile';

export const NotificationWrapper = styled.div<NotificationProps>`
  padding: ${({ padding }) => (padding ? padding : '16px 12px')};
  border-radius: 16px;
  /* box-shadow: ${({ noShadow }) => (!noShadow && boxShadow)}; */
  min-height: 52px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: ${({ border, borderColor }) => (border ? `1px solid ${borderColor ? borderColor : themeColors.bg.info }` : `none`)};
  background-color: ${({backgroundColor}) => (backgroundColor ? backgroundColor : themeColors.white )};
`;

export const NotificationLeftSegment = styled.div<NotificationProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ description, descriptionJSX }) => (!description && !descriptionJSX ? 'center' : 'flex-start')};
  gap: 8px;
  width: ${({ fullContent }) => (fullContent ? '100%' : 'auto')};
`;

export const NotificationIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotificationDescriptionContainer = styled.div<NotificationProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: ${({ fullContent }) => (fullContent ? '100%' : 'auto')};
`;

export const NotificationDescriptionTitle = styled.p`
  font-size: ${fontSizes.small};
  font-weight: ${typography.bodySemiBold};
`;

export const NotificationDescriptionTitleLarge = styled.p`
  margin-top: 2px;
  font-size: ${fontSizes.regular};
  font-weight: ${typography.bodySemiBold};
`;

export const NotificationDescriptionText = styled.p`
  ${typography.captionRegular};
  white-space: pre-line;
`;

export const NotificationDescriptionLargeText = styled.p`
  white-space: pre-line;
  font-size: ${fontSizes.regular};
`;


export const NotificationDescriptionJSXText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationButtonsContainer = styled.div<NotificationProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotificationButtonContainer = styled.div<NotificationProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ centeredButton }) => (centeredButton ? '8px' : 0)};
  height: 100%;
`;

export const NotificationButtonText = styled.p`
  ${typography.bodySmallSemiBold};
`;

export const NotificationCloseContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px;
`;

export const NotificationContainer = styled.div<NotificationProps>`
  display: flex;
  flex-direction: ${isMobile() ? ({ hasButton }) => (hasButton ? 'column' : 'row') : 'row'};
  align-items: ${({ centeredButton }) => (centeredButton ? 'center' : 'flex-start')};
  justify-content: space-between;
`;
