/* eslint-disable react-hooks/exhaustive-deps */
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import WarningIcon from 'assets/icons/WarningIcon';
import { layoutSizes, themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import DataGrid from 'components/DataGrid';
import Chip from 'components/common/Chip';
import Divider from 'components/common/divider';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import FullViewDialog from 'components/dialogs/full-view/FullViewDialog';
import LedgerDetail from 'components/ledger/LedgerDetail';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { _matchingDiscrepantStatus } from 'lib/constants/matching';
import { ledgerInvoiceHeader } from 'lib/data/ledger/ledgerInvoiceHeader';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { getInvoiceStatusTooltipText } from 'lib/tooltips/tooltips';
import { DateTime } from 'luxon';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router-dom';
import { RESET_LEDGER_INVOICE } from 'store/actions';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import {
  LedgerInvoicesContainer,
  LedgerInvoicesMobileContainer,
  LedgerInvoicesMobileItemBold,
  LedgerInvoicesMobileItemLeft,
  LedgerInvoicesMobileItemRegular,
  LedgerInvoicesMobileItemRight,
  LedgerInvoicesMobileWrapper,
  LedgerInvoicesNoData,
  LedgerInvoicesSubtitle,
  LedgerInvoicesTitle,
  LedgerInvoicesWrapper
} from './styled';
import PurchaseOrderApi from 'api/ledger/purchaseOrder.api';
import { store } from 'store';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import InvoiceApi from 'api/ledger/invoices.api';

const LedgerInvoice: FC = () => {
  const dispatch = useDispatch();
  const params: Readonly<Params<string>> = useParams();
  const layoutRef: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const [layoutWidth, setLayoutWidth]: [number, Dispatch<SetStateAction<number>>] =
    useState<number>(0);
  const [poNumber, setPoNumber] = useState<string>('');
  const [invoiceLedgerData, setInvoiceLedgerData] = useState<ILedgerInvoice[]>([]);
  const [data, setData] = useState<DataGridRowItem[]>([]);
  const [ledgerDetailOpen, setLedgerDetailOpen] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [ledgerInvoiceId, setLedgerInvoiceId] = useState<string>('');
  const {
    purchaseOrderLedger,
    invoiceLedger,
    allProgramsWithDetails
  }: {
    purchaseOrderLedger: ILedgerPurchaseOrder[];
    invoiceLedger: ILedgerInvoice[];
    allProgramsWithDetails: ProgramConfig[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const clientWidth: number = layoutRef?.current?.clientWidth || 0;

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_LEDGER_INVOICE });
    };
  }, []);

  useEffect(() => {
    if (!params.purchaseOrderId || !purchaseOrderLedger) return;
    getPONumber();
  }, [params.purchaseOrderId, purchaseOrderLedger]);

  useEffect(() => {
    if (!poNumber) return;
    getLedgerInvoiceId();
  }, [poNumber]);

  useEffect(() => {
    if (!invoiceLedgerData) return;
    setData(mapInvoiceLedgerToGridModel());
  }, [invoiceLedgerData]);

  useEffect(() => {
    getLayoutSize();
    window.addEventListener('resize', getLayoutSize);

    return () => {
      window.removeEventListener('resize', getLayoutSize);
    };
  }, [clientWidth]);

  const getLayoutSize: () => void = () => {
    const newWidth: number = layoutRef?.current?.clientWidth || clientWidth;
    setLayoutWidth(newWidth);
  };

  const getPONumber: () => void = async () => {
    const id = params.purchaseOrderId;
    if (!id) return;
    const poApi = new PurchaseOrderApi(store);
    const response = await poApi.getPurchaseOrderById(id);
    setPoNumber(response.data.value[0].data?.poNumber || '');
  };

  const getLedgerInvoiceId: () => void = async () => {
    const invoiceApi = new InvoiceApi(store);
    setPageLoading(false);

    const response = await invoiceApi.getInvoiceLedger(allProgramsWithDetails.map((p) => p.id));

    const data: ILedgerInvoice[] = response.data.value.filter((inv) =>
      (inv.data.lineItems || []).find((l) => l.poNumber === poNumber)
    );

    setInvoiceLedgerData(data || []);
    setPageLoading(true);
  };

  const mapInvoiceLedgerToGridModel: () => DataGridRowItem[] = () =>
    invoiceLedgerData.map((inv) => ({
      invoiceId: inv.invoiceId,
      invoiceNumber: inv.invoiceNumber,
      invoiceStatus: inv.linkedProgram.invoiceStatus || '-',
      isDiscrepant: inv.linkedProgram.matchingStatus === _matchingDiscrepantStatus,
      invoiceDate: formatDateTime(inv.createdAt || '', `${_DATE_FORMAT} HH:mm`),
      currency: inv.invoiceCurrency,
      totalAmount: formatNumber(inv.data.amount || 0, 2),
      dueDate: formatDateTime(inv.data.paymentDueDate || '', _DATE_FORMAT),
      maturityDate: formatDateTime(
        DateTime.fromFormat(inv.maturityDate || '', _DATE_FORMAT).toISO(),
        _DATE_FORMAT
      ),
      paymentId: '-'
    }));

  const closeFullViewDialog: () => void = () => {
    setLedgerDetailOpen(false);
    ledgerInvoiceId && setLedgerInvoiceId('');
  };

  const openFullViewDialog: (row: string) => void = (row) => {
    layoutWidth < layoutSizes.maxMobileWidth ? setLedgerInvoiceId(row) : setLedgerInvoiceId(row[0]);
    setLedgerDetailOpen(true);
  };

  const renderFullViewDialog: (id: string) => JSX.Element = (id) => (
    <FullViewDialog
      open={ledgerDetailOpen}
      clickHandler={closeFullViewDialog}
      dialogContent={
        <LayoutViewContainer size="small" margin="0 auto">
          <LedgerDetail
            type={AssetEnum.INVOICE}
            showSummaryAccordions
            showEntities
            hideBanner
            paramId={id}
          />
        </LayoutViewContainer>
      }
    />
  );

  const renderTable: () => JSX.Element = () =>
    layoutWidth < layoutSizes.maxMobileWidth ? (
      <>
        {data.map((data, index, array) => (
          <LedgerInvoicesMobileWrapper key={index}>
            <LedgerInvoicesMobileContainer onClick={() => openFullViewDialog(data.invoiceId)}>
              <LedgerInvoicesMobileItemLeft>
                <LedgerInvoicesMobileItemBold>{data.invoiceNumber}</LedgerInvoicesMobileItemBold>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                  <Chip
                    type={data.invoiceStatus}
                    color={themeColors.text.primary}
                    radius="6px"
                    fontSize={fontSizes.small}
                    height="18px"
                    semibold="bold"
                    testingTag="ledger-invoice"
                    tooltipText={getInvoiceStatusTooltipText(data?.invoiceStatus)}
                  />
                  {!data.matchingStatus && (
                    <IconTooltip
                      tooltipText="An issue was found on this invoice during the matching process. Your buyer will review the issue(s) and accept it for payment or contact you to amend the invoice."
                      children={<WarningIcon color={themeColors.icon.error} />}
                    />
                  )}
                </div>
              </LedgerInvoicesMobileItemLeft>
              <LedgerInvoicesMobileItemRight>
                <LedgerInvoicesMobileItemBold>{data.invoiceDate}</LedgerInvoicesMobileItemBold>
                <LedgerInvoicesMobileItemRegular>
                  Total Amount: {CurrencySymbolsLookUp[data.currency || ''] + data.totalAmount}
                </LedgerInvoicesMobileItemRegular>
              </LedgerInvoicesMobileItemRight>
            </LedgerInvoicesMobileContainer>
            {array.length > index + 1 && <Divider />}
          </LedgerInvoicesMobileWrapper>
        ))}
      </>
    ) : (
      <DataGrid
        headers={ledgerInvoiceHeader}
        data={data}
        ariaLabel="Ledger Ledger Invoices Table"
        ariaCaption="A table that shows the ledger invoices."
        totalRows={data.length}
        rowClickHandler={openFullViewDialog}
        exportFilename={''}
        fixedHeader
        dataAutomationTag="invoice-ledger-detail-table-purchase-orders"
      />
    );

  const renderLedgerInvoiceSummary: () => JSX.Element = () => (
    <LedgerDetail
      type={AssetEnum.INVOICE}
      showSummaryAccordions
      showEntities
      paramId={invoiceLedgerData[0]?.invoiceId}
    />
  );

  const renderLedgerInvoiceList: () => JSX.Element = () => (
    <>
      {ledgerInvoiceId && renderFullViewDialog(ledgerInvoiceId)}
      <LedgerInvoicesWrapper>
        <LedgerInvoicesContainer>
          <LedgerInvoicesTitle data-automation-id="ledger-invoices-h4-page-title">
            Invoices
          </LedgerInvoicesTitle>
          <LedgerInvoicesSubtitle data-automation-id="ledger-invoices-p-page-description">
            There are multiple invoices linked to this purchase order.
          </LedgerInvoicesSubtitle>
          {layoutWidth > 0 && renderTable()}
        </LedgerInvoicesContainer>
      </LedgerInvoicesWrapper>
    </>
  );

  const renderLedgerInvoice: () => JSX.Element = () => (
    <LedgerInvoicesWrapper ref={layoutRef}>
      {data.length > 0 ? (
        data.length === 1 ? (
          renderLedgerInvoiceSummary()
        ) : (
          renderLedgerInvoiceList()
        )
      ) : (
        <LedgerInvoicesNoData data-automation-id="ledger-invoices-h5-no-data">
          Invoice unavailable.
        </LedgerInvoicesNoData>
      )}
    </LedgerInvoicesWrapper>
  );

  return pageLoading ? renderLedgerInvoice() : <LoaderFullPage />;
};

export default LedgerInvoice;
