import { Dialog } from '@mui/material';
import { FC } from 'react';
import CompanyUserManagementAction from 'views/CompanyDetails/CompanyUserManagement/CompanyUserManagementAction';

interface UserManagementActionDialogProps {
  open: boolean;
  status: string;
  clickHandler: () => void;
}

const UserManagementActionDialog: FC<UserManagementActionDialogProps> = ({
  open = false,
  status,
  clickHandler
}) => (
  <Dialog
    open={open}
    onClose={clickHandler}
    data-automation-id="user-management-active-dialog-div-container"
  >
    <CompanyUserManagementAction status={status} closeHandler={clickHandler} />
  </Dialog>
);

export default UserManagementActionDialog;
