/* eslint-disable react-hooks/exhaustive-deps */
import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import Breadcrumbs from 'components/breadcrumbs';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import FundingRequestDialog from 'components/dialogs/full-view/FundingRequestDialog';
import SessionTimeoutDialog from 'components/dialogs/SessionTimeoutDialog';
import ErrorSnackBar from 'components/error';
import Footer from 'components/Footer';
import FullViewEntity from 'components/FullViewEntity';
import Navbar from 'components/Navbar';
import SubNav from 'components/Navbar/SubNav';
import Layout from 'layout';
import ScrollToTop from 'layout/hoc/ScrollToTop/ScrollToTop';
import { DateTime } from 'luxon';
import { FC, Suspense, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import RoutesTracker from 'routes';
import forbiddenAppRoutes from 'routes/forbidden.routes';
import routes from 'routes/index.routes';
import onboardingRoutes from 'routes/onboarding.routes';
import tenantRoutes from 'routes/tenant.routes';

import { ProgramConfig } from 'api/interfaces/program/program.interface';
import OnboardingApi from 'api/onboarding/onboarding.api';
import { getCookie, setCookie } from 'lib/helpers/cookieHelper';
import { oidcConfig } from 'lib/oidc/oidc.config';
import { User } from 'oidc-client-ts';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import {
  FETCH_PROGRAM_OWNER_DETAILS,
  INITIALISE_APP,
  SET_REDIRECT_PATH,
  SET_TENANT,
  UPDATE_ACCESS_TOKEN,
  UPDATE_CURRENT_PATH,
  UPDATE_REQUEST_ERROR
} from 'store/actions';
import { RESET_PO_LOADING } from 'store/actions/purchaseOrder';
import { AppWrapper, ViewWrapper } from 'styled';
import { IRoute } from 'utils/interfaces/route/route.interface';
import SystemDown from 'views/SystemDown';

const _signOutTime: number = 300 * 60 * 1000;

const App: FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [appLockOut, setAppLockOut] = useState<boolean>(false);
  const [initCount, setInitCount] = useState<number>(0);
  const [tenantSelected, setTenantSelected] = useState<boolean>(false);
  const selectedPlatformName: string = 'supplier-platform';
  const {
    error: errorMessage,
    onboardingComplete,
    allProgramsWithDetails,
    tenant,
    loading
  }: {
    error: string;
    onboardingComplete: boolean | null;
    tenant: string | null;
    allProgramsWithDetails: ProgramConfig[];
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    entityType,
    entityDetails
  }: {
    entityType: 'buyer' | 'funder' | undefined;
    entityDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.entity);

  const {
    error
  }: {
    error: string;
  } = useSelector((state: RootStateOrAny) => state.program);

  const {
    earlyPaymentDialogOpen
  }: {
    earlyPaymentDialogOpen: boolean;
  } = useSelector((state: RootStateOrAny) => state.fundingRequest);

  let session: NodeJS.Timer;
  const [timeOut, setTimeOut] = useState<boolean>(false);
  let warnTimeout: any;
  let logoutTimeout: any;
  const onLogout: () => Promise<void> = async () => console.log('SIGN OUT');
  // const onLogout: () => Promise<void> = async () => oktaAuth.signOut();

  useEffect(() => {
    const oidcStorage = localStorage.getItem(
      `oidc.user:${(oidcConfig as any).authority}:${(oidcConfig as any).clientId}`
    );
    if (oidcStorage) {
      User.fromStorageString(oidcStorage);
    }

    const pathLinkShare: string = (location.pathname + location.search).slice(1);
    const entryWithTenant: string | undefined = pathLinkShare.split('app/')[1]?.split('/')[0];
    const selectedPlatform: string = getCookie(selectedPlatformName);

    console.log('PATH', entryWithTenant);
    console.log('PATH', selectedPlatform);

    if (selectedPlatform) {
      dispatch({ type: SET_TENANT, payload: entryWithTenant || selectedPlatform });
      setTenantSelected(true);
    }

    // if (entryWithTenant) {
    //   dispatch({ type: SET_TENANT, payload: entryWithTenant });
    //   setTenantSelected(true);
    //   setCookie(selectedPlatformName, entryWithTenant, 365);
    //   dispatch({ type: SET_REDIRECT_PATH, payload: pathLinkShare });
    // }

    // if (pathLinkShare && !pathLinkShare.includes('login/callback')) {
    //   dispatch({ type: SET_REDIRECT_PATH, payload: pathLinkShare });
    // }
    dispatch({ type: RESET_PO_LOADING });
  }, []);

  useEffect(() => {
    if (!onboardingComplete) return require('assets/css/onboardingStyles.css');
    require('assets/css/styles.css');
  }, [onboardingComplete]);

  useEffect(() => {
    if (tenant && auth.isAuthenticated && auth.user?.access_token ) {
      console.log('INIT', auth);
      setTimeout(() => {
        dispatch({ type: INITIALISE_APP, payload: auth.user?.access_token });
        dispatch({ type: UPDATE_CURRENT_PATH, payload: { path: window.location.pathname } });
        dispatch({ type: FETCH_PROGRAM_OWNER_DETAILS, payload: auth.user?.access_token });
      }, 500);

      setInitCount(initCount + 1);
    }

    return () => {
      dispatch({ type: UPDATE_REQUEST_ERROR, payload: '' });
    };
  }, [auth.user?.access_token, tenant]);

  useEffect(() => {
    if (errorMessage.length > 0) dispatch({ type: UPDATE_REQUEST_ERROR, payload: errorMessage });
    if (errorMessage.includes('403')) setAppLockOut(true);
  }, [errorMessage]);

  useEffect(() => {
    const matchedProgramPOFlipRule =
      allProgramsWithDetails.map(
        (p) =>
          p?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value?.ruleByAssetType?.PURCHASE_ORDER
      ) || null;

    const matchedProgramInvoiceFlipRule =
      allProgramsWithDetails.map(
        (p) => p?.rules.find((r) => r.type === 'OA_ASSET_FLIP')?.value?.ruleByAssetType?.INVOICE
      ) || null;

    const hasProgramLevelPOFlipOn =
      matchedProgramPOFlipRule.filter((r) => r?.suppliers?.length > 0 || r?.enabledForAllSuppliers)
        .length > 0;

    const hasProgramLevelInvoiceFlipOn =
      matchedProgramInvoiceFlipRule.filter(
        (r) => r?.suppliers?.length > 0 || r?.enabledForAllSuppliers
      ).length > 0;

    if (hasProgramLevelPOFlipOn) setCookie('spPoFlipEnabled', `true`, 365);
    if (hasProgramLevelInvoiceFlipOn) setCookie('spInvoiceFlipEnabled', `true`, 365);
  }, [allProgramsWithDetails]);

  useEffect(() => {
    const logout: () => void = () => {
      setTimeOut(true);
    };

    const setTimeouts: () => void = () => {
      logoutTimeout = setTimeout(logout, _signOutTime);
    };

    const clearTimeouts: () => void = () => {
      if (warnTimeout) clearTimeout(warnTimeout);
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimeout: () => void = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  const renderRoutes: () => JSX.Element[] = () => {
    let routeSet: IRoute[] = [];
    if (appLockOut) routeSet = forbiddenAppRoutes;
    if (!appLockOut && (tenantSelected || tenant))
      routeSet = onboardingComplete ? routes : onboardingRoutes;
    if (!appLockOut && !tenantSelected && !tenant) routeSet = tenantRoutes;

    return routeSet.map((parentRoutes, i) => (
      <Route key={i} path={parentRoutes.path} element={parentRoutes.component}>
        {parentRoutes.secure && <Route path="" element={parentRoutes.secure} />}
      </Route>
    ));
  };

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  return (
    <AppWrapper>
      {/* <ConnectedRouter history={history}> */}
      <RoutesTracker>
        <Navbar />
        <SubNav />
        <ViewWrapper>
          {onboardingComplete && <Breadcrumbs />}
          <Layout>
            <ScrollToTop />
            <Suspense fallback={<LoaderFullPage />}>
              {onboardingComplete && error && error.includes('INTERNAL_SP_APP') ? (
                <SystemDown />
              ) : loading ? (
                <LoaderFullPage />
              ) : (
                <Routes>{renderRoutes()}</Routes>
              )}
            </Suspense>
          </Layout>
        </ViewWrapper>
        {timeOut && (
          <SessionTimeoutDialog
            open={true}
            clickHandler={() => onLogout()}
            data-testid="sp-full-view-dialog-session-timeout"
          />
        )}
        <Footer />
      </RoutesTracker>
      {!!entityDetails && <FullViewEntity entityOpen={!!entityDetails} entityType={entityType} />}
      {earlyPaymentDialogOpen && <FundingRequestDialog open={earlyPaymentDialogOpen} />}
      <ErrorSnackBar />
      {/* </ConnectedRouter> */}
    </AppWrapper>
  );
};

export default App;
