import { SET_FUNDING_COSTS, SET_MODIFIED_DATA, SET_ORIGINAL_DATA } from 'store/actions';
import { FundingCosts, GroupedItem } from 'utils/interfaces/early-payment/earlyPayment.interface';
import { ReducerAction } from '.';

interface EarlyPaymentReducerStateProps {
  modifiedData: GroupedItem[];
  originalData: GroupedItem[];
  fundingCosts: FundingCosts;
}

const DEFAULT_STATE: EarlyPaymentReducerStateProps = {
  modifiedData: [],
  originalData: [],
  fundingCosts: {
    totalBaseRateCost: 0,
    totalMarginCost: 0
  }
};

const earlyPaymentReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case SET_MODIFIED_DATA:
      return {
        ...state,
        modifiedData: payload
      };
    case SET_ORIGINAL_DATA:
      return {
        ...state,
        originalData: payload
      };
    case SET_FUNDING_COSTS:
      return {
        ...state,
        fundingCosts: payload
      };
    default:
      return state;
  }
};

export { earlyPaymentReducer };
export type { EarlyPaymentReducerStateProps };
