/* eslint-disable react-hooks/exhaustive-deps */
import { ILedgerInvoice, LedgerInvoiceData } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { ILogisticsRuleSubtemplateField } from 'api/interfaces/program/program.interface';
import Tabs from 'components/Tabs';
import BaseCard from 'components/common/cards/BaseCard';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import AlertDialog from 'components/dialogs/alertDialog';
import { LedgerDetailHeaderProps } from 'components/ledger/LedgerDetail/LedgerDetailHeader';
import LedgerNotifications from 'components/ledger/LedgerNotifications';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { purchaseOrderTabs } from 'lib/data/purchaseOrderTabs';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { Dispatch, FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import {
  GET_G2F_CONNECTIVITY,
  RESET_CREATE_NEW_INVOICE_ERROR,
  RESET_GATEWAY_TO_FINANCE_ID,
  RESET_NEW_INVOICE_ID,
  SET_BREADCRUMB,
  SET_DOCUMENT_FOR_VIEW,
  SET_PO_FOR_VIEW,
  SHOW_NEW_INVOICE_BAR
} from 'store/actions';
import { ReducerAction } from 'store/reducers';
import { TabsItem } from 'utils/interfaces/tabs/tabs.interface';

const PurchaseOrderDetail: FC = () => {
  const [searchParams]: readonly [
    URLSearchParams,
    (
      nextInit: URLSearchParamsInit,
      navigateOptions?:
        | {
            replace?: boolean | undefined;
            state?: any;
          }
        | undefined
    ) => void
  ] = useSearchParams();
  const navigate = useNavigate();
  const searchTab: string | null = searchParams.get('tab');
  const params: Readonly<Params<string>> = useParams();
  const dispatch: Dispatch<ReducerAction> = useDispatch();
  const [defaultTab, setDefaultTab] = useState<number>(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const {
    loading: appLoading,
    g2fFeatureHidden
  }: {
    loading: boolean;
    g2fFeatureHidden: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  const {
    draftInvoiceModal,
    draftPurchaseOrderId,
    completed
  }: {
    draftInvoiceModal: ILogisticsRuleSubtemplateField[];
    draftPurchaseOrderId: string;
    completed: boolean;
  } = useSelector((state: RootStateOrAny) => state.createInvoice);
  const {
    documentForViewHeaderData
  }: {
    documentForViewHeaderData: {
      header: LedgerDetailHeaderProps | null;
      data: ILedgerInvoice | ILedgerPurchaseOrder | null;
    } | null;
  } = useSelector((state: RootStateOrAny) => state.context);

  const tabsMenu: TabsItem[] = purchaseOrderTabs;

  const programOpenAccount: boolean = useCheckProgramsContainsType(ProgramType.OPEN_ACCOUNT);

  useEffect(() => {
    if (draftInvoiceModal) {
      confirmResetDraft();
      //setConfirmDialogOpen(!confirmDialogOpen);
    }
    if (completed) {
      dispatch({ type: RESET_NEW_INVOICE_ID });
      dispatch({ type: RESET_CREATE_NEW_INVOICE_ERROR });
    }

    dispatch({ type: RESET_GATEWAY_TO_FINANCE_ID });
    dispatch({ type: SHOW_NEW_INVOICE_BAR });
    dispatch({ type: SET_PO_FOR_VIEW, payload: null });
    if (!g2fFeatureHidden) dispatch({ type: GET_G2F_CONNECTIVITY });

    return () => {
      dispatch({ type: SET_DOCUMENT_FOR_VIEW, payload: null });
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: SET_BREADCRUMB, payload: null });
    };
  }, []);

  useEffect(() => {
    searchTab && setDefaultTab(Number(searchTab));
  }, [searchTab]);

  const confirmResetDraft: () => void = () => {
    dispatch({ type: RESET_NEW_INVOICE_ID });
    dispatch({ type: RESET_CREATE_NEW_INVOICE_ERROR });
    //setConfirmDialogOpen(!confirmDialogOpen);
  };

  const mapTabs: () => TabsItem[] = () => {
    if (programOpenAccount) {
      return tabsMenu.map((tab) => tab);
    } else {
      return tabsMenu
        .filter(
          (f) => f.text !== 'Purchase order' && f.text !== 'Logistic' && f.text !== 'Matching'
        )
        .map((tab) => tab);
    }
  };

  const toggleConfirmClickHandler: () => void = () => {
    setConfirmDialogOpen(!confirmDialogOpen);
    navigate(`/purchase-orders/${draftPurchaseOrderId}?tab=1`);
  };

  return (
    <LayoutViewContainer size="xlarge" data-testid="sp-purchase-order-detail-container">
      {documentForViewHeaderData && (
        <LedgerNotifications matchingClickHandler={() => setDefaultTab(3)} />
      )}

      {/* {confirmDialogOpen && (
        <AlertDialog
          title="Are you sure?"
          description="If you press 'Continue', your current draft invoice will be deleted."
          primaryButtonText="Continue"
          secondButtonText="Go to draft"
          open={confirmDialogOpen}
          clickHandler={confirmResetDraft}
          clickSecondHandler={toggleConfirmClickHandler}
        />
      )} */}
      {appLoading ? (
        <LoaderFullPage />
      ) : (
        <>
          <Tabs
            tabItems={mapTabs()}
            documentName="Purchase order"
            defaultValue={defaultTab}
            testingTagPage="purchase-order-detail"
          />
          <BaseCard noBorder noPadding />
        </>
      )}
    </LayoutViewContainer>
  );
};

export default PurchaseOrderDetail;
