import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { Funding } from 'api/interfaces/fundings/fundings.interface';
import { ILedgerInvoice, ODataLedgerInvoices } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { LedgerPayment } from 'api/interfaces/ledger/ledgerPayment.interface';
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import FundingApi from 'api/ledger/funding.api';
import InvoiceApi from 'api/ledger/invoices.api';
import PaymentApi from 'api/ledger/payment.api';
import PaymentCircleIcon from 'assets/icons/PaymentCircleIcon';
import { AxiosResponse } from 'axios';
import Divider from 'components/common/divider';
import LoaderInPage from 'components/common/loader/LoaderInPage';
import IconTooltip from 'components/common/tooltip/IconTooltip';
import { FundingRequestIncludedInvoices, FundingRequestSummary } from 'components/funding-request';
import FundingRequestDetails from 'components/funding-request/FundingRequestDetails';
import TransactionHistory from 'components/funding-request/TransactionHistory';
import { _DATE_FORMAT } from 'lib/constants/contants';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { useCheckProgramsContainsType } from 'lib/hooks/useCheckProgramsContainsType';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { store } from 'store';
import { AccordionStyled, PaymentAccordionTitle } from '../styled';
import {
  PaymentContainer,
  PaymentHeaderContainer,
  PaymentHeaderH3,
  PaymentHeaderH3Blue,
  PaymentHeaderSegment,
  PaymentHeaderTypeContainer,
  PaymentInvoicesContainer,
  PaymentInvoicesHeader,
  PaymentInvoicesTotalItems,
  PaymentSubtitle,
  PaymentSummaryContainer,
  PaymentSummaryDetailBoldValue,
  PaymentSummaryDetailContainer,
  PaymentSummaryDetailLabel,
  PaymentSummaryDetailLabelContainer,
  PaymentSummaryDetailRow,
  PaymentSummaryDetailTitle,
  PaymentSummaryDetailValue
} from './styled';

export interface PaymentDetailInvoiceViewModel {
  invoiceNumber: string;
  buyer: string;
  paymentDueDate: string;
  invoiceAmount: string;
  fundingCosts: string;
  purchasePrice: string;
  id: string;
  currencySymbol: string;
}

export interface PaymentDetailProps {
  fundingId?: string;
  loaderInPage?: boolean;
  paymentTabView?: boolean;
}

const TradePaymentDetail: FC<PaymentDetailProps> = ({
  fundingId,
  loaderInPage = false,
  paymentTabView
}) => {
  const [selectedActivity, setSelectedActivity] = useState<Funding>();
  const [invoicesViewModel, setInvoicesViewModel] = useState<PaymentDetailInvoiceViewModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [funder, setFunder] = useState<string>('');
  const [payment, setPayment] = useState<LedgerPayment | null>(null);
  const [createdDateTime, setCreatedDateTime] = useState<string>('');
  const [buyers, setBuyers] = useState<string[]>([]);
  const [bankAccountId, setBankAccountId] = useState<string | null>(null);
  const [paymentSentDate, setPaymentSentDate] = useState<string | null>(null);
  const requestFundings: Funding[] = useSelector((state: RootStateOrAny) => state.app.fundings);
  const appLoading: boolean = useSelector((state: RootStateOrAny) => state.app.loading);
  const allProgramsWithDetails: ProgramConfig[] = useSelector(
    (state: RootStateOrAny) => state.app.allProgramsWithDetails
  );

  const invoiceApi = new InvoiceApi(store);
  const requestFundingsCount: number = requestFundings.length || 0;
  const params: Readonly<Params<string>> = useParams();
  const [searchParams] = useSearchParams();
  const fundingApi = new FundingApi(store);

  const expectedTradePaymentDate: string =
    selectedActivity?.fundingResponse?.expectedTradePaymentDate || '';

  const isProgramFixedCost: boolean = useCheckProgramsContainsType(ProgramType.FIXED_DISCOUNT);

  useEffect(() => {
    setLoading(true);
    if (allProgramsWithDetails) {
      getSelectedActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProgramsWithDetails]);

  const getInvoices: (paymentId: string) => Promise<ILedgerInvoice[]> = async (paymentId) => {
    const res: AxiosResponse<ODataLedgerInvoices> = await invoiceApi.getInvoiceLedgerByFundingId(
      paymentId,
      searchParams.get('programId') || ''
    );
    return res.data.value;
  };

  const getSelectedActivity: () => void = async () => {
    const paymentApi = new PaymentApi(store);
    const ledgerResponse = await paymentApi.getPaymentsLedger(
      allProgramsWithDetails.map((p) => p.id)
    );

    if (!params.paymentId && !fundingId) return;

    const id: string = params.paymentId ? params.paymentId || '' : fundingId || '';
    const paymentId: string = searchParams.get('paymentId') || '';

    setPayment(ledgerResponse.data.value.find((l) => l.id === paymentId) || null);

    let matchedActivity: Funding | null;
    matchedActivity = await fundingApi.getMatchedFundingByProgramIDPoll(
      id,
      searchParams.get('programId') || ''
    );

    const invoices: ILedgerInvoice[] = await getInvoices(id);
    const { funder, createdAt } = matchedActivity;

    setSelectedActivity(matchedActivity);
    setFunder(funder);
    setCreatedDateTime(formatDateTime(createdAt || '', `${_DATE_FORMAT} HH:mm`));
    mapInvoiceData(invoices);
    uniqueBuyers(invoices);
    const { bankAccountId, sentDate } = matchedPayment(matchedActivity, ledgerResponse.data.value);
    setBankAccountId(bankAccountId);
    setPaymentSentDate(sentDate);
  };

  const matchedPayment: (
    matchedActivity: Funding | null,
    ledger: LedgerPayment[]
  ) => { bankAccountId: string | null; sentDate: string | null } = (matchedActivity, ledger) => {
    const matched: number =
      matchedActivity?.linkedPayments && matchedActivity.linkedPayments.length > 0
        ? ledger.findIndex((payment) => payment.id === matchedActivity?.linkedPayments[0].paymentId)
        : -1;

    if (matched < 0)
      return {
        bankAccountId: null,
        sentDate: null
      };
    return {
      bankAccountId: ledger[matched].destinationBankAccountId,
      sentDate: ledger[matched].sentDate
    };
  };

  const uniqueBuyers: (matchedInvoices: ILedgerInvoice[]) => void = (matchedInvoices) => {
    const namesArr: string[] | undefined = matchedInvoices.map((inv) => inv.data.buyerName || '');
    if (!namesArr || !namesArr.length) return [];
    const unique: string[] = Array.from(new Set(namesArr));
    setBuyers(unique);
  };

  const mapInvoiceData: (matchedInvoices: ILedgerInvoice[]) => void = async (matchedInvoices) => {
    const mappedInvoiceViewModel: PaymentDetailInvoiceViewModel[] = matchedInvoices.map((inv) => ({
      invoiceNumber: inv.invoiceNumber,
      buyer: inv.data.buyerName || '',
      paymentDueDate: formatDateTime(inv.data.paymentDueDate || '', 'yyyy-LL-dd'),
      invoiceAmount: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
        inv.data.amount || 0,
        2
      )}`,
      fundingCosts: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
        isProgramFixedCost
          ? (inv.linkedFundings && inv.linkedFundings[0]?.invoiceFixedDiscountCost) || 0
          : inv.activeFunding?.invoiceFundingCost || 0,
        2
      )}`,
      purchasePrice: `${CurrencySymbolsLookUp[inv.invoiceCurrency]}${formatNumber(
        inv.activeFunding?.invoicePurchasePrice || 0,
        2
      )}`,
      id: inv.invoiceId,
      currencySymbol: CurrencySymbolsLookUp[inv.invoiceCurrency]
    }));
    setInvoicesViewModel(mappedInvoiceViewModel);
    setLoading(false);
  };

  const paymentExpectedAlternative: () => string = () => {
    switch (selectedActivity?.status) {
      case 'FUNDING_REQUEST_REJECTED':
        return 'Rejected';
      case 'FUNDING_REQUEST_EXPIRED':
        return 'Expired';
      default:
        return 'Awaiting Response';
    }
  };

  const renderView: () => JSX.Element = () => (
    <>
      <FundingRequestSummary
        pageTitle="Early payment request"
        supplier={selectedActivity?.supplier}
        paymentId={payment?.paymentReferences[0] || ''}
        funder={funder || ''}
        createdDateTime={createdDateTime}
        expectedPaymentDate={
          expectedTradePaymentDate
            ? formatDateTime(expectedTradePaymentDate || '', _DATE_FORMAT)
            : paymentExpectedAlternative()
        }
        status={selectedActivity?.status || ''}
        invoicesTotalCount={selectedActivity?.fundingRequest?.invoicesTotalCount}
        isProgramFixedCost={isProgramFixedCost}
        paymentAmount={payment?.amount}
        currency={payment?.currency || ''}
      />
      <Divider margin="8px 0" />
      <AccordionStyled defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentAccordionTitle data-automation-id="early-payment-request-h4-accordion-title-transaction-history">
            Transaction history
          </PaymentAccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TransactionHistory
            currencyCode={selectedActivity?.currency || ''}
            status={selectedActivity?.status}
            funder={funder}
            fundingRequest={selectedActivity?.fundingRequest}
            fundingResponse={selectedActivity?.fundingResponse}
            linkedPayments={selectedActivity?.linkedPayments}
            loading={requestFundingsCount > 0 ? false : true}
            bankAccountId={bankAccountId}
            paidDate={formatDateTime(paymentSentDate || '', `${_DATE_FORMAT} HH:mm`)}
            entityName={payment?.fromParticipant.registeredName || ''}
          />
        </AccordionDetails>
      </AccordionStyled>
      <Divider margin="8px 0" />
      <AccordionStyled disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentAccordionTitle data-automation-id="early-payment-request-h4-accordion-title-request-details">
            Request details
          </PaymentAccordionTitle>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '24px' }}>
          <FundingRequestDetails
            buyers={buyers}
            totalRequestAmount={selectedActivity ? selectedActivity.invoicesTotalAdvancedAmount : 0}
            totalFundingCost={selectedActivity ? selectedActivity.invoicesTotalFundingCost : 0}
            totalPaymentValue={selectedActivity ? selectedActivity.invoicesTotalPurchasePrice : 0}
            currencyCode={selectedActivity ? selectedActivity.currency : ''}
            totalMarginCosts={
              selectedActivity?.fundingRequest
                ? selectedActivity.fundingRequest.invoicesTotalMarginCosts
                : 0
            }
            totalBaseRateCosts={
              selectedActivity?.fundingRequest
                ? selectedActivity.fundingRequest.invoicesTotalBaseRateCosts
                : 0
            }
            fixedDiscountRate={
              selectedActivity?.fundingRequest
                ? selectedActivity.fundingRequest.fixedDiscountRate
                : 0
            }
            invoicesTotalFixedDiscountCost={
              selectedActivity?.fundingRequest
                ? selectedActivity.fundingRequest.invoicesTotalFixedDiscountCost
                : 0
            }
            isProgramFixedCost={isProgramFixedCost}
          />
        </AccordionDetails>
      </AccordionStyled>
      <Divider margin="8px 0" />
      <AccordionStyled defaultExpanded disableGutters style={{ marginBottom: '8px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentAccordionTitle data-automation-id="early-payment-request-h4-accordion-title-included-invoices">
            Included invoices
          </PaymentAccordionTitle>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '8px' }}>
          <FundingRequestIncludedInvoices invoices={invoicesViewModel} loading={loading} />
        </AccordionDetails>
      </AccordionStyled>
    </>
  );

  const renderPaymentView: () => JSX.Element = () => (
    <PaymentContainer>
      {renderPaymentHeaderView()}
      <Divider margin="0 -24px" />
      {renderPaymentSummaryView()}
      <Divider margin="0 -24px" />
      {renderPaymentInvoicesView()}
    </PaymentContainer>
  );

  const renderPaymentHeaderView: () => JSX.Element = () => (
    <PaymentHeaderContainer>
      <PaymentHeaderSegment>
        <PaymentCircleIcon />
        <PaymentHeaderTypeContainer>
          <PaymentHeaderH3>ID123445565</PaymentHeaderH3>
          <PaymentSubtitle>Trade payment</PaymentSubtitle>
        </PaymentHeaderTypeContainer>
      </PaymentHeaderSegment>
      <PaymentHeaderSegment>
        <PaymentHeaderH3Blue>59,940.00 {selectedActivity?.currency || ''}</PaymentHeaderH3Blue>
      </PaymentHeaderSegment>
    </PaymentHeaderContainer>
  );
  const renderPaymentSummaryView: () => JSX.Element = () => (
    <PaymentSummaryContainer>
      <PaymentSummaryDetailContainer>
        <PaymentSummaryDetailTitle>Payment</PaymentSummaryDetailTitle>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Total invoiced amount</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailValue>$6,000.00</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Total fee amount</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailValue>- $60.00</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabelContainer>
            <PaymentSummaryDetailLabel>Total adjustment: Deductions</PaymentSummaryDetailLabel>
            <IconTooltip tooltipText="This is the amount your invoice has been reduced by following a discrepancy." />
          </PaymentSummaryDetailLabelContainer>
          <PaymentSummaryDetailValue>- $0.00</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabelContainer>
            <PaymentSummaryDetailLabel>Total adjustment: Credits</PaymentSummaryDetailLabel>
            <IconTooltip tooltipText="This is the additional payment your buyer has made to you for this invoice." />
          </PaymentSummaryDetailLabelContainer>
          <PaymentSummaryDetailValue>- $0.00</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <Divider margin="-8px 0" />
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Total amount paid</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailBoldValue>$5,940.00</PaymentSummaryDetailBoldValue>
        </PaymentSummaryDetailRow>
      </PaymentSummaryDetailContainer>
      <PaymentSummaryDetailContainer>
        <PaymentSummaryDetailTitle>Payment details</PaymentSummaryDetailTitle>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Paid to</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailValue>{selectedActivity?.supplier || ''}</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Paid by</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailValue>{funder || ''}</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
        <PaymentSummaryDetailRow>
          <PaymentSummaryDetailLabel>Payment date</PaymentSummaryDetailLabel>
          <PaymentSummaryDetailValue>{_DATE_FORMAT}</PaymentSummaryDetailValue>
        </PaymentSummaryDetailRow>
      </PaymentSummaryDetailContainer>
    </PaymentSummaryContainer>
  );
  const renderPaymentInvoicesView: () => JSX.Element = () => (
    <PaymentInvoicesContainer>
      <PaymentInvoicesHeader>
        <PaymentAccordionTitle>Invoices included with the payment</PaymentAccordionTitle>
        <PaymentInvoicesTotalItems>
          {invoicesViewModel.length} item{invoicesViewModel.length === 1 ? '' : 's'}
        </PaymentInvoicesTotalItems>
      </PaymentInvoicesHeader>
      <FundingRequestIncludedInvoices invoices={invoicesViewModel} loading={loading} />
    </PaymentInvoicesContainer>
  );

  return appLoading || loading ? (
    <LoaderInPage />
  ) : paymentTabView ? (
    renderPaymentView()
  ) : (
    renderView()
  );
};

export default TradePaymentDetail;
