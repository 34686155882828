import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { SET_EARLY_PAYMENT_DIALOG_OPEN } from 'store/actions';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import Notification from 'components/Notification';
import InfoIcon from 'assets/icons/InfoIcon';
import GetPaidNowIcon from 'assets/icons/GetPaidNowIcon';
import { NotificationContainer } from './styled';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { Invoice } from 'utils/interfaces/invoice/invoice.interface';

const EarlyPaymentBanner: FC = () => {
  const dispatch = useDispatch();
  const {
    activeCurrencyCode,
    advancedInvoicesTotal,
    invoices
  }: {
    activeCurrencyCode: string;
    advancedInvoicesTotal: number;
    invoices: Invoice[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const earlyPaymentInvoices: () => number = () => (invoices ? invoices.length : 0);

  const openAutoPaymentDialog: () => void = () => {
    dispatch({ type: SET_EARLY_PAYMENT_DIALOG_OPEN, payload: true });
  };

  return (
    <NotificationContainer>
      <Notification
        backgroundColor={themeColors.bg.infoMuted}
        color={themeColors.text.info}
        icon={<InfoIcon />}
        hasButton={true}
        clickHandler={openAutoPaymentDialog}
        buttonText="Get paid"
        titleLarge
        title={`${
          CurrencySymbolsLookUp[activeCurrencyCode] + formatNumber(advancedInvoicesTotal || 0, 2)
        } available for early payment`}
        descriptionLarge
        description={`${earlyPaymentInvoices()} invoice${
          earlyPaymentInvoices() > 1 ? 's' : ''
        } qualify for early payment.`}
      />
    </NotificationContainer>
  );
};

export default EarlyPaymentBanner;
