import {
  CREATE_NEW_INVOICE,
  CREATE_NEW_INVOICE_FAILURE,
  CREATE_NEW_INVOICE_SUCCESS,
  RESET_NEW_INVOICE_ID,
  SET_DRAFT_INVOICE_MODAL,
  HIDE_NEW_INVOICE_BAR,
  SHOW_NEW_INVOICE_BAR,
  RESET_CREATE_NEW_INVOICE_ERROR,
  SET_DRAFT_PO
} from 'store/actions';

import { ReducerAction, StateBase } from '.';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';
import { SET_DRAFT_ASYNCTASKID } from 'store/actions/createInvoice';
import { ILogisticsRuleSubtemplateField } from 'api/interfaces/program/program.interface';

interface CreateInvoiceProps extends StateBase {
  createInvoiceId: string | null;
  display: boolean;
  draft: boolean;
  draftPurchaseOrderId: string;
  draftPurchaseOrder: ILedgerPurchaseOrder | null;
  draftInvoiceModal: ILogisticsRuleSubtemplateField[] | null;
  completed: boolean;
  errorMessage: string[];
  draftAsyncTaskID: string | null;
}

const DEFAULT_STATE: CreateInvoiceProps = {
  createInvoiceId: null,
  draftAsyncTaskID: null,
  display: true,
  loading: false,
  draft: false,
  draftPurchaseOrderId: '',
  draftPurchaseOrder: null,
  draftInvoiceModal: null,
  error: '',
  completed: false,
  errorMessage: []
};

const createInvoiceReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case RESET_NEW_INVOICE_ID:
      return {
        ...state,
        loading: false,
        display: true,
        draftInvoiceModal: null,
        completed: false,
        draft: false,
        draftPurchaseOrderId: '',
        draftPurchaseOrder: null,
        createInvoiceId: null,
        draftAsyncTaskID: null
      };
    case HIDE_NEW_INVOICE_BAR:
      return {
        ...state,
        display: false
      };
    case SHOW_NEW_INVOICE_BAR:
      return {
        ...state,
        display: true
      };
  
      case SET_DRAFT_INVOICE_MODAL:
      return {
        ...state,
        draft: true,
        draftPurchaseOrderId: payload.id,
        draftInvoiceModal: payload.data
      };
      case SET_DRAFT_ASYNCTASKID:
      return {
        ...state,
        draftAsyncTaskID: payload,
      };
    case SET_DRAFT_PO:
      return {
        ...state,
        draftPurchaseOrder: payload.purchaseOrder
      };

    case CREATE_NEW_INVOICE:
      return {
        ...state,
        loading: true
      };
    case CREATE_NEW_INVOICE_SUCCESS:
      return {
        ...state,
        createInvoiceId: payload.assetId,
        errorMessage: payload.errorMessage,
        completed: payload.completed,
        loading: false,
        draft: payload.draft
      };
    case CREATE_NEW_INVOICE_FAILURE:
      return {
        ...state,
        error: payload,
        completed: false,
        loading: false,
        draft: false,
        draftAsyncTaskID: null
      };

    case RESET_CREATE_NEW_INVOICE_ERROR:
      return {
        ...state,
        error: '',
        errorMessage: []
      };

    default:
      return state;
  }
};

export { createInvoiceReducer };
export type { CreateInvoiceProps };
