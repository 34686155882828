import { EntityOwner } from 'api/interfaces/entity/entity.interface';
import BaseCard from 'components/common/cards/BaseCard';
import Divider from 'components/common/divider';
import LedgerDetailHeader, {
  LedgerDetailHeaderProps
} from 'components/ledger/LedgerDetail/LedgerDetailHeader';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { LedgerDetailViewCommentsContainer, LedgerDetailViewCommentsWrapper } from './styled';
import { ILedgerInvoice } from 'api/interfaces/ledger/ledgerInvoice.interface';
import { ILedgerPurchaseOrder } from 'api/interfaces/ledger/ledgerPurchaseOrder.interface';

interface LedgerDetailViewCommentsProps {
  children: React.ReactNode;
}

const LedgerDetailViewComments: FC<LedgerDetailViewCommentsProps> = ({ children }) => {
  const {
    programOwnerDetails
  }: {
    programOwnerDetails: EntityOwner | null;
  } = useSelector((state: RootStateOrAny) => state.program);
  const {
    documentForViewHeaderData
  }: {
    documentForViewHeaderData: {
      header: LedgerDetailHeaderProps | null;
      data: ILedgerInvoice | ILedgerPurchaseOrder | null;
    } | null;
  } = useSelector((state: RootStateOrAny) => state.context);

  return (
    <LayoutViewContainer size="small" margin="0 auto">
      <BaseCard noBoxShadow noBorder>
        <LedgerDetailViewCommentsWrapper
          data-automation-id="ledger-detail-view-comments-div-header"
          data-testid="sp-ledger-view-comments-header"
        >
          {documentForViewHeaderData?.header && (
            <LedgerDetailHeader
              {...documentForViewHeaderData.header}
              companyLogo={programOwnerDetails?.companyLogo || ''}
            />
          )}
          <LedgerDetailViewCommentsContainer
            data-automation-id="ledger-detail-view-comments-div-container"
            data-testid="sp-ledger-view-comments-container"
          >
            <Divider margin="16px 0 0 0" />
            {children}
          </LedgerDetailViewCommentsContainer>
        </LedgerDetailViewCommentsWrapper>
      </BaseCard>
    </LayoutViewContainer>
  );
};

export default LedgerDetailViewComments;
