import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { Insurance } from 'api/interfaces/insurance/insurance.interface';
import { LedgerLogistics } from 'api/interfaces/logistics/logistics.interface';
import Divider from 'components/common/divider';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { FC } from 'react';
import LedgerDetailInsurance from './LedgerDetailInsurance';
import LedgerDetailLogistic from './LedgerDetailLogistic';
import {
  AccordionStyled,
  LedgerDetailAccordionContainer,
  LedgerDetailAccordionTitle
} from './styled';

interface LedgerDetailOtherAccordionProps {
  logistics: LedgerLogistics | null;
  insurance: Insurance | null;
  type: keyof typeof AssetEnum;
}

const LedgerDetailOtherAccordion: FC<LedgerDetailOtherAccordionProps> = ({
  logistics,
  insurance,
  type
}) => {
  return (
    <LedgerDetailAccordionContainer>
      {insurance && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-h4-other-details-title-insurance">
                Insurance
              </LedgerDetailAccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerDetailInsurance insurance={insurance} type={type} />
            </AccordionDetails>
          </AccordionStyled>
          <Divider margin="0 24px" />
        </>
      )}
      {logistics && (
        <>
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <LedgerDetailAccordionTitle data-automation-id="ledger-detail-h4-other-details-title-logistics">
                Logistics
              </LedgerDetailAccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerDetailLogistic logistics={logistics} />
            </AccordionDetails>
          </AccordionStyled>
        </>
      )}
    </LedgerDetailAccordionContainer>
  );
};

export default LedgerDetailOtherAccordion;
