/* eslint-disable react-hooks/exhaustive-deps */
import { IComment } from 'api/interfaces/open-account/comment/comment.interface';
import { IUser } from 'api/interfaces/users/user.interface';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import Divider from 'components/common/divider';
import { AssetEnum } from 'lib/enums/asset/asset.enum';
import { formatDate } from 'lib/helpers/formatters/datetimeFormatters';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  CommentIconContainer,
  CommentIconWrapper,
  LedgerDetailCommentLink,
  LedgerDetailCommentsButtonContainer,
  LedgerDetailCommentsCard,
  LedgerDetailCommentsCardText,
  LedgerDetailCommentsContainer,
  LedgerDetailCommentsDateTime,
  LedgerDetailCommentsHeader,
  LedgerDetailCommentsHeaderTitleContainer,
  LedgerDetailCommentsInitials,
  LedgerDetailCommentsItemContainer,
  LedgerDetailCommentsRow,
  LedgerDetailCommentsRowContainer,
  LedgerDetailCommentsSubtitle,
  LedgerDetailCommentsTitleRow
} from './styled';
import CommentIcon from 'assets/icons/CommentIcon/CommentIcon';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';

interface LedgerDetailCommentsProps {
  comments: IComment[];
  assetType: keyof typeof AssetEnum;
  assetId: string;
  inView?: boolean;
  addCommentClickHandler: () => void;
  viewCommentsClickHandler: () => void;
  addCommentSubmitClickHandler: (comment: string) => void;
}

const LedgerDetailComments: FC<LedgerDetailCommentsProps> = ({
  comments,
  assetType,
  assetId,
  inView,
  addCommentClickHandler,
  viewCommentsClickHandler,
  addCommentSubmitClickHandler
}) => {
  const [commentValue, setCommentValue] = useState<string>('');
  const [uniqueCompanies, setUniqueCompanies] = useState<string[]>([]);
  const [data, setData] = useState<IComment[]>([]);

  const { loggedInUser }: { loggedInUser: IUser | null } = useSelector(
    (state: RootStateOrAny) => state.user
  );
  const { invoiceCommentsLoading }: { invoiceCommentsLoading: boolean } = useSelector(
    (state: RootStateOrAny) => state.invoice
  );

  useEffect(() => {
    sortCommentsChronologically();
    determineUniqueCompanies();
  }, [comments]);

  const sortCommentsChronologically: () => void = () => {
    const sortedArr: IComment[] = comments.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return -1;
      return a.createdAt < b.createdAt ? 1 : -1;
    });
    if (inView) {
      setData(sortedArr.slice(0, 3));
      return;
    }
    setData(sortedArr);
  };

  const determineUniqueCompanies: () => void = () => {
    const companies = comments.map((c) => c.userCompany || '');
    const uniqueCompaniesArr: string[] = Array.from(new Set(companies));
    const sorted = uniqueCompaniesArr.sort((a, b) =>
      a !== loggedInUser?.profile.tenants[0] ? 1 : -1
    );
    setUniqueCompanies(sorted);
  };

  const addClickHandler: () => void = () => {
    if (!commentValue || commentValue.length === 0) return;
    addCommentSubmitClickHandler(commentValue || '');
  };

  const checkBuyerUser: (myX500Data: string) => boolean = (myX500Data) => {
    const ouData = myX500Data.match(/OU=([^,]+)/);
    if (ouData) {
      return ouData[1].includes('Buyer');
    }
    return false;
  };

  return (
    <LedgerDetailCommentsContainer
      data-automation-id="ledger-detail-comments-div-view-container"
      data-testid="sp-ledger-comments-view-container"
    >
      <LedgerDetailCommentsButtonContainer inView={inView}>
        {!inView && (
          <h4
            data-automation-id="ledger-detail-comments-h4-title"
            data-testid="sp-ledger-comments-title"
          >
            All comments
          </h4>
        )}
        <OutlinedInput
          id="sp-ledger-add-comment-input"
          dataAutomationId="ledger-detail-add-comment-input"
          dataTestId="sp-ledger-add-comment-input"
          defaultValue={commentValue}
          changeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setCommentValue(event.target.value)
          }
          placeholder="Start typing your comment..."
          endAdornment={
            <InputAdornment position="end">
              <CommentIconWrapper
                onClick={() => addClickHandler()}
                disabled={invoiceCommentsLoading || commentValue.length === 0}
              >
                <CommentIcon />
              </CommentIconWrapper>
            </InputAdornment>
          }
          multiline
          width="100%"
          height="100%"
          minHeight="56px"
          noBorder
          showShadow
          borderRadius="50px"
        />
      </LedgerDetailCommentsButtonContainer>
      <LedgerDetailCommentsRowContainer
        data-automation-id="ledger-detail-comments-div-container"
        data-testid="sp-ledger-comments-container"
      >
        {data.map((comment, i) => (
          <LedgerDetailCommentsRow
            data-automation-id={`ledger-detail-comment-div-wrapper-${i}`}
            data-testid={`sp-ledger-comment-wrapper-${i}`}
            key={i}
          >
            <LedgerDetailCommentsItemContainer>
              <LedgerDetailCommentsHeader>
                <CommentIconContainer
                  color={
                    comment?.userCompany !== uniqueCompanies[0]
                      ? `${themeColors.chart.light.colour7}`
                      : `${themeColors.bg.primary}`
                  }
                >
                  <LedgerDetailCommentsInitials>
                    {checkBuyerUser(comment?.myX500 || '') === true
                      ? comment?.userPseudo || ''
                      : (comment?.name || '').charAt(0)}
                  </LedgerDetailCommentsInitials>
                </CommentIconContainer>
                <LedgerDetailCommentsHeaderTitleContainer>
                  <LedgerDetailCommentsTitleRow>
                    <LedgerDetailCommentsSubtitle
                      data-automation-id={`ledger-detail-comment-p-username-${i}`}
                      data-testid={`sp-ledger-comment-username-${i}`}
                    >
                      {`${
                        checkBuyerUser(comment?.myX500 || '') === true
                          ? comment?.userPseudo || ''
                          : comment?.name || ''
                      } (${comment?.userCompany})`}
                    </LedgerDetailCommentsSubtitle>
                  </LedgerDetailCommentsTitleRow>
                  <LedgerDetailCommentsDateTime
                    data-automation-id={`ledger-detail-comment-p-datetime-${i}`}
                    data-testid={`sp-ledger-comment-datetime-${i}`}
                  >
                    {formatDate(comment.createdAt || '', 'yyyy/MM/dd HH:mm')}
                  </LedgerDetailCommentsDateTime>
                </LedgerDetailCommentsHeaderTitleContainer>
              </LedgerDetailCommentsHeader>
              <LedgerDetailCommentsCard>
                <LedgerDetailCommentsCardText
                  data-automation-id={`ledger-detail-comment-p-content-${i}`}
                  data-testid={`sp-ledger-comment-content-${i}`}
                >
                  {comment?.content}
                </LedgerDetailCommentsCardText>
              </LedgerDetailCommentsCard>
            </LedgerDetailCommentsItemContainer>
          </LedgerDetailCommentsRow>
        ))}
      </LedgerDetailCommentsRowContainer>
      {inView && comments?.length > 3 && (
        <>
          <Divider margin="16px 0" />
          <LedgerDetailCommentLink
            onClick={viewCommentsClickHandler}
            data-automation-id="ledger-detail-comments-p-see-all-comments"
            data-testid="sp-ledger-comments-see-all-comments"
          >
            See all comments
          </LedgerDetailCommentLink>
        </>
      )}
    </LedgerDetailCommentsContainer>
  );
};

export default LedgerDetailComments;
