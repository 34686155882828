import { Accordion } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Checkbox } from '@mui/material';
import Lottie from 'lottie-react';

interface ArrowProps {
  opened: boolean;
}

interface OutgoingPaymentRequestNameValueRowProps {
  margin?: string;
}

export const OutgoingPaymentRequestWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const OutgoingPaymentRequestGridRowGroupText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const CheckboxStyled = styled(Checkbox)`
  color: ${themeColors.text.primary};
`;

export const AccordionSummaryInvoices = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowRightIcon style={{ fontSize: '1.5rem', color: themeColors.icon.primary }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '4px'
  }
}));

export const ArrowWrapper = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ opened }) => (opened ? 'rotate(90deg)' : '')};
`;

export const GroupedTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${typography.bodyRegular};
  th {
    padding: 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${themeColors.bg.light.muted};
    }
  }
  td {
    padding: 16px 8px;
  }
  .accordion-row {
    padding: 0 8px;
  }
  .MuiButtonBase-root {
    background-color: ${themeColors.mono6};
    padding: 0 8px;
    margin: 0 8px;
  }
  .MuiAccordionDetails-root {
    margin-top: 0 !important;
    padding: 8px 8px 16px;
  }
  .MuiAccordionSummary-content {
    margin-left: 0;
  }
`;

export const OutgoingPaymentRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
`;

export const OutgoingPaymentRequestTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.primary};
  margin-bottom: 32px;
`;

export const OutgoingPaymentRequestName = styled.p`
  margin: 0;
  ${typography.bodyRegular};
`;

export const OutgoingPaymentRequestData = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const OutgoingPaymentRequestAccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    margin: 0 -16px;
    ::before {
      display: none;
    }
  }
`;

export const AccordionSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const OutgoingPaymentRequestNameValueRow = styled.div<OutgoingPaymentRequestNameValueRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const OutgoingPaymentRequestActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;
export const OutgoingPaymentRequestButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const OutgoingPaymentRequestButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
  font-family: 'Source Sans Pro', sans-serif;
`;

export const TableMUIStyled = styled(MUIDataTable)`
  min-width: 600px;
  .MuiToolbar-root {
    display: none;
  }
  th {
    padding: 0 8px;
    ${typography.tableHeader};
    background-color: ${themeColors.mono5};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  }
  tbody {
    tr {
      :hover {
        background-color: ${themeColors.mono6} !important;
      }
    }
  }
  [class*='MUIDataTableResize-resizer'] {
    border: 1px solid transparent !important;
    cursor: ew-resize;
  }
`;

export const OutgoingPaymentLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const GeneratingOutgoingPaymentRequestLottieAnimation = styled(Lottie)`
  display: flex;
  height: 200px;
  margin-top: 25vh;
`;

export const OutgoingPaymentNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
`;

export const OutgoingPaymentRequestLoadingTitle = styled.h2`
  margin: 0;
  color: ${themeColors.text.primary};
  text-align: center;
  margin-top: 24px;
`;
