import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const LedgerMatchingViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LedgerMatchingViewHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 6px;
`;

export const LedgerMatchingViewTitle = styled.h3`
  margin: 0;
`;

export const LedgerMatchingViewDescription = styled.p`
  margin: 0;
  ${typography.fontHindRegular};
`;
