/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { LedgerLineItemPackaging } from 'api/interfaces/line-item/lineItem';
import { FC, useEffect, useState } from 'react';
import {
  AccordionStyled,
  LedgerItemsDialogContentContainer,
  LedgerItemsDialogKeyValueRow,
  LedgerItemsDialogListContainer,
  LedgerItemsDialogTextRegular,
  LedgerItemsDialogTextSemiBold,
  LedgerItemsDialogTitle,
  LedgerItemsTitle,
  LineItemsDialogWrapper
} from './styled';
import { toSentenceCase } from 'lib/helpers/formatters/stringFormatters';
import invoice from 'store/epics/invoice';

interface ILedgerDetailLineItemsDialog {
  packaging: LedgerLineItemPackaging | null | undefined;
  lineItemNumber: string;
}

const LedgerDetailLineItemsDialog: FC<ILedgerDetailLineItemsDialog> = ({
  packaging,
  lineItemNumber
}) => {
  const [view, setView] = useState<JSX.Element[]>([<></>]);

  useEffect(() => {
    if (packaging) renderView(packaging);
  }, []);

  const renderView: (data: any) => void = (data) => {
    setView(
      Object.keys(data)
        .filter((key) => Boolean(data[key]) && !key.includes('@'))
        .map((key) => (
          <AccordionStyled disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              data-automation-id="ledger-detail-lineItems-accordion-summary"
            >
              <LedgerItemsDialogTitle data-automation-id="ledger-detail-view-h4-accordion-title-line-items">
                {toSentenceCase(key)}
              </LedgerItemsDialogTitle>
            </AccordionSummary>
            <AccordionDetails>
              <LedgerItemsDialogContentContainer>
                {Object.keys(data[key])
                  .filter((key) => !key.includes('@'))
                  .map((subKey) => (
                    <LedgerItemsDialogKeyValueRow key={subKey}>
                      <LedgerItemsDialogTextSemiBold>
                        {toSentenceCase(subKey)}
                      </LedgerItemsDialogTextSemiBold>
                      <LedgerItemsDialogTextRegular>
                        {data[key][subKey]}
                      </LedgerItemsDialogTextRegular>
                    </LedgerItemsDialogKeyValueRow>
                  ))}
              </LedgerItemsDialogContentContainer>
            </AccordionDetails>
          </AccordionStyled>
        ))
    );
  };

  return (
    <LineItemsDialogWrapper>
      <LedgerItemsTitle>Packaging - {lineItemNumber}</LedgerItemsTitle>
      <LedgerItemsDialogListContainer>{view}</LedgerItemsDialogListContainer>
    </LineItemsDialogWrapper>
  );
};

export default LedgerDetailLineItemsDialog;
