import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  height: ${({ height }) => height || '40px'};
  width: ${({ width }) => width || '100%'};
  border: none;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '10px 18px'};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
  background-color: ${({ backgroundColor, disabled, disabledBackgroundColor }) =>
    disabled
      ? disabledBackgroundColor
        ? disabledBackgroundColor
        : themeColors.bg.primaryDisabled
      : backgroundColor || themeColors.bg.primary};
  color: ${({ disabledTextColor, disabled, textColor }) =>(
    disabled ? disabledTextColor ? disabledTextColor : themeColors.text.primaryDisabled :
    textColor ? textColor : themeColors.text.onPrimary)};
  ${typography.bodySmallSemiBold};
  border-radius: 6px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
