import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';

export const SubNavStyled = styled.nav`
  display: flex;
  height: 56px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  background: ${themeColors.bg.light.strong};
  padding-left: ${isMobile() ? '16px' : '80px'};
  padding-right: ${isMobile() ? '16px' : '110px'};
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SubNavItemsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding-left: 8px;
`;

export const AccountingSoftwareConnectionStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-right:110px;
  ${typography.bodyMediumSemiBold};
  color: ${themeColors.text.light.onInvert};
`;