export const FETCH_ENTITY_DETAILS = '@@frontend/store/reducers/entity/FETCH_ENTITY_DETAILS';
export const FETCH_ENTITY_DETAILS_SUCCESS =
  '@@frontend/store/reducers/entity/FETCH_ENTITY_DETAILS_SUCCESS';
export const FETCH_ENTITY_DETAILS_FAILURE =
  '@@frontend/store/reducers/entity/FETCH_ENTITY_DETAILS_FAILURE';
export const DELETE_ENTITY_DETAILS = '@@frontend/store/reducers/entity/DELETE_ENTITY_DETAILS';
export const FETCH_BANK_ACCOUNT = '@@frontend/store/reducers/entity/FETCH_BANK_ACCOUNT';
export const FETCH_BANK_ACCOUNT_SUCCESS =
  '@@frontend/store/reducers/entity/FETCH_BANK_ACCOUNT_SUCCESS';
export const FETCH_BANK_ACCOUNT_FAILURE =
  '@@frontend/store/reducers/entity/FETCH_BANK_ACCOUNT_FAILURE';
